import { useTranslation } from 'react-i18next'

import { OrderStatus } from '@/network/graphql/types.generated'
import { PaymentStatus } from '@/types'
import { OrderPaymentStatusMappingReverse } from '@/util/mapping'

import type { OrderedProduct } from '@/network/graphql/types.generated'

import './SoldStatusTag.scss'

interface orderStatusTagProps {
  product: Pick<OrderedProduct, 'order'>
}

export const SoldStatusTag = ({ product }: orderStatusTagProps) => {
  const { t } = useTranslation()

  const mappedStatus = OrderPaymentStatusMappingReverse[product.order.paymentStatus]

  switch (product.order.status) {
    case OrderStatus.Canceled:
      return <p className="sold-status-tag danger">{t('showProductsListTableColumnStatusCanceled')}</p>
    case OrderStatus.Refunded:
      return <p className="sold-status-tag danger">{t('showProductsListTableColumnStatusRefunded')}</p>

    case OrderStatus.Delivered:
      return <p className="sold-status-tag success">{t('showProductsListTableColumnStatusDelivered')}</p>
    case OrderStatus.Shipped:
      return <p className="sold-status-tag success">{t('showProductsListTableColumnStatusShipped')}</p>

    case OrderStatus.Pending:
      switch (mappedStatus) {
        case PaymentStatus.failed:
          return <p className="sold-status-tag danger">{t('showProductsListTableColumnPaymentFail')}</p>
        case PaymentStatus.pending_sepa_debit:
          return <p className="sold-status-tag warning">{t('showProductsListTableColumnPaymentInProgress')}</p>
        case PaymentStatus.in_progress:
          return <p className="sold-status-tag warning">{t('showProductsListTableColumnPaymentInProgress')}</p>
        case PaymentStatus.success:
          return <p className="sold-status-tag info">{t('showProductsListTableColumnPaymentSuccess')}</p>
      }
  }
}
