import { useCallback, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'

type ResetPasswordFormProps = {
  error?: string
  success?: boolean
  onCancel: () => void
  onSubmit: (credential: string) => Promise<void>
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { onCancel, onSubmit, success, error } = props
  const { t } = useTranslation()

  const [credential, setCredential] = useState<string>('')
  const isValid = Boolean(credential && credential.length)

  const handleSubmit = useCallback(async () => {
    if (!isValid) {
      return
    }

    await onSubmit(credential)
  }, [credential, onSubmit])

  const handleCredentialChange = useCallback(
    (event: ChangeEvent) => {
      const credential = (event.target as HTMLInputElement).value
      setCredential(credential)
    },
    [setCredential]
  )

  const handleCancelClick = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Form
      error={error}
      id="reset-password-form"
      success={success}
      successMessage={t('loginResetPasswordConfirmationMessage')}
      onSubmit={handleSubmit}
    >
      {!success && (
        <Field
          id="credential"
          label={t('loginResetPasswordCredentialLabel')}
          name="credential"
          placeholder={t('loginResetPasswordCredentialPlaceholder')}
          required={true}
          validation={false}
          onChange={handleCredentialChange}
        />
      )}
      <div className="actions">
        <Button className="cancel-action secondary" label={t('commonPrevious')} onClick={handleCancelClick} />
        {!success && (
          <Button
            className="validate-action primary"
            disabled={!isValid}
            htmlType="submit"
            label={t('commonValidate')}
          />
        )}
      </div>
    </Form>
  )
}

export default ResetPasswordForm
