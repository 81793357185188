import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

export const SHOW_RATING_REVIEW_FIELD_MIN_LENGTH = 50

type ShowRatingReasonFieldProps = {
  required: boolean
  value: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onValidityChange?: (isValid: boolean) => void
}

const ShowRatingReviewField = (props: ShowRatingReasonFieldProps) => {
  const { t } = useTranslation()

  const { required, value, onChange = () => undefined, onValidityChange = () => undefined } = props

  return (
    <Field
      label={t('ShowRatingModalSubTitle')}
      minLength={required ? SHOW_RATING_REVIEW_FIELD_MIN_LENGTH : undefined}
      name="review"
      placeholder={t('ShowRatingModalPlaceholder')}
      required={required}
      rows={4}
      type="textarea"
      value={value}
      hint={
        required
          ? t('showRatingReviewFieldHint', { min: SHOW_RATING_REVIEW_FIELD_MIN_LENGTH, current: value.length })
          : undefined
      }
      onChange={onChange}
      onValidityChange={onValidityChange}
    />
  )
}

export default ShowRatingReviewField
