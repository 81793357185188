import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type CreateAndLaunchFieldProps = {
  value: boolean
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const CreateAndLaunchField = (props: CreateAndLaunchFieldProps) => {
  const { value: createAndLaunch, onChange: handleCreateAndLaunchChange, disabled } = props
  const { t } = useTranslation()

  return (
    <Field
      checked={createAndLaunch}
      data-tooltip={disabled ? t('productFormLaunchProductAfterCreateDisabledTitle') : undefined}
      data-tooltip-position="bottom left"
      disabled={disabled}
      id="create-and-launch"
      label={t('productFormLaunchProductAfterCreateLabel')}
      name="create-and-launch"
      type="checkbox"
      onChange={handleCreateAndLaunchChange}
    />
  )
}

export default CreateAndLaunchField
