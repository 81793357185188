import { showsAdapter } from '../reducers/shows'

import type { RootState } from '../reducers'

export const {
  selectAll: selectAllShows,
  selectById: selectShowById,
  selectEntities: selectShowEntities,
  selectIds: selectShowIds,
  selectTotal: selectTotalShows,
} = showsAdapter.getSelectors((state: RootState) => state.shows)
