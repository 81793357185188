import { APP_TITLE } from '@/config/constants'

import './VoggtLogo.scss'

const VoggtLogo = () => {
  return (
    <span className="voggt-logo">
      <span className="logo">
        <svg height="300" viewBox="0 0 270.933 79.375" xmlns="http://www.w3.org/2000/svg">
          <path d="M57.644 10.175 46.358 72.197H12.592L1.307 10.175h20.76l7.407 52.719 7.409-52.719z" fill="#0f0" />
          <path
            d="M113.202 38.602v5.168c0 19.296-8.96 28.857-28.6 28.857-19.554 0-28.599-9.56-28.599-28.857v-5.168c0-19.297 9.045-28.858 28.6-28.858 19.64 0 28.599 9.561 28.599 28.858zm-20.674 4.393v-3.618c0-9.218-2.412-11.629-7.926-11.629-5.513 0-7.925 2.411-7.925 11.629v3.618c0 9.216 2.412 11.629 7.925 11.629 5.514 0 7.926-2.413 7.926-11.63zm79.679-32.82V60.05c0 11.63-8.787 16.883-28.427 16.883-18.692 0-27.134-4.823-27.134-13.867V62.03h19.468v.604c0 4.22 2.153 5.684 7.58 5.684 5.772 0 7.84-1.808 7.84-6.633v-21.88c0 13.439-5.342 19.21-17.315 19.21-14.041 0-19.21-6.46-19.21-21.363v-6.547c0-14.902 5.169-21.362 19.21-21.362 11.973 0 17.314 5.77 17.314 19.209V10.175zm-20.674 26.36v-4.308z"
            fill="#0f0"
          />
          <path
            d="M260.495 10.174V3.11h-20.674v7.063H234.4v16.54h5.42v45.483h20.675V26.714h8.527v-16.54z"
            fill="#0f0"
          />
          <path
            d="M231.738 10.175V60.05c0 11.63-8.786 16.883-28.426 16.883-18.693 0-27.134-4.823-27.134-13.867V62.03h19.467v.604c0 4.22 2.153 5.684 7.58 5.684 5.772 0 7.84-1.808 7.84-6.633v-21.88c0 13.439-5.341 19.21-17.315 19.21-14.04 0-19.21-6.46-19.21-21.363v-6.547c0-14.902 5.17-21.362 19.21-21.362 11.974 0 17.315 5.77 17.315 19.209V10.175zm-20.673 26.36v-4.308z"
            fill="#0f0"
          />
          <path
            className="eye voggt-eye"
            d="M126.077 23.372c-4.91 0-7.924 1.896-7.924 10.596v.516c0 8.7 3.015 10.597 7.924 10.597 5.342 0 7.926-1.724 7.926-8.7v-4.308c0-6.978-2.584-8.701-7.926-8.701z"
            fill="#fff"
          />
          <path
            className="eye voggt-eye"
            d="M185.624 23.424c-4.91 0-7.925 1.896-7.925 10.596v.516c0 8.7 3.015 10.596 7.925 10.596 5.341 0 7.925-1.723 7.925-8.7v-4.307c0-6.978-2.584-8.701-7.925-8.701z"
            fill="#fff"
          />
        </svg>
      </span>
      <span className="label">{APP_TITLE}</span>
    </span>
  )
}

export default VoggtLogo
