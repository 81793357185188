import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type ProductCardGradeFieldFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  required?: boolean
  disabled?: boolean
}

export const productCardGradeOptions = [
  { value: '' },
  { value: 'Authentic' },
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '6.5' },
  { value: '7' },
  { value: '7.5' },
  { value: '8' },
  { value: '8.5' },
  { value: '9' },
  { value: '9.5' },
  { value: '10' },
  { value: '10+' },
]

const ProductCardGradeField = (props: ProductCardGradeFieldFieldProps) => {
  const { value, onChange, required, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      disabled={disabled}
      label={t('productFormCardGradeLabel')}
      name="card-grade"
      options={productCardGradeOptions}
      placeholder={t('productFormCardGradePlaceholder')}
      required={required}
      type="select"
      value={value}
      errorMessages={{
        required: t('productFormCardGradeRequiredError'),
      }}
      onChange={onChange}
    />
  )
}

export default ProductCardGradeField
