import { FaPaypal } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { PAYPAL_SSO_PARAM_NAME } from '@/util/url-auth-tokens'

const { VITE_APP_PAYPAL_CLIENT_ID, VITE_APP_BITS_ENV, VITE_APP_PUBLIC_LOCATION } = import.meta.env

const BASE_URL =
  VITE_APP_BITS_ENV?.toLowerCase() === 'production' ? 'https://www.paypal.com' : 'https://www.sandbox.paypal.com'

const PaypalSSOButton = () => {
  const PAYPAL_URL = VITE_APP_PAYPAL_CLIENT_ID
    ? `${BASE_URL}/signin/authorize?flowEntry=static&client_id=${VITE_APP_PAYPAL_CLIENT_ID}&scope=openid%20profile%20email&redirect_uri=${VITE_APP_PUBLIC_LOCATION}?${PAYPAL_SSO_PARAM_NAME}=true&response_type=id_token`
    : '#'

  return (
    <a href={PAYPAL_URL}>
      <Button className="sso-login-action facebook-sso-login-action" icon={<FaPaypal />} />
    </a>
  )
}

export default PaypalSSOButton
