export type PaymentMethod = {
  id: number
  type: string
  card: any
}

export type ShippingAddress = {
  id: number
  name: string

  inputLine1: string
  inputLine2: string
  inputPostalCode: string
  inputCity: string
  inputCountry: string

  firstName: string
  lastName: string
  address1: string
  address2: string
  address3: string
}

export enum ProductStatusInAuction {
  pending = 'pending',
  inProgress = 'inProgress',
  sold = 'sold',
  unsold = 'unsold',
}

export enum OrderStatus {
  pending = 'pending',
  shipped = 'shipped',
  delivered = 'delivered',
  refunded = 'refunded',
  done = 'done',
  canceled = 'canceled',
}

export enum PaymentStatus {
  in_progress = 'in_progress',
  pending_sepa_debit = 'pending_sepa_debit',
  success = 'success',
  failed = 'failed',
}

export enum ProductType {
  auction = 'auction',
  giveaway = 'giveaway',
}

export type Order = {
  id: number
  name: string
  status: OrderStatus
  paymentStatus: PaymentStatus
  amount: number
  feeDeductibleFromBuyerPayment: number
  refundedAmount: number
  refundDebitedAccount: string
  feeDeductibleFromTopUpToSeller: number

  shippingAddressId: number
  shippingAmount: number
  deductedShippingCost?: number
  paymentMethodId: number
  paymentMethod?: PaymentMethod
  customerId: number
  productId: number
  sellerId: number
  showId: number

  shippingAddress?: ShippingAddress
  customer?: User
  product?: Product
  seller?: User
  show?: Show

  updatedAt: Date
  createdAt: Date
}

export type Product = {
  id: number
  name: string
  type: ProductType
  description: string
  startingAmount: number
  showId: number
  status: ProductStatusInAuction
  createdAt: Date
  updatedAt: Date
  weight: number
  isPinned: boolean
}

export enum ShowShippingOption {
  free = 'free',
  letter = 'letter',
  large_letter = 'large_letter',
  letter_registered = 'letter_registered',
  package = 'package',

  ups = 'ups',
  colissimo = 'colissimo',
  mondial_relay = 'mondial_relay',

  sneakers_standard = 'sneakers_standard',
  sneakers_affordable = 'sneakers_affordable',
  sneakers_premium = 'sneakers_premium',

  fashion_small_package = 'fashion_small_package',
  fashion_small_package_incremental = 'fashion_small_package_incremental',
  fashion_medium_package = 'fashion_medium_package',
  fashion_medium_package_incremental = 'fashion_medium_package_incremental',
  fashion_large_package = 'fashion_large_package',
  fashion_large_package_incremental = 'fashion_large_package_incremental',

  letter_plus = 'letter_plus',
  large_letter_plus = 'large_letter_plus',
  package_plus = 'package_plus',

  letter_incremental = 'letter_incremental',
  package_incremental = 'package_incremental',
  letter_registered_incremental = 'letter_registered_incremental',
}

/**
 * @Deprecated Seems not used, maybe we could remove it ?
 */
export enum MappedShowShippingOptions {
  Free = 'FREE',
  Letter = 'LETTER',
  LargeLetter = 'LARGE_LETTER',
  LetterRegistered = 'LETTER_REGISTERED',
  Package = 'PACKAGE',

  Ups = 'UPS',
  Colissimo = 'COLISSIMO',
  MondialRelay = 'MONDIAL_RELAY',

  SneakersStandard = 'SNEAKERS_STANDARD',
  SneakersAffordable = 'SNEAKERS_AFFORDABLE',
  SneakersPremium = 'SNEAKERS_PREMIUM',

  FashionSmallPackage = 'FASHION_SMALL_PACKAGE',
  FashionMediumPackage = 'FASHION_MEDIUM_PACKAGE',
  FashionLargePackage = 'FASHION_LARGE_PACKAGE',
}

export type Show = {
  id: number
  name: string
  note: string
  slug: string
  user: User
  category: Category
  categoryId: Category['id']
  languageId: number
  channel: Channel
  shippingCategory: ShowShippingOption
  thumbnailUrl: string
  startAt: Date
  createdAt: Date
  updatedAt: Date
  userId?: number
  countryId: string
  isBroadcasting: boolean
  paymentPreAuthorizationAmount: number
  gmvTargetAmount: number
  preShowTeaserVideoUrl: string
  preShowTeaserVideoInfo: any
  ratingId: string
  isReusingPaidShippingFeesFromPreviousShows: boolean
  isPremier: boolean
  isFeatured: boolean
}

export type Channel = {
  id: number
  hls: string
  isBroadcasting: boolean
  rtmp: string
  streamKey: string
  createdAt: Date
  updatedAt: Date
}

export type User = {
  id: number
  email: string
  firstName: string
  lastName: string
  username: string
  channel: number
  isSeller: boolean
  isSelected: boolean
  phoneNumber: string
  avatarUrl: string
  createdAt: Date
  updatedAt: Date
  sellerConfig: any
  shippingAddress: any
  fixedFee: number
  percentageFee: number
  isProfessionalSeller: boolean
  countryId: string
  birthdate: Date
}

export type Category = {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
  colorAccent: string
}

export type CustomUrlPreshow = {
  get: string
  put: string
  delete: string
}

export enum ProductCategoryName {
  Fashion = 'FASHION',
  Sneakers = 'SNEAKERS',
}
