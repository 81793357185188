import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6'

import Button from '../../Button/Button'
import Loader from '../../Loader/Loader'

type TablePaginationProps = {
  perPage: number
  onItemPerPageChange: (perPage: number) => void
  perPageOptions: number[]
  currentPage: number
  selectable?: {
    selectedRows: string[]
    onSelectedRowsChange: (selectedRows: string[]) => void
  }
  loading?: boolean
  pages: number
  onPageChange: (page: number) => void
  extra?: React.ReactNode
}

const TablePagination = (props: TablePaginationProps) => {
  const { perPage, onItemPerPageChange, perPageOptions, currentPage, selectable, loading, pages, onPageChange, extra } =
    props
  const { t } = useTranslation()

  return (
    <>
      <div className="pagination">
        <div className="pagination-left-content">
          <div className="items-per-page">
            <span>{t('tableItemsPerPage')}</span>
            <select
              className="items-per-page-selector"
              value={perPage}
              onChange={(e) => {
                onItemPerPageChange(parseInt(e.target.value, 10))
              }}
            >
              {perPageOptions.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          {selectable && selectable.selectedRows.length > 0 && (
            <span className="selected-items">
              {t('tableSelecteditems', { number: selectable.selectedRows.length })}
            </span>
          )}
          {loading && <Loader />}
        </div>
        <div className="pagination-right-content">
          <div className="total">
            <span>{t('tablePaginationTotal')}</span>
            <select
              value={currentPage}
              onChange={(e) => {
                const page = parseInt(e.target.value, 10)
                if (page >= 1 && page <= pages) {
                  onPageChange(page)
                }
              }}
            >
              {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                <option key={page} value={page}>
                  {page}
                </option>
              ))}
            </select>
            <span className="table-total-page-number">/ {pages}</span>
          </div>

          <div className="table-navigation">
            <Button
              className="pagination-action prev-page-action secondary"
              disabled={currentPage <= 2}
              icon={<FaAnglesLeft />}
              onClick={() => onPageChange(1)}
            />
            <Button
              className="pagination-action prev-page-action secondary"
              disabled={currentPage <= 1}
              icon={<FaAngleLeft />}
              onClick={() => onPageChange(currentPage - 1)}
            />
            <Button
              className="pagination-action next-page-action secondary"
              disabled={currentPage >= pages}
              icon={<FaAngleRight />}
              onClick={() => onPageChange(currentPage + 1)}
            />
            <Button
              className="pagination-action next-page-action secondary"
              disabled={currentPage >= pages - 1}
              icon={<FaAnglesRight />}
              onClick={() => onPageChange(pages)}
            />
          </div>
        </div>
      </div>
      <div className="pagination-extra">{extra}</div>
    </>
  )
}

export default memo(TablePagination)
