import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerGlobalMetricsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerGlobalMetricsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', sellerRating?: number | null, followers: { __typename?: 'UserConnection', totalCount: number }, customers: { __typename?: 'CustomerConnection', totalCount: number }, referrals: { __typename?: 'UserConnection', totalCount: number }, shows: { __typename?: 'ShowConnection', totalCount: number, edges: Array<{ __typename?: 'ShowEdge', node: { __typename?: 'Show', currency: Types.Currency, totalAmountOrdersNetInCurrencyCents: number, totalNetIncomeInCurrencyCents: number } }> }, sellerStatistics?: { __typename?: 'SellerStatistics', totalOrderAmount: number, totalStreamingHours: number } | null } | null };


export const GetSellerGlobalMetricsDocument = gql`
    query GetSellerGlobalMetrics {
  viewer {
    sellerRating
    followers(first: 0) {
      totalCount
    }
    customers(first: 0) {
      totalCount
    }
    referrals(first: 0) {
      totalCount
    }
    shows: pastAndCurrentShowsByStartDateDesc(first: 1) {
      totalCount
      edges {
        node {
          currency
          totalAmountOrdersNetInCurrencyCents
          totalNetIncomeInCurrencyCents
        }
      }
    }
    sellerStatistics {
      totalOrderAmount
      totalStreamingHours
    }
  }
}
    `;

/**
 * __useGetSellerGlobalMetricsQuery__
 *
 * To run a query within a React component, call `useGetSellerGlobalMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerGlobalMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerGlobalMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerGlobalMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>(GetSellerGlobalMetricsDocument, options);
      }
export function useGetSellerGlobalMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>(GetSellerGlobalMetricsDocument, options);
        }
export function useGetSellerGlobalMetricsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>(GetSellerGlobalMetricsDocument, options);
        }
export type GetSellerGlobalMetricsQueryHookResult = ReturnType<typeof useGetSellerGlobalMetricsQuery>;
export type GetSellerGlobalMetricsLazyQueryHookResult = ReturnType<typeof useGetSellerGlobalMetricsLazyQuery>;
export type GetSellerGlobalMetricsSuspenseQueryHookResult = ReturnType<typeof useGetSellerGlobalMetricsSuspenseQuery>;
export type GetSellerGlobalMetricsQueryResult = Apollo.QueryResult<GetSellerGlobalMetricsQuery, GetSellerGlobalMetricsQueryVariables>;