import iso7064 from 'iso-7064'

const FORMAT_IBAN = /^[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}$/

/**
 * Check requirements.
 * Returns if the IBAN check digits are valid.
 *
 * If `validateBBAN === true`, validate BBAN before the check digits.
 * See method `validateBBAN(rawValue, rawCountryCode)` for more information.
 * Default value is `false`.
 *
 * Requirements:
 * - rawValue must be not `Null`
 * - rawValue must be of type `String`
 * - rawValue must respect format `^[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}$`
 *
 * @param {*} rawValue
 */
const validateIBAN = (rawValue: string) => {
  const value = rawValue

  // Validate global IBAN format
  if (!value.match(FORMAT_IBAN)) {
    return false
  }

  const countryCode = value.substring(0, 2)
  const bban = value.substring(4, value.length)

  if (!(countryCode in FORMAT_BBAN)) {
    return false
  }

  const formatForCountry = FORMAT_BBAN[countryCode as keyof typeof FORMAT_BBAN]
  if (!formatForCountry.validator.test(bban)) {
    return false
  }

  return iso7064.computeWithoutCheck(value.substring(4, value.length) + value.substring(0, 4)) === 1
}

const FORMAT_BBAN = {
  AD: { format: [4, 4, 12], validator: /^[0-9]{8}[0-9A-Z]{12}$/ },
  AE: { format: [3, 16], validator: /^[0-9]{19}$/ },
  AL: { format: [8, 16], validator: /^[0-9]{8}[0-9A-Z]{16}$/ },
  AT: { format: [5, 11], validator: /^[0-9]{16}$/ },
  AZ: { format: [4, 20], validator: /^[A-Z]{4}[0-9A-Z]{20}$/ },
  BA: { format: [3, 3, 8, 2], validator: /^[0-9]{16}$/ },
  BE: { format: [3, 7, 2], validator: /^[0-9]{12}$/ },
  BG: { format: [4, 4, 2, 8], validator: /^[A-Z]{4}[0-9]{6}[0-9A-Z]{8}$/ },
  BH: { format: [4, 14], validator: /^[A-Z]{4}[0-9A-Z]{14}$/ },
  BI: { format: [5, 5, 11, 2], validator: /^[0-9]{23}$/ },
  BR: { format: [8, 5, 10, 1, 1], validator: /^[0-9]{23}[A-Z]{1}[0-9A-Z]{1}$/ },
  BY: { format: [4, 4, 16], validator: /^[0-9A-Z]{4}[0-9]{4}[0-9A-Z]{16}$/ },
  CH: { format: [5, 12], validator: /^[0-9]{5}[0-9A-Z]{12}$/ },
  CR: { format: [4, 14], validator: /^[0-9]{18}$/ },
  CY: { format: [3, 5, 16], validator: /^[0-9]{8}[0-9A-Z]{16}$/ },
  CZ: { format: [4, 6, 10], validator: /^[0-9]{20}$/ },
  DE: { format: [8, 10], validator: /^[0-9]{18}$/ },
  DJ: { format: [5, 5, 11, 2], validator: /^[0-9]{23}$/ },
  DK: { format: [4, 9, 1], validator: /^[0-9]{14}$/ },
  DO: { format: [4, 20], validator: /^[0-9A-Z]{4}[0-9]{20}$/ },
  EE: { format: [2, 2, 11, 1], validator: /^[0-9]{16}$/ },
  EG: { format: [4, 4, 17], validator: /^[0-9]{25}$/ },
  ES: { format: [4, 4, 1, 1, 10], validator: /^[0-9]{20}$/ },
  FI: { format: [3, 11], validator: /^[0-9]{14}$/ },
  FO: { format: [4, 9, 1], validator: /^[0-9]{14}$/ },
  FR: { format: [5, 5, 11, 2], validator: /^[0-9]{10}[0-9A-Z]{11}[0-9]{2}$/ },
  GB: { format: [4, 6, 8], validator: /^[A-Z]{4}[0-9]{14}$/ },
  GE: { format: [2, 16], validator: /^[A-Z]{2}[0-9]{16}$/ },
  GI: { format: [4, 15], validator: /^[A-Z]{4}[0-9A-Z]{15}$/ },
  GL: { format: [4, 9, 1], validator: /^[0-9]{14}$/ },
  GR: { format: [3, 4, 16], validator: /^[0-9]{7}[0-9A-Z]{16}$/ },
  GT: { format: [4, 20], validator: /^[0-9A-Z]{24}$/ },
  HR: { format: [7, 10], validator: /^[0-9]{17}$/ },
  HU: { format: [3, 4, 1, 15, 1], validator: /^[0-9]{24}$/ },
  IE: { format: [4, 6, 8], validator: /^[A-Z]{4}[0-9]{14}$/ },
  IL: { format: [3, 3, 13], validator: /^[0-9]{19}$/ },
  IQ: { format: [4, 3, 12], validator: /^[A-Z]{4}[0-9]{15}$/ },
  IS: { format: [4, 2, 6, 10], validator: /^[0-9]{22}$/ },
  IT: { format: [1, 5, 5, 12], validator: /^[A-Z]{1}[0-9]{10}[0-9A-Z]{12}$/ },
  JO: { format: [4, 4, 18], validator: /^[A-Z]{4}[0-9]{4}[0-9A-Z]{18}$/ },
  KW: { format: [4, 22], validator: /^[A-Z]{4}[0-9A-Z]{22}$/ },
  KZ: { format: [3, 13], validator: /^[0-9]{3}[0-9A-Z]{13}$/ },
  LB: { format: [4, 20], validator: /^[0-9]{4}[0-9A-Z]{20}$/ },
  LC: { format: [4, 24], validator: /^[A-Z]{4}[0-9A-Z]{24}$/ },
  LI: { format: [5, 12], validator: /^[0-9]{5}[0-9A-Z]{12}$/ },
  LT: { format: [5, 11], validator: /^[0-9]{16}$/ },
  LU: { format: [3, 13], validator: /^[0-9]{3}[0-9A-Z]{13}$/ },
  LV: { format: [4, 13], validator: /^[A-Z]{4}[0-9A-Z]{13}$/ },
  LY: { format: [3, 3, 15], validator: /^[0-9]{21}$/ },
  MC: { format: [5, 5, 11, 2], validator: /^[0-9]{10}[0-9A-Z]{11}[0-9]{2}$/ },
  MD: { format: [2, 18], validator: /^[0-9A-Z]{20}$/ },
  ME: { format: [3, 13, 2], validator: /^[0-9]{18}$/ },
  MK: { format: [3, 10, 2], validator: /^[0-9]{3}[0-9A-Z]{10}[0-9]{2}$/ },
  MR: { format: [5, 5, 11, 2], validator: /^[0-9]{23}$/ },
  MT: { format: [4, 5, 18], validator: /^[A-Z]{4}[0-9]{5}[0-9A-Z]{18}$/ },
  MU: { format: [4, 2, 2, 12, 3, 3], validator: /^[A-Z]{4}[0-9]{19}[A-Z]{3}$/ },
  NL: { format: [4, 10], validator: /^[A-Z]{4}[0-9]{10}$/ },
  NO: { format: [4, 6, 1], validator: /^[0-9]{11}$/ },
  PK: { format: [4, 16], validator: /^[A-Z]{4}[0-9A-Z]{16}$/ },
  PL: { format: [8, 16], validator: /^[0-9]{24}$/ },
  PS: { format: [4, 21], validator: /^[A-Z]{4}[0-9A-Z]{21}$/ },
  PT: { format: [4, 4, 11, 2], validator: /^[0-9]{21}$/ },
  QA: { format: [4, 21], validator: /^[A-Z]{4}[0-9A-Z]{21}$/ },
  RO: { format: [4, 16], validator: /^[A-Z]{4}[0-9A-Z]{16}$/ },
  RS: { format: [3, 13, 2], validator: /^[0-9]{18}$/ },
  RU: { format: [9, 5, 15], validator: /^[0-9]{14}[0-9A-Z]{15}$/ },
  SA: { format: [2, 18], validator: /^[0-9]{2}[0-9A-Z]{18}$/ },
  SC: { format: [4, 2, 2, 16, 3], validator: /^[A-Z]{4}[0-9]{20}[A-Z]{3}$/ },
  SD: { format: [2, 12], validator: /^[0-9]{14}$/ },
  SE: { format: [3, 16, 1], validator: /^[0-9]{20}$/ },
  SI: { format: [5, 8, 2], validator: /^[0-9]{15}$/ },
  SK: { format: [4, 6, 10], validator: /^[0-9]{20}$/ },
  SM: { format: [1, 5, 5, 12], validator: /^[A-Z]{1}[0-9]{10}[0-9A-Z]{12}$/ },
  ST: { format: [4, 4, 11, 2], validator: /^[0-9]{21}$/ },
  SV: { format: [4, 20], validator: /^[A-Z]{4}[0-9]{20}$/ },
  TL: { format: [3, 14, 2], validator: /^[0-9]{19}$/ },
  TN: { format: [2, 3, 13, 2], validator: /^[0-9]{20}$/ },
  TR: { format: [5, 1, 16], validator: /^[0-9]{6}[0-9A-Z]{16}$/ },
  UA: { format: [6, 19], validator: /^[0-9]{6}[0-9A-Z]{19}$/ },
  VA: { format: [3, 15], validator: /^[0-9]{18}$/ },
  VG: { format: [4, 16], validator: /^[A-Z]{4}[0-9]{16}$/ },
  XK: { format: [4, 10, 2], validator: /^[0-9]{16}$/ },
}

export default validateIBAN
