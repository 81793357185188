import dayjs from 'dayjs'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useUser } from '@/contexts/user/User.context'
import { setToLocalStorage } from '@/helpers/localstorage'
import { trackEvent } from '@/util/eventTracker'
import { useGetVisibleActiveCommercialEventsQuery } from '@/views/Show/operations.generated'

import type { GetVisibleActiveCommercialEventsQuery } from '@/views/Show/operations.generated'
import type { SwiperRef } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'

import './CommercialEvents.scss'

export const CommercialEvents = () => {
  const { user } = useUser()

  if (!user) return null

  const { data } = useGetVisibleActiveCommercialEventsQuery({ variables: { first: 10, sellerId: user?.id } })

  const visibleActiveCommercialEvents = data?.visibleActiveCommercialEventsForSellerStudio

  const commercialEvents = visibleActiveCommercialEvents?.edges?.map((edge) => edge.node) ?? []

  const ref = useRef<SwiperRef | null>(null)

  const handleNext = () => {
    ref?.current?.swiper.slideNext()
  }

  const handlePrev = () => {
    ref?.current?.swiper.slidePrev()
  }

  if (!commercialEvents.length) return null

  return (
    <div className="commercial-events-container">
      {commercialEvents.length > 1 && (
        <div className="commercial-events-previous-buttton">
          <FaChevronCircleLeft size={24} onClick={handlePrev} />
        </div>
      )}

      <Swiper
        ref={ref}
        breakpointsBase="container"
        className="mySwiper"
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={12}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: `.next-button`,
          prevEl: `.prev-button`,
        }}
        pagination={{
          clickable: true,
        }}
        loop
      >
        {commercialEvents.map((event) => (
          <SwiperSlide key={event.id}>
            <Slide banner={event} />
          </SwiperSlide>
        ))}
      </Swiper>
      {commercialEvents.length > 1 && (
        <div className="commercial-events-next-buttton">
          <FaChevronCircleRight size={24} onClick={handleNext} />
        </div>
      )}
    </div>
  )
}

const Slide = ({
  banner,
}: {
  banner: Extract<
    GetVisibleActiveCommercialEventsQuery['visibleActiveCommercialEventsForSellerStudio']['edges'][0]['node'],
    { __typename?: 'CommercialEvent' }
  >
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateShow = async () => {
    await setToLocalStorage('sellerCreatingShowEvent', 'true')
    trackEvent('COMMERCIAL_EVENT_BANNER_CLICK', { commercialEventId: banner.id })

    navigate('/shows?action=create')
  }
  return (
    <div key={`carousel-img-${banner.largeBannerWebPUrl}`} className="commercial-events-item">
      <div className="commercial-events-item-image">
        {banner.largeBannerWebPUrl && (
          <a onClick={handleCreateShow}>
            <img
              alt={banner.bannerWebPUrl || 'commercial-event'}
              className="commercial-events-img"
              src={banner.largeBannerWebPUrl}
            />
          </a>
        )}
      </div>
      <div className="commercial-events-item-content">
        <div className="commercial-events-item-details">
          <span className="commercial-events-item-dates">
            {dayjs(banner.startAt).format('DD/MM/YYYY')} -{dayjs(banner.endAt).format('DD/MM/YYYY')}
          </span>
          <p className="commercial-events-item-description">{banner.description}</p>
        </div>
        <div className="commercial-events-item-link">
          <a onClick={handleCreateShow}>{t('commercialEventBannerButton')}</a>
        </div>
      </div>
    </div>
  )
}
