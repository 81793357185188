import style from './style.module.scss'

interface HeaderProps {
  title?: string
  extra?: any
  isSmall?: boolean
  isHeaderCentered?: boolean
}

const Header = ({ title, extra, isSmall, isHeaderCentered }: HeaderProps) => {
  if (!title && !extra) return null

  return (
    <div
      className={`${style.custom_card__header} ${isSmall && style.custom_card__header_small} ${
        isHeaderCentered && style.custom_card__header_centered
      }`}
    >
      <div className={`${style.custom_card__header_title} callout_4`}>{title}</div>
      {extra}
    </div>
  )
}

export default Header
