const LearningIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3591 14.7904C10.848 15.0225 11.3769 15.1545 11.92 15.153C12.4536 15.153 12.9862 15.0321 13.4819 14.7903C14.1531 14.463 14.8254 14.1374 15.4976 13.8118C16.7547 13.203 18.012 12.5941 19.263 11.9733C19.3862 11.9121 19.5308 12.0023 19.53 12.1399L19.5151 14.579C19.5123 14.9611 19.8206 15.2731 20.2028 15.2759H20.2074C20.5877 15.2759 20.8969 14.9685 20.8997 14.5882L20.9302 9.59439C20.9305 9.54589 20.9258 9.49852 20.9167 9.45277C20.8647 8.55208 20.34 7.7566 19.5197 7.35216L13.4809 4.40016C12.4896 3.91277 11.3533 3.9137 10.3637 4.39924L4.33325 7.35124C3.46648 7.77124 2.92925 8.63154 2.93018 9.59524C2.93018 10.5589 3.46833 11.4174 4.33141 11.8355L6.44341 12.8703C6.46833 12.8869 6.4951 12.9026 6.52372 12.9155L10.3591 14.7904Z"
        fill="black"
      />
      <path
        d="M13.9871 15.8272C13.3308 16.1484 12.6265 16.3081 11.9204 16.3081C11.2008 16.3081 10.4944 16.1472 9.85267 15.8262L6.63185 14.253C6.50921 14.1931 6.36621 14.2825 6.36621 14.4189V16.8821C6.36621 17.8763 6.94867 18.7606 7.85144 19.1372C9.19544 19.6883 10.5413 19.9652 11.8779 19.9652C13.2154 19.9652 14.5465 19.6883 15.8582 19.1354C16.7527 18.7597 17.3305 17.8828 17.3305 16.9006V14.4925C17.3305 14.356 17.1876 14.2668 17.065 14.3265L13.9871 15.8272Z"
        fill="black"
      />
    </svg>
  )
}

export default LearningIcon
