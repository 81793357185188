import { useTranslation } from 'react-i18next'

import Empty from '@/components/Empty/Empty'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import User from '@/components/ui/User/User'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import { useGetBestCustomersQuery } from '../../Shipments/operations.generated'

const BestCustomersCard = () => {
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()
  const { data, loading } = useGetBestCustomersQuery({ fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-only' })

  const customers = data?.viewer?.bestCustomers.edges

  return (
    <Card subtitle={t('widgetBestCustomersNotice')} title={t('widgetBestCustomersTitle')}>
      <div className="flex flex-col max-h-80 overflow-auto">
        {loading && <Loader />}

        {customers?.length === 0 ? (
          <Empty description={t('widgetBestCustomersEmpty')} type={'files'} />
        ) : (
          <div className="flex flex-col">
            {customers?.map((customer, index) => (
              <div key={customer.node.id} className="flex items-center justify-between py-2">
                <div className="flex items-center gap-4">
                  <div className="w-4">{index + 1}</div>

                  <User src={customer.node.avatarWebPUrl} isSmall>
                    {customer.node.username}
                  </User>
                </div>

                {customer.node.sellerTotalRevenueInCents && (
                  <div className="font-semibold">
                    {formatCurrency(
                      customer.node.sellerTotalRevenueInCents.amount,
                      customer.node.sellerTotalRevenueInCents.currency
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  )
}

export default BestCustomersCard
