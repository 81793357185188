import { useSSO } from '@bits-app/react-sso'
import { useCallback } from 'react'
import { FaFacebook } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'

const { VITE_APP_FACEBOOK_SSO_APP_ID } = import.meta.env

type FacebookSSOLoginButtonProps = {
  onClick: (response: any) => void
}

const FacebookSSOLoginButton = (props: FacebookSSOLoginButtonProps) => {
  const { onClick } = props

  const { signInWithFacebook } = useSSO()
  const handleSignInWithFacebook = useCallback(async () => {
    try {
      const options = { clientId: VITE_APP_FACEBOOK_SSO_APP_ID! }
      const response = await signInWithFacebook(options)
      return onClick(response)
    } catch (e: any) {
      // Nothing here for the moment
    }
  }, [signInWithFacebook])

  return (
    <Button
      className="sso-login-action facebook-sso-login-action"
      icon={<FaFacebook />}
      onClick={handleSignInWithFacebook}
    />
  )
}

export default FacebookSSOLoginButton
