const ShieldIcon = () => {
  return (
    <svg fill="none" height="73" viewBox="0 0 72 73" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M59.6103 26.12C59.5503 29.03 59.4603 32.99 59.4603 38.51C59.4603 45.26 56.6403 50.51 52.9803 54.5C51.8403 55.73 50.6403 56.84 49.4103 57.8C43.2603 62.78 36.5703 64.88 36.4503 64.91C36.3003 64.97 36.1503 65 36.0003 65C35.8503 65 35.7003 64.97 35.5503 64.91C35.4303 64.88 28.6802 62.78 22.5302 57.77C21.3002 56.78 20.1002 55.67 18.9902 54.44C15.3302 50.48 12.5402 45.23 12.5402 38.51C12.5402 32.99 12.4502 29 12.3902 26.09C12.2702 18.89 12.2402 17.57 13.9202 15.89C16.0502 13.79 32.9403 8 36.0003 8C39.0603 8 55.9503 13.79 58.0803 15.89C59.7603 17.6 59.7303 18.89 59.6103 26.12Z"
        fill="black"
        fillRule="evenodd"
        opacity="0.4"
      />
      <path
        clipRule="evenodd"
        d="M52.9803 54.4998C51.8403 55.7298 50.6403 56.8398 49.4103 57.7998C48.6603 55.1598 45.9003 50.5098 35.9403 50.5098C25.9802 50.5098 23.2502 55.1598 22.5302 57.7698C21.3002 56.7798 20.1002 55.6698 18.9902 54.4398C21.1502 49.8798 26.4002 46.0098 35.9403 46.0098C45.5403 46.0098 50.7903 49.9098 52.9803 54.4998Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M35.9403 38.2718C32.4903 38.2718 29.6404 35.4518 29.6404 32.0018C29.6404 28.5219 32.4903 25.6719 35.9403 25.6719C39.4203 25.6719 42.2403 28.5219 42.2403 32.0018C42.2403 35.4518 39.4203 38.2718 35.9403 38.2718ZM35.9403 21.1719C30.0003 21.1719 25.1404 26.0319 25.1404 32.0018C25.1404 37.9418 30.0003 42.7718 35.9403 42.7718C41.9103 42.7718 46.7403 37.9418 46.7403 32.0018C46.7403 26.0319 41.9103 21.1719 35.9403 21.1719Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ShieldIcon
