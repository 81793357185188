import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import ArrowCell from '@/components/ArrowCell/ArrowCell'
import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'
import Button from '@/components/ui/Button/Button'
import ParamsIcon from '@/components/ui/Icons/ParamsIcon/ParamsIcon'
import Popover from '@/components/ui/Popover/Popover'

import { ChatFontSize } from './ChatFontSize'
import { ChatJoinItemsSettings } from './ChatJoinItemSettings'

import './ChatSettings.scss'

const enum STEPS {
  menu = 'menu',
  font = 'font',
}

export const ChatSettings = memo(() => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.menu)

  const handleGoBack = useCallback(() => {
    setCurrentStep(STEPS.menu)
  }, [])
  const handleGoToFont = useCallback(() => {
    setCurrentStep(STEPS.font)
  }, [])

  const handleClose = useCallback(() => {
    setCurrentStep(STEPS.menu)
    setIsOpen(false)
  }, [])

  const handleToggle = useCallback(() => {
    const value = !isOpen
    setIsOpen(value)
    if (!value) {
      setCurrentStep(STEPS.menu)
    }
  }, [isOpen])

  return (
    <NewFeatureIntroducer
      alreadySeenKey="hasSeenJoinedSetting"
      content={<p>{t('chatSettingDisplayJoinedIntroducer')}</p>}
    >
      <Popover
        className="chat-settings-popover"
        classNameContent="chat-settings-popover-content"
        hideOnClickOutside={false}
        hideOnMouseOut={false}
        isOpen={isOpen}
        openOnMouseHover={false}
        positions={['bottom', 'left']}
        content={
          <>
            {currentStep === STEPS.menu && (
              <div className="chat-settings">
                <div className="chat-settings-title">
                  <h2>{t('chatSettingsTitle')}</h2>
                  <Button className="chat-settings-close" icon={<FaXmark />} onClick={handleClose} />
                </div>
                <ArrowCell title={t('chatSettingsFontTitle')} onClick={handleGoToFont} />
                <ChatJoinItemsSettings />
              </div>
            )}
            {currentStep === STEPS.font && <ChatFontSize handleClose={handleClose} handleGoBack={handleGoBack} />}
          </>
        }
      >
        <Button className="chat-font-size-action" icon={<ParamsIcon />} onClick={handleToggle} />
      </Popover>
    </NewFeatureIntroducer>
  )
})
