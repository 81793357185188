import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { computeGenderFieldOptions } from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

type ProductGenderFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductGenderField = (props: ProductGenderFieldProps) => {
  const { value: gender, onChange: handleGenderChange, disabled = false } = props
  const { t } = useTranslation()

  const genderOptions = useMemo(() => computeGenderFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormGenderLabel')}
      name="gender"
      options={genderOptions}
      placeholder={t('productFormGenderPlaceholder')}
      type="select"
      value={gender}
      onChange={handleGenderChange}
    />
  )
}

export default ProductGenderField
