import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { computeSellingTypeOptions } from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

type SalesTypeFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

const SalesTypeField = (props: SalesTypeFieldProps) => {
  const { value: salesType, onChange: handleSalesTypeChange } = props
  const { t } = useTranslation()

  const salesTypeOptions = useMemo(() => computeSellingTypeOptions(t), [t])

  return (
    <Field
      label={t('salesTypeLabel')}
      name="sales-type"
      options={salesTypeOptions}
      type="select"
      value={salesType}
      onChange={handleSalesTypeChange}
    />
  )
}

export default SalesTypeField
