import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Link from '@/components/ui/Link/Link'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'

import {
  useApplyForFeaturedShowMutation,
  useGetFeaturedShowConceptsForParentCategoryQuery,
} from '../../operations.generated'

import type { FeaturedShowApplicationType } from '@/network/graphql/types.generated'

type FeaturedShowConceptProps = {
  showParentCategoryGlobalId: string
  showId: string
  showCategorySlug: string
  handleNextSlide: () => void
  featuredShowTargetInEuros: number
  featuredShowApplicationType: FeaturedShowApplicationType
}

const FeaturedShowConcept = ({
  showParentCategoryGlobalId,
  showId,
  showCategorySlug,
  handleNextSlide,
  featuredShowTargetInEuros,
  featuredShowApplicationType,
}: FeaturedShowConceptProps) => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false)

  const [checkedConcepts, setCheckedConcepts] = useState<string[]>([])

  const canSelectMultipleConcepts = showCategorySlug === 'pokemon-cards' || showCategorySlug === 'sports'

  const { data } = useGetFeaturedShowConceptsForParentCategoryQuery({
    variables: { parentCategoryId: showParentCategoryGlobalId },
    onError: (error) => notificationDanger(error?.message),
  })

  const [applyForFeaturedShowMutation] = useApplyForFeaturedShowMutation()

  const concepts = data?.featuredShowConceptsForParentCategory || []

  const handleConceptsChange = (event: any) => {
    const { name, checked } = event.target
    if (canSelectMultipleConcepts) {
      setCheckedConcepts((prev) => (checked ? [...prev, name] : prev.filter((concept) => concept !== name)))
    } else {
      setCheckedConcepts([name])
    }
  }

  const handleGMVCheckboxChange = useCallback((event) => {
    setIsChecked(event.target.checked)
  }, [])

  const handleSubmit = async () => {
    if (!(checkedConcepts.length > 0 && isChecked)) return

    await applyForFeaturedShowMutation({
      variables: {
        input: {
          showId: showId,
          concepts: checkedConcepts,
          applicationType: featuredShowApplicationType,
        },
      },
      onError: (error) => notificationDanger(error?.message),
      onCompleted: () => notificationSuccess(t('showEventApplicationSuccessMessage')),
    })
    handleNextSlide()
  }

  return (
    <div className="featured-show-concept">
      <div className="featured-show-concept__container">
        <p className="featured-show-concept__title">{t('showEventApplicationConceptSelectionTitle')}</p>
        <div className="featured-show-concept__concepts-container">
          {concepts.map((concept) => (
            <div key={concept} className="featured-show-concept__concept-item">
              <Field
                checked={checkedConcepts.includes(concept)}
                className="featured-show-concept__field-content"
                label={t(`${concept}ShowEventApplicationConcept`)}
                name={concept}
                type="checkbox"
                onChange={handleConceptsChange}
              />
            </div>
          ))}
          {!canSelectMultipleConcepts && (
            <Alert className="featured-show-concept__concept-alert" emphasis="low" type="info">
              {t('showEventApplicationMultipleConceptsSelectionWarning')}
            </Alert>
          )}
        </div>
        {checkedConcepts.length > 0 && (
          <div className="featured-show-concept__gmv-container">
            <Field
              checked={isChecked}
              className="featured-show-concept__gmv-field"
              label={t('showEventApplicationGmvGoalAcknowledgement', { gmvTarget: featuredShowTargetInEuros })}
              name="gmv-approval-checkbox"
              type="checkbox"
              onChange={handleGMVCheckboxChange}
            />
            <Alert className="featured-show-concept__gmv-alert" emphasis="high" type="warning">
              <p>
                {t('showEventApplicationGmvTargetNotMetWarning')}
                <Link
                  className="featured-show-concept__link"
                  href={t('showEventApplicationLearnMoreLink')}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('commonTextLearnMore')}
                </Link>
              </p>
            </Alert>
          </div>
        )}
        <div className="featured-show-concept__button-container">
          <Button className="primary featured-show-concept__button" disabled={!isChecked} onClick={handleSubmit}>
            {t('commonActionApply')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FeaturedShowConcept
