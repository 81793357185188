import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/reducers'

import CopyableText from '../../../../components/CopyableText'
import Card from '../../../../components/ui/Card/Card'

import { useGetSellerStreamingConfigQuery } from './operations.generated'

import style from './style.module.scss'

const StreamConfig = () => {
  const { t } = useTranslation()

  const user = useAppSelector((state) => state.authentication.user)

  const { data } = useGetSellerStreamingConfigQuery({ skip: !user })

  if (!user) return null

  const streamingConfigs = data?.viewer?.sellerConfig?.streamingConfigs
  if (!streamingConfigs?.length) return null

  const canStreamAbove720p = user.sellerConfig?.canStreamAbove720p || false

  return (
    <Card title={t('homeChanelTitle')}>
      {streamingConfigs.map((streamingConfig) => (
        <div key={streamingConfig.url} className="mb-4">
          <span className={`${style.stream_config__title} body_normal`}>{streamingConfig.name}</span>
          <div className="w-full">
            <CopyableText label="URL" value={streamingConfig.url} />
            <CopyableText label="Key" value={streamingConfig.streamKey} secret />
          </div>
        </div>
      ))}
      {!canStreamAbove720p && (
        <div className="w-full">
          <Typography.Text className="flex items-center gap-1 text-xs" type="secondary">
            {t('showTipStreamingEqualsOrAbove720p')}
          </Typography.Text>
        </div>
      )}
    </Card>
  )
}

export default StreamConfig
