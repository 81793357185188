import {
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'

import { computeMostUsedCurrency, useCurrencyFormatter } from '@/helpers/currencyFormatter'

import { CHART_PLUGINS_OPTIONS, CHART_TOOLTIP_OPTIONS, LINE_CHART_OPTIONS } from './options'

import type { ShowItemFragment } from '../../Shows/operations.generated'
import type { Currency } from '@/network/graphql/types.generated'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
defaults.font.family = 'Gantari-Regular'
defaults.font.weight = '600'

const RevenuesNetPerMonth = ({ shows }: { shows: ShowItemFragment[] }) => {
  const currencyFormatter = useCurrencyFormatter()

  const currency: Currency = useMemo(() => {
    return computeMostUsedCurrency(shows)
  }, [shows])

  const options = {
    ...LINE_CHART_OPTIONS,
    plugins: {
      ...CHART_PLUGINS_OPTIONS,
      tooltip: {
        ...CHART_TOOLTIP_OPTIONS,
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.data[context.dataIndex]
            return currencyFormatter(label * 100, currency)
          },
        },
      },
    },
  }

  const dates = new Array(12)
    .fill(0)
    .map((_, index) => dayjs(new Date()).subtract(index, 'month').toDate())
    .reverse()
  const labels = dates.map((month) => dayjs(month).format('MMM'))

  const values: number[] = useMemo(() => {
    const _amounts: number[] = []
    for (const date of dates) {
      _amounts.push(
        shows
          .filter((show) => dayjs(date).isSame(show.startAt, 'month'))
          .reduce((a, b) => a + b.totalNetIncomeInCurrencyCents / 100, 0)
      )
    }

    return _amounts
  }, [dates, shows])

  const data = {
    labels,
    datasets: [
      {
        data: values,
        dates: labels,
      },
    ],
  }

  return (
    <div className="w-full">
      <div className="w-full min-h-[250px]">
        <Line data={data} options={options} />
      </div>
    </div>
  )
}

export default RevenuesNetPerMonth
