import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'

import { useUser } from '@/contexts/user/User.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { useGetShowViewersCountQuery, useShowViewersSubscription } from '@/modules/show/graphql/operations.generated'
import LiveIndicator from '@/views/Show/components/ShowInfos/LiveIndicator/LiveIndicator'
import { useLiveShowMetrics } from '@/views/Shows/hooks/use-live-show-metrics'

import type { ShowViewersSubscription } from '@/modules/show/graphql/operations.generated'
import type { OnDataOptions } from '@apollo/client'

dayjs.extend(LocalizedFormat)

export interface LiveShowCardProps {
  showId: number
}
export const LiveShowCard = ({ showId }: LiveShowCardProps) => {
  const currencyFormatter = useCurrencyFormatter()
  const { t, i18n } = useTranslation()

  const [presentUsersCount, setPresentUsersCount] = useState<number>(0)
  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics } = sellerConfig || {}

  const { show } = useLiveShowMetrics({ showId })
  const { data: dataViewers } = useGetShowViewersCountQuery({
    skip: !showId,
    variables: { nodeId: `Show|${showId}` },
    pollInterval: 60000,
  })

  const onShowViewerSubscriptionData = useCallback(
    (event: OnDataOptions<ShowViewersSubscription>) => {
      if (event.data.data?.showPresentUsersChanged) {
        setPresentUsersCount(event.data.data.showPresentUsersChanged.showPresentUsersTotalCount)
      }
    },
    [presentUsersCount]
  )

  useShowViewersSubscription({
    skip: !showId,
    variables: { showId: `Show|${showId}` },
    onData: onShowViewerSubscriptionData,
  })

  useEffect(() => {
    if (dataViewers?.node?.__typename !== 'Show') return
    setPresentUsersCount(dataViewers.node.presentUsers.totalCount)
  }, [dataViewers])

  if (!show) return null

  const {
    isBroadcasting,
    name,
    startAt,
    orderedProducts,
    customers,
    totalAmountOrdersSuccessInCurrencyCents,
    currency,
  } = show
  const isUpcoming = dayjs(startAt).isAfter(new Date())
  const startAsDateWithLocale = dayjs(startAt).locale(i18n.resolvedLanguage || '')
  const formattedStartDate = isUpcoming ? startAsDateWithLocale.format('LLLL') : startAsDateWithLocale.format('LT')

  return (
    <NavLink className="w-full flex-1 text-white hover:text-white" to={`/shows/${showId}`}>
      <div className="w-full p-4 bg-slate-800 rounded-md flex gap-6 justify-center lg:justify-between cursor-pointer">
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <LiveIndicator isLive={isBroadcasting} />
            <p className="text-base font-semibold m-0">{name}</p>
          </div>

          <p className="m-0">
            {isUpcoming ? t('showStartAt') : t('showStartedAt')} {formattedStartDate}
          </p>
        </div>

        <div className="w-fit flex overflow-hidden gap-8 max-sm:hidden">
          <div className="flex flex-col gap-2">
            <p className="m-0 font-semibold">{t('showStatisticOrdersTitle')}</p>
            <p className="m-0">{orderedProducts.totalCount ?? 0}</p>
          </div>

          <div className="flex flex-col gap-2">
            <p className="m-0 font-semibold">{t('showStatisticViewersTitle')}</p>
            <p className="m-0">{presentUsersCount}</p>
          </div>

          <div className="flex flex-col gap-2">
            <p className="m-0 font-semibold">{t('showStatisticCustomersTitle')}</p>
            <p className="m-0">{customers.totalCount ?? 0}</p>
          </div>

          {canAccessHomeMetrics && (
            <div className="flex flex-col gap-2">
              <p className="m-0 font-semibold">{t('showStatisticTotalTitle')}</p>
              <p className="m-0">{currencyFormatter(totalAmountOrdersSuccessInCurrencyCents, currency)}</p>
            </div>
          )}
        </div>

        <div className="text-white right-4 flex items-center">
          <FaCaretRight size={12} />
        </div>
      </div>
    </NavLink>
  )
}
