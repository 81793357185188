import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'

import ShipmentProvider from './ShipmentProvider'
import { ShipmentTabs } from './ShipmentTabs/ShipmentTabs'

import './Shipments.scss'

export const Shipments = () => {
  const { t } = useTranslation()
  useDocumentTitle(t('sideMenuOrdersSubmenuTitle'))

  return (
    <ShipmentProvider>
      <ViewContainer
        id="shipments"
        leftContent={t('sideMenuOrdersSubmenuTitle')}
        rightContent={
          <Button className="secondary" href="/export">
            {t('sideMenuOrdersExportTitle')}
          </Button>
        }
      >
        <ShipmentTabs />
      </ViewContainer>
    </ShipmentProvider>
  )
}
