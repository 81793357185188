import { useEffect, useState } from 'react'

const useDocumentTitle = (title: string) => {
  const [documentTitle, setDocumentTitle] = useState(title)

  useEffect(() => {
    document.title = `${title} | Seller Studio`
  })

  return [documentTitle, setDocumentTitle]
}

export { useDocumentTitle }
