import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { trackEvent } from '@/util/eventTracker'

import CompanyForm from './CompanyForm'
import IndividualForm from './IndividualForm'

type DocumentSlideProps = {
  individualInformation: IndividualInformation
  setIndividualInformation: (information: IndividualInformation) => void
  companyInformation: CompanyInformation
  setCompanyInformation: (information: CompanyInformation) => void
  setCurrent: (index: number) => void
  onDocumentSubmit: () => void
  isSubmitLoading: boolean
  isProfessional: boolean
  current: number
}

function DocumentsSlide(props: DocumentSlideProps) {
  const { setCurrent, onDocumentSubmit, isSubmitLoading, current } = props
  const { t } = useTranslation()

  useEffect(() => {
    trackEvent('SELLER_ONBOARDING_DOCUMENTS_OPENED')
  }, [])

  const [formDisabled, setFormDisabled] = useState(true)

  const childrenProps = { ...props, setFormDisabled }

  return (
    <>
      <div>{props.isProfessional ? <CompanyForm {...childrenProps} /> : <IndividualForm {...childrenProps} />}</div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
        }}
      >
        <Button
          className="previous-step-action secondary"
          label={t('commonPrevious')}
          onClick={() => setCurrent(current - 1)}
        />
        <Button
          className="next-step-action primary"
          disabled={formDisabled}
          htmlType="button"
          isLoading={isSubmitLoading}
          label={t('signupFormValidateButton')}
          onClick={onDocumentSubmit}
        />
      </div>
    </>
  )
}

export default DocumentsSlide
