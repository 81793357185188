import * as Types from '../../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerBankingInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerBankingInfoQuery = { __typename?: 'Query', sellerInfo: { __typename?: 'SellerInfo', sellerBankingInfo: { __typename?: 'SellerBankingInfo', ibanFourLastDigits: string, bic?: string | null, bankAccountName?: string | null } } };

export type UpsertSellerBankingInfoMutationVariables = Types.Exact<{
  phoneVerificationToken: Types.Scalars['String']['input'];
  input: Types.UpsertSellerBankingInfoInput;
}>;


export type UpsertSellerBankingInfoMutation = { __typename?: 'Mutation', upsertSellerBankingInfo: { __typename?: 'UpsertSellerBankingInfoPayload', ok: boolean } };

export type TriggerSellerPhoneNumberVerificationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type TriggerSellerPhoneNumberVerificationMutation = { __typename?: 'Mutation', triggerSellerPhoneNumberVerification: { __typename?: 'TriggerSellerPhoneNumberVerificationPayload', phoneVerificationToken: string } };


export const GetSellerBankingInfoDocument = gql`
    query GetSellerBankingInfo {
  sellerInfo {
    sellerBankingInfo {
      ibanFourLastDigits
      bic
      bankAccountName
    }
  }
}
    `;

/**
 * __useGetSellerBankingInfoQuery__
 *
 * To run a query within a React component, call `useGetSellerBankingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerBankingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerBankingInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerBankingInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>(GetSellerBankingInfoDocument, options);
      }
export function useGetSellerBankingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>(GetSellerBankingInfoDocument, options);
        }
export function useGetSellerBankingInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>(GetSellerBankingInfoDocument, options);
        }
export type GetSellerBankingInfoQueryHookResult = ReturnType<typeof useGetSellerBankingInfoQuery>;
export type GetSellerBankingInfoLazyQueryHookResult = ReturnType<typeof useGetSellerBankingInfoLazyQuery>;
export type GetSellerBankingInfoSuspenseQueryHookResult = ReturnType<typeof useGetSellerBankingInfoSuspenseQuery>;
export type GetSellerBankingInfoQueryResult = Apollo.QueryResult<GetSellerBankingInfoQuery, GetSellerBankingInfoQueryVariables>;
export const UpsertSellerBankingInfoDocument = gql`
    mutation UpsertSellerBankingInfo($phoneVerificationToken: String!, $input: UpsertSellerBankingInfoInput!) {
  upsertSellerBankingInfo(
    phoneVerificationToken: $phoneVerificationToken
    input: $input
  ) {
    ok
  }
}
    `;
export type UpsertSellerBankingInfoMutationFn = Apollo.MutationFunction<UpsertSellerBankingInfoMutation, UpsertSellerBankingInfoMutationVariables>;

/**
 * __useUpsertSellerBankingInfoMutation__
 *
 * To run a mutation, you first call `useUpsertSellerBankingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSellerBankingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSellerBankingInfoMutation, { data, loading, error }] = useUpsertSellerBankingInfoMutation({
 *   variables: {
 *      phoneVerificationToken: // value for 'phoneVerificationToken'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSellerBankingInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSellerBankingInfoMutation, UpsertSellerBankingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSellerBankingInfoMutation, UpsertSellerBankingInfoMutationVariables>(UpsertSellerBankingInfoDocument, options);
      }
export type UpsertSellerBankingInfoMutationHookResult = ReturnType<typeof useUpsertSellerBankingInfoMutation>;
export type UpsertSellerBankingInfoMutationResult = Apollo.MutationResult<UpsertSellerBankingInfoMutation>;
export type UpsertSellerBankingInfoMutationOptions = Apollo.BaseMutationOptions<UpsertSellerBankingInfoMutation, UpsertSellerBankingInfoMutationVariables>;
export const TriggerSellerPhoneNumberVerificationDocument = gql`
    mutation TriggerSellerPhoneNumberVerification {
  triggerSellerPhoneNumberVerification {
    phoneVerificationToken
  }
}
    `;
export type TriggerSellerPhoneNumberVerificationMutationFn = Apollo.MutationFunction<TriggerSellerPhoneNumberVerificationMutation, TriggerSellerPhoneNumberVerificationMutationVariables>;

/**
 * __useTriggerSellerPhoneNumberVerificationMutation__
 *
 * To run a mutation, you first call `useTriggerSellerPhoneNumberVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSellerPhoneNumberVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSellerPhoneNumberVerificationMutation, { data, loading, error }] = useTriggerSellerPhoneNumberVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerSellerPhoneNumberVerificationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerSellerPhoneNumberVerificationMutation, TriggerSellerPhoneNumberVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerSellerPhoneNumberVerificationMutation, TriggerSellerPhoneNumberVerificationMutationVariables>(TriggerSellerPhoneNumberVerificationDocument, options);
      }
export type TriggerSellerPhoneNumberVerificationMutationHookResult = ReturnType<typeof useTriggerSellerPhoneNumberVerificationMutation>;
export type TriggerSellerPhoneNumberVerificationMutationResult = Apollo.MutationResult<TriggerSellerPhoneNumberVerificationMutation>;
export type TriggerSellerPhoneNumberVerificationMutationOptions = Apollo.BaseMutationOptions<TriggerSellerPhoneNumberVerificationMutation, TriggerSellerPhoneNumberVerificationMutationVariables>;