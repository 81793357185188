import { t } from 'i18next'

const getDeliveryType = (type: string | null | undefined) => {
  if (!type) return null

  const deliveryTypes = [
    { values: ['POST_OFFICE'], name: t('commonAtPostOffice') },

    { values: ['PICKUP_POINT', 'SERVICE_POINT', 'PICKUP'], name: t('commonAtPickupPoint') },

    { values: ['DROPOFF_POINT', 'PICKUP_DROPOFF', 'DROPOFF'], name: t('commonAtPickupPoint') },

    { values: ['HOME', 'HOME_DELIVERY'], name: t('commonAtHome') },

    { values: ['MAILBOX'], name: t('commonMailBox') },
  ]

  const type2 = deliveryTypes.find((deliveryType) => deliveryType.values.includes(type.toUpperCase()))?.name

  return type2
}

export default getDeliveryType
