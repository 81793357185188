import style from './style.module.scss'

export type InfoType = 'success' | 'error' | 'info' | 'news'

interface Props {
  type: InfoType
  message: React.ReactNode | string | number
  icon?: React.ReactNode
}

const InfoLabel = ({ type, message, icon }: Props) => {
  return (
    <div className={`${style.infoLabel_container} ${style[type]} callout_3`}>
      {icon && <span className={style.infoLabel_icon}>{icon}</span>}
      <span className={style.infoLabel_message}>{message}</span>
    </div>
  )
}

export default InfoLabel
