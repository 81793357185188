const HourglassIcon = () => {
  return (
    <svg fill="none" height="80" viewBox="0 0 80 80" width="80" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M46.3419 37.8706C44.8588 39.406 44.8588 40.5998 46.3019 42.0921C52.6096 48.1444 56.7757 52.1444 57.2188 56.7721C57.4927 60.2029 56.5573 63.3444 54.585 65.609C52.6896 67.7875 49.9665 69.0367 46.625 69.2306L31.6127 69.2275C28.3604 69.0367 25.6404 67.7844 23.745 65.609C21.7727 63.3414 20.8373 60.2029 21.1081 56.7721C21.1112 56.7629 21.1112 56.7537 21.1112 56.7444C21.5542 52.1383 25.7204 48.1413 31.9881 42.1321C33.4711 40.5998 33.4711 39.4029 32.0281 37.9106C25.7204 31.8583 21.5542 27.8614 21.1081 23.2306C20.8373 19.7967 21.7727 16.6614 23.745 14.3937C25.6404 12.2183 28.3604 10.9629 31.7019 10.769L46.7142 10.7721C49.9665 10.9629 52.6896 12.2152 54.585 14.3937C56.5573 16.6583 57.4927 19.7967 57.2158 23.2552C56.7757 27.8583 52.6065 31.8552 46.3419 37.8706Z"
        fill="black"
        fillRule="evenodd"
        opacity="0.4"
      />
      <path
        d="M43.8724 51.7211L43.8773 51.7261C44.6927 52.4953 45.5142 53.2738 46.1142 53.9907C47.4342 55.2368 47.8527 57.1322 47.1819 58.8184C46.5111 60.5076 44.8988 61.5999 43.0742 61.5999H35.2496C33.425 61.5999 31.8127 60.5076 31.1388 58.8184C30.4713 57.1291 30.8896 55.2368 32.0957 54.1107C32.8096 53.2738 33.6311 52.4953 34.4465 51.7261C35.8127 50.4368 37.1019 49.2184 37.588 47.8984L38.0834 46.7876H39.1604L40.3604 46.8922L40.7327 47.8984C41.2185 49.2168 42.508 50.4337 43.8724 51.7211Z"
        fill="black"
      />
    </svg>
  )
}

export default HourglassIcon
