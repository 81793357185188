import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Empty from '@/components/Empty/Empty'
import RatingStars from '@/components/RatingStars'
import Avatar from '@/components/ui/Avatar/Avatar'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { useGetSellerAllRatingsFromCustomersQuery } from '@/views/Ratings/operations.generated'
import { extractRatings } from '@/views/Ratings/Ratings'

dayjs.extend(relativeTime)

const LastReviewsCard = () => {
  const { t } = useTranslation()

  const { data, loading: loading } = useGetSellerAllRatingsFromCustomersQuery({
    variables: { first: 3 },
    onError: (error) => {
      notificationDanger(error.message)
    },
  })
  const lastReviews = extractRatings(data)

  return (
    <Card
      title={
        <div className="flex">
          {t('widgetLastReviewsTitle')}
          <Link className="ml-auto font-semibold" to="/ratings">
            {t('widgetLastReviewsSeeAll')}
          </Link>
        </div>
      }
    >
      <div className="h-64 overflow-auto">
        {loading && (
          <div className="flex items-center h-full">
            <Loader />
          </div>
        )}

        {lastReviews?.length === 0 ? (
          <div className="flex items-center h-full">
            <Empty description={t('widgetRatingsEmpty')} type="files" />
          </div>
        ) : (
          <div className="flex flex-col divide-y divide-slate-100">
            {lastReviews?.map((review) => (
              <div key={review.createdAt.toString()} className="flex flex-col ">
                <div className="flex gap-4 items-center justify-between py-2">
                  <div className="flex gap-4">
                    <Avatar src={review.customer.avatarWebPUrl}>{review.customer.username}</Avatar>

                    <div>
                      <p className="m-0 font-semibold">@{review.customer.username}</p>
                      <RatingStars rating={review.rating || 0} />
                    </div>
                  </div>

                  <p className="m-0">
                    {dayjs(review.createdAt)
                      .locale(i18next.resolvedLanguage || '')
                      .fromNow()}
                  </p>
                </div>

                {review.ratingComment && (
                  <div className="ml-12 py-2 rounded text-slate-700">{review.ratingComment}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  )
}

export default LastReviewsCard
