import { message } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { addInventoryProductPicture } from '@/actions/inventory/add-inventory-product-picture'
import { deleteInventoryProductPicture } from '@/actions/inventory/delete-inventory-product-picture'

export interface InventoryProductImage {
  id: string
  url: string
  file: File
  isDeleted: boolean
}

interface UseInventoryProductPicturesReturn {
  uploadImages: (files: File[], productId: string) => Promise<void>
  deleteImages: (fileIds: number[], productId: string) => Promise<void>
  isLoading: boolean
}

export const useInventoryProductPictures = (): UseInventoryProductPicturesReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const uploadImages = async (files: File[], inventoryProductId: string) => {
    if (!files.length) {
      return
    }

    setIsLoading(true)
    try {
      await dispatch(addInventoryProductPicture({ files, inventoryProductId }))
    } catch (e: any) {
      message.error(e.message)
    }
    setIsLoading(false)
  }

  const deleteImages = async (fileIds: number[], productId: string) => {
    if (!fileIds.length) {
      return
    }

    setIsLoading(true)
    try {
      await Promise.all(fileIds.map((fileId) => dispatch(deleteInventoryProductPicture({ productId, fileId }))))
    } catch (e: any) {
      message.error(e.message)
    }
    setIsLoading(false)
  }

  return {
    uploadImages,
    deleteImages,
    isLoading,
  }
}
