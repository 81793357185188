import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import GreenTickIcon from '@/components/ui/Icons/GreenTickIcon/GreenTickIcon'

type ShowEventApplicationEligibilityCardProps = {
  title: string
  isCompleted: boolean
}

const ShowEventApplicationEligibilityCard = ({ title, isCompleted }: ShowEventApplicationEligibilityCardProps) => {
  const { t } = useTranslation()

  return (
    <Card className="eligibility-card">
      <div className="eligibility-card__container">
        <div className="eligibility-card__content">
          <div className="eligibility-card__icon-wrapper">
            {isCompleted ? (
              <GreenTickIcon />
            ) : (
              <div className="eligibility-card__icon-container">
                <div className="eligibility-card__icon"></div>
              </div>
            )}
          </div>
          <p className="eligibility-card__title">{title}</p>
        </div>
        <div
          className={`eligibility-card__status ${isCompleted ? 'eligibility-card__status--completed' : 'eligibility-card__status--incomplete'}`}
        >
          <p className={isCompleted ? 'eligibility-card__text--completed' : 'eligibility-card__text--incomplete'}>
            {isCompleted
              ? t('showEventApplicationCriteriaEligibleTag')
              : t('showEventApplicationCriteriaIneligibleTag')}
          </p>
        </div>
      </div>
    </Card>
  )
}

export default ShowEventApplicationEligibilityCard
