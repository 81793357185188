import { Upload, type UploadFile } from 'antd'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa6'

import type { UploadChangeParam } from 'antd/lib/upload'

import './ImageUploader.scss'

interface ImageUploaderProps {
  image: string | UploadFile
  onUpload: (file: UploadFile | string) => void
}

const MAX_IMAGE_SIZE = 10000000

const OPTIMAL_PICTURE_RATIO_MIN = 1.1
const OPTIMAL_PICTURE_RATIO_MAX = 1.3

export const ImageUploader = (props: ImageUploaderProps) => {
  const { t } = useTranslation()
  const { image: imageForm, onUpload } = props

  const [image, setImage] = useState<UploadFile | undefined | string>(imageForm)
  const [warning, setWarning] = useState<string>('')
  const [error, setError] = useState<string>('')

  const onFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    setWarning('')
    setError('')
    if (info.fileList.length > 0) {
      if (!info.file.size || info.file.size > MAX_IMAGE_SIZE) {
        setImage(undefined)
        onUpload('')
        setError(t('createShowModalDetailsFormAddCoverWeightError'))
        return
      }

      const blob = info.file.originFileObj as Blob
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.addEventListener('load', (event) => {
        const _loadedImageUrl = event.target?.result

        const url = _loadedImageUrl as string
        const image = document.createElement('img')

        image.src = url

        image.addEventListener('load', () => {
          const { width, height } = image
          const ratio = height / width

          const isRatioOK = ratio > OPTIMAL_PICTURE_RATIO_MIN && ratio < OPTIMAL_PICTURE_RATIO_MAX

          if (!isRatioOK) {
            setWarning(t('createShowModalDetailsFormAddCoverRatioError'))
            return
          }
        })
      })

      setImage(info.file)
      onUpload(info.file)
    } else {
      setImage(undefined)
      onUpload('')
    }
  }

  return (
    <div className="image-uploader">
      <p className="image-title">
        {t('imageUploaderTitle')} <span className="required">*</span>
      </p>
      <Upload.Dragger
        accept=".png, .jpg, .jpeg, .heic, .heif"
        fileList={image && typeof image !== 'string' ? [image] : []}
        maxCount={1}
        name="files"
        customRequest={(e) => {
          if (image) return e.onSuccess && e.onSuccess('ok')
          else e.onError && e.onError({ name: 'ERROR_UPLOAD', message: 'Error when uploading file' })
        }}
        onChange={onFileUpload}
      >
        <div className="drag_and_drop_image">
          {image && typeof image !== 'string' && image.originFileObj ? (
            <div className="image-upload-preview-box">
              <img
                alt="cover-picture"
                className="image-upload-preview"
                src={URL.createObjectURL(image.originFileObj)}
              />
            </div>
          ) : image && typeof image === 'string' ? (
            <div className="image-upload-preview-box">
              <img alt="cover-picture" className="image-upload-preview" src={image} />
            </div>
          ) : (
            <>
              <svg fill="none" height="48" viewBox="0 0 49 48" width="49" xmlns="http://www.w3.org/2000/svg">
                <path
                  clipRule="evenodd"
                  d="M33.759 18.4647C33.5843 18.4431 33.4451 18.3068 33.4208 18.1327C32.8771 14.2418 29.7122 9.3822 23.5789 9.3822C16.9854 9.3822 14.2742 14.7537 13.7485 18.1376C13.7218 18.3093 13.5832 18.4427 13.4107 18.4639C9.0555 18.9974 6.10693 22.3349 6.10693 26.9243C6.10693 30.2695 8.07863 33.3231 11.1635 34.7188C12.646 35.3188 13.9328 35.4092 14.5679 35.4092H18.3019C18.6683 35.4092 18.9654 35.1122 18.9654 34.7457C18.9654 34.3508 18.6213 34.0475 18.2269 34.029C17.126 33.9779 16.0415 33.5429 15.2011 32.7009C13.3974 30.8991 13.3974 27.9766 15.2011 26.1748L20.3131 21.0628C20.5291 20.8468 20.7654 20.6529 21.0201 20.4831C21.1328 20.4092 21.2528 20.3575 21.3691 20.293C21.5168 20.2136 21.6589 20.125 21.814 20.0604C21.9617 19.9994 22.1168 19.9644 22.2681 19.9182C22.4048 19.8776 22.5358 19.8259 22.6761 19.7982C22.9586 19.741 23.2429 19.7151 23.5291 19.7114C23.5457 19.7114 23.5623 19.7059 23.5789 19.7059C23.5937 19.7059 23.6066 19.7096 23.6195 19.7114C23.9094 19.7133 24.1974 19.741 24.4817 19.7982C24.6201 19.8259 24.7475 19.8757 24.8823 19.9164C25.0374 19.9625 25.1943 19.9976 25.3438 20.0604C25.4934 20.1231 25.63 20.2062 25.7703 20.2837C25.894 20.3501 26.0214 20.4055 26.1395 20.4849C26.3666 20.6363 26.5752 20.8117 26.7709 21C26.7949 21.0203 26.8208 21.0351 26.8429 21.0572L31.9586 26.1729C33.7623 27.9766 33.7623 30.8972 31.9586 32.7009C31.1339 33.5274 30.0613 33.9718 28.9318 34.0257C28.5379 34.0445 28.1961 34.3484 28.1961 34.7428C28.1961 35.1109 28.4947 35.4092 28.8628 35.4092H32.5734C33.2158 35.4092 34.5137 35.3206 36.0349 34.7114C39.0792 33.3341 41.0472 30.2769 41.0472 26.9243C41.0472 22.3403 38.1061 19.0035 33.759 18.4647Z"
                  fill="black"
                  fillRule="evenodd"
                  opacity="0.4"
                />
                <path
                  clipRule="evenodd"
                  d="M28.6925 30.8249C29.047 30.8249 29.4015 30.6901 29.671 30.4187C30.2119 29.8778 30.2119 29.0027 29.671 28.4618L24.5553 23.3461C24.4279 23.2169 24.2747 23.1172 24.1049 23.047C23.935 22.9769 23.7559 22.9399 23.575 22.9399C23.5676 22.9399 23.5621 22.9436 23.5547 22.9436C23.3812 22.9473 23.2095 22.9787 23.047 23.047C22.8772 23.1172 22.7221 23.2187 22.5947 23.3479L17.4809 28.4618C16.94 29.0027 16.94 29.8778 17.4809 30.4187C18.0218 30.9596 18.8987 30.9596 19.4396 30.4187L22.1922 27.6679V37.2366C22.1922 38.0027 22.8125 38.6212 23.5769 38.6212C24.3412 38.6212 24.9615 38.0009 24.9615 37.2366V27.6679L27.7141 30.4187C27.9836 30.6901 28.3381 30.8249 28.6925 30.8249Z"
                  fill="black"
                  fillRule="evenodd"
                />
              </svg>

              <div className="image-upload-infos">
                <Trans
                  i18nKey="createShowModalDetailsFormAddCoverLabel"
                  components={{
                    1: <b></b>,
                  }}
                />
                <p className="image-upload-infos-notice">{t('createShowModalDetailsFormAddCoverWeightInfo')}</p>
              </div>
            </>
          )}
        </div>
      </Upload.Dragger>
      {image && (
        <div className="image_controls">
          <span>{typeof image === 'string' ? '' : image.name}</span>

          <span
            className="image_control delete"
            onClick={(e) => {
              e.preventDefault()
              setImage(undefined)
              onUpload('')
              setWarning('')
              setError('')
            }}
          >
            <FaTrash height={18} width={20} />
          </span>
        </div>
      )}
      {warning && <p className="image-warning">{warning}</p>}
      {error && <p className="image-error">{error}</p>}
    </div>
  )
}
