const GavelIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.79089 6.97486C1.14002 7.62573 1.14002 8.681 1.79089 9.33187L3.67651 11.2175C4.32738 11.8684 5.38266 11.8684 6.03353 11.2175C6.5168 10.7343 6.64124 10.0281 6.40688 9.42993L6.93427 8.90253L11.2055 13.8309C11.9133 14.6476 13.1654 14.6923 13.9296 13.9281C14.6938 13.1639 14.6491 11.9118 13.8324 11.204L8.90407 6.93273L9.4314 6.40541C10.0295 6.63978 10.7357 6.51533 11.219 6.03206C11.8699 5.38119 11.8699 4.32591 11.219 3.67504L9.33333 1.78942C8.68247 1.13855 7.6272 1.13855 6.97634 1.78942C6.49308 2.27268 6.36863 2.97889 6.603 3.57698L3.57845 6.60153C2.98036 6.36716 2.27416 6.49161 1.79089 6.97486Z"
        fill="#222C36"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default GavelIcon
