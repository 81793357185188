import { message } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'

import { useAcceptStripeDebitAgreementMutation } from '../operations.generated'
import StripeAutoDebitAgreementContent from '../StripeAutoDebitAgreementContent/StripeAutoDebitAgreementContent'

interface StripeAutoDebitAgreementCardProps {
  onAccepted?: () => void
}

export const StripeAutoDebitAgreementCard = (props: StripeAutoDebitAgreementCardProps) => {
  const { onAccepted = () => undefined } = props

  const { t } = useTranslation()
  const [acceptStripeDebitAgreement, { loading }] = useAcceptStripeDebitAgreementMutation()

  const handleSubmit = useCallback(async () => {
    await acceptStripeDebitAgreement({
      onCompleted: () => {
        message.success(t('sepaAgreementAccepted'))
        onAccepted()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }, [acceptStripeDebitAgreement, onAccepted, t])

  return (
    <Card className="stripe-auto-debit-agreement" title={t('sepaAcceptDebitAgreementTitle')}>
      <StripeAutoDebitAgreementContent loading={loading} onSubmit={handleSubmit} />
    </Card>
  )
}
