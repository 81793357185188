import { type ChangeEvent, type SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import Link from '@/components/ui/Link/Link'

type LoginFormProps = {
  error?: string
  isLoading?: boolean
  onSubmit: (credential: string, password: string) => void
  onForgottenPassword: () => void
  onSubAccountRequest: () => void
}

const LoginForm = (props: LoginFormProps) => {
  const { isLoading, error, onSubmit, onForgottenPassword, onSubAccountRequest } = props
  const { t } = useTranslation()

  const [credential, setCredential] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const isValid = Boolean(credential && password)

  const handleCredentialChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setCredential(value)
  }, [])

  const handlePasswordChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setPassword(value)
  }, [])

  const handleForgottenPasswordClick = useCallback((event?: SyntheticEvent) => {
    event?.preventDefault()
    onForgottenPassword()
  }, [])

  const handleSubmit = useCallback(() => {
    if (!isValid) {
      return
    }

    onSubmit(credential, password)
  }, [credential, password, onSubmit])

  return (
    <Form error={error} id="login-form" onSubmit={handleSubmit}>
      <Field
        autoComplete="username"
        id="crendential"
        label={t('loginFormCredentialLabel')}
        name="credential"
        placeholder={t('loginFormCredentialPlaceholder')}
        required={true}
        validation={false}
        onChange={handleCredentialChange}
      />
      <Field
        autoComplete="current-password"
        id="password"
        label={t('loginFormPasswordLabel')}
        name="password"
        placeholder={t('loginFormPasswordLabel')}
        required={true}
        type="password"
        validation={false}
        onChange={handlePasswordChange}
      >
        <div className="forgotten-password-action">
          <Link href="#" onClick={handleForgottenPasswordClick}>
            {t('loginResetPasswordButtonMessage')}
          </Link>
          <Link href="#" onClick={onSubAccountRequest}>
            {t('loginToSubAccountButtonMessage')}
          </Link>
        </div>
      </Field>

      <div className="actions">
        <Button
          className="validate-action login-action primary"
          disabled={!isValid}
          htmlType="submit"
          isLoading={isLoading}
          label={t('loginButtonLoginLabel')}
        />
      </div>
    </Form>
  )
}

export default LoginForm
