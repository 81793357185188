import { message } from 'antd'
import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegBell, FaCircleCheck } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { notificationSuccess } from '@/components/ui/Notification/Notification'
import Config from '@/config/config'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import {
  GetShowNotificationDocument,
  useGetShowNotificationQuery,
  useSendShowNotificationMutation,
} from '@/views/Show/components/SendShowNotification/operations.generated'
import SendShowNotificationModal from '@/views/Show/components/SendShowNotification/ShowNotificationModal/SendShowNotificationModal'

import './SendShowNotification.scss'

const { VITE_APP_LONG_TIMEOUT } = Config
interface NotificationContainerProps {
  showId?: number
}

const SendShowNotification = (props: NotificationContainerProps) => {
  const { showId } = props
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [, rerender] = useState(false)

  const showGlobalId = `Show|${showId}`
  const { data: showNotificationData } = useGetShowNotificationQuery({
    skip: !showGlobalId,
    variables: {
      nodeId: showGlobalId,
    },
  })

  const [sendShowNotificationMutation, { loading: isSendNotificationLoading }] = useSendShowNotificationMutation({
    variables: {
      input: {
        showId: showGlobalId,
      },
    },
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
    onError: (err) => {
      // TODO: We should probably display a custom error message here instead
      message.error(err.message)
      trackError(err, { meta: { showId, scope: 'NotificationContainer.sendShowNotificationMutation' } })
    },
    onCompleted: () => {
      trackEvent('SHOW_SEND_NOTIFICATION', { showId })
    },
    refetchQueries: [GetShowNotificationDocument],
  })

  const notification =
    showNotificationData?.node.__typename === 'Show' ? showNotificationData.node.nextNotificationToSend : undefined

  useEffect(() => {
    if (!notification) return

    const timeoutRef = setTimeout(() => {
      rerender((prev) => !prev)
    }, dayjs(notification.sendableAt).diff(dayjs()))

    return () => clearTimeout(timeoutRef)
  }, [notification?.sendableAt])

  const hasNextNotif = !!notification

  const handleSendNotification = useCallback(async () => {
    await sendShowNotificationMutation()
    setIsModalOpen(false)
    notificationSuccess(t('notificationSlidingPanelCatchPhraseSent'))
  }, [sendShowNotificationMutation])

  const handleCancel = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleClick = useCallback(() => {
    if (!hasNextNotif) {
      return
    }

    setIsModalOpen(true)
  }, [hasNextNotif, setIsModalOpen])

  const { title, body: notifMessage, sendableAt } = notification || {}

  const isSendable = dayjs(sendableAt).isBefore()
  const formattedSentAt = sendableAt ? dayjs(sendableAt).format('HH:mm') : ''

  const toolTipContent = hasNextNotif
    ? isSendable
      ? t('showNotificationSendableNowMessage', { hour: formattedSentAt })
      : t('showNotificationSendableAtMessage', { hour: formattedSentAt })
    : t('notificationSlidingPanelCatchPhraseSent')

  return (
    <>
      <Button
        className={`send-show-notification-action secondary`}
        disabled={!isSendable}
        tooltip={toolTipContent}
        tooltipPosition="bottom right"
        icon={
          <>
            <FaRegBell />
            {!hasNextNotif && <FaCircleCheck className="sent-marker" />}
          </>
        }
        onClick={handleClick}
      />
      {notifMessage && title && (
        <SendShowNotificationModal
          isLoading={isSendNotificationLoading}
          message={notifMessage}
          open={isModalOpen}
          success={!hasNextNotif}
          title={title}
          onCancel={handleCancel}
          onSubmit={handleSendNotification}
        />
      )}
    </>
  )
}

export default memo(SendShowNotification)
