import type { ReactNode } from 'react'

import './Card.scss'

export interface CardProps {
  title?: string | ReactNode
  subtitle?: string | ReactNode
  children: ReactNode
  noPadding?: boolean
  className?: string
  level?: 'low' | 'medium' | 'high'
}

export default function Card(props: CardProps) {
  const { children, title, subtitle, noPadding = false, className = '', level = 'low' } = props

  return (
    <div className={`card ${level} ${className}`}>
      {title && (
        <header className="header">
          <h1 className="title">{title}</h1>
          {subtitle && <p className="subtitle">{subtitle}</p>}
        </header>
      )}

      {noPadding && children}
      {!noPadding && <div className="content">{children}</div>}
    </div>
  )
}
