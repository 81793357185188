import { SellerShippingAddressForm } from './SellerShippingAddressForm/SellerShippingAddressForm'

import './ShippingPreferences.scss'

export const ShippingPreferences = () => {
  return (
    <div className="shipping-preferences">
      <SellerShippingAddressForm />
    </div>
  )
}
