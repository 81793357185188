import { useTranslation } from 'react-i18next'

import Countdown from '@/components/Countdown'
import Empty from '@/components/Empty/Empty'
import Card from '@/components/ui/Card/Card'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderPaymentStatus, ShowAuctionStatus } from '@/network/graphql/types.generated'

import type { StartAuctionMutation, AuctionFragment } from '@/views/Show/operations.generated'

import './CurrentAuction.scss'

interface AuctionProps {
  showId: string
  auctionStartData?: StartAuctionMutation | null
  currentAuction?: AuctionFragment | null
}

const CurrentAuction = (props: AuctionProps) => {
  const { auctionStartData, currentAuction } = props
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()

  const initialAuctionData = auctionStartData?.startAuctionInShow.auction

  if (!currentAuction) {
    return (
      <Card className="current-activity current-auction" title={t('showAuctionCardTitle')}>
        {!currentAuction && <Empty description={t('showAuctionCardEmpty')} type="files" />}
      </Card>
    )
  }

  const { winningUser, amountEuroCents: amount, status, serverTime, currency, orderIfAuthorized } = currentAuction
  const product = currentAuction?.product || initialAuctionData?.product
  const hasOrder = status === ShowAuctionStatus.Closed && orderIfAuthorized
  const orderId = orderIfAuthorized?.id
  const winningUserUsername = winningUser?.username
  const endAt = new Date(currentAuction?.endAt || initialAuctionData?.endAt)
  const displayedEndDate = endAt ? new Date(endAt) : new Date()
  const displayedStartDate = serverTime || new Date()

  const displayedStatus =
    status === ShowAuctionStatus.Closed
      ? hasOrder && orderId
        ? orderIfAuthorized?.paymentStatus === OrderPaymentStatus.Success
          ? 'order-success'
          : orderIfAuthorized?.paymentStatus === OrderPaymentStatus.Failed
            ? 'order-failed'
            : 'order-pending'
        : 'done'
      : status === ShowAuctionStatus.Countdown
        ? 'pending'
        : 'in-progress'
  const statusClassName = `is-${displayedStatus}`

  return (
    <Card className="current-activity current-auction" title={t('showAuctionCardTitle')}>
      <dl>
        <dt className="product-name-title">{t('showAuctionCardStatisticProductTitle')}</dt>
        <dd className="product-name">
          <span className="value">{product?.name}</span>
        </dd>

        <dt className="status-title">{t('showAuctionCardStatisticStatusTitle')}</dt>
        <dd className={`status ${statusClassName}`}>
          {displayedStatus === 'pending' && t('showCurrentActivityItemStatusPendingLabel')}
          {displayedStatus === 'in-progress' && t('showCurrentActivityItemStatusInProgressLabel')}
          {displayedStatus === 'done' && t('showCurrentActivityItemStatusDoneLabel')}
          {displayedStatus === 'order-success' && t('showAuctionStatusInSuccessPayment')}
          {displayedStatus === 'order-failed' && t('showAuctionStatusInErrorPayment')}
          {displayedStatus === 'order-pending' && t('showAuctionStatusInPaymentPending')}
        </dd>

        <dt className="remaining-time-title">{t('showAuctionCardStatisticTimeLeft')}</dt>
        <dd className="remaining-time">
          <Countdown endDate={displayedEndDate} startDate={displayedStartDate} />
        </dd>

        <dt className="winner-username-title">{t('showAuctionCardStatisticLastAuctionTitle')}</dt>
        <dd className="winner-username">
          <span className="value">{winningUserUsername ? `@${winningUserUsername}` : ''}</span>
        </dd>

        <dt className="price-title">{t('showAuctionCardStatisticAmountTitle')}</dt>
        <dd className="price">{formatCurrency(amount, currency)}</dd>
      </dl>
    </Card>
  )
}

export default CurrentAuction
