import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'
import customerSupportAvailability from '@/views/Show/components/IssueReporter/CustomerSupportAvailability/data/customerServiceAvailability'

import browserLanguage from '../../../../../helpers/browserLanguage'

import './CustomerSupportAvailability.scss'

const CustomerSupportAvailability = () => {
  const { t } = useTranslation()
  const userBrowserLanguage = browserLanguage()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { country } = sellerConfig || {}

  dayjs.locale(userBrowserLanguage)

  const availabilityForSellerCountry = customerSupportAvailability.find(
    (availability) => availability.country === country?.iso2Code
  )

  if (!availabilityForSellerCountry) return null

  const { weekAvailability, weekEndAvailability } = availabilityForSellerCountry
  const {
    startHour: weekStartHour,
    startMinutes: weekStartMinutes,
    endHour: weekEndHour,
    endMinutes: weekEndMinutes,
  } = weekAvailability.time
  const { startDay: weekStartDay, endDay: weekEndDay } = weekAvailability.day

  const formattedWeekAvailability = `${t('commonFromDate')} ${dayjs().day(weekStartDay).format('dddd')} ${t('commonToDate')} ${dayjs()
    .day(weekEndDay)
    .format('dddd')} ${t('commonBetween')} ${dayjs()
    .hour(weekStartHour)
    .minute(weekStartMinutes)
    .format('HH:mm')} ${t('commonAnd')} ${dayjs().hour(weekEndHour).minute(weekEndMinutes).format('HH:mm')}`

  const {
    startHour: weekEndStartHour,
    startMinutes: weekEndStartMinutes,
    endHour: weekEndEndHour,
    endMinutes: weekEndEndMinutes,
  } = weekEndAvailability?.time || {}
  const formattedWeekEndAvailability = weekEndAvailability
    ? `${dayjs().day(availabilityForSellerCountry.weekEndAvailability.day).format('dddd')}  ${t('commonBetween')} ${dayjs()
        .hour(weekEndStartHour as number)
        .minute(weekEndStartMinutes as number)
        .format('HH:mm')} ${t('commonAnd')} ${dayjs()
        .hour(weekEndEndHour as number)
        .minute(weekEndEndMinutes as number)
        .format('HH:mm')}`
    : ''

  return (
    <ul className="customer-support-availability">
      <li>{`${formattedWeekAvailability}.`}</li>
      {weekEndAvailability && <li>{`${formattedWeekEndAvailability}.`}</li>}
      {availabilityForSellerCountry.extra && <li>{t(availabilityForSellerCountry.extra)}</li>}
    </ul>
  )
}

export default memo(CustomerSupportAvailability)
