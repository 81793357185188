import { useTranslation } from 'react-i18next'

import Avatar from '@/components/ui/Avatar/Avatar'
import Button from '@/components/ui/Button/Button'

import { userIsBannedFromSeller, userIsBannedFromShow } from '../../chatbox.helper'
import { useChatBoxContext } from '../../ChatBoxProvider'

import type { Viewer } from '../../chatbox.helper'

import styles from './style.module.scss'

export interface ShowViewerItemProps {
  viewer: Viewer
}

export const ShowBannedFromShowOrSellerViewerItem = ({ viewer }: ShowViewerItemProps) => {
  const { bannedUsersFromSeller, bannedUsersFromShow, onUnBanUserFromSeller, onUnBanUserFromShow } = useChatBoxContext()
  const { t } = useTranslation()

  const isBannedFromSeller = userIsBannedFromSeller(viewer, bannedUsersFromSeller)
  const isBannedFromShow = userIsBannedFromShow(viewer, bannedUsersFromShow)

  return (
    <div className={`flex items-center justify-between py-2 ${styles.show_viewer_item__border}`}>
      <div className="flex items-center gap-4">
        <div className="relative">
          <Avatar src={viewer.avatarWebPUrl}>{viewer.username}</Avatar>
        </div>
        <div className="flex flex-col">
          <div className="callout_3">@{viewer.username}</div>

          <p className="callout_3 m-0 text-black-130">
            {isBannedFromSeller
              ? t('showAllBannedViewersListBannedFromSellerUsers')
              : t('showAllBannedViewersListBannedFromShowUsers')}
          </p>
        </div>
      </div>

      {isBannedFromSeller && (
        <Button
          className="unban-action unban-from-seller-action secondary"
          label={t('showAllBannedViewersListUnbanFromSellerAction')}
          onClick={() => onUnBanUserFromSeller(viewer)}
        />
      )}
      {isBannedFromShow && (
        <Button
          className="unban-action unban-from-show-action secondary"
          label={t('showAllBannedViewersListUnbanFromShowAction')}
          onClick={() => onUnBanUserFromShow(viewer)}
        />
      )}
    </div>
  )
}
