// Sources: Shipping Provider Matrix
// https://docs.google.com/spreadsheets/d/1mKGQdLxePMTpaSgO_PXwRt7sRji7vAllKj3udznvCZs/edit#gid=659240692
// https://github.com/bits-app/bits-server/blob/669b6b7d85dd1648283cb3ea6ae36c44e588652d/src/config/shipping-providers.ts

import type { Country } from '@/data/countriesClusters'

export type ShippingProviderCountryCode = Country | 'others'
export type ShippingProvider = {
  label: string
  value: string
}
type ShippingProvidersByCountry = Record<ShippingProviderCountryCode, ShippingProvider[]>

export const SHIPPING_PROVIDER_HAND_DELIVERY_VALUE = 'hand_delivery'

const shippingProvidersFR = [
  { label: 'Mondial Relay', value: 'mondialrelay-fr' },
  { label: 'La Poste Lettre Suivie', value: 'la-poste-lettre-suivie' },
  { label: 'La Poste Colissimo', value: 'la-poste-colissimo' },
  { label: 'Remise en main propre', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'UPS', value: 'ups' },
  { label: 'Chronopost', value: 'chronopost' },
  { label: 'GLS', value: 'gls' },
  { label: 'Relais Colis', value: 'relais-colis' },
  { label: 'TNT', value: 'tnt' },
  { label: 'DPD', value: 'dpd' },
  { label: 'DHL', value: 'dhl' },
  { label: 'Colis Privé', value: 'colis-prive' },
]

const shippingProvidersBE = [
  { label: 'Mondial Relay', value: 'mondialrelay-fr' },
  { label: 'La Poste Lettre Suivie', value: 'la-poste-lettre-suivie' },
  { label: 'La Poste Colissimo', value: 'la-poste-colissimo' },
  { label: 'Remise en main propre/ Met de hand bezorgd', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'DPD', value: 'dpd' },
  { label: 'BPost', value: 'bpost' },
  { label: 'Chronopost', value: 'chronopost' },
  { label: 'PostNL', value: 'postnl' },
  { label: 'UPS', value: 'ups' },
  { label: 'Colis Privé', value: 'colis-prive' },
  { label: 'GLS', value: 'gls' },
]

const shippingProvidersCH = [
  { label: 'La Poste Lettre Suivie', value: 'la-poste-lettre-suivie' },
  { label: 'La Poste Colissimo', value: 'la-poste-colissimo' },
  { label: 'Mondial Relay', value: 'mondialrelay-fr' },
  { label: 'Chronopost', value: 'chronopost' },
  { label: 'Remise en main propre/ Eigenhändige Übergabe', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'Poste Suisse', value: 'poste-suisse' },
]

const shippingProvidersDE = [
  { label: 'DHL', value: 'dhl_de' },
  { label: 'Deutsche Post', value: 'deutsch-post' },
  { label: 'Persönliche Zustellung', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'DPD', value: 'dpd' },
  { label: 'GLS', value: 'gls_de' },
  { label: 'UPS', value: 'ups' },
  { label: 'Hermes', value: 'hermes' },
]

const shippingProvidersAT = [
  { label: 'PostAG', value: 'austrian-post-registered' },
  { label: 'Persönliche Zustellung', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'Hermes', value: 'hermes' },
  { label: 'DHL', value: 'dhl_de' },
  { label: 'Deutsche Post', value: 'deutsch-post' },
  { label: 'GLS', value: 'gls_de' },
  { label: 'UPS', value: 'ups' },
  { label: 'DPD', value: 'dpd' },
]

const shippingProvidersNL = [
  { label: 'PostNL', value: 'postnl' },
  { label: 'Met de hand bezorgd', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'DHL', value: 'dhl' },
  { label: 'DPD', value: 'dpd' },
  { label: 'Mondial Relay', value: 'mondialrelay' },
  { label: 'UPS', value: 'ups' },
  { label: 'BPost', value: 'bpost' },
  { label: 'Hermes', value: 'hermes' },
]

const shippingProvidersGB = [
  { label: 'Royal Mail', value: 'royal-mail' },
  { label: 'Hand delivered', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'Evri', value: 'evri' },
  { label: 'Hermes', value: 'hermes_uk' },
  { label: 'UPS', value: 'ups' },
  { label: 'Yodel', value: 'yodel' },
  { label: 'Parcelforce', value: 'parcel-force' },
  { label: 'DPD', value: 'dpd' },
  { label: 'DHL', value: 'dhl' },
  { label: 'Fedex', value: 'fedex' },
]

const shippingProvidersES = [
  { label: 'Correos', value: 'correosexpress' },
  { label: 'Mondial Relay', value: 'mondialrelay' },
  { label: 'UPS', value: 'ups' },
  { label: 'Entregado en mano', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'Japan Post', value: 'japan-post' },
  { label: 'GLS', value: 'gls' },
  { label: 'SEUR', value: 'spanish-seur' },
  { label: 'Nacex', value: 'nacex-spain' },
  { label: 'CTT', value: 'ctt' },
  { label: 'DHL', value: 'dhl' },
]

const shippingProvidersOthers = [
  { label: 'DHL', value: 'dhl' },
  { label: 'UPS', value: 'ups' },
  { label: 'Delivered by hand', value: SHIPPING_PROVIDER_HAND_DELIVERY_VALUE },
  { label: 'Mondial Relay', value: 'mondialrelay' },
  { label: 'Japan Post', value: 'japan-post' },
  { label: 'Fedex', value: 'fedex' },
  { label: 'DPD', value: 'dpd' },
  { label: 'GLS', value: 'gls' },
  { label: 'POST Luxembourg', value: 'post-luxembourg' },
  { label: 'La Poste Colissimo', value: 'la-poste-colissimo' },
]

export const shippingProvidersByCountry: ShippingProvidersByCountry = {
  FR: shippingProvidersFR,
  BE: shippingProvidersBE,
  CH: shippingProvidersCH,
  DE: shippingProvidersDE,
  AT: shippingProvidersAT,
  NL: shippingProvidersNL,
  GB: shippingProvidersGB,
  ES: shippingProvidersES,
  others: shippingProvidersOthers,
}
