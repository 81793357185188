import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'

import Popover from '@/components/ui/Popover/Popover'
import { legacyCurrencyFormatter } from '@/helpers/currencyFormatter'
import { trackEvent } from '@/util/eventTracker'

import { useGetShowQuery } from './operations.generated'

import style from './style.module.scss'

interface AmountPopoverProps {
  showId: string
  children?: React.ReactNode
}

const AmountPopover = ({ children, showId }: AmountPopoverProps) => {
  const { t } = useTranslation()
  const nodeId = `Show|${showId}`

  const onOpenChange = (value: boolean) => {
    if (value)
      trackEvent('SHOW_OPEN_TOTAL_AMOUNT_POPOVER', {
        showId,
      })
  }

  const { data } = useGetShowQuery({ skip: !showId, variables: { nodeId } })

  const showData = data?.node?.__typename === 'Show' ? data.node : null

  if (!showData) {
    return null
  }

  const {
    totalAmountOrdersSuccessInCurrencyCents,
    totalProductsSoldInCurrencyCents,
    totalShippingFeesInCurrencyCents,
    totalAmountRefundedInCurrencyCents,
    totalCommissionInCurrencyCents,
    totalDeductedShippingCostInCurrencyCents,
    totalNetIncomeInCurrencyCents,
  } = showData

  const ordersMetricsTable = (
    <table className={style.show__popover_table}>
      <tbody>
        <tr>
          <td>
            <div>{t('commonShowTotalSoldProducts') + ' '}</div>
          </td>
          <td>{legacyCurrencyFormatter(totalAmountOrdersSuccessInCurrencyCents / 100)}</td>
        </tr>
        <tr>
          <td>
            <div>
              <span>&#10551;&nbsp;</span>
              {t('commonProductsSold')}
            </div>
          </td>
          <td>{legacyCurrencyFormatter(totalProductsSoldInCurrencyCents / 100)}</td>
        </tr>
        <tr>
          <td>
            <div>
              <span>&#10551;&nbsp;</span>
              {t('commonShippingFees')}
            </div>
          </td>
          <td>{legacyCurrencyFormatter(totalShippingFeesInCurrencyCents / 100)}</td>
        </tr>
        {!!totalAmountRefundedInCurrencyCents && (
          <tr>
            <td>
              <div>{t('commonShowRefundedAmount') + ' '}</div>
            </td>
            <td>-{legacyCurrencyFormatter(totalAmountRefundedInCurrencyCents / 100)}</td>
          </tr>
        )}

        <tr>
          <td className={style.row__divider} colSpan={2}>
            <Divider />
          </td>
        </tr>

        <tr>
          <td>
            <div>{t('showAmountPopoverTableTaxFree')}</div>
          </td>
          <td>
            {legacyCurrencyFormatter(
              totalAmountOrdersSuccessInCurrencyCents / 100 - totalAmountRefundedInCurrencyCents / 100
            )}
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <span>&#10551;&nbsp;</span>
              {t('commonCommission')}
            </div>
          </td>
          <td>-{legacyCurrencyFormatter(totalCommissionInCurrencyCents / 100)}</td>
        </tr>
        {!!totalDeductedShippingCostInCurrencyCents && (
          <tr>
            <td>
              <div>
                <span>&#10551;&nbsp;</span>
                {t('commonShippingLabel')}
              </div>
            </td>
            <td>-{legacyCurrencyFormatter(totalDeductedShippingCostInCurrencyCents / 100)}</td>
          </tr>
        )}
        <tr className={style.row__bold}>
          <td>
            <div>{t('commonNetIncome')}</div>
          </td>
          <td>{!!totalNetIncomeInCurrencyCents && legacyCurrencyFormatter(totalNetIncomeInCurrencyCents / 100)}</td>
        </tr>
      </tbody>
    </table>
  )

  return (
    <Popover
      className="show-sales-figure-summary"
      content={ordersMetricsTable}
      title={t('showAmountPopoverTitle')}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  )
}

export default AmountPopover
