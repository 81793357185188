import { Result } from 'antd'
import { useTranslation } from 'react-i18next'

import ViewContainer from '@/components/ViewContainer/ViewContainer'

const ErrorPage404 = () => {
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%', display: 'flex', height: '100vh' }}>
      <div style={{ overflow: 'scroll', width: '100%' }}>
        <ViewContainer leftContent={`${t('errorPageHeaderTitle')}`}>
          <Result status="warning" subTitle={t('errorPageDoesntExist')} title={t('errorPageTitle')} />
        </ViewContainer>
      </div>
    </div>
  )
}

export default ErrorPage404
