import { message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useBanUserFromSellerMutation,
  useBanUserFromShowMutation,
  useGetUsersBannedFromSellerQuery,
  useGetUsersBannedFromShowQuery,
  useUnbanUserFromSellerMutation,
  useUnbanUserFromShowMutation,
} from '@/modules/ban-hammer/graphql/operations.generated'
import { trackEvent } from '@/util/eventTracker'

import type { Show } from '@/types'

export const useBanUsers = (sellerId?: string, showId?: number) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  //TODO : Cut in several hooks OR use a context
  //TODO 2 : Refactor to use globalId

  const { data: bannedFromSellerUserList, refetch: refetchBannedFromSeller } = useGetUsersBannedFromSellerQuery({
    variables: { sellerId: sellerId ?? '' },
    fetchPolicy: 'network-only',
    skip: !sellerId,
  })

  const { data: bannedFromShowUserList, refetch: refetchBannedFromShow } = useGetUsersBannedFromShowQuery({
    variables: { showId: `Show|${showId}` },
    fetchPolicy: 'network-only',
    skip: !showId,
  })

  const [banUserFromSellerWithoutPrefix] = useBanUserFromSellerMutation()
  const [banUserFromShowWithoutPrefix] = useBanUserFromShowMutation()
  const [unbanUserFromSellerWithoutPrefix] = useUnbanUserFromSellerMutation()
  const [unbanUserFromShowWithoutPrefix] = useUnbanUserFromShowMutation()

  const banUserFromShow = async (showId: Show['id'], userId: string) => {
    trackEvent('SHOW_BAN_FROM_SHOW_CLICKED', { show: showId })
    setIsLoading(true)
    try {
      const result = await banUserFromShowWithoutPrefix({
        variables: {
          input: { showId: `Show|${showId}`, userId: userId },
        },
      })

      refetchBannedFromShow({ showId: `Show|${showId}` })
      message.success(t('banUserFromShowSuccess'))
      return result
    } catch (err) {
      message.error(t('banUserFromShowError'))
    } finally {
      setIsLoading(false)
    }
  }

  const banUserFromSeller = async (sellerId: string, userId: string) => {
    trackEvent('SHOW_BAN_FROM_SELLER_CLICKED', { show: showId })
    setIsLoading(true)
    try {
      const result = await banUserFromSellerWithoutPrefix({
        variables: {
          input: { sellerId: sellerId, userId: userId },
        },
      })

      refetchBannedFromSeller({ sellerId: sellerId ?? '' })
      message.success(t('banUserFromSellerSuccess'))
      return result
    } catch (err) {
      message.error(t('banUserFromSellerError'))
    } finally {
      setIsLoading(false)
    }
  }

  const unbanUserFromSeller = async (userId: string, sellerId: string) => {
    trackEvent('SHOW_UNBAN_FROM_SELLER_CLICKED', { show: showId })
    setIsLoading(true)
    try {
      const result = await unbanUserFromSellerWithoutPrefix({
        variables: {
          input: { sellerId, userId },
        },
      })

      refetchBannedFromSeller({ sellerId: sellerId ?? '' })
      message.success(t('unbanUserFromSellerSuccess'))
      return result
    } catch (err) {
      message.error(t('unbanUserFromSellerError'))
    } finally {
      setIsLoading(false)
    }
  }

  const unbanUserFromShow = async (showId: number, userId: string) => {
    trackEvent('SHOW_UNBAN_FROM_SHOW_CLICKED', { show: showId })
    setIsLoading(true)
    try {
      const result = await unbanUserFromShowWithoutPrefix({
        variables: {
          input: { showId: `Show|${showId}`, userId: userId },
        },
      })

      refetchBannedFromShow({ showId: `Show|${showId}` ?? '' })
      message.success(t('unbanUserFromSellerSuccess'))
      return result
    } catch (err) {
      message.error(t('unbanUserFromSellerError'))
    } finally {
      setIsLoading(false)
    }
  }

  return {
    bannedFromSellerUserList,
    bannedFromShowUserList,
    banUserFromSeller,
    banUserFromShow,
    unbanUserFromSeller,
    unbanUserFromShow,
    isLoading,
  }
}
