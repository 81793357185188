import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Countdown from '@/components/Countdown'
import Card from '@/components/ui/Card/Card'
import Collapsible from '@/components/ui/Collapsible/Collapsible'

import type { PollFragment } from '@/views/Show/operations.generated'

import './Polls.scss'
interface PollProps {
  currentPoll?: PollFragment | null
}

const Poll = (props: PollProps) => {
  const { currentPoll } = props
  const { t } = useTranslation()

  const [shouldBeOpened, setShouldBeOpened] = useState(false)

  useEffect(() => {
    if (status === 'OPENED') {
      setShouldBeOpened(true)
    }
  }, [currentPoll?.status])

  const handleCollapse = useCallback((isCollapsed: boolean) => {
    setShouldBeOpened(isCollapsed)
  }, [])

  if (!currentPoll) return null

  const { endAt, serverTime, status, answers, votes } = currentPoll

  const endDate = new Date(endAt)
  // If the poll is closed from more than 5 minutes, we don't want to show it
  if (dayjs(endDate).add(5, 'minute') < dayjs()) return null

  const answer1 = answers[0]
  const answer2 = answers[1]
  const answer3 = answers.length > 2 ? answers[2] : undefined
  const answer4 = answers.length > 3 ? answers[3] : undefined

  const votesForAnswer1 = votes.participations[0]
  const votesForAnswer2 = votes.participations[1]
  const votesForAnswer3 = votes.participations.length > 2 ? votes.participations[2] : 0
  const votesForAnswer4 = votes.participations.length > 3 ? votes.participations[3] : 0

  const participantCount = votes.participantCount

  const percentForAnswer1 = participantCount > 0 ? (votesForAnswer1 / participantCount) * 100 : 0
  const percentForAnswer2 = participantCount > 0 ? (votesForAnswer2 / participantCount) * 100 : 0
  const percentForAnswer3 = participantCount > 0 ? (votesForAnswer3 / participantCount) * 100 : 0
  const percentForAnswer4 = participantCount > 0 ? (votesForAnswer4 / participantCount) * 100 : 0

  const answer1IsWinner =
    votesForAnswer1 > votesForAnswer2 && votesForAnswer1 > votesForAnswer3 && votesForAnswer1 > votesForAnswer4
  const answer2IsWinner =
    votesForAnswer2 > votesForAnswer1 && votesForAnswer2 > votesForAnswer3 && votesForAnswer2 > votesForAnswer4
  const answer3IsWinner =
    votesForAnswer3 > votesForAnswer1 && votesForAnswer3 > votesForAnswer2 && votesForAnswer3 > votesForAnswer4
  const answer4IsWinner =
    votesForAnswer4 > votesForAnswer1 && votesForAnswer4 > votesForAnswer2 && votesForAnswer4 > votesForAnswer3

  const displayedStartDate = serverTime || new Date()

  if (!currentPoll) return null

  return (
    <Card className="current-poll" level="medium">
      <Collapsible
        opened={shouldBeOpened}
        title={t('showPollCardTitle')}
        headerContent={
          <div className="remaining-time-and-status">
            <span>
              <Countdown endDate={endDate} startDate={displayedStartDate} />
            </span>
            <span className={`badge badge-${status === 'CLOSED' ? 'closed' : 'opened'}`}>{t(`polls-${status}`)}</span>
          </div>
        }
        onCollapse={handleCollapse}
      >
        <div className="answers">
          <div className="answer-content">
            <div
              className={`progress ${answer1IsWinner ? 'winner' : votesForAnswer1 > 0 ? 'loser' : 'no-votes'}`}
              style={{ width: `${percentForAnswer1}%` }}
            ></div>
            <div className="content">
              <span>{answer1}</span>
              <div>
                <span>{percentForAnswer1.toFixed(2)}%</span>
                <span>({votesForAnswer1})</span>
              </div>
            </div>
          </div>
          <div className="answer-content">
            <div
              className={`progress ${answer2IsWinner ? 'winner' : votesForAnswer2 > 0 ? 'loser' : 'no-votes'}`}
              style={{ width: `${percentForAnswer2}%` }}
            ></div>
            <div className="content">
              <span>{answer2}</span>
              <div>
                <span>{percentForAnswer2.toFixed(2)}%</span>
                <span>({votesForAnswer2})</span>
              </div>
            </div>
          </div>
          {answer3 && (
            <div className="answer-content">
              <div
                className={`progress ${answer3IsWinner ? 'winner' : votesForAnswer3 > 0 ? 'loser' : 'no-votes'}`}
                style={{ width: `${percentForAnswer3}%` }}
              ></div>
              <div className="content">
                <span>{answer3}</span>
                <div>
                  <span>{percentForAnswer3.toFixed(2)}%</span>
                  <span>({votesForAnswer3})</span>
                </div>
              </div>
            </div>
          )}
          {answer4 && (
            <div className="answer-content">
              <div
                className={`progress ${answer4IsWinner ? 'winner' : votesForAnswer4 > 0 ? 'loser' : 'no-votes'}`}
                style={{ width: `${percentForAnswer4}%` }}
              ></div>

              <div className="content">
                <span>{answer4}</span>
                <div>
                  <span>{percentForAnswer4.toFixed(2)}%</span>
                  <span>({votesForAnswer4})</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Collapsible>
    </Card>
  )
}

export default Poll
