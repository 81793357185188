const NewPaperIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.36625 9.15334H9.50817C9.7788 9.15334 10.0032 8.93334 10.0032 8.66001C10.0032 8.38001 9.7788 8.16001 9.50817 8.16001H8.36625V7.00668C8.36625 6.73334 8.14843 6.51334 7.87781 6.51334C7.60718 6.51334 7.38276 6.73334 7.38276 7.00668V8.16001H6.24744C5.97682 8.16001 5.75239 8.38001 5.75239 8.66001C5.75239 8.93334 5.97682 9.15334 6.24744 9.15334H7.38276V10.3067C7.38276 10.58 7.60718 10.8 7.87781 10.8C8.14843 10.8 8.36625 10.58 8.36625 10.3067V9.15334ZM12.892 6.01708C13.0471 6.01529 13.2161 6.01334 13.3696 6.01334C13.5346 6.01334 13.6666 6.14668 13.6666 6.31334V11.6733C13.6666 13.3267 12.3399 14.6667 10.6963 14.6667H5.44876C3.73259 14.6667 2.33325 13.26 2.33325 11.5267V4.34001C2.33325 2.68668 3.66659 1.33334 5.31015 1.33334H8.8349C9.00652 1.33334 9.13853 1.47334 9.13853 1.64001V3.78668C9.13853 5.00668 10.1352 6.00668 11.3432 6.01334C11.6253 6.01334 11.874 6.01545 12.0917 6.0173C12.2611 6.01873 12.4116 6.02001 12.5445 6.02001C12.6384 6.02001 12.7602 6.0186 12.892 6.01708ZM13.0733 5.04414C12.5307 5.04614 11.8911 5.04414 11.431 5.03948C10.701 5.03948 10.0997 4.43214 10.0997 3.69481V1.93748C10.0997 1.65014 10.4449 1.50748 10.6422 1.71481C10.9998 2.09037 11.4914 2.60677 11.9806 3.12065C12.4675 3.63208 12.952 4.14102 13.2997 4.50614C13.4924 4.70814 13.3511 5.04348 13.0733 5.04414Z"
        fill="#222C36"
        fillOpacity="0.8"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default NewPaperIcon
