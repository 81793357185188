import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import EventIcon from '@/components/ui/Icons/EventIcon/EventIcon'

type ShowEventApplicationButtonProps = {
  handleOnClick: () => void
}

const ShowEventApplicationButton = ({ handleOnClick }: ShowEventApplicationButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      className="show-event-application-action"
      icon={<EventIcon />}
      label={t('showEventTypeformLinkText')}
      tooltip={`${t('showEventTypeformLinkText')} ${t('showEventTypeformLinkPopover')}`}
      tooltipPosition="bottom right"
      onClick={handleOnClick}
    />
  )
}

export default ShowEventApplicationButton
