import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import DuplicateIcon from '@/components/ui/Icons/DuplicateIcon/DuplicateIcon'

import type { Product } from '@/network/graphql/types.generated'

interface EditAndDeleteButtonsProps {
  product: Product
  onProductDuplicateClick: (product: Product) => void
}

const DuplicateButton = (props: EditAndDeleteButtonsProps) => {
  const { product, onProductDuplicateClick } = props

  const { t } = useTranslation()

  return (
    <div className="product-action">
      <Button
        className="duplicate-action duplicate-product-action"
        icon={<DuplicateIcon />}
        tooltip={t('showProductsListTableNewDuplicate')}
        onClick={() => onProductDuplicateClick(product)}
      />
    </div>
  )
}

export default DuplicateButton
