import dayjs from 'dayjs'

import Collapsible from '@/components/ui/Collapsible/Collapsible'
import Loader from '@/components/ui/Loader/Loader'

import { ShowsPanelHeader } from '../ShowsPanelHeader'
import { ShowsTable } from '../ShowsTable/ShowsTable'

import type { GetSellerShowsAggregatedByMonthQueryResult } from '../../operations.generated'

import './ShowsList.scss'

export interface ShowsListProps {
  aggregatedMonths: GetSellerShowsAggregatedByMonthQueryResult['data']
}

export const ShowsList = ({ aggregatedMonths }: ShowsListProps) => {
  if (!aggregatedMonths) return <Loader />

  const rows = aggregatedMonths.sellerShowsAggregatedByMonth.edges.map((row) => row.node)

  return (
    <div className="shows-list">
      {rows.map(({ currency, id, month, netIncome, showCount, year }) => {
        const isCurrentMonth = year === dayjs().year() && parseInt(month, 10) === dayjs().month() + 1
        const name = dayjs(`${year}-${month}`).format('MMMM YYYY')
        return (
          <Collapsible
            key={id}
            headerContent={<ShowsPanelHeader currency={currency} netIncome={netIncome} showCount={showCount} />}
            opened={isCurrentMonth}
            title={name}
          >
            <ShowsTable aggregatedMonthId={id} />
          </Collapsible>
        )
      })}
    </div>
  )
}
