import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RateShowAsSellerMutationVariables = Types.Exact<{
  input: Types.RateShowAsSellerInput;
}>;


export type RateShowAsSellerMutation = { __typename?: 'Mutation', rateShowAsSeller: { __typename?: 'RateShowAsSellerPayload', ok: boolean } };


export const RateShowAsSellerDocument = gql`
    mutation rateShowAsSeller($input: RateShowAsSellerInput!) {
  rateShowAsSeller(input: $input) {
    ok
  }
}
    `;
export type RateShowAsSellerMutationFn = Apollo.MutationFunction<RateShowAsSellerMutation, RateShowAsSellerMutationVariables>;

/**
 * __useRateShowAsSellerMutation__
 *
 * To run a mutation, you first call `useRateShowAsSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateShowAsSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateShowAsSellerMutation, { data, loading, error }] = useRateShowAsSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRateShowAsSellerMutation(baseOptions?: Apollo.MutationHookOptions<RateShowAsSellerMutation, RateShowAsSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RateShowAsSellerMutation, RateShowAsSellerMutationVariables>(RateShowAsSellerDocument, options);
      }
export type RateShowAsSellerMutationHookResult = ReturnType<typeof useRateShowAsSellerMutation>;
export type RateShowAsSellerMutationResult = Apollo.MutationResult<RateShowAsSellerMutation>;
export type RateShowAsSellerMutationOptions = Apollo.BaseMutationOptions<RateShowAsSellerMutation, RateShowAsSellerMutationVariables>;