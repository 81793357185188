import style from './style.module.scss'

interface EmptyProps {
  description?: string
  type: 'files' | 'save' | 'image'
}

const files = (
  <svg fill="none" height="70" viewBox="0 0 81 70" width="81" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M23.6113 4.00886C24.1922 2.96109 25.0504 2.08017 26.1053 1.47112C27.6346 0.588217 29.4519 0.348959 31.1575 0.805984L51.498 6.25619C52.7541 6.59276 53.825 7.41452 54.4752 8.5407L63.1612 23.5852C63.8114 24.7114 63.9876 26.0498 63.651 27.3058L56.3841 54.4264C55.927 56.1321 54.8112 57.5863 53.2819 58.4692C51.7527 59.3521 49.9354 59.5914 48.2297 59.1344L36.2751 55.9311H30.8271L18.8733 59.1342C17.1676 59.5912 15.3503 59.3519 13.8211 58.469C12.2918 57.5861 11.176 56.1319 10.7189 54.4263L0.726882 17.1354C0.269857 15.4298 0.509116 13.6125 1.39202 12.0832C2.27492 10.554 3.72914 9.43812 5.43479 8.9811C5.52569 8.95674 5.61727 8.93501 5.70943 8.91592L12.8084 7.44566C13.1073 6.90487 13.4826 6.40368 13.9274 5.95894C15.176 4.71032 16.8695 4.00886 18.6353 4.00886H23.6113Z"
      fill="#FCFCFC"
      fillRule="evenodd"
      stroke="#767676"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M32.259 9.25104C32.8399 8.20327 33.6981 7.32236 34.753 6.71331C36.2823 5.8304 38.0996 5.59115 39.8052 6.04817L60.1457 11.4984C61.4018 11.8349 62.4727 12.6567 63.1229 13.7829L71.8089 28.8274C72.4591 29.9536 72.6353 31.292 72.2987 32.548L65.0318 59.6686C64.5748 61.3743 63.4589 62.8285 61.9296 63.7114C60.4004 64.5943 58.5831 64.8336 56.8774 64.3765L44.9228 61.1733H39.4748L27.521 64.3763C25.8153 64.8334 23.998 64.5941 22.4688 63.7112C20.9395 62.8283 19.8237 61.3741 19.3666 59.6684L9.37459 22.3776C8.91756 20.672 9.15682 18.8546 10.0397 17.3254C10.9226 15.7962 12.3768 14.6803 14.0825 14.2233C14.1734 14.1989 14.265 14.1772 14.3571 14.1581L21.4561 12.6878C21.755 12.1471 22.1303 11.6459 22.5751 11.2011C23.8237 9.95251 25.5172 9.25104 27.283 9.25104H32.259Z"
      fill="#FCFCFC"
      fillRule="evenodd"
      stroke="#767676"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M40.293 14.2208C40.8739 13.173 41.7321 12.2921 42.787 11.683C44.3162 10.8001 46.1335 10.5609 47.8392 11.0179L68.1796 16.4681C69.4357 16.8047 70.5067 17.6264 71.1569 18.7526L79.8428 33.7972C80.493 34.9233 80.6692 36.2617 80.3327 37.5178L73.0657 64.6384C72.6087 66.344 71.4928 67.7982 69.9636 68.6811C68.4343 69.564 66.617 69.8033 64.9114 69.3463L52.9568 66.143H47.5088L35.5549 69.3461C33.8493 69.8031 32.0319 69.5639 30.5027 68.6809C28.9735 67.798 27.8576 66.3438 27.4006 64.6382L17.4085 27.3473C16.9515 25.6417 17.1908 23.8244 18.0737 22.2951C18.9566 20.7659 20.4108 19.65 22.1164 19.193C22.2073 19.1687 22.2989 19.1469 22.3911 19.1278L29.49 17.6576C29.789 17.1168 30.1643 16.6156 30.609 16.1708C31.8576 14.9222 33.5511 14.2208 35.3169 14.2208H40.293Z"
      fill="#C1B5FB"
      fillRule="evenodd"
    />
    <path
      d="M34.286 64.6101C33.8365 64.7306 33.3575 64.6675 32.9544 64.4348C32.5514 64.2021 32.2573 63.8188 32.1368 63.3693L22.1448 26.0785C22.0243 25.6289 22.0874 25.1499 22.3201 24.7469C22.5528 24.3438 22.9361 24.0497 23.3856 23.9292L33.5623 21.8216V20.8789C33.5623 20.4135 33.7471 19.9672 34.0762 19.6381C34.4053 19.309 34.8517 19.1241 35.3171 19.1241H43.8506L44.4211 16.995C44.5415 16.5454 44.8356 16.1621 45.2387 15.9294C45.6418 15.6967 46.1208 15.6337 46.5703 15.7541L66.9108 21.2043L75.5967 36.2489L68.3298 63.3695C68.2093 63.819 67.9152 64.2023 67.5122 64.435C67.1091 64.6677 66.6301 64.7308 66.1806 64.6103L53.6024 61.24H46.8634L34.286 64.6101Z"
      fill="#C1B5FB"
    />
    <path
      d="M46.8634 61.24H35.3171C34.8517 61.24 34.4053 61.0552 34.0762 60.7261C33.7471 60.397 33.5623 59.9506 33.5623 59.4852V21.8216M46.8634 61.24L34.286 64.6101C33.8365 64.7306 33.3575 64.6675 32.9544 64.4348C32.5514 64.2021 32.2573 63.8188 32.1368 63.3693L22.1448 26.0785C22.0243 25.6289 22.0874 25.1499 22.3201 24.7469C22.5528 24.3438 22.9361 24.0497 23.3856 23.9292L33.5623 21.8216M46.8634 61.24H53.6024M66.9108 21.2043L46.5703 15.7541C46.1208 15.6337 45.6418 15.6967 45.2387 15.9294C44.8356 16.1621 44.5415 16.5454 44.4211 16.995L43.8506 19.1241M66.9108 21.2043L75.5967 36.2489M66.9108 21.2043L63.7315 33.0696L75.5967 36.2489M75.5967 36.2489L68.3298 63.3695C68.2093 63.819 67.9152 64.2023 67.5122 64.435C67.1091 64.6677 66.6301 64.7308 66.1806 64.6103L53.6024 61.24M43.8506 19.1241L34.429 54.2858C34.3086 54.7354 34.3716 55.2144 34.6043 55.6174C34.837 56.0205 35.2203 56.3146 35.6699 56.435L53.6024 61.24M43.8506 19.1241H35.3171C34.8517 19.1241 34.4053 19.309 34.0762 19.6381C33.7471 19.9672 33.5623 20.4135 33.5623 20.8789V21.8216"
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

const save = (
  <svg fill="none" height="83" viewBox="0 0 80 83" width="80" xmlns="http://www.w3.org/2000/svg">
    <rect height="83" width="80" />
    <g clipPath="url(#clip0_636_5532)">
      <path
        clipRule="evenodd"
        d="M14.8992 21.2026C13.8003 16.4427 16.7681 11.6931 21.528 10.5942L49.6075 4.11157C52.0507 3.5475 54.6186 4.04694 56.6723 5.48566L68.2199 13.5752C70.0096 14.8289 71.2718 16.7008 71.7634 18.8299L78.0103 45.8883C79.1092 50.6482 76.1414 55.3977 71.3815 56.4966L34.1119 65.101C29.352 66.1999 24.6025 63.2321 23.5035 58.4722L14.8992 21.2026Z"
        fill=""
        fillRule="evenodd"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M8.44435 28.7328C7.34544 23.9729 10.3133 19.2234 15.0732 18.1245L43.1526 11.6418C45.5959 11.0778 48.1638 11.5772 50.2175 13.0159L61.765 21.1055C63.5547 22.3592 64.817 24.2311 65.3086 26.3602L71.5555 53.4186C72.6544 58.1785 69.6866 62.928 64.9266 64.0269L27.6571 72.6313C22.8971 73.7302 18.1476 70.7624 17.0487 66.0024L8.44435 28.7328Z"
        fillRule="evenodd"
        stroke="#767676"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M1.98976 36.2631C0.890846 31.5032 3.85868 26.7537 8.61859 25.6548L36.6981 19.1721C39.1413 18.608 41.7092 19.1075 43.7629 20.5462L55.3105 28.6357C57.1001 29.8895 58.3624 31.7613 58.854 33.8905L65.1009 60.9488C66.1998 65.7087 63.232 70.4583 58.4721 71.5572L21.2025 80.1615C16.4426 81.2605 11.693 78.2926 10.5941 73.5327L1.98976 36.2631Z"
        fill="#C1B5FB"
        fillRule="evenodd"
      />
      <path
        d="M57.0205 65.2676L19.751 73.872C18.4645 74.169 17.1808 73.3668 16.8838 72.0804L8.27947 34.8108C7.98247 33.5243 8.78458 32.2407 10.071 31.9437L38.1505 25.461C38.8108 25.3086 39.5049 25.4435 40.0599 25.8324L51.6075 33.9219C52.0912 34.2608 52.4324 34.7667 52.5652 35.3421L58.8121 62.4005C59.1091 63.6869 58.307 64.9706 57.0205 65.2676Z"
        fill="#C1B5FB"
        stroke="#1D1D1D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M50.0318 66.8818L50.2567 67.8562C50.7949 67.732 51.1304 67.195 51.0061 66.6569L50.0318 66.8818ZM26.7383 72.2595L25.7639 72.4845C25.8882 73.0226 26.4251 73.3582 26.9632 73.2339L26.7383 72.2595ZM25.5282 56.3907L44.163 52.0885L43.7131 50.1398L25.0783 54.442L25.5282 56.3907ZM45.8308 53.1307L49.0574 67.1068L51.0061 66.6569L47.7795 52.6808L45.8308 53.1307ZM49.8068 65.9074L26.5133 71.2852L26.9632 73.2339L50.2567 67.8562L49.8068 65.9074ZM27.7127 72.0346L24.486 58.0585L22.5373 58.5084L25.7639 72.4845L27.7127 72.0346ZM44.163 52.0885C44.9113 51.9158 45.658 52.3823 45.8308 53.1307L47.7795 52.6808C47.3583 50.8562 45.5377 49.7185 43.7131 50.1398L44.163 52.0885ZM25.0783 54.442C23.2537 54.8632 22.116 56.6838 22.5373 58.5084L24.486 58.0585C24.3133 57.3102 24.7798 56.5635 25.5282 56.3907L25.0783 54.442Z"
        fill="#1D1D1D"
      />
      <path
        d="M37.845 35.3456L23.8689 38.5723"
        stroke="#1D1D1D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

const image = (
  <svg fill="none" height="30" viewBox="0 0 34 30" width="34" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25 23.3332L10.1966 14.0621C10.9029 13.2381 11.2554 12.8262 11.6745 12.6777C12.0423 12.5473 12.4456 12.5501 12.8115 12.6856C13.2285 12.84 13.5751 13.2567 14.2699 14.0904L18.7139 19.4233C19.3623 20.2013 19.6858 20.5903 20.0801 20.7467C20.4265 20.8841 20.8102 20.9015 21.1676 20.796C21.5745 20.6759 21.9326 20.3178 22.6488 19.6017L23.4772 18.7732C24.2064 18.0441 24.5709 17.6796 24.9836 17.5605C25.346 17.4559 25.7327 17.4773 26.0814 17.6213C26.4784 17.7852 26.8011 18.1879 27.4453 18.9932L32.2508 25M32.25 6.99976V22.9998C32.25 24.8666 32.2504 25.8002 31.887 26.5132C31.5675 27.1404 31.0569 27.6502 30.4296 27.9698C29.7166 28.3331 28.7838 28.3332 26.917 28.3332H7.58366C5.71682 28.3332 4.7827 28.3331 4.06966 27.9698C3.44245 27.6502 2.93289 27.1404 2.61331 26.5132C2.25 25.8002 2.25 24.8666 2.25 22.9998V6.99976C2.25 5.13291 2.25 4.19961 2.61331 3.48657C2.93289 2.85937 3.44245 2.34939 4.06966 2.02982C4.7827 1.6665 5.71682 1.6665 7.58366 1.6665H26.917C28.7838 1.6665 29.7166 1.6665 30.4296 2.02982C31.0569 2.34939 31.5675 2.85937 31.887 3.48657C32.2504 4.19961 32.25 5.13291 32.25 6.99976ZM22.25 11.6665C21.3295 11.6665 20.5833 10.9203 20.5833 9.99984C20.5833 9.07936 21.3295 8.33317 22.25 8.33317C23.1705 8.33317 23.9167 9.07936 23.9167 9.99984C23.9167 10.9203 23.1705 11.6665 22.25 11.6665Z"
      stroke="#111111"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.33333"
    />
  </svg>
)

const Empty = ({ description, type }: EmptyProps) => {
  return (
    <div className={`empty no-data ${style.empty__container}`}>
      <div className="icon">{type === 'files' ? files : type === 'image' ? image : save}</div>
      {description && <span className={`callout_2 ${style.empty__description} text-black-120`}>{description}</span>}
    </div>
  )
}

export default Empty
