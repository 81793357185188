import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import HourglassIcon from '@/components/ui/Icons/HourglassIcon/HourglassIcon'

type ShowEventApplicationSuccessProps = {
  handleCloseModal: () => void
}

const ShowEventApplicationSuccess = ({ handleCloseModal }: ShowEventApplicationSuccessProps) => {
  const { t } = useTranslation()

  return (
    <div className="show-event-application-success">
      <div className="container">
        <div className="content">
          <HourglassIcon />
          <div className="content">
            <p className="title">{t('showEventApplicationConfirmationTitle')}</p>
            <p className="subtitle">{t('showEventApplicationConfirmationSubtitle')}</p>
          </div>
        </div>

        <div>
          <p className="description">{t('showEventApplicationConfirmationDescription')}</p>
        </div>

        <div className="button-container">
          <Button className="button primary is-success" onClick={handleCloseModal}>
            {t('showEventApplicationAmazingAction')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShowEventApplicationSuccess
