import { useTranslation } from 'react-i18next'

import { SellerConsentForm } from '@/components/SellerConsent/SellerConsentForm'
import Card from '@/components/ui/Card/Card'

import BrandPack from './BrandPack/BrandPack'

import './Marketing.scss'

export const Marketing = () => {
  const { t } = useTranslation()

  return (
    <div className="marketing-preferences">
      <Card title={t('marketingSellerConsent')}>
        <SellerConsentForm emailLabel={t('marketingSellerConsentEmail')} smsLabel={t('marketingSellerConsentSMS')} />
      </Card>

      <BrandPack />
    </div>
  )
}
