import { memo } from 'react'

import Input from '@/components/Form/Field/Input/Input'

import SortingIconBottom from '../../Icons/SortingIcon/SortingIconBottom'
import SortingIconNeutral from '../../Icons/SortingIcon/SortingIconNeutral'
import SortingIconTop from '../../Icons/SortingIcon/SortingIconTop'

type TableHeaderProps = {
  headers: any
  selectable?: {
    selectedRows: string[]
    onSelectedRowsChange: (selectedRows: string[]) => void
  }
  selectedData: string[]
  onSelectAll: (e: any) => void
  flexRender: (cell: any, context: any) => any
}

const TableHeader = (props: TableHeaderProps) => {
  const { headers, selectable, selectedData, onSelectAll, flexRender } = props

  return (
    <thead>
      {headers.map((headerGroup: any) => (
        <tr key={headerGroup.id}>
          {selectable && (
            <th>
              <div>
                <Input
                  type="checkbox"
                  checked={
                    selectable.selectedRows.length > 0 &&
                    selectedData.every((r: string) => selectable.selectedRows.includes(r))
                  }
                  onChange={onSelectAll}
                />
              </div>
            </th>
          )}
          {headerGroup.headers.map((header: any) => (
            <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
              {header.isPlaceholder ? null : (
                <div>
                  <div className="content">{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  <div className="sort">
                    {header.column.getCanSort() &&
                      ({ asc: <SortingIconTop />, desc: <SortingIconBottom /> }[
                        header.column.getIsSorted() as string
                      ] ?? (
                        <span className="sortable">
                          <SortingIconNeutral />
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}

export default memo(TableHeader)
