import { useTranslation } from 'react-i18next'

import StatusTag from '@/components/StatusTag'
import * as Types from '@/network/graphql/types.generated'
import { getStatusTranslationKey } from '@/views/Ledger/helper'

import type { StatusTagProps } from '@/components/StatusTag'

export const PayoutStatus = ({ payoutStatus }: { payoutStatus: Types.SellerLedgerTransactionStatus }) => {
  const { t } = useTranslation()

  return <StatusTag message={t(getStatusTranslationKey(payoutStatus))} type={getType(payoutStatus)} />
}

const getType = (status: Types.SellerLedgerTransactionStatus): StatusTagProps['type'] => {
  switch (status) {
    case Types.SellerLedgerTransactionStatus.InProgress:
      return 'in_progress'
    case Types.SellerLedgerTransactionStatus.Done:
      return 'success'
    case Types.SellerLedgerTransactionStatus.Failed:
      return 'error'
    default:
      return 'pending'
  }
}
