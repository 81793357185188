import { type MouseEvent } from 'react'

export const preventChangeOnWheelScrollOnNumberInputs = (event: MouseEvent) => {
  const input = event.target as HTMLInputElement
  input.blur()
  event.stopPropagation()
  setTimeout(() => {
    input.focus()
  }, 0)
}
