import { Country } from '@/data/countriesClusters'

const customerSupportAvailability = [
  {
    country: Country.FR,
    weekAvailability: {
      day: {
        startDay: 1,
        endDay: 5,
      },
      time: {
        startHour: 10,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    },
    weekEndAvailability: {
      day: 6,
      time: {
        startHour: 10,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
    },
  },
  {
    country: Country.GB,
    weekAvailability: {
      day: {
        startDay: 1,
        endDay: 5,
      },
      time: {
        startHour: 10,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 30,
      },
    },
  },
  {
    country: Country.DE,
    weekAvailability: {
      day: {
        startDay: 1,
        endDay: 3,
      },
      time: {
        startHour: 18,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    },
    extra: 'showIssueReporterPanelCustomerSupportAvailabilityPartialCovertureSaturdaySunday',
  },
  {
    country: Country.ES,
    weekAvailability: {
      day: {
        startDay: 1,
        endDay: 5,
      },
      time: {
        startHour: 10,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
    },
  },
  {
    country: Country.NL,
    weekAvailability: {
      day: {
        startDay: 1,
        endDay: 5,
      },
      time: {
        startHour: 18,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    },
  },
]

export default customerSupportAvailability
