import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SearchIcon from '@/components/ui/Icons/SearchIcon/SearchIcon'

type ProductSearchInputProps = {
  debounce?: number
  onChange: (value: string) => void
  value: string
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

const ProductSearchInput = (props: ProductSearchInputProps) => {
  const { t } = useTranslation()

  const { value: initialValue, onChange, debounce = 500 } = props
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <div className="product-search-container">
      <label className={`product-search ${value && 'expanded'}`}>
        <SearchIcon />
        <input
          placeholder={t('showProductsListSearchPlaceholder')}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </label>
    </div>
  )
}

export default ProductSearchInput
