import type { DetailedHTMLProps, HTMLAttributes } from 'react'

export type BodyVariant = 'body1' | 'body2' | 'body3' | 'body4' | 'body5' | 'body6'

type BodyHTMLProps = DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>

export interface BodyProps extends BodyHTMLProps {
  variant: BodyVariant
}

export const Body = ({ variant, className, ...props }: BodyProps) => {
  const base = new Map<string, string>([
    ['body1', 'text-[18px]'],
    ['body2', 'font-semibold text-[18px]'],
    ['body3', 'text-[16px]'],
    ['body4', 'font-semibold text-[16px]'],
    ['body5', 'font-semibold text-[12px]'],
    ['body6', 'text-[14px]'],
  ])

  const classNames = `${base.get(variant)} font-Gantari text-black-160 mb-0 ${className}`

  return <p className={classNames} {...props} />
}
