import { Progress } from 'antd'

import globalStyle from '../../styles/_export.module.scss'

export interface ProgressBarProps {
  percent: number
}

export default function ProgressBar({ percent }: ProgressBarProps) {
  if (percent <= 0) {
    return null
  }

  return (
    <Progress
      percent={Math.abs(percent)}
      size="small"
      strokeColor={globalStyle.black150}
      trailColor={globalStyle.white}
    />
  )
}
