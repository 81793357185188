export enum FashionProductCondition {
  NEW_WITH_TAGS = 'new-with-tags',
  NEW_WITHOUT_TAGS = 'new-without-tags',
  VERY_GOOD = 'very-good',
  GOOD = 'good',
  SATISFACTORY = 'satisfactory',
}

export const fashionProductConditionsTranslationKeys = [
  'showProductsDrawerFormConditionNewWithTags',
  'showProductsDrawerFormConditionNewWithoutTags',
  'showProductsDrawerFormConditionVeryGood',
  'showProductsDrawerFormConditionGood',
  'showProductsDrawerFormConditionSatisfactory',
]

export const acceptableConditionNewWithTagsValues = [
  'new with tags',
  'neuf avec étiquette',
  'neuf avec etiquette',
  'neu mit tags',
]
export const acceptableConditionNewWithoutTagsValues = [
  'new without tags',
  'neuf sans étiquette',
  'neuf sans etiquette',
  'neu ohne tags',
]
export const acceptableConditionVeryGoodValues = [
  'very good',
  'très bon état',
  'tres bon etat',
  'très bon',
  'tres bon',
  'sehr gut',
]
export const acceptableConditionGoodValues = ['good', 'bon état', 'bon etat', 'bon', 'gut']
export const acceptableConditionSatisfactoryValues = ['satisfactory', 'satisfaisant', 'befriedigend']
