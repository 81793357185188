import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

import type { BankInfoFormValidity } from '@/views/Account/components/BankInfo/BankInfoForm/types'

type SellerBankSepaConditionFieldProps = {
  value: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof BankInfoFormValidity, isValid: boolean) => void
}

const SellerBankSepaConditionField = (props: SellerBankSepaConditionFieldProps) => {
  const { value: sepaCondition, onChange, onValidityChange = () => undefined, ...others } = props
  const { t } = useTranslation()

  return (
    <Field
      checked={sepaCondition}
      id="sepaCondition"
      label={t('signupFormBankAccountSepaAcceptConditionsCheckbox')}
      name="sepaCondition"
      required={true}
      type="checkbox"
      {...others}
      onChange={onChange}
      onValidityChange={(isValid: boolean) => onValidityChange('sepaCondition', isValid)}
    />
  )
}

export default SellerBankSepaConditionField
