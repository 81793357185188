import { type ReactNode, useCallback, useMemo, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import Config from '@/config/config'
import { Currency, OrderCancellationReason, OrderPaymentStatus } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'

import { useCancelAndRefundOrderMutation } from '../../../operations.generated'

import type { Order } from '@/network/graphql/types.generated'

import './OrderCancellation.scss'

const { VITE_APP_LONG_TIMEOUT } = Config

interface OrderCancellationModalProps {
  order: Order
  sellerId: string | undefined
  customButtonContent?: ReactNode
  onSubmittedCallback?: () => void
}

type SelectAndCheckboxChangeEvent = ChangeEvent<HTMLSelectElement>

export const OrderCancellationModal = ({
  order,
  sellerId,
  customButtonContent,
  onSubmittedCallback,
}: OrderCancellationModalProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const isFailedPayment = useMemo(() => {
    return order.paymentStatus === OrderPaymentStatus.Failed || order.paymentStatus === OrderPaymentStatus.InProgress
  }, [order])

  const [reason, setReason] = useState<OrderCancellationReason>(
    isFailedPayment ? OrderCancellationReason.FailedPayment : OrderCancellationReason.BuyerRefusal
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [cancelAndRefundOrder] = useCancelAndRefundOrderMutation()

  const handleSubmit = () => {
    if (reason) {
      setIsLoading(true)
      cancelAndRefundOrder({
        variables: {
          input: {
            orderId: order.id,
            cancellationReason: reason,
          },
        },
        context: {
          timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
        },
        onCompleted: () => {
          setIsLoading(false)
          notificationSuccess(t('ordersCancellationModalMessageSucces'))
          setIsOpen(false)
          if (onSubmittedCallback) onSubmittedCallback()
          trackEvent('ORDER_CANCEL_REQUEST', { sellerId })
        },
        onError: (error) => {
          setIsLoading(false)
          notificationDanger(error.message)
        },
      })
    } else {
      notificationDanger(t('ordersCancellationModalMessageError'))
    }
  }

  const handleReason = useCallback((event: SelectAndCheckboxChangeEvent) => {
    setReason(event.target.value as OrderCancellationReason)
  }, [])

  const reasons = useMemo(() => {
    if (isFailedPayment) {
      return [
        {
          label: t('ordersCancellationModalSelectFailedPayment'),
          value: OrderCancellationReason.FailedPayment,
        },
      ]
    }

    return [
      {
        label: t('ordersCancellationModalSelectBuyerRefusal'),
        value: OrderCancellationReason.BuyerRefusal,
      },
    ]
  }, [isFailedPayment])

  const RegularButton = () => (
    <Button
      className="cancel-order-button secondary"
      label={t('ordersToBeSentListCancelOrderActionLabel')}
      tooltip={t('ordersToBeSentListCancelOrderActionTitle')}
      tooltipPosition="top right"
      onClick={() => {
        setIsOpen(!isOpen)
        trackEvent('SHIPMENT_ORDER_DETAIL_CANCEL_ORDER_BUTTON_CLICK')
      }}
    />
  )

  const CustomButton = () => <div onClick={() => setIsOpen(!isOpen)}>{customButtonContent}</div>

  return (
    <div>
      {(isFailedPayment || order.paymentStatus === OrderPaymentStatus.Success) &&
        (customButtonContent ? <CustomButton /> : <RegularButton />)}

      <Dialog
        className="order-cancellation"
        isOpen={isOpen}
        title={t('ordersCancellationModalTitle')}
        onClose={() => setIsOpen(!isOpen)}
      >
        {!isFailedPayment && order.amountInCurrencyCents && (
          <p className="cancellation-amount">
            {t('order-cancellation-amount-info')} {order.amountInCurrencyCents / 100}
            {order.currency === Currency.Eur ? '€' : '£'}
            <br />
            {t('order-cancellation-amount-info-shipping')}
          </p>
        )}

        <Form onSubmit={handleSubmit}>
          <Field
            label={t('ordersCancellationModalInputLabel')}
            name="reason"
            options={reasons}
            type="select"
            required
            onChange={handleReason}
          />

          {!isFailedPayment && <Alert type="warning">{t('ordersCancellationModalAlert')}</Alert>}

          <div className="cancellation-buttons">
            <Button
              className="secondary"
              label={t('commonCancel')}
              onClick={() => {
                setIsOpen(false)
              }}
            />
            <Button className="primary" isLoading={isLoading} label={t('commonValidate')} onClick={handleSubmit} />
          </div>
        </Form>
      </Dialog>
    </div>
  )
}
