import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import { TabBar } from '@/components/ui/TabBar/TabBar'

import { useSellerShipmentsTabsCountsQuery } from '../operations.generated'
import CanceledOrdersTable from '../Tables/CanceledOrdersTable'
import { PendingOrdersTable } from '../Tables/PendingOrdersTable'
import ShippedShipmentsTable from '../Tables/ShippedShipmentsTable'

import './ShipmentTabs.scss'

export const ShipmentTabs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const initialTab = new URLSearchParams(location.search).get('tab') || 'pending'

  const handleTabChange = useCallback(
    (tab: string) => {
      const url = `/shipments?tab=${tab}`
      navigate(url)
    },
    [history]
  )

  const { data, refetch } = useSellerShipmentsTabsCountsQuery()
  const totalCountPending = data?.unshipped.totalCount || 0
  const totalCountShipped = data?.shipped.totalCount || 0

  return (
    <TabBar
      className="shipments-tabs"
      initial={initialTab}
      tabs={[
        {
          key: 'pending',
          title: (
            <p className="shipments-pending-tab-bar-item">
              {t('ordersTabSwitcherPendingLabel')}
              {totalCountPending > 0 && <Tag content={totalCountPending} status={TagStatus.INFO} />}
            </p>
          ),
          content: <PendingOrdersTable hasAlreadyShipped={!!(totalCountShipped > 0)} onShipped={refetch} />,
        },
        {
          key: 'shipped',
          title: (
            <p className="shipments-shipped-tab-bar-item">
              {t('ordersTabSwitcherShippedLabel')}
              {totalCountShipped > 0 && <Tag content={totalCountShipped} status={TagStatus.SECONDARY} />}
            </p>
          ),
          content: <ShippedShipmentsTable />,
        },
        {
          key: 'cancelAndRefund',
          title: t('ordersTabSwitcherCancelAndRefundLabel'),
          content: <CanceledOrdersTable />,
        },
      ]}
      onChange={handleTabChange}
    />
  )
}
