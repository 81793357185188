import { useTranslation } from 'react-i18next'

import { getFromArrayLocalStorage, setToArrayLocalStorage } from '@/helpers/localstorage'

import Alert from '../ui/Alert/Alert'

export const WarningInShowBanner = () => {
  const { t } = useTranslation()

  const keyWarning = t('warningInShowBannerKey')

  // If the key is not defined, we don't display the banner
  // In order to display the banner, you need to define the key in localize, do not forget to use a different key each time because we use it as a localstorage key
  // Do not forget to add the translation for at least the title and the text if you add a key
  if (keyWarning === 'warningInShowBannerKey') return null

  const localStorageKey = 'hasSeenWarningInShowBanner'
  const dismissed = !!getFromArrayLocalStorage(localStorageKey, keyWarning)

  const handleClick = () => {
    setToArrayLocalStorage(localStorageKey, keyWarning)
  }

  return (
    <Alert
      className="warning-in-show-alert"
      dismissed={dismissed}
      emphasis="high"
      title={t('warningInShowBannerTitle')}
      type="warning"
      dismissible
      onDismiss={handleClick}
    >
      <span>{t('warningInShowBannerText')}</span>
    </Alert>
  )
}
