import { useTranslation } from 'react-i18next'

interface DescriptionProps {
  value: string
}

export const Description = (props: DescriptionProps) => {
  const { t } = useTranslation()
  const { value } = props

  function getDescriptionTranslated(description: string) {
    if (!description) return ''
    switch (description.trim()) {
      case 'Free shipping':
        return t('ledgerTransactionDescriptionFreeShipping')
      case 'Delivered by hand':
        return t('ledgerTransactionDescriptionHandDelivery')
      case 'Ledger Initilialization':
        return t('ledgerTransactionDescriptionLegerInit')
      case 'Shipping refund collection - 0124':
        return t('ledgerTransactionDescriptionRefundCollection')
      case 'Change of mind':
        return t('ledgerTransactionDescriptionChangeOfMind')
      case 'Manual transfer - Variable Bonus':
        return t('ledgerTransactionDescriptionVariableBonus')
      case 'Auto payout - Manual transfer - Variable Bonus':
        return t('ledgerTransactionDescriptionVariableBonus')
      case 'Manual transfer - Commercial Gesture':
        return t('ledgerTransactionDescriptionCommercialGesture')
      case 'Manual transfer - Shipping labels: overweight charges':
        return t('ledgerTransactionDescriptionOverweightCharges')
      case 'Manual transfer - Care - commercial gesture':
        return t('ledgerTransactionDescriptionCommercialGesture')
      case 'Manual transfer - Second Shipping':
        return t('ledgerTransactionDescriptionSecondShipping')
      case 'Manual transfer - Marketing - buyers activation (HH)':
        return t('ledgerTransactionDescriptionCommercialCampaign')
      case 'LEDGER - Label cancellation':
        return t('ledgerTransactionDescriptionLabelCancel')
      case 'Manual transfer - Marketing - marketing campaigns':
        return t('ledgerTransactionDescriptionCommercialCampaign')
      case 'Seller mistake':
        return t('ledgerTransactionDescriptionSellerMistake')
      case 'LEDGER - Shipping label cancellation':
        return t('ledgerTransactionDescriptionLabelCancel')
      case 'Manual transfer - Commission Correction':
        return t('ledgerTransactionDescriptionComissionCorrection')
      case 'Auto payout - Manual transfer - Care - commercial gesture':
        return t('ledgerTransactionDescriptionCommercialGesture')
      case 'LEDGER - Stripe Commission Refund':
        return t('ledgerTransactionDescriptionCommissionRefund')
      case 'Manual transfer - Refund Mistake':
        return t('ledgerTransactionDescriptionRefundMistake')
      case 'Auto payout - Manual transfer - Commercial Gesture':
        return t('ledgerTransactionDescriptionCommercialGesture')
      case 'Remboursement surchages 0524':
        return t('ledgerTransactionDescriptionOverweightCharges')
      case 'Manual transfer - Extra Shipping':
        return t('ledgerTransactionDescriptionExtraShipping')
      case 'Lost parcel':
        return t('ledgerTransactionDescriptionLostParcel')
      case 'LEDGER - Stripe Refund':
        return t('ledgerTransactionDescriptionRefund')
      case 'Ledger - Stripe refund':
        return t('ledgerTransactionDescriptionRefund')
      case 'LEDGER - Duplicate payment refunded':
        return t('ledgerTransactionDescriptionRefundDuplicatePayment')
      case 'Auto payout - Manual transfer - Second Shipping':
        return t('ledgerTransactionDescriptionSecondShipping')
      case 'Manual transfer - Care - insurance reimbursement':
        return t('ledgerTransactionDescriptionInsuranceReimbursment')
      case 'Auto payout - Manual transfer - Refund Mistake':
        return t('ledgerTransactionDescriptionRefundMistake')
      case 'Counterfeit':
        return t('ledgerTransactionDescriptionCounterfeit')
      case 'Auto payout - Manual transfer - Care - insurance reimbursement':
        return t('ledgerTransactionDescriptionInsuranceReimbursment')
      case 'Damaged product':
        return t('ledgerTransactionDescriptionInsuranceDamagedProduct')
      case 'Auto payout - Manual transfer - Extra Shipping':
        return t('ledgerTransactionDescriptionExtraShipping')
      case 'Auto payout - Manual transfer - Commission Correction':
        return t('ledgerTransactionDescriptionComissionCorrection')
      case 'Others':
        return t('ledgerTransactionDescriptionOthers')
      case 'Delight':
        return t('ledgerTransactionDescriptionDelight')

      default:
        if (description.includes('Payout failed:')) return t('ledgerTransactionDescriptionPayoutFailed')
        if (description.includes('Adjustement of')) return ''
        else return description
    }
  }

  return <p>{getDescriptionTranslated(value)}</p>
}
