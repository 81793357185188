import { Form, Input, Modal, message } from 'antd'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'

import { trackEvent } from '../../util/eventTracker'

import { useUpdateOrderNoteMutation } from './operations.generated'

import type { OrderedProduct } from '@/network/graphql/types.generated'

import globalStyle from '../../styles/_export.module.scss'

export type AddNoteFormType = {
  orderId: string
  note: string
}

const AddNoteForm = ({ product, onClose }: { product: OrderedProduct; onClose: () => void; showId?: any }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<AddNoteFormType>()
  const [isVisible, setIsVisible] = useState(false)
  const [updateNote, { loading }] = useUpdateOrderNoteMutation({
    onCompleted: () => {
      trackEvent('PRODUCT_NOTE_UPDATE', { order: product.order.id })
    },
    onError: (error) => message.error(error.message),
  })

  useEffect(() => {
    if (product) {
      setIsVisible(true)
    }
  }, [product])

  const onSubmit = async (values: AddNoteFormType) => {
    updateNote({
      variables: {
        input: {
          note: values.note,
          orderId: product.order?.id || '',
        },
      },
    })

    onClose()
    setIsVisible(false)
  }

  const handleCancel = () => {
    onClose()
    form.resetFields()
    setIsVisible(false)
  }

  const { name: ProductName, order } = product || {}
  const { note, customer } = order || {}
  const { username } = customer || {}

  return (
    <Modal
      closeIcon={<FaXmark color={globalStyle.black150} size={12} />}
      footer={null}
      open={isVisible}
      title={<div className="text-base text-black-130 normal-case">{`${ProductName} (@${username})`}</div>}
      destroyOnClose
      forceRender
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          note,
        }}
        onFinish={onSubmit}
      >
        <Form.Item name="note">
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item className="flex justify-end">
          <Button
            className="validate-action add-note-action primary"
            htmlType="submit"
            isLoading={loading}
            label={note ? t('showOrderDrawerNoteFormSubmitEdit') : t('showOrderDrawerNoteFormSubmitCreate')}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddNoteForm
