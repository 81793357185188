import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { notificationDanger, notificationWarning } from '@/components/ui/Notification/Notification'
import Config from '@/config/config'

import { useShipmentBulkShippingLabelsForBoxtalLazyQuery } from './operations.generated'

import type { OnShippingLabelsDownloadedOptions } from './types'

const { VITE_APP_LONG_TIMEOUT } = Config

type BulkDownloadShippingLabelsForBoxtalProps = {
  shipmentIds: string[]
  onDownloaded?: (shipmentsIds: string[], options?: OnShippingLabelsDownloadedOptions) => void
}

const BulkDownloadShippingLabelsForBoxtal = (props: BulkDownloadShippingLabelsForBoxtalProps) => {
  const { shipmentIds, onDownloaded } = props
  const hasSelectedShipments = !!shipmentIds.length

  const { t } = useTranslation()

  const [bulkDownloadShippingLabelsForBoxtal, { loading: bulkShippingLabelDownloadLoading }] =
    useShipmentBulkShippingLabelsForBoxtalLazyQuery()
  const isDisabled = bulkShippingLabelDownloadLoading || !hasSelectedShipments

  const handleBulkDownloadLabelsForBoxtal = async () => {
    if (!shipmentIds.length) {
      return
    }

    let shipmentsToSend = shipmentIds

    await bulkDownloadShippingLabelsForBoxtal({
      variables: {
        boxtalShipmentIds: shipmentIds,
      },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: async (data) => {
        if (data?.bulkShippingLabelsForBoxtal?.label) {
          window.open(data.bulkShippingLabelsForBoxtal.label)
        }
        if (
          data?.bulkShippingLabelsForBoxtal.missingShipments &&
          data.bulkShippingLabelsForBoxtal.missingShipments.edges.length > 0
        ) {
          const missingShipmentsList = data?.bulkShippingLabelsForBoxtal.missingShipments.edges
            .map((edge) => edge.node.customer.username)
            .join()

          shipmentsToSend = shipmentsToSend.filter(
            (shipmentId) =>
              !data?.bulkShippingLabelsForBoxtal.missingShipments.edges.map((edge) => edge.node.id).includes(shipmentId)
          )

          notificationWarning(t('missingLabelsFromBoxtal', { missingShipmentsList }))
        }
      },
      onError: (error) => {
        notificationDanger(error.message)
      },
    })

    if (onDownloaded) {
      onDownloaded(shipmentsToSend)
    }
  }

  return (
    <Button
      className="action download-action download-shipping-labels-action primary"
      disabled={isDisabled}
      icon={<FaDownload />}
      isLoading={bulkShippingLabelDownloadLoading}
      label={t('shipmentsBulkDownloadShippingLabelsButtonLabel', { count: shipmentIds.length })}
      onClick={handleBulkDownloadLabelsForBoxtal}
    />
  )
}

export default BulkDownloadShippingLabelsForBoxtal
