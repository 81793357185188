import { Trans, useTranslation } from 'react-i18next'
import { FaQuestionCircle } from 'react-icons/fa'

import DownloadBulkImportTemplateButton from '@/components/ImportProducts/DownloadBulkImportTemplateButton/DownloadBulkImportTemplateButton'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'

import './ProductsBulkImporterHowTo.scss'

const ProductsBulkImporterHowTo = () => {
  const { t } = useTranslation()

  return (
    <div className="how-to products-bulk-import-how-to">
      <h3 className="title">{t('productsBulkImporterHowToTitle')}</h3>
      <ol className="steps">
        <li>
          <Trans i18nKey={'productsBulkImporterHowToStep1'} />
        </li>
        <li>
          <Trans i18nKey={'productsBulkImporterHowToStep2'} />
        </li>
        <li>
          <Trans i18nKey={'productsBulkImporterHowToStep3'} />
        </li>
        <li>
          <Trans i18nKey={'productsBulkImporterHowToStep4'} />
        </li>
        <li>
          <Trans i18nKey={'productsBulkImporterHowToStep5'} />
        </li>
      </ol>
      <Button className="products-bulk-import-notice" href={t('bulkUploadNoticeLink')}>
        <FaQuestionCircle />
        {t('bulkUploadNoticeContent')}
      </Button>
      <Alert className="important-rules" emphasis="high" type="warning">
        <Trans i18nKey="productsBulkImporterImportantRules" shouldUnescape={false} />
      </Alert>
      <DownloadBulkImportTemplateButton />
    </div>
  )
}

export default ProductsBulkImporterHowTo
