export const convertHeicToPng = async (file: File): Promise<File> => {
  const heic2any = (await import('heic2any')).default

  try {
    // Use the heic2any library to convert the HEIC file to PNG
    const fileBlob = await heic2any({ blob: file, toType: 'image/png' })

    // Check if the result is a Blob or an array of Blobs
    const fileBlobConverted = fileBlob instanceof Blob ? [fileBlob] : fileBlob

    // Generate a new file name with the .png extension
    const newFileName = generateFileName(file.name, 'png')

    // Create a new File object with the converted Blob(s) and set the appropriate file name and type
    return new File(fileBlobConverted, newFileName, {
      type: 'image/png',
    })
  } catch (error) {
    // Handle conversion errors
    throw new Error('Failed to convert HEIC to PNG.')
  }
}

// Helper function to generate a new file name with the specified extension
const generateFileName = (originalName: string, newExtension: string): string => {
  // Get the file name without the extension
  const fileNameWithoutExtension = originalName.replace(/\.[^/.]+$/, '')

  // Concatenate the new extension
  return `${fileNameWithoutExtension}.${newExtension}`
}
