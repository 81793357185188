import { useCallback, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { preventChangeOnWheelScrollOnNumberInputs } from '@/helpers/forms/preventChangeOnWheelScrollOnNumberInputs'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct/types'

const PRICE_MAX_IN_CENTS = 10000000
const PRICE_MIN_IN_CENTS = 50

type ProductPriceFieldProps = {
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductPriceField = (props: ProductPriceFieldProps) => {
  const { value: price, onChange: handlePriceChange, onValidityChange = () => undefined, disabled = false } = props
  const { t } = useTranslation()

  const preventInputWheelOnNumberInput = useCallback(preventChangeOnWheelScrollOnNumberInputs, [])

  return (
    <Field
      disabled={disabled}
      label={t('productFormPriceLabel')}
      max={PRICE_MAX_IN_CENTS / 100}
      min={PRICE_MIN_IN_CENTS / 100}
      name="price"
      required={true}
      type="number"
      value={price}
      onChange={handlePriceChange}
      onValidityChange={(isValid: boolean) => onValidityChange('price', isValid)}
      onWheel={preventInputWheelOnNumberInput}
    />
  )
}

export default ProductPriceField
