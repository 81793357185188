import { useTranslation } from 'react-i18next'

import { EmptyState } from '@/components/ui/EmptyState/EmptyState'
import Loader from '@/components/ui/Loader/Loader'

import { useChatBoxContext } from '../../ChatBoxProvider'

import { ShowBannedFromShowOrSellerViewerItem } from './ShowBannedFromShowOrSellerViewerItem'

export const ShowAllBannedViewersList = () => {
  const { t } = useTranslation()
  const { bannedUsersFromSeller, bannedUsersFromShow, viewersLoading } = useChatBoxContext()

  const bannedUsers = bannedUsersFromSeller.concat(bannedUsersFromShow)

  if (viewersLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader />
      </div>
    )
  }

  const hasBannedUsers = bannedUsers && bannedUsers.length > 0

  return (
    <div className="h-full overflow-auto chat-banned-content">
      <div className="mt-2 mb-4">
        <p className="mb-2">{t('showAllBannedSubtitle')}</p>
        {hasBannedUsers && (
          <div className="flex flex-column gap-1">
            {bannedUsers.map((user) => user && <ShowBannedFromShowOrSellerViewerItem key={user.id} viewer={user} />)}
          </div>
        )}
        {!hasBannedUsers && (
          <EmptyState
            subtitle={t('showAllBannedViewersListIsEmptySubtitle')}
            title={t('showAllBannedViewersListIsEmptyTitle')}
          />
        )}
      </div>
    </div>
  )
}
