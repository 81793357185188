import { DatePicker, Select, Badge } from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaCaretLeft, FaCaretRight } from 'react-icons/fa6'
import { useInView } from 'react-intersection-observer'
import { useDispatch, connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchAllShows } from '@/actions/fetch-all-shows'
import CategoriesCloud from '@/components/CategoriesCloud/CategoriesCloud'
import {
  useCategoriesOrderedByPositionQuery,
  useParentCategoriesOrderedByPositionQuery,
} from '@/components/CategoriesCloud/operations.generated'
import { CreateShow } from '@/components/CreateShow/CreateShow'
import CreateShowButton from '@/components/CreateShowButton/CreateShowButton'
import { BreakerCannotCreateShow } from '@/components/SellerTier/BreakerCannotCreateShow/BreakerCannotCreateShow'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { getLegacyId } from '@/helpers/getLegacyId'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { useAppSelector } from '@/reducers'
import { resetShowsState } from '@/reducers/shows'
import { selectAllShows } from '@/selectors/shows'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'

import Day from './Day/Day'

import type { Category } from '@/network/graphql/types.generated'
import type { Show } from '@/types'
import type { Moment } from 'moment'

import style from './style.module.scss'

import './Calendar.scss'

const Calendar = ({ isShowsLoading, isStaff, userId, sellerLanguage }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const shows = useAppSelector(selectAllShows)
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()

  useDocumentTitle(t('sideMenuCalendarTitle'))

  const [selectedDate, setSelectedDate] = useState(moment().startOf('d'))

  const { data: parentCategories, loading: parentCategoriesLoading } = useParentCategoriesOrderedByPositionQuery({
    variables: {
      first: 10000,
    },
  })

  const { data: categoriesData, loading: isCategoriesLoading } = useCategoriesOrderedByPositionQuery({
    variables: {
      first: 10000,
    },
  })

  const categories = categoriesData?.categoriesOrderedByPosition.edges
    .filter(({ node: category }) => !category.isOffline)
    .map(({ node }) => node)

  const getDatesRange = () => {
    if (moment(selectedDate).subtract(3, 'day').isBefore(moment(), 'd')) {
      const datesRange = [
        moment(selectedDate),
        moment(selectedDate).add(1, 'days'),
        moment(selectedDate).add(2, 'days'),
        moment(selectedDate).add(3, 'days'),
        moment(selectedDate).add(4, 'days'),
        moment(selectedDate).add(5, 'days'),
        moment(selectedDate).add(6, 'days'),
      ]
      return datesRange
    } else {
      const datesRange = [
        moment(selectedDate).subtract(3, 'days'),
        moment(selectedDate).subtract(2, 'days'),
        moment(selectedDate).subtract(1, 'days'),
        moment(selectedDate),
        moment(selectedDate).add(1, 'days'),
        moment(selectedDate).add(2, 'days'),
        moment(selectedDate).add(3, 'days'),
      ]
      return datesRange
    }
  }

  const showsByCountry = shows.filter((show) => {
    return show.languageId === sellerLanguage
  })

  const getWeeklyShows = () => {
    return showsByCountry.filter((show) => moment(show?.startAt).isBetween(datesRange[0], datesRange[6], 'day', '[]'))
  }

  let storedDefaultCalendarCategory = null
  try {
    storedDefaultCalendarCategory = sessionStorage?.getItem('bitsDefaultCalendarCategory') || null
  } catch (e: any) {
    trackError(e)
  }

  const defaultCategory = isStaff ? 'all' : parseInt(storedDefaultCalendarCategory || '0')

  const [datesRange, setDatesRange] = useState<Moment[]>(getDatesRange())
  const [weeklyShows, setWeeklyShows] = useState<Show[]>([])
  const [selectedCategory, setSelectedCategory] = useState<number | string>(defaultCategory)

  const handleChangeWeek = (e: Moment | null) => {
    if (e) {
      if (moment(e).subtract(3, 'day').isBefore(moment(), 'd')) {
        setSelectedDate(moment())
      } else {
        setSelectedDate(moment(e))
      }
    }
  }

  const handleClickWeekChangeButton = (type: 'previous' | 'next') => {
    trackEvent('CALENDAR_CHANGE_DATE')

    if (type === 'previous') {
      handleChangeWeek(datesRange[3].subtract(7, 'day'))
    } else if (type === 'next') {
      handleChangeWeek(datesRange[3].add(7, 'day'))
    } else {
      return true
    }
  }

  const disabledDate = (current: Moment) => {
    return current.isBefore(moment().startOf('d'))
  }

  const isPreviousDisabled = () => {
    return moment(datesRange[0]).isBefore(moment().endOf('d')) || moment(datesRange[0]).isSame(moment(), 'day')
  }

  const customWeekStartEndFormat = () =>
    `${moment(datesRange[0]).format('DD/MM')} → ${moment(datesRange[6]).format('DD/MM')}`

  const handleOpenModal = (value: boolean) => {
    trackEvent('CALENDAR_OPEN_CREATE_SHOW_MODAL')
    setShowModal(value)
  }

  const handleChangeCategory = (value: number | string) => {
    trackEvent('CALENDAR_CHANGE_CATEGORY')
    setSelectedCategory(value)
  }

  useEffect(() => {
    trackEvent('CALENDAR_OPEN')
    dispatch(fetchAllShows())

    return () => dispatch(resetShowsState())
  }, [])

  useEffect(() => {
    setDatesRange(getDatesRange())
  }, [selectedDate])

  useEffect(() => {
    const weeklyShows = getWeeklyShows()

    if (selectedCategory)
      setWeeklyShows(
        weeklyShows.filter((show) => (selectedCategory === 'all' ? true : show.categoryId === selectedCategory))
      )
  }, [shows, datesRange, selectedCategory])

  const { Option } = Select

  const setDefaultCategory = (providedCategory: Category) => {
    const id = getLegacyId(providedCategory.id)
    sessionStorage?.setItem('bitsDefaultCalendarCategory', `${id}`)
    setSelectedCategory(id)
  }

  const { ref, inView } = useInView({
    threshold: 1,
  })

  return (
    <ViewContainer
      isLoading={isShowsLoading || isCategoriesLoading || parentCategoriesLoading}
      leftContent={
        <>
          <Link className="arrow-back" to="/shows">
            <FaArrowLeft />
          </Link>
          {t('calendarTitle')}
        </>
      }
    >
      <div className="flex items-center mb-4">
        {showModal && <CreateShow isShop={false} onClose={() => setShowModal(false)} />}
      </div>

      <div id="calendar-view">
        <BreakerCannotCreateShow className="mb-4" />

        {selectedCategory !== 0 && !isShowsLoading && !isCategoriesLoading && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CreateShowButton handleOnClick={() => handleOpenModal(true)} />
          </div>
        )}

        <div className={style.page__content}>
          {selectedCategory !== 0 && !isShowsLoading && !isCategoriesLoading && (
            <div className={style.filters__container}>
              <div className={style.datePicker__container}>
                <div
                  className={`${style.datePicker__previous_button} ${
                    isPreviousDisabled() && style.datePicker__button_disabled
                  }`}
                  onClick={() => handleClickWeekChangeButton('previous')}
                >
                  <FaCaretLeft size={12} />
                </div>

                <DatePicker
                  allowClear={false}
                  className={style.calendar__datePicker}
                  defaultValue={selectedDate}
                  disabledDate={disabledDate}
                  format={customWeekStartEndFormat}
                  size="middle"
                  style={{ borderRadius: 16, padding: 16, width: 276, textAlign: 'center' }}
                  onChange={handleChangeWeek}
                />
                <div className={`${style.datePicker__next_button}`} onClick={() => handleClickWeekChangeButton('next')}>
                  <FaCaretRight size={12} />
                </div>
              </div>

              <Select
                className={style.category__select}
                listItemHeight={10}
                placeholder={t('calendarSelectorCategoryLabel')}
                size="middle"
                value={selectedCategory}
                onChange={handleChangeCategory}
              >
                {isStaff && <Option value="all">{t('calendarSelectorOptionAllLabel')}</Option>}

                {categories?.map((category, index) => {
                  return (
                    <Option key={index} value={getLegacyId(category.id)}>
                      {category.name === 'Pokemon' ? 'Pokémon' : category.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          )}
          <div className={`${style.calendar__overflow_wrapper} card_shadow`}>
            {selectedCategory !== 0 && (
              <div
                className={
                  inView
                    ? `${style.calendar__header_row}`
                    : `${style.calendar__header_row}  ${style.calendar__header_row_scrolled}`
                }
              >
                <div ref={ref} style={{ backgroundColor: 'red', width: '0px', height: '0px' }} />
                {datesRange.map((date, index) => {
                  const isToday = moment(date).isSame(moment(), 'day')

                  return (
                    <div key={index} className={style.calendar__day_header}>
                      <span className="title_2">{moment(date).format('D')}</span>
                      <div className={style.day_header__right_container}>
                        <span className="callout_2">{moment(date).format('dddd')}</span>
                        <span className="callout_3">{moment(date).format('MMMM')}</span>
                      </div>
                      {isToday && (
                        <div style={{ zIndex: 3, bottom: '20px', right: '0px', position: 'absolute' }}>
                          <Badge.Ribbon text={t('calendarBadgeToday')} />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}

            {selectedCategory === 0 && (
              <div className={style.no_category__overlay}>
                <span className="title_2">{t('calendarChooseMainCategorie')}</span>
                <div className={style.no_category__categories_container}>
                  <CategoriesCloud handleCategorySelect={setDefaultCategory} center />
                </div>
              </div>
            )}

            {selectedCategory !== 0 && (
              <div className={style.background__container}>
                {datesRange.map((date, index) => {
                  const dailyShows = weeklyShows.filter(
                    (show) => date.format('LL') === moment(show.startAt).format('LL')
                  )

                  const allStartHours: string[] = []
                  const isDateBeforeToday = moment(date).endOf('d').isBefore(moment())

                  dailyShows.forEach((show) => {
                    const hour = moment(show.startAt).format('HH:mm').substring(0, 2)
                    if (!allStartHours.includes(hour)) allStartHours.push(hour)
                  })

                  return (
                    <Day
                      key={index}
                      allStartHours={allStartHours}
                      dailyShows={dailyShows}
                      date={date}
                      isDateBeforeToday={isDateBeforeToday}
                      parentCategories={parentCategories}
                      selectedCategory={selectedCategory}
                      userId={userId}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </ViewContainer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isShowsLoading: state.shows.isLoading,
    isStaff: state.authentication.user?.isStaff || false,
    sellerLanguage: state.authentication.user?.sellerConfig?.defaultShowLanguageId,
    userId: state.authentication.user?.id,
  }
}

export default connect(mapStateToProps)(Calendar)
