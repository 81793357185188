import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import ShieldIcon from '@/components/ui/Icons/ShieldIcon/ShieldIcon'

import type { SellerSubAccount } from '@/network/graphql/types.generated'

interface SubAccountsRemovalDialogProps {
  onCancel: () => void
  onComplete: () => void
  isLoading: boolean
  subAccountToDelete: SellerSubAccount | undefined
}

export const SubAccountsRemovalDialog = (props: SubAccountsRemovalDialogProps) => {
  const { onCancel, onComplete, isLoading, subAccountToDelete } = props
  const { t } = useTranslation()

  return (
    <Dialog
      className="subaccount-removal"
      isOpen={true}
      modal={true}
      title={t('removeSubAccountTitle')}
      onClose={onCancel}
    >
      <div className="delete-sub-account-content">
        <ShieldIcon />
        <p>{t('deleteSubAccountContent', { email: subAccountToDelete?.email })}</p>
      </div>
      <div className="delete-sub-account-actions">
        <Button
          className="secondary cancel-subaccount-removal-action"
          label={t('deleteSubAccountCancel')}
          onClick={onCancel}
        />
        <Button
          className="primary is-danger complete-subaccount-removal-action"
          isLoading={isLoading}
          label={t('deleteSubAccountOk')}
          onClick={onComplete}
        />
      </div>
    </Dialog>
  )
}
