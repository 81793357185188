import { Dropdown } from 'antd'
import { useState } from 'react'
import { FaEllipsis } from 'react-icons/fa6'

import { trackEvent } from '../../../../../../util/eventTracker'

import { ViewerActionsDropdownMenu } from './ViewerActionsDropdownMenu'

import type { Viewer } from '../../chatbox.helper'

type ViewerActionsDropdownProps = {
  viewer: Viewer
  hidden?: boolean
}

export const ViewerActionsDropdown = ({ viewer, hidden }: ViewerActionsDropdownProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const onDropdownVisibleChange = (openState: boolean) => {
    if (dropdownIsOpen) {
      trackEvent('SHOW_OPEN_VIEWERS_ACTION_MODAL')
    }
    setDropdownIsOpen(openState)
  }

  if (hidden) return null
  return (
    <Dropdown
      className="cursor-pointer rounded-lg py-1 px-2"
      dropdownRender={() => <ViewerActionsDropdownMenu setDropdownIsOpen={setDropdownIsOpen} viewer={viewer} />}
      open={dropdownIsOpen}
      placement="bottomRight"
      trigger={['click']}
      onOpenChange={(open) => onDropdownVisibleChange(open)}
    >
      <span className="invisible group-hover/viewerOrComment:visible">
        <FaEllipsis className="inline" />
      </span>
    </Dropdown>
  )
}
