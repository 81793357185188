import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa6'
import { useNavigate, useParams } from 'react-router-dom'

import Confirm from '@/components/Confirm/Confirm'
import { PRODUCT_FORM_ACTION } from '@/components/CreateOrEditProduct/types'
import GiveawayModal from '@/components/GiveawayModal/GiveawayModal'
import AddNoteForm from '@/components/ProductList/AddNoteForm'
import ExpandOrCollapseProductListButton from '@/components/ProductList/ExpandOrCollapseProductListButton/ExpandOrCollapseProductListButton'
import {
  useRemoveBulkProductFromShowMutation,
  useUpdateProductToFirstPositionMutation,
} from '@/components/ProductList/operations.generated'
import { TabName } from '@/components/ProductList/types'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { useShow } from '@/contexts/show/Show.context'
import { useUser } from '@/contexts/user/User.context'
import { ProductType } from '@/network/graphql/types.generated'

import { FlashSalesModal } from '../FlashSalesModal/FlashSalesModal'
import { notificationDanger, notificationSuccess } from '../ui/Notification/Notification'

import { OrderingProducts } from './OrderingProducts/OrderingProducts'
import ProductSearchInput from './ProductSearchInput/ProductSearchInput'
import { ProductsTab } from './ProductsTab/ProductsTab'
import AuctionProducts from './ProductTable/AuctionProducts'
import GiveawayProducts from './ProductTable/GiveawayProducts'
import InstantBuyProducts from './ProductTable/InstantBuyProducts'
import { SoldProducts } from './ProductTable/SoldProducts'
import { SortingOptions } from './SortingOptions/SortingOptions'

import type { OrderedProduct, Product, ShowGiveawayAudience } from '@/network/graphql/types.generated'

import './ProductList.scss'

interface ProductListProps {
  showId: number
  showCategory?: string
  sellerId?: string
  isActivityRunning: boolean
  launchGiveaway: (productId: string, audience: ShowGiveawayAudience, isRestrictedToShowCountry: boolean) => void
  launchAuction: (product: Product) => void
  launchFlashSale: (productId: string, discount: number | null) => void
  onExpand?: () => void
  isExpandable?: boolean
  isProductListExpanded?: boolean
  isShowBroadcasting?: boolean
  isShop?: boolean // TODO: get this from the URL instead of using a prop to make the component simpler
  currentTab: TabName
  lastCreatedProduct: Product | undefined
  editedProduct: Product | undefined
  setEditedProduct: (product: Product | undefined) => void
  setCurrentTab: (tab: TabName) => void
  setLastCreatedProduct: (product: Product) => void
  handleCloseCreateOrEditProduct: (type?: ProductType) => void
  stringProductId: string | undefined
  giveawayAudience: ShowGiveawayAudience | null
  giveawayInternational: boolean
  productInLaunch: Product | undefined
  isGiveawayModalVisible: boolean
  setIsGiveawayModalVisible: (isVisible: boolean) => void
  isFlashSaleModalVisible: boolean
  setIsFlashSaleModalVisible: (isVisible: boolean) => void
  openGiveawayModal: (product: Product) => void
  openFlashSaleModal: (product: Product) => void
}

export const productTypeToTabName = (productType: ProductType): TabName => {
  switch (productType) {
    case ProductType.Auction:
      return TabName.auction
    case ProductType.InstantBuy:
      return TabName.instantBuy
    case ProductType.Giveaway:
      return TabName.giveaway
    default:
      return TabName.auction
  }
}

function ProductList(props: ProductListProps) {
  const {
    showId,
    sellerId,
    isActivityRunning,
    launchAuction,
    launchGiveaway,
    launchFlashSale,
    onExpand = () => undefined,
    isProductListExpanded = false,
    isShowBroadcasting,
    isExpandable = true,
    isShop = false,
    currentTab,
    setEditedProduct,
    setCurrentTab,
    stringProductId,
    giveawayAudience,
    giveawayInternational = false,
    productInLaunch,
    isFlashSaleModalVisible,
    setIsFlashSaleModalVisible,
    isGiveawayModalVisible,
    setIsGiveawayModalVisible,
    openFlashSaleModal,
    openGiveawayModal,
  } = props

  const { t } = useTranslation()
  // const { showId, productId } = useParams<any>()
  const { productId } = useParams<any>()
  const navigate = useNavigate()

  const showGlobalId = `Show|${showId}`

  const {
    totalOrders,
    search,
    handleSearchChange,
    refetchProducts,
    handleAuctionPageChange,
    handleInstantBuyPageChange,
    handleGiveawayPageChange,

    auctions,
    totalAuctions,

    instantBuys,
    totalInstantBuys,

    giveaways,
    totalGiveaways,

    sold,
  } = useShow()

  const [selectedProducts, setSelectedProducts] = useState<string[]>()
  const [addNoteProductSelect, setAddNoteProductSelect] = useState<OrderedProduct>()
  const [isDeleting, setIsDeleting] = useState(false)

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessInventory: sellerCanAccessInventory, isInventoryInitialized } = sellerConfig || {}

  const canAccessInventory = Boolean(sellerCanAccessInventory && isInventoryInitialized)

  const [removeBulkProductFromShowMutation] = useRemoveBulkProductFromShowMutation()

  const tabItems = useMemo(() => {
    return [
      {
        key: 'auction',
        label: `${t('showProductsListTabSwitcherAuctions')} ${totalAuctions !== null ? `(${totalAuctions})` : ''}`,
      },
      {
        key: 'instantBuy',
        label: `${t('showProductsListTabSwitcherInstantBuy')} ${totalInstantBuys !== null ? `(${totalInstantBuys})` : ''}`,
      },
      {
        key: 'giveaway',
        label: `${t('showProductsListTabSwitcherGiveaways')} ${totalGiveaways !== null ? `(${totalGiveaways})` : ''}`,
      },
      {
        key: 'sold',
        label: `${t('showProductsListTabSwitcherSold')} ${totalOrders !== null ? `(${totalOrders})` : ''}`,
      },
    ]
  }, [t, totalOrders, totalAuctions, totalInstantBuys, totalGiveaways])

  const handleFlashSaleModalVisibility = useCallback((isVisible: boolean) => {
    setIsFlashSaleModalVisible(isVisible)
  }, [])

  const handleEditOrDuplicateProduct = useCallback(
    (product: Product, action?: PRODUCT_FORM_ACTION) => {
      const productId = product.legacyId
      const url = `/${isShop ? 'shops' : 'shows'}/${showId}/products/${productId}?productType=${currentTab}&action=${action}`
      navigate(url)

      setEditedProduct(product)
    },
    [isShop, currentTab, showId]
  )

  const onCancelOrderedProductWithFailedPayment = async () => {
    await refetchProducts()
  }

  const onLaunchGiveaway = (
    productId: string,
    audience: ShowGiveawayAudience,
    isOpenedToInternationalBuyers: boolean
  ) => {
    launchGiveaway(productId, audience, isOpenedToInternationalBuyers)
  }

  const [updateProductToFirstPosition] = useUpdateProductToFirstPositionMutation()
  const handleUpdateProductToFirstPosition = async (productId: string, productType: ProductType) => {
    await updateProductToFirstPosition({
      variables: {
        input: { productId, showId: showGlobalId, productType },
      },
    })
    await refetchProducts()
  }

  const handleTabChange = useCallback(
    (selected: string) => {
      const newTab = tabItems.find((item) => item.key === selected)?.key || TabName.auction

      const url = `/${isShop ? 'shops' : 'shows'}/${showId}/?productType=${newTab}`
      navigate(url)

      setCurrentTab(newTab as TabName)
    },
    [isShop, history, showId, tabItems]
  )

  const handleConfirmDeleteProducts = useCallback(async () => {
    if (!selectedProducts) {
      return
    }

    setIsDeleting(true)
    await removeBulkProductFromShowMutation({
      variables: {
        input: {
          products: selectedProducts,
          showId: showGlobalId,
        },
      },
      onCompleted: (data) =>
        notificationSuccess(
          t('deleteBulkProductConfirmationMessage', { count: data.removeBulkProductFromShow?.count })
        ),
      onError: (err) => {
        notificationDanger(err.message)
      },
    })
    handleAuctionPageChange(1)
    handleInstantBuyPageChange(1)
    handleGiveawayPageChange(1)
    refetchProducts()
    setSelectedProducts([])
    setIsDeleting(false)
  }, [selectedProducts])

  useEffect(() => {
    if (productId) {
      return
    }

    navigate(`/${isShop ? 'shops' : 'shows'}/${showId}/?productType=${currentTab}`)
  }, [productId, isShop, currentTab, history, showId])

  useEffect(() => {
    if (!productId || productId === 'new') {
      return
    }
    const legacyProductId = Number(productId)

    // TODO: improve the following by using a single "products" variable
    let editedProduct
    if (currentTab === 'auction') {
      editedProduct = (auctions || []).find(({ legacyId }) => legacyId === legacyProductId)
    } else if (currentTab === 'instantBuy') {
      editedProduct = (instantBuys || []).find(({ legacyId }) => legacyId === legacyProductId)
    } else if (currentTab === 'giveaway') {
      editedProduct = (giveaways || []).find(({ legacyId }) => legacyId === legacyProductId)
    } else if (currentTab === 'sold') {
      editedProduct = (sold || []).find(({ legacyId }) => legacyId === legacyProductId)
    }

    setEditedProduct(editedProduct)
  }, [productId, currentTab, auctions, instantBuys, giveaways, sold])

  const handleAddNoteClosed = useCallback(() => {
    setAddNoteProductSelect(undefined)
  }, [])

  const selectedProductsCount = selectedProducts?.length ?? 0

  return (
    <>
      {isGiveawayModalVisible && (
        <GiveawayModal
          giveawayAudience={giveawayAudience}
          giveawayInternational={giveawayInternational}
          launchGiveaway={onLaunchGiveaway}
          productId={stringProductId}
          onChangeModalVisibility={setIsGiveawayModalVisible}
        />
      )}
      {isFlashSaleModalVisible && productInLaunch && (
        <FlashSalesModal
          isModalVisible={isFlashSaleModalVisible}
          launchFlashSale={launchFlashSale}
          product={productInLaunch}
          onChangeModalVisibility={handleFlashSaleModalVisibility}
        />
      )}

      <Card className="product-list-container" noPadding={true}>
        <div className="product-list-header">
          <ProductsTab className="product-list-tabs" initial={currentTab} tabs={tabItems} onChange={handleTabChange} />

          <div className="actions product-list-actions">
            <ProductSearchInput className="product-search-input" value={search ?? ''} onChange={handleSearchChange} />

            {currentTab !== 'sold' && (
              <>
                <OrderingProducts
                  showId={showGlobalId}
                  productType={
                    currentTab === 'auction'
                      ? ProductType.Auction
                      : currentTab === 'instantBuy'
                        ? ProductType.InstantBuy
                        : ProductType.Giveaway
                  }
                />
                <SortingOptions />
              </>
            )}

            <Confirm
              confirmActionLabel={t('commonDelete')}
              message={`${t('showProductsListBulkDeleteConfirmationMessage', { count: selectedProductsCount })} ${canAccessInventory ? t('showProductsListTableDeleteProductContentWithInventory') : ''}`}
              onConfirm={handleConfirmDeleteProducts}
            >
              <Button
                className="primary delete-products-action is-danger"
                disabled={!selectedProductsCount}
                icon={<FaTrash />}
                isLoading={isDeleting}
                tooltip={t('showProductsListBulkDeleteTitle')}
              />
            </Confirm>

            {addNoteProductSelect && <AddNoteForm product={addNoteProductSelect} onClose={handleAddNoteClosed} />}

            {isExpandable && (
              <ExpandOrCollapseProductListButton isExpanded={isProductListExpanded} onToggle={onExpand} />
            )}
          </div>
        </div>
        <div className="product-list-table">
          {currentTab === 'auction' && (
            <AuctionProducts
              handleUpdateProductToFirstPosition={handleUpdateProductToFirstPosition}
              isActivityRunning={isActivityRunning}
              isShowBroadcasting={isShowBroadcasting}
              launchAuction={launchAuction}
              selectedRows={selectedProducts ?? []}
              onProductEditClick={(product: Product) => handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.EDIT)}
              onSelectedRows={setSelectedProducts}
              onProductDuplicateClick={(product: Product) =>
                handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.DUPLICATE)
              }
            />
          )}

          {currentTab === 'instantBuy' && (
            <InstantBuyProducts
              handleUpdateProductToFirstPosition={handleUpdateProductToFirstPosition}
              isActivityRunning={isActivityRunning}
              isShowBroadcasting={isShowBroadcasting}
              openFlashSaleModal={openFlashSaleModal}
              selectedRows={selectedProducts ?? []}
              onProductEditClick={(product: Product) => handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.EDIT)}
              onSelectedRows={setSelectedProducts}
              onProductDuplicateClick={(product: Product) =>
                handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.DUPLICATE)
              }
            />
          )}

          {currentTab === 'giveaway' && (
            <GiveawayProducts
              handleUpdateProductToFirstPosition={handleUpdateProductToFirstPosition}
              isActivityRunning={isActivityRunning}
              isShowBroadcasting={isShowBroadcasting}
              openGiveawayModal={openGiveawayModal}
              selectedRows={selectedProducts ?? []}
              onProductEditClick={(product: Product) => handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.EDIT)}
              onSelectedRows={setSelectedProducts}
              onProductDuplicateClick={(product: Product) =>
                handleEditOrDuplicateProduct(product, PRODUCT_FORM_ACTION.DUPLICATE)
              }
            />
          )}

          {currentTab === 'sold' && (
            <SoldProducts
              sellerId={sellerId}
              onAddNoteProduct={setAddNoteProductSelect}
              onCancelOrderedProductWithFailedPayment={onCancelOrderedProductWithFailedPayment}
            />
          )}
        </div>
      </Card>
    </>
  )
}

export default ProductList
