import { Popover } from 'antd'

import style from './style.module.scss'

type Props = {
  children: React.ReactNode | string | number
  title?: React.ReactNode | string | number
  content?: React.ReactNode | string | number
  maxWidth?: number | string
  width?: number | string
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
  color?: string
}

const CustomPopover = ({ children, title, content, width, maxWidth, placement, color }: Props) => {
  return (
    <Popover
      className={style.popover__container}
      color={color}
      content={content}
      placement={placement || 'bottom'}
      title={title ? <span className={style.popover__title}>{title}</span> : undefined}
      overlayStyle={{
        width: width || 500,
        maxWidth: maxWidth || '90%',
      }}
    >
      {children}
    </Popover>
  )
}

export default CustomPopover
