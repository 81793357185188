import { useMemo } from 'react'

import Loader from '@/components/ui/Loader/Loader'
import { Country } from '@/data/countriesClusters'
import { Currency } from '@/network/graphql/types.generated'

import Card from '../ui/Card/Card'

import { useGetSellerProfessionalStatusQuery } from './operations.generated'
import SellerPayoutInfo from './SellerPayoutInfo'

import type { User } from '@/types'

interface SellerPayoutInfoContainerProps {
  user: Pick<User, 'sellerConfig' | 'percentageFee' | 'fixedFee' | 'countryId'>
}

export const SellerPayoutInfoContainer = (props: SellerPayoutInfoContainerProps) => {
  const { user } = props
  const { sellerConfig, percentageFee, fixedFee, countryId } = user

  const { data: sellerStatus, loading } = useGetSellerProfessionalStatusQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !user,
  })

  const isProfessionalSeller = useMemo(() => sellerStatus?.viewer?.isProfessionalSeller, [sellerStatus])

  const currency = sellerConfig.sellerCurrency

  if (loading) {
    return (
      <Card>
        <Loader />
      </Card>
    )
  }

  return (
    <SellerPayoutInfo
      commission={percentageFee ?? null}
      currency={currency ?? Currency.Eur}
      fixedFee={fixedFee ?? null}
      isFrenchSeller={!!countryId && countryId === Country.FR}
      isProSeller={isProfessionalSeller}
    />
  )
}
