import { RatingStar } from './RatingStar'

import style from './style.module.scss'

interface RatingStarsProps {
  rating: number
}

const getStarPercentage = (starIndex: number, rating: number) => {
  const majorDigit = parseInt(String(rating).slice(0, 1))
  const minorDigit = String(rating)

  if (starIndex <= majorDigit) return '100'
  if (starIndex === majorDigit + 1) {
    return minorDigit.substring(2).length > 1 ? minorDigit.slice(2, 4) : `${minorDigit.slice(2, 3)}0`
  }
  return '0'
}

const RatingStars = ({ rating }: RatingStarsProps) => {
  return (
    <div>
      <div className={style.rating_stars__stars_container}>
        <RatingStar percentage={getStarPercentage(1, rating)} />
        <RatingStar percentage={getStarPercentage(2, rating)} />
        <RatingStar percentage={getStarPercentage(3, rating)} />
        <RatingStar percentage={getStarPercentage(4, rating)} />
        <RatingStar percentage={getStarPercentage(5, rating)} />
      </div>
    </div>
  )
}

export default RatingStars
