import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLastSoldProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLastSoldProductsQuery = { __typename?: 'Query', lastSoldProducts: { __typename?: 'LastSoldProductsWithCustomerConnection', edges: Array<{ __typename?: 'LastSoldProductsWithCustomerEdge', customer: { __typename?: 'User', id: string, username: string }, node: { __typename?: 'OrderedProduct', name: string, createdAt: Date, amountWithCurrency: { __typename?: 'AmountWithCurrency', amount: number, currency: Types.Currency } } }> } };

export type GetMonthlyStatisticsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMonthlyStatisticsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', sellerMonthlyStatistics?: { __typename?: 'SellerMonthlyStatistics', commentCountMtd: number, commentCountLastMonth: number, newFollowerCountMtd: number, newFollowerCountLastMonth: number, showCountMtd: number, showCountLastMonth: number, soldItemsCountMtd: number, soldItemsCountLastMonth: number, totalGmvMtd: number, totalGmvLastMonth: number, viewerCountMtd: number, viewerCountLastMonth: number } | null, sellerConfig?: { __typename?: 'SellerConfig', sellerCurrency: Types.Currency } | null } | null };


export const GetLastSoldProductsDocument = gql`
    query GetLastSoldProducts {
  lastSoldProducts {
    edges {
      customer {
        id
        username
      }
      node {
        name
        createdAt
        amountWithCurrency {
          amount
          currency
        }
      }
    }
  }
}
    `;

/**
 * __useGetLastSoldProductsQuery__
 *
 * To run a query within a React component, call `useGetLastSoldProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSoldProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSoldProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastSoldProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>(GetLastSoldProductsDocument, options);
      }
export function useGetLastSoldProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>(GetLastSoldProductsDocument, options);
        }
export function useGetLastSoldProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>(GetLastSoldProductsDocument, options);
        }
export type GetLastSoldProductsQueryHookResult = ReturnType<typeof useGetLastSoldProductsQuery>;
export type GetLastSoldProductsLazyQueryHookResult = ReturnType<typeof useGetLastSoldProductsLazyQuery>;
export type GetLastSoldProductsSuspenseQueryHookResult = ReturnType<typeof useGetLastSoldProductsSuspenseQuery>;
export type GetLastSoldProductsQueryResult = Apollo.QueryResult<GetLastSoldProductsQuery, GetLastSoldProductsQueryVariables>;
export const GetMonthlyStatisticsDocument = gql`
    query GetMonthlyStatistics {
  viewer {
    sellerMonthlyStatistics {
      commentCountMtd
      commentCountLastMonth
      newFollowerCountMtd
      newFollowerCountLastMonth
      showCountMtd
      showCountLastMonth
      soldItemsCountMtd
      soldItemsCountLastMonth
      totalGmvMtd
      totalGmvLastMonth
      viewerCountMtd
      viewerCountLastMonth
    }
    sellerConfig {
      sellerCurrency
    }
  }
}
    `;

/**
 * __useGetMonthlyStatisticsQuery__
 *
 * To run a query within a React component, call `useGetMonthlyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonthlyStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>(GetMonthlyStatisticsDocument, options);
      }
export function useGetMonthlyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>(GetMonthlyStatisticsDocument, options);
        }
export function useGetMonthlyStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>(GetMonthlyStatisticsDocument, options);
        }
export type GetMonthlyStatisticsQueryHookResult = ReturnType<typeof useGetMonthlyStatisticsQuery>;
export type GetMonthlyStatisticsLazyQueryHookResult = ReturnType<typeof useGetMonthlyStatisticsLazyQuery>;
export type GetMonthlyStatisticsSuspenseQueryHookResult = ReturnType<typeof useGetMonthlyStatisticsSuspenseQuery>;
export type GetMonthlyStatisticsQueryResult = Apollo.QueryResult<GetMonthlyStatisticsQuery, GetMonthlyStatisticsQueryVariables>;