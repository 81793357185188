const ShopIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6649 12.6509C11.6649 14.6926 9.93569 16.3476 7.96819 16.3476C6.00069 16.3476 4.27148 14.7537 4.27148 12.6509C4.27148 11.5887 4.91934 8.73386 5.35403 7.41063C5.76358 6.16044 6.92995 5.31738 8.24601 5.31738H22.4328C23.7441 5.31738 24.9057 6.15325 25.3212 7.39625C25.7643 8.72189 26.4253 11.5851 26.4253 12.6509C26.4253 14.6387 24.6961 16.3476 22.7286 16.3476C20.7611 16.3476 19.0307 14.6926 19.0307 12.6509"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M5.77734 19.2021V21.3325C5.77734 24.4652 7.72808 26.6781 10.8631 26.6781H19.8312C22.9676 26.6781 24.9182 24.4652 24.9182 21.3325V19.2021"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11.6514 11.5V12.6556C11.6514 14.6974 13.3063 16.3522 15.3481 16.3522C17.3898 16.3522 19.0447 14.6974 19.0447 12.6556V11.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default ShopIcon
