import { Divider, Form, Input, Select, message as messageNotify } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaLink, FaPhone } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { ShowAuctionStatus } from '@/network/graphql/types.generated'
import { trackWarning } from '@/util/sentry'
import showIssues, {
  IssueTypeTag,
} from '@/views/Show/components/IssueReporter/CustomerSupportAvailability/data/showIssues'

import InputLabel from '../../../../../components/InputLabel'
import CustomerSupportAvailability from '../CustomerSupportAvailability/CustomerSupportAvailability'
import { useReportIssueInShowMutation } from '../operations.generated'
import UnstuckShow from '../UnstuckShow/UnstuckShow'

import './IssueReporterForm.scss'

type IssueReporterFormProps = {
  showId: string
  currentAuction: any // TODO improve type
  onClosePanelClick: () => void
}

const IssueReporterForm = (props: IssueReporterFormProps) => {
  const { showId, onClosePanelClick, currentAuction } = props

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isFormValid, setIsFormValid] = useState(false)

  const { Option } = Select
  const { TextArea } = Input

  const [reportIssueInShowMutation, { loading }] = useReportIssueInShowMutation()

  const checkIsFormValid = () => {
    const messageValue = form.getFieldValue('message')
    const topicValue = form.getFieldValue('topic')

    const newFormState = messageValue && topicValue

    if (newFormState !== isFormValid) setIsFormValid(newFormState)
  }

  const handleSubmitForm = ({ message, topic }: { message: string; topic: string }) => {
    const issue = showIssues.find((issue) => issue.key === topic)

    if (!issue) {
      trackWarning(`Couldn't find an issue type matching topic`, {
        meta: { scope: 'IssueReporterForm' },
      })
    }

    reportIssueInShowMutation({
      variables: {
        input: {
          message,
          showId: `Show|${showId}`,
          topic: t(issue?.translationKey || 'No topic'),
          tag: issue?.tag || IssueTypeTag.emergency,
        },
      },
      onCompleted: () => {
        messageNotify.success(t('showIssueReporterSubmitSuccess'))
        onClosePanelClick()
      },
      onError: () => {
        messageNotify.error(t('showIssueReporterSubmitError'))
      },
    })
  }

  const showGlobalId = `Show|${showId}`
  return (
    <Form className="issue-reporter-form" form={form} onFinish={handleSubmitForm}>
      <h3 className="body_4 mb-4">{t('showIssueReporterPanelFrequentlyAskedTitle')}</h3>
      <ul className="faq-links">
        {showIssues.map(
          (universityLink) =>
            universityLink.link !== '' && (
              <li key={universityLink.key}>
                <a className="mb-2 flex items-center" href={t(universityLink.link)} rel="noreferrer" target="_blank">
                  {t(universityLink.translationKey)}
                  <span className="icon">
                    <FaLink />
                  </span>
                </a>
              </li>
            )
        )}
      </ul>

      {currentAuction && (
        <UnstuckShow
          endDate={currentAuction?.endAt || new Date()}
          showId={showGlobalId}
          startDate={currentAuction?.node?.auction?.serverTime || new Date()}
          status={currentAuction?.node?.auction?.status || ShowAuctionStatus.Closed}
        />
      )}

      <Divider />
      <Button
        className="secondary request-onboarding-session-action"
        href={t('signUpFormRequestOnboardingSessionLink')}
        icon={<FaPhone />}
        label={t('onboardingModalFormRequestOnboardingSessionLinkLabel')}
        target="_blank"
      />
      <Divider />

      <h3 className="body_4 mb-4 mt-1">{t('showIssueReporterPanelContactUsTitle')}</h3>

      <div className="mb-2 w-full">
        <InputLabel title={t('showIssueReporterPanelContactUsFormTopicLabel')} mandatory />

        <Form.Item
          className="w-full"
          name="topic"
          rules={[
            {
              required: true,
              message: t('showIssueReporterPanelContactUsFormMissingTopicError'),
            },
          ]}
        >
          <Select className="w-full" onChange={checkIsFormValid}>
            {showIssues.map((issue) => (
              <Option key={issue.key}>{t(issue.translationKey)}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="mb-2">
        <div className="body_4 mb-2 mt-4">
          <InputLabel title={t('showIssueReporterPanelContactUsFormMessageLabel')} mandatory />
        </div>

        <span className="pt-3">{t('showIssueReporterPanelContactUsFormMessageNotice')}</span>
        <div className="mt-1">
          <Form.Item
            className="w-full"
            name="message"
            rules={[
              {
                required: true,
                message: t('showIssueReporterPanelContactUsFormMissingMessageError'),
              },
            ]}
          >
            <TextArea rows={3} onChange={checkIsFormValid} />
          </Form.Item>
        </div>
      </div>

      <Form.Item className="w-full">
        <Button
          className="validate-action primary"
          disabled={!isFormValid}
          htmlType="submit"
          isLoading={loading}
          label={t('commonSend')}
        />
      </Form.Item>

      <CustomerSupportAvailability />
    </Form>
  )
}

export default IssueReporterForm
