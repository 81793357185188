import { Modal } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { trackEvent } from '@/util/eventTracker'

import { useRaid } from './hooks/use-raid'

import type { CurrentRaidShowQuery, RaidBasicFragmentFragment } from '@/views/Show/operations.generated'

import './CurrentRaid.scss'

interface RaidProps {
  showGlobalId: string
  currentRaidData?: CurrentRaidShowQuery
}

const CurrentRaid = (props: RaidProps) => {
  const { t } = useTranslation()
  const { currentRaidData, showGlobalId } = props
  const { sendRaid, sendRaidMutationLoading, cancelRaid, cancelRaidMutationLoading } = useRaid({
    showGlobalId,
  })

  const { node } = currentRaidData || {}
  const raid: RaidBasicFragmentFragment | undefined =
    node?.__typename === 'Show' ? (node.showRaid as RaidBasicFragmentFragment) : undefined
  const { destinationShow, participantsCount = 0 } = raid || {}

  const handleSendRaidClick = useCallback(() => {
    trackEvent('SHOW_RAID_CLICKED', { show: showGlobalId })

    // TODO: Use <Confirm> component instead of the following
    Modal.confirm({
      title: t('showRaidCardConfirmModalTitle'),
      content: t('showRaidCardConfirmModalContent', {
        showName: destinationShow?.name,
        sellerName: destinationShow?.seller?.username,
      }),
      okText: t('showRaidCardConfirmModalOkButtonText'),
      cancelText: t('showRaidCardConfirmModalCancelButtonText'),
      onOk: async () => {
        trackEvent('SHOW_RAID_SENT', {
          currentShowId: showGlobalId,
          destinationShowId: destinationShow?.id,
        })
        await sendRaid()
      },
    })
  }, [destinationShow, sendRaid, t])

  const handleCancelClick = useCallback(async () => {
    trackEvent('SHOW_RAID_CANCELLED', { show: showGlobalId })
    await cancelRaid()
  }, [cancelRaid])

  const { name: destinationShowName, seller } = destinationShow || {}
  const { username: sellerUsername } = seller || {}

  return (
    <Card className="current-activity current-raid" title={t('showRaidCardTitle')}>
      {!destinationShow && <Loader />}
      {destinationShow && (
        <>
          <dl>
            <dt className="show-name-title">{t('showRaidCardShowNameTitle')}</dt>
            <dd className="show-name">
              <span className="value">{destinationShowName}</span>
            </dd>

            <dt className="seller-name-title">{t('showRaidCardSellerNameTitle')}</dt>
            <dd className="seller-name">
              <span className="value">{sellerUsername ? `@${sellerUsername}` : ''}</span>
            </dd>

            <dt className="participants-count-title">{t('showRaidCardViewerCountTitle')}</dt>
            <dd className="participants-count">
              <data>{participantsCount}</data>
              <span className="unit">&nbsp;{t('showRaidParticipantsUnit')}</span>
            </dd>
          </dl>

          <div className="actions">
            <Button
              className="cancel-action secondary cancel-raid-action"
              disabled={sendRaidMutationLoading}
              isLoading={cancelRaidMutationLoading}
              label={t('showRaidCardCancelButton')}
              onClick={handleCancelClick}
            />
            <Button
              className="validate-action primary send-raid-action"
              disabled={sendRaidMutationLoading}
              isLoading={sendRaidMutationLoading}
              label={t('showRaidCardSubmitButton')}
              onClick={handleSendRaidClick}
            />
          </div>
        </>
      )}
    </Card>
  )
}

export default CurrentRaid
