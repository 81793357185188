import dayjs from 'dayjs'
import { memo } from 'react'

import User from '../../../../../../components/ui/User/User'
import { UserType } from '../../chatbox.helper'
import { useChatBoxContext } from '../../ChatBoxProvider'
import { ViewerActionsDropdown } from '../ViewerActionsDropdown/ViewerActionsDropdown'

import { CommentItemWithEmote } from './CommentItemWithEmote'

import type { EmoteMap } from '../../hooks/useChat'
import type { LoyaltyLevel } from '@/network/graphql/types.generated'
import type { CommentFragment } from '@/views/Shows/operations.generated'

interface CommentItemProps {
  item: CommentFragment
  emoteMap: EmoteMap | undefined
}

export const CommentItem = memo(({ item, emoteMap }: CommentItemProps) => {
  const { moderators, fontSize } = useChatBoxContext()

  const { author, isAuthoredByShowSeller, date } = item

  const username = author.username

  const userType: UserType = author.isStaff
    ? UserType.Admin
    : moderators.some((moderator) => moderator.id === author.id)
      ? UserType.Moderator
      : UserType.User

  if (!item) return null

  return (
    <div
      className={`flex hover:bg-slate-100 py-1 break-words flex-row group/viewerOrComment chat-comment-message chat-comment-message-${fontSize}`}
    >
      <div className="basis-5/6">
        <User
          loyalty={item.userLoyaltyLevelForShowSeller as LoyaltyLevel}
          message={<CommentItemWithEmote emoteMap={emoteMap} item={item} />}
          src={author.avatarUrl}
          userType={userType}
          isSmall
        >
          {username}
        </User>
      </div>

      <div className="basis-1/6 text-xs text-black-120 text-right mr-2">
        <ViewerActionsDropdown hidden={author.isStaff || isAuthoredByShowSeller} viewer={author} />
        <span className="group-hover/viewerOrComment:hidden">{dayjs(date).format('HH:mm')}</span>
      </div>
    </div>
  )
})
