const GiftIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 33 32" width="33" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M16.1492 9.5226H11.3453C9.87157 9.5226 8.67676 8.28627 8.67676 6.76195C8.67676 5.23632 9.87157 4 11.3453 4C15.0816 4 16.1492 9.5226 16.1492 9.5226Z"
        fillRule="evenodd"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        clipRule="evenodd"
        d="M16.1494 9.5226H20.953C22.4267 9.5226 23.6215 8.28627 23.6215 6.76195C23.6215 5.23632 22.4267 4 20.953 4C17.2167 4 16.1494 9.5226 16.1494 9.5226Z"
        fillRule="evenodd"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M9.64563 9.52246H22.6394C25.509 9.52246 27.2979 11.6591 27.2979 14.6779V22.8392C27.2979 25.858 25.4974 27.9947 22.6394 27.9947H9.64563C6.776 27.9947 5 25.858 5 22.8392V14.6779C5 11.6591 6.776 9.52246 9.64563 9.52246Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.25 9.53823V14.9651L15.9951 14.4142H16.008L18.7532 14.9573L18.7401 9.52246"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default GiftIcon
