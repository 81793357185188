import { useCallback, useState } from 'react'

import Button from '@/components/ui/Button/Button'

import IssueReporterPanel from './IssueReporterPanel/IssueReporterPanel'

import './IssueReporter.scss'

type IssueReporterProps = {
  showId: string
  currentAuction: any
}

const IssueReporter = (props: IssueReporterProps) => {
  const { showId, currentAuction } = props
  const [isPanelVisible, setIsPanelVisible] = useState(false)

  const handleOpenIssueReporterClick = useCallback(() => setIsPanelVisible(true), [])
  const handleCloseIssueReporter = useCallback(() => setIsPanelVisible(false), [])

  return (
    <>
      <Button className="open-issue-reporter-action" label={'?'} onClick={handleOpenIssueReporterClick} />
      {isPanelVisible && (
        <IssueReporterPanel currentAuction={currentAuction} showId={showId} onClose={handleCloseIssueReporter} />
      )}
    </>
  )
}

export default IssueReporter
