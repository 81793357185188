import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

import type { ChangeEvent } from 'react'

type ShowRatingReasonFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  options: { value: string; label: string }[]
}

const ShowRatingReasonField = (props: ShowRatingReasonFieldProps) => {
  const { t } = useTranslation()

  const { value, options, onChange = () => undefined } = props

  return (
    <Field
      label={t('ShowRatingModalReason')}
      name="reason"
      options={options}
      required={true}
      type="select"
      value={value}
      onChange={onChange}
    />
  )
}

export default ShowRatingReasonField
