import { memo } from 'react'

const ProductsPreviewTableHeaders = () => {
  return (
    <thead>
      <tr>
        <th className="row-number">#</th>
        <th className="row-select">&nbsp;</th>
        <th className="name">Name</th>
        <th className="category">Category</th>
        <th className="selling-mode">Selling Mode</th>
        <th className="description">Description</th>
        <th className="quantity">Quantity</th>
        <th className="starting-price">Starting Price</th>
        <th className="instant-buy-price">Instant Buy Price</th>
        <th className="images">Images</th>
        <th className="brand">Brand</th>
        <th className="model">Model</th>
        <th className="size">Size</th>
        <th className="color">Color</th>
        <th className="gender">Gender</th>
        <th className="condition">Condition</th>
        <th className="card-type">Card type</th>
        <th className="card-language">Card Language</th>
        <th className="card-grade">Card Grade</th>
        <th className="card-grading-service">Card Grading Service</th>
        <th className="card-condition">Condition</th>
      </tr>
    </thead>
  )
}

export default memo(ProductsPreviewTableHeaders)
