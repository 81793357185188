import { ShowShippingOption } from '../types'

export interface ShippingOption {
  label: string
  value: ShowShippingOption
  amount: number
}

interface ShowShippingCategories {
  [key: string]: {
    [key: number | string]: Array<ShippingOption>
  }
}

const FrenchFashionShippingOptions = [
  {
    label: 'createShowModalDetailsShippingLetter',
    amount: 3.2,
    value: ShowShippingOption.letter,
  },
  {
    label: 'commonSmallPackage',
    value: ShowShippingOption.fashion_small_package,
    amount: 5,
  },
  {
    label: 'commonSmallPackageIncremental',
    value: ShowShippingOption.fashion_small_package_incremental,
    amount: 3,
  },
  {
    label: 'commonMediumPackage',
    value: ShowShippingOption.fashion_medium_package,
    amount: 7,
  },
  {
    label: 'commonMediumPackageIncremental',
    value: ShowShippingOption.fashion_medium_package_incremental,
    amount: 3,
  },
  {
    label: 'commonLargePackage',
    value: ShowShippingOption.fashion_large_package,
    amount: 10,
  },
  {
    label: 'commonLargePackageIncremental',
    value: ShowShippingOption.fashion_large_package_incremental,
    amount: 5,
  },
]

const FrenchIncrementalShippingOptions = [
  {
    label: 'createShowModalDetailsShippingLetter',
    amount: 3.2,
    value: ShowShippingOption.letter,
  },
  {
    label: 'shippingLetterIncremental',
    amount: 1.3,
    value: ShowShippingOption.letter_incremental,
  },
  {
    label: 'createShowModalDetailsShippingPackage',
    amount: 5,
    value: ShowShippingOption.package,
  },
  {
    label: 'shippingPackageIncremental',
    amount: 1.5,
    value: ShowShippingOption.package_incremental,
  },
  {
    label: 'createShowModalDetailsShippingLetterRegistered',
    amount: 10.5,
    value: ShowShippingOption.letter_registered,
  },
  {
    label: 'shippingRegisteredIncremental',
    amount: 5,
    value: ShowShippingOption.letter_registered_incremental,
  },
]

const BelgiumIncrementalShippingOptions = [
  {
    label: 'createShowModalDetailsShippingLetter',
    amount: 3.3,
    value: ShowShippingOption.letter,
  },
  {
    label: 'shippingLetterIncrementalBE',
    amount: 1.3,
    value: ShowShippingOption.letter_incremental,
  },
  {
    label: 'createShowModalDetailsShippingPackage',
    amount: 5.6,
    value: ShowShippingOption.package,
  },
  {
    label: 'shippingPackageIncrementalBE',
    amount: 1.4,
    value: ShowShippingOption.package_incremental,
  },
  {
    label: 'createShowModalDetailsShippingLetterRegistered',
    amount: 10.5,
    value: ShowShippingOption.letter_registered,
  },
  {
    label: 'shippingRegisteredIncrementalBE',
    amount: 5,
    value: ShowShippingOption.letter_registered_incremental,
  },
]

const GermanyToysOptions = [
  {
    label: 'createShowModalDetailsShippingLetter',
    amount: 2.6,
    value: ShowShippingOption.letter,
  },
  {
    label: 'commonLetterPlus',
    amount: 2.75,
    value: ShowShippingOption.letter_plus,
  },
  {
    label: 'createShowModalDetailsShippingLargeLetter',
    amount: 3.85,
    value: ShowShippingOption.large_letter,
  },
  {
    label: 'commonLargeLetterPlus',
    amount: 4.05,
    value: ShowShippingOption.large_letter_plus,
  },
  {
    label: 'createShowModalDetailsShippingPackage',
    amount: 6,
    value: ShowShippingOption.package,
  },
  {
    label: 'createShowModalDetailsShippingPackagePlus',
    amount: 6.35,
    value: ShowShippingOption.package_plus,
  },
  {
    label: 'createShowModalDetailsShippingPackageUPS',
    amount: 6,
    value: ShowShippingOption.ups,
  },
]

const LuxembourgIncrementalShippingOptions = [
  {
    label: 'createShowModalDetailsShippingLetter',
    amount: 2.9,
    value: ShowShippingOption.letter,
  },
  {
    label: 'shippingLetterIncrementalLU',
    amount: 1.3,
    value: ShowShippingOption.letter_incremental,
  },
  {
    label: 'createShowModalDetailsShippingPackage',
    amount: 5,
    value: ShowShippingOption.package,
  },
  {
    label: 'shippingPackageIncrementalLU',
    amount: 1.5,
    value: ShowShippingOption.package_incremental,
  },
  {
    label: 'createShowModalDetailsShippingLetterRegistered',
    amount: 10.5,
    value: ShowShippingOption.letter_registered,
  },
  {
    label: 'shippingRegisteredIncrementalLU',
    amount: 5,
    value: ShowShippingOption.letter_registered_incremental,
  },
]

const showShippingCategories: ShowShippingCategories = {
  // country
  FR: {
    // show category
    36: FrenchFashionShippingOptions,
    37: FrenchFashionShippingOptions,
    38: FrenchFashionShippingOptions,
    39: FrenchFashionShippingOptions,
    40: FrenchFashionShippingOptions,
    54: FrenchFashionShippingOptions,
    63: FrenchFashionShippingOptions,
    73: FrenchFashionShippingOptions,
    74: FrenchFashionShippingOptions,
    75: FrenchFashionShippingOptions,
    17: FrenchFashionShippingOptions,
    69: FrenchFashionShippingOptions,

    /// TCG + Toys + Sport + Culture
    2: FrenchIncrementalShippingOptions,
    3: FrenchIncrementalShippingOptions,
    4: FrenchIncrementalShippingOptions,
    5: FrenchIncrementalShippingOptions,
    6: FrenchIncrementalShippingOptions,
    7: FrenchIncrementalShippingOptions,
    8: FrenchIncrementalShippingOptions,
    9: FrenchIncrementalShippingOptions,
    11: FrenchIncrementalShippingOptions,
    12: FrenchIncrementalShippingOptions,
    13: FrenchIncrementalShippingOptions,
    15: FrenchIncrementalShippingOptions,
    19: FrenchIncrementalShippingOptions,
    20: FrenchIncrementalShippingOptions,
    22: FrenchIncrementalShippingOptions,
    23: FrenchIncrementalShippingOptions,
    24: FrenchIncrementalShippingOptions,
    25: FrenchIncrementalShippingOptions,
    26: FrenchIncrementalShippingOptions,
    27: FrenchIncrementalShippingOptions,
    28: FrenchIncrementalShippingOptions,
    29: FrenchIncrementalShippingOptions,
    31: FrenchIncrementalShippingOptions,
    32: FrenchIncrementalShippingOptions,
    33: FrenchIncrementalShippingOptions,
    34: FrenchIncrementalShippingOptions,
    35: FrenchIncrementalShippingOptions,
    41: FrenchIncrementalShippingOptions,
    42: FrenchIncrementalShippingOptions,
    44: FrenchIncrementalShippingOptions,
    45: FrenchIncrementalShippingOptions,
    46: FrenchIncrementalShippingOptions,
    47: FrenchIncrementalShippingOptions,
    48: FrenchIncrementalShippingOptions,
    51: FrenchIncrementalShippingOptions,
    52: FrenchIncrementalShippingOptions,
    53: FrenchIncrementalShippingOptions,
    55: FrenchIncrementalShippingOptions,
    56: FrenchIncrementalShippingOptions,
    57: FrenchIncrementalShippingOptions,
    58: FrenchIncrementalShippingOptions,
    59: FrenchIncrementalShippingOptions,
    61: FrenchIncrementalShippingOptions,
    64: FrenchIncrementalShippingOptions,
    67: FrenchIncrementalShippingOptions,
    70: FrenchIncrementalShippingOptions,
    71: FrenchIncrementalShippingOptions,
    72: FrenchIncrementalShippingOptions,
    76: FrenchIncrementalShippingOptions,

    14: [
      {
        label: 'Mondial Relay',
        value: ShowShippingOption.sneakers_affordable,
        amount: 5,
      },
      {
        label: 'Colissimo',
        value: ShowShippingOption.sneakers_standard,
        amount: 11,
      },
      {
        label: 'UPS',
        value: ShowShippingOption.sneakers_premium,
        amount: 14,
      },
    ],
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 3.2,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 5,
        value: ShowShippingOption.package,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 10.5,
        value: ShowShippingOption.letter_registered,
      },
    ],
  },
  GB: {
    14: [
      {
        label: 'commonPackage',
        value: ShowShippingOption.sneakers_standard,
        amount: 6.99,
      },
    ],
    40: [
      {
        label: 'commonSmallPackage',
        value: ShowShippingOption.fashion_small_package,
        amount: 4.45,
      },
      {
        label: 'commonMediumPackage',
        value: ShowShippingOption.fashion_medium_package,
        amount: 5.55,
      },
      {
        label: 'commonLargePackage',
        value: ShowShippingOption.fashion_large_package,
        amount: 8,
      },
    ],
    39: [
      {
        label: 'commonSmallPackage',
        value: ShowShippingOption.fashion_small_package,
        amount: 4.45,
      },
      {
        label: 'commonMediumPackage',
        value: ShowShippingOption.fashion_medium_package,
        amount: 5.55,
      },
      {
        label: 'commonLargePackage',
        value: ShowShippingOption.fashion_large_package,
        amount: 8,
      },
    ],
    38: [
      {
        label: 'commonSmallPackage',
        value: ShowShippingOption.fashion_small_package,
        amount: 4.45,
      },
      {
        label: 'commonMediumPackage',
        value: ShowShippingOption.fashion_medium_package,
        amount: 5.55,
      },
      {
        label: 'commonLargePackage',
        value: ShowShippingOption.fashion_large_package,
        amount: 8,
      },
    ],
    37: [
      {
        label: 'commonSmallPackage',
        value: ShowShippingOption.fashion_small_package,
        amount: 4.45,
      },
      {
        label: 'commonMediumPackage',
        value: ShowShippingOption.fashion_medium_package,
        amount: 5.55,
      },
      {
        label: 'commonLargePackage',
        value: ShowShippingOption.fashion_large_package,
        amount: 8,
      },
    ],
    36: [
      {
        label: 'commonSmallPackage',
        value: ShowShippingOption.fashion_small_package,
        amount: 4.45,
      },
      {
        label: 'commonMediumPackage',
        value: ShowShippingOption.fashion_medium_package,
        amount: 5.55,
      },
      {
        label: 'commonLargePackage',
        value: ShowShippingOption.fashion_large_package,
        amount: 8,
      },
    ],
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 3.2,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingLargeLetter',
        amount: 3.99,
        value: ShowShippingOption.large_letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 5.55,
        value: ShowShippingOption.package,
      },
    ],
  },
  DE: {
    71: GermanyToysOptions,
    77: GermanyToysOptions,
    10: GermanyToysOptions,
    22: GermanyToysOptions,
    35: GermanyToysOptions,
    28: GermanyToysOptions,
    29: GermanyToysOptions,
    34: GermanyToysOptions,

    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 2.6,
        value: ShowShippingOption.letter,
      },
      {
        label: 'commonLetterPlus',
        amount: 2.75,
        value: ShowShippingOption.letter_plus,
      },
      {
        label: 'createShowModalDetailsShippingLargeLetter',
        amount: 3.85,
        value: ShowShippingOption.large_letter,
      },
      {
        label: 'commonLargeLetterPlus',
        amount: 4.05,
        value: ShowShippingOption.large_letter_plus,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 6,
        value: ShowShippingOption.package,
      },
      {
        label: 'createShowModalDetailsShippingPackagePlus',
        amount: 6.35,
        value: ShowShippingOption.package_plus,
      },
    ],
  },
  AT: {
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 2.75,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 6.9,
        value: ShowShippingOption.letter_registered,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 4.3,
        value: ShowShippingOption.package,
      },
    ],
  },
  NL: {
    14: [
      {
        label: 'commonPackage',
        value: ShowShippingOption.sneakers_affordable,
        amount: 6.95,
      },
      {
        label: 'createShowModalDetailsShippingInsuredPackage',
        value: ShowShippingOption.sneakers_standard,
        amount: 8.9,
      },
    ],
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 2,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingLargeLetter',
        amount: 4.25,
        value: ShowShippingOption.large_letter,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 8.75,
        value: ShowShippingOption.letter_registered,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 6.95,
        value: ShowShippingOption.package,
      },
    ],
  },
  ES: {
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 1.6,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingLargeLetter',
        amount: 3.1,
        value: ShowShippingOption.large_letter,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 4.8,
        value: ShowShippingOption.letter_registered,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 6.0,
        value: ShowShippingOption.package,
      },
    ],
  },
  BE: {
    /// TCG + Toys + Sport + Culture
    2: BelgiumIncrementalShippingOptions,
    3: BelgiumIncrementalShippingOptions,
    4: BelgiumIncrementalShippingOptions,
    5: BelgiumIncrementalShippingOptions,
    6: BelgiumIncrementalShippingOptions,
    7: BelgiumIncrementalShippingOptions,
    8: BelgiumIncrementalShippingOptions,
    9: BelgiumIncrementalShippingOptions,
    11: BelgiumIncrementalShippingOptions,
    12: BelgiumIncrementalShippingOptions,
    13: BelgiumIncrementalShippingOptions,
    15: BelgiumIncrementalShippingOptions,
    19: BelgiumIncrementalShippingOptions,
    20: BelgiumIncrementalShippingOptions,
    22: BelgiumIncrementalShippingOptions,
    23: BelgiumIncrementalShippingOptions,
    24: BelgiumIncrementalShippingOptions,
    25: BelgiumIncrementalShippingOptions,
    26: BelgiumIncrementalShippingOptions,
    27: BelgiumIncrementalShippingOptions,
    28: BelgiumIncrementalShippingOptions,
    29: BelgiumIncrementalShippingOptions,
    31: BelgiumIncrementalShippingOptions,
    32: BelgiumIncrementalShippingOptions,
    33: BelgiumIncrementalShippingOptions,
    34: BelgiumIncrementalShippingOptions,
    35: BelgiumIncrementalShippingOptions,
    41: BelgiumIncrementalShippingOptions,
    42: BelgiumIncrementalShippingOptions,
    44: BelgiumIncrementalShippingOptions,
    45: BelgiumIncrementalShippingOptions,
    46: BelgiumIncrementalShippingOptions,
    47: BelgiumIncrementalShippingOptions,
    48: BelgiumIncrementalShippingOptions,
    51: BelgiumIncrementalShippingOptions,
    52: BelgiumIncrementalShippingOptions,
    53: BelgiumIncrementalShippingOptions,
    55: BelgiumIncrementalShippingOptions,
    56: BelgiumIncrementalShippingOptions,
    57: BelgiumIncrementalShippingOptions,
    58: BelgiumIncrementalShippingOptions,
    59: BelgiumIncrementalShippingOptions,
    61: BelgiumIncrementalShippingOptions,
    64: BelgiumIncrementalShippingOptions,
    67: BelgiumIncrementalShippingOptions,
    70: BelgiumIncrementalShippingOptions,
    71: BelgiumIncrementalShippingOptions,
    72: BelgiumIncrementalShippingOptions,
    76: BelgiumIncrementalShippingOptions,

    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 3.3,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 5.6,
        value: ShowShippingOption.package,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 10.5,
        value: ShowShippingOption.letter_registered,
      },
    ],
  },
  LU: {
    /// TCG + Toys + Sport + Culture
    2: LuxembourgIncrementalShippingOptions,
    3: LuxembourgIncrementalShippingOptions,
    4: LuxembourgIncrementalShippingOptions,
    5: LuxembourgIncrementalShippingOptions,
    6: LuxembourgIncrementalShippingOptions,
    7: LuxembourgIncrementalShippingOptions,
    8: LuxembourgIncrementalShippingOptions,
    9: LuxembourgIncrementalShippingOptions,
    11: LuxembourgIncrementalShippingOptions,
    12: LuxembourgIncrementalShippingOptions,
    13: LuxembourgIncrementalShippingOptions,
    15: LuxembourgIncrementalShippingOptions,
    19: LuxembourgIncrementalShippingOptions,
    20: LuxembourgIncrementalShippingOptions,
    22: LuxembourgIncrementalShippingOptions,
    23: LuxembourgIncrementalShippingOptions,
    24: LuxembourgIncrementalShippingOptions,
    25: LuxembourgIncrementalShippingOptions,
    26: LuxembourgIncrementalShippingOptions,
    27: LuxembourgIncrementalShippingOptions,
    28: LuxembourgIncrementalShippingOptions,
    29: LuxembourgIncrementalShippingOptions,
    31: LuxembourgIncrementalShippingOptions,
    32: LuxembourgIncrementalShippingOptions,
    33: LuxembourgIncrementalShippingOptions,
    34: LuxembourgIncrementalShippingOptions,
    35: LuxembourgIncrementalShippingOptions,
    41: LuxembourgIncrementalShippingOptions,
    42: LuxembourgIncrementalShippingOptions,
    44: LuxembourgIncrementalShippingOptions,
    45: LuxembourgIncrementalShippingOptions,
    46: LuxembourgIncrementalShippingOptions,
    47: LuxembourgIncrementalShippingOptions,
    48: LuxembourgIncrementalShippingOptions,
    51: LuxembourgIncrementalShippingOptions,
    52: LuxembourgIncrementalShippingOptions,
    53: LuxembourgIncrementalShippingOptions,
    55: LuxembourgIncrementalShippingOptions,
    56: LuxembourgIncrementalShippingOptions,
    57: LuxembourgIncrementalShippingOptions,
    58: LuxembourgIncrementalShippingOptions,
    59: LuxembourgIncrementalShippingOptions,
    61: LuxembourgIncrementalShippingOptions,
    64: LuxembourgIncrementalShippingOptions,
    67: LuxembourgIncrementalShippingOptions,
    70: LuxembourgIncrementalShippingOptions,
    71: LuxembourgIncrementalShippingOptions,
    72: LuxembourgIncrementalShippingOptions,
    76: LuxembourgIncrementalShippingOptions,

    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 2.9,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 5,
        value: ShowShippingOption.package,
      },
      {
        label: 'createShowModalDetailsShippingLetterRegistered',
        amount: 10.5,
        value: ShowShippingOption.letter_registered,
      },
    ],
  },
  GR: {
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 3,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 6.0,
        value: ShowShippingOption.package,
      },
    ],
  },
  IE: {
    default: [
      {
        label: 'createShowModalDetailsShippingLetter',
        amount: 3,
        value: ShowShippingOption.letter,
      },
      {
        label: 'createShowModalDetailsShippingPackage',
        amount: 6.0,
        value: ShowShippingOption.package,
      },
    ],
  },
}

const getShowShippingCategories = (sellerCountry: string, showCategory: number) => {
  let shippingMethods

  if (showShippingCategories[sellerCountry] && showShippingCategories[sellerCountry][showCategory]) {
    shippingMethods = showShippingCategories[sellerCountry][showCategory]
  } else if (showShippingCategories[sellerCountry] && showShippingCategories[sellerCountry].default) {
    shippingMethods = showShippingCategories[sellerCountry].default
  } else {
    shippingMethods = showShippingCategories.FR.default
  }

  return [
    ...shippingMethods,
    {
      label: 'commonFree',
      amount: null,
      value: ShowShippingOption.free,
    },
  ]
}

export default getShowShippingCategories
