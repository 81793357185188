import { useTranslation } from 'react-i18next'

import Alert from '@/components/ui/Alert/Alert'

import type { ShowRaidIncomingInShowFragment } from '@/views/Shows/operations.generated'

export const IncomingRaidItem = ({ item }: { item: ShowRaidIncomingInShowFragment }) => {
  const { t } = useTranslation()
  return (
    <Alert emphasis="high" type="warning">
      {t('incomingRaidMessage', {
        username: item.originShow.seller.username,
        participantCount: item.participantsCount,
      })}
    </Alert>
  )
}
