import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackError } from '@/util/sentry'
import {
  useAddCommentToShowMutation,
  useGetMostRecentShowFeedItemsLazyQuery,
  useOnNewShowFeedItemSubscription,
} from '@/views/Shows/operations.generated'

import type {
  CommentFragment,
  UserFollowFragment,
  EmoteSetsFragment,
  JoinFragment,
  ShowRaidIncomingInShowFragment,
  UserBlockedFromCommentingInShowFragment,
  UserUnblockedFromCommentingInShowFragment,
} from '@/views/Shows/operations.generated'

const MAX_COMMENTS_IN_CHAT = 150

export type BanItemType = UserBlockedFromCommentingInShowFragment & UserUnblockedFromCommentingInShowFragment

type Emote = EmoteSetsFragment['emotes'][number]
export type EmoteMap = { [key: string]: Emote }

export type CustomizedJoinFragment = JoinFragment & { customText: string }
export type CustomizedUserFollowFragment = UserFollowFragment & { customText: string }
export type ChatItem =
  | CommentFragment
  | CustomizedJoinFragment
  | CustomizedUserFollowFragment
  | UserBlockedFromCommentingInShowFragment
  | UserUnblockedFromCommentingInShowFragment
  | ShowRaidIncomingInShowFragment

export const useChat = (showId: number) => {
  const { t } = useTranslation()
  const [addCommentMutation] = useAddCommentToShowMutation()
  const [loadMostRecentFeedItems] = useGetMostRecentShowFeedItemsLazyQuery()
  const showGlobalId = `Show|${showId}`

  const [previousItemsLoaded, setPreviousItemsLoaded] = useState(false)

  const [chatItems, setChatItems] = useState<ChatItem[]>([])
  const [availableEmoteSets, setAvailableEmoteSets] = useState<EmoteSetsFragment[]>([])
  const [emoteMap, setEmoteMap] = useState<EmoteMap | undefined>()

  const handleAddComment = useCallback(
    (comment: string) => {
      addCommentMutation({
        variables: { showId: showGlobalId, textContent: comment },
        onError: (err) => {
          notificationDanger(t('commonSomethingWentWrong'))
          trackError(err, {
            meta: {
              feature: 'raid.hooks.useRaid',
            },
          })
        },
      })
    },
    [showGlobalId]
  )

  // -----------------------
  const addItemToList = useCallback(
    <T extends { id: any }>(
      item: ChatItem,
      setList: React.Dispatch<React.SetStateAction<T[]>>,
      sortFunction?: (a: T, b: T) => number,
      threshold?: number
    ) => {
      const random = Math.floor(Math.random() * 3) + 1
      setList((prevList: any) => {
        if (prevList.some((existingItem: any) => existingItem.id === item.id)) {
          return prevList // Return the original list if item exists
        } else {
          const customItem = { ...item }
          if (customItem.__typename === 'UserJoinedFeedItem') {
            customItem.customText = t(`showCommentsWelcomeMessage${random}`)
          } else if (customItem.__typename === 'UserFollowFeedItem') {
            customItem.customText = t('commentsListUserFollowMessage')
          }

          const newList = [...prevList, customItem]
          if (sortFunction) {
            newList.sort(sortFunction) // Sort the list if a sort function is provided
          }
          if (threshold && newList.length > threshold) {
            return newList.slice(-threshold) // Keep only the latest items if a threshold is provided
          }
          return newList
        }
      })
    },
    []
  )

  const removeItemFromChatItemsFromUser = useCallback((userId: string) => {
    setChatItems((prevChatItems) =>
      prevChatItems.filter((item) => item.__typename === 'UserCommentFeedItem' && item.author.id !== userId)
    )
  }, [])

  const sortByTimestamp = useCallback((a: ChatItem, b: ChatItem) => {
    return dayjs(a.date).diff(b.date)
  }, [])

  const parseFeedItem = useCallback((feedItem: ChatItem) => {
    if (!feedItem) return
    switch (feedItem.__typename) {
      case 'UserCommentFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'UserJoinedFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'UserFollowFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'UserBlockedFromCommentingInShowFeedItem':
      case 'UserUnblockedFromCommentingInShowFeedItem':
        if (feedItem.__typename === 'UserBlockedFromCommentingInShowFeedItem') {
          removeItemFromChatItemsFromUser(feedItem.user.id)
        }
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      case 'ShowRaidIncomingFeedItem':
        addItemToList(feedItem, setChatItems, sortByTimestamp, MAX_COMMENTS_IN_CHAT)
        break
      default:
        // Handle unknown type
        break
    }
  }, [])

  // Emote map generation, we use a map to avoid looping through all emotes on every render
  useEffect(() => {
    if (!availableEmoteSets) return
    const emoteMap: EmoteMap = {}
    availableEmoteSets.forEach((emoteSet) => {
      emoteSet.emotes.forEach((emote) => {
        // Cast to Emote here is not necessary if emote is already of type Emote
        emoteMap[`:${emote.name}:`] = emote
      })
    })
    setEmoteMap(emoteMap)
  }, [availableEmoteSets])

  // Load most recent feed items
  useEffect(() => {
    setPreviousItemsLoaded(false)
    loadMostRecentFeedItems({
      variables: { showId: showGlobalId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.node?.__typename === 'Show') {
          // Parse feed items
          data.node.feedItemsByTimestampDesc.edges.forEach((edge) => {
            parseFeedItem(edge.node as ChatItem)
          })

          // Parse available emote sets
          const emoteSets = data.node.availableEmoteSetsV2 ?? null
          if (emoteSets) {
            setAvailableEmoteSets(emoteSets)
          }
          setPreviousItemsLoaded(true)
        }
      },
      onError: (error) => {
        trackError(error, { meta: { showId, scope: 'useChat.loadMostRecentFeedItems' } })
      },
    })
  }, [loadMostRecentFeedItems, showId])

  // Subscribe to new feed items
  useOnNewShowFeedItemSubscription({
    ignoreResults: true,
    variables: { showId: showGlobalId },
    onData: ({ data }) => {
      const feedItem = data?.data?.showFeedItemAdded?.feedItem
      if (feedItem) {
        parseFeedItem(feedItem as ChatItem)
      }
    },
  })

  return {
    handleAddComment,
    chatItems,
    emoteMap,
    previousItemsLoaded,
  }
}
