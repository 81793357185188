import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import type { ShipmentDimension } from '../../../../utils/shipments.util'
import type { ChangeEvent } from 'react'

type SelectAndCheckboxChangeEvent = ChangeEvent<HTMLSelectElement>

export const SHIPMENT_DIMENSIONS_LETTER: ShipmentDimension = {
  weight: 0.1,
  height: 2.0,
  depth: 16.0,
  width: 12.0,
}

const SHIPMENT_DIMENSIONS_SMALL: ShipmentDimension = {
  weight: 0.5,
  height: 15.0,
  depth: 15.0,
  width: 15.0,
}

const SHIPMENT_DIMENSIONS_MEDIUM: ShipmentDimension = {
  weight: 1.0,
  height: 15.0,
  depth: 30.0,
  width: 30.0,
}

const SHIPMENT_DIMENSIONS_LARGE: ShipmentDimension = {
  weight: 2.0,
  height: 30.0,
  depth: 30.0,
  width: 30.0,
}

const SHIPMENT_DIMENSIONS_CUSTOM: ShipmentDimension = {
  weight: parseInt(getFromLocalStorage('shipmentWeight') || '0'),
  height: parseInt(getFromLocalStorage('shipmentHeight') || '0'),
  depth: parseInt(getFromLocalStorage('shipmentDepth') || '0'),
  width: parseInt(getFromLocalStorage('shipmentWidth') || '0'),
}

type ShipmentDimensionSelectorProps = {
  onSetShipmentDimensions: (dimensions: ShipmentDimension, name: string) => void
  defaultValue: string | null
}

const LETTER = 'letter'
const SMALL = 'small'
const MEDIUM = 'medium'
const LARGE = 'large'
const CUSTOM = 'custom'

const ShipmentDimensionSelectorMap = {
  [LETTER]: SHIPMENT_DIMENSIONS_LETTER,
  [SMALL]: SHIPMENT_DIMENSIONS_SMALL,
  [MEDIUM]: SHIPMENT_DIMENSIONS_MEDIUM,
  [LARGE]: SHIPMENT_DIMENSIONS_LARGE,
  [CUSTOM]: SHIPMENT_DIMENSIONS_CUSTOM,
}

export const ShipmentDimensionSelector = (props: ShipmentDimensionSelectorProps) => {
  const { onSetShipmentDimensions, defaultValue } = props

  const { t } = useTranslation()

  const options = [
    {
      value: LETTER,
      label: t('commonLetter'),
    },
    {
      value: SMALL,
      label: t('commonSmallPackage'),
    },
    {
      value: MEDIUM,
      label: t('commonMediumPackage'),
    },
    {
      value: LARGE,
      label: t('commonLargePackage'),
    },
    {
      value: CUSTOM,
      label: t('commonCustomPackage'),
    },
  ]

  return (
    <Field
      name="shipment-dimensions-presets"
      options={options}
      type="select"
      value={defaultValue || 'letter'}
      onChange={(event: SelectAndCheckboxChangeEvent) => {
        if (event.target.value) {
          setToLocalStorage('shipmentDimensions', event.target.value)
          const dimensions =
            ShipmentDimensionSelectorMap[event.target.value as keyof typeof ShipmentDimensionSelectorMap]
          setToLocalStorage('shipmentWeight', dimensions.weight.toString())
          setToLocalStorage('shipmentHeight', dimensions.height ? dimensions.height.toString() : '0')
          setToLocalStorage('shipmentDepth', dimensions.depth ? dimensions.depth.toString() : '0')
          setToLocalStorage('shipmentWidth', dimensions.width ? dimensions.width.toString() : '0')
          onSetShipmentDimensions(dimensions, event.target.value)
        }
      }}
    />
  )
}
