import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaDownLeftAndUpRightToCenter, FaUpRightAndDownLeftFromCenter } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'

type ExpandOrCollapseProductListButtonProps = {
  isExpanded: boolean
  onToggle: () => void
}

const ExpandOrCollapseProductListButton = (props: ExpandOrCollapseProductListButtonProps) => {
  const { t } = useTranslation()
  const { isExpanded, onToggle } = props

  const handleClick = useCallback(() => {
    onToggle()
  }, [onToggle])

  return (
    <Button
      className="expand-action"
      icon={isExpanded ? <FaDownLeftAndUpRightToCenter /> : <FaUpRightAndDownLeftFromCenter />}
      tooltip={isExpanded ? t('commonExpandLabel') : t('commonCollapseLabel')}
      onClick={handleClick}
    />
  )
}

export default ExpandOrCollapseProductListButton
