interface ShippingAddressColumnProps {
  inputLine1: string
  inputLine2?: string | null
  inputPostalCode: string
  inputCity: string
  inputCountry: string
}
export const ShippingAddressColumn = (props: ShippingAddressColumnProps) => {
  const { inputLine1, inputLine2, inputPostalCode, inputCity, inputCountry } = props

  const formatAddressLinesWithPostalCode = () => {
    let values = inputLine1
    if (inputLine2) {
      values += `, ${inputLine2}`
    }
    return values
  }

  const formatAddressCityAndCountry = () => {
    return `${inputPostalCode}, ${inputCity}, ${inputCountry}`
  }

  return (
    <div>
      <p>
        {formatAddressLinesWithPostalCode()}, {formatAddressCityAndCountry()}
      </p>
    </div>
  )
}
