import { type TFunction } from 'i18next'
import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

export enum TCGCardCondition {
  MINT = 'mint',
  NEAR_MINT = 'near-mint',
  EXCELLENT = 'excellent',
  GOOD = 'good',
  LIGHT_PLAYED = 'light-played',
  PLAYED = 'played',
  POOR = 'poor',
}

export const computeCardConditionFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '' },
    { value: TCGCardCondition.MINT, label: t('productCardConditionMintOptionLabel') },
    { value: TCGCardCondition.NEAR_MINT, label: t('productCardConditionNearMintOptionLabel') },
    { value: TCGCardCondition.EXCELLENT, label: t('productCardConditionExcellentOptionLabel') },
    { value: TCGCardCondition.GOOD, label: t('productCardConditionGoodOptionLabel') },
    { value: TCGCardCondition.LIGHT_PLAYED, label: t('productCardConditionLightPlayedOptionLabel') },
    { value: TCGCardCondition.PLAYED, label: t('productCardConditionPlayedOptionLabel') },
    { value: TCGCardCondition.POOR, label: t('productCardConditionPoorOptionLabel') },
  ]
}

type ProductCardConditionFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductCardConditionField = (props: ProductCardConditionFieldProps) => {
  const { value, onChange, disabled = false } = props
  const { t } = useTranslation()

  const options = useMemo(() => computeCardConditionFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormCardConditionLabel')}
      name="card-condition"
      options={options}
      placeholder={t('productFormCardConditionPlaceholder')}
      type="select"
      value={value}
      onChange={onChange}
    />
  )
}

export default ProductCardConditionField
