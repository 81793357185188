import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { uniqBy } from '@/util/lodash-replacer'

import { useGetAllShowsLazyQuery } from '../operations.generated'

import type { Currency } from '@/network/graphql/types.generated'

const CSVHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'Date', key: 'date' },
  { label: 'Show', key: 'name' },
  { label: 'Show total', key: 'total' },
  { label: 'Commission', key: 'commission' },
  { label: 'Refunded', key: 'refunded' },
  { label: 'Shipping label', key: 'shipping' },
  { label: 'Net revenue', key: 'revenue' },
  { label: 'Currency', key: 'currency' },
]

// TODO: do on backend instead. Bugged lib. Workaround is to click once to load data, second time to download
// see https://github.com/react-csv/react-csv/issues?q=is%3Aissue+is%3Aopen+done

export const CSVExportButton = () => {
  const { t } = useTranslation()
  const [getAllShows, { loading }] = useGetAllShowsLazyQuery()
  const [dataToDownload, setDataToDownload] = useState<
    {
      id: number
      date: Date
      name: string
      total: number
      commission: number
      shipping: number
      revenue: number
      refunded: number
      currency: Currency
    }[]
  >([])

  if (!dataToDownload.length) {
    return (
      <Button
        icon={<FaDownload className="h-[16x] w-[16px]" />}
        isLoading={loading}
        label={t('ShowsListExportGenerate')}
        onClick={async () => {
          const { data: allShows } = await getAllShows()
          if (!allShows?.viewer) {
            return []
          }

          let shows = allShows.viewer.showsByStartAtDescForSeller.edges.map((edge) => edge.node)

          shows = uniqBy(shows, (show) => show.legacyId).sort(
            (a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
          )

          const data = shows.map((s) => ({
            id: s.legacyId,
            date: s.startAt,
            name: s.name,
            total: s.totalAmountOrdersSuccessInCurrencyCents / 100,
            commission: s.totalCommissionInCurrencyCents / 100,
            shipping: s.totalDeductedShippingCostInCurrencyCents / 100,
            revenue: s.totalNetIncomeInCurrencyCents / 100,
            refunded: s.totalAmountRefundedInCurrencyCents / 100,
            currency: s.currency,
          }))
          setDataToDownload(data)
        }}
      />
    )
  }

  return (
    <CSVLink data={dataToDownload} filename={`shows-export-${new Date().getTime()}`} headers={CSVHeaders}>
      <Button icon={<FaDownload className="h-[16x] w-[16px]" />} label={t('ShowsListExportReady')} />
    </CSVLink>
  )
}
