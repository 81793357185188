import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaGavel } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { ProductStatusInAuction, type Product } from '@/network/graphql/types.generated'

import './LaunchActivityButton.scss'
interface LaunchActivityButtonProps {
  product: Product
  status?: Product['status']
  onLaunch: (product: Product) => void
  disabled?: boolean
  disabledReason?: 'not-broadcasting' | 'activity-running' | 'no-quantity'
}

const LaunchActivityButton = (props: LaunchActivityButtonProps) => {
  const { product, disabled, onLaunch, status, disabledReason } = props
  const { t } = useTranslation()

  const hasAlreadyBeenLaunched = status === ProductStatusInAuction.Unsold
  let tooltip = undefined
  if (disabled) {
    switch (disabledReason) {
      case 'not-broadcasting':
        tooltip = t('launchActivityActionDisabledNotBroadcasting')
        break
      case 'activity-running':
        tooltip = t('launchActivityActionDisabledActivityRunning')
        break
      case 'no-quantity':
        tooltip = t('launchActivityActionDisabledNoQuantity')
        break
      default:
        // this should never happen
        tooltip = t('launchActivityActionDisabled')
        break
    }
  }

  const handleClick = useCallback(() => {
    onLaunch(product)
  }, [onLaunch, product])

  return (
    <Button
      className="action secondary launch-activity-action"
      disabled={disabled}
      icon={<FaGavel />}
      label={hasAlreadyBeenLaunched ? t('commonRelaunch') : t('commonLaunch')}
      tooltip={tooltip}
      tooltipPosition="bottom right"
      onClick={handleClick}
    />
  )
}

export default LaunchActivityButton
