import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/ui/Alert/Alert'
import Link from '@/components/ui/Link/Link'
import { useUser } from '@/contexts/user/User.context'
import { getFromArrayLocalStorage, setToArrayLocalStorage } from '@/helpers/localstorage'
import { trackEvent } from '@/util/eventTracker'

import './OnboardingBanner.scss'

export const OnboardingBanner = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { sellerConfig, id } = user || {}
  const { createdAt } = sellerConfig || {}

  const shouldSeeBanner = createdAt && dayjs(createdAt).add(2, 'weeks') > dayjs()

  if (!shouldSeeBanner) return null

  const today = dayjs().format('YYYY-MM-DD')

  const localStorageKey = 'hasSeenOnbordingBanner'
  const dismissed = !!getFromArrayLocalStorage(localStorageKey, today)

  const handleClick = () => {
    setToArrayLocalStorage(localStorageKey, today)
    trackEvent('DISMISSED_ONBOARDING_BANNER_ON_PRODUCT_CREATION', { userId: id })
  }

  if (dismissed) return null

  return (
    <div className="onboarding-alert-container">
      <hr />
      <Alert
        className="onboarding-alert"
        dismissed={dismissed}
        emphasis="high"
        type="info"
        dismissible
        onDismiss={handleClick}
      >
        <div className="onboarding-alert">
          <h2>{t('onboardingBannerTitle')}</h2>
          <p>
            <Link
              href={t('signUpFormRequestOnboardingSessionLink')}
              target="blank"
              onClick={() => trackEvent('CLICKED_ONBOARDING_BANNER_ON_PRODUCT_CREATION', { userId: id })}
            >
              {t('onboardingBannerSubTitle1')}
            </Link>
            &nbsp;
            {t('onboardingBannerSubTitle2')}
          </p>
        </div>
      </Alert>
    </div>
  )
}
