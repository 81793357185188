import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import validateIBAN from '@/views/Account/components/BankInfo/helpers/iban'

import type { BankInfoFormValidity } from '@/views/Account/components/BankInfo/BankInfoForm/types'

type SellerBankIbanFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof BankInfoFormValidity, isValid: boolean) => void
}

const SellerBankIbanField = (props: SellerBankIbanFieldProps) => {
  const { value: iban, onChange, onValidityChange = () => undefined, ...others } = props
  const { t } = useTranslation()

  return (
    <Field
      // formatValue={(value) => value.replace(/-/g, '').toUpperCase()}
      id="iban"
      label={t('sellerBankInfoIbanFieldLabel')}
      name="iban"
      placeholder={t('sellerBankInfoIbanFieldPlaceholder')}
      required={true}
      type="text"
      value={iban}
      validators={[
        {
          test: (value) => validateIBAN((value || '')?.toString()),
          errorMessage: t('sellerBankInfoIbanFieldInvalidFormatError'),
        },
      ]}
      {...others}
      onChange={onChange}
      onValidityChange={(isValid: boolean) => onValidityChange('iban', isValid)}
    />
  )
}

export default SellerBankIbanField
