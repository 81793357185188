const GavelOutlineIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.28499 17.9566L4.28498 17.9566C3.37376 17.0454 3.37376 15.568 4.28498 14.6568C4.96045 13.9814 5.94869 13.8052 6.78814 14.1341L7.39739 14.3729L7.8601 13.9102L13.9092 7.86106L14.3719 7.39836L14.1332 6.7891C13.8042 5.94965 13.9804 4.96142 14.6559 4.28596L13.9488 3.57885L14.6559 4.28595C15.5671 3.37473 17.0444 3.37473 17.9557 4.28595L17.9557 4.28597L21.727 8.05718C22.6382 8.9684 22.6382 10.4458 21.727 11.357C21.0515 12.0325 20.0633 12.2087 19.2237 11.8797L18.6145 11.641L18.1518 12.1037L17.0971 13.1584L16.3377 13.9178L17.1493 14.6211L27.006 23.1637L27.006 23.1637C28.2009 24.1992 28.2662 26.031 27.1482 27.149C26.0302 28.2671 24.1984 28.2017 23.1629 27.0069C23.1628 27.0069 23.1628 27.0069 23.1628 27.0069L14.6203 17.1501L13.9169 16.3385L13.1575 17.098L12.1027 18.1528L11.64 18.6154L11.8788 19.2247C12.2077 20.0643 12.0315 21.0525 11.356 21.728C10.4448 22.6392 8.96744 22.6392 8.05622 21.728L4.28499 17.9566Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  )
}

export default GavelOutlineIcon
