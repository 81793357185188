import { useTranslation } from 'react-i18next'

import { Body } from '@/components/Typography/Body'
import { Callout } from '@/components/Typography/Callout'
import Button from '@/components/ui/Button/Button'
import User from '@/components/ui/User/User'
import { useBanUsers } from '@/modules/ban-hammer/hooks/use-ban-users'
import { useSellerModerators } from '@/modules/moderators/hooks/use-seller-moderators'
import { UserListType } from '@/views/Account/components/Moderation/Moderation'

import Card from '../ui/Card/Card'

import type { Viewer } from '@/views/Show/components/ChatBox/chatbox.helper'

export type UserListProps = {
  title: string
  subtitle: string
  users: Array<Pick<Viewer, 'id' | 'username' | 'avatarWebPUrl'>>
  type: UserListType
  sellerId: Viewer['id']
}

// TODO: this component should be split in 2: <ModeratorsList /> and <BannedUsersList />
export const UserList = (props: UserListProps) => {
  const { users, type, sellerId } = props

  const { t } = useTranslation()

  const isModeratorsList = type === UserListType.moderators

  const { unbanUserFromSeller } = useBanUsers()
  const { demoteUserFromModerator } = useSellerModerators()

  const handleClick = async (userId: string) => {
    if (isModeratorsList) {
      await demoteUserFromModerator(userId)
    } else {
      await unbanUserFromSeller(userId, sellerId)
    }
  }

  return (
    <Card>
      <div>
        <Body className="max-lines-2" variant="body4">
          {isModeratorsList ? t('privacySettingsModeratorsPanelTitle') : t('privacySettingsBannedUsersPanelTitle')}
        </Body>
        <Callout className="text-black-130" variant="callout1">
          {isModeratorsList
            ? t('privacySettingsModeratorsPanelSubtitle')
            : t('privacySettingsBannedUsersPanelSubtitle')}
        </Callout>
      </div>

      {users.map(({ id, username, avatarWebPUrl }) => (
        <div key={id} className="w-full flex flex-col mt-4 gap-2">
          <div className="w-full flex justify-between items-center py-3">
            <User src={avatarWebPUrl}>{username}</User>
            <Button
              className="demote-action"
              label={
                isModeratorsList
                  ? t('privacySettingsModeratorsPanelRemoveFromModeratorsAction')
                  : t('privacySettingsBannedUsersPanelUnbanAction')
              }
              onClick={() => handleClick(id)}
            />
          </div>
        </div>
      ))}
    </Card>
  )
}
