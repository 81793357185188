import './config/rum'

import { ApolloProvider } from '@apollo/client'
import { SSOProvider } from '@bits-app/react-sso'
import { StrictMode } from 'react'
import CacheBuster from 'react-cache-buster'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Loader from '@/components/ui/Loader/Loader'
import { isProdEnv } from '@/config/config'
import { APP_VERSION } from '@/config/constants'
import { initApolloClient } from '@/network/graphql/apolloClient'

import { UserProvider } from './contexts/user/User.context'
import { LayoutProvider } from './providers/Layout.provider'
import { store, persistor } from './reducers'
import Router from './views/Router/Router'

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en'

import './translations/i18n'
import './index.less'

import './index.scss'

const {
  VITE_APP_APPLE_SSO_CLIENT_ID_WEB,
  VITE_APP_APPLE_SSO_REDIRECT_URI,
  VITE_APP_GOOGLE_SSO_CLIENT_ID_WEB,
  VITE_APP_FACEBOOK_SSO_APP_ID,
} = import.meta.env

let apolloClient: ApolloClient<NormalizedCacheObject>
const getApolloClient = async () => {
  if (!apolloClient) {
    apolloClient = await initApolloClient(store)
  }
  return apolloClient
}

;(async () => {
  const apolloClient = await getApolloClient()

  ReactDOM.render(
    <CacheBuster
      currentVersion={APP_VERSION}
      isEnabled={isProdEnv}
      isVerboseMode={!isProdEnv}
      loadingComponent={<Loader />}
      metaFileDirectory={'./'}
    >
      <StrictMode>
        <SSOProvider
          config={{
            facebook: { appId: VITE_APP_FACEBOOK_SSO_APP_ID! },
            apple: {
              clientId: VITE_APP_APPLE_SSO_CLIENT_ID_WEB!,
              redirectUrl: VITE_APP_APPLE_SSO_REDIRECT_URI!,
            },
            google: {
              clientId: VITE_APP_GOOGLE_SSO_CLIENT_ID_WEB!,
            },
          }}
        >
          <ApolloProvider client={apolloClient}>
            <PersistGate loading={null} persistor={persistor}>
              <Provider store={store}>
                <UserProvider>
                  <LayoutProvider apolloClient={apolloClient}>
                    <Router />
                  </LayoutProvider>
                </UserProvider>
              </Provider>
            </PersistGate>
          </ApolloProvider>
        </SSOProvider>
      </StrictMode>
    </CacheBuster>,
    document.getElementById('root')
  )
})()
