import { RightOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import type { Currency } from '@/network/graphql/types.generated'

interface InstantOrderItemProps {
  price: number
  currency: Currency
  productName: string
  username: string
  date: Date
}

const LastInstantOrderItem = ({ price, currency, productName, username, date }: InstantOrderItemProps) => {
  const formatCurrency = useCurrencyFormatter()

  return (
    <div className="flex items-center gap-4 py-2">
      <div className="flex-1">
        <p className="m-0 font-semibold">{productName}</p>
        <p className="m-0 text-slate-600">@{username}</p>
      </div>

      <div className="flex gap-4 items-center self-start h-full">
        <div className="flex flex-col items-end">
          <p className="m-0 text-base font-semibold">{formatCurrency(price, currency)}</p>
          <p className="m-0 text-slate-600">
            {moment(date).format('DD/MM/YYYY')} ({moment(date).fromNow()})
          </p>
        </div>
        <RightOutlined />
      </div>
    </div>
  )
}

export default LastInstantOrderItem
