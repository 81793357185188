import { Modal } from 'antd'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaUserShield, FaUserXmark, FaBan } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import User from '@/components/ui/User/User'

import { ViewerActionsType, userIsModerator } from '../../chatbox.helper'
import { useChatBoxContext } from '../../ChatBoxProvider'

import type { Viewer } from '../../chatbox.helper'

import style from './style.module.scss'
import globalStyle from '@/styles/_export.module.scss'

type ViewerActionsDropdownMenuProps = {
  viewer: Viewer
  setDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ViewerActionsDropdownMenu = ({ viewer, setDropdownIsOpen }: ViewerActionsDropdownMenuProps) => {
  const { moderators } = useChatBoxContext()
  const { t } = useTranslation()

  const isModerator = userIsModerator(viewer, moderators)

  const VIEWERS_ACTIONS = [
    {
      title: t('showUserActionsPopupPromoteAction'),
      type: ViewerActionsType.promoteAction,
      buttonType: 'primary',
    },
    {
      title: t('showUserActionsPopupDemoteAction'),
      type: ViewerActionsType.demoteAction,
      buttonType: 'primary',
    },
    {
      title: t('showUserActionsPopupBanFromShowAction'),
      type: ViewerActionsType.banFromShowAction,
      buttonType: 'error',
    },
    {
      title: t('showUserActionsPopupBanFromSellerAction'),
      type: ViewerActionsType.banFromSellerAction,
      buttonType: 'error',
    },
  ]

  const getFilteredActions = () => {
    return isModerator
      ? VIEWERS_ACTIONS.filter((action) => action.type !== ViewerActionsType.promoteAction)
      : VIEWERS_ACTIONS.filter((action) => action.type !== ViewerActionsType.demoteAction)
  }

  const viewerActions = getFilteredActions()

  const getActionDetails = (actionType: ViewerActionsType) => {
    if (actionType === ViewerActionsType.promoteAction) {
      return {
        icon: <FaUserShield size={18} stroke={globalStyle.black160} />,
      }
    } else if (actionType === ViewerActionsType.demoteAction) {
      return {
        icon: <FaUserXmark size={18} stroke={globalStyle.black160} />,
      }
    } else {
      return {
        icon: <FaBan size={18} stroke={globalStyle.errorRed} />,
      }
    }
  }

  const { onBanUserFromSeller, onBanUserFromShow, onPromoteUserToModerator, onDemoteUserFromModerator } =
    useChatBoxContext()

  const [isBanModalOpen, setIsBanModalOpen] = useState(false)
  const [banType, setBanType] = useState<ViewerActionsType>()

  const openBanModal = () => {
    setIsBanModalOpen(true)
  }

  const hideModalBanModal = () => setIsBanModalOpen(false)

  const getDropdownAction = (type: ViewerActionsType) => {
    switch (type) {
      case ViewerActionsType.promoteAction:
        return onPromoteUserToModerator(viewer)
      case ViewerActionsType.demoteAction:
        return onDemoteUserFromModerator(viewer)
      case ViewerActionsType.banFromShowAction:
        setBanType(ViewerActionsType.banFromShowAction)
        return openBanModal()
      case ViewerActionsType.banFromSellerAction:
        setBanType(ViewerActionsType.banFromSellerAction)
        return openBanModal()
    }
  }

  const handleConfirmBanUser = useCallback(() => {
    if (banType === ViewerActionsType.banFromSellerAction) {
      onBanUserFromSeller(viewer)
    } else {
      onBanUserFromShow(viewer)
    }

    setIsBanModalOpen(false)
  }, [banType, onBanUserFromSeller, onBanUserFromShow, viewer])

  const username = viewer.username

  return (
    <>
      <div className={`py-2 px-4 ${style.viewer_item__dropdown}`} hidden={isBanModalOpen}>
        <div className="flex flex-column gap-4">
          <User src={viewer.avatarWebPUrl}>{username}</User>

          {viewerActions.map((viewerAction) => {
            const { type, title } = viewerAction
            const { icon } = getActionDetails(type)
            return (
              <Button
                key={title}
                icon={icon}
                label={title}
                onClick={() => {
                  getDropdownAction(type)
                  setDropdownIsOpen(false)
                }}
              />
            )
          })}
        </div>
      </div>
      {/* TODO: use <Confirm> component instead of Modal below */}
      <Modal
        closable={false}
        footer={null}
        open={isBanModalOpen}
        title={false}
        width={425}
        onCancel={hideModalBanModal}
      >
        <div className="p-4">
          <div className="flex flex-column">
            <p className="callout_2">{t('confirmBanTitle', { username })}</p>
            <p className="callout_3">
              {banType === ViewerActionsType.banFromSellerAction
                ? t('confirmBanFromSellerContent')
                : t('confirmBanFromShowContent')}
            </p>
          </div>

          <div className="flex flex-row justify-between w-full">
            <Button
              className="cancel-action cancel-ban-user-action secondary"
              label={t('confirmBanCancelButton')}
              onClick={hideModalBanModal}
            />
            <Button
              className="validate-action confirm-ban-user-action primary"
              label={t('confirmBanOkButton')}
              onClick={handleConfirmBanUser}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
