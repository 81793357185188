import { type TFunction } from 'i18next'
import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

export enum TCGCardType {
  SEALED_PACK = 'sealed-pack',
  BOXED_SET = 'boxed-set',
  INDIVIDUAL_CARDS = 'individual-cards',
}

export const computeCardTypeFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '' },
    { value: TCGCardType.SEALED_PACK, label: t('productCardTypeSealedPackOptionLabel') },
    { value: TCGCardType.BOXED_SET, label: t('productCardTypeBoxedSetOptionLabel') },
    { value: TCGCardType.INDIVIDUAL_CARDS, label: t('productCardTypeIndividualCardsOptionLabel') },
  ]
}

type ProductCardTypeFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductCardTypeField = (props: ProductCardTypeFieldProps) => {
  const { value, onChange, disabled = false } = props
  const { t } = useTranslation()

  const options = useMemo(() => computeCardTypeFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormCardTypeLabel')}
      name="card-type"
      options={options}
      placeholder={t('productFormCardTypePlaceholder')}
      type="select"
      value={value}
      onChange={onChange}
    />
  )
}

export default ProductCardTypeField
