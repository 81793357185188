import { Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import InputLabel from '../../../../components/InputLabel'

type IndividualFormProps = {
  individualInformation: IndividualInformation
  setIndividualInformation: (infos: IndividualInformation) => void
  setFormDisabled: (value: boolean) => void
}

function IndividualForm(props: IndividualFormProps) {
  const { individualInformation, setIndividualInformation, setFormDisabled } = props
  const { t } = useTranslation()

  const handleFileSelect = (data: FileList | null, type: string) => {
    if (data && data[0]) {
      if (type === 'id-front') setIndividualInformation({ ...individualInformation, idFront: data[0] })
      if (type === 'id-back') setIndividualInformation({ ...individualInformation, idBack: data[0] })
      if (type === 'id-add-front') setIndividualInformation({ ...individualInformation, idAddFront: data[0] })

      const isComplete = isFormComplete(type)

      // Due tue latency while setting state, we need to consider the newly added file
      setFormDisabled(!isComplete)
    }
  }

  const isFormComplete = (type: string) => {
    const { idFront, idBack, idAddFront } = individualInformation

    // Type is the document that has just been set, not yet stored in individualInformation
    return Boolean(
      (idFront || type === 'id-front') && (idBack || type === 'id-back') && (idAddFront || type === 'id-add-front')
    )
  }

  return (
    <div>
      <h3>{t('signupFormUserInfoStepTitle')}</h3>
      <Form initialValues={{ remember: true }} layout="vertical" name="basic" size="large">
        <Form.Item rules={[{ required: true, message: t('inputLabelMandatory') }]}>
          <InputLabel title={t('signupFormLabelIdFront')} mandatory />
          <Input
            accept=".jpeg,.jpg,.png,.pdf,.heic,.heif"
            id="id-front"
            size="small"
            style={{ padding: 5 }}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files, 'id-front')}
          />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: t('inputLabelMandatory') }]}>
          <InputLabel title={t('signupFormLabelIdBack')} mandatory />
          <Input
            accept=".jpeg,.jpg,.png,.pdf,.heic,.heif"
            id="id-back"
            size="small"
            style={{ padding: 5 }}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files, 'id-back')}
          />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: t('inputLabelMandatory') }]}>
          <InputLabel title={t('signupFormLabelProofAddress')} mandatory />
          <Input
            accept=".jpeg,.jpg,.png,.pdf,.heic,.heif"
            id="id-add-front"
            size="small"
            style={{ padding: 5 }}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files, 'id-add-front')}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default IndividualForm
