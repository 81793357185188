import { useRef, useCallback, useLayoutEffect, type ReactNode } from 'react'

import Dialog from '@/components/ui/Dialog/Dialog'

import './CreateOrEditProductDialog.scss'

type CreateOrEditProductDialogProps = {
  title?: string
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  className?: string
}

const CreateOrEditProductDialog = (props: CreateOrEditProductDialogProps) => {
  const { title, isOpen, onClose = () => undefined, children, className } = props

  const dialogRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!dialogRef?.current) {
      return
    }

    const dialog = dialogRef.current
    dialog?.addEventListener('animationend', (event: AnimationEvent) => {
      if (event.animationName === 'closing-animation') {
        onClose()
      }
    })
  }, [dialogRef, onClose])

  const handleClose = useCallback(() => {
    // Trigger the closing animation.
    // Once the animation ends, the onClose callback will be called.
    dialogRef?.current?.classList.add('is-closing')
  }, [onClose])

  return (
    <Dialog
      className={`create-edit-product ${className}`}
      innerRef={dialogRef}
      isOpen={isOpen}
      modal={true}
      title={title}
      onClose={handleClose}
    >
      {children}
    </Dialog>
  )
}

export default CreateOrEditProductDialog
