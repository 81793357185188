import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPastAndCurrentShowsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPastAndCurrentShowsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, shows: { __typename?: 'ShowConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'ShowEdge', node: { __typename?: 'Show', id: string, legacyId: number, startAt: Date, name: string } }> } } | null };


export const GetPastAndCurrentShowsDocument = gql`
    query GetPastAndCurrentShows($first: Int!, $after: String) {
  viewer {
    id
    shows: pastAndCurrentShowsByStartDateDesc(first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          legacyId
          startAt
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetPastAndCurrentShowsQuery__
 *
 * To run a query within a React component, call `useGetPastAndCurrentShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastAndCurrentShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastAndCurrentShowsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPastAndCurrentShowsQuery(baseOptions: Apollo.QueryHookOptions<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables> & ({ variables: GetPastAndCurrentShowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>(GetPastAndCurrentShowsDocument, options);
      }
export function useGetPastAndCurrentShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>(GetPastAndCurrentShowsDocument, options);
        }
export function useGetPastAndCurrentShowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>(GetPastAndCurrentShowsDocument, options);
        }
export type GetPastAndCurrentShowsQueryHookResult = ReturnType<typeof useGetPastAndCurrentShowsQuery>;
export type GetPastAndCurrentShowsLazyQueryHookResult = ReturnType<typeof useGetPastAndCurrentShowsLazyQuery>;
export type GetPastAndCurrentShowsSuspenseQueryHookResult = ReturnType<typeof useGetPastAndCurrentShowsSuspenseQuery>;
export type GetPastAndCurrentShowsQueryResult = Apollo.QueryResult<GetPastAndCurrentShowsQuery, GetPastAndCurrentShowsQueryVariables>;