import { Checkbox, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { updateSellerConfig } from '@/actions/update-seller-config'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { useAppSelector } from '@/reducers'
import { logout } from '@/reducers/authentication'
import { selectAllBlocks } from '@/selectors/seller-contract'

import './SellerContract.scss'

type SellerContractProps = {
  isContractLoading?: boolean
  userId: number
}

const SellerContract = (props: SellerContractProps) => {
  const { isContractLoading, userId } = props
  const { t } = useTranslation()
  useDocumentTitle(t('commonSellerContract'))

  const [checkedConditions, setCheckedConditions] = useState<number[]>([])

  const dispatch = useDispatch()

  const sellerContract = useAppSelector(selectAllBlocks)

  const conditions = sellerContract.filter((block: any) => block.type === 'numbered_list_item')

  const title: any = sellerContract.find((block: any) => block?.heading_3)

  const onChange = (i: any) => {
    if (checkedConditions.includes(i)) {
      const newConditions = checkedConditions.filter((condition) => condition !== i)
      setCheckedConditions(newConditions)
    } else setCheckedConditions([...checkedConditions, i])
  }

  const acceptConditions = async () => {
    const result: any = await dispatch(
      updateSellerConfig({
        userId,
        values: {
          acceptedStudioConditionsOn: new Date(),
        },
      })
    )

    const { type } = result

    if (type.indexOf('rejected') > 0) {
      message.error(t('sellerContractMessageError'))
    } else {
      message.success(t('sellerContractMessageSuccess'))
    }
  }

  return (
    <ViewContainer id="seller-contract">
      {!isContractLoading && (
        <>
          <h2 className="title page-title title_2">{t('commonSellerContract')}</h2>
          <Card
            className="seller-contract"
            title={title?.heading_3.text.map((text: any, key: number) =>
              text.text.link ? (
                <a key={key} href={text.text.link.url} rel="noreferrer" target="_blank">
                  {text.text.content}
                </a>
              ) : (
                text.text.content
              )
            )}
          >
            <ul className="conditions-list">
              {conditions.map((condition: any, i) => (
                <li key={i}>
                  <Checkbox onChange={() => onChange(i)}>
                    {condition.numbered_list_item.text.map((text: any, key: number) =>
                      text.text.link ? (
                        <a key={key} href={text.text.link.url} rel="noreferrer" target="_blank">
                          {text.text.content}
                        </a>
                      ) : (
                        text.text.content
                      )
                    )}
                  </Checkbox>
                </li>
              ))}
            </ul>

            <div className="actions">
              <Button
                className="cancel-action logout-action secondary"
                label={t('sellerContractButtonLogoutLabel')}
                onClick={() => dispatch(logout())}
              />
              <Button
                className="validate-action accept-seller-contract-action primary"
                disabled={!(checkedConditions.length === conditions.length)}
                label={t('sellerContractButtonAcceptLabel')}
                onClick={acceptConditions}
              />
            </div>
          </Card>
        </>
      )}
      {isContractLoading && <Loader />}
    </ViewContainer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isContractLoading: state.sellerContract.isContractLoading,
  }
}

export default connect(mapStateToProps)(SellerContract)
