import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'

import Empty from '@/components/Empty/Empty'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'

import { useGetLastSoldProductsQuery } from '../operations.generated'

import LastInstantOrderItem from './LastPurchaseItem'

const LastPurchasesCard = () => {
  const { t } = useTranslation()
  const { data, loading } = useGetLastSoldProductsQuery()

  const allCustomersWithLateUnshippedOrdereredProducts = data?.lastSoldProducts?.edges

  return (
    <Card subtitle={t('widgetLastPurchasesNotice')} title={t('widgetLastPurchasesTitle')}>
      <div className="max-h-80 overflow-auto">
        {loading && (
          <div className="flex items-center h-full">
            <Loader />
          </div>
        )}
        {allCustomersWithLateUnshippedOrdereredProducts?.length === 0 ? (
          <div className="flex items-center h-full">
            <Empty description={t('widgetLastPurchasesEmpty')} type={'files'} />
          </div>
        ) : (
          <div>
            {allCustomersWithLateUnshippedOrdereredProducts?.map((edge) => {
              const customer = edge.customer
              const product = edge.node
              // TODO : the customer.id is a global ID, we should uniformize this and make the scrollTo work with it
              const key = customer.id + product.name + product.createdAt
              return (
                <HashLink key={key} to={`/shipments#${customer.id}`}>
                  <LastInstantOrderItem
                    currency={product.amountWithCurrency.currency}
                    date={product.createdAt}
                    price={product.amountWithCurrency.amount}
                    productName={product.name}
                    username={customer.username}
                  />
                </HashLink>
              )
            })}
          </div>
        )}
      </div>
    </Card>
  )
}

export default LastPurchasesCard
