import { useSSO } from '@bits-app/react-sso'
import { MissingProviderConfigError } from '@bits-app/react-sso/lib/esm/SSO/utils/errors'
import { message } from 'antd'
import { useCallback } from 'react'
import { FaApple } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'

import type { AppleServiceSignInResponse } from '@bits-app/react-sso'

interface Props {
  onClick: (response: AppleServiceSignInResponse) => void
}

const AppleSSOLoginButton = (props: Props) => {
  const { onClick } = props

  const { signInWithApple } = useSSO()
  const handleSignInWithApple = useCallback(async () => {
    try {
      const response = await signInWithApple({ usePopup: true, scope: 'openid name email' })
      if (response instanceof MissingProviderConfigError) {
        return message.error('Enable to login with Apple account, please contact support.')
      }

      return onClick(response)
    } catch (error: any) {
      // Nothing here for the moment
      // Note: The above promise might also throw an error if the user closes the popup
    }
  }, [signInWithApple])

  return (
    <Button className="sso-login-action apple-sso-login-action" icon={<FaApple />} onClick={handleSignInWithApple} />
  )
}

export default AppleSSOLoginButton
