import { useMemo, type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

const PRODUCT_BULK_IMPORT_TEMPLATE_FILE_CONTENT = `
"name","description","quantity","startingPrice","instantBuyPrice","imagesUrls","brand","model","color","size","gender","condition","cardType","cardLanguage","cardCondition","cardGrade","cardGradingService"
"My product 01 for auction with instant buy","my super description for product 01","1","10","50","https://fastly.picsum.photos/id/257/200/300.jpg?hmac=j0NVivHS9qSXBGkBOUjchG0Ckt6pje1KSfHsnwtr_8M","","","","","","","","","","",""
"Another product, no instant buy","my super description for product","1", "10","","https://fastly.picsum.photos/id/257/200/300.jpg?hmac=j0NVivHS9qSXBGkBOUjchG0Ckt6pje1KSfHsnwtr_8M|https://fastly.picsum.photos/id/257/200/300.jpg?hmac=j0NVivHS9qSXBGkBOUjchG0Ckt6pje1KSfHsnwtr_8M","","","","","","","","","","",""
"A giveaway product (no price)","my super description for product","2","","","","","","","","","","","","","",""
"An instant buy only product","my super description for product","2","","10","","","","","","","","","","","",""
"Sample fashion product","some description","1","9","49.00","","Nike","Air Max","black","42","men","new-with-tags","","","",""
"Sample fashion product 02","some description","1","10.0","50.00","","Nike","Air Max","pink","42","women","new-without-tags","","","",""
"Sample fashion product 03","some description","1","10.0","50.00","","Nike","Air Max","white","38","unisex","very-good","","","",""
"Sample fashion product 04","some description","1","17","49.50","","Nike","Air Max","apricot","36","women","good","","","",""
"Sample fashion product 05","some description","1","1","12","","Nike","Air Max","apricot","46","men","satisfactory","","","",""
"Sample TCG card 01","some description","1","10","50","","","","","","","","sealed-pack","japanese","mint","9.5","psa"
"Sample TCG card 02","some description","1","10","50","","","","","","","","boxed-set","english-us","mint","9.5","psa"
"Sample TCG card 03","some description","1","10","50","","","","","","","","individual-cards","french","mint","9.5","psa"
`.trim()

const DownloadBulkImportTemplateButton = () => {
  const { t } = useTranslation()

  const templateFileURL = useMemo(
    () => URL.createObjectURL(new Blob([PRODUCT_BULK_IMPORT_TEMPLATE_FILE_CONTENT], { type: 'text/csv' })),
    []
  )

  return (
    <Button
      className="secondary download-products-import-template-action"
      download="voggt-products-import-template.csv"
      href={templateFileURL}
      label={t('productsBulkImporterDownloadTemplateActionLabel')}
      onClick={(e: MouseEvent) => e.stopPropagation()}
    />
  )
}

export default DownloadBulkImportTemplateButton
