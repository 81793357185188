import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrdersCsvForDateRangeMutationVariables = Types.Exact<{
  after: Types.Scalars['Date']['input'];
  before: Types.Scalars['Date']['input'];
  language: Types.Scalars['BCP47LanguageTag']['input'];
}>;


export type CreateOrdersCsvForDateRangeMutation = { __typename?: 'Mutation', export: { __typename?: 'CreateCSVExportOfOrdersPayload', fileURL: string } };

export type CreateOrdersCsvForShowMutationVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
  language: Types.Scalars['BCP47LanguageTag']['input'];
}>;


export type CreateOrdersCsvForShowMutation = { __typename?: 'Mutation', export: { __typename?: 'CreateCSVExportOfOrdersPayload', fileURL: string } };


export const CreateOrdersCsvForDateRangeDocument = gql`
    mutation CreateOrdersCSVForDateRange($after: Date!, $before: Date!, $language: BCP47LanguageTag!) {
  export: createCSVExportOfOrdersSoldByViewerBetweeDates(
    input: {language: $language, after: $after, before: $before}
  ) {
    fileURL
  }
}
    `;
export type CreateOrdersCsvForDateRangeMutationFn = Apollo.MutationFunction<CreateOrdersCsvForDateRangeMutation, CreateOrdersCsvForDateRangeMutationVariables>;

/**
 * __useCreateOrdersCsvForDateRangeMutation__
 *
 * To run a mutation, you first call `useCreateOrdersCsvForDateRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrdersCsvForDateRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrdersCsvForDateRangeMutation, { data, loading, error }] = useCreateOrdersCsvForDateRangeMutation({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateOrdersCsvForDateRangeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrdersCsvForDateRangeMutation, CreateOrdersCsvForDateRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrdersCsvForDateRangeMutation, CreateOrdersCsvForDateRangeMutationVariables>(CreateOrdersCsvForDateRangeDocument, options);
      }
export type CreateOrdersCsvForDateRangeMutationHookResult = ReturnType<typeof useCreateOrdersCsvForDateRangeMutation>;
export type CreateOrdersCsvForDateRangeMutationResult = Apollo.MutationResult<CreateOrdersCsvForDateRangeMutation>;
export type CreateOrdersCsvForDateRangeMutationOptions = Apollo.BaseMutationOptions<CreateOrdersCsvForDateRangeMutation, CreateOrdersCsvForDateRangeMutationVariables>;
export const CreateOrdersCsvForShowDocument = gql`
    mutation CreateOrdersCSVForShow($showId: ID!, $language: BCP47LanguageTag!) {
  export: createCSVExportOfOrdersForShow(
    input: {showId: $showId, language: $language}
  ) {
    fileURL
  }
}
    `;
export type CreateOrdersCsvForShowMutationFn = Apollo.MutationFunction<CreateOrdersCsvForShowMutation, CreateOrdersCsvForShowMutationVariables>;

/**
 * __useCreateOrdersCsvForShowMutation__
 *
 * To run a mutation, you first call `useCreateOrdersCsvForShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrdersCsvForShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrdersCsvForShowMutation, { data, loading, error }] = useCreateOrdersCsvForShowMutation({
 *   variables: {
 *      showId: // value for 'showId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateOrdersCsvForShowMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrdersCsvForShowMutation, CreateOrdersCsvForShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrdersCsvForShowMutation, CreateOrdersCsvForShowMutationVariables>(CreateOrdersCsvForShowDocument, options);
      }
export type CreateOrdersCsvForShowMutationHookResult = ReturnType<typeof useCreateOrdersCsvForShowMutation>;
export type CreateOrdersCsvForShowMutationResult = Apollo.MutationResult<CreateOrdersCsvForShowMutation>;
export type CreateOrdersCsvForShowMutationOptions = Apollo.BaseMutationOptions<CreateOrdersCsvForShowMutation, CreateOrdersCsvForShowMutationVariables>;