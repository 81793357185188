import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import Alert from '@/components/ui/Alert/Alert'
import Link from '@/components/ui/Link/Link'
import { useUser } from '@/contexts/user/User.context'

import './StripeAutoDebitAgreementBanner.scss'

const StripeAutoDebitAgreementBanner = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { shouldAcceptStripeDebitAgreement = false } = sellerConfig || {}

  if (!shouldAcceptStripeDebitAgreement) {
    return null
  }

  return (
    <Alert className="stripe-auto-debit-agreement-alert" emphasis="high" type="warning" dismissible>
      <Trans
        i18nKey="sepaAcceptDebitAgreementBannerText"
        components={{
          1: <RouterLink className="text-link" to="/account?tab=banking"></RouterLink>,
          2: (
            <Link className="text-link" href={t('sepaAgreementPageLinkNotion')} rel="noreferrer" target="_blank"></Link>
          ),
        }}
      />
    </Alert>
  )
}

export default memo(StripeAutoDebitAgreementBanner)
