import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OpenRaidMutationVariables = Types.Exact<{
  input: Types.OpenRaidInShowInput;
}>;


export type OpenRaidMutation = { __typename?: 'Mutation', openRaidInShow: { __typename?: 'OpenRaidInShowPayload', showRaid: { __typename?: 'ShowRaid', id: string, status: Types.ShowRaidStatus, destinationShow: { __typename?: 'Show', id: string, name: string, seller: { __typename?: 'User', username: string } } } } };

export type SendRaidMutationVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type SendRaidMutation = { __typename?: 'Mutation', sendCurrentShowRaidAndEndShow: { __typename?: 'SendCurrentRaidAndEndShowPayload', showRaid: { __typename?: 'ShowRaid', status: Types.ShowRaidStatus } } };

export type CancelRaidMutationVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type CancelRaidMutation = { __typename?: 'Mutation', cancelCurrentRaidInShow: { __typename?: 'CancelCurrentRaidInShowPayload', ok: boolean } };

export type GetSimilarShowsToRaidQueryVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  all?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetSimilarShowsToRaidQuery = { __typename?: 'Query', similarShowsToRaid: { __typename?: 'ShowConnection', edges: Array<{ __typename?: 'ShowEdge', node: { __typename?: 'Show', id: string, seller: { __typename?: 'User', username: string } } }> } };


export const OpenRaidDocument = gql`
    mutation OpenRaid($input: OpenRaidInShowInput!) {
  openRaidInShow(input: $input) {
    showRaid {
      id
      status
      destinationShow {
        id
        name
        seller {
          username
        }
      }
    }
  }
}
    `;
export type OpenRaidMutationFn = Apollo.MutationFunction<OpenRaidMutation, OpenRaidMutationVariables>;

/**
 * __useOpenRaidMutation__
 *
 * To run a mutation, you first call `useOpenRaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenRaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openRaidMutation, { data, loading, error }] = useOpenRaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenRaidMutation(baseOptions?: Apollo.MutationHookOptions<OpenRaidMutation, OpenRaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenRaidMutation, OpenRaidMutationVariables>(OpenRaidDocument, options);
      }
export type OpenRaidMutationHookResult = ReturnType<typeof useOpenRaidMutation>;
export type OpenRaidMutationResult = Apollo.MutationResult<OpenRaidMutation>;
export type OpenRaidMutationOptions = Apollo.BaseMutationOptions<OpenRaidMutation, OpenRaidMutationVariables>;
export const SendRaidDocument = gql`
    mutation SendRaid($showId: ID!) {
  sendCurrentShowRaidAndEndShow(input: {showId: $showId}) {
    showRaid {
      status
    }
  }
}
    `;
export type SendRaidMutationFn = Apollo.MutationFunction<SendRaidMutation, SendRaidMutationVariables>;

/**
 * __useSendRaidMutation__
 *
 * To run a mutation, you first call `useSendRaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRaidMutation, { data, loading, error }] = useSendRaidMutation({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useSendRaidMutation(baseOptions?: Apollo.MutationHookOptions<SendRaidMutation, SendRaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRaidMutation, SendRaidMutationVariables>(SendRaidDocument, options);
      }
export type SendRaidMutationHookResult = ReturnType<typeof useSendRaidMutation>;
export type SendRaidMutationResult = Apollo.MutationResult<SendRaidMutation>;
export type SendRaidMutationOptions = Apollo.BaseMutationOptions<SendRaidMutation, SendRaidMutationVariables>;
export const CancelRaidDocument = gql`
    mutation CancelRaid($showId: ID!) {
  cancelCurrentRaidInShow(input: {showId: $showId}) {
    ok
  }
}
    `;
export type CancelRaidMutationFn = Apollo.MutationFunction<CancelRaidMutation, CancelRaidMutationVariables>;

/**
 * __useCancelRaidMutation__
 *
 * To run a mutation, you first call `useCancelRaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRaidMutation, { data, loading, error }] = useCancelRaidMutation({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useCancelRaidMutation(baseOptions?: Apollo.MutationHookOptions<CancelRaidMutation, CancelRaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRaidMutation, CancelRaidMutationVariables>(CancelRaidDocument, options);
      }
export type CancelRaidMutationHookResult = ReturnType<typeof useCancelRaidMutation>;
export type CancelRaidMutationResult = Apollo.MutationResult<CancelRaidMutation>;
export type CancelRaidMutationOptions = Apollo.BaseMutationOptions<CancelRaidMutation, CancelRaidMutationVariables>;
export const GetSimilarShowsToRaidDocument = gql`
    query GetSimilarShowsToRaid($showId: ID!, $first: Int, $after: String, $offset: Int, $all: Boolean) {
  similarShowsToRaid(
    showId: $showId
    first: $first
    after: $after
    offset: $offset
    all: $all
  ) {
    edges {
      node {
        id
        seller {
          username
        }
      }
    }
  }
}
    `;

/**
 * __useGetSimilarShowsToRaidQuery__
 *
 * To run a query within a React component, call `useGetSimilarShowsToRaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarShowsToRaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarShowsToRaidQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      offset: // value for 'offset'
 *      all: // value for 'all'
 *   },
 * });
 */
export function useGetSimilarShowsToRaidQuery(baseOptions: Apollo.QueryHookOptions<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables> & ({ variables: GetSimilarShowsToRaidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>(GetSimilarShowsToRaidDocument, options);
      }
export function useGetSimilarShowsToRaidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>(GetSimilarShowsToRaidDocument, options);
        }
export function useGetSimilarShowsToRaidSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>(GetSimilarShowsToRaidDocument, options);
        }
export type GetSimilarShowsToRaidQueryHookResult = ReturnType<typeof useGetSimilarShowsToRaidQuery>;
export type GetSimilarShowsToRaidLazyQueryHookResult = ReturnType<typeof useGetSimilarShowsToRaidLazyQuery>;
export type GetSimilarShowsToRaidSuspenseQueryHookResult = ReturnType<typeof useGetSimilarShowsToRaidSuspenseQuery>;
export type GetSimilarShowsToRaidQueryResult = Apollo.QueryResult<GetSimilarShowsToRaidQuery, GetSimilarShowsToRaidQueryVariables>;