export function getStatusTranslationKey(status: string) {
  switch (status) {
    case 'READY':
      return 'ledgerTransactionStatusReady'
    case 'LOCK':
      return 'ledgerTransactionStatusLock'
    case 'DONE':
      return 'ledgerTransactionStatusDone'
    case 'IN_PROGRESS':
      return 'ledgerTransactionStatusInProgress'
    case 'FAILED':
      return 'ledgerTransactionStatusFailed'
    default:
      return 'commonNotAvailableValue'
  }
}

export function getErrorCode(erorMessage: string) {
  // We're creating error codes for the care team to be able to know in which case we are.
  // If some new errors are handled in the Ledger, we should add them here
  switch (erorMessage) {
    /**
     * the user has less found that the payout amount /1st source of errors
     */
    case 'insuficient-found-error':
      return 10
    /**
     *  the transaction is created but the payout fails /2nd source of errors
     */
    case 'process-payout-error':
      return 11
    /**
     * the given payoutId is not found in the database
     */
    case 'no-previous-payout-found':
      return 12
    /**
     * the user has a negative balance
     */
    case 'negative-balance-error':
      return 13
    /**
     *  an error is encounter with the Stripe Balance of the user
     */
    case 'stripe-balance-error':
      return 14
    /**
     *  no payout id is returned from Stripe
     */
    case 'no-payout-id-error':
      return 15
    /**
     *  the amount is below our minimum payout amount
     */
    case 'lower-threshold-balance-error':
      return 16
    default:
      // This is if we fall into the catch of the payout and we don't have a specific error message. This case should be invastigated if this append
      return 1
  }
}
