import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { DispatchLabelType } from '@/network/graphql/types.generated'

import { ShippingMethod } from '../../ShippingModal'

import { SHIPMENT_DIMENSIONS_LETTER, ShipmentDimensionSelector } from './ShipmentDimensionSelector'

import type { ShipmentDimension } from '@/views/Shipments/utils/shipments.util'
import type { ChangeEvent } from 'react'

type SelectAndCheckboxChangeEvent = ChangeEvent<HTMLSelectElement>

type EasyShippingProps = {
  defaultShippingProvider: ShippingMethod
  onSubmit: (
    weight: number,
    height?: number | null,
    width?: number | null,
    depth?: number | null,
    labelFormat?: string | null
  ) => void
}

export enum ShippingLabelFormat {
  A4 = 'A4',
  A6 = 'A6',
}

export const ShippingLabelFormatToGraphQLDispatchLabelType = {
  [ShippingLabelFormat.A4]: DispatchLabelType.PdfA4,
  [ShippingLabelFormat.A6]: DispatchLabelType.Pdf_10x15,
}

export const Dimensions = (props: EasyShippingProps) => {
  const { t } = useTranslation()
  const { defaultShippingProvider, onSubmit } = props

  const [defaultShippingDimensions, setDefaultShippingDimensions] = useState<string | null>(
    getFromLocalStorage('shipmentDimensions') ?? null
  )

  const [shipmentHeight, setShipmentHeight] = useState<number | null>(
    getFromLocalStorage('shipmentHeight') !== null
      ? parseInt(getFromLocalStorage('shipmentHeight') as string)
      : SHIPMENT_DIMENSIONS_LETTER.height ?? null
  )
  const [shipmentWidth, setShipmentWidth] = useState<number | null>(
    getFromLocalStorage('shipmentWidth') !== null
      ? parseInt(getFromLocalStorage('shipmentWidth') as string)
      : SHIPMENT_DIMENSIONS_LETTER.width ?? null
  )
  const [shipmentDepth, setShipmentDepth] = useState<number | null>(
    getFromLocalStorage('shipmentDepth') !== null
      ? parseInt(getFromLocalStorage('shipmentDepth') as string)
      : SHIPMENT_DIMENSIONS_LETTER.depth ?? null
  )
  const [shipmentWeight, setShipmentWeight] = useState<number | null>(
    getFromLocalStorage('shipmentWeight') !== null
      ? parseFloat(getFromLocalStorage('shipmentWeight') as string)
      : SHIPMENT_DIMENSIONS_LETTER.weight ?? null
  )

  const [labelFormat, setLabelFormat] = useState<ShippingLabelFormat>(
    (getFromLocalStorage('userPreferredBoxtalShippingLabelFormat') as ShippingLabelFormat) || ShippingLabelFormat.A4
  )
  const handleFormatLabelChange = useCallback((event: SelectAndCheckboxChangeEvent) => {
    setLabelFormat(event.target.value as ShippingLabelFormat)
    setToLocalStorage('userPreferredBoxtalShippingLabelFormat', event.target.value)
  }, [])

  const handleDimensionAsCustom = useCallback(() => {
    if (defaultShippingDimensions === 'custom') return
    setDefaultShippingDimensions('custom')
    setToLocalStorage('shipmentDimensions', 'custom')
  }, [defaultShippingDimensions])

  const handleHeightChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setShipmentHeight(value ? parseInt(value) : null)
    setToLocalStorage('shipmentWeight', value)
    handleDimensionAsCustom()
  }, [])
  const handleWidthChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setShipmentWidth(value ? parseInt(value) : null)
    setToLocalStorage('shipmentWidth', value)
    handleDimensionAsCustom()
  }, [])
  const handleDepthChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setShipmentDepth(value ? parseInt(value) : null)
    setToLocalStorage('shipmentDepth', value)
    handleDimensionAsCustom()
  }, [])
  const handleWeightChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setShipmentWeight(value ? parseFloat(value) : null)
    setToLocalStorage('shipmentWeight', value)
    handleDimensionAsCustom()
  }, [])

  const handleDimensionChange = useCallback((dimensions: ShipmentDimension, name: string) => {
    setShipmentDepth(dimensions.depth ?? null)
    setShipmentHeight(dimensions.height ?? null)
    setShipmentWidth(dimensions.width ?? null)
    setShipmentWeight(dimensions.weight)
    setDefaultShippingDimensions(name)
  }, [])

  const handleSubmit = useCallback(() => {
    if (!shipmentWeight) return
    onSubmit(shipmentWeight, shipmentHeight, shipmentWidth, shipmentDepth, labelFormat)
  }, [onSubmit, shipmentHeight, shipmentWidth, shipmentDepth, shipmentWeight, labelFormat])

  const isFormValid = useMemo(
    () =>
      defaultShippingProvider === ShippingMethod.Boxtal
        ? Boolean(shipmentHeight && shipmentWidth && shipmentDepth && shipmentWeight)
        : Boolean(shipmentWeight),
    [shipmentHeight, shipmentWidth, shipmentDepth, shipmentWeight]
  )

  return (
    <Form className="easy-shipment-form" onSubmit={handleSubmit}>
      <Alert className="easy-shipment-weight-alert" emphasis="high" type="warning">
        {t('shippingWeightAlertMessage')}
      </Alert>
      <ShipmentDimensionSelector
        defaultValue={defaultShippingDimensions}
        onSetShipmentDimensions={handleDimensionChange}
      />
      <div
        className={`easy-shipment-form-dimensions-inputs ${defaultShippingProvider === ShippingMethod.Boxtal && 'is-Boxtal'}`}
      >
        {defaultShippingProvider === ShippingMethod.Boxtal && (
          <>
            <Field
              label={t('easyShippingHeight')}
              name="height"
              type="number"
              value={shipmentHeight || 0}
              required
              onChange={handleHeightChange}
            />

            <Field
              label={t('easyShippingWidth')}
              name="width"
              type="number"
              value={shipmentWidth || 0}
              required
              onChange={handleWidthChange}
            />
            <Field
              label={t('easyShippingDepth')}
              name="depth"
              type="number"
              value={shipmentDepth || 0}
              required
              onChange={handleDepthChange}
            />
          </>
        )}
        <Field
          label={t('easyShippingWeight')}
          name="weight"
          type="number"
          value={shipmentWeight || 0}
          required
          onChange={handleWeightChange}
        />
      </div>
      {defaultShippingProvider === ShippingMethod.Boxtal && (
        <Field
          label={t('shippingLabelFormatLabel')}
          name="labelFormat"
          type="select"
          value={labelFormat}
          options={[
            { label: ShippingLabelFormat.A4, value: ShippingLabelFormat.A4 },
            { label: ShippingLabelFormat.A6, value: ShippingLabelFormat.A6 },
          ]}
          required
          onChange={handleFormatLabelChange}
        />
      )}

      <div className="easy-shipment-form-button">
        <Button className="primary" disabled={!isFormValid} label={t('commonValidate')} type="submit" />
      </div>
    </Form>
  )
}
