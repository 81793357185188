import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Config from '@/config/config'
import { trackError } from '@/util/sentry'

import {
  useCreatePreShowTeaserVideoUploadUrlMutation,
  useDeletePreShowTeaserVideoMutation,
} from '../operations.generated'

import { ImageUploader } from './ImageUploader/ImageUploader'
import { VideoUploadedStatus, VideoUploaderContainer } from './VideoUploader/VideoUploader.container'

import type { UploadFile } from 'antd'

export type VideoPreShow = {
  name: string
  size: number
  status: VideoUploadedStatus
  url: string
}

interface ImageAndVideoProps {
  onChange: (image: string | UploadFile, video: VideoPreShow) => void
  image: string | UploadFile
  video: VideoPreShow
  isUploading: boolean
  onUploadChange: (isUploading: boolean) => void
  showId?: string
}

type CustomUrlPreshow = {
  get: string
  put: string
  delete: string
}

const { VITE_APP_LONG_TIMEOUT } = Config

export const ImageAndVideo = (props: ImageAndVideoProps) => {
  const { t } = useTranslation()
  const { onChange: onChange, image: formImage, video: formVideo, isUploading, onUploadChange, showId } = props

  const [deletePreShowTeaserVideo] = useDeletePreShowTeaserVideoMutation()
  const [createPreShowTeaserVideoUploadUrl] = useCreatePreShowTeaserVideoUploadUrlMutation()

  const [image, setImage] = useState<string | UploadFile>(formImage)
  const [videoUploadStatus, setVideoUploadStatus] = useState<VideoUploadedStatus>(
    formVideo.status
      ? formVideo.status
      : formVideo.name
        ? VideoUploadedStatus.VIDEO_UPLOADED
        : VideoUploadedStatus.NO_VIDEO
  )
  const [customUrlPreShow, setCustomUrlPreShow] = useState<CustomUrlPreshow>({
    get: formVideo.url,
    put: '',
    delete: '',
  })
  const [videoName, setVideoName] = useState<string>('')
  const [videoSize, setVideoSize] = useState<number>(0)

  const handleDeletePreShowTeaserVideo = async () => {
    if (!showId) return
    await deletePreShowTeaserVideo({
      variables: {
        input: {
          showId,
        },
      },
    })
  }

  const onVideoChangeDuringUpdate = (info: any) => {
    const { name, size } = info.file

    setVideoName(name)
    setVideoSize(size)
  }

  const getCustomUrlPreShow = () => {
    createPreShowTeaserVideoUploadUrl({
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: (data) => {
        const customUrlPut = data.createPreShowTeaserVideoUploadUrl.uploadUrl
        const newUrl = new URL(customUrlPut)
        newUrl.search = ''
        const customUrlGet = newUrl.toString()
        setCustomUrlPreShow({
          get: customUrlGet,
          put: customUrlPut,
          delete: data.createPreShowTeaserVideoUploadUrl.deleteUrl,
        })
      },
      onError: (error) => {
        trackError(error, { meta: { feature: 'show.creation.preShowTeaserVideo.createUploadUrl' } })
      },
    })
  }

  useEffect(() => {
    if (customUrlPreShow.put === '') {
      getCustomUrlPreShow()
    }
  })

  useEffect(() => {
    if (
      videoUploadStatus === VideoUploadedStatus.VIDEO_UPLOADING ||
      videoUploadStatus === VideoUploadedStatus.VIDEO_TRANSCODING
    ) {
      if (!isUploading) {
        onUploadChange(true)
      }
    } else {
      if (isUploading) {
        onUploadChange(false)
      }
      if (videoUploadStatus === VideoUploadedStatus.VIDEO_UPLOADED) {
        onChange(formImage, {
          name: videoName,
          size: videoSize,
          status: videoUploadStatus,
          url: customUrlPreShow.get,
        })
      } else if (videoUploadStatus === VideoUploadedStatus.NO_VIDEO) {
        onChange(formImage, {
          name: '',
          size: 0,
          status: videoUploadStatus,
          url: '',
        })
      }
    }
  }, [videoUploadStatus])

  useEffect(() => {
    onChange(image, formVideo)
  }, [image])

  return (
    <div className="image-and-video">
      <ImageUploader image={image} onUpload={setImage} />
      <VideoUploaderContainer
        customUrl={customUrlPreShow}
        label={t('createShowModalDetailsFormUploadVideoInputLabel')}
        setVideoUploadStatus={setVideoUploadStatus}
        videoUploadStatus={videoUploadStatus}
        preShowTeaserVideoInfo={{
          mp4HdFileInfo: { url: customUrlPreShow.get },
        }}
        onDelete={handleDeletePreShowTeaserVideo}
        onVideoChange={onVideoChangeDuringUpdate}
      />
    </div>
  )
}
