import * as Types from '../../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TerminateShowMutationVariables = Types.Exact<{
  input: Types.TerminateShowInput;
}>;


export type TerminateShowMutation = { __typename?: 'Mutation', terminateShow: { __typename?: 'TerminateShowPayload', show: { __typename?: 'Show', id: string, isBroadcasting: boolean, terminatedAt?: Date | null } } };


export const TerminateShowDocument = gql`
    mutation TerminateShow($input: TerminateShowInput!) {
  terminateShow(input: $input) {
    show {
      id
      isBroadcasting
      terminatedAt
    }
  }
}
    `;
export type TerminateShowMutationFn = Apollo.MutationFunction<TerminateShowMutation, TerminateShowMutationVariables>;

/**
 * __useTerminateShowMutation__
 *
 * To run a mutation, you first call `useTerminateShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateShowMutation, { data, loading, error }] = useTerminateShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateShowMutation(baseOptions?: Apollo.MutationHookOptions<TerminateShowMutation, TerminateShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateShowMutation, TerminateShowMutationVariables>(TerminateShowDocument, options);
      }
export type TerminateShowMutationHookResult = ReturnType<typeof useTerminateShowMutation>;
export type TerminateShowMutationResult = Apollo.MutationResult<TerminateShowMutation>;
export type TerminateShowMutationOptions = Apollo.BaseMutationOptions<TerminateShowMutation, TerminateShowMutationVariables>;