import { message } from 'antd'
import { useState } from 'react'

import { addProductPicture } from '@/actions/product/add-product-picture'
import { deleteProductPicture } from '@/actions/product/delete-product-picture'
import { notificationDanger } from '@/components/ui/Notification/Notification'

export interface ProductImage {
  id: string
  url: string
  file: File
  isDeleted: boolean
}

interface UseProductPicturesReturn {
  uploadImages: (files: File[], productId: number, updateInventoryProduct?: boolean) => Promise<void>
  deleteImages: (fileIds: number[], productId: number) => Promise<void>
  isLoading: boolean
}

export const useProductPictures = (): UseProductPicturesReturn => {
  const [isLoading, setIsLoading] = useState(false)

  const uploadImages = async (files: File[], productId: number) => {
    if (!files.length) {
      return
    }

    setIsLoading(true)
    try {
      await addProductPicture({ files, productId })
    } catch (e: any) {
      notificationDanger(e?.message)
    }
    setIsLoading(false)
  }

  const deleteImages = async (fileIds: number[], productId: number) => {
    if (!fileIds.length) {
      return
    }

    setIsLoading(true)
    try {
      // TODO: Use promise.allSettled and properly handle errors
      await Promise.all(fileIds.map((fileId) => deleteProductPicture({ productId, fileId })))
    } catch (e: any) {
      message.error(e?.message)
    }
    setIsLoading(false)
  }

  return { uploadImages, deleteImages, isLoading }
}
