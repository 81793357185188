import pLimit from 'p-limit'

import { useUser } from '@/contexts/user/User.context'
import { EasyShippingMethod } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useSetShipmentStatusLabelDownloadedMutation } from '@/views/Shipments/operations.generated'

import BulkDownloadShippingLabelsForBoxtal from './BulkDownloadShippingLabelsForBoxtal'
import BulkDownloadShippingLabelsForSendcloud from './BulkDownloadShippingLabelsForSendcloud'

import type { PrinterType } from './types'

import './BulkDownloadShippingLabels.scss'

type ShipmentsBulkShippingLabelsDownloadButtonProps = {
  shipments: string[]
  onDownloaded?: () => void
}

export const BulkDownloadShippingLabels = (props: ShipmentsBulkShippingLabelsDownloadButtonProps) => {
  const { shipments, onDownloaded } = props

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { easyShippingMethod } = sellerConfig || {}

  // we should replace here with the field from backend
  const useSendCloud = easyShippingMethod === EasyShippingMethod.Sendcloud
  const useBoxtal = easyShippingMethod === EasyShippingMethod.Boxtal

  const [setShipmentStatusLabelDownloaded] = useSetShipmentStatusLabelDownloadedMutation()

  const setShipmentLabelAsDownloaded = (shipmentId: string) => {
    setShipmentStatusLabelDownloaded({
      variables: {
        input: { shipmentId },
      },
      onError: (error: Error) => {
        trackError(error, {
          meta: { shipmentId, context: 'shipments.shipped.markShipmentLabelAsDownloaded' },
        })
      },
    })
  }

  const handledDownloadedShippingLabels = async (shipmentIds: string[], options?: { printerType?: PrinterType }) => {
    const { printerType } = options || {}

    // Track the event
    trackEvent('SHIPMENT_BULK_DOWNLOAD_BUTTON_CLICK', { useBoxtal, useSendCloud, printerType })

    // Set all shipment labels as downloaded (max 5 at a time)
    const limit = pLimit(5)
    const promisesWithLimitedConcurrency = shipmentIds.map((shipmentId) =>
      limit(() => setShipmentLabelAsDownloaded(shipmentId))
    )
    await Promise.allSettled(promisesWithLimitedConcurrency)

    if (onDownloaded) {
      onDownloaded()
    }
  }

  if (!useSendCloud && !useBoxtal) {
    return null
  }

  if (useBoxtal) {
    return (
      <BulkDownloadShippingLabelsForBoxtal shipmentIds={shipments} onDownloaded={handledDownloadedShippingLabels} />
    )
  }
  if (useSendCloud) {
    return (
      <BulkDownloadShippingLabelsForSendcloud shipmentIds={shipments} onDownloaded={handledDownloadedShippingLabels} />
    )
  }
  return null
}
