const CloudIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.7516 23.1467C26.6677 22.2813 28 20.3535 28 18.1144C28 14.6532 25.5597 12.5957 22.5293 12.5944C22.5293 10.4175 20.822 6.0625 16 6.0625C11.1779 6.0625 9.47071 10.4175 9.47071 12.5944C6.44411 12.6203 4 14.6532 4 18.1144C4 20.3535 5.33232 22.2813 7.24843 23.1467"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M16.0004 25.9355L16 16.6094"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12.3047 22.2395L15.9993 25.9342L19.6941 22.2395"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default CloudIcon
