import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowUpShortWide } from 'react-icons/fa6'

import Field from '@/components/Form/Field/Field'
import Button from '@/components/ui/Button/Button'
import Popover from '@/components/ui/Popover/Popover'
import { useShow } from '@/contexts/show/Show.context'
import { removeFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { ProductSortColumn, SortDirection } from '@/network/graphql/types.generated'

import './SortingOptions.scss'

export const SortingOptions = () => {
  const { t } = useTranslation()
  const [isSortingOptionsOpen, setIsSortingOptionsOpen] = useState<boolean>(false)
  const [hasColumnSorting, setHasColumnSorting] = useState<boolean>(false)

  enum SortingOptions {
    dateAsc = 'DateAsc',
    dateDesc = 'DateDesc',
    followingUsersCount = 'following',
    position = 'position',
  }

  const {
    sortByProducts,
    setSortByProducts,
    setFilterByPositiveQuantity,
    filterByPositiveQuantity,
    setAuctionSortBy,
    setInstantBuySortBy,
    setGiveawaySortBy,
    auctionSortBy,
    instantBuySortBy,
    giveawaySortBy,
  } = useShow()

  const handleResetSorting = useCallback(() => {
    setAuctionSortBy(undefined)
    setInstantBuySortBy(undefined)
    setGiveawaySortBy(undefined)
  }, [setAuctionSortBy, setInstantBuySortBy, setGiveawaySortBy])

  const handleSortingChange = useCallback(
    (sorting: SortingOptions) => {
      setIsSortingOptionsOpen(false)

      if (sorting === SortingOptions.followingUsersCount) {
        setSortByProducts({
          column: ProductSortColumn.FollowingUsersCount,
          direction: SortDirection.Desc,
        })
        handleResetSorting()
      } else if (sorting === SortingOptions.dateAsc || sorting === SortingOptions.dateDesc) {
        setSortByProducts({
          column: ProductSortColumn.CreatedAt,
          direction: sorting === SortingOptions.dateAsc ? SortDirection.Asc : SortDirection.Desc,
        })
        handleResetSorting()
      } else if (sorting === SortingOptions.position) {
        setSortByProducts(undefined)
        handleResetSorting()
      }
    },
    [setSortByProducts, handleResetSorting]
  )

  useEffect(() => {
    if (auctionSortBy || instantBuySortBy || giveawaySortBy) {
      setHasColumnSorting(true)
    } else {
      setHasColumnSorting(false)
    }
  }, [auctionSortBy, instantBuySortBy, giveawaySortBy])

  return (
    <Popover
      className="sorting-options-popover"
      classNameContent="sorting-options-popover-content"
      hideOnMouseOut={false}
      isOpen={isSortingOptionsOpen}
      openOnMouseHover={false}
      positions={['bottom', 'top', 'left']}
      content={
        <div className="sorting-options">
          <div
            className={!hasColumnSorting && sortByProducts === undefined ? 'selected' : ''}
            onClick={() => handleSortingChange(SortingOptions.position)}
          >
            <header className="title">
              <h2>
                {t('sortingProductsPositionTitle')} {t('defaultSortingOption')}
              </h2>
            </header>
            <p>{t('sortingProductsPosition')}</p>
          </div>

          <hr />

          <div
            className={
              !hasColumnSorting &&
              sortByProducts?.direction === SortDirection.Desc &&
              sortByProducts.column === ProductSortColumn.CreatedAt
                ? 'selected'
                : ''
            }
            onClick={() => handleSortingChange(SortingOptions.dateDesc)}
          >
            <header className="title">
              <h2>{t('sortingProductsDateDescTitle')}</h2>
            </header>
            <p>{t('sortingProductsZA')}</p>
          </div>

          <hr />

          <div
            className={
              !hasColumnSorting &&
              sortByProducts?.direction === SortDirection.Asc &&
              sortByProducts.column === ProductSortColumn.CreatedAt
                ? 'selected'
                : ''
            }
            onClick={() => handleSortingChange(SortingOptions.dateAsc)}
          >
            <header className="title">
              <h2>{t('sortingProductsDateAscTitle')}</h2>
            </header>
            <p>{t('sortingProductsAZ')}</p>
          </div>

          <hr />

          <div
            className={
              !hasColumnSorting && sortByProducts?.column === ProductSortColumn.FollowingUsersCount ? 'selected' : ''
            }
            onClick={() => handleSortingChange(SortingOptions.followingUsersCount)}
          >
            <header className="title">
              <h2>{t('sortingProductsFollowingTitle')}</h2>
            </header>
            <p>{t('sortingProductsFollowing')}</p>
          </div>

          <hr />

          <div className="filter-sold-out">
            <Field
              checked={filterByPositiveQuantity}
              label={t('showFilterByPositiveTotalQuantity')}
              name="quantity"
              type="checkbox"
              onClick={() => {
                setFilterByPositiveQuantity(!filterByPositiveQuantity)
                if (!filterByPositiveQuantity) {
                  setToLocalStorage('show_filter_sold_out', 'true')
                } else {
                  removeFromLocalStorage('show_filter_sold_out')
                }
              }}
            />
          </div>
        </div>
      }
      onClickOutside={() => setIsSortingOptionsOpen(false)}
    >
      <Button
        className="sorting-products-action"
        icon={<FaArrowUpShortWide />}
        onClick={() => setIsSortingOptionsOpen(!isSortingOptionsOpen)}
      />
    </Popover>
  )
}
