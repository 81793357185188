import { useGetLiveShowMetricsQuery } from '../operations.generated'

export interface UseLiveShowProps {
  showId: number
}

export const useLiveShowMetrics = ({ showId }: UseLiveShowProps) => {
  const { loading, data } = useGetLiveShowMetricsQuery({
    skip: !showId,
    variables: { nodeId: `Show|${showId}` },
  })

  return { loading, show: data?.node.__typename === 'Show' ? data.node : null }
}
