import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import SellerBankBicField from '@/views/Account/components/BankInfo/BankInfoForm/SellerBankBicField/SellerBankBicField'
import SellerBankIbanField from '@/views/Account/components/BankInfo/BankInfoForm/SellerBankIbanField/SellerBankIbanField'
import SellerBankNameField from '@/views/Account/components/BankInfo/BankInfoForm/SellerBankNameField/SellerBankNameField'

import SellerBankSepaConditionField from './SellerBankSepaConditionField copy/SellerBankSepaConditionField'
import SellerBankSepaNotifField from './SellerBankSepaNotifField/SellerBankSepaNotifField'

import type { BankInfoFormValidity, BankInfoInputData } from '@/views/Account/components/BankInfo/BankInfoForm/types'

import './BankInfoForm.scss'

type BankInfoFormProps = {
  verificationToken?: string
  initialValues?: BankInfoInputData
  isLoading?: boolean
  error?: string
  onSubmit?: (input: BankInfoInputData) => void
  onCancel?: () => void
}

const BankInfoForm = (props: BankInfoFormProps) => {
  const {
    verificationToken,
    initialValues,
    isLoading = false,
    error,
    onSubmit = () => undefined,
    onCancel = () => undefined,
  } = props

  const { t } = useTranslation()

  const initialValidity: BankInfoFormValidity = {
    bankName: false,
    iban: false,
    bic: false,
    verificationCode: false,
    sepaCondition: false,
    sepaNotif: false,
  }

  const [iban, setIban] = useState<string>(initialValues?.iban || '')
  const [bankName, setBankName] = useState<string>(initialValues?.bankName || '')
  const [bic, setBic] = useState<string>(initialValues?.bic || '')
  const [sepaNotif, setSepaNotif] = useState<boolean>(false)
  const [sepaCondition, setSepaCondition] = useState<boolean>(false)
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [validity, setValidity] = useState<BankInfoFormValidity>(initialValidity)

  const handleBankNameChange = useCallback((event) => setBankName(event.target.value), [])
  const handleIbanChange = useCallback(
    (event) => setIban(event.target.value.replace(/-/g, '').replace(/\s/g, '').toUpperCase()),
    []
  )
  const handleBicChange = useCallback(
    (event) => setBic(event.target.value.replace(/-/g, '').replace(/\s/g, '').toUpperCase()),
    []
  )
  const handleVerificationCodeChange = useCallback((event) => setVerificationCode(event.target.value), [])

  const handleSepaNotifChange = useCallback((event) => setSepaNotif(event.target.value), [])
  const handleSepaConditionChange = useCallback((event) => setSepaCondition(event.target.value), [])

  const handleValidityChange = useCallback((name: keyof BankInfoFormValidity, isValid: boolean) => {
    setValidity((prev) => ({ ...prev, [name]: isValid }))
  }, [])

  const resetForm = useCallback(() => {
    setBankName('')
    setIban('')
    setBic('')
    setVerificationCode('')
    setSepaCondition(false)
    setSepaNotif(false)
    setValidity(initialValidity)
  }, [])

  const handleCancel = useCallback(() => {
    resetForm()
    onCancel()
  }, [onCancel])

  const handleSubmit = useCallback(() => {
    const inputData = { iban, bankName, bic, verificationCode }
    onSubmit(inputData)
  }, [iban, bankName, bic, verificationCode, onSubmit])

  const isValid = !verificationToken
    ? validity.bankName && validity.iban && validity.bic && validity.sepaNotif && validity.sepaCondition
    : verificationCode

  return (
    <Form id="bank-info-form" onSubmit={handleSubmit}>
      {!verificationToken && (
        <>
          <SellerBankNameField
            value={bankName}
            onChange={handleBankNameChange}
            onValidityChange={handleValidityChange}
          />
          <SellerBankIbanField value={iban} onChange={handleIbanChange} onValidityChange={handleValidityChange} />
          <SellerBankBicField value={bic} onChange={handleBicChange} onValidityChange={handleValidityChange} />

          <h3 className="sepa-title">{t('signupFormBankAccountSepaLegalHeader')}</h3>
          <p>{t('signupFormBankAccountSepaLegalText')}</p>
          <SellerBankSepaNotifField
            value={sepaNotif}
            onChange={handleSepaNotifChange}
            onValidityChange={handleValidityChange}
          />
          <SellerBankSepaConditionField
            value={sepaCondition}
            onChange={handleSepaConditionChange}
            onValidityChange={handleValidityChange}
          />
        </>
      )}

      {verificationToken && (
        <>
          <p className="verif-code-intro-message">{t('sellerBankInfoUpdateConfirmationCodeIntroMessage')}</p>
          <Field
            id="verification-code"
            label={t('sellerBankInfoVerificationCodeFieldLabel')}
            name="verificationCode"
            placeholder={t('sellerBankInfoVerificationCodeFieldPlaceholder')}
            required={true}
            type="text"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
          />
        </>
      )}

      <div className="actions">
        {error && (
          <Alert className="error" emphasis="high" type="danger">
            {error}
          </Alert>
        )}
        <Button
          className="cancel-action cancel-edit-bank-info-action"
          label={t('commonCancel')}
          onClick={handleCancel}
        />
        <Button
          className="primary"
          disabled={!isValid}
          htmlType="submit"
          isLoading={isLoading}
          label={t('commonContinueActionLabel')}
        />
      </div>
    </Form>
  )
}

export default BankInfoForm
