const RaidIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12.0889 8.71923C11.8396 8.71805 11.6793 8.83432 11.6205 8.96456C11.4568 9.32695 11.0304 9.48803 10.668 9.32433C10.3056 9.16064 10.1445 8.73417 10.3081 8.37178C10.6561 7.60147 11.4253 7.27609 12.0958 7.27924C12.7624 7.28237 13.532 7.61365 13.8557 8.39158C14.0085 8.75871 13.8347 9.18016 13.4675 9.33293C13.1004 9.48568 12.679 9.3119 12.5262 8.94477C12.4824 8.83941 12.3421 8.72041 12.0889 8.71923Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.12194 9.85497C9.42331 10.1144 9.4573 10.569 9.19788 10.8704L7.86871 12.4143C7.12475 13.3139 6.26237 14.1085 5.30508 14.7767L4.92631 15.041C4.46492 15.363 4.19 15.8901 4.19 16.4528V17.6338C4.19 17.7624 4.29431 17.8668 4.42299 17.8668H8.65221C9.04986 17.8668 9.37221 18.1891 9.37221 18.5868C9.37221 18.9844 9.04986 19.3068 8.65221 19.3068H4.42299C3.49902 19.3068 2.75 18.5578 2.75 17.6338V16.4528C2.75 15.4195 3.25489 14.4515 4.1022 13.8602L4.48098 13.5958C5.33344 13.0009 6.1012 12.293 6.76325 11.4916L6.77268 11.4804L8.10655 9.93091C8.36597 9.62957 8.82058 9.59554 9.12194 9.85497Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.0979 9.85497C15.3993 9.59554 15.8539 9.62957 16.1133 9.93091L17.4472 11.4804L17.4566 11.4916C18.1187 12.293 18.8865 13.0009 19.739 13.5958L20.1177 13.8602C20.965 14.4515 21.4699 15.4195 21.4699 16.4528V17.6338C21.4699 18.5578 20.7208 19.3068 19.7969 19.3068H15.5677C15.17 19.3068 14.8477 18.9844 14.8477 18.5868C14.8477 18.1891 15.17 17.8668 15.5677 17.8668H19.7969C19.9256 17.8668 20.0299 17.7624 20.0299 17.6338V16.4528C20.0299 15.8901 19.755 15.363 19.2936 15.041L18.9148 14.7767C17.9576 14.1085 17.0951 13.3139 16.3512 12.4143L15.022 10.8704C14.7626 10.569 14.7966 10.1144 15.0979 9.85497Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.1133 16.0425C17.1133 15.6448 17.4356 15.3225 17.8333 15.3225H20.6716C21.0692 15.3225 21.3916 15.6448 21.3916 16.0425C21.3916 16.4402 21.0692 16.7625 20.6716 16.7625H17.8333C17.4356 16.7625 17.1133 16.4402 17.1133 16.0425Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.90625 16.0425C2.90625 15.6448 3.22861 15.3225 3.62625 15.3225H6.21755C6.6152 15.3225 6.93755 15.6448 6.93755 16.0425C6.93755 16.4402 6.6152 16.7625 6.21755 16.7625H3.62625C3.22861 16.7625 2.90625 16.4402 2.90625 16.0425Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.138 14.2763C12.5356 14.2763 12.858 14.5986 12.858 14.9963V18.5869C12.858 18.9846 12.5356 19.3069 12.138 19.3069C11.7402 19.3069 11.418 18.9846 11.418 18.5869V14.9963C11.418 14.5986 11.7402 14.2763 12.138 14.2763Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.1371 4.05984C11.7789 4.05984 11.4354 4.20214 11.182 4.45545C10.0579 5.57961 9.42633 7.10431 9.42633 8.69413V18.6825C9.42633 18.9991 9.54683 19.218 9.72888 19.37C9.92386 19.5327 10.2303 19.6494 10.6222 19.6494H13.652C14.044 19.6494 14.3504 19.5327 14.5453 19.37C14.7274 19.218 14.8479 18.9991 14.8479 18.6825V8.69414C14.8479 7.10432 14.2163 5.57961 13.0921 4.45545C12.8389 4.20214 12.4953 4.05984 12.1371 4.05984ZM10.1639 3.43721C10.6872 2.91386 11.397 2.61984 12.1371 2.61984C12.8772 2.61984 13.587 2.91386 14.1104 3.43721C15.5046 4.83143 16.2879 6.7224 16.2879 8.69414V18.6825C16.2879 19.424 15.9795 20.0485 15.4681 20.4755C14.9697 20.8916 14.3182 21.0894 13.652 21.0894H10.6222C9.95602 21.0894 9.30452 20.8916 8.80602 20.4755C8.29471 20.0485 7.98633 19.424 7.98633 18.6825V8.69413C7.98633 6.72239 8.76959 4.83143 10.1639 3.43721Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default RaidIcon
