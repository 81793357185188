import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LedgerQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filters?: Types.InputMaybe<Types.SellerLedgerTransactionsFilters>;
  sort?: Types.InputMaybe<Types.SellerLedgerTransactionsSort>;
}>;


export type LedgerQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', sellerConfig?: { __typename?: 'SellerConfig', canUseLedger: boolean } | null } | null, sellerLedgerTransactions: { __typename?: 'SellerLedgerTransactionConnection', totalCount: number, edges: Array<{ __typename?: 'SellerLedgerTransactionEdge', node: { __typename?: 'SellerLedgerTransaction', createdAt: Date, type: Types.SellerLedgerTransactionType, orderId?: number | null, shopId?: number | null, description?: string | null, showId?: number | null, status: Types.SellerLedgerTransactionStatus, id: string, stripePayoutId?: string | null, amount: { __typename?: 'AmountWithCurrency', amount: number, currency: Types.Currency } } }> }, sellerLedgerBalance: { __typename?: 'SellerLedgerBalance', availableSoon: { __typename?: 'AmountWithCurrency', currency: Types.Currency, amount: number }, availableforPayout: { __typename?: 'AmountWithCurrency', amount: number, currency: Types.Currency } }, sellerLedgerPayouts: { __typename?: 'SellerLedgerPayoutConnection', edges: Array<{ __typename?: 'SellerLedgerPayoutEdge', node: { __typename?: 'SellerLedgerPayout', createdAt: Date, description?: string | null, id: string, status: Types.SellerLedgerTransactionStatus, stripePayoutId?: string | null, amount: { __typename?: 'AmountWithCurrency', amount: number, currency: Types.Currency } } }> } };

export type CreatePayoutFromSellerLedgerMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreatePayoutFromSellerLedgerMutation = { __typename?: 'Mutation', createPayoutFromSellerLedger: { __typename?: 'CreatePayoutFromSellerLedgerPayloadError', ok: boolean, error: string } | { __typename?: 'CreatePayoutFromSellerLedgerPayloadOk', ok: boolean } };

export type CreateExportSellerLedgerTransactionsMutationVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.SellerLedgerTransactionsFilters>;
  language: Types.Scalars['BCP47LanguageTag']['input'];
}>;


export type CreateExportSellerLedgerTransactionsMutation = { __typename?: 'Mutation', createExportSellerLedgerTransactions: { __typename?: 'CreateExportSellerLedgerPayload', fileURL: string } };


export const LedgerDocument = gql`
    query Ledger($first: Int!, $offset: Int, $filters: SellerLedgerTransactionsFilters, $sort: SellerLedgerTransactionsSort) {
  viewer {
    sellerConfig {
      canUseLedger
    }
  }
  sellerLedgerTransactions(
    first: $first
    offset: $offset
    filters: $filters
    sort: $sort
  ) {
    totalCount
    edges {
      node {
        createdAt
        type
        orderId
        shopId
        description
        showId
        status
        id
        stripePayoutId
        amount {
          amount
          currency
        }
      }
    }
  }
  sellerLedgerBalance {
    availableSoon {
      currency
      amount
    }
    availableforPayout {
      amount
      currency
    }
  }
  sellerLedgerPayouts(first: 20) {
    edges {
      node {
        createdAt
        description
        id
        status
        stripePayoutId
        amount {
          amount
          currency
        }
      }
    }
  }
}
    `;

/**
 * __useLedgerQuery__
 *
 * To run a query within a React component, call `useLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLedgerQuery(baseOptions: Apollo.QueryHookOptions<LedgerQuery, LedgerQueryVariables> & ({ variables: LedgerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LedgerQuery, LedgerQueryVariables>(LedgerDocument, options);
      }
export function useLedgerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LedgerQuery, LedgerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LedgerQuery, LedgerQueryVariables>(LedgerDocument, options);
        }
export function useLedgerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LedgerQuery, LedgerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LedgerQuery, LedgerQueryVariables>(LedgerDocument, options);
        }
export type LedgerQueryHookResult = ReturnType<typeof useLedgerQuery>;
export type LedgerLazyQueryHookResult = ReturnType<typeof useLedgerLazyQuery>;
export type LedgerSuspenseQueryHookResult = ReturnType<typeof useLedgerSuspenseQuery>;
export type LedgerQueryResult = Apollo.QueryResult<LedgerQuery, LedgerQueryVariables>;
export const CreatePayoutFromSellerLedgerDocument = gql`
    mutation CreatePayoutFromSellerLedger {
  createPayoutFromSellerLedger {
    ... on CreatePayoutFromSellerLedgerPayloadOk {
      ok
    }
    ... on CreatePayoutFromSellerLedgerPayloadError {
      ok
      error
    }
  }
}
    `;
export type CreatePayoutFromSellerLedgerMutationFn = Apollo.MutationFunction<CreatePayoutFromSellerLedgerMutation, CreatePayoutFromSellerLedgerMutationVariables>;

/**
 * __useCreatePayoutFromSellerLedgerMutation__
 *
 * To run a mutation, you first call `useCreatePayoutFromSellerLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayoutFromSellerLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayoutFromSellerLedgerMutation, { data, loading, error }] = useCreatePayoutFromSellerLedgerMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePayoutFromSellerLedgerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayoutFromSellerLedgerMutation, CreatePayoutFromSellerLedgerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayoutFromSellerLedgerMutation, CreatePayoutFromSellerLedgerMutationVariables>(CreatePayoutFromSellerLedgerDocument, options);
      }
export type CreatePayoutFromSellerLedgerMutationHookResult = ReturnType<typeof useCreatePayoutFromSellerLedgerMutation>;
export type CreatePayoutFromSellerLedgerMutationResult = Apollo.MutationResult<CreatePayoutFromSellerLedgerMutation>;
export type CreatePayoutFromSellerLedgerMutationOptions = Apollo.BaseMutationOptions<CreatePayoutFromSellerLedgerMutation, CreatePayoutFromSellerLedgerMutationVariables>;
export const CreateExportSellerLedgerTransactionsDocument = gql`
    mutation CreateExportSellerLedgerTransactions($filters: SellerLedgerTransactionsFilters, $language: BCP47LanguageTag!) {
  createExportSellerLedgerTransactions(
    input: {filters: $filters, language: $language}
  ) {
    fileURL
  }
}
    `;
export type CreateExportSellerLedgerTransactionsMutationFn = Apollo.MutationFunction<CreateExportSellerLedgerTransactionsMutation, CreateExportSellerLedgerTransactionsMutationVariables>;

/**
 * __useCreateExportSellerLedgerTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateExportSellerLedgerTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportSellerLedgerTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportSellerLedgerTransactionsMutation, { data, loading, error }] = useCreateExportSellerLedgerTransactionsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateExportSellerLedgerTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateExportSellerLedgerTransactionsMutation, CreateExportSellerLedgerTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExportSellerLedgerTransactionsMutation, CreateExportSellerLedgerTransactionsMutationVariables>(CreateExportSellerLedgerTransactionsDocument, options);
      }
export type CreateExportSellerLedgerTransactionsMutationHookResult = ReturnType<typeof useCreateExportSellerLedgerTransactionsMutation>;
export type CreateExportSellerLedgerTransactionsMutationResult = Apollo.MutationResult<CreateExportSellerLedgerTransactionsMutation>;
export type CreateExportSellerLedgerTransactionsMutationOptions = Apollo.BaseMutationOptions<CreateExportSellerLedgerTransactionsMutation, CreateExportSellerLedgerTransactionsMutationVariables>;