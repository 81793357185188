import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import { useSellerNewsQuery } from './operations.generated'

const SellerNews = () => {
  const { t } = useTranslation()
  const [lastVoggNewsCheck, setLastVoggNewsCheck] = useState<Date>(new Date('12 june 2021'))
  const { data } = useSellerNewsQuery({ fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-only' })
  const sellerNews = data?.sellerNews || []

  useEffect(() => {
    const localLastVoggNewsCheck = getFromLocalStorage('last_voggt_news_check')
    if (localLastVoggNewsCheck) setLastVoggNewsCheck(new Date(parseInt(localLastVoggNewsCheck)))

    const timeout = setTimeout(() => {
      setToLocalStorage('last_voggt_news_check', new Date().getTime().toString())
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="p-4 flex flex-col gap-4 h-full overflow-auto">
      <h2 className="text-lg font-semibold mb-2">{t('widgetVoggtNewsTitle')}</h2>

      {sellerNews?.map((news) => (
        <div key={news.title}>
          <p className="text-base font-semibold flex-1 mb-1">{news.title}</p>

          <div className="flex gap-2 justify-between items-center mb-2">
            <p className="text-xs text-gray-500 m-0">{new Date(news.createdAt).toDateString()}</p>
            {lastVoggNewsCheck && new Date(news.createdAt) > lastVoggNewsCheck && (
              <div className="w-2 h-2 bg-sky-400 rounded-full animate-pulse" />
            )}
          </div>
          <p className="mb-1">{news.message}</p>

          {news.url && (
            <a className="text-blue-500 hover:underline m-0" href={news.url} rel="noreferrer" target="_blank">
              {t('commonSeeMore')}
            </a>
          )}
        </div>
      ))}
    </div>
  )
}

export default SellerNews
