import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModeratorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetModeratorsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, allModerators: Array<{ __typename?: 'User', id: string, legacyId: number, username: string, avatarWebPUrl?: string | null }> } | null };

export type PromoteUserToModeratorMutationVariables = Types.Exact<{
  input: Types.PromoteUserToModeratorInput;
}>;


export type PromoteUserToModeratorMutation = { __typename?: 'Mutation', promoteUserToModerator: { __typename?: 'PromoteUserToModeratorPayload', ok: boolean } };

export type DemoteUserFromModeratorMutationVariables = Types.Exact<{
  input: Types.DemoteUserFromModeratorInput;
}>;


export type DemoteUserFromModeratorMutation = { __typename?: 'Mutation', demoteUserFromModerator: { __typename?: 'DemoteUserFromModeratorPayload', ok: boolean } };


export const GetModeratorsDocument = gql`
    query GetModerators {
  viewer {
    id
    allModerators {
      id
      legacyId
      username
      avatarWebPUrl(options: {width: 32})
    }
  }
}
    `;

/**
 * __useGetModeratorsQuery__
 *
 * To run a query within a React component, call `useGetModeratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModeratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModeratorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModeratorsQuery(baseOptions?: Apollo.QueryHookOptions<GetModeratorsQuery, GetModeratorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModeratorsQuery, GetModeratorsQueryVariables>(GetModeratorsDocument, options);
      }
export function useGetModeratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModeratorsQuery, GetModeratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModeratorsQuery, GetModeratorsQueryVariables>(GetModeratorsDocument, options);
        }
export function useGetModeratorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetModeratorsQuery, GetModeratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModeratorsQuery, GetModeratorsQueryVariables>(GetModeratorsDocument, options);
        }
export type GetModeratorsQueryHookResult = ReturnType<typeof useGetModeratorsQuery>;
export type GetModeratorsLazyQueryHookResult = ReturnType<typeof useGetModeratorsLazyQuery>;
export type GetModeratorsSuspenseQueryHookResult = ReturnType<typeof useGetModeratorsSuspenseQuery>;
export type GetModeratorsQueryResult = Apollo.QueryResult<GetModeratorsQuery, GetModeratorsQueryVariables>;
export const PromoteUserToModeratorDocument = gql`
    mutation PromoteUserToModerator($input: PromoteUserToModeratorInput!) {
  promoteUserToModerator(input: $input) {
    ok
  }
}
    `;
export type PromoteUserToModeratorMutationFn = Apollo.MutationFunction<PromoteUserToModeratorMutation, PromoteUserToModeratorMutationVariables>;

/**
 * __usePromoteUserToModeratorMutation__
 *
 * To run a mutation, you first call `usePromoteUserToModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteUserToModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteUserToModeratorMutation, { data, loading, error }] = usePromoteUserToModeratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePromoteUserToModeratorMutation(baseOptions?: Apollo.MutationHookOptions<PromoteUserToModeratorMutation, PromoteUserToModeratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PromoteUserToModeratorMutation, PromoteUserToModeratorMutationVariables>(PromoteUserToModeratorDocument, options);
      }
export type PromoteUserToModeratorMutationHookResult = ReturnType<typeof usePromoteUserToModeratorMutation>;
export type PromoteUserToModeratorMutationResult = Apollo.MutationResult<PromoteUserToModeratorMutation>;
export type PromoteUserToModeratorMutationOptions = Apollo.BaseMutationOptions<PromoteUserToModeratorMutation, PromoteUserToModeratorMutationVariables>;
export const DemoteUserFromModeratorDocument = gql`
    mutation DemoteUserFromModerator($input: DemoteUserFromModeratorInput!) {
  demoteUserFromModerator(input: $input) {
    ok
  }
}
    `;
export type DemoteUserFromModeratorMutationFn = Apollo.MutationFunction<DemoteUserFromModeratorMutation, DemoteUserFromModeratorMutationVariables>;

/**
 * __useDemoteUserFromModeratorMutation__
 *
 * To run a mutation, you first call `useDemoteUserFromModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoteUserFromModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoteUserFromModeratorMutation, { data, loading, error }] = useDemoteUserFromModeratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDemoteUserFromModeratorMutation(baseOptions?: Apollo.MutationHookOptions<DemoteUserFromModeratorMutation, DemoteUserFromModeratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DemoteUserFromModeratorMutation, DemoteUserFromModeratorMutationVariables>(DemoteUserFromModeratorDocument, options);
      }
export type DemoteUserFromModeratorMutationHookResult = ReturnType<typeof useDemoteUserFromModeratorMutation>;
export type DemoteUserFromModeratorMutationResult = Apollo.MutationResult<DemoteUserFromModeratorMutation>;
export type DemoteUserFromModeratorMutationOptions = Apollo.BaseMutationOptions<DemoteUserFromModeratorMutation, DemoteUserFromModeratorMutationVariables>;