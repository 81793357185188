import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaHouse,
  FaVideo,
  FaCartShopping,
  FaGraduationCap,
  FaMessage,
  FaWallet,
  FaBoxOpen,
  FaShop,
  FaUserLarge,
  FaDoorOpen,
} from 'react-icons/fa6'

import NavItem from '@/components/Layout/Header/MainNav/NavItem/NavItem'
import { DEFAULT_COUNTRY_ID } from '@/config/config'
import { voggtUniversityLinkByCountry } from '@/config/voggtUniversityLinks'
import { useUser } from '@/contexts/user/User.context'

import type { Country } from '@/data/countriesClusters'

import './MainNav.scss'

const MainNav = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const {
    country,
    canUseLedger = false,
    canAccessInventory = false,
    canManageAccountSettings = false,
  } = sellerConfig || {}
  const sellerCountry = country?.iso2Code || DEFAULT_COUNTRY_ID
  const voggtUniversityLink =
    voggtUniversityLinkByCountry[sellerCountry as Country] || voggtUniversityLinkByCountry[DEFAULT_COUNTRY_ID]

  const handleMainNavClick = useCallback(() => {
    // Hide the side menu when a link is clicked (only necessary for mobile)
    document.getElementById('header')?.classList.remove('is-open')
  }, [])

  return (
    <nav className="nav main-nav" id="main-nav" onClick={handleMainNavClick}>
      <ul className="nav-list">
        <NavItem icon={<FaHouse />} label={t('sideMenuHomeLabel')} name="home" to="/" />
        {canAccessInventory && <NavItem icon={<FaBoxOpen />} label={t('sideMenuInventoryTitle')} to="/inventory" />}
        <NavItem icon={<FaVideo />} label={t('sideMenuMyShowsTitle')} name="shows" to="/shows?status=coming" />
        <NavItem icon={<FaShop />} label={t('sideMenuMyShopsTitle')} to="/shops" />
        <NavItem icon={<FaCartShopping />} label={t('sideMenuOrdersTitle')} to="/shipments" />
        {canUseLedger && <NavItem icon={<FaWallet />} label={t('sideMenuLedgerTitle')} to="/ledger" />}
        <NavItem
          icon={<FaUserLarge />}
          label={t('sideMenuAccountTitle')}
          to={`/account?tab=${canManageAccountSettings ? 'shippingPreferences' : 'moderation'}`}
        />
        <NavItem
          icon={<FaGraduationCap />}
          label={t('sideMenuUniversityLinkMessage')}
          name="university"
          rel="noopener noreferrer"
          target="_blank"
          to={voggtUniversityLink}
        />
        <NavItem
          icon={<FaMessage />}
          label={t('sideMenuContactUsTitle')}
          name="contact"
          // rel="noopener noreferrer"
          // target="_blank"
          // to={t('supportLinkNewRequest')}
          to="/contact"
        />
        <NavItem icon={<FaDoorOpen />} label={t('sideMenuLogoutTitle')} to="/logout" />
      </ul>
    </nav>
  )
}

export default memo(MainNav)
