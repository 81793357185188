export enum FashionProductGender {
  UNISEX = 'unisex',
  WOMEN = 'women',
  MEN = 'men',
}

export const fashionProductGendersTranslationKeys = ['genderUnisex', 'genderWomen', 'genderMen']
export const defaultGender = 'genderUnisex'

export const acceptableGenderMenValues = ['male', 'men', 'man', 'homme', 'männer', 'manner', 'man', 'h', 'm']
export const acceptableGenderWomenValues = ['female', 'women', 'woman', 'femme', 'frauen', 'frau', 'w', 'f']
export const acceptableGenderUnisexValues = ['unisex', 'u', 'unisexe', 'u']
export const allAcceptableGenderValues = acceptableGenderMenValues.concat(
  acceptableGenderWomenValues,
  acceptableGenderUnisexValues
)
