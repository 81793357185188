import Header from './Header'

import style from './style.module.scss'

interface CustomCardProps {
  children?: React.ReactNode
  title?: string
  extra?: any
  isSmall?: boolean
  isStretch?: boolean
  isHeaderCentered?: boolean
  containerStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

const CustomCard = ({
  children,
  title,
  extra,
  isSmall,
  isStretch,
  isHeaderCentered,
  containerStyle,
  contentStyle,
}: CustomCardProps) => {
  return (
    <div
      style={containerStyle}
      className={`${style.custom_card__container} ${
        isSmall && style.custom_card__container_small
      } ${isStretch && style.custom_card__container_stretch}`}
    >
      {(title || extra) && <Header extra={extra} isHeaderCentered={isHeaderCentered} isSmall={isSmall} title={title} />}
      <div className={style.custom_card__content} style={contentStyle}>
        {children}
      </div>
    </div>
  )
}

export default CustomCard
