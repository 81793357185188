import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type PreOrderFieldProps = {
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PreOrderField = (props: PreOrderFieldProps) => {
  const { value: isPreOrder, onChange: handlePreOrderChange } = props
  const { t } = useTranslation()

  return (
    <Field
      checked={isPreOrder}
      id="pre-order"
      label={t('productFormPreOrderLabel')}
      name="pre-order"
      type="checkbox"
      onChange={handlePreOrderChange}
    />
  )
}

export default PreOrderField
