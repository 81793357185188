import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { trackEvent } from '@/util/eventTracker'

import { SignupForm } from './components/SignupForm/SignupForm'

const { VITE_APP_STRIPE_PUBLISHABLE_KEY } = import.meta.env

import './Signup.scss'

const Signup = () => {
  const { t } = useTranslation()
  useDocumentTitle(t('commonSignup'))

  useEffect(() => {
    trackEvent('SIGNUP_OPEN')
  }, [])

  const stripePromise = useMemo(() => loadStripe(VITE_APP_STRIPE_PUBLISHABLE_KEY!), [VITE_APP_STRIPE_PUBLISHABLE_KEY])

  return (
    <ViewContainer id="signup">
      <Elements stripe={stripePromise}>
        <SignupForm />
      </Elements>
    </ViewContainer>
  )
}

export default Signup
