import ShowEventApplicationStatusTag from './ShowEventApplicationStatusTag'
import { ShowEventDate } from './ShowEventDate'

import type { ShowEventApplicationStatus } from '../types'
import type { ReactNode } from 'react'

type ShowEventListItemProps = {
  icon: ReactNode
  status: ShowEventApplicationStatus | null
  title: string
  subTitle?: string
  startAt?: Date
  endAt?: Date
  isCommercialEvent?: boolean
  onClick: () => void
}

const ShowEventListItem = ({
  icon,
  onClick,
  status,
  title,
  endAt,
  isCommercialEvent,
  startAt,
  subTitle,
}: ShowEventListItemProps) => {
  const isDisabled = status !== null
  const handleClick = isDisabled ? undefined : onClick

  return (
    <div className={`list ${isDisabled ? 'disabled' : ''}`} onClick={handleClick}>
      <div className="list-icon">{icon}</div>
      <div className="list-content">
        <h2 className="list-title">{title}</h2>
        {isCommercialEvent && (
          <>
            <p className="list-subtitle">{subTitle}</p>
            <ShowEventDate endAt={endAt} startAt={startAt} />
          </>
        )}
      </div>

      <div className="list-arrow">
        <ShowEventApplicationStatusTag status={status} />
      </div>
    </div>
  )
}

export default ShowEventListItem
