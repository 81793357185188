import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import type { GetShipmentInfoForPackingSlipFieldsFragment } from '@/views/Shipments/operations.generated'
import type { PackingInfo } from '@/views/Shipments/types'
import type { ApolloError } from '@apollo/client/errors'

export enum ShippingMethod {
  Sendcloud = 'sendcloud',
  Boxtal = 'boxtal',
  Custom = 'custom',
}

interface GraphQLErrorExtensions {
  meta: {
    responseError: {
      message: string
    }
    responseData: {
      message: string
    }
    cause: string
    // ... other properties if present
  }
}

export interface ShipmentDimension {
  weight: number // in kg
  height?: number // in cm
  depth?: number // in cm
  width?: number // in cm
}

export const parseShippingMethodError = (error: ApolloError): string[] => {
  const graphQLErrors = error.graphQLErrors || []

  return graphQLErrors.reduce<string[]>((causes, graphQLError) => {
    // The only way to get the meta.* typing "right" is to cast the error to GraphQLError
    const extensions = graphQLError.extensions as unknown as GraphQLErrorExtensions

    if (extensions.meta) {
      const cause = extensions.meta.responseError?.message || extensions.meta.responseData?.message

      if (Array.isArray(cause)) {
        causes.push(...cause.filter((item) => typeof item === 'string'))
      } else if (typeof cause === 'string') {
        causes.push(cause)
      }
    }
    return causes.filter(function (cause) {
      return cause !== ''
    })
  }, [])
}

const SHIPMENT_DIMENSION_LOCALSTORAGE_KEY = 'latestShipmentDimension'

export const storeLatestShipmentDimensionInLocalStorage = (shipmentDimension: ShipmentDimension) => {
  setToLocalStorage(SHIPMENT_DIMENSION_LOCALSTORAGE_KEY, JSON.stringify(shipmentDimension))
}

export const getLatestShipmentDimensionFromLocalStorage = (): ShipmentDimension | null => {
  const shipmentDimension = getFromLocalStorage(SHIPMENT_DIMENSION_LOCALSTORAGE_KEY)

  if (shipmentDimension) {
    return JSON.parse(shipmentDimension)
  }

  return null
}

export const isMondialRelayShippingProvider = (shippingProvider: string): boolean => {
  return /mondial\s?relay/.test(shippingProvider.toLowerCase())
}

export const computeShorterShipmentId = (shipmentId: string): string => {
  const parts = shipmentId.split('-')
  return parts[parts.length - 1]
}

export const buildPackingSlipInfoFromShipment = (
  shipment: GetShipmentInfoForPackingSlipFieldsFragment
): PackingInfo => {
  const { customer, shippingAddress, shippingProvider, trackingNumber } = shipment
  return {
    customer,
    orderedProducts: shipment?.orderedProducts?.edges?.map((edge) => edge.node) ?? [],
    shippingAddress,
    shippingProvider,
    trackingNumber,
  }
}
