import { useMemo } from 'react'

import { uniqBy } from '@/util/lodash-replacer'

import { useGetAllShopsQuery } from '../operations.generated'

import type { ShopItemFragment } from '../operations.generated'

export const useShops = () => {
  const { data, loading } = useGetAllShopsQuery({ fetchPolicy: 'cache-first' })

  const shops: Array<ShopItemFragment> = useMemo(() => {
    let shops: Array<ShopItemFragment> = []

    if (data?.viewer?.shopsByStartAtDescForSeller?.edges) {
      shops = data?.viewer?.shopsByStartAtDescForSeller?.edges.map((edge) => edge.node)
    }

    return uniqBy(shops, (shop) => shop.legacyId).sort(
      (a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
    )
  }, [data])

  return { shops, loading }
}
