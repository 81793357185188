import { memo } from 'react'

import { useChatBoxContext } from '../../ChatBoxProvider'

import BanAndUnbanItem from './BanAndUnbanItem'
import { CommentItem } from './CommentItem'
import { IncomingRaidItem } from './IncomingRaidItem'
import { JoinAndFollowItem } from './JoinAndFollowItem'

import type { EmoteMap, ChatItem } from '../../hooks/useChat'

interface Props {
  emoteMap: EmoteMap | undefined
  chatItem: ChatItem
}

export const ChatMessageComponent = memo(({ emoteMap, chatItem }: Props) => {
  const { displayJoinedItems } = useChatBoxContext()

  switch (chatItem.__typename) {
    case 'ShowRaidIncomingFeedItem':
      return <IncomingRaidItem key={`raid-${chatItem.id}`} item={chatItem} />
    case 'UserCommentFeedItem':
      return <CommentItem key={`comment-${chatItem.id}`} emoteMap={emoteMap} item={chatItem} />
    case 'UserJoinedFeedItem':
      return displayJoinedItems ? <JoinAndFollowItem key={`comment-${chatItem.id}`} item={chatItem} /> : null
    case 'UserFollowFeedItem':
      return <JoinAndFollowItem key={`comment-${chatItem.id}`} item={chatItem} />

    case 'UserBlockedFromCommentingInShowFeedItem':
    case 'UserUnblockedFromCommentingInShowFeedItem':
      return (
        <BanAndUnbanItem
          key={`ban-${chatItem.id}`}
          timestamp={chatItem.date}
          typename={chatItem.__typename}
          username={chatItem.user.username}
        />
      )
    default:
      return null
  }
})
