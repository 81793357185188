import * as Types from '../../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShipmentBulkShippingLabelsForBoxtalQueryVariables = Types.Exact<{
  boxtalShipmentIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type ShipmentBulkShippingLabelsForBoxtalQuery = { __typename?: 'Query', bulkShippingLabelsForBoxtal: { __typename?: 'ShippingLabelForBoxtal', label: string, missingShipments: { __typename?: 'ShipmentConnection', totalCount: number, edges: Array<{ __typename?: 'ShipmentEdge', node: { __typename?: 'Shipment', id: string, customer: { __typename?: 'User', id: string, username: string } } }> } } };


export const ShipmentBulkShippingLabelsForBoxtalDocument = gql`
    query ShipmentBulkShippingLabelsForBoxtal($boxtalShipmentIds: [ID!]!) {
  bulkShippingLabelsForBoxtal(boxtalShipmentIds: $boxtalShipmentIds) {
    label
    missingShipments {
      totalCount
      edges {
        node {
          id
          customer {
            id
            username
          }
        }
      }
    }
  }
}
    `;

/**
 * __useShipmentBulkShippingLabelsForBoxtalQuery__
 *
 * To run a query within a React component, call `useShipmentBulkShippingLabelsForBoxtalQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentBulkShippingLabelsForBoxtalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentBulkShippingLabelsForBoxtalQuery({
 *   variables: {
 *      boxtalShipmentIds: // value for 'boxtalShipmentIds'
 *   },
 * });
 */
export function useShipmentBulkShippingLabelsForBoxtalQuery(baseOptions: Apollo.QueryHookOptions<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables> & ({ variables: ShipmentBulkShippingLabelsForBoxtalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>(ShipmentBulkShippingLabelsForBoxtalDocument, options);
      }
export function useShipmentBulkShippingLabelsForBoxtalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>(ShipmentBulkShippingLabelsForBoxtalDocument, options);
        }
export function useShipmentBulkShippingLabelsForBoxtalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>(ShipmentBulkShippingLabelsForBoxtalDocument, options);
        }
export type ShipmentBulkShippingLabelsForBoxtalQueryHookResult = ReturnType<typeof useShipmentBulkShippingLabelsForBoxtalQuery>;
export type ShipmentBulkShippingLabelsForBoxtalLazyQueryHookResult = ReturnType<typeof useShipmentBulkShippingLabelsForBoxtalLazyQuery>;
export type ShipmentBulkShippingLabelsForBoxtalSuspenseQueryHookResult = ReturnType<typeof useShipmentBulkShippingLabelsForBoxtalSuspenseQuery>;
export type ShipmentBulkShippingLabelsForBoxtalQueryResult = Apollo.QueryResult<ShipmentBulkShippingLabelsForBoxtalQuery, ShipmentBulkShippingLabelsForBoxtalQueryVariables>;