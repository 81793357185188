import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/ui/Dialog/Dialog'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { trackError } from '@/util/sentry'
import { useReportIssueInShowMutation } from '@/views/Show/components/IssueReporter/operations.generated'
import { ShowRatingForm } from '@/views/Show/components/RateShow/ShowRatingForm/ShowRatingForm'

import { useRateShowAsSellerMutation } from './operations.generated'

export interface RatingContainerProps {
  showId: string
  showStartAt: Date | undefined
  showCategory: string | undefined
}

export const RateShow = (props: RatingContainerProps) => {
  const { t } = useTranslation()

  const { showId, showStartAt, showCategory } = props

  const showGlobalId = `Show|${showId}`
  const localStorageRatingKeyForShow = `${showGlobalId}_rating`
  const locallyStoredRating = getFromLocalStorage(localStorageRatingKeyForShow)

  const [isOpen, setIsOpen] = useState(true)

  const [reportIssueInShow] = useReportIssueInShowMutation()
  const [rateShowSeller] = useRateShowAsSellerMutation()

  const permanentlyDismissRatingForShow = useCallback(() => {
    setToLocalStorage(localStorageRatingKeyForShow, 'canceled')
  }, [localStorageRatingKeyForShow])

  const markShowAsRated = useCallback(() => {
    setToLocalStorage(localStorageRatingKeyForShow, 'rated')
    setIsOpen(false)
  }, [localStorageRatingKeyForShow])

  const handleCancel = useCallback(() => {
    permanentlyDismissRatingForShow()
    setIsOpen(false)
  }, [])

  const showRatingData = () => {
    let showRatingData = ''
    if (showStartAt) {
      showRatingData = `${new Date(showStartAt).toDateString()}`
    }
    if (showCategory) {
      if (showStartAt) showRatingData += ' - '
      showRatingData += `${showCategory}`
    }
    return showRatingData
  }

  const handleSubmit = async (rating: number, review: string, reason: string) => {
    const comment = `${review} - REASON : ${reason}`
    const input = { showId: showGlobalId, rating, comment }

    const { errors } =
      (await rateShowSeller({
        variables: { input },
        onCompleted: () => {
          notificationSuccess(t('showRatingSubmitSuccess'))
        },
        onError: (err) => {
          const hasAlreadyBeenRated =
            err?.graphQLErrors?.[0]?.extensions?.translationKey === 'show-sellers-rating.error.already-rated'

          if (hasAlreadyBeenRated) {
            markShowAsRated()
            trackError(err, { meta: { ...input, scope: 'RatingContainer.handleSubmit' } })
            return
          }

          notificationDanger(t('showRatingSubmitFailure'))
          trackError(err, { meta: { ...input, scope: 'RatingContainer.handleSubmit' } })
        },
      })) || {}

    if (errors) {
      return
    }

    if (rating < 5) {
      await reportIssueInShow({
        variables: {
          input: {
            message: `Rating ${rating} ⭐: ${comment}`,
            showId: showGlobalId,
            // TODO think about renaming this field
            showStartAt: showRatingData(),
            topic: 'Post-Show Rating - Feedback from our seller',
            newStatus: true,
          },
        },
        onError: (err) => {
          trackError(err, { meta: { ...input, scope: 'RatingContainer.handleSubmit' } })
        },
      })
    }

    markShowAsRated()
  }

  const shouldAskForRating = !locallyStoredRating

  if (!shouldAskForRating) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} title={t('ShowRatingModalTitle')} onClose={handleCancel}>
      <ShowRatingForm onCancel={handleCancel} onSubmit={handleSubmit} />
    </Dialog>
  )
}
