import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerStreamingConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerStreamingConfigQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', sellerConfig?: { __typename?: 'SellerConfig', streamingConfigs: Array<{ __typename?: 'SellerStreamingConfig', name: string, url: string, streamKey: string }> } | null } | null };


export const GetSellerStreamingConfigDocument = gql`
    query GetSellerStreamingConfig {
  viewer {
    sellerConfig {
      streamingConfigs {
        name
        url
        streamKey
      }
    }
  }
}
    `;

/**
 * __useGetSellerStreamingConfigQuery__
 *
 * To run a query within a React component, call `useGetSellerStreamingConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerStreamingConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerStreamingConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerStreamingConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>(GetSellerStreamingConfigDocument, options);
      }
export function useGetSellerStreamingConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>(GetSellerStreamingConfigDocument, options);
        }
export function useGetSellerStreamingConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>(GetSellerStreamingConfigDocument, options);
        }
export type GetSellerStreamingConfigQueryHookResult = ReturnType<typeof useGetSellerStreamingConfigQuery>;
export type GetSellerStreamingConfigLazyQueryHookResult = ReturnType<typeof useGetSellerStreamingConfigLazyQuery>;
export type GetSellerStreamingConfigSuspenseQueryHookResult = ReturnType<typeof useGetSellerStreamingConfigSuspenseQuery>;
export type GetSellerStreamingConfigQueryResult = Apollo.QueryResult<GetSellerStreamingConfigQuery, GetSellerStreamingConfigQueryVariables>;