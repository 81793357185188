import { message } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import RatingStars from '@/components/RatingStars'
import Card from '@/components/ui/Card/Card'
import Table, { DEFAULT_ITEM_PER_PAGE } from '@/components/ui/Table/Table'
import User from '@/components/ui/User/User'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'

import { useGetSellerAllRatingsFromCustomersQuery } from './operations.generated'

import type { GetSellerAllRatingsFromCustomersQuery } from './operations.generated'
import type { RatingFromCustomer } from '@/network/graphql/types.generated'

export const extractRatings = (
  ratingsFromCustomersResults: GetSellerAllRatingsFromCustomersQuery | undefined
): RatingFromCustomer[] => {
  if (!ratingsFromCustomersResults?.viewer?.ratingsFromCustomers?.edges) return []
  return ratingsFromCustomersResults?.viewer?.ratingsFromCustomers?.edges.map(
    (edge) => edge.node
  ) as RatingFromCustomer[]
}

export const Ratings = () => {
  const { t } = useTranslation()
  useDocumentTitle(t('allRatingsTitle'))
  useEffect(() => {
    trackEvent('RATINGS_OPEN')
  }, [])

  const perPageStored = getFromLocalStorage('default_per_page_ratings')
  const defaultPerPage = perPageStored ? parseInt(perPageStored) : DEFAULT_ITEM_PER_PAGE
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultPerPage)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const {
    data,
    loading: loading,
    fetchMore,
  } = useGetSellerAllRatingsFromCustomersQuery({
    variables: { first: itemsPerPage },
    onError: (error) => {
      message.error(error.message, 15)
      trackError(error, {
        meta: {
          first: itemsPerPage,
          scope: 'Ratings.useGetSellerAllRatingsFromCustomersQuery',
        },
      })
    },
  })
  const ratings = extractRatings(data)

  const handlePageChange = async (page: number) => {
    setCurrentPage(page)
    await fetchMore({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    setToLocalStorage('default_per_page_ratings', itemsPerPage.toString())
    setItemsPerPage(itemsPerPage)
    setCurrentPage(1)
  }

  const desktopColumns = [
    {
      header: t('ratingsCustomerColumn'),
      id: 'customer',
      accessorKey: 'customer',
      enableColumnFilter: false,
      cell: (item: any) => {
        const customer = item.getValue()
        return <User src={customer?.avatarWebPUrl}>{customer?.username}</User>
      },
    },
    {
      header: t('ratingsCustomerRating'),
      id: 'rating',
      accessorKey: 'rating',
      enableColumnFilter: false,
      cell: (item: any) => {
        const rating = item.getValue()
        return <RatingStars rating={rating} />
      },
    },
    {
      header: t('ratingsCustomerComment'),
      id: 'ratingComment',
      accessorKey: 'ratingComment',
      enableColumnFilter: false,
      cell: (item: any) => {
        const comment = item.getValue()
        return <p>{comment}</p>
      },
    },
    {
      header: '',
      id: 'createdAt',
      accessorKey: 'createdAt',
      enableColumnFilter: false,
      cell: (item: any) => {
        const createdAt = item.getValue()

        return (
          <p>
            {dayjs(createdAt)
              .locale(i18next.resolvedLanguage || '')
              .fromNow()}
          </p>
        )
      },
    },
  ]
  const mobileColumns = [
    {
      header: t('ratingsCustomerColumn'),
      id: 'customer',
      accessorKey: 'customer',
      enableColumnFilter: false,
      cell: (item: any) => {
        const rating = item.row.original
        return (
          <>
            <User src={rating?.customer?.avatarWebPUrl}> {rating?.customer?.username}</User>
            <p className="ml-9">
              {dayjs(rating?.createdAt)
                .locale(i18next.resolvedLanguage || '')
                .fromNow()}
            </p>
          </>
        )
      },
    },
    {
      header: t('ratingsCustomerComment'),
      id: 'rating',
      accessorKey: 'rating',
      enableColumnFilter: false,
      cell: (item: any) => {
        const rating = item.row.original
        return (
          <>
            <RatingStars rating={rating?.rating} />
            <p>{rating?.ratingComment}</p>
          </>
        )
      },
    },
  ]

  const columns = isMobileOnly ? mobileColumns : desktopColumns

  return (
    <ViewContainer id="ratings" leftContent={t('allRatingsTitle')}>
      <Card className={isMobileOnly ? 'table-rating-mobile' : 'table-rating'} noPadding={true}>
        <Table
          columns={columns}
          customPerPageOptions={[25, 50]}
          data={ratings}
          loading={loading}
          pagination={{
            total: data?.viewer?.ratingsFromCustomers?.totalCount,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: handlePageChange,
            onItemPerPageChange: handleItemPerPageChange,
          }}
        />
      </Card>
    </ViewContainer>
  )
}
