import axios from 'axios'
import axiosRetry from 'axios-retry'

import Config from '@/config/config'
import { store } from '@/reducers'
import { trackError } from '@/util/sentry'

import type { AxiosError } from 'axios'

const URL_EVENTS_TRACKING = `${Config.SERVER_URL}/monitoring/seller-studio`
const RETRY_DEFAULT_DELAY_IN_MS = 5 * 1000

const axiosClient = axios.create()
axiosRetry(axiosClient, {
  retries: 3,
  retryCondition: (error: AxiosError) => {
    return error?.code === 'ERR_NETWORK'
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retryDelay: (retryCount: number, error: AxiosError) => {
    return RETRY_DEFAULT_DELAY_IN_MS * retryCount
  },
  // onRetry: () => void 0,
})

export const trackEvent = (event: string, props?: Record<string, any>) => {
  const userId = store.getState().authentication.user?.id
  const meta = {
    event,
    userId,
    props,
    scope: 'trackEvent',
    feature: 'event.tracking',
    version: `${Config.PLATFORM}/${Config.PLATFORM_VERSION}`,
    url: URL_EVENTS_TRACKING,
  }

  axiosClient.post(URL_EVENTS_TRACKING, { ...props, event, userId }).catch((err: AxiosError) => {
    // Note: the following is a workaround to avoid tracking errors when the request is aborted
    // Just ignore aborted requests
    if (err?.message.includes('Request aborted')) {
      return
    }

    trackError(err, { meta })
  })
}
