import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'

import type { ChangeEvent } from 'react'

import './CreatePollForm.scss'

const POLL_QUESTION_LENGTH = 80
const POLL_ANSWER_MAX_LENGTH = 80

type CreatePollFormProps = {
  onComplete: (data: PollInputData) => void
  isLoading: boolean
}

type InputChangeEvent = ChangeEvent<HTMLInputElement>

export type PollInputData = {
  question: string
  answers: string[]
}

const CreatePollForm = (props: CreatePollFormProps) => {
  const { t } = useTranslation()
  const { onComplete, isLoading } = props

  const [question, setQuestion] = useState<string>('')
  const [answer1, setAnswer1] = useState<string>('')
  const [answer2, setAnswer2] = useState<string>('')
  const [answer3, setAnswer3] = useState<string>('')
  const [answer4, setAnswer4] = useState<string>('')

  const handleSubmit = useCallback(() => {
    if (disabled) {
      return
    }

    const answers = [answer1, answer2, answer3, answer4].filter((answer) => answer !== '')
    onComplete({ question, answers })
  }, [question, answer1, answer2, answer3, answer4, onComplete])

  const disabled = answer1 === '' || answer2 === '' || question === ''

  return (
    <Form id="create-poll-form" onSubmit={handleSubmit}>
      <div className="poll-question">
        <Field
          label={t('pollQuestion')}
          maxLength={POLL_QUESTION_LENGTH}
          name="question"
          placeholder={t('pollQuestionPlaceholder')}
          type="text"
          value={question}
          hint={
            question
              ? t('pollQuestionHint', { max: POLL_QUESTION_LENGTH, remaining: POLL_QUESTION_LENGTH - question.length })
              : undefined
          }
          required
          onChange={(e: InputChangeEvent) => setQuestion(e.target.value)}
        />
      </div>

      <div className="poll-answers">
        <Field
          label={t('pollAnswer1')}
          maxLength={POLL_ANSWER_MAX_LENGTH}
          name="answer1"
          placeholder={t('pollAnswerPlaceholder')}
          type="text"
          value={answer1}
          hint={
            answer1
              ? t('pollAnswerHint', {
                  max: POLL_ANSWER_MAX_LENGTH,
                  remaining: POLL_ANSWER_MAX_LENGTH - answer1.length,
                })
              : undefined
          }
          required
          onChange={(e: InputChangeEvent) => setAnswer1(e.target.value)}
        />

        <Field
          label={t('pollAnswer2')}
          maxLength={POLL_ANSWER_MAX_LENGTH}
          name="answer2"
          placeholder={t('pollAnswerPlaceholder')}
          type="text"
          value={answer2}
          hint={
            answer2
              ? t('pollAnswerHint', {
                  max: POLL_ANSWER_MAX_LENGTH,
                  remaining: POLL_ANSWER_MAX_LENGTH - answer2.length,
                })
              : undefined
          }
          required
          onChange={(e: InputChangeEvent) => setAnswer2(e.target.value)}
        />

        <Field
          label={t('pollAnswer3')}
          maxLength={POLL_ANSWER_MAX_LENGTH}
          name="answer3"
          placeholder={t('pollAnswerPlaceholder')}
          type="text"
          value={answer3}
          hint={
            answer3
              ? t('pollAnswerHint', {
                  max: POLL_ANSWER_MAX_LENGTH,
                  remaining: POLL_ANSWER_MAX_LENGTH - answer3.length,
                })
              : undefined
          }
          onChange={(e: InputChangeEvent) => setAnswer3(e.target.value)}
        />

        <Field
          label={t('pollAnswer4')}
          maxLength={POLL_ANSWER_MAX_LENGTH}
          name="answer4"
          placeholder={t('pollAnswerPlaceholder')}
          type="text"
          value={answer4}
          hint={
            answer4
              ? t('pollAnswerHint', {
                  max: POLL_ANSWER_MAX_LENGTH,
                  remaining: POLL_ANSWER_MAX_LENGTH - answer4.length,
                })
              : answer4
          }
          onChange={(e: InputChangeEvent) => setAnswer4(e.target.value)}
        />
      </div>

      <div className="launch-poll">
        <Button
          className="validate-action primary"
          disabled={disabled}
          isLoading={isLoading}
          label={t('pollModalSubmit')}
          type="submit"
          // TODO: temp workaround as the button does not seem to trigger the form onSubmit event
          onClick={handleSubmit}
        />
      </div>
    </Form>
  )
}

export default CreatePollForm
