import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import DeleteIcon from '@/components/ui/Icons/DeleteIcon/DeleteIcon'
import TeamIcon from '@/components/ui/Icons/TeamIcon/TeamIcon'
import Table from '@/components/ui/Table/Table'

import type { SellerSubAccount } from '@/network/graphql/types.generated'

interface SubAccountsTableProps {
  data: SellerSubAccount[]
  currentPage: number
  total: number | undefined
  isLoading: boolean
  itemsPerPage: number
  onOpenModalCreate: () => void
  onOpenModalDelete: (subAccount: SellerSubAccount) => void
  onPageChange: (page: number) => void
  onItemPerPageChange: (itemsPerPage: number) => void
}

const { VITE_APP_SHORT_LIVED_REFRESH_TOKEN_DURATION } = import.meta.env
const EXPIRATION_DAYS = parseInt(VITE_APP_SHORT_LIVED_REFRESH_TOKEN_DURATION, 10)

export const SubAccountsTable = (props: SubAccountsTableProps) => {
  const {
    data,
    currentPage,
    isLoading,
    total,
    itemsPerPage,
    onOpenModalCreate,
    onOpenModalDelete,
    onPageChange,
    onItemPerPageChange: onItemPerPageChange,
  } = props
  const { t } = useTranslation()

  const columns = [
    {
      header: t('subAccountEmail'),
      id: 'email',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        return <p>{record.email}</p>
      },
    },
    {
      header: t('subAccountStatus'),
      id: 'status',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const expirationDate = dayjs(record.lastLoginAt).add(EXPIRATION_DAYS, 'days')
        const isExpired = dayjs().isAfter(expirationDate)
        return record.lastLoginAt ? (
          isExpired ? (
            <span className="subaccount-status expired">{t('subAccountExpired')}</span>
          ) : (
            <span className="subaccount-status connected">{t('subAccountConnected')}</span>
          )
        ) : (
          <span>-</span>
        )
      },
    },
    {
      header: t('subAccountLastConnection'),
      id: 'lastLogin',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        return <p>{record.lastLoginAt ? dayjs(record.lastLoginAt).format('DD/MM/YYYY HH:mm') : '-'}</p>
      },
    },
    {
      header: t('subAccountAction'),
      id: 'action',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        return <Button icon={<DeleteIcon />} onClick={() => onOpenModalDelete(record)} />
      },
    },
  ]

  return !isLoading && total === 0 ? (
    <div className="subaccount-empty">
      <header className="subacount-header">
        <h2 className="subaccount-title">{t('subAccountTitle')}</h2>
        <Button
          className="primary add-subaccount-action"
          label={t('addASubAccountButtonLabel')}
          onClick={onOpenModalCreate}
        />
      </header>
      <div className="subaccount-empty-content">
        <TeamIcon />
        <h2 className="subaccount-empty-title">{t('subAccountsEmptyTitle')}</h2>
        <p>{t('subAccountsEmptyContent')}</p>
      </div>
    </div>
  ) : (
    <Table
      columns={columns}
      customPerPageOptions={[10, 20]}
      data={data}
      loading={isLoading}
      header={
        <header className="subacount-header">
          <h2 className="subaccount-title">{t('subAccountTitle')}</h2>
          <Button
            className="primary add-subaccount-action"
            label={t('addASubAccountButtonLabel')}
            onClick={onOpenModalCreate}
          />
        </header>
      }
      pagination={{
        total,
        currentPage: currentPage,
        perPage: itemsPerPage,
        onPageChange: onPageChange,
        onItemPerPageChange: onItemPerPageChange,
      }}
    />
  )
}
