import { Input, Radio } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import Button from '../ui/Button/Button'
import Dialog from '../ui/Dialog/Dialog'

import type { Product } from '@/network/graphql/types.generated'
import type { RadioChangeEvent } from 'antd'

import './FlashSalesModal.scss'

interface FlashSalesModal {
  isModalVisible: boolean
  onChangeModalVisibility: (value: boolean) => void
  product: Product
  launchFlashSale: (productId: string, discount: number | null) => void
}

const enum options {
  WITHOUT_DISCOUNT = 'without-discount',
  WITH_DISCOUNT = 'with-discount',
}

export const FlashSalesModal = ({
  isModalVisible,
  onChangeModalVisibility,
  product,
  launchFlashSale,
}: FlashSalesModal) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const { fixedAmount, currency } = product
  const amount = currencyFormatter(fixedAmount, currency)

  const [error, setError] = useState<string | undefined>(undefined)
  const [discount, setDiscount] = useState<number | undefined>(undefined)
  const [value, setValue] = useState<string>(options.WITHOUT_DISCOUNT)
  const launchingPrice =
    value === options.WITH_DISCOUNT && discount ? fixedAmount * ((100 - discount) / 100) : fixedAmount
  const amountWithDiscount = currencyFormatter(launchingPrice, currency)

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === options.WITHOUT_DISCOUNT) {
      setError(undefined)
      setDiscount(undefined)
    }
    setValue(e.target.value)
  }

  const handleSubmit = () => {
    if (value === options.WITH_DISCOUNT && !discount) {
      setError(t('flashSalesWithDiscountError'))
    }
    onChangeModalVisibility(false)
    launchFlashSale(product.id, discount ?? null)
  }

  return (
    <Dialog
      className="flash-sales"
      isOpen={isModalVisible}
      modal={true}
      title={t('flashSalesModalTitle')}
      onClose={() => onChangeModalVisibility(false)}
    >
      <Radio.Group className="flash-sales-options" value={value} onChange={onChange}>
        <Radio value={options.WITHOUT_DISCOUNT}>{t('flashSalesWithoutDiscount')}</Radio>
        <Radio value={options.WITH_DISCOUNT}>
          {t('flashSalesWithDiscount')}
          <span className="input-discount">
            <Input
              max={99}
              min={1}
              placeholder={t('flashSalesWithDiscountPlaceholder')}
              type="number"
              value={discount}
              onClick={() => setValue('with-discount')}
              onChange={(e) => {
                setDiscount(Number(e.target.value) || undefined)
              }}
            />
            %
          </span>

          {error && (
            <div className="error">
              <span>{error}</span>
            </div>
          )}
        </Radio>
      </Radio.Group>

      <div className="flash-sales-launching">
        <p className={!discount && value === options.WITH_DISCOUNT ? 'disabled' : ''}>
          {t('flashSalesLaunchingPriceLabel')}
          {options.WITHOUT_DISCOUNT && !!discount && <span className="old-amount">{amount}</span>}
          <span className={!!discount && value === options.WITH_DISCOUNT ? 'discounted amount' : 'amount'}>
            {amountWithDiscount}
          </span>
        </p>
        <Button className="action primary launch-action" onClick={handleSubmit}>
          {t('flashSalesLaunchButton')}
        </Button>
      </div>
    </Dialog>
  )
}
