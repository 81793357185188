import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Dialog from '@/components/ui/Dialog/Dialog'

import './SendShowNotificationModal.scss'

// TODO: migrate to new Modal component when available

interface NotificationProps {
  title: string
  message: string
  isLoading?: boolean
  open?: boolean
  success?: boolean
  onSubmit?: () => void
  onCancel?: () => void
}

const SendShowNotificationModal = (props: NotificationProps) => {
  const {
    isLoading = false,
    open = false,
    title,
    message,
    success,
    onCancel = () => undefined,
    onSubmit = () => undefined,
  } = props

  const { t } = useTranslation()

  const handleSubmit = useCallback(() => {
    onSubmit()
  }, [onSubmit])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Dialog
      className="send-show-notification"
      isOpen={open}
      modal={true}
      title={t('notificationSlidingPanelContentSendNotificationTitle')}
      onClose={handleCancel}
    >
      {success ? (
        <Alert emphasis="high" type="success">
          {t('notificationSlidingPanelSubmitSuccessConfirmation')}
        </Alert>
      ) : (
        <>
          <p>{t('notificationSlidingPanelContentSendNotificationContentV2')}</p>
          <Card className="notification-preview" title={title}>
            {message}
          </Card>

          <div className="actions">
            <Button
              className="validate-action primary"
              isLoading={isLoading}
              label={t('commonSend')}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </Dialog>
  )
}

export default memo(SendShowNotificationModal)
