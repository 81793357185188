import { useTranslation } from 'react-i18next'

import { notificationDanger, notificationSuccess } from '../../../../../../components/ui/Notification/Notification'
import { useApplyForCommercialEventMutation } from '../../operations.generated'
import ShowEventCriteria from '../ShowEventCriteria'

import type { CommercialEventApplicationCriteria } from '../../../../../../network/graphql/types.generated'

type CommercialEventCriteriaProps = {
  showId: string
  commercialEventId: string
  commercialEventName: string
  commercialEventBanner: string
  commercialEventDescription: string
  commercialEventStartsAt: Date
  commercialEventEndsAt: Date
  handleNextSlide: () => void
  commercialEventApplicationCriteria: CommercialEventApplicationCriteria
}

const CommercialEventCriteria = ({
  commercialEventApplicationCriteria,
  showId,
  commercialEventStartsAt,
  commercialEventEndsAt,
  commercialEventId,
  commercialEventName,
  commercialEventBanner,
  commercialEventDescription,
  handleNextSlide,
}: CommercialEventCriteriaProps) => {
  const { t } = useTranslation()
  const [applyForCommercialEventMutation] = useApplyForCommercialEventMutation()

  const handleSubmit = async () => {
    await applyForCommercialEventMutation({
      variables: {
        input: {
          showId: showId,
          commercialEventId: commercialEventId,
        },
      },
      onError: (error) => notificationDanger(error?.message),
      onCompleted: () => notificationSuccess(t('showEventApplicationSuccessMessage')),
    })
    handleNextSlide()
  }

  const { hasCreatedEnoughItemsWithPhotos, isSellerRatingHighEnough, isShowDateInFutureEnough } =
    commercialEventApplicationCriteria

  const canApplyToShowEvent = isSellerRatingHighEnough && hasCreatedEnoughItemsWithPhotos && isShowDateInFutureEnough

  const criteria = [
    { isCompleted: isSellerRatingHighEnough, title: t('showEventApplicationCriteriaIsSellerRatingHighEnough') },
    { isCompleted: hasCreatedEnoughItemsWithPhotos, title: t('showEventApplicationCriteriaHasCreatedEnoughItems') },
    { isCompleted: isShowDateInFutureEnough, title: t('showEventApplicationCriteriaIsShowDateInFutureEnough') },
  ]

  return (
    <ShowEventCriteria
      buttonText={t('commonActionApply')}
      canApply={canApplyToShowEvent}
      criteria={criteria}
      endAt={commercialEventEndsAt}
      headerBanner={commercialEventBanner}
      headerDescription={commercialEventDescription}
      headerTitle={commercialEventName}
      startAt={commercialEventStartsAt}
      onNext={handleSubmit}
    />
  )
}

export default CommercialEventCriteria
