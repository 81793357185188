import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type ProductDescriptionFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  disabled?: boolean
}

const ProductDescriptionField = (props: ProductDescriptionFieldProps) => {
  const { value: description, onChange: handleDescriptionChange, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      disabled={disabled}
      label={t('productFormDescriptionLabel')}
      name="description"
      placeholder={t('productFormDescriptionPlaceholder')}
      type="textarea"
      value={description}
      onChange={handleDescriptionChange}
    />
  )
}

export default ProductDescriptionField
