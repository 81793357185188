import { useTranslation } from 'react-i18next'

import CalendarClockIcon from '../../../../../components/ui/Icons/CalendarClockIcon/CalendarClockIcon'
import StarIcon from '../../../../../components/ui/Icons/StarIcon/StarIcon'
import { useUser } from '../../../../../contexts/user/User.context'
import { useGetVisibleActiveCommercialEventsQuery } from '../../../operations.generated'

import ShowEventListItem from './ShowEventListItem'

import type { FeaturedShowApplicationStatus } from '../../../../../network/graphql/types.generated'
import type { ShowEventApplicationType, CommercialEventApplications } from '../types'

type ShowEventListProps = {
  onSelectType: (type: ShowEventApplicationType, commercialEventId?: string) => void
  featuredShowApplicationStatus: FeaturedShowApplicationStatus | null
  commercialEventApplications: CommercialEventApplications
  showId: string
}

const ShowEventList = ({
  onSelectType,
  featuredShowApplicationStatus,
  commercialEventApplications,
}: ShowEventListProps) => {
  const { t } = useTranslation()
  const { user } = useUser()

  if (!user) return null

  const { data } = useGetVisibleActiveCommercialEventsQuery({ variables: { first: 10, sellerId: user?.id } })

  const visibleActiveCommercialEvents = data?.visibleActiveCommercialEventsForSellerStudio

  const commercialEvents = visibleActiveCommercialEvents?.edges?.map((edge) => edge.node) ?? []

  const applicationStatusMap = new Map(
    commercialEventApplications?.map((app) => [app?.commercialEvent.id, app?.status]) ?? []
  )

  const commercialEventsWithApplicationStatus = commercialEvents.map((option) => ({
    ...option,
    applicationStatus: applicationStatusMap.get(option.id) ?? null,
  }))

  return (
    <div className="product-selector">
      <ShowEventListItem
        icon={<StarIcon />}
        status={featuredShowApplicationStatus}
        title={t('featuredShowApplicationTitle')}
        onClick={() => onSelectType({ type: 'featured' })}
      />
      {commercialEventsWithApplicationStatus.map((commercialEvent) => {
        return (
          <ShowEventListItem
            key={commercialEvent.id}
            endAt={commercialEvent.endAt}
            icon={<CalendarClockIcon />}
            startAt={commercialEvent.startAt}
            status={commercialEvent.applicationStatus}
            subTitle={commercialEvent.description ?? ''}
            title={commercialEvent.name}
            isCommercialEvent
            onClick={() =>
              onSelectType({
                type: 'commercialEvent',
                id: commercialEvent.id,
                name: commercialEvent.name,
                description: commercialEvent.description ?? '',
                banner: commercialEvent.bannerWebPUrl ?? '',
                startAt: commercialEvent.startAt,
                endAt: commercialEvent.endAt,
              })
            }
          />
        )
      })}
    </div>
  )
}

export default ShowEventList
