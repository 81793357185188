import { Popover } from 'antd'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { useUser } from '@/contexts/user/User.context'

import { useSellerTier } from '../SellerTier/hooks/use-seller-tier'
import Link from '../ui/Link/Link'

import './CreateShowButton.scss'

type CreateShowButtonProps = {
  handleOnClick: (value: boolean) => void
  isShop?: boolean
}

enum CANT_CREATE_SHOW_REASON {
  seller_tier = 'seller_tier',
  seller_config = 'seller_config',
}

const CreateShowButton = ({ handleOnClick, isShop }: CreateShowButtonProps) => {
  const { t } = useTranslation()
  const { sellerTier } = useSellerTier()

  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const togglePopover = useCallback(() => setIsPopoverVisible((val) => !val), [])

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canCreateShow } = sellerConfig || {}

  let reason: string = ''

  if (!canCreateShow) {
    reason = CANT_CREATE_SHOW_REASON.seller_config
  } else if (!sellerTier?.canCreateShow) {
    reason = CANT_CREATE_SHOW_REASON.seller_tier
  }

  return (
    <Popover
      content={<UnauthorizedPopoverMessage reason={reason} />}
      open={!canCreateShow && isPopoverVisible}
      overlayStyle={{ width: '90%', maxWidth: '300px' }}
      placement="leftTop"
      onOpenChange={togglePopover}
    >
      <Button
        className="go-to-action primary create-show-button"
        disabled={!canCreateShow}
        label={isShop ? t('calendarButtonCreateShop') : t('calendarButtonCreateShow')}
        onClick={() => handleOnClick(true)}
      />
    </Popover>
  )
}

const UnauthorizedPopoverMessage = ({ reason }: { reason: string }) => {
  const { t } = useTranslation()

  if (reason === CANT_CREATE_SHOW_REASON.seller_config) {
    return (
      <span>
        {t('createShowButtonUnauthorizedPopoverMessage')}{' '}
        <Link className="!text-white" href={`mailto:${t('commonSupportContactEmailAddressForSellers')}`}>
          {t('commonSupportContactEmailAddressForSellers')}
        </Link>
      </span>
    )
  }
  if (reason === CANT_CREATE_SHOW_REASON.seller_tier) {
    return (
      <span>
        <Trans
          i18nKey="sellerTierCannotCreateShowButtonPopover"
          components={{
            1: <Link className="!text-white" href="/account"></Link>,
          }}
        />
      </span>
    )
  }
  return null
}

export default CreateShowButton
