import { useEffect, useRef } from 'react'
import useCountDown from 'react-countdown-hook'

export interface CountdownProps {
  endDate: Date
  startDate: Date
}

const Countdown = ({ endDate, startDate }: CountdownProps) => {
  const timeToCountReference = useRef({ endTime: 0, startTime: 0 })
  const [timeLeft, { start }] = useCountDown(0, 1000)
  const endTime = endDate.getTime()
  const startTime = new Date(startDate).getTime()

  useEffect(() => {
    const timeToCount = Math.round((endTime - startTime) / 1000) * 1000

    if (timeToCountReference.current.startTime !== startTime && timeToCountReference.current.endTime !== endTime) {
      start(timeToCount)
      timeToCountReference.current = { endTime, startTime }
    }
  }, [endTime, startTime, start])

  const seconds = Math.floor((timeLeft / 1000) % 60)

  return <>{String(seconds).padStart(2, '0')}s</>
}

export default Countdown
