import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createParentCategoriesOptionGroups } from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import useParentCategories from '@/components/CreateOrEditProduct/useParentCategories'
import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'

import type { SelectOption, SelectOptionGroup } from '@/components/Form/Field/Select/Select'

interface CategoryProps {
  onComplete: (category: string, subCategory: string) => void
  category: string
  subCategory: string
  isShop?: boolean
}

export const Category = (props: CategoryProps) => {
  const { t } = useTranslation()
  const { onComplete, category: formCategory, subCategory: formmSubCategory, isShop } = props

  const [category, setCategory] = useState<string>(formCategory)
  const [subCategory, setSubCategory] = useState<string>(formmSubCategory)

  const { parentCategories } = useParentCategories()
  const parentCategoriesOptionGroups = useMemo(
    () => createParentCategoriesOptionGroups(parentCategories, isShop ? true : false),
    [parentCategories]
  )

  const categoryOptions: SelectOption[] = parentCategoriesOptionGroups.map((category) => {
    return { label: category.label as string, value: category.key as string }
  })
  const subCategoryOptions = useMemo(() => {
    const selectedCategory = parentCategoriesOptionGroups.find((cat) => cat.key === category) as SelectOptionGroup
    return selectedCategory ? selectedCategory.options.map((subCategory) => subCategory) : []
  }, [category])

  const handleCategoryChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(event.target.value)
  }, [])
  const handleSubCategoryChange = useCallback(
    (key: string) => {
      setSubCategory(key)
      onComplete(category, key)
    },
    [category, onComplete]
  )

  return (
    <Form>
      <Field
        label={t('createShowCategoryLabel')}
        name="category"
        options={categoryOptions}
        type="select"
        value={category}
        required
        onChange={handleCategoryChange}
      />

      <div className="sub-category-selection">
        {subCategoryOptions.length > 0 && (
          <>
            <p className="sub-category-selection-title">
              {t('createShowSubCategoryLabel')} <span className="required">*</span>
            </p>
            {subCategoryOptions.map((subCategoryOption: any) => {
              if (subCategoryOption.disabled || !subCategoryOption.key) return null
              return (
                <div
                  key={subCategoryOption.value}
                  className={`create-show-sub-category ${subCategory === subCategoryOption.key ? 'selected' : ''}`}
                  onClick={() => handleSubCategoryChange(subCategoryOption.key as string)}
                >
                  <p>{subCategoryOption.label}</p>

                  {subCategoryOption.cover && (
                    <div className="image">
                      <img alt={subCategoryOption.label} src={subCategoryOption.cover} />
                    </div>
                  )}
                </div>
              )
            })}
          </>
        )}
      </div>
    </Form>
  )
}
