import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  credential: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginPayload', token: string, refreshToken: string, tokenExpiresAt: Date } };

export type LoginWithSsoMutationVariables = Types.Exact<{
  idToken: Types.Scalars['String']['input'];
}>;


export type LoginWithSsoMutation = { __typename?: 'Mutation', loginWithSso: { __typename?: 'LoginWithSsoPayload', token: string, refreshToken: string, tokenExpiresAt: Date } };

export type LoginWithFacebookSsoMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String']['input'];
}>;


export type LoginWithFacebookSsoMutation = { __typename?: 'Mutation', loginWithFacebookSso: { __typename?: 'LoginWithFacebookSsoPayload', token: string, refreshToken: string, tokenExpiresAt: Date } };

export type LoginWithImpersonationTokenMutationVariables = Types.Exact<{
  impersonationToken: Types.Scalars['String']['input'];
}>;


export type LoginWithImpersonationTokenMutation = { __typename?: 'Mutation', loginWithImpersonationToken: { __typename?: 'LoginWithImpersonationTokenPayload', token: string, tokenExpiresAt: Date } };

export type LoginWithMagicTokenMutationVariables = Types.Exact<{
  magicToken: Types.Scalars['String']['input'];
}>;


export type LoginWithMagicTokenMutation = { __typename?: 'Mutation', loginWithMagicToken: { __typename?: 'LoginWithMagicTokenPayload', token: string, refreshToken: string, tokenExpiresAt: Date } };


export const LoginDocument = gql`
    mutation Login($credential: String!, $password: String!) {
  login(credential: $credential, password: $password) {
    token
    refreshToken
    tokenExpiresAt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginWithSsoDocument = gql`
    mutation LoginWithSso($idToken: String!) {
  loginWithSso(idToken: $idToken) {
    token
    refreshToken
    tokenExpiresAt
  }
}
    `;
export type LoginWithSsoMutationFn = Apollo.MutationFunction<LoginWithSsoMutation, LoginWithSsoMutationVariables>;

/**
 * __useLoginWithSsoMutation__
 *
 * To run a mutation, you first call `useLoginWithSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithSsoMutation, { data, loading, error }] = useLoginWithSsoMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useLoginWithSsoMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithSsoMutation, LoginWithSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithSsoMutation, LoginWithSsoMutationVariables>(LoginWithSsoDocument, options);
      }
export type LoginWithSsoMutationHookResult = ReturnType<typeof useLoginWithSsoMutation>;
export type LoginWithSsoMutationResult = Apollo.MutationResult<LoginWithSsoMutation>;
export type LoginWithSsoMutationOptions = Apollo.BaseMutationOptions<LoginWithSsoMutation, LoginWithSsoMutationVariables>;
export const LoginWithFacebookSsoDocument = gql`
    mutation LoginWithFacebookSso($accessToken: String!) {
  loginWithFacebookSso(accessToken: $accessToken) {
    token
    refreshToken
    tokenExpiresAt
  }
}
    `;
export type LoginWithFacebookSsoMutationFn = Apollo.MutationFunction<LoginWithFacebookSsoMutation, LoginWithFacebookSsoMutationVariables>;

/**
 * __useLoginWithFacebookSsoMutation__
 *
 * To run a mutation, you first call `useLoginWithFacebookSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithFacebookSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithFacebookSsoMutation, { data, loading, error }] = useLoginWithFacebookSsoMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useLoginWithFacebookSsoMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithFacebookSsoMutation, LoginWithFacebookSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithFacebookSsoMutation, LoginWithFacebookSsoMutationVariables>(LoginWithFacebookSsoDocument, options);
      }
export type LoginWithFacebookSsoMutationHookResult = ReturnType<typeof useLoginWithFacebookSsoMutation>;
export type LoginWithFacebookSsoMutationResult = Apollo.MutationResult<LoginWithFacebookSsoMutation>;
export type LoginWithFacebookSsoMutationOptions = Apollo.BaseMutationOptions<LoginWithFacebookSsoMutation, LoginWithFacebookSsoMutationVariables>;
export const LoginWithImpersonationTokenDocument = gql`
    mutation LoginWithImpersonationToken($impersonationToken: String!) {
  loginWithImpersonationToken(impersonationToken: $impersonationToken) {
    token
    tokenExpiresAt
  }
}
    `;
export type LoginWithImpersonationTokenMutationFn = Apollo.MutationFunction<LoginWithImpersonationTokenMutation, LoginWithImpersonationTokenMutationVariables>;

/**
 * __useLoginWithImpersonationTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithImpersonationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithImpersonationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithImpersonationTokenMutation, { data, loading, error }] = useLoginWithImpersonationTokenMutation({
 *   variables: {
 *      impersonationToken: // value for 'impersonationToken'
 *   },
 * });
 */
export function useLoginWithImpersonationTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithImpersonationTokenMutation, LoginWithImpersonationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithImpersonationTokenMutation, LoginWithImpersonationTokenMutationVariables>(LoginWithImpersonationTokenDocument, options);
      }
export type LoginWithImpersonationTokenMutationHookResult = ReturnType<typeof useLoginWithImpersonationTokenMutation>;
export type LoginWithImpersonationTokenMutationResult = Apollo.MutationResult<LoginWithImpersonationTokenMutation>;
export type LoginWithImpersonationTokenMutationOptions = Apollo.BaseMutationOptions<LoginWithImpersonationTokenMutation, LoginWithImpersonationTokenMutationVariables>;
export const LoginWithMagicTokenDocument = gql`
    mutation LoginWithMagicToken($magicToken: String!) {
  loginWithMagicToken(input: {magicToken: $magicToken}) {
    token
    refreshToken
    tokenExpiresAt
  }
}
    `;
export type LoginWithMagicTokenMutationFn = Apollo.MutationFunction<LoginWithMagicTokenMutation, LoginWithMagicTokenMutationVariables>;

/**
 * __useLoginWithMagicTokenMutation__
 *
 * To run a mutation, you first call `useLoginWithMagicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithMagicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithMagicTokenMutation, { data, loading, error }] = useLoginWithMagicTokenMutation({
 *   variables: {
 *      magicToken: // value for 'magicToken'
 *   },
 * });
 */
export function useLoginWithMagicTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithMagicTokenMutation, LoginWithMagicTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithMagicTokenMutation, LoginWithMagicTokenMutationVariables>(LoginWithMagicTokenDocument, options);
      }
export type LoginWithMagicTokenMutationHookResult = ReturnType<typeof useLoginWithMagicTokenMutation>;
export type LoginWithMagicTokenMutationResult = Apollo.MutationResult<LoginWithMagicTokenMutation>;
export type LoginWithMagicTokenMutationOptions = Apollo.BaseMutationOptions<LoginWithMagicTokenMutation, LoginWithMagicTokenMutationVariables>;