import { useTranslation } from 'react-i18next'

import { getFromArrayLocalStorage, setToArrayLocalStorage } from '@/helpers/localstorage'

import Alert from '../ui/Alert/Alert'

interface ShopsBannerProps {
  shopId: number
  isTerminated?: boolean
}

export const ShopsBanner = (props: ShopsBannerProps) => {
  const { t } = useTranslation()
  const { shopId, isTerminated } = props

  const localStorageKey = 'hasSeenShopBanner'
  const dismissed = !!getFromArrayLocalStorage(localStorageKey, shopId)

  const handleClick = () => {
    setToArrayLocalStorage(localStorageKey, shopId)
  }

  if (isTerminated)
    return (
      <Alert className="shops-alert" dismissed={dismissed} type="danger">
        <span>{t('shopsTerminatedBannerText')}</span>
      </Alert>
    )

  return (
    <Alert className="shops-alert" dismissed={dismissed} type="info" dismissible onDismiss={handleClick}>
      <span>{t('shopsBannerText')}</span>
    </Alert>
  )
}
