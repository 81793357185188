import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import ProductImageList from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductImagesField/ProductImageList/ProductImageList'
import Field from '@/components/Form/Field/Field'
import Button from '@/components/ui/Button/Button'
import {
  PRODUCT_IMAGES_ACCEPTED_EXTENSIONS,
  PRODUCT_IMAGES_MAX_IMAGE_SIZE_IN_BYTES,
  PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT,
  ONE_MB_IN_BYTES,
} from '@/config/constants'
import { convertImageIfNecessary } from '@/helpers/images/convertImageIfNecessary'
import { uniqueId } from '@/util/lodash-replacer'
import { trackError } from '@/util/sentry'

import type { ProductImage } from '@/components/CreateOrEditProduct/types'
import type { TFunction } from 'i18next'
import type { ChangeEvent } from 'react'

import './ProductImagesField.scss'

type ImageErrorCode = 'file-too-large' | 'max-allowed-reached'

const computeFormattedImageError = (error: ImageErrorCode, t: TFunction<'translation', undefined>) => {
  switch (error) {
    case 'file-too-large':
      return t('productFormImageTooBigError', {
        maxImageSizeInMb: PRODUCT_IMAGES_MAX_IMAGE_SIZE_IN_BYTES / ONE_MB_IN_BYTES,
      })
    case 'max-allowed-reached':
      return t('productFormImagesMaxImagesLimitReachedError')
    default:
      return error
  }
}

type ImageError = {
  file: File
  error: ImageErrorCode
}

type ProductImagesFieldProps = {
  value: ProductImage[]
  onUpdateImages: (images: ProductImage[]) => void
  disabled?: boolean
}

const ProductImagesField = (props: ProductImagesFieldProps) => {
  const { value: images, onUpdateImages = () => undefined, disabled = false } = props

  const { t } = useTranslation()

  const [imageErrors, setImageErrors] = useState<ImageError[]>([])

  const handleImageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target
      if (!files) {
        return
      }

      handleAddImages(files as unknown as File[])
    },
    [images, onUpdateImages]
  )

  const handleAddImages = useCallback(
    async (files: File[]) => {
      // Note:
      // The following try/catch wrapper is used to catch a possible error that only seems to occur on Chrome mobile (webview?),
      // on iOS.
      // https://voggt.sentry.io/issues/5389146078/events/4a67eedb36034a6394d7945c8289e67e/events/?cursor=0%3A250%3A0&project=4506070559883264&referrer=replay-errors&sort=-browser
      let newImages: ProductImage[]
      try {
        const imageErrors: ImageError[] = []
        const acceptableFiles = []
        const currentNotDeletedImages = images.filter((image) => !image.isDeleted)

        for (const file of files) {
          // TODO: validate file type?

          if (file.size > PRODUCT_IMAGES_MAX_IMAGE_SIZE_IN_BYTES) {
            imageErrors.push({ file, error: 'file-too-large' })
            continue
          }

          if (currentNotDeletedImages.length + acceptableFiles.length + 1 > PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT) {
            imageErrors.push({ file, error: 'max-allowed-reached' })
            continue
          }

          acceptableFiles.push(file)
        }

        const promises = acceptableFiles.map((file) => convertImageIfNecessary(file))
        const toBeAddedFiles = (await Promise.all(promises)).filter((maybeFile): maybeFile is File => !!maybeFile)

        const toBeAddedImages = toBeAddedFiles.map((file) => ({
          id: `new-${uniqueId()}`,
          url: URL.createObjectURL(file),
          file,
          isDeleted: false,
        }))
        newImages = images.concat(toBeAddedImages)
      } catch (e) {
        trackError(new Error('Failure to add images 1'))
        // Recreate a files array as we suspect an issue while working with the original files array itself
        const filesData = files.map(({ name, size }) => ({
          name,
          size,
        }))
        trackError(new Error('Failure to add images 2'), {
          meta: { originalError: e, filesData, scope: 'ProductImagesField.handleAddImage' },
        })
        throw e
      }

      setImageErrors(imageErrors)
      onUpdateImages(newImages)
    },
    [images, onUpdateImages]
  )

  const currentURL = window.location.href
  const imagesNotDeleted = images.filter((image) => !image.isDeleted)
  const maxRemainingImages = PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT - imagesNotDeleted.length

  const { getRootProps: getDropZoneRootProps } = useDropzone({
    // maxFiles: maxRemainingImages, // Will be handled customly in the handleAddImages function
    // maxSize: MAX_IMAGE_SIZE_IN_BYTES, // Will be handled customly in the handleAddImages function
    onDrop: handleAddImages,
    accept: { 'image/*': PRODUCT_IMAGES_ACCEPTED_EXTENSIONS },
    noClick: true,
  })
  const dropZoneRootProps = getDropZoneRootProps()
  const { ref: dropZoneRef, ...otherDropZoneRootProps } = dropZoneRootProps

  const handleDeleteImage = useCallback(
    (imageId: ProductImage['id']) => {
      const newImages = images.map((image) => {
        if (image.id === imageId) {
          image.isDeleted = true
        }

        return image
      })
      onUpdateImages(newImages)
    },
    [images, onUpdateImages]
  )

  const handleReorderImages = useCallback(
    (newImagesOrder: ProductImage[]) => {
      onUpdateImages(newImagesOrder)
    },
    [images, onUpdateImages]
  )

  return (
    <div ref={dropZoneRef} className="product-images" {...otherDropZoneRootProps}>
      <Field
        accept={PRODUCT_IMAGES_ACCEPTED_EXTENSIONS.join(', ')}
        disabled={disabled}
        hint={t('productFormImagesHint', { maxCount: PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT })}
        label={t('productFormImagesLabel')}
        max={maxRemainingImages}
        multiple={true}
        name="images"
        placeholder={t('productFormImagesPlaceholder')}
        size={PRODUCT_IMAGES_MAX_IMAGE_SIZE_IN_BYTES}
        type="file"
        value={images as unknown as string}
        onChange={handleImageChange}
      >
        <div className="qr-code current-page-qr-code">
          <QRCode value={currentURL} />
        </div>
        <Button
          className="secondary add-image-action"
          disabled={disabled}
          label={t('productFormImagesAddActionLabel')}
        />
      </Field>

      <ProductImageList
        disabled={disabled}
        images={imagesNotDeleted}
        onDeleteImage={handleDeleteImage}
        onReorderImages={handleReorderImages}
      />
      {imageErrors.length > 0 && (
        <div className="message error error-message">
          <p>Invalid images:</p>
          <ul>
            {imageErrors.map(({ file, error }) => {
              const formattedError = computeFormattedImageError(error, t)
              return (
                <li key={file.name}>
                  {file.name} ({formattedError})
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ProductImagesField
