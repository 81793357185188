import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { DEFAULT_ITEM_PER_PAGE } from '@/components/ui/Table/Table'
import Config from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { Currency, InventoryProductSortColumn, SortDirection } from '@/network/graphql/types.generated'
import { trackError } from '@/util/sentry'

import ErrorPage404 from '../../components/Error/ErrorPage404/ErrorPage404'

import { Inventory } from './Inventory'
import {
  useAddInventoryProductsToShowMutation,
  useGetInventoryProductQuery,
  useGetPastShowsQuery,
  useInitializeInventoryMutation,
  useRemoveInventoryProductMutation,
  useRemoveInventoryProductsMutation,
} from './operation.generated'

import type { GetInventoryProductQuery, GetPastShowsQuery } from './operation.generated'
import type { FiltersValue } from '@/components/ui/Table/Table'
import type { InventoryProduct, SortInventoryProductsBy } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

export const enum InventoryProductFormType {
  Add = 'add',
  AddBulk = 'add-bulk',
  Edit = 'edit',
}

export const FILTER_BY_SHOWS = 'filterByShows'

const { VITE_APP_LONG_TIMEOUT } = Config

const extractInventoryProducts = (
  inventoryProductsQueryResults: GetInventoryProductQuery | undefined
): InventoryProduct[] => {
  if (!inventoryProductsQueryResults?.inventoryProducts?.edges) return []
  return inventoryProductsQueryResults?.inventoryProducts?.edges.map((edge) => edge.node) as InventoryProduct[]
}

const extractPastShowsOptions = (pastShowsOptions: GetPastShowsQuery | undefined) => {
  if (!pastShowsOptions?.viewer?.shows?.edges) return []
  return pastShowsOptions?.viewer?.shows.edges.map((item) => {
    return { label: `${dayjs(item.node.startAt).format('YYYY/MM/DD')} : ${item.node.name}`, value: item.node.id }
  })
}

const sortingOptionsMap: { [key: string]: InventoryProductSortColumn } = {
  product: InventoryProductSortColumn.Name,
  defaultType: InventoryProductSortColumn.DefaultType,
  startingAmount: InventoryProductSortColumn.StartingAmount,
  fixedAmount: InventoryProductSortColumn.FixedAmount,
  availableQuantity: InventoryProductSortColumn.AvailableQuantity,
  totalQuantity: InventoryProductSortColumn.TotalQuantity,
  createdAt: InventoryProductSortColumn.CreatedAt,
}

interface InventoryContainerProps {
  isImporter?: boolean
  showId?: number
  onImported?: () => void // TODO: We should pass the imported products as argument
}

export const InventoryContainer = (props: InventoryContainerProps) => {
  const { t } = useTranslation()
  useDocumentTitle(t('sideMenuInventoryTitle'))
  const { isImporter, showId, onImported } = props
  const showGlobalId = `Show|${showId}`

  const { user, fetchUser: refetchSeller, isLoading: loadingSeller } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessInventory, isInventoryInitialized, sellerCurrency: currency } = sellerConfig || {}

  const [initializeInventory] = useInitializeInventoryMutation()
  const [removeInventoryProduct] = useRemoveInventoryProductMutation()
  const [removeInventoryProducts] = useRemoveInventoryProductsMutation()
  const [addInventoryProductToShow] = useAddInventoryProductsToShowMutation()

  const perPageStored = getFromLocalStorage('default_per_page_inventory')
  const defaultPerPage = perPageStored ? parseInt(perPageStored) : DEFAULT_ITEM_PER_PAGE
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultPerPage)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [filterByText, setFilterByText] = useState<string | undefined>(undefined)
  const [filterByShows, setFilterByShows] = useState<string[] | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [loadingImporter, setLoadingImporter] = useState(false)
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'product',
      desc: false,
    },
  ])
  const [sortBy, setSortBy] = useState<SortInventoryProductsBy | undefined>({
    column: InventoryProductSortColumn.Name,
    direction: SortDirection.Asc,
  })

  const filterByPositiveTotalQuantityForPastShows = !!isImporter
  const [filterByPositiveTotalQuantityForAllShows, setFilterByPositiveTotalQuantityForAllShows] =
    useState<boolean>(!isImporter) // handle localstorage
  const showIdToExclude = showId ? showGlobalId : undefined

  const {
    data: inventoryProductsData,
    loading: loadingInventory,
    refetch: refetchInventoryProducts,
    fetchMore: fetchMoreInventoryProducts,
  } = useGetInventoryProductQuery({
    variables: {
      first: itemsPerPage,
      filterByPositiveTotalQuantityForPastShows,
      filterByPositiveTotalQuantityForAllShows,
      filterByText,
      filterByShows,
      showIdToExclude,
      sortBy,
    },
    onError: (error) => {
      notificationDanger(error.message)
      trackError(error, {
        meta: {
          first: itemsPerPage,
          filterByPositiveTotalQuantity: filterByPositiveTotalQuantityForPastShows,
          scope: 'InventoryContainer.useGetInventoryProductQuery',
        },
      })
    },
  })

  const { data: pastShowsOptions } = useGetPastShowsQuery({
    variables: {
      first: 50,
      lastNDaysOnly: 30,
    },
  })

  const inventory = extractInventoryProducts(inventoryProductsData)
  const pastShowsOptionsList = [
    { label: t('tableDefaultFilterOption'), value: '' },
    ...extractPastShowsOptions(pastShowsOptions),
  ]

  useEffect(() => {
    if (typeof isInventoryInitialized !== 'boolean') {
      return
    }
    const hasAlreadyBeenInitialized = isInventoryInitialized || getFromLocalStorage('inventoryInitialized')

    if (!hasAlreadyBeenInitialized) {
      // Currently, the backend doesn't handle if we refresh while this mutation is already called, so we check it in frontend
      setToLocalStorage('inventoryInitialized', 'true')

      initializeInventory({
        context: {
          timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
        },
        onCompleted: () => {
          refetchInventoryProducts({ first: itemsPerPage })
          removeFromLocalStorage('inventoryInitialized')
          refetchSeller()
        },
        onError: (error) => {
          notificationDanger(error.message)
          removeFromLocalStorage('inventoryInitialized')
          trackError(error, { meta: { scope: 'InventoryContainer.initializeInventory' } })
        },
      })
    }
  }, [isInventoryInitialized])

  const handleDelete = (product: InventoryProduct) => {
    //[Remove antd] Replace with custom modal when we handle the confirm
    Modal.confirm({
      title: t('inventoryProductDeleteTitle'),
      centered: true,
      content: <span className="inventory-modal-warning">{t('inventoryProductDeleteContent')}</span>,
      okText: t('inventoryProductDeleteOkText'),
      cancelText: t('inventoryProductDeleteCancelText'),
      onOk: async () => {
        const input = { inventoryProductId: product.id }

        await removeInventoryProduct({
          variables: { input },
          onCompleted: () => {
            refetchInventoryProducts({ first: itemsPerPage })
          },
          onError: (error) => {
            notificationDanger(error.message)
            trackError(error, { meta: { ...input, scope: 'InventoryContainer.handleDelete' } })
          },
        })
      },
    })
  }

  let timeoutHandleSearchId: any
  const handleSearch = async (value: any) => {
    clearTimeout(timeoutHandleSearchId)

    timeoutHandleSearchId = setTimeout(async () => {
      const filterByText = value.target.value || undefined
      setFilterByText(filterByText)
      setCurrentPage(1)
    }, 500)
  }

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    setToLocalStorage('default_per_page_inventory', itemsPerPage.toString())
    setItemsPerPage(itemsPerPage)
    setCurrentPage(1)
  }

  const handlePageChange = async (page: number) => {
    setCurrentPage(page)
    setLoading(true)
    await fetchMoreInventoryProducts({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
    setLoading(false)
  }

  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setLoading(true)
    setCurrentPage(1)
    if (!sort[0]) {
      setSortBy(undefined)
    } else {
      setSortBy({
        column: sortingOptionsMap[sort[0]?.id],
        direction: sort[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
      })
    }
    setLoading(false)
  }

  const handleFilter = (filters: FiltersValue[]) => {
    const filterShows = filters.find(({ name }) => name === FILTER_BY_SHOWS)
    const value = filterShows?.value ? [filterShows.value as string] : undefined
    setFilterByShows(value)
  }

  const handleImportInShow = async () => {
    if (!onImported || !showId) {
      return
    }
    setLoadingImporter(true)

    const input = { showId: `Show|${showId}`, inventoryProductIds: selectedRows }

    await addInventoryProductToShow({
      variables: {
        input,
      },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: () => {
        refetchInventoryProducts({ first: itemsPerPage })
        onImported() // TODO: We should pass the imported products as argument
      },
      onError: (error) => {
        refetchInventoryProducts({ first: itemsPerPage })
        notificationDanger(error.message)
        trackError(error, { meta: { ...input, scope: 'InventoryContainer.handleImportInShow' } })
      },
    })
    setLoadingImporter(false)
  }

  const updateInventory = () => {
    refetchInventoryProducts({
      offset: (currentPage - 1) * itemsPerPage,
      first: itemsPerPage,
      filterByText,
      filterByPositiveTotalQuantityForPastShows,
      filterByPositiveTotalQuantityForAllShows,
    })
  }

  const handleFilterByPositiveTotalQuantity = (value: boolean) => {
    setFilterByPositiveTotalQuantityForAllShows(!value)
  }

  const handleBulkRemove = () => {
    //[Remove antd] Replace with custom modal when we handle the confirm
    Modal.confirm({
      title: t('inventoryProductDeleteTitle'),
      centered: true,
      content: <span className="inventory-modal-warning">{t('inventoryProductDeleteContent')}</span>,
      okText: t('inventoryProductDeleteOkText'),
      cancelText: t('inventoryProductDeleteCancelText'),
      onOk: async () => {
        removeInventoryProducts({
          variables: { input: { inventoryProductIds: selectedRows } },
          context: {
            timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
          },
          onCompleted: () => {
            setSelectedRows([])
            refetchInventoryProducts({ first: itemsPerPage })
          },
          onError: (error) => {
            notificationDanger(error.message)
            trackError(error, { meta: { scope: 'InventoryContainer.handleBulkRemove' } })
          },
        })
      },
    })
  }

  if (loadingSeller) return <Loader />
  if (canAccessInventory === false) return <ErrorPage404 />
  return (
    <Inventory
      currency={currency ?? Currency.Eur}
      currentPage={currentPage}
      filterByPositiveTotalQuantity={filterByPositiveTotalQuantityForAllShows}
      filterByShows={filterByShows}
      handleBulkRemove={handleBulkRemove}
      handleDelete={handleDelete}
      handleFilter={handleFilter}
      handleFilterByPositiveTotalQuantity={handleFilterByPositiveTotalQuantity}
      handleImportInShow={handleImportInShow}
      handleItemPerPageChange={handleItemPerPageChange}
      handlePageChange={handlePageChange}
      handleSearch={handleSearch}
      handleSelectedRowsChange={setSelectedRows}
      handleSortingChange={handleSortingChange}
      inventoryProducts={inventory}
      isImporter={isImporter}
      isInventoryInitialized={isInventoryInitialized ?? false}
      itemsPerPage={itemsPerPage}
      loadingImporter={loadingImporter}
      loadingInventory={loadingInventory || loading}
      pastShowsOptions={pastShowsOptionsList}
      selectedRows={selectedRows}
      sorting={sorting}
      total={inventoryProductsData?.inventoryProducts?.totalCount}
      updateInventory={updateInventory}
    />
  )
}
