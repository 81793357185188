import { Drawer } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import IssueReporterForm from '../IssueReporterForm/IssueReporterForm'

import './IssueReporterPanel.scss'

type IssueReporterPanelProps = {
  onClose: () => void
  showId: string
  currentAuction: any
}

const IssueReporterPanel = (props: IssueReporterPanelProps) => {
  const { showId, currentAuction, onClose = () => undefined } = props

  const { t } = useTranslation()
  const handleClose = useCallback(() => onClose(), [onClose])

  return (
    <Drawer
      className="issue-reporter-panel"
      closeIcon={<FaXmark />}
      open={true}
      placement="right"
      title={t('showIssueReporterPanelTitle')}
      width={400}
      onClose={handleClose}
    >
      <IssueReporterForm currentAuction={currentAuction} showId={showId} onClosePanelClick={handleClose} />
    </Drawer>
  )
}

export default IssueReporterPanel
