import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPasswordResetMutationVariables = Types.Exact<{
  credential: Types.Scalars['String']['input'];
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: { __typename?: 'RequestPasswordResetPayload', ok: boolean } };

export type RequestSellerSubAccountLoginEmailMutationVariables = Types.Exact<{
  sellerUsername: Types.Scalars['String']['input'];
  subAccountEmail: Types.Scalars['String']['input'];
}>;


export type RequestSellerSubAccountLoginEmailMutation = { __typename?: 'Mutation', requestMagicLinkForSellerSubAccount: { __typename?: 'RequestMagicLinkForSellerSubAccountPayload', ok: boolean } };


export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($credential: String!) {
  requestPasswordReset(credential: $credential) {
    ok
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const RequestSellerSubAccountLoginEmailDocument = gql`
    mutation RequestSellerSubAccountLoginEmail($sellerUsername: String!, $subAccountEmail: String!) {
  requestMagicLinkForSellerSubAccount(
    input: {sellerUsername: $sellerUsername, subAccountEmail: $subAccountEmail}
  ) {
    ok
  }
}
    `;
export type RequestSellerSubAccountLoginEmailMutationFn = Apollo.MutationFunction<RequestSellerSubAccountLoginEmailMutation, RequestSellerSubAccountLoginEmailMutationVariables>;

/**
 * __useRequestSellerSubAccountLoginEmailMutation__
 *
 * To run a mutation, you first call `useRequestSellerSubAccountLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSellerSubAccountLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSellerSubAccountLoginEmailMutation, { data, loading, error }] = useRequestSellerSubAccountLoginEmailMutation({
 *   variables: {
 *      sellerUsername: // value for 'sellerUsername'
 *      subAccountEmail: // value for 'subAccountEmail'
 *   },
 * });
 */
export function useRequestSellerSubAccountLoginEmailMutation(baseOptions?: Apollo.MutationHookOptions<RequestSellerSubAccountLoginEmailMutation, RequestSellerSubAccountLoginEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestSellerSubAccountLoginEmailMutation, RequestSellerSubAccountLoginEmailMutationVariables>(RequestSellerSubAccountLoginEmailDocument, options);
      }
export type RequestSellerSubAccountLoginEmailMutationHookResult = ReturnType<typeof useRequestSellerSubAccountLoginEmailMutation>;
export type RequestSellerSubAccountLoginEmailMutationResult = Apollo.MutationResult<RequestSellerSubAccountLoginEmailMutation>;
export type RequestSellerSubAccountLoginEmailMutationOptions = Apollo.BaseMutationOptions<RequestSellerSubAccountLoginEmailMutation, RequestSellerSubAccountLoginEmailMutationVariables>;