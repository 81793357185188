import { Divider } from 'antd'
import { useEffect, useRef } from 'react'
import useCountDown from 'react-countdown-hook'
import { useTranslation } from 'react-i18next'

import Confirm from '@/components/Confirm/Confirm'
import Button from '@/components/ui/Button/Button'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import { ShowAuctionStatus } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackWarning } from '@/util/sentry'

import { useUnstuckShowCurrentAuctionOrGiveawayMutation } from '../../../operations.generated'

import type { ShowGiveawayStatus } from '@/network/graphql/types.generated'

export interface UnstuckShowProps {
  endDate: Date
  startDate: Date
  showId: string
  status: ShowAuctionStatus | ShowGiveawayStatus
}

const TIME_ALLOCATED_BEFORE_UNSTUCK_BUTTON_IS_DISPLAYED = 10000

const UnstuckShow = ({ endDate, startDate, showId, status }: UnstuckShowProps) => {
  const { t } = useTranslation()
  const timeToCountReference = useRef({ endTime: 0, startTime: 0 })

  const [unstuckAuctionOrGiveaway] = useUnstuckShowCurrentAuctionOrGiveawayMutation({
    onCompleted: () => {
      trackEvent('UNSTUCK_SHOW_REQUEST', { showId, status, shouldEndAt: endDate.getTime() })
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'seller unstucked the auction or giveaway',
        meta: { showId },
      })
      location.reload()
    },
    variables: { input: { showId } },
  })

  const [timeLeft, { start }] = useCountDown(0, 5000)
  const endTime = new Date(endDate).getTime()
  const startTime = new Date(startDate).getTime()

  useEffect(() => {
    const timeToCount =
      Math.round((endTime - startTime) / 1000) * 1000 + TIME_ALLOCATED_BEFORE_UNSTUCK_BUTTON_IS_DISPLAYED

    if (
      timeToCountReference.current.startTime !== startTime &&
      timeToCountReference.current.endTime !== endTime &&
      status !== ShowAuctionStatus.Closed
    ) {
      start(timeToCount)
      timeToCountReference.current = { endTime, startTime }
    }
  }, [status, endTime, startTime, start])

  const isAuctionOrGiveawayPotentiallyStuck = timeLeft === 0 && status !== ShowAuctionStatus.Closed
  useEffect(() => {
    if (isAuctionOrGiveawayPotentiallyStuck) {
      trackWarning('Auction might be stuck', {
        meta: {
          reason: 'Countdown at 0 but auction is not closed',
          showId,
          status,
          endDate,
          startDate,
          scope: 'UnstuckShow',
        },
      })
    }
  }, [isAuctionOrGiveawayPotentiallyStuck])

  return (
    <>
      {isAuctionOrGiveawayPotentiallyStuck && (
        <>
          <Divider />
          <p>{t('showAuctionCardUnStuckIntroText')}</p>
          <Confirm message={t('showAuctionCardUnStuckConfirmLabel')} onConfirm={unstuckAuctionOrGiveaway}>
            <Button className="unstuck-auction-action secondary" label={t('showAuctionCardUnStuckActionLabel')} />
          </Confirm>
        </>
      )}
    </>
  )
}

export default UnstuckShow
