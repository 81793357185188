import { type ChangeEvent } from 'react'

import type { SelectOption, SelectOptions } from '@/components/Form/Field/Select/Select'

type CategoryCellProps = {
  category: string
  error?: string
  options: SelectOptions
  onUpdate: (event: ChangeEvent<HTMLSelectElement>) => void
}

const CategoryCell = (props: CategoryCellProps) => {
  const { category, options, onUpdate, error } = props

  return (
    <td className={`category ${error ? 'is-error' : 'is-valid'}`}>
      <span className="value">
        <select value={category} onChange={onUpdate}>
          {options.map((group) => {
            // @ts-expect-error: to fix this
            const { label, options = [] } = group
            return (
              <optgroup key={label} label={label}>
                {options.map((option: SelectOption) => {
                  const { label, value } = option
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  )
                })}
              </optgroup>
            )
          })}
        </select>
      </span>
      {error && <span className="error">{error}</span>}
    </td>
  )
}

export default CategoryCell
