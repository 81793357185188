import { useCallback, useState, type MouseEvent } from 'react'
import { Draggable, DragDropContext, Droppable, type DropResult } from 'react-beautiful-dnd'

import Dialog from '@/components/ui/Dialog/Dialog'

import ProductImageItem from '../ProductImageItem/ProductImageItem'

import type { ProductImage } from '@/components/CreateOrEditProduct/types'

import './ProductImageList.scss'

type ProductPictureListProps = {
  images: ProductImage[]
  onDeleteImage?: (id: string) => void
  onReorderImages?: (images: ProductImage[]) => void
  disabled: boolean
}

const ProductImageList = (props: ProductPictureListProps) => {
  const { images, onDeleteImage = () => {}, onReorderImages = () => undefined, disabled } = props

  const listId = 'PRODUCT_IMAGES'
  const [clickedImageUrl, setClickedImageUrl] = useState<ProductImage['url']>('')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const handleDeleteImage = useCallback(
    (url: ProductImage['url']) => {
      const imageId = images.find((image) => image.url === url)?.id
      if (!imageId) {
        return
      }

      onDeleteImage(imageId)
    },
    [images]
  )

  const handleImageClick = useCallback((url: ProductImage['url']) => {
    setClickedImageUrl(url)
    setIsDialogOpen(true)
  }, [])

  const handleCloseDialog = useCallback((event?: MouseEvent) => {
    if (event) event.stopPropagation()

    setIsDialogOpen(false)
  }, [])

  const handleImageDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !images.length) {
        return
      }

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result

      if (sourceIndex === destinationIndex) {
        return
      }

      const currentImages = [...images]
      const [toBeMovedImage] = currentImages.splice(sourceIndex, 1)
      const deletedImages = currentImages.filter(({ isDeleted }) => isDeleted)
      const newImagesOrder = currentImages.toSpliced(destinationIndex, 0, toBeMovedImage).concat(deletedImages)

      onReorderImages(newImagesOrder)
    },
    [images, onReorderImages]
  )

  if (!images.length) {
    return null
  }

  return (
    <DragDropContext onDragEnd={handleImageDragEnd}>
      <div className="product-image-list-container">
        <Droppable direction="horizontal" droppableId={listId} isDropDisabled={disabled}>
          {(dropProvided) => (
            <>
              <ul ref={dropProvided.innerRef} className="product-image-list" {...dropProvided.droppableProps}>
                {images.map((image, index) => {
                  const { id, url } = image
                  return (
                    <Draggable key={id} draggableId={id} index={index} isDragDisabled={disabled}>
                      {(dragProvided) => (
                        <ProductImageItem
                          key={id}
                          disabled={disabled}
                          innerRef={dragProvided.innerRef}
                          url={url}
                          onDeleteImage={handleDeleteImage}
                          onViewImage={handleImageClick}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  )
                })}
              </ul>
              {dropProvided.placeholder}
            </>
          )}
        </Droppable>
        <Dialog className="product-image-preview" isOpen={isDialogOpen} modal={true} onClose={handleCloseDialog}>
          <img src={clickedImageUrl} />
        </Dialog>
      </div>
    </DragDropContext>
  )
}

export default ProductImageList
