import { useCallback, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { FaArrowDownUpAcrossLine, FaBars } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useShow } from '@/contexts/show/Show.context'
import { ProductType } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'

import { useProductsForOrderingLazyQuery, useUpdateProductPositionsMutation } from './operations.generated'

import './OrderingProducts.scss'

type OrderingProductItem = {
  id: string
  name: string
  description?: string | null
}

type OrderingProductsProps = {
  showId: string
  productType: ProductType
}

export const OrderingProducts = (props: OrderingProductsProps) => {
  const { t } = useTranslation()

  const { showId, productType } = props
  const { refetchProducts } = useShow()

  const [isOrderingOpen, setIsOrderingOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [data, setData] = useState<OrderingProductItem[]>([])
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [draggingId, setDraggingId] = useState<string | null>(null)

  const [loadProductsForOrdering] = useProductsForOrderingLazyQuery()
  const [updateProductPositions] = useUpdateProductPositionsMutation()

  const handleOpenDialog = useCallback(() => {
    setLoading(true)
    setIsOrderingOpen(true)
    loadProductsForOrdering({
      variables: {
        showId,
        productType,
      },
      onCompleted: (data) => {
        setData(data.productsForOrdering.edges.map((edge) => edge.node))
        setLoading(false)
      },
      onError: (error) => {
        trackError(error, { meta: { scope: 'OrderingProducts.loadProductsForOrdering' } })
        notificationDanger(error?.message)
        setLoading(false)
      },
    })
  }, [showId, productType, trackError, notificationDanger, loadProductsForOrdering])

  const handleCloseDialog = useCallback(
    (skip: boolean = false) => {
      if (!hasChanged || skip) {
        setHasChanged(false)
        setIsOrderingOpen(false)
        return
      } else if (window.confirm(t('orderingProductsCloseConfirm'))) {
        setIsOrderingOpen(false)
        setHasChanged(false)
        setData([])
        return
      } else return
    },
    [hasChanged]
  )

  const handleSaveProductsOrder = useCallback(() => {
    setLoadingSave(true)
    updateProductPositions({
      variables: {
        input: {
          showId,
          productType,
          productIds: data?.map((product) => `Product|${product.id}`) || [],
        },
      },
      onCompleted: () => {
        trackEvent('PRODUCTS_ORDERED', { showId, productType })
        refetchProducts(productType)
        handleCloseDialog(true)
        setLoadingSave(false)
        notificationSuccess(t('orderingProductsSaveSuccess'))
      },
      onError: (error) => {
        trackError(error, { meta: { scope: 'OrderingProducts.updateProductPositions' } })
        notificationDanger(error?.message)
        setLoadingSave(false)
      },
    })
  }, [
    data,
    showId,
    productType,
    refetchProducts,
    handleCloseDialog,
    trackError,
    notificationDanger,
    updateProductPositions,
  ])

  const onDragStart = useCallback((start) => {
    setDraggingId(start.draggableId)
  }, [])

  const onDragEnd = useCallback(
    (result) => {
      setDraggingId(null)
      if (!result.destination) return
      if (!hasChanged) setHasChanged(true)

      const newItems = Array.from(data || [])
      const selectedIds = new Set(selectedItems)
      const draggedItemId = result.draggableId

      if (selectedIds.has(draggedItemId) && selectedIds.size > 1) {
        const selectedElements = newItems.filter((item) => selectedIds.has(item.id))

        selectedElements.forEach((item) => {
          const index = newItems.findIndex((i) => i.id === item.id)
          if (index !== -1) newItems.splice(index, 1)
        })

        const destinationIndex = result.destination.index
        newItems.splice(destinationIndex, 0, ...selectedElements)
      } else {
        const [reorderedItem] = newItems.splice(result.source.index, 1)
        newItems.splice(result.destination.index, 0, reorderedItem)
      }

      setData(newItems)
    },
    [data, selectedItems]
  )

  const toggleSelection = useCallback((itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId) ? prevSelected.filter((id) => id !== itemId) : [...prevSelected, itemId]
    )
  }, [])

  const handleItemClick = useCallback(
    (event, itemId) => {
      if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
        setSelectedItems([itemId])
      } else {
        toggleSelection(itemId)
      }
    },
    [toggleSelection]
  )

  return (
    <>
      <Button
        className="ordering-products-action"
        icon={<FaArrowDownUpAcrossLine />}
        tooltip={t('orderingProductsTooltip')}
        onClick={handleOpenDialog}
      />
      {isOrderingOpen && (
        <Dialog
          buttonConfirmText={t('orderingProductsSave')}
          className="ordering-products-dialog"
          isConfirmDisabled={!hasChanged}
          isConfirmLoading={loadingSave}
          isOpen={true}
          modal={true}
          title={
            <div>
              <h2>
                {t('orderingProductsTitle', {
                  type:
                    productType === ProductType.Auction
                      ? t('orderingProductsTitleAuction')
                      : productType === ProductType.InstantBuy
                        ? t('orderingProductsTitleInstantBuy')
                        : t('orderingProductsTitleGiveaway'),
                })}
              </h2>
              <p>{t('orderingProductsSubTitle')}</p>
              <span>{t('orderingProductsInfo')}</span>
            </div>
          }
          onClose={() => handleCloseDialog(false)}
          onConfirm={handleSaveProductsOrder}
        >
          <div className="ordering-products-list">
            {data && (
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <div className="ordering-products-container">
                  <Droppable droppableId="list">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0 }}>
                        {data.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => {
                              const isSelected = selectedItems.includes(item.id)
                              const isDragging = snapshot.isDragging
                              const isCollapsed = draggingId && isSelected && !isDragging

                              return (
                                <li
                                  ref={provided.innerRef}
                                  className={`ordering-products-item ${isSelected ? 'selected' : ''} ${isDragging ? 'dragging' : ''} ${isCollapsed ? 'collapsed' : ''}`}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                  }}
                                  onClick={(e) => handleItemClick(e, item.id)}
                                >
                                  <FaBars />
                                  <div>
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                  </div>
                                  {isDragging && isSelected && selectedItems.length > 1 && (
                                    <span style={{ marginLeft: '10px' }}>+{selectedItems.length - 1}</span>
                                  )}
                                </li>
                              )
                            }}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            )}
            {loading && <Loader />}
          </div>
        </Dialog>
      )}
    </>
  )
}
