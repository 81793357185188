import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

export interface BanAndUnbanItemProps {
  typename: string
  username: string
  timestamp: Date
}

const BanAndUnbanItem = ({ typename, username, timestamp }: BanAndUnbanItemProps) => {
  const { t } = useTranslation()

  const getBanUnbanText = () => {
    if (!typename) return ''
    switch (typename) {
      case 'UserBlockedFromCommentingInShowFeedItem':
        return t('banUserFromShowSuccess')
      case 'UserUnblockedFromCommentingInShowFeedItem':
        return t('unbanUserFromShowSuccess')
      default:
        return ''
    }
  }

  if (!username || !typename) {
    return null
  }

  return (
    <div className="flex py-1 break-words flex-row text-sm">
      <div className="basis-5/6 text-red-500 font-semibold">
        {username} : {getBanUnbanText()}
      </div>

      <div className="basis-1/6 text-xs text-black-120 text-right mr-2">
        <span>{dayjs(timestamp).format('HH:mm')}</span>
      </div>
    </div>
  )
}

export default memo(BanAndUnbanItem)
