import { Upload } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaTrash, FaPlay, FaEyeLowVision } from 'react-icons/fa6'

import ProgressBar from '@/components/ProgressBar'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'

import { VideoUploadedStatus } from './VideoUploader.container'
import { formatBytes } from './videoUploader.helper'

import type { UploadFile } from 'antd'

import './VideoUploader.scss'

const MAX_VIDEO_SIZE_IN_MB = 100
interface VideoUploaderProps {
  isRequired?: boolean | null
  title: string
  name: string
  valuePropName?: string
  getValueFromEvent?: (e: any) => void
  accept: string
  maxCount: number
  onChange: (e: any) => void
  onRemove: () => void
  customRequest: (e: any) => void
  errorMessage?: string
  uploadStatus?: VideoUploadedStatus
  videoUrl?: string | null
  parsedVideoUrl?: string | null
  progress: number
  disabled: boolean
  originalFileInfo: any
}

export const VideoUploader = ({
  isRequired,
  title,
  name,
  valuePropName,
  getValueFromEvent,
  accept,
  maxCount,
  onChange,
  onRemove,
  customRequest,
  uploadStatus,
  videoUrl,
  parsedVideoUrl,
  errorMessage,
  progress,
  disabled,
  originalFileInfo,
}: VideoUploaderProps) => {
  const { t } = useTranslation()

  const [file, setFile] = useState<UploadFile | null>(null)
  const [playModalOpen, setPlayModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [videoName, setVideoName] = useState<string>('')
  const [videoSize, setVideoSize] = useState<number>(0)

  useEffect(() => {
    const name = originalFileInfo?.name ? originalFileInfo.name : file?.name || t('defaultTrailerVideoName')
    setVideoName(name)
    const size = originalFileInfo?.size ? originalFileInfo.size : file?.size || 0
    setVideoSize(size)
  }, [originalFileInfo, file])

  const renderVideoSnapshot = (videoUrl: string, isBig: boolean = false) => {
    return (
      <div className="video_box">
        {(file && file.type === 'video/mp4') || uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED ? (
          <video className={isBig ? 'video_big' : 'video_thumbnail'} controls={isBig} src={videoUrl}></video>
        ) : (
          <FaEyeLowVision />
        )}
      </div>
    )
  }

  const isVideoUploaded = useMemo(
    () =>
      (uploadStatus === VideoUploadedStatus.VIDEO_UPLOADED && parsedVideoUrl) ||
      (uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED && videoUrl),
    [videoUrl, parsedVideoUrl, uploadStatus]
  )

  const renderDeleteButton = () => (
    <span
      className="video_control delete"
      onClick={(e) => {
        e.preventDefault()
        setDeleteModalOpen(true)
      }}
    >
      <FaTrash height={18} width={20} />
    </span>
  )

  const renderVideoContent = (isBig: boolean = false) => {
    return (
      <>
        {uploadStatus === VideoUploadedStatus.NO_VIDEO && (
          <div className="drag_and_drop_video">
            <svg fill="none" height="48" viewBox="0 0 49 48" width="49" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M32.4735 28.4124V33.8954C32.4735 34.1724 32.455 34.4677 32.4365 34.7262C32.1042 38.8431 29.2796 41.5385 25.2365 41.5385H14.1227C9.80267 41.5385 6.88574 38.4739 6.88574 33.8954V28.4124C6.88574 23.8524 9.80267 20.7693 14.1227 20.7693H25.2365C29.2796 20.7693 32.1042 23.4831 32.4365 27.5816V27.6001C32.455 27.8585 32.4735 28.1354 32.4735 28.4124Z"
                fill="black"
                fillRule="evenodd"
                opacity="0.4"
              />
              <path
                clipRule="evenodd"
                d="M41.5002 26.5755L41.4632 35.7694C41.4632 37.4494 40.0971 38.8155 38.4355 38.8155C37.734 38.8155 37.0509 38.5755 36.4971 38.114L32.4355 34.7171C32.454 34.4586 32.4725 34.1632 32.4725 33.8863V28.4032C32.4725 28.1263 32.454 27.8494 32.4355 27.5909V27.5724L36.534 24.194C37.1617 23.6586 37.9371 23.4186 38.7679 23.5109C39.5617 23.5848 40.3002 23.9724 40.8171 24.6186C41.2602 25.1724 41.5002 25.874 41.5002 26.5755Z"
                fill="black"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M24.4051 29.8702H19.7528C18.9885 29.8702 18.3682 29.2499 18.3682 28.4856C18.3682 27.7213 18.9885 27.101 19.7528 27.101H24.4051C25.1695 27.101 25.7898 27.7213 25.7898 28.4856C25.7898 29.2499 25.1695 29.8702 24.4051 29.8702Z"
                fill="black"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M25.2737 6.46155C21.8177 6.46155 19.0078 9.31016 19.0078 12.8105C19.0078 16.3034 21.8177 19.1446 25.2737 19.1446C28.7297 19.1446 31.5395 16.3034 31.5395 12.8105C31.5395 9.31016 28.7297 6.46155 25.2737 6.46155Z"
                fill="black"
                fillRule="evenodd"
              />
              <path
                clipRule="evenodd"
                d="M12.4654 10.3197C10.0598 10.3197 8.10107 12.2988 8.10107 14.732C8.10107 17.1652 10.0598 19.1443 12.4654 19.1443C14.8728 19.1443 16.8297 17.1652 16.8297 14.732C16.8297 12.2988 14.8728 10.3197 12.4654 10.3197Z"
                fill="black"
                fillRule="evenodd"
              />
            </svg>

            <div className="video-upload-infos">
              <p>
                <Trans
                  i18nKey="createShowModalDetailsFormUploadVideoContent"
                  components={{
                    1: <b></b>,
                  }}
                />
              </p>
              <span className="video-upload-infos-notice">
                <Trans
                  i18nKey="uploadVideoNotice"
                  values={{
                    sizeInMb: MAX_VIDEO_SIZE_IN_MB,
                  }}
                />
              </span>
            </div>
          </div>
        )}
        {uploadStatus === VideoUploadedStatus.VIDEO_UPLOADING && progress && (
          <div className="drag_and_drop_video">
            <div className="video_loading">
              <h3>{t('videoUploadProgress')}</h3>
              <ProgressBar percent={progress} />
            </div>
          </div>
        )}

        {uploadStatus === VideoUploadedStatus.VIDEO_UPLOADED &&
          parsedVideoUrl &&
          renderVideoSnapshot(parsedVideoUrl, isBig)}

        {uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED &&
          videoUrl &&
          renderVideoSnapshot(videoUrl, isBig)}

        {uploadStatus === VideoUploadedStatus.VIDEO_TRANSCODING && (
          <div className="drag_and_drop_video">
            <div className="video_loading">
              <h3 className="video_transcoding_content">
                {t('videoUploadTranscodingInProgress')}
                {renderDeleteButton()}
              </h3>
            </div>
          </div>
        )}
      </>
    )
  }

  const handleOnChange = (info: any) => {
    setFile(info.file)
    onChange(info)
  }

  const renderVideoItem = () => {
    const videoSizeFormatted = formatBytes(videoSize)
    return (
      <div className="video_controls">
        <span>
          {videoName} ({videoSizeFormatted})
        </span>
        <span
          className="video_control"
          onClick={(e) => {
            e.preventDefault()
            setPlayModalOpen(true)
          }}
        >
          <FaPlay />
        </span>
        {renderDeleteButton()}
      </div>
    )
  }

  return (
    <>
      <FormItem
        getValueFromEvent={getValueFromEvent}
        valuePropName={valuePropName}
        rules={[
          {
            required: !!isRequired,
            message: t('createShowModalDetailsFormFieldRequired'),
          },
        ]}
      >
        <p className="video-title">{title}</p>
        <Upload.Dragger
          accept={accept}
          className="video-uploader"
          customRequest={customRequest}
          disabled={disabled}
          itemRender={() => null}
          maxCount={maxCount}
          name={name}
          onChange={handleOnChange}
          onRemove={onRemove}
        >
          {renderVideoContent()}
        </Upload.Dragger>

        {errorMessage && <p className="error_upload">{errorMessage}</p>}
        {isVideoUploaded && renderVideoItem()}
      </FormItem>
      <Dialog isOpen={playModalOpen} title={videoName} onClose={() => setPlayModalOpen(false)}>
        {renderVideoContent(true)}
      </Dialog>
      <Dialog isOpen={deleteModalOpen} title={t('videoModalDeleteTitle')} onClose={() => setDeleteModalOpen(false)}>
        <div className="delete-video-buttons">
          <Button
            className="secondary"
            label={t('videoModalDeleteCancelText')}
            onClick={() => setDeleteModalOpen(false)}
          />
          <Button
            className="primary"
            label={t('videoModalDeleteOkText')}
            onClick={() => {
              onRemove()
              setDeleteModalOpen(false)
            }}
          />
        </div>
      </Dialog>
    </>
  )
}
