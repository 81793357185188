import { Popover, Tag } from 'antd'
import moment from 'moment'

import User from '../../../components/ui/User/User'

import type { Show } from '../../../types'

import globalStyle from './../../../styles/_export.module.scss'
import style from './style.module.scss'

type ShowCardProps = {
  show: Pick<Show, 'category' | 'user' | 'startAt' | 'name' | 'note'> & {
    parentCategory: { primaryColor: string; secondaryColor: string }
  }
}

const ShowCard = ({ show }: ShowCardProps) => {
  const { name, note, user, parentCategory, startAt } = show
  const primaryColor = parentCategory?.primaryColor ?? globalStyle.black150
  const secondaryColor = parentCategory?.secondaryColor ?? globalStyle.offwhite130

  if (!show) return null

  const content = () => (
    <div className={style.popover__container}>
      {user && <User src={user.avatarUrl}>{user.username}</User>}
      <h3 className={style.popover__title}>{name}</h3>
      <h4 className={style.popover__description}>{note}</h4>

      <div className={style.popover__tag}>
        <Tag
          className="body_normal"
          color={secondaryColor}
          style={{
            borderRadius: 8,
            padding: '8px 10px',
            border: `2px solid ${primaryColor}`,
          }}
        >
          <span style={{ color: primaryColor }}>{show.category.name}</span>
        </Tag>
      </div>
    </div>
  )

  const popoverTitle = () => (
    <div className={style.popover__title}>
      <span className="callout_2">{moment(startAt).format('ddd Do MMMM YYYY')}</span>
      <span className="callout_2">{moment(startAt).format('HH:mm')}</span>
    </div>
  )

  return (
    <Popover content={content} title={popoverTitle}>
      <div
        className={style.show_card__container}
        style={{
          backgroundColor: secondaryColor,
        }}
      >
        <div className={style.show_card__border_container}>
          <div className={style.show_card__border} style={{ backgroundColor: primaryColor }} />
        </div>
        <span className={`callout_4`} style={{ color: primaryColor }}>
          {name}
        </span>
        <span className={`${style.show_card__username} callout_3`}>@{user?.username}</span>
      </div>
    </Popover>
  )
}

export default ShowCard
