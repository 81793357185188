import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaXmark } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { setToLocalStorage } from '@/helpers/localstorage'

import { FontSizes, useChatBoxContext } from '../../../ChatBoxProvider'

interface ChatFontSizeProps {
  handleClose: () => void
  handleGoBack: () => void
}

export const ChatFontSize = memo((props: ChatFontSizeProps) => {
  const { t } = useTranslation()
  const { handleClose, handleGoBack } = props
  const { fontSize, setFontSize } = useChatBoxContext()

  const handleFontSizeChange = useCallback((value: FontSizes) => {
    setFontSize(value)
    setToLocalStorage('chat_font_size', value)
  }, [])

  return (
    <>
      <div className="chat-settings">
        <div className="chat-settings-title">
          <div className="chat-settings-back">
            <Button className="chat-settings-back" icon={<FaArrowLeft />} onClick={handleGoBack} />
            <h2>{t('chatSettingsFontTitle')}</h2>
          </div>
          <Button className="chat-settings-close" icon={<FaXmark />} onClick={handleClose} />
        </div>
      </div>
      <div className="chat-font-size">
        <div
          className={fontSize === FontSizes.small ? 'selected' : ''}
          onClick={() => handleFontSizeChange(FontSizes.small)}
        >
          <p className="chat-font-small">{t('chatFontSizeSmall')}</p>
        </div>

        <div
          className={fontSize === FontSizes.normal ? 'selected' : ''}
          onClick={() => handleFontSizeChange(FontSizes.normal)}
        >
          <p className="chat-font-normal">{t('chatFontSizeNormal')}</p>
        </div>

        <div
          className={fontSize === FontSizes.medium ? 'selected' : ''}
          onClick={() => handleFontSizeChange(FontSizes.medium)}
        >
          <p className="chat-font-medium">{t('chatFontSizeMedium')}</p>
        </div>

        <div
          className={fontSize === FontSizes.large ? 'selected' : ''}
          onClick={() => handleFontSizeChange(FontSizes.large)}
        >
          <p className="chat-font-large">{t('chatFontSizeLarge')}</p>
        </div>
      </div>
    </>
  )
})
