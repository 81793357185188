import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Tag from '@/components/Tag/Tag'
import Dialog from '@/components/ui/Dialog/Dialog'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderPaymentStatus, type ShipmentStatus } from '@/network/graphql/types.generated'
import { useGetShipmentByIdQuery } from '@/views/Shipments/operations.generated'
import { ShipmentStatusTag } from '@/views/Shipments/Tables/components/ShipmentStatus/ShipmentStatus'
import UsernameAndContactColumn from '@/views/Shipments/Tables/components/UsernameAndContactColumn/UsernameAndContactColumn'

import type { TagStatus } from '@/components/Tag/Tag'

import './DrawerShipment.scss'

export interface DrawerShipmentProps {
  open: boolean
  onClose(): void
  onReset(): void
  shipmentId: string | null
}

export const DrawerShipment = (props: DrawerShipmentProps) => {
  const { onClose, onReset, open, shipmentId } = props
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const { data } = useGetShipmentByIdQuery({
    skip: !shipmentId,
    variables: {
      nodeId: shipmentId ?? '',
    },
  })

  const shipment = data?.node.__typename === 'Shipment' ? data?.node : undefined

  const handleClose = () => {
    onClose()
    onReset()
  }
  if (!shipment) return null

  return open ? (
    <Dialog
      className="shipment-detail-drawer is-drawer is-animated"
      isOpen={true}
      modal={true}
      title={t('drawerShipmentTitle')}
      onClose={handleClose}
    >
      <div className="shipment-detail-item-header">
        <div>
          <UsernameAndContactColumn
            avatarUrl={shipment.customer.avatarWebPUrl}
            createdAt={shipment.createdAt}
            username={shipment.customer.username}
          />
          <p className="shipment-detail-item-date">{dayjs(shipment.createdAt).format('DD/MM/YYYY')}</p>
        </div>

        <ShipmentStatusTag status={shipment.status as ShipmentStatus} />
      </div>
      <div>
        {shipment.orderedProducts.edges.map((item) => {
          const { images, name, order, id } = item.node
          const {
            paymentStatus,
            amountInCurrencyCents,
            shippingAmountInCurrencyCents,
            currency,
            shippingFeesCancellationRequestedAt,
          } = order
          const isCancelled = !!shippingFeesCancellationRequestedAt
          let paymentStatusLabel
          let paymentStatusColor

          switch (paymentStatus) {
            case OrderPaymentStatus.Success:
              paymentStatusLabel = t('orderPaymentStatusSuccess')
              paymentStatusColor = 'success'
              break
            case OrderPaymentStatus.Failed:
              paymentStatusLabel = t('orderPaymentStatusFailed')
              paymentStatusColor = 'danger'
              break
            case OrderPaymentStatus.InProgress:
              paymentStatusLabel = t('orderPaymentStatusInProgress')
              paymentStatusColor = 'warning'
              break
            case OrderPaymentStatus.PendingSepaDebit:
              paymentStatusLabel = t('orderPaymentStatusInProgress')
              paymentStatusColor = 'warning'
              break
          }

          const imageUrl = images?.[0]?.webPUrl || '/badges/empty-pic.png'
          return (
            <div className="shipment-detail-item-container">
              <div key={`product-${id}`} className="shipment-detail-item-product">
                <div className="shipment-detail-product-image">
                  <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
                </div>
                <div className="shipment-detail-product-details">
                  <p>{name}</p>
                </div>
              </div>

              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemStatus')}</p>
                <Tag content={paymentStatusLabel} status={paymentStatusColor as TagStatus} />
              </div>
              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemAmount')}</p>
                <p>{amountInCurrencyCents ? currencyFormatter(amountInCurrencyCents, currency) : '-'}</p>
              </div>
              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemShippingFee')}</p>
                <p className={isCancelled ? 'shipping-fees-refunded' : ''}>
                  {shippingAmountInCurrencyCents ? currencyFormatter(shippingAmountInCurrencyCents, currency) : '-'}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </Dialog>
  ) : null
}
