import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import EditIcon from '@/components/ui/Icons/EditIcon/EditIcon'
import { ShowFormStep } from '@/views/Show/Show'

import './EditShow.scss'

type EditShowProps = {
  onEdit?: (string: ShowFormStep) => void
}

const EditShow = (props: EditShowProps) => {
  const { onEdit = () => undefined } = props
  const { t } = useTranslation()

  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false)

  const handleToggleEditMenu = useCallback(() => {
    setIsEditMenuOpen(!isEditMenuOpen)
  }, [isEditMenuOpen])

  return (
    <span className="edit-show-actions">
      <Button
        className="toggle-menu toggle-edit-show-action"
        icon={<EditIcon />}
        label={t('commonEdit')}
        onClick={handleToggleEditMenu}
      >
        {/* <span className="toggle">
          <FaCaretDown />
        </span> */}
      </Button>
      <menu className={`show-edit-menu ${isEditMenuOpen ? 'is-open' : ''}`}>
        <ul>
          <li>
            <Button
              className="edit-action edit-show-action primary"
              icon={<EditIcon />}
              label={t('showCardButtonShowCategoryEdit')}
              onClick={() => onEdit(ShowFormStep.Category)}
            />
          </li>
          <li>
            <Button
              className="edit-action edit-show-action primary"
              icon={<EditIcon />}
              label={t('showCardButtonShowImageEdit')}
              onClick={() => onEdit(ShowFormStep.Image)}
            />
          </li>
          <li>
            <Button
              className="edit-action edit-show-action primary"
              icon={<EditIcon />}
              label={t('showCardButtonShowInfoEdit')}
              onClick={() => onEdit(ShowFormStep.Info)}
            />
          </li>
        </ul>
      </menu>
    </span>
  )
}

export default EditShow
