import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircleInfo, FaDownload } from 'react-icons/fa6'

import CopyableText from '@/components/CopyableText'
import ErrorPageAccessDenied from '@/components/Error/ErrorPageAccessDenied/ErrorPageAccessDenied'
import StripeAutoDebitAgreementBanner from '@/components/Stripe/StripeAutoDebitAgreement/StripeAutoDebitAgreementBanner/StripeAutoDebitAgreementBanner'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Dialog from '@/components/ui/Dialog/Dialog'
import Link from '@/components/ui/Link/Link'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import Popover from '@/components/ui/Popover/Popover'
import Table, { DEFAULT_ITEM_PER_PAGE, FiltersType } from '@/components/ui/Table/Table'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import {
  SellerLedgerTransactionsSort,
  SellerLedgerTransactionStatus,
  SellerLedgerTransactionType,
} from '@/network/graphql/types.generated'
import { triggerDownloadForFile } from '@/util/download-files'
import { PayoutStatus } from '@/views/Ledger/components/PayoutStatus'
import { getErrorCode, getStatusTranslationKey } from '@/views/Ledger/helper'

import { useGetSellerBankingInfoQuery } from '../Account/components/BankInfo/BankInfoForm/operations.generated'

import { Description } from './components/Description'
import {
  useCreateExportSellerLedgerTransactionsMutation,
  useCreatePayoutFromSellerLedgerMutation,
  useLedgerQuery,
} from './operations.generated'

import type { LedgerQueryVariables } from './operations.generated'
import type { FiltersValue } from '@/components/ui/Table/Table'
import type { SortingState } from '@tanstack/react-table'

import './Ledger.scss'

dayjs.extend(localizedFormat)

const filtersTypeAsArray = Object.values(SellerLedgerTransactionType)
const filtersStatusesAsArray = Object.values(SellerLedgerTransactionStatus).filter(
  (status) => status !== SellerLedgerTransactionStatus.Success // For nor the success status is not used
)

const MIN_MINUTES_BEFORE_PAYOUT_RETRY = 5

const shouldDisablePayoutBasedOnLastAttempt = () => {
  const localStorageValue = getFromLocalStorage('lastPayoutAttempt') || '0'
  const casted = parseInt(localStorageValue, 10) || 0
  const lastAttemptAt = new Date(casted)
  return dayjs().diff(lastAttemptAt, 'minute') < MIN_MINUTES_BEFORE_PAYOUT_RETRY
}

const MINIMUM_PAYOUT_AMOUNT_IN_CENTS = 1000

function Payout() {
  const { t, i18n } = useTranslation()

  const { language } = i18n

  useDocumentTitle(t('ledgerTitle'))

  const { user, isLoading } = useUser()
  const { sellerConfig } = user || {}
  const { canUseLedger, shouldAcceptStripeDebitAgreement } = sellerConfig || {}

  const [perPage, setPerPage] = useState(DEFAULT_ITEM_PER_PAGE)
  const [currentPage, setCurrentPage] = useState(1)
  const offset = (currentPage - 1) * perPage

  const filtersTypeMapped = filtersTypeAsArray.map((value) => {
    return { label: t('ledger_' + value), value }
  })
  const filtersStatusesMapped = filtersStatusesAsArray.map((value) => {
    return { label: t(getStatusTranslationKey(value)), value }
  })
  const [filters, setFilters] = useState<LedgerQueryVariables['filters']>(undefined)
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'date',
      desc: true,
    },
  ])
  const [sortBy, setSortBy] = useState<SellerLedgerTransactionsSort | undefined>(undefined)

  const { data, refetch, loading } = useLedgerQuery({ variables: { first: perPage, offset, filters, sort: sortBy } })
  const [createPayoutFromSellerLedger] = useCreatePayoutFromSellerLedgerMutation()
  const [createExportSellerLedgerTransactions] = useCreateExportSellerLedgerTransactionsMutation()
  const transactions = data?.sellerLedgerTransactions.edges.map((edge) => edge?.node) || []
  const payoutAvailableNow = data?.sellerLedgerBalance.availableforPayout.amount || 0
  const payoutAvailableSoon = data?.sellerLedgerBalance.availableSoon.amount || 0
  const pastPayouts = data?.sellerLedgerPayouts.edges.map((edge) => edge?.node) || []
  const [loadingExport, setIsLoadingExport] = useState<boolean>(false)

  const { data: bankingInfo } = useGetSellerBankingInfoQuery()
  const { sellerBankingInfo } = bankingInfo?.sellerInfo || {}
  const { bankAccountName: bankName, bic, ibanFourLastDigits } = sellerBankingInfo || {}
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false)

  if (!getFromLocalStorage('hasSeenLedger')) {
    setToLocalStorage('hasSeenLedger', 'true')
  }

  const onPayoutCreate = async () => {
    const shouldAbort = shouldDisablePayoutBasedOnLastAttempt()
    setToLocalStorage('lastPayoutAttempt', new Date().valueOf().toString())
    if (shouldAbort) return

    const response = await createPayoutFromSellerLedger()

    if (response.data?.createPayoutFromSellerLedger.ok) {
      notificationSuccess(t('ledgerPayoutFlowSuccess'))
      refetch()
    } else {
      removeFromLocalStorage('lastPayoutAttempt')
      localStorage?.removeItem('lastPayoutAttempt')
    }

    const errorMessage =
      response.data?.createPayoutFromSellerLedger.__typename === 'CreatePayoutFromSellerLedgerPayloadError'
        ? response.data.createPayoutFromSellerLedger.error
        : false

    if (errorMessage) {
      // This is a monkey patch for the error message. It should be handled in the backend
      notificationDanger(t('ledgerPayoutInsufficientFundsErrorWithCode', { errorCode: getErrorCode(errorMessage) }))
    }
  }

  const onExportCreate = async () => {
    setIsLoadingExport(true)
    await createExportSellerLedgerTransactions({
      variables: {
        filters,
        language,
      },
      onCompleted: (result) => {
        if (result?.createExportSellerLedgerTransactions?.fileURL) {
          const filename = `voggt-finances-${dayjs().format('YYYY-MM-DD-HH-mm')}.csv`

          triggerDownloadForFile(result.createExportSellerLedgerTransactions.fileURL, filename)
        }
      },
      onError: (error) => {
        notificationDanger(error.message)
      },
    })
    setIsLoadingExport(false)
  }

  const transactionColumns = [
    { id: 'orderId', header: 'Order ID ', accessorKey: 'orderId', enableColumnFilter: true },
    {
      id: 'type',
      header: t('ledger_table_title_type'),
      accessorKey: 'type',
      cell: (info: any) => <p className="m-0 lowercase first-letter:uppercase">{t('ledger_' + info.getValue())}</p>,
      enableColumnFilter: true,
    },
    {
      id: 'description',
      header: t('ledger_table_title_description'),
      accessorKey: 'description',
      enableColumnFilter: false,
      cell: (info: any) => <Description value={info.getValue()} />,
    },
    {
      id: 'status',
      header: t('ledger_table_title_status'),
      accessorKey: 'status',
      cell: (info: any) => t(getStatusTranslationKey(info.getValue())),
      enableColumnFilter: true,
    },
    {
      id: 'amount',
      header: t('ledger_table_title_amount'),
      accessorKey: 'amount',
      cell: (info: any) => `${info.getValue().amount / 100}€`,
      enableColumnFilter: true,
    },
    {
      id: 'date',
      header: t('commonDate'),
      accessorKey: 'createdAt',
      cell: (info: any) =>
        dayjs(info.getValue())
          .locale(i18n.resolvedLanguage || '')
          .format('LL')
          .toString(),
      enableColumnFilter: true,
    },
    {
      id: 'showId',
      header: t('ledger_table_title_show_id'),
      cell: (info: any) => {
        const record = info.row.original

        return (
          <Link href={`/shows/${record.showId}`} rel="noreferrer" target="_blank">
            {record.showId}
          </Link>
        )
      },
    },
    {
      id: 'stripePayoutId',
      header: t('ledger_table_title_payoutid'),
      cell: (info: any) => {
        const payoutId = info.row.original.stripePayoutId
        if (!payoutId) return null

        return <CopyableText content={payoutId.slice(-6)} value={payoutId} />
      },
    },
  ]

  const total = data?.sellerLedgerTransactions.totalCount

  const onItemPerPageChange = (pp: number) => {
    setCurrentPage(1)
    setPerPage(pp)
  }
  const onPageChange = (p: number) => {
    setCurrentPage(p)
  }

  const handleFilterStripePayoutIdOptionSelected = (option: string) => {
    if (option && typeof option === 'string') {
      setFilters({ stripePayoutIds: [option] })
    } else {
      setFilters(undefined)
    }
    setCurrentPage(1)
  }

  const handleSortingChange = (sort: SortingState) => {
    setSorting(sort)
    setCurrentPage(1)
    if (!sort[0]) {
      setSortBy(undefined)
    } else {
      const sortColumn = sort[0].id
      const isSortDesc = sort[0].desc === true
      switch (sortColumn) {
        case 'amount':
          setSortBy(isSortDesc ? SellerLedgerTransactionsSort.AmountDesc : SellerLedgerTransactionsSort.AmountAsc)
          break
        case 'date':
          setSortBy(isSortDesc ? SellerLedgerTransactionsSort.CreatedAtDesc : SellerLedgerTransactionsSort.CreatedAtAsc)
          break
        case 'description':
          setSortBy(
            isSortDesc ? SellerLedgerTransactionsSort.DescriptionDesc : SellerLedgerTransactionsSort.DescriptionAsc
          )
          break
        case 'orderId':
          setSortBy(isSortDesc ? SellerLedgerTransactionsSort.OrderIdDesc : SellerLedgerTransactionsSort.OrderIdAsc)
          break
        case 'status':
          setSortBy(isSortDesc ? SellerLedgerTransactionsSort.StatusDesc : SellerLedgerTransactionsSort.StatusAsc)
          break
        case 'type':
          setSortBy(isSortDesc ? SellerLedgerTransactionsSort.TypeDesc : SellerLedgerTransactionsSort.TypeAsc)
          break
      }
    }
  }

  const handleFilter = (filters: FiltersValue[]) => {
    const filtersToApply: LedgerQueryVariables['filters'] = {}

    filters.forEach((filter) => {
      const value = filter.value
      switch (filter.name) {
        case 'type':
          if (value !== '') filtersToApply.transactionTypes = [value as SellerLedgerTransactionType]
          break
        case 'orderId':
          if (value !== '') filtersToApply.orderIds = [`Order|${value}`]
          break
        case 'status':
          if (value !== '') filtersToApply.statuses = [filter.value as SellerLedgerTransactionStatus]
          break
        case 'stripePayoutId':
          if (value !== '') filtersToApply.stripePayoutIds = [filter.value as string]
          break
        case 'amount':
          filtersToApply.amount = {
            from: value && typeof value === 'object' && value.from ? Number(value.from) * 100 : undefined,
            to: value && typeof value === 'object' && value.to ? Number(value.to) * 100 : undefined,
          }
          break
        case 'date':
          filtersToApply.createdAt = {
            from: value && typeof value === 'object' && value.from ? new Date(value.from) : undefined,
            to: value && typeof value === 'object' && value.to ? new Date(value.to) : undefined,
          }
          break
      }
    })

    setFilters(filtersToApply)
    setCurrentPage(1)
  }

  const isMinimumPayoutAvailable = Boolean(payoutAvailableNow >= MINIMUM_PAYOUT_AMOUNT_IN_CENTS)
  const isPayoutButtonDisabled =
    Boolean(!isMinimumPayoutAvailable) || shouldDisablePayoutBasedOnLastAttempt() || shouldAcceptStripeDebitAgreement
  const shouldDisplayMinPayoutInfo = Boolean(!!payoutAvailableNow && !isMinimumPayoutAvailable)

  if (isLoading) return <Loader />
  if (!isLoading && !canUseLedger) return <ErrorPageAccessDenied />

  return (
    <ViewContainer id="ledger" leftContent={<h1 className="m-0">{t('ledgerTitle')}</h1>}>
      <StripeAutoDebitAgreementBanner />
      <Card className="available-now">
        <header className="header">
          <h1 className="title">{t('ledgerAvailableNowTitle')}</h1>
          <Button
            className="action"
            href={t('ledgerUniversityLink')}
            icon={<FaCircleInfo />}
            target="_blank"
            tooltip={t('ledgerUniversityLinkLabel')}
          />
        </header>
        <div className="flex flex-col divide-y divide-slate-100">
          <div className="flex gap-2 items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <h2 className="text-2xl m-0">{(payoutAvailableNow / 100).toFixed(2)}€</h2>
              {payoutAvailableNow < 0 && (
                <Popover content={t('ledgerNegativeAmount')}>
                  <FaCircleInfo />
                </Popover>
              )}
            </div>

            <div className="flex justify-end items-center">
              <span className="trigger-payout">
                <Button
                  className="action trigger-payout-action primary"
                  disabled={isPayoutButtonDisabled}
                  label={t('ledgerPayoutButton')}
                  onClick={() => setIsModalConfirmVisible(true)}
                />
                {/* TODO: the following should not be a button as it actually has no action */}
                {shouldDisplayMinPayoutInfo && (
                  <Button
                    className="info min-payment-info"
                    icon={<FaCircleInfo />}
                    tabIndex={-1}
                    tooltip={t('payoutMinimumAvailableAmountInfoMessage')}
                  />
                )}
              </span>
            </div>
          </div>

          <div className="pt-4">
            <div className="flex flex-col gap-2">
              <h2 className="text-base font-semibold m-0">{t('ledgerAvailableSoon')}</h2>
              <h2 className="text-base m-0 text-slate-600">{(payoutAvailableSoon / 100).toFixed(2)}€</h2>
            </div>
          </div>
        </div>
      </Card>

      <Card className="past-payouts" title={t('ledgerPastPayoutsTitle')}>
        <div className="flex flex-col">
          {pastPayouts.map((payout) => (
            <div key={payout.id} className="flex justify-between items-center py-4 border-b border-slate-100">
              <p className="text-base m-0">
                {dayjs(payout.createdAt)
                  .locale(i18n.resolvedLanguage || '')
                  .format('LL')
                  .toString()}
              </p>
              {payout.stripePayoutId && (
                <p
                  className="text-base m-0 underline"
                  onClick={() => handleFilterStripePayoutIdOptionSelected(payout.stripePayoutId as string)}
                >
                  {payout.stripePayoutId.slice(-6)}
                </p>
              )}

              <PayoutStatus payoutStatus={payout.status} />

              <div className="flex items-center gap-4">
                <p className="font-semibold m-0">{(payout.amount.amount / 100).toFixed(2)}€</p>
              </div>
            </div>
          ))}
        </div>
      </Card>

      <Card className="transactions" noPadding={true}>
        <Table
          columns={transactionColumns}
          data={transactions}
          loading={loading}
          pagination={{ currentPage, onItemPerPageChange, onPageChange, perPage, total }}
          sort={{ sorting, onSortingChange: handleSortingChange }}
          filters={{
            options: [
              {
                type: FiltersType.select,
                name: 'type',
                filterOptions: [{ label: '', value: '' }, ...filtersTypeMapped],
                label: t('ledgerFilterType'),
                selected: !!filters?.transactionTypes?.[0],
              },
              {
                name: 'orderId',
                type: FiltersType.inputNumber,
                label: t('ledgerFilterOrder'),
                selected: !!filters?.orderIds?.[0],
              },
              {
                name: 'status',
                type: FiltersType.select,
                filterOptions: [{ label: '', value: '' }, ...filtersStatusesMapped],
                label: t('ledgerFilterStatus'),
                selected: !!filters?.statuses?.[0],
              },
              {
                name: 'amount',
                type: FiltersType.rangeNumber,
                label: t('ledgerFilterAmount'),
                selected: !!filters?.amount,
              },
              {
                name: 'date',
                type: FiltersType.rangeDate,
                label: t('ledgerFilterDate'),
                selected: !!filters?.createdAt,
              },
              {
                name: 'stripePayoutId',
                type: FiltersType.inputText,
                label: t('ledgerFilterStripePayoutId'),
                selected: !!filters?.stripePayoutIds?.[0],
                initialValue: filters?.stripePayoutIds?.[0],
              },
            ],
            onFilter: handleFilter,
          }}
          header={
            <div className="ledger-header">
              <h2>{t('ledgerTransactionsTitle')}</h2>
              <Button
                className="secondary export-action"
                icon={<FaDownload />}
                isLoading={loadingExport}
                label={t('ledgerExportButton')}
                onClick={onExportCreate}
              />
            </div>
          }
        />
      </Card>
      <Dialog
        isOpen={isModalConfirmVisible}
        modal={true}
        title={t('ledgerPayoutModalTitle')}
        onClose={() => setIsModalConfirmVisible(false)}
      >
        {bankName && bic && ibanFourLastDigits ? (
          <>
            <div className="ledger bank-account-confirm">
              <h2>{t('ledgerPayoutModalAmount', { amount: `${(payoutAvailableNow / 100).toFixed(2)}€` })}</h2>
              <p>{bankName}</p>
              <span>
                {bic} - ****{ibanFourLastDigits}
              </span>
            </div>
            <div className="ledger bank-account-actions">
              <Button
                className="action cancel-payout-action secondary"
                label={t('ledgerPayoutButtonCancel')}
                onClick={() => setIsModalConfirmVisible(false)}
              />
              <Button
                className="action trigger-payout-action primary"
                disabled={isPayoutButtonDisabled}
                label={t('ledgerPayoutButton')}
                onClick={onPayoutCreate}
              />
            </div>
          </>
        ) : (
          <>
            <div className="ledger bank-account-confirm">
              <h2>{t('ledgerPayoutModalNoBankAccount')}</h2>
            </div>
            <div className="ledger bank-account-actions">
              <Button
                className="action cancel-payout-action secondary"
                label={t('ledgerPayoutButtonCancel')}
                onClick={() => setIsModalConfirmVisible(false)}
              />
              <Button
                className="action trigger-payout-action primary"
                href="/account?tab=banking"
                label={t('ledgerPayoutButtonAddBankAccount')}
              />
            </div>
          </>
        )}
      </Dialog>
    </ViewContainer>
  )
}

export default Payout
