import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { setToLocalStorage } from '@/helpers/localstorage'

import { useChatBoxContext } from '../../../ChatBoxProvider'

export const ChatJoinItemsSettings = memo(() => {
  const { t } = useTranslation()
  const { displayJoinedItems, setDisplayJoinedItems } = useChatBoxContext()

  const handleDisplayJoinedItemsChange = useCallback(() => {
    const value = !displayJoinedItems
    setDisplayJoinedItems(value)
    setToLocalStorage('display_joined_items', value ? 'true' : 'false')
  }, [displayJoinedItems])

  return (
    <div className="chat-join-items-settings">
      <Field
        checked={displayJoinedItems}
        className="chat-display-joined-items"
        label={t('chatDisplayJoinedItems')}
        name="display-joined-items"
        type="checkbox"
        onChange={handleDisplayJoinedItemsChange}
      />
    </div>
  )
})
