import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '@/components/ui/Loader/Loader'
import { UserList } from '@/components/UserList/UserList'
import { useUser } from '@/contexts/user/User.context'
import { useBanUsers } from '@/modules/ban-hammer/hooks/use-ban-users'
import { useSellerModerators } from '@/modules/moderators/hooks/use-seller-moderators'

import type { Viewer } from '../../../Show/components/ChatBox/chatbox.helper'

export enum UserListType {
  moderators = 'moderators',
  bannedUsers = 'bannedUsers',
}

const Moderation = () => {
  const { t } = useTranslation()

  const [bannedFromSellerUsers, setBannedFromSellerUsers] = useState<Viewer[]>([])

  const { user, isLoading } = useUser()
  const { id: userId } = user || {}

  const { bannedFromSellerUserList } = useBanUsers(userId)
  const { moderators } = useSellerModerators()

  useEffect(() => {
    if (bannedFromSellerUserList?.node.__typename === 'User') {
      setBannedFromSellerUsers(
        bannedFromSellerUserList.node.bannedUsersFromSeller.map((bannedUser: any) => ({
          ...bannedUser,
          banType: 'banFromSeller',
        }))
      )
    }
  }, [bannedFromSellerUserList])

  if (isLoading) return <Loader />
  return (
    <div className="account-moderation">
      {userId && (
        <>
          <div className="account-moderation-moderators">
            <UserList
              sellerId={userId}
              subtitle={t('Moderators assigned for all shows')}
              title={t('Moderators')}
              type={UserListType.moderators}
              users={moderators}
            />
          </div>
          <div className="account-moderation-banned">
            <UserList
              sellerId={userId}
              subtitle={t('These users are banned from all your shows')}
              title={t('Banned users')}
              type={UserListType.bannedUsers}
              users={bannedFromSellerUsers}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Moderation
