import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import Card from '@/components/ui/Card/Card'
import Link from '@/components/ui/Link/Link'
import Loader from '@/components/ui/Loader/Loader'
import Table from '@/components/ui/Table/Table'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderPaymentStatus, OrderStatus, type ShipmentStatus } from '@/network/graphql/types.generated'

import { useGetShipmentByIdQuery } from '../operations.generated'
import Copyable from '../Tables/components/Copyable/Copyable'
import { ShipmentStatusTag } from '../Tables/components/ShipmentStatus/ShipmentStatus'
import { ShippingAddressColumn } from '../Tables/components/ShippingAddressColumn/ShippingAddressColumn'
import UsernameAndContactColumn from '../Tables/components/UsernameAndContactColumn/UsernameAndContactColumn'

import { CancelShippingFee } from './components/CancelShippingFee/CancelShippingFee'
import { CreateLabelFromShipmentButton } from './components/CreateLabelFromShipmentButton/CreateLabelFromShipmentButton'
import { CreatePackingSlipButton } from './components/CreatePackingSlipButton/CreatePackingSlipButton'
import { RefundOrderAfterShipped } from './components/RefundOrderAfterShipped/RefundOrderAfterShipped'

import type { GetShipmentByIdQuery } from '../operations.generated'
import type { TableColumns } from '@/components/ui/Table/Table'

import './Details.scss'

interface ShipmentDetailsProps {
  shipmentId: string
}

type Columns = TableColumns<
  Extract<GetShipmentByIdQuery['node'], { __typename?: 'Shipment' }>['orderedProducts']['edges'][0]['node']
>

const ShipmentDetails = (props: ShipmentDetailsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const currencyFormatter = useCurrencyFormatter()

  const { shipmentId } = props
  const { data, loading, refetch } = useGetShipmentByIdQuery({
    skip: !shipmentId,
    variables: {
      nodeId: shipmentId ?? '',
    },
  })

  const shipment = data?.node.__typename === 'Shipment' ? data?.node : undefined

  const totalOrderedProductsAmount = shipment?.totalOrderedProductsAmount || 0
  const totalShippingAmount =
    shipment?.orderedProducts.edges
      .filter((item) => !item.node.order.shippingFeesCancellationRequestedAt)
      .map((item) => item.node.order.shippingAmountInCurrencyCents)
      .reduce((accumulator, current) => accumulator + current, 0) || 0
  const totalFee =
    shipment?.orderedProducts.edges
      .map((item) => item.node.order.feeAmountInCurrencyCents)
      .reduce((accumulator, current) => accumulator + current, 0) || 0
  const totalAmount = totalOrderedProductsAmount + totalShippingAmount - totalFee
  const easyShippingAmount = shipment?.easyShippingAmount || 0
  const totalAfterShipping = totalAmount - easyShippingAmount

  const handleGoBack = () => {
    navigate('/shipments?tab=shipped')
  }

  if (!shipment && !loading) {
    handleGoBack()
    return null
  }

  const columns: Columns = [
    {
      header: t('shipmentDetailTableHeaderProduct'),
      id: 'product',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { id, name, images } = record
        const imageUrl = images?.[0]?.webPUrl || '/badges/empty-pic.png'

        return (
          <div key={`product-${id}`} className="shipment-detail-product">
            <div>
              <div className="shipment-detail-product-image">
                <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
              </div>
              <div className="shipment-detail-product-details">
                <p>{name}</p>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      header: t('shipmentDetailTableHeaderCreatedAt'),
      id: 'createdAt',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { createdAt } = record.order

        return <p className="shipments-date">{dayjs(createdAt).format('DD/MM/YYYY')}</p>
      },
    },
    {
      header: t('shippedShipmentsTableHeaderStatus'),
      id: 'status',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { paymentStatus, status } = record.order

        if (status === OrderStatus.Refunded) {
          return <Tag content={t('canceledOrdersTableStatusRefunded')} status={TagStatus.SECONDARY} />
        }

        switch (paymentStatus) {
          case OrderPaymentStatus.Success:
            return <Tag content={t('orderPaymentStatusSuccess')} status={TagStatus.SUCCESS} />
          case OrderPaymentStatus.Failed:
            return <Tag content={t('orderPaymentStatusFailed')} status={TagStatus.DANGER} />
          case OrderPaymentStatus.InProgress:
            return <Tag content={t('orderPaymentStatusInProgress')} status={TagStatus.WARNING} />
          case OrderPaymentStatus.PendingSepaDebit:
            return <Tag content={t('orderPaymentStatusInProgress')} status={TagStatus.WARNING} />
        }
      },
    },
    {
      header: t('shipmentDetailTableHeaderAmount'),
      id: 'amount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { amountInCurrencyCents, currency } = record.order

        return amountInCurrencyCents ? currencyFormatter(amountInCurrencyCents, currency) : '-'
      },
    },
    {
      header: t('shipmentDetailTableHeaderShippingFees'),
      id: 'shippingFees',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { shippingAmountInCurrencyCents, currency, shippingFeesCancellationRequestedAt, id, status } =
          record.order

        const isCancelled = !!shippingFeesCancellationRequestedAt

        return (
          <div className="column-shipping-fee">
            <p className={isCancelled ? 'shipping-fees-refunded' : ''}></p>
            {shippingAmountInCurrencyCents ? currencyFormatter(shippingAmountInCurrencyCents, currency) : '-'}
            <CancelShippingFee
              cancellationRequestedAt={shippingFeesCancellationRequestedAt}
              currency={currency}
              orderId={id}
              shippingFeeAmount={shippingAmountInCurrencyCents}
              status={status}
              onCompleted={refetch}
            />
          </div>
        )
      },
    },
    {
      header: t('shipmentDetailTableHeaderFees'),
      id: 'fees',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original
        const { feeAmountInCurrencyCents, currency } = record.order
        return feeAmountInCurrencyCents ? currencyFormatter(feeAmountInCurrencyCents, currency) : '-'
      },
    },
    {
      header: t('shipmentListRefundedAmountLabel'),
      id: 'refundedAmount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original.order
        const { currency, refundedAmount } = record
        return refundedAmount === 0 ? '-' : currencyFormatter(refundedAmount, currency)
      },
    },
    {
      header: t('shipmentListRefundedCommissionAmountLabel'),
      id: 'refundedCommissionAmount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original.order
        const { currency, refundedCommissionAmount } = record
        return refundedCommissionAmount === 0 ? '-' : currencyFormatter(refundedCommissionAmount, currency)
      },
    },
    {
      header: t('shipmentDetailTableTotalAmount'),
      id: 'total',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const record = item.row.original.order
        const { currency, netAmount } = record
        return currencyFormatter(netAmount, currency)
      },
    },
    {
      header: t('shipmentDetailTableHeaderAction'),
      id: 'action',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item) => {
        const order = item.row.original?.order
        if (!order) {
          return null
        }
        const { status, amountInCurrencyCents = 0, shippingAmountInCurrencyCents = 0, refundedAmount = 0 } = order
        const isFullyRefunded = amountInCurrencyCents + shippingAmountInCurrencyCents === refundedAmount
        if (status === OrderStatus.Refunded && isFullyRefunded) {
          return null
        }
        return <RefundOrderAfterShipped order={order} onSubmittedCallback={refetch} />
      },
    },
  ]

  return (
    <div className="shipments-details details-page">
      <div className="shipments-detail-links detail-links">
        <Link onClick={handleGoBack}>{t('ordersTabSwitcherShippedLabel')}</Link>
        <FaCaretRight />
        <p>{t('shipmentDetail')}</p>
      </div>

      {!shipment || loading ? (
        <Loader />
      ) : (
        <>
          <Card className="shipment-detail-header detail-header">
            <h2 className="title">
              {t('shipmentDetailheaderTitle', { id: `#${shipmentId.split('|')[1].slice(0, 8)}` })}
            </h2>
            <div className="shipment-detail-header-actions detail-header-actions">
              <ShipmentStatusTag status={shipment.status as ShipmentStatus} />
              <CreatePackingSlipButton shipmentId={shipment.id} />
              <CreateLabelFromShipmentButton shipment={shipment} onCompleted={refetch} />
            </div>
          </Card>

          <div className="shipment-detail-infos detail-infos">
            <Card>
              <h3 className="title">{t('shipmentDetailInfoAmountsTitle')}</h3>

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalProducts')}</p>
                <p>
                  {totalOrderedProductsAmount ? currencyFormatter(totalOrderedProductsAmount, shipment.currency) : '-'}
                </p>
              </div>

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalShipping')}</p>
                <p>{totalShippingAmount ? currencyFormatter(totalShippingAmount, shipment.currency) : '-'}</p>
              </div>

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalFees')}</p>
                <p>- {totalFee ? currencyFormatter(totalFee, shipment.currency) : '-'}</p>
              </div>

              <hr />
              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalAmount')}</p>
                <p>{totalAmount ? currencyFormatter(totalAmount, shipment.currency) : '-'}</p>
              </div>

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalShippingFees')}</p>
                <p>- {easyShippingAmount ? currencyFormatter(easyShippingAmount, shipment.currency) : '-'}</p>
              </div>

              <hr />

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalAfterShipping')}</p>
                <p>{totalAfterShipping ? currencyFormatter(totalAfterShipping, shipment.currency) : '-'}</p>
              </div>
            </Card>

            <Card>
              <h3 className="title">{t('shipmentDetailInfoShipmentTitle')}</h3>
              <div className="detail-info">
                <p>{t('shipmentDetailInfoUser')}</p>
                <UsernameAndContactColumn
                  avatarUrl={shipment.customer.avatarWebPUrl}
                  createdAt={shipment.createdAt}
                  userId={shipment.customer.id}
                  username={shipment.customer.username}
                />
              </div>
              <div className="detail-info">
                <p>{t('shipmentDetailInfoFullName')}</p>
                <p>{shipment.shippingAddress.name}</p>
              </div>
              <div className="detail-info">
                <p>{t('shipmentDetailInfoAddress')}</p>
                <ShippingAddressColumn {...shipment.shippingAddress} />
              </div>
              <hr />

              <div className="detail-info">
                <p>{t('shipmentDetailInfoTrackingNumber')}</p>
                <div>
                  {shipment.trackingNumber ? (
                    <Copyable
                      messageSuccess={t('copyableTrackingNumberTextMessageSuccess')}
                      value={shipment.trackingNumber}
                    />
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
              <div className="detail-info">
                <p>{t('shipmentDetailInfoTotalItems')}</p>
                <p>{shipment.orderedProducts.edges.length}</p>
              </div>
            </Card>
          </div>

          <Card className="shipment-detail-items detail-items">
            <Table
              columns={columns}
              data={shipment.orderedProducts.edges.map((x) => x?.node)}
              header={<h3 className="title">{t('shipmentDetailArticlesTableTitle')}</h3>}
            />
          </Card>
        </>
      )}
    </div>
  )
}

export default memo(ShipmentDetails)
