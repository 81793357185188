import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from '@/components/ui/EmptyState/EmptyState'
import Loader from '@/components/ui/Loader/Loader'
import { useGetShowViewersQuery } from '@/modules/show/graphql/operations.generated'

import { useChatBoxContext } from '../../ChatBoxProvider'

import { ShowViewerItem } from './ShowViewerItem'

export const ShowViewersList = () => {
  const { t } = useTranslation()

  const { moderators, showId } = useChatBoxContext()

  const { data: viewers, loading: viewersLoading } = useGetShowViewersQuery({
    skip: !showId,
    variables: { first: 2000, nodeId: `Show|${showId}` },
    pollInterval: 60000,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'cache-first',
  })

  const computedViewers = useMemo(
    () =>
      viewers?.node?.__typename === 'Show' && viewers?.node?.presentUsers?.edges?.length
        ? viewers.node.presentUsers.edges.map((e) => e.node)
        : [],
    [viewers]
  )

  if (viewersLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div className="h-full overflow-auto chat-viewer-content">
      {moderators && moderators.length > 0 && (
        <div className="mt-2 mb-4">
          <p className="mb-2">{t('myModerators')}</p>

          <div className="flex flex-column">
            {moderators.map((viewer) => (
              <ShowViewerItem key={viewer.id} isModerator={true} viewer={viewer} viewers={computedViewers} />
            ))}
          </div>
        </div>
      )}

      <div className="mt-2">
        <div className="flex items-center">
          <p className="mb-2">{t('viewersInTheShow')}</p>
        </div>

        {computedViewers.filter(
          (v) => moderators && moderators.findIndex((moderator) => moderator.legacyId === v.legacyId) === -1
        ).length === 0 && (
          <EmptyState subtitle={t('showViewerListNoViewersContent')} title={t('showViewerListNoViewersTitle')} />
        )}

        <div className="flex flex-column">
          {computedViewers
            .filter((v) => moderators && moderators.findIndex((moderator) => moderator.legacyId === v.legacyId) === -1)
            .map((viewer) => (
              <ShowViewerItem key={viewer.id} isModerator={false} viewer={viewer} viewers={computedViewers} />
            ))}
        </div>
      </div>
    </div>
  )
}
