import { useEffect, useState } from 'react'

export interface TabsProps {
  onChange?: (activeTabKey: string) => void
  activeTabIndex?: number
  noMargin?: boolean
  tabs: {
    key: string
    title: string
    content: React.ReactNode
  }[]
  extraContent?: React.ReactNode
}

const Tabs = ({ tabs, onChange, activeTabIndex, noMargin, extraContent }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0)
  const activeTabContent = tabs[activeTab].content
  const handleTabClick = (index: number) => setActiveTab(index)

  useEffect(() => onChange?.(tabs[activeTab].key), [onChange, activeTab])

  useEffect(() => {
    if (!activeTabIndex) return
    if (activeTabIndex < 0) handleTabClick(0)
    if (activeTabIndex > tabs.length) handleTabClick(0)
  }, [activeTabIndex])

  if (!tabs.length) return null

  const classNames = `tabs flex ${noMargin ? '' : 'mb-4'}`

  return (
    <>
      <div className={classNames}>
        {tabs.map(({ title }, index) => (
          <button
            key={title}
            className={`flex items-center justify-center px-3 py-1 rounded-full animate duration-200
             ${activeTab === index && 'bg-slate-200'}`}
            onClick={() => handleTabClick(tabs.findIndex((tab) => tab.title === title))}
          >
            {title}
          </button>
        ))}
        <div className="flex items-center px-3">{extraContent}</div>
      </div>

      {activeTabContent}
    </>
  )
}

export default Tabs
