import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { trackError } from '@/util/sentry'

import { useReportIssueFromSellerMutation } from './operations.generated'

import type { ChangeEvent } from 'react'

import './Contact.scss'

type SelectChangeEvent = ChangeEvent<HTMLSelectElement>
type InputChangeEvent = ChangeEvent<HTMLTextAreaElement>

enum CONTACT_SUJECTS {
  seller_shipping = 'seller_shipping',
  seller_return = 'seller_return',
  seller_finance = 'seller_finance',
  seller_mediation = 'seller_mediation',
  seller_show = 'seller_show',
  seller_bug = 'seller_bug',
  seller_other = 'seller_other',
}

enum CONTACT_SUJECTS_LABELS {
  seller_shipping = 'contactUsShippingIssue',
  seller_return = 'contactUsReturnIssue',
  seller_finance = 'contactUsFinanceIssue',
  seller_mediation = 'contactUsMediationIssue',
  seller_show = 'contactUsShowIssue',
  seller_bug = 'contactUsBugIssue',
  seller_other = 'contactUsOtherIssue',
}

export const Contact = () => {
  const { t } = useTranslation()

  useDocumentTitle(t('sideMenuContactUsTitle'))

  const [loading, setLoading] = useState<boolean>(false)
  const [subject, setSubject] = useState<CONTACT_SUJECTS | ''>('')
  const [comment, setComment] = useState<string>('')

  const [reportIssueFromSeller] = useReportIssueFromSellerMutation()

  const subjectOptions = useMemo(
    () => [
      {
        label: '',
        value: '',
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_shipping),
        value: CONTACT_SUJECTS.seller_shipping,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_return),
        value: CONTACT_SUJECTS.seller_return,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_finance),
        value: CONTACT_SUJECTS.seller_finance,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_mediation),
        value: CONTACT_SUJECTS.seller_mediation,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_show),
        value: CONTACT_SUJECTS.seller_show,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_bug),
        value: CONTACT_SUJECTS.seller_bug,
      },
      {
        label: t(CONTACT_SUJECTS_LABELS.seller_other),
        value: CONTACT_SUJECTS.seller_other,
      },
    ],
    [t]
  )

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const input = {
      tag: subject,
      subject: t(CONTACT_SUJECTS_LABELS[subject as keyof typeof CONTACT_SUJECTS]),
      comment,
    }
    await reportIssueFromSeller({
      variables: {
        input,
      },
      onCompleted: () => {
        notificationSuccess(t('successfullySentContact'))
        setSubject('')
        setComment('')
      },
      onError: (error) => {
        notificationDanger(error.message)
        trackError(error, { meta: { ...input, scope: 'Contact.reportIssueFromSeller' } })
      },
    })
    setLoading(false)
  }, [subject, comment])

  return (
    <ViewContainer id="contact" leftContent={t('sideMenuContactUsTitle')}>
      <Card className="contact-card">
        <h2 className="title">{t('contactUsTitle')}</h2>
        <Form className="contact-form" onSubmit={handleSubmit}>
          <div className="contact-form-fields">
            <Field
              label={t('contactUsSubject')}
              name="subject"
              options={subjectOptions}
              type="select"
              value={subject}
              required
              onChange={(e: SelectChangeEvent) => setSubject(e.target.value as CONTACT_SUJECTS)}
            />
            <Field
              label={t('contactUsComment')}
              name="comment"
              rows={10}
              type="textarea"
              value={comment}
              required
              onChange={(e: InputChangeEvent) => setComment(e.target.value)}
            />
          </div>
          <div className="contact-form-submit">
            <Button
              className="validate-action primary"
              disabled={!subject || !comment}
              isLoading={loading}
              label={t('commonSend')}
              type="submit"
            />
          </div>
        </Form>
      </Card>
    </ViewContainer>
  )
}
