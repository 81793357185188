import { useCallback, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useChat } from '@/views/Show/components/ChatBox/hooks/useChat'

const CHAT_COMMENT_MAX_LENGTH = 100

type ChatFormProps = {
  showId: number // TODO: this should probably be retrieved from a ShowContext
}

const ChatForm = (props: ChatFormProps) => {
  const { showId } = props

  const { t } = useTranslation()
  const { handleAddComment } = useChat(showId)

  const [message, setMessage] = useState('')

  const isMessageValid = message.length > 0 && message.length < CHAT_COMMENT_MAX_LENGTH

  const handleMessageUpdate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event?.target.value)
  }, [])

  const handleSendMessage = useCallback(() => {
    if (!isMessageValid) {
      return
    }

    handleAddComment(message)
    setMessage('')
  }, [handleAddComment, isMessageValid, message])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSendMessage()
      }
    },
    [handleSendMessage]
  )

  return (
    // TODO: the following should be properly wrapped in a <Form> component
    <input
      className="chat-input"
      maxLength={CHAT_COMMENT_MAX_LENGTH}
      minLength={1}
      name="inputContent"
      placeholder={t('showCommentsInputPlaceholder')}
      type="text"
      value={message}
      onChange={handleMessageUpdate}
      onKeyDown={handleKeyDown}
    />
  )
}

export default ChatForm
