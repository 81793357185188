import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type ProductCardGradingServiceFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

export const PRODUCT_CARD_GRADING_SERVICE_UNGRADED = 'ungraded'

export const productCardGradingServicesOptions = [
  { value: '' },
  { value: 'ungraded', label: 'Ungraded/Loose' },
  { value: 'psa', label: 'PSA (Professional Sports Authenticator)' },
  { value: 'pca', label: 'PCA (Professional Card Authentication)' },
  { value: 'bgs', label: 'BGS (Beckett Grading Services)' },
  { value: 'cgc', label: 'CGC (Certified Guaranty Company)' },
  { value: 'sgs', label: 'SGS' },
  { value: 'ccc', label: 'CCC' },
  { value: 'collect-aura', label: 'Collect Aura' },
  { value: 'jsa', label: 'JSA (James Spence Authentication)' },
  { value: 'sfg', label: 'SFG' },
  { value: 'other', label: 'Other' },
]

const ProductCardGradingServiceField = (props: ProductCardGradingServiceFieldProps) => {
  const { value, onChange, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      disabled={disabled}
      label={t('productFormCardGradingServiceLabel')}
      name="card-grading-service"
      options={productCardGradingServicesOptions}
      placeholder={t('productFormCardGradingServicePlaceholder')}
      type="select"
      value={value}
      onChange={onChange}
    />
  )
}

export default ProductCardGradingServiceField
