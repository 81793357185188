import * as Types from '../../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSellerShippingAddressMutationVariables = Types.Exact<{
  input: Types.SetSellerShippingAddressInput;
}>;


export type SetSellerShippingAddressMutation = { __typename?: 'Mutation', setSellerShippingAddress: { __typename?: 'SetSellerShippingAddressPayload', city: string, countryIso2Code: any, email: string, firstName: string, houseNumber?: string | null, lastName: string, phoneNumber: string, street: string, street2?: string | null, zipCode: string } };


export const SetSellerShippingAddressDocument = gql`
    mutation SetSellerShippingAddress($input: SetSellerShippingAddressInput!) {
  setSellerShippingAddress(input: $input) {
    city
    countryIso2Code
    email
    firstName
    houseNumber
    lastName
    phoneNumber
    street
    street2
    zipCode
  }
}
    `;
export type SetSellerShippingAddressMutationFn = Apollo.MutationFunction<SetSellerShippingAddressMutation, SetSellerShippingAddressMutationVariables>;

/**
 * __useSetSellerShippingAddressMutation__
 *
 * To run a mutation, you first call `useSetSellerShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSellerShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSellerShippingAddressMutation, { data, loading, error }] = useSetSellerShippingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSellerShippingAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetSellerShippingAddressMutation, SetSellerShippingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSellerShippingAddressMutation, SetSellerShippingAddressMutationVariables>(SetSellerShippingAddressDocument, options);
      }
export type SetSellerShippingAddressMutationHookResult = ReturnType<typeof useSetSellerShippingAddressMutation>;
export type SetSellerShippingAddressMutationResult = Apollo.MutationResult<SetSellerShippingAddressMutation>;
export type SetSellerShippingAddressMutationOptions = Apollo.BaseMutationOptions<SetSellerShippingAddressMutation, SetSellerShippingAddressMutationVariables>;