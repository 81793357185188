import { Input, Form, Select } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import { setToLocalStorage } from '@/helpers/localstorage'

import InputLabel from '../../../../components/InputLabel'

import taxIdRegistrars from './data/taxIdRegistrars'

import type { Country, Value as Phone } from 'react-phone-number-input'

export const CountrySpecificInputs: Record<string, CountrySpecificInput[]> = {
  DE: [
    {
      name: 'lucidId',
      title: 'signupFormLabelLucidId',
      isMandatory: false,
      type: 'input',
    },
    {
      name: 'taxIdRegistrar',
      title: 'signupFormLabelTaxIdRegistrar',
      isMandatory: true,
      type: 'select',
      selectData: taxIdRegistrars,
    },
  ],
}

type CompanySlideProps = {
  companyInformation: CompanyInformation
  setCompanyInformation: (information: CompanyInformation) => void
  setFormDisabled: (value: boolean) => void
}

function CompanyForm(props: CompanySlideProps) {
  const { companyInformation, setCompanyInformation, setFormDisabled } = props

  const { t } = useTranslation()

  const { name, address, city, postalCode, country, email, phone, state, vatId, taxId, documentFront, documentBack } =
    companyInformation || {}
  const phoneIsValid = phone && isValidPhoneNumber(phone)

  useEffect(() => {
    setFormDisabled(!isFormComplete())
  }, [companyInformation])

  const isFormComplete = () => {
    return (
      name &&
      address &&
      postalCode &&
      city &&
      country &&
      phone &&
      taxId &&
      documentFront &&
      documentBack &&
      countrySpecificFieldsAreFilled()
    )
  }

  const countrySpecificFieldsAreFilled = () => {
    const fields = CountrySpecificInputs[country] ?? []
    return fields.every((input) => {
      if (!input.isMandatory) return true
      return Boolean(companyInformation[input.name])
    })
  }

  const handleFileSelect = (data: FileList | null, type: string) => {
    if (data?.[0]) {
      if (type === 'document-front') setCompanyInformation({ ...companyInformation, documentFront: data[0] })
      else if (type === 'document-back') setCompanyInformation({ ...companyInformation, documentBack: data[0] })
    }
  }

  const setCompanyAttribute = (key: string, value: any) => {
    const updateCompanyInformation = { ...companyInformation, [key]: value }
    setCompanyInformation(updateCompanyInformation)
    setToLocalStorage('onboardingCompanyInfo', JSON.stringify(updateCompanyInformation))
  }

  const computeCountrySpecificFields: () => Array<CountrySpecificInput> = () => {
    return CountrySpecificInputs[country] || []
  }

  const { Option } = Select

  return (
    <div>
      <Form initialValues={{ remember: true }} layout="vertical" name="basic" size="large">
        <Form.Item>
          <InputLabel title={t('signupFormLabelLegalName')} mandatory />
          <Input value={name} onChange={({ target }) => setCompanyAttribute('name', target.value)} />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'requis' }]}>
          <InputLabel title={t('signupFormLabelAddress')} mandatory />
          <Input value={address} onChange={({ target }) => setCompanyAttribute('address', target.value)} />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'requis' }]}>
          <InputLabel title={t('signupFormLabelCity')} mandatory />
          <Input value={city} onChange={({ target }) => setCompanyAttribute('city', target.value)} />
        </Form.Item>

        <Form.Item rules={[{ required: true, message: 'requis' }]}>
          <InputLabel title={t('signupFormLabelZipcode')} mandatory />
          <Input value={postalCode} onChange={({ target }) => setCompanyAttribute('postalCode', target.value)} />
        </Form.Item>

        <Form.Item>
          <InputLabel title={t('signupFormLabelCountry')} mandatory />
          <Select
            defaultValue={country}
            optionFilterProp="children"
            showSearch={true}
            style={{ width: '100%' }}
            filterOption={(input, option) => {
              const { children } = option || {}
              return (typeof children === 'string' ? children : '').toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(value) => {
              setCompanyAttribute('country', value)
            }}
          >
            <Option value="AT">{t('commonAustria')}</Option>
            <Option value="BE">{t('commonBelgium')}</Option>
            <Option value="DE">{t('commonGermany')}</Option>
            <Option value="FR">{t('commonFrance')}</Option>
            {/* <Option value="GB">{t('commonGreatBritain')}</Option> */}
            {/* <Option value="LU">{t('commonLuxembourg')}</Option> */}
            {/* <Option value="NL">{t('commonNetherlands')}</Option> */}
            {/* <Option value="ES">{t('commonSpain')}</Option> */}
          </Select>
        </Form.Item>
        <Form.Item>
          <InputLabel title={t('signupFormLabelEmail')} mandatory />

          <Input value={email} onChange={({ target }) => setCompanyAttribute('email', target.value)} />
        </Form.Item>

        <Form.Item
          help={!phoneIsValid && phone && t('signupFormLabelInvalidPhoneNumberError')}
          validateStatus={!phoneIsValid ? 'error' : undefined}
        >
          <InputLabel title={t('signupFormLabelPhoneNumber')} mandatory />
          <PhoneInput
            defaultCountry={country as Country}
            value={phone as Phone}
            onChange={(value) => setCompanyAttribute('phone', value)}
          />
        </Form.Item>

        <Form.Item>
          <InputLabel title={t('signupFormLabelRegionState')} />

          <Input value={state} onChange={({ target }) => setCompanyAttribute('state', target.value)} />
        </Form.Item>

        <Form.Item>
          <InputLabel title={t('signupFormLabelVatId')} />

          <Input value={vatId} onChange={({ target }) => setCompanyAttribute('vatId', target.value)} />
        </Form.Item>

        <Form.Item>
          <InputLabel title={t('signupFormLabelTaxId')} mandatory />
          <Input value={taxId} onChange={({ target }) => setCompanyAttribute('taxId', target.value)} />
        </Form.Item>

        {computeCountrySpecificFields().map((input: CountrySpecificInput) => {
          if (input.type === 'select')
            return (
              <Form.Item>
                <InputLabel mandatory={input.isMandatory} title={t(input.title)} />
                <Select allowClear onChange={(value) => setCompanyAttribute(input.name, value)}>
                  {input.selectData?.map((value) => <Option value={value}>{value}</Option>)}
                </Select>
              </Form.Item>
            )
          return (
            <Form.Item>
              <InputLabel mandatory={input.isMandatory} title={t(input.title)} />
              <Input
                value={companyInformation[input.name]}
                onChange={({ target }) => setCompanyAttribute(input.name, target.value)}
              />
            </Form.Item>
          )
        })}

        <Form.Item rules={[{ required: true, message: 'requis' }]}>
          <InputLabel
            noticePopover={{ content: t('signupFormHelpNoticeDocumentList') }}
            title={t('signupFormLabelCompanyDocumentFront')}
          ></InputLabel>
          <Input
            accept=".jpeg,.jpg,.png,.pdf,.heic,.heif"
            id="document-front"
            size="small"
            style={{ padding: 5 }}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files, 'document-front')}
          />
        </Form.Item>
        <Form.Item>
          <InputLabel
            noticePopover={{ content: t('signupFormHelpNoticeDocumentList') }}
            title={t('signupFormLabelCompanyDocumentBack')}
          ></InputLabel>
          <Input
            accept=".jpeg,.jpg,.png,.pdf,.heic,.heif"
            id="document-back"
            size="small"
            style={{ padding: 5 }}
            type="file"
            onChange={(e) => handleFileSelect(e.target.files, 'document-back')}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default CompanyForm
