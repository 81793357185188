import { useTranslation } from 'react-i18next'

import Countdown from '@/components/Countdown'
import Card from '@/components/ui/Card/Card'
import { ShowGiveawayStatus } from '@/network/graphql/types.generated'
import GiveawayParticipantsCount from '@/views/Show/components/CurrentGiveaway/GiveawayParticipantsCount/GiveawayParticipantsCount'

import type { GiveawayBasicFragmentFragment, StartGiveawayInShowMutation } from '@/views/Show/operations.generated'

import './CurrentGiveaway.scss'

type GiveawayProps = {
  giveawayStartData: StartGiveawayInShowMutation | null | undefined
  currentGiveaway?: GiveawayBasicFragmentFragment | null
  // TODO: remove giveawayStartData and currentGiveaway and instead pass 'productName', 'endDate', 'winnerUsername', 'status', and 'participantsCount' as individual props
  showId: number
}

const CurrentGiveaway = (props: GiveawayProps) => {
  const { giveawayStartData, currentGiveaway, showId } = props
  const { t } = useTranslation()

  if (!currentGiveaway) return null

  const { serverTime, status, endAt, product } = currentGiveaway
  const { endAt: initialEndAt, product: initialProduct } = giveawayStartData?.startGiveawayInShow?.giveaway || {}

  const { name: productName } = product || initialProduct || {}
  const winnerUsername = currentGiveaway?.winner?.username
  const endDate = endAt ? new Date(endAt) : initialEndAt ? new Date(initialEndAt) : null

  const displayedStatus = status === ShowGiveawayStatus.Closed ? 'done' : 'in-progress'
  const statusClassName = `is-${displayedStatus}`

  return (
    <Card className="current-activity current-giveaway" title={t('showGiveawayCardTitle')}>
      {product && (
        <dl>
          <dt className="product-name-title">{t('showAuctionCardStatisticProductTitle')}</dt>
          <dd className="product-name">
            <span className="value">{productName}</span>
          </dd>

          <dt className="remaining-time-title">{t('showAuctionCardStatisticTimeLeft')}</dt>
          <dd className="remaining-time">
            <Countdown endDate={endDate || new Date()} startDate={serverTime} />
          </dd>

          <dt className="winner-username-title">{t('showGiveawayCardStatisticWinner')}</dt>
          <dd className="winner-username">
            <span className="value">{winnerUsername ? `@${winnerUsername}` : ''}</span>
          </dd>

          <dt className="status-title">{t('showCurrentGiveawayStatusLabel')}</dt>
          <dd className={`status ${statusClassName}`}>
            {displayedStatus === 'in-progress' && t('showCurrentActivityItemStatusInProgressLabel')}
            {displayedStatus === 'done' && t('showCurrentActivityItemStatusDoneLabel')}
          </dd>

          <dt className="participants-count-title">{t('showGiveawayCardStatisticParticipants')}</dt>
          <dd className="participants-count">
            {/* TODO: refactor the ParticipantsCount and transform it into a hook that will just return the value */}
            <GiveawayParticipantsCount showId={showId} />
            <span className="unit">&nbsp;{t('showGiveawayParticipantsUnit')}</span>
          </dd>
        </dl>
      )}
    </Card>
  )
}

export default CurrentGiveaway
