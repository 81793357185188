import moment from 'moment'

import getCalendarRules from '@/data/calendarRules'

import type { Show } from '../types'
import type { Moment } from 'moment'

export const getIsDisabled = (slotStart: Moment, shows: Show[], countryId: string, category: number) => {
  const showsOfProvidedCategory = shows?.filter((show) => show.categoryId === category) || []
  const showsOnThisDay = showsOfProvidedCategory.filter((show) =>
    moment(show.startAt).startOf('d').isSame(moment(slotStart).startOf('d'))
  )

  const rules = getCalendarRules(countryId, category)

  if (
    rules.minutesBetweenTwoShows &&
    getConcurrentShowsByStepTime(showsOnThisDay, slotStart, rules.minutesBetweenTwoShows)?.length
  )
    return true

  if (rules.disabledSlots && getIsInADisabledSlots(slotStart, rules.disabledSlots)) return true

  //There will be a maintenance at this time, this code can be removed after the maintenance
  if (moment(slotStart).isBetween(moment('2024-05-22 03:45'), moment('2024-05-22 06:00'))) return true

  return moment(slotStart).isBefore(moment())
}

export const isDayDisabled = (date: Moment | null, countryId: string, categoryId: number) => {
  // disable past days
  if (moment(date).isBefore(moment().startOf('d'))) return true
  // disable in 3 months
  if (!moment(date).isBetween(moment().startOf('day'), moment().add(3, 'months').endOf('d'))) return true

  const rules = getCalendarRules(countryId, categoryId)

  if (rules.disabledDates?.includes(moment(date).format('DD/MM/YYYY'))) return true

  return false
}

export const getHoursSlots = (date: Moment | null, shows: Show[], countryId: string, category: number) => {
  let slotStart = moment(date).startOf('day')
  let unavailable: any = {}
  let i = 0

  do {
    if (getIsDisabled(slotStart, shows, countryId, category)) {
      const startHour = parseInt(slotStart.format('HH'))
      const startQuarter = parseInt(slotStart.format('mm'))

      unavailable = {
        ...unavailable,
        [startHour]: { ...unavailable[startHour], [startQuarter]: 1 },
      }
    }

    i++
    slotStart = moment(slotStart).add(15, 'minutes')
  } while (i !== 0 && moment(slotStart).format('HH:mm') !== '00:00')

  const array: any = []

  Object.keys(unavailable).forEach((hour: any) => {
    if (Object.keys(unavailable[hour]).length === 4) array.push(parseInt(hour))
  })

  return array
}

export const getQuartersSlots = (
  date: Moment | null,
  selectedHour: number,
  shows: Show[],
  countryId: string,
  categoryId: number
) => {
  let slotStart = moment(date).set('hour', selectedHour).set('minutes', 0).set('seconds', 0)
  const unavailable: number[] = []
  let i = 0
  do {
    if (getIsDisabled(slotStart, shows, countryId, categoryId)) {
      const startQuarter = parseInt(slotStart.format('mm'))

      unavailable.push(startQuarter)
    }

    i++
    slotStart = moment(slotStart).add(15, 'minutes')
  } while (i !== 0 && moment(slotStart).format('mm') !== '00')
  return unavailable
}

export const findNextAvailableSlots = (
  from: Moment,
  til: Moment,
  showsToGet: number,
  shows: Show[],
  countryId: string,
  categoryId: number
) => {
  let slotStart = moment(from).set('seconds', 0)
  const available: Moment[] = []

  do {
    if (!getIsDisabled(slotStart, shows, countryId, categoryId)) {
      const hour = parseInt(slotStart.format('HH'))
      const quarter = parseInt(slotStart.format('mm'))
      available.push(moment(from).set('hour', hour).set('minutes', quarter))
    }

    slotStart = moment(slotStart).add(15, 'minutes')
  } while (available.length < showsToGet && moment(slotStart).isBefore(til))

  return available
}

const getConcurrentShowsByStepTime = (shows: Show[], slot: Moment, stepTime: number) => {
  return shows.filter((show) =>
    moment(show.startAt).isBetween(
      moment(slot).subtract(stepTime, 'minutes'),
      moment(slot).add(stepTime, 'minutes'),
      'minutes',
      '()'
    )
  )
}

const getIsInADisabledSlots = (slotStart: Moment, disabledSlots: Record<string, any>[]) => {
  return disabledSlots.some((slot: any) =>
    moment(slotStart).isBetween(
      moment(slotStart).set('hour', slot.start.hours).set('minutes', slot.start.minutes),
      moment(slotStart).set('hour', slot.end.hours).set('minutes', slot.end.minutes)
    )
  )
}
