import Button from '@/components/ui/Button/Button'

import ShowEventApplicationEligibilityCard from './ShowEventApplicationEligibilityCard'
import ShowEventHeader from './ShowEventHeader'

type ShowEventCriterion = {
  isCompleted: boolean
  title: string
}

type ShowEventCriteriaProps = {
  criteria: ShowEventCriterion[]
  canApply: boolean
  onNext: () => void
  headerTitle: string
  headerBanner?: string
  headerDescription?: string
  buttonText: string
  isLoading?: boolean
  startAt?: Date
  endAt?: Date
}

const ShowEventCriteria = ({
  criteria,
  canApply,
  onNext,
  headerTitle,
  headerBanner,
  headerDescription,
  buttonText,
  startAt,
  endAt,
  isLoading = false,
}: ShowEventCriteriaProps) => {
  return (
    <div className="show-event-criteria">
      <ShowEventHeader
        banner={headerBanner}
        description={headerDescription}
        endAt={endAt}
        startAt={startAt}
        title={headerTitle}
      />
      <div className="show-event-criteria-list">
        {criteria.map((criterion) => (
          <ShowEventApplicationEligibilityCard
            key={criterion.title}
            isCompleted={criterion.isCompleted}
            title={criterion.title}
          />
        ))}
      </div>
      <div className="next-slide-container">
        <Button className="primary next-slide-button" disabled={!canApply || isLoading} onClick={onNext}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default ShowEventCriteria
