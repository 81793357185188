import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCalendar, FaPhone } from 'react-icons/fa6'
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'

import { CreateShow } from '@/components/CreateShow/CreateShow'
import CreateShowButton from '@/components/CreateShowButton/CreateShowButton'
import { BreakerCannotCreateShow } from '@/components/SellerTier/BreakerCannotCreateShow/BreakerCannotCreateShow'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import Link from '@/components/ui/Link/Link'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { trackEvent } from '@/util/eventTracker'

import { CSVExportButton } from './components/CSVExportButton'
import { LiveShowCard } from './components/LiveShowCard'
import { ShowsList } from './components/ShowList/ShowsList'
import { useGetSellerShowsAggregatedByMonthQuery } from './operations.generated'

import './Shows.scss'

const FASHION_PARENT_CATEGORY = 'fashion'

export const Shows = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const isQueryParamOpenModal = query.get('action') === 'create'

  const [isShowCreationOpen, setIsShowCreationOpen] = useState<boolean>(isQueryParamOpenModal)
  const [firstSellerConnexionModalIsOpen, setFirstSellerConnexionModalIsOpen] = useState<boolean>(
    !!getFromLocalStorage('isSellerRegistering')
  )

  const { data, loading } = useGetSellerShowsAggregatedByMonthQuery({ nextFetchPolicy: 'cache-only' })
  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { favoriteParentCategory, country } = sellerConfig || {}

  useDocumentTitle(t('sideMenuMyShowsTitle'))

  useEffect(() => {
    if (isQueryParamOpenModal) navigate('/shows')
  }, [isShowCreationOpen])

  const highlightedShow = data?.viewer?.currentAndUpcomingShowsByStartDateAsc.edges[0]?.node

  return (
    <ViewContainer
      isLoading={loading}
      leftContent={t('showsPageHeaderTitle')}
      rightContent={<CreateShowButton handleOnClick={() => setIsShowCreationOpen(true)} />}
    >
      {isShowCreationOpen && <CreateShow isShop={false} onClose={() => setIsShowCreationOpen(false)} />}

      <>
        <div className="w-full mb-4">
          <BreakerCannotCreateShow className="mb-4" />
        </div>

        {highlightedShow && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2">
              {highlightedShow.isBroadcasting ? t('ShowsListShowInProgress') : t('ShowsListNextShow')}
            </h2>

            <LiveShowCard showId={highlightedShow.legacyId} />
          </div>
        )}

        <div className="w-full">
          <p className="text-lg font-semibold mb-2">{t('ShowsListMyShows')}</p>

          <div className="w-full layout-card">
            <div className="p-4 flex items-center justify-between border-b border-slate-200">
              <div className="flex gap-4 items-center">
                <RouterLink
                  className="flex items-center gap-2 bg-slate-100 hover:bg-slate-200 focus:bg-slate-300 active:bg-slate-300 text-slate-700 px-4 py-2 rounded-md"
                  to="/calendar"
                >
                  <FaCalendar size={18} />
                  {t('sideMenuCalendarTitle')}
                </RouterLink>
              </div>
              <CSVExportButton />
            </div>
            <ShowsList aggregatedMonths={data} />
          </div>
        </div>
      </>

      <Dialog
        isOpen={firstSellerConnexionModalIsOpen}
        modal={true}
        title={t('firstSellerConnexionTitle')}
        onClose={() => {
          trackEvent('NEW_SELLER_CLOSED_ONBOARDING_DIALOG', { userId: user?.id })
          removeFromLocalStorage('isSellerRegistering')
          setFirstSellerConnexionModalIsOpen(false)
        }}
      >
        {(favoriteParentCategory && favoriteParentCategory.slug === FASHION_PARENT_CATEGORY) ||
        country?.iso2Code === 'FR' ? (
          <>
            <p>
              <Trans
                i18nKey="firstSellerConnexionFashionHelp"
                components={{
                  1: <Link href={t('firstSellerConnexionUniversityLink')} rel="noreferrer" target="_blank" />,
                }}
              />
            </p>

            <div className="shows-onboarding-fashion-actions">
              <Button
                className="primary request-onboarding-session-action"
                href={t('signUpFormRequestOnboardingSessionLink')}
                icon={<FaPhone />}
                label={t('onboardingModalFormRequestOnboardingSessionLinkLabelFashion')}
                target="_blank"
                onClick={() => {
                  trackEvent('NEW_SELLER_OPENED_ONBOARDING', { userId: user?.id })
                  removeFromLocalStorage('isSellerRegistering')
                  setFirstSellerConnexionModalIsOpen(false)
                  setIsShowCreationOpen(true)
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p>{t('firstSellerConnexionContent')}</p>
            <p>
              <Trans
                i18nKey="firstSellerConnexionHelp"
                components={{
                  1: <Link href={t('firstSellerConnexionUniversityLink')} rel="noreferrer" target="_blank" />,
                }}
              />
            </p>

            <div className="shows-onboarding-actions">
              <Button
                className="secondary request-onboarding-session-action"
                href={t('signUpFormRequestOnboardingSessionLink')}
                icon={<FaPhone />}
                label={t('onboardingModalFormRequestOnboardingSessionLinkLabel')}
                target="_blank"
              />
              <Button
                className="primary request-onboarding-session-action"
                label={t('firstSellerConnexionCTA')}
                onClick={() => {
                  removeFromLocalStorage('isSellerRegistering')
                  setFirstSellerConnexionModalIsOpen(false)
                  setIsShowCreationOpen(true)
                }}
              />
            </div>
          </>
        )}
      </Dialog>
    </ViewContainer>
  )
}
