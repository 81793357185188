import type { TooltipYAlignment, TextAlign } from 'chart.js'

import globalStyle from './../../../styles/_export.module.scss'

export const BAR_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 8,
}

export const LINE_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 8,
  elements: {
    point: {
      radius: 4,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderWidth: 0,
      hoverBorderWidth: 1,
      borderColor: globalStyle.black160,
      hitRadius: 5,
    },
    line: {
      backgroundColor: globalStyle.black160,
      borderColor: globalStyle.black160,
      tension: 0.4,
      borderWidth: 1,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
}

export const CHART_PLUGINS_OPTIONS = {
  legend: {
    display: false,
  },
  title: {
    display: false,
  },
}

export const CHART_TOOLTIP_OPTIONS = {
  caretSize: 0,
  padding: 8,
  yAlign: 'bottom' as TooltipYAlignment,
  titleFont: {
    size: 12,
  },
  titleColor: globalStyle.black120,
  bodyFont: {
    size: 18,
  },
  displayColors: false,
  backgroundColor: globalStyle.white,
  bodyColor: globalStyle.black160,
  borderWidth: 1,
  bodyAlign: 'center' as TextAlign,
  borderColor: globalStyle.black110,
}

export const DEFAULT_BAR_CHART_OPTIONS = {
  ...BAR_CHART_OPTIONS,
  plugins: {
    ...CHART_PLUGINS_OPTIONS,
    tooltip: CHART_TOOLTIP_OPTIONS,
  },
}
