const PollIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5389 20.3076H7.64597C4.93272 20.3076 3.23077 18.3865 3.23077 15.667V8.332C3.23077 5.61246 4.92465 3.69226 7.64597 3.69226H15.431C18.1531 3.69226 19.8462 5.61246 19.8462 8.332V15.667C19.8462 18.3865 18.1531 20.3076 15.4318 20.3076H15.1582"
        stroke="#171514"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        clipRule="evenodd"
        d="M8.80569 16.3555C8.18868 16.3555 7.68842 15.8552 7.68842 15.2382V11.4877C7.68842 10.8706 8.18868 10.3704 8.80569 10.3704C9.42269 10.3704 9.923 10.8706 9.923 11.4877V15.2382C9.923 15.8552 9.42269 16.3555 8.80569 16.3555Z"
        fillRule="evenodd"
        stroke="#171514"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        clipRule="evenodd"
        d="M14.2725 16.3552C13.6554 16.3552 13.1552 15.8549 13.1552 15.238V8.76509C13.1552 8.14808 13.6554 7.64783 14.2725 7.64783C14.8895 7.64783 15.3898 8.14808 15.3898 8.76509V15.238C15.3898 15.8549 14.8895 16.3552 14.2725 16.3552Z"
        fillRule="evenodd"
        stroke="#171514"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
    </svg>
  )
}

export default PollIcon
