import { Big } from 'big.js'

import { TabName } from '@/components/ProductList/types'
import { brands } from '@/data/brands'
import { fashionProductColorsTranslationKeys } from '@/data/fashionProductColors'
import { fashionProductConditionsTranslationKeys } from '@/data/fashionProductConditions'
import { fashionProductGendersTranslationKeys } from '@/data/fashionProductGenders'
import { ProductType, SalesType } from '@/network/graphql/types.generated'
import { ProductCategoryName } from '@/types'
import { trackError } from '@/util/sentry'

import { PRODUCT_FORM_ACTION } from './types'

import type { ProductImage, ProductInputData } from './types'
import type { ParentCategories } from './useParentCategories'
import type { SelectOptions } from '@/components/Form/Field/Select/Select'
import type { GetProductImagesQuery } from '@/components/ProductList/operations.generated'
import type { Scalars, Product, ImageUpload, Exact } from '@/network/graphql/types.generated'
import type { GetInventoryProductImagesQuery } from '@/views/Inventory/operation.generated'
import type { LazyQueryExecFunction } from '@apollo/client'
import type { TFunction } from 'i18next'

const PARENT_CATEGORY_FASHION_SLUG = 'fashion'
const PARENT_CATEGORY_SNEAKERS_DEFAULT_SUBCATEGORY_SLUG = 'sneakers'
const PARENT_CATEGORY_TCG_SLUG = 'trading-cards'
const PARENT_CATEGORY_SPORTS_SLUG = 'sports'
const CATEGORY_SPORTS_MEMORABILIA_SLUG = 'sports-memorabilia'

const CATEGORY_SNEAKERS_SLUG = 'sneakers'

const computeInitialCategory = (
  categoryName?: string | null,
  showCategory?: string | null,
  fashionCategoriesSlugs?: string[]
) => {
  if (categoryName === ProductCategoryName.Fashion) {
    const isFashionShow = (fashionCategoriesSlugs || []).includes(showCategory || '')
    return isFashionShow && showCategory ? showCategory : 'vintage-streetwear'
  }
  if (categoryName === ProductCategoryName.Sneakers) {
    return PARENT_CATEGORY_SNEAKERS_DEFAULT_SUBCATEGORY_SLUG
  }

  return categoryName || showCategory || ''
}

export const computeInitialImages = (images?: Pick<ImageUpload, 'webPUrl' | 'id'>[]): ProductImage[] => {
  return (
    (images || []).map(
      ({ webPUrl: url, id }) =>
        ({
          url,
          id,
          file: undefined,
          isDeleted: false,
        }) as ProductImage
    ) || []
  )
}

export const convertPriceInCentsToFloatingNumber = (price: number | undefined) => {
  return typeof price === 'number' ? price / 100 : undefined
}

export const convertFloatingNumberToPriceInCents = (value: number) => {
  if (typeof value !== 'number') return undefined

  return new Big(value).mul(100).toNumber()
}

export const computeInitialValues = (
  product?: Product,
  options?: {
    showCategory?: string
    fashionCategoriesSlugs?: string[]
    productType: TabName
    action: PRODUCT_FORM_ACTION
    lastCreatedProduct?: Product
  }
) => {
  const { categoryName, name, description, images, type, giveawayAudience, isGiveawayOpenToInternational } =
    product || {}
  const { brand, color, size, condition, gender, model } = product || {}
  const { cardCondition, cardGrade, cardGradingService, cardLanguage, cardType } = product || {}
  const { availableQuantity, startingAmount, fixedAmount } = product || {}
  const { showCategory, fashionCategoriesSlugs, productType, action, lastCreatedProduct } = options || {}

  const isCreateMode = action === PRODUCT_FORM_ACTION.CREATE || product?.id === 'new'
  const isDuplicateMode = action === PRODUCT_FORM_ACTION.DUPLICATE

  return {
    category: computeInitialCategory(categoryName, showCategory, fashionCategoriesSlugs),
    title:
      (isCreateMode && lastCreatedProduct?.name
        ? lastCreatedProduct.name
        : isDuplicateMode
          ? `${name} [copy]`
          : name) || '',
    description: (isCreateMode && lastCreatedProduct?.description ? lastCreatedProduct.description : description) || '',
    images: computeInitialImages(images),
    type: type || computeInitialProductType(productType),
    giveawayAudience: giveawayAudience || undefined,
    isGiveawayOpenToInternational: isGiveawayOpenToInternational ?? undefined,
    startingPrice: isCreateMode
      ? convertPriceInCentsToFloatingNumber(lastCreatedProduct?.startingAmount) || 1
      : convertPriceInCentsToFloatingNumber(startingAmount),
    buyNowPrice: isCreateMode
      ? convertPriceInCentsToFloatingNumber(lastCreatedProduct?.fixedAmount) || undefined
      : // TODO: Clean this once the backend will expose new proper APIs that will correctly handle all this
        // Note:
        // To update an auction product to remove a previously set fixedAmount, we currently need to set it to 0 (instead of null).
        // as the backend does not correctly handle null values for fixedAmount
        // Then when updating again that same product, we will get a 0 value, which is theoretically not an allowed value for fixedAmount in the form
        fixedAmount === 0
        ? undefined
        : convertPriceInCentsToFloatingNumber(fixedAmount),
    price: isCreateMode
      ? lastCreatedProduct?.type === ProductType.InstantBuy && lastCreatedProduct?.fixedAmount
        ? convertPriceInCentsToFloatingNumber(lastCreatedProduct.fixedAmount)
        : undefined
      : type === ProductType.InstantBuy
        ? convertPriceInCentsToFloatingNumber(fixedAmount)
        : undefined,
    availableQuantity: availableQuantity ?? 1,
    // Fashion/sneakers attributes
    brand: brand || '',
    color: color || '',
    condition: condition || '',
    gender: gender || '',
    model: model || '',
    size: size || '',
    // TCG attributes
    cardCondition,
    cardGrade,
    cardGradingService,
    cardLanguage,
    cardType,
    isPreOrder: product?.isPreOrder ?? false,
    salesType: product?.salesType ?? null,
  }
}

const computeInitialProductType = (productType?: TabName) => {
  if (productType === TabName.instantBuy) {
    return ProductType.InstantBuy
  }
  if (productType === TabName.giveaway) {
    return ProductType.Giveaway
  }

  return ProductType.Auction
}

export const createParentCategoriesOptionGroups = (parentCategories: ParentCategories, offline: boolean = false) => {
  const optGroups = parentCategories.map(({ id, name, categories }) => {
    return {
      key: id,
      label: name,
      options: categories
        .filter(({ isOffline }) => isOffline === offline)
        .map(({ id, name, slug, stickerUrl }) => ({ key: id, label: name, value: slug, cover: stickerUrl })),
    }
  })

  return [{ label: '', options: [{ value: '' }] }, ...optGroups] as SelectOptions
}

export const getFashionCategoriesSlugs = (parentCategories: ParentCategories) => {
  return (
    parentCategories
      .find((parentCategory) => parentCategory.slug === PARENT_CATEGORY_FASHION_SLUG)
      ?.categories.map(({ slug }) => slug)
      // We don't want the default sneakers subcategory to be considered as a fashion category as it's handle specifically
      .filter((slug) => slug !== PARENT_CATEGORY_SNEAKERS_DEFAULT_SUBCATEGORY_SLUG) || []
  )
}

export const getTcgCategoriesSlugs = (parentCategories: ParentCategories) => {
  const tcgCategoriesSlugs = parentCategories
    // We want to consider both TCG and sports categories as TCG categories
    .filter((parentCategory) => [PARENT_CATEGORY_TCG_SLUG, PARENT_CATEGORY_SPORTS_SLUG].includes(parentCategory.slug))
    .map(
      (parentCategory) =>
        parentCategory.categories // We don't want the sports memorabilia subcategory to be considered as a TCG category as it's handle specifically
          .filter(({ slug }) => slug !== CATEGORY_SPORTS_MEMORABILIA_SLUG)
          .map(({ slug }) => slug) || []
    )
    .flat()

  return tcgCategoriesSlugs
}

export const computeGenderFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [{ value: '' }].concat(fashionProductGendersTranslationKeys.map((gender) => ({ value: t(gender) })))
}

export const computeColorFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [{ value: '' }].concat(fashionProductColorsTranslationKeys.map((color) => ({ value: t(color) })))
}

export const computeConditionFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [{ value: '' }].concat(fashionProductConditionsTranslationKeys.map((condition) => ({ value: t(condition) })))
}

export const computeBrandFieldOptions = (t: TFunction<'translation', undefined>) => {
  return brands.map((brand) => {
    const value = t(brand)
    return { value, label: value }
  })
}

export const computeSellingTypeOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '' },
    { value: SalesType.New, label: t('salesTypeNew') },
    { value: SalesType.SecondHand, label: t('salesTypeSecondHand') },
  ]
}

export const computeSellingModeOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '' },
    { value: ProductType.Auction, label: t('commonAuction') },
    { value: ProductType.InstantBuy, label: t('commonInstantBuy') },
    { value: ProductType.Giveaway, label: t('commonGiveaway') },
  ]
}

export const sanitizeStartingAmount = (startingPrice: number | undefined, type: ProductType) => {
  if (type === ProductType.Giveaway) {
    return 0
  }

  return startingPrice ? new Big(startingPrice).mul(100).toNumber() : null
}

export const computeImagesPositions = async (
  images: ProductImage[] | undefined,
  refetchImages:
    | LazyQueryExecFunction<
        GetInventoryProductImagesQuery,
        Exact<{
          nodeId: Scalars['ID']['input']
        }>
      >
    | LazyQueryExecFunction<
        GetProductImagesQuery,
        Exact<{
          nodeId: string
        }>
      >,
  productId: string
) => {
  // If we have newly uploaded images, we are going to need to re-fetch the product images once they will have been uploaded,
  // in order to compute the proper imagesPosition, assuming that they have been updated if the correct order
  const refetched = await refetchImages({
    variables: { nodeId: productId },
  })
  const refetchedImages =
    refetched?.data?.node?.__typename === 'Product' || refetched?.data?.node?.__typename === 'InventoryProduct'
      ? //@ts-expect-error - TS is not able to infer the type of the images field
        refetched?.data?.node?.images
      : []

  // If no changes were made to the images, we can just return the current images ids
  if (!images?.length) {
    return refetchedImages.map((image: ImageUpload) => image.id)
  }

  if (images.filter(({ isDeleted }) => !isDeleted).length !== refetchedImages.length) {
    trackError(new Error('Sent images position does not match expected images count sent from backend'), {
      meta: {
        productId,
        refetchedImages,
        images,
        scope: 'CreateOrEditProduct.helpers.computeImagesPositions',
      },
    })
  }

  // We are gonna need the ids of the images that we already know about before changes were made
  const alreadyKnownImagesIds = (images || [])
    .filter((image) => !image.id.startsWith('new-') && !image.isDeleted)
    .map((image) => image.id)

  // Then we now need to get the ids of the newly uploaded images, assuming that they have been uploaded
  // in the correct order as we have currently no other way to match images BEFORE & AFTER upload
  const refetchedNewImagesIds = refetchedImages
    .filter((image: ImageUpload) => !alreadyKnownImagesIds.includes(image.id))
    .map((image: ImageUpload) => image.id)

  // Now, compute the position of the images based on the actual form images,
  // minus the deleted ones.
  // And for the newly uploaded images, we get their id from the corresponding re-fetched images,
  // once again assuming they are in the correct order
  let i = 0
  return images
    .filter((image) => !image.isDeleted)
    .map((image) => {
      const isNewlyUploaded = image.id.startsWith('new-')

      let id = image.id
      if (isNewlyUploaded) {
        id = refetchedNewImagesIds[i]
        i += 1
      }

      return id
    })
}

export const getApplicableProductAttributesForCategory = (
  input: Pick<
    ProductInputData,
    | 'brand'
    | 'color'
    | 'size'
    | 'condition'
    | 'model'
    | 'gender'
    | 'cardCondition'
    | 'cardGrade'
    | 'cardGradingService'
    | 'cardLanguage'
    | 'cardType'
  >,
  category: string,
  fashionCategoriesSlugs: string[],
  tcgCategorySlugs: string[]
) => {
  const { brand, color, size, condition, model, gender } = input
  const { cardCondition, cardGrade, cardGradingService, cardLanguage, cardType } = input

  if (isProductFromSneakersCategory(category)) {
    return { brand, model, color, size, condition }
  } else if (isProductFromFashionCategory(category, fashionCategoriesSlugs)) {
    return { brand, color, size, gender, condition }
  } else if (isProductFromTCGCategory(category, tcgCategorySlugs)) {
    return { cardCondition, cardGrade, cardGradingService, cardLanguage, cardType }
  } else return {}
}

export const isProductAnAuctionProduct = (type: ProductType) => type === ProductType.Auction
export const isProductAnInstantBuyProduct = (type: ProductType) => type === ProductType.InstantBuy
export const isProductAGiveawayProduct = (type: ProductType) => type === ProductType.Giveaway

// export const isProductFromSneakersCategory = (category: string) => category === CATEGORY_SNEAKERS_SLUG
export const isProductFromSneakersCategory = (category: string) =>
  category === ProductCategoryName.Sneakers || category === CATEGORY_SNEAKERS_SLUG
// export const isProductFromFashionCategory = (category: string) => category === PARENT_CATEGORY_FASHION_SLUG
export const isProductFromFashionCategory = (category: string, fashionCategoriesSlugs?: string[]) =>
  category === ProductCategoryName.Fashion || fashionCategoriesSlugs?.includes(category)
export const isProductFromTCGCategory = (category: string, tcgCategorySlugs: string[]) =>
  tcgCategorySlugs.includes(category)
