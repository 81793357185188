const TeamIcon = () => {
  return (
    <svg fill="none" height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M45.1135 33.9184C46.5314 34.7077 48.1348 35.1535 49.8683 35.1535C55.3043 35.1535 59.7157 30.7394 59.7157 25.3034C59.7157 19.8951 55.3043 15.481 49.8683 15.481C48.7911 15.481 47.7664 15.6665 46.7944 15.9794C48.2123 18.2668 49.0264 20.9696 49.0264 23.833C49.0264 27.721 47.5809 31.2406 45.1135 33.9184Z"
        fill="#171514"
        fillRule="evenodd"
        opacity="0.4"
      />
      <path
        clipRule="evenodd"
        d="M17.9835 35.1526C19.717 35.1526 21.3176 34.7067 22.7382 33.9175C20.2681 31.2396 18.8225 27.72 18.8225 23.832C18.8225 20.9714 19.6367 18.2658 21.0573 15.9812C20.0853 15.6655 19.0607 15.48 17.9835 15.48C12.5475 15.48 8.1333 19.8941 8.1333 25.3024C8.1333 30.7412 12.5475 35.1526 17.9835 35.1526Z"
        fill="#171514"
        fillRule="evenodd"
        opacity="0.4"
      />
      <path
        clipRule="evenodd"
        d="M33.925 36.3281C40.8093 36.3281 46.4087 30.7287 46.4087 23.8444C46.4087 16.9629 40.8093 11.3635 33.925 11.3635C27.0408 11.3635 21.4414 16.9629 21.4414 23.8444C21.4414 30.7287 27.0408 36.3281 33.925 36.3281Z"
        fill="#171514"
        fillRule="evenodd"
        opacity="0.4"
      />
      <g opacity="0.4">
        <path
          clipRule="evenodd"
          d="M64.6521 48.6527C62.6306 42.3223 57.2721 38.7001 49.9198 38.7001H49.8672C46.8709 38.6724 44.193 39.2761 41.9333 40.4309C48.2389 42.4552 52.9133 46.9469 55.065 53.3023C58.4795 52.8287 61.4481 51.7792 64.0733 50.1758L64.9401 49.6247L64.6521 48.6527Z"
          fill="#171514"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M25.7824 40.3799C23.5753 39.2777 20.975 38.699 18.0341 38.699H17.9288C10.5765 38.699 5.21807 42.3239 3.22145 48.6516L2.90576 49.6236L3.77253 50.1747C6.26761 51.7005 9.2113 52.7224 12.4928 53.2485C14.6722 46.8682 19.427 42.3488 25.7824 40.3799Z"
          fill="#171514"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M33.8288 41.8252C33.7845 41.8252 33.743 41.8252 33.7015 41.8252C24.2473 41.8252 17.3547 46.472 14.7904 54.583L14.4802 55.5634L15.3581 56.1006C20.3648 59.1523 26.3464 60.6366 33.6433 60.6366H33.8787C41.1811 60.6366 47.1655 59.1523 52.1722 56.1006L53.0528 55.5634L52.7427 54.583C50.1756 46.472 43.2858 41.8252 33.8288 41.8252Z"
          fill="#171514"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default TeamIcon
