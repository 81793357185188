import { Carousel as CarouselAntd } from 'antd'
import { cloneElement, isValidElement, Children, forwardRef } from 'react'

import type { CarouselProps } from 'antd'
import type { CarouselRef } from 'antd/lib/carousel'

interface carouselT extends CarouselProps {
  selectedSlide?: number
}

const Carousel = forwardRef<CarouselRef, carouselT>((props, ref) => {
  const { children, selectedSlide = 0, infinite } = props

  const childrenWithProps = Children.map(children, (child, index: number) => {
    if (isValidElement(child) && selectedSlide === index) {
      return cloneElement(child)
    }
  })

  return (
    <CarouselAntd ref={ref} infinite={infinite} {...props}>
      {childrenWithProps}
    </CarouselAntd>
  )
})

export default Carousel
