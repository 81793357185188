import moment from 'moment'

import type {
  StripeCompanyTokenCreationParams,
  StripeIndividualTokenCreationParams,
  StripePersonTokenCreationParams,
} from './types'
import type { TokenCreateParams, TokenResult, Stripe } from '@stripe/stripe-js'

export async function createStripeIndividualToken(
  stripeHook: Stripe,
  params: StripeIndividualTokenCreationParams
): Promise<TokenResult> {
  const createTokenResponse = await stripeHook.createToken('account', {
    business_type: 'individual',
    individual: {
      first_name: params.firstname,
      last_name: params.lastname,
      dob: {
        day: parseInt(moment(params.birthdate).format('DD')),
        month: parseInt(moment(params.birthdate).format('MM')),
        year: parseInt(moment(params.birthdate).format('YYYY')),
      },
      email: params.seller.email,
      phone: params.phone,
      address: {
        line1: params.address,
        postal_code: params.postalCode,
        //state: params.state.length ? params.state : undefined,
        state: params.state,
        city: params.city,
        country: params.country,
      },
      verification: {
        document: {
          front: params.idFront,
          back: params.idBack,
        },
        additional_document: {
          front: params.idAddFront,
        },
      },
    },
    tos_shown_and_accepted: true,
  })

  return createTokenResponse
}

export async function createStripeCompanyToken(
  stripeHook: Stripe,
  params: StripeCompanyTokenCreationParams
): Promise<TokenResult> {
  return stripeHook.createToken('account', {
    business_type: 'company',
    company: {
      address: {
        line1: params.address,
        postal_code: params.postalCode,
        state: params.state,
        city: params.city,
        country: params.country,
      },

      name: params.name,
      phone: params.phone,
      tax_id: params.taxId, // DE only
      tax_id_registrar: params.taxIdRegistrar, // DE only
      owners_provided: true,
      directors_provided: true,
      executives_provided: true,
      verification: {
        document: {
          back: params.documentBack,
          front: params.documentFront,
        },
      },
    },
    tos_shown_and_accepted: true,
  } as TokenCreateParams.Account)
}

export async function createStripePersonToken(
  stripeHook: Stripe,
  params: StripePersonTokenCreationParams
): Promise<TokenResult> {
  const dob = {
    day: parseInt(moment(params.birthdate).format('DD')),
    month: parseInt(moment(params.birthdate).format('MM')),
    year: parseInt(moment(params.birthdate).format('YYYY')),
  }

  return stripeHook.createToken('person', {
    address: {
      line1: params.address,
      postal_code: params.postalCode,
      state: params.state,
      city: params.city,
      country: params.country,
    },
    dob,
    email: params.email,
    first_name: params.firstname,
    last_name: params.lastname,
    phone: params.phone,
    relationship: {
      director: true,
      executive: true,
      owner: true,
      representative: true,
      title: 'CEO',
    },
  } as TokenCreateParams.Person)
}
