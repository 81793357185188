import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'

import type { ChangeEvent } from 'react'

interface SubAccountsCreationDialogProps {
  onCancel: () => void
  onComplete: (email: string) => void
  isLoading: boolean
}

type SubAccountValidity = {
  email: boolean
}
const initialValidity: SubAccountValidity = {
  email: false,
}

type InputChangeEvent = ChangeEvent<HTMLInputElement>

export const SubAccountsCreationDialog = (props: SubAccountsCreationDialogProps) => {
  const { onCancel, onComplete, isLoading } = props
  const { t } = useTranslation()

  const [subAccountToAddEmail, setSubAccountToAddEmail] = useState<string | undefined>(undefined)
  const [validity, setValidity] = useState<SubAccountValidity>(initialValidity)

  const handleValidityChange = useCallback((name: keyof SubAccountValidity, isValid: boolean) => {
    setValidity((prev) => ({ ...prev, [name]: isValid }))
  }, [])
  const handleEmailChange = useCallback((e: InputChangeEvent) => setSubAccountToAddEmail(e.target.value), [])

  const isValid = validity.email

  const handleAddSubAccount = async () => {
    if (!subAccountToAddEmail) {
      return
    }
    await onComplete(subAccountToAddEmail)
    setSubAccountToAddEmail(undefined)
  }

  return (
    <Dialog
      className="subaccount-creation"
      isOpen={true}
      modal={true}
      title={t('addSubAccountTitle')}
      onClose={onCancel}
    >
      <Alert emphasis="high" type="info">
        {t('addSubAccountInfo')}
      </Alert>
      <Form className="seller-subaccount-form" onSubmit={handleAddSubAccount}>
        <div className="seller-sub-account-form-content">
          <Field
            label={t('sellerSubAccountEmail')}
            name="email"
            placeholder={t('sellerSubAccountEmail')}
            type="email"
            value={subAccountToAddEmail}
            required
            onChange={handleEmailChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('email', isValid)}
          />
        </div>
        <div className="seller-sub-account-form-action">
          <Button
            className="validate-action primary"
            disabled={!isValid}
            isLoading={isLoading}
            label={t('sellerSubAccountSubmit')}
            type="submit"
          />
        </div>
      </Form>
    </Dialog>
  )
}
