import { useCallback, useEffect, useState } from 'react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'

import MainNav from '@/components/Layout/Header/MainNav/MainNav'
import Button from '@/components/ui/Button/Button'
import VoggtLogo from '@/components/ui/VoggtLogo/VoggtLogo'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import './Header.scss'

const Header = () => {
  const location = useLocation()
  useEffect(() => {
    setToLocalStorage('currentPath', location.pathname)
    const previouslyMinimized = getFromLocalStorage('isMinimized') || 'false'

    if (location.pathname.includes('/shops/') || location.pathname.includes('/shows/')) {
      if (!isMinimized) setIsMinimized(true)
    } else {
      if (isMinimized && previouslyMinimized === 'false') setIsMinimized(false)
    }
  }, [location])

  const isShowOrShop = location.pathname.includes('/shops/') || location.pathname.includes('/shows/')

  const [isMinimized, setIsMinimized] = useState<boolean>(isShowOrShop)

  const toggleHeader = useCallback(() => {
    const value = !isMinimized

    setToLocalStorage('isMinimized', value ? 'true' : 'false')
    setIsMinimized(value)
  }, [isMinimized])

  return (
    <header className={`header ${isMinimized ? 'is-minimized' : ''}`} id="header">
      <VoggtLogo />
      <MainNav />
      <div className="header-toggle">
        <Button className="header-toggle-action" onClick={toggleHeader}>
          {isMinimized ? <FaCaretRight /> : <FaCaretLeft />}
        </Button>
      </div>
    </header>
  )
}

export default Header
