import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Tag from '@/components/Tag/Tag'
import Dialog from '@/components/ui/Dialog/Dialog'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderPaymentStatus } from '@/network/graphql/types.generated'
import { useGetCustomerWithUnshippedOrderedProductsQuery } from '@/views/Shipments/operations.generated'
import { OrderStatusTag } from '@/views/Shipments/Tables/components/OrderStatus/OrderStatus'
import UsernameAndContactColumn from '@/views/Shipments/Tables/components/UsernameAndContactColumn/UsernameAndContactColumn'

import type { TagStatus } from '@/components/Tag/Tag'

import './DrawerShipment.scss'

export interface DrawerPendingShipmentProps {
  open: boolean
  onClose(): void
  onReset(): void
  customerId: string | null
}

export const DrawerPendingShipment = (props: DrawerPendingShipmentProps) => {
  const { onClose, onReset, open, customerId } = props
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const { data } = useGetCustomerWithUnshippedOrderedProductsQuery({
    skip: !customerId,
    variables: {
      // @ts-expect-error ts does not consider the skip
      customerId,
    },
  })

  const orderedProducts = data?.customerWithUnshippedOrderedProducts?.unshippedOrderedProducts.edges.map(
    (item) => item.node
  )
  const customer = data?.customerWithUnshippedOrderedProducts?.customer
  const createdAt = data?.customerWithUnshippedOrderedProducts?.oldestOrderedProductDate

  const status = data?.customerWithUnshippedOrderedProducts?.statusSummary

  const handleClose = () => {
    onClose()
    onReset()
  }
  if (!orderedProducts || !customer) return null

  return open ? (
    <Dialog
      className="shipment-detail-drawer is-drawer is-animated"
      isOpen={true}
      modal={true}
      title={t('drawerShipmentTitle')}
      onClose={handleClose}
    >
      <div className="shipment-detail-item-header">
        <div>
          <UsernameAndContactColumn
            avatarUrl={customer.avatarWebPUrl}
            createdAt={createdAt}
            username={customer.username}
          />
          <p className="shipment-detail-item-date">{dayjs(createdAt).format('DD/MM/YYYY')}</p>
        </div>

        <OrderStatusTag status={status} />
      </div>
      <div>
        {orderedProducts.map((item) => {
          const { images, name, order, id, description } = item
          const {
            paymentStatus,
            amountInCurrencyCents,
            shippingAmountInCurrencyCents,
            currency,
            shippingFeesCancellationRequestedAt,
          } = order
          const isCancelled = !!shippingFeesCancellationRequestedAt
          let paymentStatusLabel
          let paymentStatusColor
          const { name: showName } = item.order.product.show || {}

          switch (paymentStatus) {
            case OrderPaymentStatus.Success:
              paymentStatusLabel = t('orderPaymentStatusSuccess')
              paymentStatusColor = 'success'
              break
            case OrderPaymentStatus.Failed:
              paymentStatusLabel = t('orderPaymentStatusFailed')
              paymentStatusColor = 'danger'
              break
            case OrderPaymentStatus.InProgress:
              paymentStatusLabel = t('orderPaymentStatusInProgress')
              paymentStatusColor = 'warning'
              break
            case OrderPaymentStatus.PendingSepaDebit:
              paymentStatusLabel = t('orderPaymentStatusInProgress')
              paymentStatusColor = 'warning'
              break
          }

          const imageUrl = images?.[0]?.webPUrl || '/badges/empty-pic.png'
          return (
            <div className="shipment-detail-item-container">
              <div key={`product-${id}`} className="shipment-detail-item-product">
                <div className="shipment-detail-product-image">
                  <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
                </div>
                <div className="shipment-detail-product-details">
                  <p>{name}</p>
                  <p>{description}</p>
                </div>
              </div>

              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemStatus')}</p>
                <Tag content={paymentStatusLabel} status={paymentStatusColor as TagStatus} />
              </div>
              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemAmount')}</p>
                <p>{amountInCurrencyCents ? currencyFormatter(amountInCurrencyCents, currency) : '-'}</p>
              </div>
              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemShippingFee')}</p>
                <p className={isCancelled ? 'shipping-fees-refunded' : ''}>
                  {shippingAmountInCurrencyCents ? currencyFormatter(shippingAmountInCurrencyCents, currency) : '-'}
                </p>
              </div>
              <div className="shipment-detail-item-content">
                <p>{t('drawerShipmentItemShow')}</p>
                <p>{showName}</p>
              </div>
            </div>
          )
        })}
      </div>
    </Dialog>
  ) : null
}
