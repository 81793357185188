import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFeaturedShowConceptsForParentCategoryQueryVariables = Types.Exact<{
  parentCategoryId: Types.Scalars['ID']['input'];
}>;


export type GetFeaturedShowConceptsForParentCategoryQuery = { __typename?: 'Query', featuredShowConceptsForParentCategory: Array<string> };

export type ApplyForFeaturedShowMutationVariables = Types.Exact<{
  input: Types.ApplyForFeaturedShowInput;
}>;


export type ApplyForFeaturedShowMutation = { __typename?: 'Mutation', applyForFeaturedShow: { __typename?: 'ApplyForFeaturedShowPayload', ok: boolean } };

export type ApplyForCommercialEventMutationVariables = Types.Exact<{
  input: Types.ApplyForCommercialEventInput;
}>;


export type ApplyForCommercialEventMutation = { __typename?: 'Mutation', applyForCommercialEvent: { __typename?: 'ApplyForCommercialEventPayload', ok: boolean } };


export const GetFeaturedShowConceptsForParentCategoryDocument = gql`
    query GetFeaturedShowConceptsForParentCategory($parentCategoryId: ID!) {
  featuredShowConceptsForParentCategory(parentCategoryId: $parentCategoryId)
}
    `;

/**
 * __useGetFeaturedShowConceptsForParentCategoryQuery__
 *
 * To run a query within a React component, call `useGetFeaturedShowConceptsForParentCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedShowConceptsForParentCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedShowConceptsForParentCategoryQuery({
 *   variables: {
 *      parentCategoryId: // value for 'parentCategoryId'
 *   },
 * });
 */
export function useGetFeaturedShowConceptsForParentCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables> & ({ variables: GetFeaturedShowConceptsForParentCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>(GetFeaturedShowConceptsForParentCategoryDocument, options);
      }
export function useGetFeaturedShowConceptsForParentCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>(GetFeaturedShowConceptsForParentCategoryDocument, options);
        }
export function useGetFeaturedShowConceptsForParentCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>(GetFeaturedShowConceptsForParentCategoryDocument, options);
        }
export type GetFeaturedShowConceptsForParentCategoryQueryHookResult = ReturnType<typeof useGetFeaturedShowConceptsForParentCategoryQuery>;
export type GetFeaturedShowConceptsForParentCategoryLazyQueryHookResult = ReturnType<typeof useGetFeaturedShowConceptsForParentCategoryLazyQuery>;
export type GetFeaturedShowConceptsForParentCategorySuspenseQueryHookResult = ReturnType<typeof useGetFeaturedShowConceptsForParentCategorySuspenseQuery>;
export type GetFeaturedShowConceptsForParentCategoryQueryResult = Apollo.QueryResult<GetFeaturedShowConceptsForParentCategoryQuery, GetFeaturedShowConceptsForParentCategoryQueryVariables>;
export const ApplyForFeaturedShowDocument = gql`
    mutation ApplyForFeaturedShow($input: ApplyForFeaturedShowInput!) {
  applyForFeaturedShow(input: $input) {
    ok
  }
}
    `;
export type ApplyForFeaturedShowMutationFn = Apollo.MutationFunction<ApplyForFeaturedShowMutation, ApplyForFeaturedShowMutationVariables>;

/**
 * __useApplyForFeaturedShowMutation__
 *
 * To run a mutation, you first call `useApplyForFeaturedShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForFeaturedShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForFeaturedShowMutation, { data, loading, error }] = useApplyForFeaturedShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyForFeaturedShowMutation(baseOptions?: Apollo.MutationHookOptions<ApplyForFeaturedShowMutation, ApplyForFeaturedShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyForFeaturedShowMutation, ApplyForFeaturedShowMutationVariables>(ApplyForFeaturedShowDocument, options);
      }
export type ApplyForFeaturedShowMutationHookResult = ReturnType<typeof useApplyForFeaturedShowMutation>;
export type ApplyForFeaturedShowMutationResult = Apollo.MutationResult<ApplyForFeaturedShowMutation>;
export type ApplyForFeaturedShowMutationOptions = Apollo.BaseMutationOptions<ApplyForFeaturedShowMutation, ApplyForFeaturedShowMutationVariables>;
export const ApplyForCommercialEventDocument = gql`
    mutation ApplyForCommercialEvent($input: ApplyForCommercialEventInput!) {
  applyForCommercialEvent(input: $input) {
    ok
  }
}
    `;
export type ApplyForCommercialEventMutationFn = Apollo.MutationFunction<ApplyForCommercialEventMutation, ApplyForCommercialEventMutationVariables>;

/**
 * __useApplyForCommercialEventMutation__
 *
 * To run a mutation, you first call `useApplyForCommercialEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForCommercialEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForCommercialEventMutation, { data, loading, error }] = useApplyForCommercialEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyForCommercialEventMutation(baseOptions?: Apollo.MutationHookOptions<ApplyForCommercialEventMutation, ApplyForCommercialEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyForCommercialEventMutation, ApplyForCommercialEventMutationVariables>(ApplyForCommercialEventDocument, options);
      }
export type ApplyForCommercialEventMutationHookResult = ReturnType<typeof useApplyForCommercialEventMutation>;
export type ApplyForCommercialEventMutationResult = Apollo.MutationResult<ApplyForCommercialEventMutation>;
export type ApplyForCommercialEventMutationOptions = Apollo.BaseMutationOptions<ApplyForCommercialEventMutation, ApplyForCommercialEventMutationVariables>;