import { useTranslation } from 'react-i18next'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import { CustomerWithUnshippedOrderedProductsStatusSummary, OrderStatus } from '@/network/graphql/types.generated'

interface OrderStatusProps {
  status: CustomerWithUnshippedOrderedProductsStatusSummary | OrderStatus | undefined
}

export const OrderStatusTag = (props: OrderStatusProps) => {
  const { t } = useTranslation()
  const { status } = props

  switch (status) {
    case CustomerWithUnshippedOrderedProductsStatusSummary.ReadyToShip:
      return <Tag content={t('unshippedOrdersTablePaymentStatusGreen')} status={TagStatus.SUCCESS} />
    case CustomerWithUnshippedOrderedProductsStatusSummary.SomeNotPaidYet:
      return <Tag content={t('unshippedOrdersTablePaymentStatusOrange')} status={TagStatus.WARNING} />
    case CustomerWithUnshippedOrderedProductsStatusSummary.SomeFailed:
      return <Tag content={t('unshippedOrdersTablePaymentStatusRed')} status={TagStatus.DANGER} />

    case OrderStatus.Canceled:
      return <Tag content={t('canceledOrdersTableStatusCanceled')} status={TagStatus.WARNING} />

    case OrderStatus.Refunded:
      return <Tag content={t('canceledOrdersTableStatusRefunded')} status={TagStatus.SECONDARY} />
    default:
      return <p>-</p>
  }
}
