import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import {
  useCreateSellerSubAccountMutation,
  useDeleteSellerSubAccountMutation,
  useGetSellerSubAccountsQuery,
} from './operations.generated'
import { SubAccountsRemovalDialog } from './SubAccountRemovalDialog/SubAccountRemovalDialog'
import { SubAccountsCreationDialog } from './SubAccountsCreationDialog/SubAccountsCreationDialog'
import { SubAccountsTable } from './SubAccountsTable/SubAccountsTable'

import type { GetSellerSubAccountsQuery } from './operations.generated'
import type { SellerSubAccount } from '@/network/graphql/types.generated'

import './SubAccounts.scss'

const extractSubAccounts = (subAccounts: GetSellerSubAccountsQuery | undefined) => {
  if (!subAccounts) return []
  return subAccounts.sellerSubAccounts.edges.map((edge) => edge.node as SellerSubAccount)
}

export const SubAccounts = () => {
  const { t } = useTranslation()

  const perPageStored = getFromLocalStorage('default_per_page_subAccounts')
  const defaultPerPage = perPageStored ? parseInt(perPageStored, 10) : 10
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultPerPage)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isModalCreateVisible, setIsModalCreateVisible] = useState<boolean>(false)
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState<boolean>(false)
  const [subAccountToDelete, setSubAccountToDelete] = useState<SellerSubAccount | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [createSellerSubAccount] = useCreateSellerSubAccountMutation()
  const [deleteSellerSubAccount] = useDeleteSellerSubAccountMutation()

  const {
    data,
    loading: loadingData,
    fetchMore,
    refetch,
  } = useGetSellerSubAccountsQuery({
    variables: {
      first: itemsPerPage,
    },
  })
  const subAccounts = extractSubAccounts(data)
  const totalSubAccounts = data?.sellerSubAccounts.totalCount

  const handlePageChange = async (page: number) => {
    setCurrentPage(page)
    await fetchMore({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    setToLocalStorage('default_per_page_subAccounts', itemsPerPage.toString())
    setItemsPerPage(itemsPerPage)
    setCurrentPage(1)
  }

  const handleOpenModalCreate = useCallback(() => {
    setIsModalCreateVisible(true)
  }, [])

  const handleCloseModalCreate = useCallback(() => {
    setIsModalCreateVisible(false)
  }, [])

  const handleOpenModalDelete = useCallback((subAccount: SellerSubAccount) => {
    setIsModalDeleteVisible(true)
    setSubAccountToDelete(subAccount)
  }, [])

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteVisible(false)
  }, [])

  const handleAddSubAccount = async (subAccountToAddEmail: string) => {
    setIsLoading(true)
    await createSellerSubAccount({
      variables: {
        input: {
          email: subAccountToAddEmail,
        },
      },
      onCompleted: () => {
        notificationSuccess(t('subAccountSuccessfullyAdded'))
      },
      onError: (error) => {
        notificationDanger(error.message)
      },
    })
    setCurrentPage(1)
    setIsLoading(false)
    handleCloseModalCreate()
    refetch()
  }

  const handleRemoveSubAccount = async () => {
    if (!subAccountToDelete?.id) {
      return
    }
    setIsLoading(true)
    await deleteSellerSubAccount({
      variables: {
        input: {
          id: subAccountToDelete.id,
        },
      },
      onCompleted: () => {
        notificationSuccess(t('subAccountSuccessfullyRemoved'))
      },
      onError: (error) => {
        notificationDanger(error.message)
      },
    })
    setCurrentPage(1)
    setIsLoading(false)
    setSubAccountToDelete(undefined)
    handleCloseModalDelete()
    refetch()
  }

  return (
    <div className="sub-accounts">
      <Card>
        <SubAccountsTable
          currentPage={currentPage}
          data={subAccounts}
          isLoading={loadingData}
          itemsPerPage={itemsPerPage}
          total={totalSubAccounts}
          onItemPerPageChange={handleItemPerPageChange}
          onOpenModalCreate={handleOpenModalCreate}
          onOpenModalDelete={handleOpenModalDelete}
          onPageChange={handlePageChange}
        />
      </Card>
      {isModalCreateVisible && (
        <SubAccountsCreationDialog
          isLoading={isLoading}
          onCancel={handleCloseModalCreate}
          onComplete={handleAddSubAccount}
        />
      )}
      {isModalDeleteVisible && (
        <SubAccountsRemovalDialog
          isLoading={isLoading}
          subAccountToDelete={subAccountToDelete}
          onCancel={handleCloseModalDelete}
          onComplete={handleRemoveSubAccount}
        />
      )}
    </div>
  )
}
