import {
  ProductStatusInAuction as ProductStatusInAuctionGql,
  OrderPaymentStatus,
  OrderStatus as OrderStatusGql,
} from '@/network/graphql/types.generated'
import { ProductStatusInAuction, OrderStatus, PaymentStatus } from '@/types'

export const ProductStatusInAuctionMapping = {
  [ProductStatusInAuction.pending]: ProductStatusInAuctionGql.Pending,
  [ProductStatusInAuction.inProgress]: ProductStatusInAuctionGql.InProgress,
  [ProductStatusInAuction.sold]: ProductStatusInAuctionGql.Sold,
  [ProductStatusInAuction.unsold]: ProductStatusInAuctionGql.Unsold,
}

export const ProductStatusInAuctionMappingReverse = {
  [ProductStatusInAuctionGql.Pending]: 'pending',
  [ProductStatusInAuctionGql.InProgress]: 'inProgress',
  [ProductStatusInAuctionGql.Sold]: 'sold',
  [ProductStatusInAuctionGql.Unsold]: 'unsold',
}

export const OrderStatusMapping = {
  [OrderStatus.pending]: OrderStatusGql.Pending,
  [OrderStatus.shipped]: OrderStatusGql.Shipped,
  [OrderStatus.canceled]: OrderStatusGql.Canceled,
  [OrderStatus.refunded]: OrderStatusGql.Refunded,
  [OrderStatus.delivered]: OrderStatusGql.Delivered,
}

export const OrderStatusMappingReverse = {
  [OrderStatusGql.Pending]: 'pending',
  [OrderStatusGql.Shipped]: 'shipped',
  [OrderStatusGql.Canceled]: 'canceled',
  [OrderStatusGql.Refunded]: 'refunded',
  [OrderStatusGql.Delivered]: 'delivered',
}

export const OrderPaymentStatusMappingReverse = {
  [OrderPaymentStatus.Failed]: PaymentStatus.failed,
  [OrderPaymentStatus.InProgress]: PaymentStatus.in_progress,
  [OrderPaymentStatus.PendingSepaDebit]: PaymentStatus.pending_sepa_debit,
  [OrderPaymentStatus.Success]: PaymentStatus.success,
}
