import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfigureStripeSellerBankAccountMutationVariables = Types.Exact<{
  input: Types.ConfigureStripeBankAccountForViewerInput;
}>;


export type ConfigureStripeSellerBankAccountMutation = { __typename?: 'Mutation', configureStripeSellerBankAccountForViewer: { __typename?: 'ConfigureStripeBankAccountForViewerMutationPayload', ok: boolean, viewer: { __typename?: 'User', id: string, sellerConfig?: { __typename?: 'SellerConfig', hasBankAccountConfiguredInStripe: boolean } | null } } };

export type CreateProSellerMutationVariables = Types.Exact<{
  input: Types.CreateProSellerInput;
}>;


export type CreateProSellerMutation = { __typename?: 'Mutation', createProSeller: { __typename?: 'CreateProSellerPayload', ok: boolean } };

export type CreateIndividualSellerMutationVariables = Types.Exact<{
  input: Types.CreateIndividualSellerInput;
}>;


export type CreateIndividualSellerMutation = { __typename?: 'Mutation', createIndividualSeller: { __typename?: 'CreateIndividualSellerPayload', ok: boolean } };

export type UpdateProSellerCompanyMutationVariables = Types.Exact<{
  input: Types.UpdateProSellerCompanyInput;
}>;


export type UpdateProSellerCompanyMutation = { __typename?: 'Mutation', updateProSellerCompany: { __typename?: 'UpdateProSellerCompanyPayload', ok: boolean } };

export type GetParentCategoriesOrderedWithCategoriesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type GetParentCategoriesOrderedWithCategoriesQuery = { __typename?: 'Query', parentCategoriesOrderedByPosition: { __typename?: 'ParentCategoryConnection', edges: Array<{ __typename?: 'ParentCategoryEdge', node: { __typename?: 'ParentCategory', name: string, slug: string, id: string, allCategories: Array<{ __typename?: 'Category', name: string, id: string, slug: string }> } }> } };

export type SearchSellerByUsernameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
  first: Types.Scalars['Int']['input'];
}>;


export type SearchSellerByUsernameQuery = { __typename?: 'Query', searchSellersByUsername: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'User', id: string, username: string } }> } };


export const ConfigureStripeSellerBankAccountDocument = gql`
    mutation ConfigureStripeSellerBankAccount($input: ConfigureStripeBankAccountForViewerInput!) {
  configureStripeSellerBankAccountForViewer(input: $input) {
    ok
    viewer {
      id
      sellerConfig {
        hasBankAccountConfiguredInStripe
      }
    }
  }
}
    `;
export type ConfigureStripeSellerBankAccountMutationFn = Apollo.MutationFunction<ConfigureStripeSellerBankAccountMutation, ConfigureStripeSellerBankAccountMutationVariables>;

/**
 * __useConfigureStripeSellerBankAccountMutation__
 *
 * To run a mutation, you first call `useConfigureStripeSellerBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureStripeSellerBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureStripeSellerBankAccountMutation, { data, loading, error }] = useConfigureStripeSellerBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureStripeSellerBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureStripeSellerBankAccountMutation, ConfigureStripeSellerBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureStripeSellerBankAccountMutation, ConfigureStripeSellerBankAccountMutationVariables>(ConfigureStripeSellerBankAccountDocument, options);
      }
export type ConfigureStripeSellerBankAccountMutationHookResult = ReturnType<typeof useConfigureStripeSellerBankAccountMutation>;
export type ConfigureStripeSellerBankAccountMutationResult = Apollo.MutationResult<ConfigureStripeSellerBankAccountMutation>;
export type ConfigureStripeSellerBankAccountMutationOptions = Apollo.BaseMutationOptions<ConfigureStripeSellerBankAccountMutation, ConfigureStripeSellerBankAccountMutationVariables>;
export const CreateProSellerDocument = gql`
    mutation CreateProSeller($input: CreateProSellerInput!) {
  createProSeller(input: $input) {
    ok
  }
}
    `;
export type CreateProSellerMutationFn = Apollo.MutationFunction<CreateProSellerMutation, CreateProSellerMutationVariables>;

/**
 * __useCreateProSellerMutation__
 *
 * To run a mutation, you first call `useCreateProSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProSellerMutation, { data, loading, error }] = useCreateProSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProSellerMutation(baseOptions?: Apollo.MutationHookOptions<CreateProSellerMutation, CreateProSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProSellerMutation, CreateProSellerMutationVariables>(CreateProSellerDocument, options);
      }
export type CreateProSellerMutationHookResult = ReturnType<typeof useCreateProSellerMutation>;
export type CreateProSellerMutationResult = Apollo.MutationResult<CreateProSellerMutation>;
export type CreateProSellerMutationOptions = Apollo.BaseMutationOptions<CreateProSellerMutation, CreateProSellerMutationVariables>;
export const CreateIndividualSellerDocument = gql`
    mutation CreateIndividualSeller($input: CreateIndividualSellerInput!) {
  createIndividualSeller(input: $input) {
    ok
  }
}
    `;
export type CreateIndividualSellerMutationFn = Apollo.MutationFunction<CreateIndividualSellerMutation, CreateIndividualSellerMutationVariables>;

/**
 * __useCreateIndividualSellerMutation__
 *
 * To run a mutation, you first call `useCreateIndividualSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndividualSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndividualSellerMutation, { data, loading, error }] = useCreateIndividualSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndividualSellerMutation(baseOptions?: Apollo.MutationHookOptions<CreateIndividualSellerMutation, CreateIndividualSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIndividualSellerMutation, CreateIndividualSellerMutationVariables>(CreateIndividualSellerDocument, options);
      }
export type CreateIndividualSellerMutationHookResult = ReturnType<typeof useCreateIndividualSellerMutation>;
export type CreateIndividualSellerMutationResult = Apollo.MutationResult<CreateIndividualSellerMutation>;
export type CreateIndividualSellerMutationOptions = Apollo.BaseMutationOptions<CreateIndividualSellerMutation, CreateIndividualSellerMutationVariables>;
export const UpdateProSellerCompanyDocument = gql`
    mutation UpdateProSellerCompany($input: UpdateProSellerCompanyInput!) {
  updateProSellerCompany(input: $input) {
    ok
  }
}
    `;
export type UpdateProSellerCompanyMutationFn = Apollo.MutationFunction<UpdateProSellerCompanyMutation, UpdateProSellerCompanyMutationVariables>;

/**
 * __useUpdateProSellerCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateProSellerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProSellerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProSellerCompanyMutation, { data, loading, error }] = useUpdateProSellerCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProSellerCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProSellerCompanyMutation, UpdateProSellerCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProSellerCompanyMutation, UpdateProSellerCompanyMutationVariables>(UpdateProSellerCompanyDocument, options);
      }
export type UpdateProSellerCompanyMutationHookResult = ReturnType<typeof useUpdateProSellerCompanyMutation>;
export type UpdateProSellerCompanyMutationResult = Apollo.MutationResult<UpdateProSellerCompanyMutation>;
export type UpdateProSellerCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateProSellerCompanyMutation, UpdateProSellerCompanyMutationVariables>;
export const GetParentCategoriesOrderedWithCategoriesDocument = gql`
    query getParentCategoriesOrderedWithCategories($first: Int!) {
  parentCategoriesOrderedByPosition(first: $first) {
    edges {
      node {
        name
        slug
        id
        allCategories {
          name
          id
          slug
        }
      }
    }
  }
}
    `;

/**
 * __useGetParentCategoriesOrderedWithCategoriesQuery__
 *
 * To run a query within a React component, call `useGetParentCategoriesOrderedWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCategoriesOrderedWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCategoriesOrderedWithCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetParentCategoriesOrderedWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables> & ({ variables: GetParentCategoriesOrderedWithCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>(GetParentCategoriesOrderedWithCategoriesDocument, options);
      }
export function useGetParentCategoriesOrderedWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>(GetParentCategoriesOrderedWithCategoriesDocument, options);
        }
export function useGetParentCategoriesOrderedWithCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>(GetParentCategoriesOrderedWithCategoriesDocument, options);
        }
export type GetParentCategoriesOrderedWithCategoriesQueryHookResult = ReturnType<typeof useGetParentCategoriesOrderedWithCategoriesQuery>;
export type GetParentCategoriesOrderedWithCategoriesLazyQueryHookResult = ReturnType<typeof useGetParentCategoriesOrderedWithCategoriesLazyQuery>;
export type GetParentCategoriesOrderedWithCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetParentCategoriesOrderedWithCategoriesSuspenseQuery>;
export type GetParentCategoriesOrderedWithCategoriesQueryResult = Apollo.QueryResult<GetParentCategoriesOrderedWithCategoriesQuery, GetParentCategoriesOrderedWithCategoriesQueryVariables>;
export const SearchSellerByUsernameDocument = gql`
    query SearchSellerByUsername($username: String!, $first: Int!) {
  searchSellersByUsername(username: $username, first: $first) {
    edges {
      node {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useSearchSellerByUsernameQuery__
 *
 * To run a query within a React component, call `useSearchSellerByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSellerByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSellerByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchSellerByUsernameQuery(baseOptions: Apollo.QueryHookOptions<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables> & ({ variables: SearchSellerByUsernameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>(SearchSellerByUsernameDocument, options);
      }
export function useSearchSellerByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>(SearchSellerByUsernameDocument, options);
        }
export function useSearchSellerByUsernameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>(SearchSellerByUsernameDocument, options);
        }
export type SearchSellerByUsernameQueryHookResult = ReturnType<typeof useSearchSellerByUsernameQuery>;
export type SearchSellerByUsernameLazyQueryHookResult = ReturnType<typeof useSearchSellerByUsernameLazyQuery>;
export type SearchSellerByUsernameSuspenseQueryHookResult = ReturnType<typeof useSearchSellerByUsernameSuspenseQuery>;
export type SearchSellerByUsernameQueryResult = Apollo.QueryResult<SearchSellerByUsernameQuery, SearchSellerByUsernameQueryVariables>;