import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SellerNewsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SellerNewsQuery = { __typename?: 'Query', sellerNews: Array<{ __typename?: 'SellerNewsPayload', message: string, title: string, url?: string | null, startAt?: Date | null, createdAt: Date }> };


export const SellerNewsDocument = gql`
    query SellerNews {
  sellerNews {
    message
    title
    url
    startAt
    createdAt
  }
}
    `;

/**
 * __useSellerNewsQuery__
 *
 * To run a query within a React component, call `useSellerNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerNewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSellerNewsQuery(baseOptions?: Apollo.QueryHookOptions<SellerNewsQuery, SellerNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerNewsQuery, SellerNewsQueryVariables>(SellerNewsDocument, options);
      }
export function useSellerNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerNewsQuery, SellerNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerNewsQuery, SellerNewsQueryVariables>(SellerNewsDocument, options);
        }
export function useSellerNewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SellerNewsQuery, SellerNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SellerNewsQuery, SellerNewsQueryVariables>(SellerNewsDocument, options);
        }
export type SellerNewsQueryHookResult = ReturnType<typeof useSellerNewsQuery>;
export type SellerNewsLazyQueryHookResult = ReturnType<typeof useSellerNewsLazyQuery>;
export type SellerNewsSuspenseQueryHookResult = ReturnType<typeof useSellerNewsSuspenseQuery>;
export type SellerNewsQueryResult = Apollo.QueryResult<SellerNewsQuery, SellerNewsQueryVariables>;