import { type ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'

import { useRequestSellerSubAccountLoginEmailMutation } from '../ResetPassword/operations.generated'

import './styles.scss'

type LoginSubAccountProps = {
  onCancel: () => void
}

const LoginSubAccount = (props: LoginSubAccountProps) => {
  const { t } = useTranslation()

  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [sellerUsername, setSellerUsername] = useState<string>('')
  const [subAccountEmail, setSubAccountEmail] = useState<string>('')
  const isValid = Boolean(sellerUsername.trim().length && subAccountEmail.trim().length)

  const [requestSellerSubAccountEmail] = useRequestSellerSubAccountLoginEmailMutation({
    onError: (error) => {
      setError(error.message)
    },
    onCompleted: () => {
      setSuccess(true)
    },
  })

  const handleRequestEmail = useCallback(
    async (username: string, email: string) => {
      await requestSellerSubAccountEmail({ variables: { sellerUsername: username, subAccountEmail: email } })
    },
    [requestSellerSubAccountEmail]
  )

  const handleSellerUsernameChange = useCallback(
    (event: ChangeEvent) => {
      const credential = (event.target as HTMLInputElement).value
      setSellerUsername(credential)
    },
    [setSellerUsername]
  )

  const handleEmailChange = useCallback(
    (event: ChangeEvent) => {
      const credential = (event.target as HTMLInputElement).value
      setSubAccountEmail(credential)
    },
    [setSubAccountEmail]
  )

  const { onCancel } = props
  const handleCancelClick = useCallback(() => {
    onCancel()
  }, [onCancel])

  const handleSubmit = useCallback(async () => {
    await handleRequestEmail(sellerUsername, subAccountEmail)
  }, [handleRequestEmail, sellerUsername, subAccountEmail])

  return (
    <Card className="login-sub-account">
      <Form
        error={error}
        id="login-sub-account-form"
        success={success}
        successMessage={t('loginSubAccountConfirmationMessage')}
        onSubmit={handleSubmit}
      >
        {!success && (
          <>
            <Field
              autoComplete="username"
              id="seller-username"
              label={t('loginSubAccountSellerUsernameLabel')}
              name="credential"
              placeholder={t('loginSubAccountSellerUsernamePlaceholder')}
              required={true}
              type="text"
              validation={false}
              onChange={handleSellerUsernameChange}
            />
            <Field
              autoComplete="email"
              id="credential"
              label={t('loginSubAccountEmailLabel')}
              name="credential"
              placeholder={t('loginSubAccountEmailPlaceholder')}
              required={true}
              type="email"
              validation={false}
              onChange={handleEmailChange}
            />
          </>
        )}
        <div className="actions">
          <Button className="cancel-action secondary" label={t('commonPrevious')} onClick={handleCancelClick} />
          {!success && (
            <Button
              className="validate-action primary"
              disabled={!isValid}
              htmlType="submit"
              label={t('commonValidate')}
            />
          )}
        </div>
      </Form>
    </Card>
  )
}

export default LoginSubAccount
