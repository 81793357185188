import { useStripe } from '@stripe/react-stripe-js'
import { Steps, message } from 'antd'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaPhone } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'

import { createSellerFiles } from '@/actions/create-seller-files'
import CustomCard from '@/components/CustomCard'
import Link from '@/components/ui/Link/Link'
import Config from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/localstorage'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import DocumentsSlide from '@/views/Signup/components/DocumentsSlide'
import InformationSlide from '@/views/Signup/components/InformationSlide'
import {
  createStripeCompanyToken,
  createStripeIndividualToken,
  createStripePersonToken,
} from '@/views/Signup/components/SignupForm/stripeOperations'
import { useCreateProSellerMutation, useCreateIndividualSellerMutation } from '@/views/Signup/operations.generated'

import type {
  ContactInformation,
  StripeAccountTokenCreationParams,
  StripeCompanyTokenCreationParams,
  StripeIndividualTokenCreationParams,
  StripePersonTokenCreationParams,
} from './types'
import type { Stripe, TokenResult } from '@stripe/stripe-js'

import './SignupForm.scss'

const { VITE_APP_LONG_TIMEOUT } = Config

// TODO: move to a shared util file
// Removes unexpected +3333 of phone numbers (TODO: this should be fixed elsewhere)
const sanitizePhoneNumber = (phoneNumber: string | undefined | null) => {
  if (!phoneNumber) return ''
  if (phoneNumber.startsWith('+3333')) return phoneNumber?.replace('+3333', '+33')

  return phoneNumber
}

export const SignupForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const stripe = useStripe() as Stripe

  const { user: seller, fetchUser } = useUser()

  const [current, setCurrent] = useState<number>(0)
  const hasPhoneNumber = !!seller?.phoneNumber

  const [isProfessional, setIsProfessional] = useState<boolean>(false)
  const storedContactInformation = JSON.parse(getFromLocalStorage('onboardingSellerInfo') || '{}')

  const sellerAddress = seller?.shippingAddresses.length ? seller.shippingAddresses[0] : null
  const [contactInformation, setContactInformation] = useState<ContactInformation>({
    ...storedContactInformation,
    firstname: seller?.firstName || storedContactInformation?.firstname || '',
    lastname: seller?.lastName || storedContactInformation?.lastname || '',
    // birthdate: seller?.birthdate || storedContactInformation?.birthdate || '',
    birthdate: storedContactInformation?.birthdate || '', // replace this by the line above when the backend will be ready
    address: sellerAddress?.inputLine1 || storedContactInformation?.address || '',
    zipcode: sellerAddress?.inputPostalCode || storedContactInformation?.zipcode || '',
    city: sellerAddress?.inputCity || storedContactInformation?.city || '',
    state: sellerAddress?.region || storedContactInformation?.state || '',
    country: sellerAddress?.resolvedCountry.iso2Code || storedContactInformation?.country || '',
    phoneNumber: seller?.phoneNumber || storedContactInformation?.phoneNumber || '',
    favoriteParentCategoryId: null,
    favoriteCategoryId: null,
    externalSalesAmount: null,
    canSellerShipFromTheirCountry: false,
    referrerUserId: null,
  })

  const [individualInformation, setIndividualInformation] = useState<IndividualInformation>({} as IndividualInformation)
  const storedCompanyInformation = JSON.parse(getFromLocalStorage('onboardingCompanyInfo') || '{}')
  const [companyInformation, setCompanyInformation] = useState<CompanyInformation>({
    ...storedCompanyInformation,
  } as CompanyInformation)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  const [createProSellerMutation] = useCreateProSellerMutation()
  const [createIndividualSellerMutation] = useCreateIndividualSellerMutation()

  // Returns the file to upload to the server regarding if the user is a pro or not
  const computeUserFiles = () => {
    if (isProfessional) {
      const { documentFront, documentBack } = companyInformation

      return {
        companyDocumentFront: documentFront,
        companyDocumentBack: documentBack,
      }
    } else {
      const { idFront, idBack, idAddFront } = individualInformation
      return { idFront, idBack, idAddFront }
    }
  }

  // Builds the params to send for a Stripe account creation regarding if the user is a professional or not
  function computeAccountTokenCreationParams(createFileResponse: any): StripeAccountTokenCreationParams {
    if (!seller) {
      //this should never happen but it's for typescript
      throw new Error('No user found')
    }
    if (isProfessional) {
      return {
        address: companyInformation.address,
        city: companyInformation.city,
        country: companyInformation.country,
        documentBack: createFileResponse.payload.find((el: any) => el.name === 'companyDocumentBack')?.id,
        documentFront: createFileResponse.payload.find((el: any) => el.name === 'companyDocumentFront')?.id,
        email: companyInformation.email,
        lucidId: companyInformation.lucidId,
        name: companyInformation.name,
        phone: sanitizePhoneNumber(companyInformation.phone),
        postalCode: companyInformation.postalCode,
        state: companyInformation.state,
        taxId: companyInformation.taxId,
        taxIdRegistrar: companyInformation.taxIdRegistrar,
        vatId: companyInformation.vatId,
      }
    } else {
      return {
        address: contactInformation.address,
        birthdate: contactInformation.birthdate,
        city: contactInformation.city,
        country: contactInformation.country,
        firstname: contactInformation.firstname,
        idAddFront: createFileResponse.payload.find((el: any) => el.name === 'idAddFront').id,
        idBack: createFileResponse.payload.find((el: any) => el.name === 'idBack').id,
        idFront: createFileResponse.payload.find((el: any) => el.name === 'idFront').id,
        lastname: contactInformation.lastname,
        phone: sanitizePhoneNumber(contactInformation.phoneNumber),
        postalCode: contactInformation.zipcode,
        seller,
        state: contactInformation.state.length ? contactInformation.state : undefined,
      }
    }
  }

  // Using actual information stored in individual/company information, create the account token on stripe
  // Returns the raw api response
  async function fetchStripeAccountTokenCreation(createFileResponse: any): Promise<TokenResult> {
    //  create user token on stripe
    const accountRegisterParams: StripeAccountTokenCreationParams =
      computeAccountTokenCreationParams(createFileResponse)

    if (isProfessional) {
      return createStripeCompanyToken(stripe, accountRegisterParams as StripeCompanyTokenCreationParams)
    } else {
      return createStripeIndividualToken(stripe, accountRegisterParams as StripeIndividualTokenCreationParams)
    }
  }

  // compute the params sent to create the person on stripe
  // We decided to use the user information as only owners can register their company on our site for now
  function computeStripePersonTokenParams(): StripePersonTokenCreationParams {
    if (!seller) {
      throw new Error('No user found') //this should never happen but it's for typescript
    }
    return {
      address: companyInformation.address,
      birthdate: contactInformation.birthdate,
      city: companyInformation.city,
      country: companyInformation.country,
      firstname: contactInformation.firstname,
      lastname: contactInformation.lastname,
      phone: sanitizePhoneNumber(companyInformation.phone),
      state: companyInformation.state,
      postalCode: companyInformation.postalCode,
      email: seller.email,
      relationship: 'owner', // hardcoded for now
      title: 'CEO', // hardcoded for now
    }
  }

  // Uploads user files to the server
  // Throws a displayable error with message on failure
  const uploadSellerFiles = async () => {
    const filesToUpload = computeUserFiles()
    const createFileResponse = await dispatch(createSellerFiles(filesToUpload))
    const { type: filesResult } = createFileResponse

    if (filesResult.indexOf('rejected') !== -1) {
      throw Error(createFileResponse?.payload?.details || 'Impossible de valider ton compte pour le moment.')
    }

    if (!stripe || filesResult.indexOf('fulfilled') === -1) {
      throw Error("L'upload de tes fichiers a échoué")
    }

    return createFileResponse
  }

  // Using the stripe token, updates the account on the server to a seller profile
  // Throws a displayable error with message on failure
  const createSellerOnServer = async (accountToken: string, personToken?: string) => {
    const extraParams = {
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onError: (error: any) => {
        throw new Error(error.message || t('signupRequestRejected'))
      },
      onCompleted: (data: any) => {
        if (!data) throw new Error(t('signupRequestRejected'))
        trackEvent('SIGNUP_COMPLETE', {
          isProfessional,
          country: isProfessional ? companyInformation.country : contactInformation.country,
        })
      },
    }

    if (isProfessional) {
      const variables = {
        input: {
          accountToken,
          personToken: personToken!,
          favoriteParentCategoryId: contactInformation.favoriteParentCategoryId,
          favoriteCategoryId: contactInformation.favoriteCategoryId,
          externalSalesAmount: contactInformation.externalSalesAmount,
          canSellerShipFromTheirCountry: contactInformation.canSellerShipFromTheirCountry,
          referrerId: contactInformation.referrerUserId,
          // Following params are necessary for the backend to store the user data
          // and not found in the previous tokens
          taxId: companyInformation.taxId,
          taxIdRegistrar: companyInformation.taxIdRegistrar,
          lucidId: companyInformation.lucidId,
          userCountry: companyInformation.country,
          vatId: companyInformation.vatId,
        },
      }

      return createProSellerMutation({ variables, ...extraParams })
    } else {
      const variables = {
        input: {
          accountToken,
          userCountry: contactInformation.country,
          favoriteParentCategoryId: contactInformation.favoriteParentCategoryId,
          favoriteCategoryId: contactInformation.favoriteCategoryId,
          externalSalesAmount: contactInformation.externalSalesAmount,
          canSellerShipFromTheirCountry: contactInformation.canSellerShipFromTheirCountry,
          referrerId: contactInformation.referrerUserId,
        },
      }

      return createIndividualSellerMutation({ variables, ...extraParams })
    }
  }

  // Called on DocumentSlide submit button, runs the whole sign up process for a seller
  const onDocumentSubmit = async () => {
    setIsSubmitLoading(true)

    trackEvent('SELLER_ONBOARDING_DOCUMENTS_SUBMITTED')
    try {
      const createFilesResponse = await uploadSellerFiles()

      const accountTokenResponse = await fetchStripeAccountTokenCreation(createFilesResponse)

      if (accountTokenResponse.error) {
        throw new Error(accountTokenResponse.error.message)
      }

      let personTokenResponse
      if (isProfessional) {
        personTokenResponse = await createStripePersonToken(stripe, computeStripePersonTokenParams())
        if (personTokenResponse.error) {
          throw new Error(personTokenResponse.error.message)
        }
      }

      const accountToken = accountTokenResponse.token.id
      const personToken = personTokenResponse?.token?.id

      //We're checking if the seller already has a seller config, and if he has a seller config this mean we don't need do create the account
      //We will need to decide later if we want to allow the seller to update the data, and if so, we need the backend mutation to be updated accordingly

      if (!seller?.sellerConfig) {
        await createSellerOnServer(accountToken, personToken)
        trackEvent('SELLER_ONBOARDING_DOCUMENTS_SUCCESS')

        // Remove onboarding temporary local storage data
        removeFromLocalStorage('onboardingSellerInfo')
        removeFromLocalStorage('onboardingBankInfo')
        // await fetchUser()
      }

      // Redirect to home if update mode or shows if registering
      fetchUser()
      window.location.href = '/shows'
    } catch (error: any) {
      trackError(error, {
        meta: {
          feature: 'onboarding.document.upload',
          scope: 'SignupForm.onDocumentSubmit',
        },
      })

      trackEvent('SELLER_ONBOARDING_DOCUMENTS_FAIL')

      if (error instanceof Error) message.error(error.message)
      else message.error(error as string)
      setIsSubmitLoading(false)
    }
  }

  // We need to update the phone number once the seller has been loaded
  useEffect(() => {
    setContactInformation({ ...contactInformation, phoneNumber: seller?.phoneNumber || '' })
  }, [seller?.phoneNumber])

  const stepItems = isProfessional
    ? [{ title: t('signupFormStepsInformations') }, { title: t('signupFormStepsCompanyInformations') }]
    : [{ title: t('signupFormStepsInformations') }, { title: t('signupFormStepsDocuments') }]

  if (!seller?.email) return null

  return (
    <div className="signup">
      <span className="title_2">{t('signupFormBankAccountTitle')}</span>
      <CustomCard
        extra={<Steps current={current} items={stepItems} size="small" onChange={(current) => setCurrent(current)} />}
        isHeaderCentered
        isStretch
      >
        <div>
          {
            {
              0: (
                <InformationSlide
                  contactInformation={contactInformation}
                  current={current}
                  hasPhoneNumber={hasPhoneNumber}
                  isProfessional={isProfessional}
                  setContactInformation={setContactInformation}
                  setCurrent={setCurrent}
                  setIsProfessional={setIsProfessional}
                />
              ),
              1: (
                <DocumentsSlide
                  companyInformation={companyInformation}
                  current={current}
                  individualInformation={individualInformation}
                  isProfessional={isProfessional}
                  isSubmitLoading={isSubmitLoading}
                  setCompanyInformation={setCompanyInformation}
                  setCurrent={setCurrent}
                  setIndividualInformation={setIndividualInformation}
                  onDocumentSubmit={onDocumentSubmit}
                />
              ),
            }[current]
          }
        </div>
      </CustomCard>

      <p className="tos-info">
        <Trans
          i18nKey="signupLegalAgreementLinks"
          components={{
            s: (
              <a
                className="callout_6"
                href={t('supportLinkArticleTermsAndConditions')}
                rel="noreferrer"
                target="_blank"
              />
            ),
            l: (
              <a
                className="callout_6"
                href="https://stripe.com/connect-account/legal"
                rel="noreferrer"
                target="_blank"
              />
            ),
          }}
          values={{
            signupLegalAgreementGCU: t('signupLegalAgreementGCU'),
            signupLegalAgreementStripe: t('signupLegalAgreementStripe'),
          }}
        />
      </p>
      <Link
        className="request-onboarding-session-action"
        href={t('signUpFormRequestOnboardingSessionLink')}
        target="blank"
      >
        <FaPhone />
        {t('signUpFormRequestOnboardingSessionLinkLabel')}
      </Link>
    </div>
  )
}
