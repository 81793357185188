import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import LearningIcon from '@/components/ui/Icons/LearningIcon/LearningIcon'

const QuickStart = () => {
  const { t } = useTranslation()

  return (
    <Button
      className="quick-start-action"
      icon={<LearningIcon />}
      label={t('showQuickStartLabel')}
      tooltip={t('showQuickStartLabel')}
      onClick={() => window.open(t('showQuickStartLink'), '_blank')}
    />
  )
}

export default QuickStart
