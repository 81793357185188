const MoreOptionsIcon = () => {
  return (
    <svg>
      <path
        clipRule="evenodd"
        d="M17.0278 1.5C20.6548 1.5 23.0918 4.046 23.0918 7.835V16.665C23.0918 20.454 20.6548 23 17.0278 23H7.6558C4.0288 23 1.5918 20.454 1.5918 16.665V7.835C1.5918 4.046 4.0288 1.5 7.6558 1.5H17.0278ZM17.0278 3H7.6558C4.8838 3 3.0918 4.897 3.0918 7.835V16.665C3.0918 19.603 4.8838 21.5 7.6558 21.5H17.0278C19.8008 21.5 21.5918 19.603 21.5918 16.665V7.835C21.5918 4.897 19.8008 3 17.0278 3ZM12.3418 7.8273C12.7558 7.8273 13.0918 8.1633 13.0918 8.5773V11.49L16.0083 11.4902C16.4223 11.4902 16.7583 11.8262 16.7583 12.2402C16.7583 12.6542 16.4223 12.9902 16.0083 12.9902L13.0918 12.99V15.9043C13.0918 16.3183 12.7558 16.6543 12.3418 16.6543C11.9278 16.6543 11.5918 16.3183 11.5918 15.9043V12.99L8.6753 12.9902C8.2603 12.9902 7.9253 12.6542 7.9253 12.2402C7.9253 11.8262 8.2603 11.4902 8.6753 11.4902L11.5918 11.49V8.5773C11.5918 8.1633 11.9278 7.8273 12.3418 7.8273Z"
        fillOpacity="0.8"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default MoreOptionsIcon
