const SearchIcon = () => {
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="6.84442"
        cy="6.84442"
        r="5.99237"
        stroke="#222C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
      <path
        d="M11.0122 11.3235L13.3616 13.6667"
        stroke="#222C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default SearchIcon
