const StarIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9457 9.54675C11.773 9.71408 11.6937 9.95608 11.733 10.1934L12.3257 13.4734C12.3757 13.7514 12.2584 14.0327 12.0257 14.1934C11.7977 14.3601 11.4944 14.3801 11.2457 14.2467L8.29305 12.7067C8.19038 12.6521 8.07638 12.6227 7.95971 12.6194H7.77905C7.71638 12.6287 7.65505 12.6487 7.59905 12.6794L4.64571 14.2267C4.49971 14.3001 4.33438 14.3261 4.17238 14.3001C3.77771 14.2254 3.51438 13.8494 3.57905 13.4527L4.17238 10.1727C4.21171 9.93341 4.13238 9.69008 3.95971 9.52008L1.55238 7.18675C1.35105 6.99141 1.28105 6.69808 1.37305 6.43341C1.46238 6.16941 1.69038 5.97675 1.96571 5.93341L5.27905 5.45275C5.53105 5.42675 5.75238 5.27341 5.86571 5.04675L7.32571 2.05341C7.36038 1.98675 7.40505 1.92541 7.45905 1.87341L7.51905 1.82675C7.55038 1.79208 7.58638 1.76341 7.62638 1.74008L7.69905 1.71341L7.81238 1.66675H8.09305C8.34371 1.69275 8.56438 1.84275 8.67971 2.06675L10.159 5.04675C10.2657 5.26475 10.473 5.41608 10.7124 5.45275L14.0257 5.93341C14.3057 5.97341 14.5397 6.16675 14.6324 6.43341C14.7197 6.70075 14.6444 6.99408 14.439 7.18675L11.9457 9.54675Z"
        fill="none"
        stroke="#171514"
      />
    </svg>
  )
}

export default StarIcon
