import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopItemFragment = { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number, totalAmountRefundedInCurrencyCents: number, totalShippingFeesInCurrencyCents: number, totalProductsSoldInCurrencyCents: number, isSupplier: boolean, terminatedAt?: Date | null };

export type GetAllShopsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllShopsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', shopsByStartAtDescForSeller: { __typename?: 'ShowConnection', edges: Array<{ __typename?: 'ShowEdge', node: { __typename?: 'Show', id: string, legacyId: number, name: string, isBroadcasting: boolean, startAt: Date, currency: Types.Currency, thumbnailWebPUrl?: string | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number, totalAmountRefundedInCurrencyCents: number, totalShippingFeesInCurrencyCents: number, totalProductsSoldInCurrencyCents: number, isSupplier: boolean, terminatedAt?: Date | null } }> } } | null };

export const ShopItemFragmentDoc = gql`
    fragment ShopItem on Show {
  id
  legacyId
  name
  isBroadcasting
  startAt
  currency
  thumbnailWebPUrl(options: {width: 100})
  totalNetIncomeInCurrencyCents
  totalCommissionInCurrencyCents
  totalAmountOrdersSuccessInCurrencyCents
  totalDeductedShippingCostInCurrencyCents
  totalAmountOrdersNetInCurrencyCents
  totalAmountRefundedInCurrencyCents
  totalShippingFeesInCurrencyCents
  totalProductsSoldInCurrencyCents
  isSupplier
  terminatedAt
}
    `;
export const GetAllShopsDocument = gql`
    query GetAllShops {
  viewer {
    shopsByStartAtDescForSeller(first: 1000) {
      edges {
        node {
          ...ShopItem
        }
      }
    }
  }
}
    ${ShopItemFragmentDoc}`;

/**
 * __useGetAllShopsQuery__
 *
 * To run a query within a React component, call `useGetAllShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllShopsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllShopsQuery, GetAllShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllShopsQuery, GetAllShopsQueryVariables>(GetAllShopsDocument, options);
      }
export function useGetAllShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllShopsQuery, GetAllShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllShopsQuery, GetAllShopsQueryVariables>(GetAllShopsDocument, options);
        }
export function useGetAllShopsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllShopsQuery, GetAllShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllShopsQuery, GetAllShopsQueryVariables>(GetAllShopsDocument, options);
        }
export type GetAllShopsQueryHookResult = ReturnType<typeof useGetAllShopsQuery>;
export type GetAllShopsLazyQueryHookResult = ReturnType<typeof useGetAllShopsLazyQuery>;
export type GetAllShopsSuspenseQueryHookResult = ReturnType<typeof useGetAllShopsSuspenseQuery>;
export type GetAllShopsQueryResult = Apollo.QueryResult<GetAllShopsQuery, GetAllShopsQueryVariables>;