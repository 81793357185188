import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type ProductModelFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ProductModelField = (props: ProductModelFieldProps) => {
  const { value: model, onChange: handleModelChange, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      disabled={disabled}
      label={t('productFormModelLabel')}
      name={'model'}
      placeholder={t('productFormModelPlaceholder')}
      value={model}
      onChange={handleModelChange}
    />
  )
}

export default ProductModelField
