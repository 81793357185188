import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaLock } from 'react-icons/fa6'

import CopyableText from '@/components/CopyableText'
import DeleteShow from '@/components/DeleteShow/DeleteShow'
import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'
import Button from '@/components/ui/Button/Button'
import { isShowUpcoming } from '@/util/showUtils'
import OpenShowInApp from '@/views/Show/components/ShowInfos/OpenShowInApp/OpenShowInApp'
import StopShow from '@/views/Show/components/ShowInfos/StopShow/StopShow'
import ShowReplayLink from '@/views/Show/components/ShowReplayLink/ShowReplayLink'
import { type ShowByIdFragment } from '@/views/Show/operations.generated'

import EditShow from '../EditShow/EditShow'

import type { ShowFormStep } from '../../Show'

import './ShowInfos.scss'

interface ShowInfosProps {
  show: ShowByIdFragment
  isShowLaunched: boolean
  onEdit?: (string: ShowFormStep) => void
}

const ShowInfos = (props: ShowInfosProps) => {
  const { show, isShowLaunched, onEdit = () => undefined } = props

  const { legacyId: showId, name = '', isBroadcasting, replayAssets, terminatedAt } = show || {}
  const { t } = useTranslation()

  const showUrl = showId ? `https://voggt.com/shows/${showId}` : ''
  const hasReplayAssets = (replayAssets || []).length > 0
  // Currently (fev 2024), shows can have several replay assets, if the show has been interrupted at some point
  // In such case, we will display as much replay links as there are assets
  // Until the moment were we will have a single replay asset that would be a concatenation of all the assets (WIP)
  const hasSeveralReplayAssets = (replayAssets || []).length > 1

  const isOfflineShop = show.isOffline
  const hasAlreadyBeenTerminated = !!terminatedAt
  const isUpcoming = isShowUpcoming(show)
  const isStartable = Boolean(isUpcoming && !hasAlreadyBeenTerminated && !isBroadcasting && !isOfflineShop)
  const isStoppable = Boolean((isBroadcasting || isOfflineShop) && !hasAlreadyBeenTerminated)
  const formattedTerminatedAtDate = terminatedAt ? dayjs.utc(terminatedAt).format('YYYY-MM-DD') : ''
  const formattedTerminatedAtTime = terminatedAt ? dayjs.utc(terminatedAt).format('HH:mm') : ''

  return (
    <div className="show-info">
      {isStartable && <OpenShowInApp showId={showId} />}
      {isStoppable && <StopShow isOfflineShop={isOfflineShop} showId={showId} />}
      {hasAlreadyBeenTerminated && (
        <Button
          className="ended"
          icon={<FaLock />}
          tooltipPosition="bottom left"
          tooltip={t('showTerminatedEndedAtTitle', {
            date: formattedTerminatedAtDate,
            time: formattedTerminatedAtTime,
          })}
        />
      )}
      {!isStartable && !isStoppable && !hasAlreadyBeenTerminated && <div className="action"></div>}
      <div className="show-name-and-actions">
        <span className="show-name">{name}</span>
        {!isShowLaunched && (
          <>
            <EditShow onEdit={onEdit} />
            <DeleteShow showId={showId} />
          </>
        )}
      </div>

      <span className="show-url">
        {showUrl && <CopyableText title={t('showUrlCopyToClipboardTitle')} value={showUrl} />}
      </span>
      {hasReplayAssets && (
        <NewFeatureIntroducer
          alreadySeenKey="hasSeenShowReplayLink"
          className="show-replay-link"
          content={<p>{t('showReplayLinkFeatureIntroducerText')}</p>}
        >
          {(replayAssets || []).map((replayAsset, i) => (
            <ShowReplayLink
              key={i}
              part={hasSeveralReplayAssets ? i + 1 : undefined}
              replayUrl={replayAsset.downloadUrl}
              showId={showId}
            />
          ))}
        </NewFeatureIntroducer>
      )}
    </div>
  )
}

export default memo(ShowInfos)
