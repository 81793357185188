import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Link from '@/components/ui/Link/Link'
import { notificationDanger } from '@/components/ui/Notification/Notification'

import { useGetBulkUnshippedShippingAddressStickersLazyQuery } from './operations.generated'

import './StickerPdf.scss'

const StickerPdf = () => {
  const { t } = useTranslation()

  const [getShippingAddressStickers] = useGetBulkUnshippedShippingAddressStickersLazyQuery()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadStickerPdfClick = async () => {
    setIsLoading(true)

    getShippingAddressStickers({
      onCompleted: (data) => {
        window.open(data.bulkUnshippedShippingAddressStickers.label, '_blank')
        setIsLoading(false)
      },
      onError: (error) => {
        notificationDanger(error?.message)
        setIsLoading(false)
      },
    })
  }

  return (
    <div className="download-sticker-pdf">
      <span>{t('orderTableShippingModalDownloadPdf')}</span>
      <Link className="download-sticker-pdf-action" isLoading={isLoading} onClick={handleDownloadStickerPdfClick}>
        {t('orderTableShippingModalDownloadPdfHere')}
      </Link>
    </div>
  )
}

export default StickerPdf
