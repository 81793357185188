import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { store, type RootState } from '@/reducers'
import { internalRequest } from '@/util/request'

interface DeleteProductPictureProps {
  fileId: number
  productId: number
}

export const deleteProductPicture = async ({ fileId, productId }: DeleteProductPictureProps) => {
  const {
    authentication: { token },
  } = store.getState() as RootState

  try {
    const { data } = await internalRequest({
      headers: {
        authorization: token,
        ...(await getAdditionalHeaders()),
      },
      url: `/products/${productId}/uploads/${fileId}`,
      method: 'DELETE',
      dispatch: store.dispatch,
    })

    return data
  } catch (error: any) {
    if (isAxiosError(error)) {
      return Promise.reject(error.response?.data)
    }
  }
}
