import { Table } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useUser } from '@/contexts/user/User.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import { useGetSellerShowsForAggregatedMonthQuery, type ShowItemFragment } from '../../operations.generated'

import { getTableColumns } from './utils'

import './style.scss'

export interface ShowsTableProps {
  aggregatedMonthId: string
}

export const ShowsTable = ({ aggregatedMonthId }: ShowsTableProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const navigate = useNavigate()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics } = sellerConfig || {}

  const { data, loading } = useGetSellerShowsForAggregatedMonthQuery({
    variables: { sellerShowsAggregatedByMonthId: aggregatedMonthId },
    fetchPolicy: 'cache-first',
  })

  const columns = getTableColumns({ currencyFormatter, isShop: false, t })

  const columnsToDisplay = useMemo(
    () =>
      canAccessHomeMetrics
        ? columns
        : columns.filter(
            ({ key }) =>
              key !== 'totalNetIncomeInCurrencyCents' &&
              key !== 'totalCommissionInCurrencyCents' &&
              key !== 'totalAmountRefundedInCurrencyCents' &&
              key !== 'totalDeductedShippingCostInCurrencyCents' &&
              key !== 'totalShippingFeesInCurrencyCents' &&
              key !== 'totalProductsSoldInCurrencyCents'
          ),
    [canAccessHomeMetrics, columns]
  )

  const shows = data?.sellerShowsForAggregatedMonth.edges.map((edge) => edge.node)

  if (loading || !shows) return null

  return (
    <Table
      className="shows-table"
      columns={columnsToDisplay}
      dataSource={shows}
      pagination={false}
      rowClassName="cursor-pointer"
      rowKey="id"
      size="small"
      onRow={(record: ShowItemFragment) => {
        return {
          onClick: () => navigate(`/shows/${record.legacyId}`),
        }
      }}
    />
  )
}
