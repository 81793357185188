import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegFileVideo } from 'react-icons/fa'

import Link from './../../../../components/ui/Link/Link'

import './style.scss'

type ShowReplayLinkProps = {
  part?: number
  showId: number
  replayUrl?: string
}

const ShowReplayLink = (props: ShowReplayLinkProps) => {
  const { showId, replayUrl, part } = props
  const { t } = useTranslation()
  const isDisabled = !replayUrl
  const labelPartSuffix = part ? ` (${part})` : ''

  return (
    <Link
      aria-disabled={isDisabled}
      className={`show-replay-asset-url ${isDisabled ? 'is-disabled' : ''}`}
      download={`show-${showId}-replay${part ? `-${part}` : ''}.mp4`}
      href={replayUrl}
      rel="external nofollow noreferrer"
      target="_blank"
      title={t('showReplayAssetLinkTitle') + labelPartSuffix}
      onClick={(e) => e?.stopPropagation()}
    >
      <span className="icon">
        <FaRegFileVideo />
      </span>
      <span className="label">
        {t('showReplayAssetLinkLabel')}
        {labelPartSuffix}
      </span>
    </Link>
  )
}

export default memo(ShowReplayLink)
