import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import RatingStars from '@/components/ui/RatingStars/RatingStars'
import ShowRatingReasonField from '@/views/Show/components/RateShow/ShowRatingForm/ShowRatingReasonField/ShowRatingReasonField'
import ShowRatingReviewField, {
  SHOW_RATING_REVIEW_FIELD_MIN_LENGTH,
} from '@/views/Show/components/RateShow/ShowRatingForm/ShowRatingReviewField/ShowRatingReviewField'

import type { RatingValue } from '@/components/ui/RatingStars/RatingStars'

const SHOW_RATING_SCORE_MAX = 5

export type ShowRatingFormProps = {
  onSubmit?: (score: number, review: string, reason: string) => void
  onCancel?: () => void
}

export const ShowRatingForm = (props: ShowRatingFormProps) => {
  const { onSubmit = () => undefined, onCancel = () => undefined } = props

  const { t } = useTranslation()

  const reasonOptions = useMemo(
    () => [
      { value: 'other', label: t('ShowRatingModalIssueOther') }, // default
      { value: 'community', label: t('ShowRatingModalIssueCommunity') },
      { value: 'lag', label: t('ShowRatingModalIssueLag') },
      { value: 'sale', label: t('ShowRatingModalIssueSale') },
      { value: 'video', label: t('ShowRatingModalIssueVideo') },
    ],
    [t]
  )

  const [score, setScore] = useState<RatingValue>(0)
  const [review, setReview] = useState<string>('')
  const [reason, setReason] = useState<string>(reasonOptions[0].value)
  // const [isReviewValid, setIsReviewValid] = useState<boolean>(false)

  const handleScoreChange = useCallback((value) => {
    setScore(value)
  }, [])
  const handleReviewChange = useCallback((event) => setReview(event.target.value), [])
  const handleReasonChange = useCallback((event) => setReason(event.target.value), [])
  // const handleReviewValidityChange = useCallback((isValid) => setIsReviewValid(isValid), [])

  const handleSubmit = useCallback(() => {
    onSubmit(score, review, reason)
  }, [onSubmit, score, review, reason])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const hasNotSelectedMaxScore = Boolean(score && score < SHOW_RATING_SCORE_MAX)
  const isReviewRequired = hasNotSelectedMaxScore
  const isReviewValid = review.trim().length >= SHOW_RATING_REVIEW_FIELD_MIN_LENGTH // TODO: get this onValidityChange instead
  const isFormValid = score === 5 || Boolean(score && isReviewValid && reason)

  return (
    <Form onSubmit={handleSubmit}>
      <Field input={<RatingStars rating={score} onChange={handleScoreChange} />} name="rating" type="custom" />
      <ShowRatingReviewField
        required={isReviewRequired}
        value={review}
        onChange={handleReviewChange}
        // onValidityChange={handleReviewValidityChange} // TODO: Buggy for this use case where constraints (minLength & required) changes sometimes. Fix it.
      />
      <ShowRatingReasonField options={reasonOptions} value={reason} onChange={handleReasonChange} />
      <div className="actions">
        <Button className="cancel-action cancel-show-rating-action" label={t('commonCancel')} onClick={handleCancel} />
        <Button
          className="validate-action send-show-rating-action primary"
          disabled={!isFormValid}
          label={t('ShowRatingModalButton')}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  )
}
