import { useTranslation } from 'react-i18next'

import ProductsBulkImportForm2 from '@/components/ImportProducts/ProductsBulkImportForm2/ProductsBulkImportForm2'
import Dialog from '@/components/ui/Dialog/Dialog'
import { InventoryContainer } from '@/views/Inventory/InventoryContainer'

import './ImportProducts.scss'

type ImportProductsProps = {
  onClose?: () => void
  showId?: any
  onImported?: () => void
  activeTab?: string
}

const ImportProducts = (props: ImportProductsProps) => {
  const { showId, onClose = () => {}, onImported = () => undefined, activeTab } = props

  const { t } = useTranslation()

  return (
    <div className="import-products import-show-products">
      {activeTab && (
        <Dialog
          className="import-products-dialog import-show-products-dialog is-drawer is-animated"
          isOpen={true}
          title={<h2 className="title">{t('showProductsImportDialogTitle')}</h2>}
          onClose={onClose}
        >
          {activeTab === 'inventory' && showId && (
            <InventoryContainer isImporter={true} showId={showId} onImported={onImported} />
          )}
          {activeTab === 'bulk' && (
            <ProductsBulkImportForm2 showId={showId} onCancel={onClose} onImported={onImported} />
          )}
        </Dialog>
      )}
    </div>
  )
}

export default ImportProducts
