export interface StatusTagProps {
  type:
    | 'success'
    | 'error'
    | 'pending'
    | 'in_progress'
    | 'delivered'
    | 'shipped'
    | 'label_downloaded'
    | 'ready_to_ship'
    | 'received'
    | 'payment_success'
    | 'payment_in_progress'
    | 'payment_failed'
    | 'in_transit'
  message: React.ReactNode | string | number
  className?: string
}

const COLORS = {
  success: {
    background: 'bg-positive-pastel',
    color: 'text-positive-accent',
  },
  error: {
    background: 'bg-error-pastel',
    color: 'text-error-red',
  },
  pending: {
    background: 'bg-news-pastel',
    color: 'text-news-accent',
  },
  in_progress: {
    background: 'bg-info-pastel',
    color: 'text-info-mauve',
  },
  delivered: {
    background: 'bg-positive-pastel',
    color: 'text-black-160',
  },
  shipped: {
    background: 'bg-news-pastel',
    color: 'text-black-160',
  },
  label_downloaded: {
    background: 'bg-info-pastel',
    color: 'text-black-160',
  },
  ready_to_ship: {
    background: 'bg-info-pastel',
    color: 'text-black-160',
  },
  received: {
    background: 'bg-positive-pastel',
    color: 'text-black-160',
  },
  payment_success: {
    background: 'bg-positive-pastel',
    color: 'text-black-160',
  },
  payment_in_progress: {
    background: 'bg-info-pastel',
    color: 'text-black-160',
  },
  payment_failed: {
    background: 'bg-error-pastel',
    color: 'text-black-160',
  },
  in_transit: {
    background: 'bg-info-pastel',
    color: 'text-black-160',
  },
}

const StatusTag = ({ type, message, className }: StatusTagProps) => {
  const classNames = `${COLORS[type].color || ''} ${COLORS[type].background || ''} py-1 px-2 items-center text-[12px] text-center justify-center rounded-full h-full text-black-160 ${className || ''}`

  return <div className={classNames}>{message}</div>
}

export default StatusTag
