import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

type CreateMoreFieldProps = {
  disabled?: boolean
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CreateMoreField = (props: CreateMoreFieldProps) => {
  const { value: createMore, onChange: handleCreateMoreChange, disabled } = props
  const { t } = useTranslation()

  return (
    <Field
      checked={createMore}
      disabled={disabled}
      id="create-more"
      label={t('productFormCreateMoreLabel')}
      name="create-more"
      type="checkbox"
      onChange={handleCreateMoreChange}
    />
  )
}

export default CreateMoreField
