import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetParentCategoriesQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type GetParentCategoriesQuery = { __typename?: 'Query', parentCategoriesOrderedByPosition: { __typename?: 'ParentCategoryConnection', edges: Array<{ __typename?: 'ParentCategoryEdge', node: { __typename?: 'ParentCategory', name: string, slug: string, id: string, allCategories: Array<{ __typename?: 'Category', name: string, id: string, slug: string, isOffline: boolean, stickerUrl: string }> } }> } };

export type UpdateInventoryProductOrProductInShowMutationVariables = Types.Exact<{
  input: Types.UpdateInventoryProductOrProductInShowInput;
}>;


export type UpdateInventoryProductOrProductInShowMutation = { __typename?: 'Mutation', updateInventoryProductOrProductInShow: { __typename?: 'UpdateInventoryProductOrProductInShowPayload', ok: boolean } };

export type CanProductBeUpdatedQueryVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
}>;


export type CanProductBeUpdatedQuery = { __typename?: 'Query', canProductBeUpdated: { __typename?: 'CanProductBeUpdatedPayload', canProductBeUpdated: boolean, canProductBeRenamed: boolean, canAmountQuantityOrTypeBeUpdated: boolean } };


export const GetParentCategoriesDocument = gql`
    query getParentCategories($first: Int!) {
  parentCategoriesOrderedByPosition(first: $first) {
    edges {
      node {
        name
        slug
        allCategories {
          name
          id
          slug
          isOffline
          stickerUrl
        }
        id
      }
    }
  }
}
    `;

/**
 * __useGetParentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetParentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetParentCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetParentCategoriesQuery, GetParentCategoriesQueryVariables> & ({ variables: GetParentCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>(GetParentCategoriesDocument, options);
      }
export function useGetParentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>(GetParentCategoriesDocument, options);
        }
export function useGetParentCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>(GetParentCategoriesDocument, options);
        }
export type GetParentCategoriesQueryHookResult = ReturnType<typeof useGetParentCategoriesQuery>;
export type GetParentCategoriesLazyQueryHookResult = ReturnType<typeof useGetParentCategoriesLazyQuery>;
export type GetParentCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetParentCategoriesSuspenseQuery>;
export type GetParentCategoriesQueryResult = Apollo.QueryResult<GetParentCategoriesQuery, GetParentCategoriesQueryVariables>;
export const UpdateInventoryProductOrProductInShowDocument = gql`
    mutation UpdateInventoryProductOrProductInShow($input: UpdateInventoryProductOrProductInShowInput!) {
  updateInventoryProductOrProductInShow(input: $input) {
    ok
  }
}
    `;
export type UpdateInventoryProductOrProductInShowMutationFn = Apollo.MutationFunction<UpdateInventoryProductOrProductInShowMutation, UpdateInventoryProductOrProductInShowMutationVariables>;

/**
 * __useUpdateInventoryProductOrProductInShowMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryProductOrProductInShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryProductOrProductInShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryProductOrProductInShowMutation, { data, loading, error }] = useUpdateInventoryProductOrProductInShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryProductOrProductInShowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryProductOrProductInShowMutation, UpdateInventoryProductOrProductInShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryProductOrProductInShowMutation, UpdateInventoryProductOrProductInShowMutationVariables>(UpdateInventoryProductOrProductInShowDocument, options);
      }
export type UpdateInventoryProductOrProductInShowMutationHookResult = ReturnType<typeof useUpdateInventoryProductOrProductInShowMutation>;
export type UpdateInventoryProductOrProductInShowMutationResult = Apollo.MutationResult<UpdateInventoryProductOrProductInShowMutation>;
export type UpdateInventoryProductOrProductInShowMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryProductOrProductInShowMutation, UpdateInventoryProductOrProductInShowMutationVariables>;
export const CanProductBeUpdatedDocument = gql`
    query CanProductBeUpdated($productId: ID!) {
  canProductBeUpdated(productId: $productId) {
    canProductBeUpdated
    canProductBeRenamed
    canAmountQuantityOrTypeBeUpdated
  }
}
    `;

/**
 * __useCanProductBeUpdatedQuery__
 *
 * To run a query within a React component, call `useCanProductBeUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanProductBeUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanProductBeUpdatedQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCanProductBeUpdatedQuery(baseOptions: Apollo.QueryHookOptions<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables> & ({ variables: CanProductBeUpdatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>(CanProductBeUpdatedDocument, options);
      }
export function useCanProductBeUpdatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>(CanProductBeUpdatedDocument, options);
        }
export function useCanProductBeUpdatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>(CanProductBeUpdatedDocument, options);
        }
export type CanProductBeUpdatedQueryHookResult = ReturnType<typeof useCanProductBeUpdatedQuery>;
export type CanProductBeUpdatedLazyQueryHookResult = ReturnType<typeof useCanProductBeUpdatedLazyQuery>;
export type CanProductBeUpdatedSuspenseQueryHookResult = ReturnType<typeof useCanProductBeUpdatedSuspenseQuery>;
export type CanProductBeUpdatedQueryResult = Apollo.QueryResult<CanProductBeUpdatedQuery, CanProductBeUpdatedQueryVariables>;