import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackEvent } from '@/util/eventTracker'
import {
  useBundleShipmentFromUnshippedOrderedProductsMutation,
  useGetShipmentByIdLazyQuery,
} from '@/views/Shipments/operations.generated'

import type { ShipmentFieldsFragment } from '@/views/Shipments/operations.generated'

interface BundleUnshippedProductsButtonProps {
  customerId: string
  disabled?: boolean
  onClick?: () => void
  onComplete: (shipment: ShipmentFieldsFragment) => void
  className?: string
}

export const ShipAllButton = (props: BundleUnshippedProductsButtonProps) => {
  const { t } = useTranslation()
  const { customerId, disabled, onClick, onComplete, className } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [bundleShipment] = useBundleShipmentFromUnshippedOrderedProductsMutation({})

  const [getShipment] = useGetShipmentByIdLazyQuery()

  const handleBulkShipClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick && onClick()

    setIsLoading(true)
    const { data } = await bundleShipment({
      variables: { input: { customerId } },
      onCompleted: () => {
        trackEvent('SHIPMENT_BUNDLE_UNSHIPPED_PRODUCTS')
      },
      onError: (error) => {
        notificationDanger(error.message)
        setIsLoading(false)
      },
    })
    if (!data) return
    const { data: getShipmentData } = await getShipment({
      variables: {
        nodeId: data.bundle.shipment.id,
      },
      onError: (error) => {
        notificationDanger(error.message)
        setIsLoading(false)
      },
    })
    if (getShipmentData?.node.__typename === 'Shipment') {
      onComplete(getShipmentData.node)
    }
    trackEvent('SHIPMENT_SHIP_ALL_BUTTON_CLICK')
  }

  return (
    <Button
      className={`bulk-action bulk-ship-action ${className ? className : 'secondary'}`}
      disabled={disabled}
      isLoading={isLoading}
      label={t('shipAllButton')}
      onClick={handleBulkShipClick}
    />
  )
}
