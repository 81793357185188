import { createAsyncThunk } from '@reduxjs/toolkit'

import { internalRequest } from '../util/request'

import type { RootState } from '../reducers'

export const createSellerFiles = createAsyncThunk(
  'create-seller-files',
  async (data: any, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    const form = new FormData()
    Object.keys(data).forEach((value) => form.append(value, data[value]))

    try {
      const { data: response } = await internalRequest({
        data: form,
        headers: {
          'content-type': 'multipart/form-data',
          authorization: token,
        },
        url: '/seller/files',
        method: 'POST',
        dispatch,
      })

      return response
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)
