import dayjs from 'dayjs'

import { type Show } from '../types'

const MAX_EXPECTED_SHOW_DURATION_IN_HOURS = 4

export const isShowAssumedEnded = (show: Pick<Show, 'startAt'>) => {
  const { startAt } = show

  return Boolean(startAt && dayjs.utc().isAfter(dayjs.utc(startAt).add(MAX_EXPECTED_SHOW_DURATION_IN_HOURS, 'hours')))
}

export const isShowUpcoming = (show: Pick<Show, 'startAt'>) => {
  const { startAt } = show

  return Boolean(startAt && dayjs.utc(startAt).isAfter(dayjs.utc()))
}
