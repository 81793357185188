import Input from '@/components/Form/Field/Input/Input'

type TableRowProps = {
  row: any
  handleSelectRow?: (row: any) => void
  handleRowClick: (row: any) => void
  selectable?: {
    selectedRows: string[]
    onSelectedRowsChange: (selectedRows: string[]) => void
  }
  flexRender: (cell: any, context: any) => any
}

const TableRow = (props: TableRowProps) => {
  const { row, selectable, handleRowClick, handleSelectRow, flexRender } = props

  return (
    <tr key={row.id} className={selectable && selectable.selectedRows.includes(row.original.id) ? 'selected' : ''}>
      {selectable && handleSelectRow && (
        <td>
          <Input
            checked={selectable.selectedRows.includes(row.original.id)}
            id={row.original.id}
            name={`select-${row.original.id}`}
            type="checkbox"
            onChange={(e: any) => handleSelectRow(e)}
          />
        </td>
      )}
      {row.getVisibleCells().map((cell: any) => (
        <td key={cell.id} onClick={() => handleRowClick(row.original)}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  )
}

export default TableRow
