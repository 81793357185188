import { useTranslation } from 'react-i18next'
import { FaCircleInfo } from 'react-icons/fa6'

import Loader from '@/components/ui/Loader/Loader'
import Popover from '@/components/ui/Popover/Popover'
import SegmentedControl from '@/components/ui/SegmentedControl/SegmentedControl'

import type { DurationValue } from './AuctionDurationSelector.container'
import type { AuctionInitialDurationPreset, AuctionResetDurationPreset } from '@/network/graphql/types.generated'

type AuctionDurationSelectorProps = {
  auctionIsRunning: boolean
  auctionDuration: AuctionInitialDurationPreset
  auctionDurationPossibleValues: DurationValue[]
  updateAuctionDuration: (auctionInitialDurationPreset: AuctionInitialDurationPreset) => void
  auctionReset: AuctionResetDurationPreset
  auctionResetPossibleValues: DurationValue[]
  updateAuctionReset: (auctionResetPreset: AuctionResetDurationPreset) => void
  auctionDurationLoading: boolean
  auctionResetDurationLoading: boolean
}

const AuctionDurationSelector = (props: AuctionDurationSelectorProps) => {
  const {
    auctionIsRunning,
    auctionDuration,
    auctionDurationPossibleValues,
    updateAuctionDuration,
    auctionReset,
    auctionResetPossibleValues,
    updateAuctionReset,
    auctionDurationLoading,
    auctionResetDurationLoading,
  } = props

  const { t } = useTranslation()

  const auctionResetPopoverContent = (
    <ul className="m-0">
      {[...auctionResetPossibleValues].map((auctionReset, i) => (
        <li key={i} className="mb-1">
          <span className="font-semibold">{auctionReset.label} :</span>{' '}
          {i > 0
            ? t('ShowAuctionCardHeaderAuctionResetNewBidResetText', { timer: auctionReset.label })
            : t('ShowAuctionCardHeaderAuctionResetTooltipNoBidText')}
        </li>
      ))}
    </ul>
  )

  const loading = auctionDurationLoading || auctionResetDurationLoading

  const auctionDurationOptions = auctionDurationPossibleValues.map(({ value, label }) => ({ value, label }))
  const auctionResetOptions = auctionResetPossibleValues.map(({ value, label }) => ({ value, label }))

  const renderAuctionDurationSelector = () => {
    return (
      <>
        <div className="field next-item-option-field next-item-auction-duration-field">
          <div className="field-label">
            <label>{t('ShowAuctionCardHeaderAuctionTime')}</label>
          </div>
          <div className="field-input">
            <SegmentedControl
              disabled={auctionIsRunning}
              initialValue={auctionDuration}
              name="auction-duration"
              options={auctionDurationOptions}
              onChange={(value) => updateAuctionDuration(value as AuctionInitialDurationPreset)}
            />
          </div>
        </div>

        <div className="field next-item-option-field next-item-auction-reset-field">
          <div className="field-label">
            <label>
              {t('ShowAuctionCardHeaderAuctionReset')}
              <Popover content={auctionResetPopoverContent}>
                <FaCircleInfo className="text-slate-400" size={12} />
              </Popover>
            </label>
          </div>
          <div className="field-input">
            <SegmentedControl
              disabled={auctionIsRunning}
              id="next-item-auction-reset"
              initialValue={auctionReset}
              name="auction-reset"
              options={auctionResetOptions}
              onChange={(value) => updateAuctionReset(value as AuctionResetDurationPreset)}
            />
          </div>
        </div>
      </>
    )
  }

  return loading ? <Loader /> : renderAuctionDurationSelector()
}

export default AuctionDurationSelector
