import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetProductImagesLazyQuery } from '@/components/ProductList/operations.generated'
import { useProductPictures } from '@/components/ProductPicturesForm/product-pictures.service'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import imageUrlToFile from '@/helpers/images/imageUrlToFile'
import {
  Currency,
  ProductType,
  type Product,
  type ProductInput,
  type ShowGiveawayAudience,
} from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useInventoryProductPictures } from '@/views/Inventory/hook/useInventoryProductPictures'
import { useGetInventoryProductImagesLazyQuery } from '@/views/Inventory/operation.generated'

import { useAddBulkProductsToInventoryAndShowMutation } from '../ImportProducts/operations.generated'
import { TabName } from '../ProductList/types'

import {
  computeImagesPositions,
  computeInitialValues,
  convertFloatingNumberToPriceInCents,
  getApplicableProductAttributesForCategory,
  getFashionCategoriesSlugs,
  getTcgCategoriesSlugs,
  isProductAGiveawayProduct,
  isProductAnAuctionProduct,
  isProductAnInstantBuyProduct,
  sanitizeStartingAmount,
} from './CreateOrEditProduct.helpers'
import CreateOrEditProductDialog from './CreateOrEditProductDialog/CreateOrEditProductDialog'
import CreateOrEditProductForm from './CreateOrEditProductForm/CreateOrEditProductForm'
import {
  useCanProductBeUpdatedQuery,
  useUpdateInventoryProductOrProductInShowMutation,
} from './CreateOrEditProductForm/operations.generated'
import { CreateGiveaway } from './GiveawayForm/GiveawayForm'
import { PRODUCT_FORM_ACTION } from './types'
import useParentCategories from './useParentCategories'

import type { ExtraProductFormOptions, ProductImage, ProductInputData } from './types'
import type { ApolloError } from '@apollo/client'

import './CreateOrEditProduct.scss'

type CreateOrEditProductProps = {
  showId?: number
  isShowBroadcasting?: boolean
  product?: Product
  lastCreatedProduct?: Product
  showCategory?: string
  onSuccess?: (product?: Product, options?: ExtraProductFormOptions, type?: ProductType) => void
  onCancel?: () => void
  showHasEnded?: boolean
}

const CreateOrEditProduct = (props: CreateOrEditProductProps) => {
  const {
    showCategory,
    showId,
    product,
    lastCreatedProduct,
    isShowBroadcasting = false,
    onSuccess = () => undefined,
    onCancel = () => undefined,
    showHasEnded,
  } = props

  const { t } = useTranslation()

  const { id: productId, legacyId: productLegacyId } = product || {}
  const urlParams = new URLSearchParams(location.search)
  const action = (urlParams.get('action') as PRODUCT_FORM_ACTION) || undefined
  const productType = (urlParams.get('productType') as TabName) || undefined
  const [isGiveawayDetails, setIsGiveawayDetails] = useState<boolean>(!!urlParams.get('details') || false)
  const [giveawayDetails, setGiveawayDetails] = useState<{
    giveawayAudience: ShowGiveawayAudience | undefined
    isGiveawayOpenToInternational: boolean
  }>({ giveawayAudience: undefined, isGiveawayOpenToInternational: false })
  const isEditMode = Boolean(productId && productId !== 'new' && action !== PRODUCT_FORM_ACTION.DUPLICATE)

  const { data: canProductBeUpdatedData } = useCanProductBeUpdatedQuery({
    skip: !isEditMode || !productId || !showId,
    variables: {
      // @ts-expect-error - TS does not recognize the check above
      productId,
    },
  })
  const canProductBeUpdated =
    isEditMode && showId && canProductBeUpdatedData
      ? canProductBeUpdatedData.canProductBeUpdated.canProductBeUpdated
      : true
  const canProductBeRenamed =
    isEditMode && showId && canProductBeUpdatedData
      ? canProductBeUpdatedData.canProductBeUpdated.canProductBeRenamed
      : true
  const canAmountQuantityOrTypeBeUpdated =
    isEditMode && showId && canProductBeUpdatedData
      ? canProductBeUpdatedData.canProductBeUpdated.canAmountQuantityOrTypeBeUpdated
      : true

  const { parentCategories } = useParentCategories()
  const fashionCategoriesSlugs: string[] = useMemo(
    () => getFashionCategoriesSlugs(parentCategories),
    [parentCategories]
  )
  const tcgCategoriesSlugs: string[] = useMemo(() => getTcgCategoriesSlugs(parentCategories), [parentCategories])

  const initialFormValues = useMemo(
    () =>
      computeInitialValues(product, { showCategory, fashionCategoriesSlugs, productType, action, lastCreatedProduct }),
    [product, fashionCategoriesSlugs, tcgCategoriesSlugs, productType, action, lastCreatedProduct]
  )

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const { uploadImages, deleteImages, isLoading: isLoadingImages } = useProductPictures()
  const {
    uploadImages: uploadImagesInventory,
    deleteImages: deleteImagesInventory,
    isLoading: isLoadingImagesInventory,
  } = useInventoryProductPictures()

  const [addBulkProductsToInventoryAndShow] = useAddBulkProductsToInventoryAndShowMutation()
  const [updateInventoryProductOrProductInShow] = useUpdateInventoryProductOrProductInShowMutation()

  const [refetchImages] = useGetProductImagesLazyQuery({ fetchPolicy: 'no-cache' })
  const [refetchInventoryImages] = useGetInventoryProductImagesLazyQuery({ fetchPolicy: 'no-cache' })

  const handleImagesChanges = useCallback(async (images: ProductImage[], productId: number) => {
    const toBeDeletedImages = images
      .filter((image) => image.isDeleted && !image.id.startsWith('new'))
      .map((image) => Number(image.id.split('|')[1]))

    const toBeUploadedImages = (images || []).filter((image) => image.file && !image.isDeleted)

    if (showId) {
      await deleteImages(toBeDeletedImages, productId)
      await uploadImages(toBeUploadedImages.map((image) => image.file as File) || [], productId, false)
    } else {
      await deleteImagesInventory(toBeDeletedImages, productId.toString())
      await uploadImagesInventory(toBeUploadedImages.map((image) => image.file as File) || [], productId.toString())
    }
  }, [])

  const handleCreateProduct = useCallback(
    async (inputData: ProductInputData & ExtraProductFormOptions) => {
      const { createMore, createAndLaunch } = inputData
      const { images, category, title, description, type, isPreOrder, salesType } = inputData
      const { startingPrice, buyNowPrice, price, availableQuantity } = inputData

      const baseInfo = { name: title, description, type, availableQuantity, isPreOrder, salesType }
      const productAttributes = getApplicableProductAttributesForCategory(
        inputData,
        category || '',
        fashionCategoriesSlugs,
        tcgCategoriesSlugs
      )
      const startingAmount = sanitizeStartingAmount(startingPrice, type)
      const fixedAmount = buyNowPrice ? convertFloatingNumberToPriceInCents(buyNowPrice) : undefined
      const instantByPrice = price ? convertFloatingNumberToPriceInCents(price) : undefined

      const toBeSentInput = {
        categoryName: category,
        ...baseInfo,
        ...productAttributes,
        ...(isProductAnAuctionProduct(type) ? { startingAmount, fixedAmount } : {}),
        ...(isProductAnInstantBuyProduct(type) ? { fixedAmount: instantByPrice } : {}),
        giveawayAudience: isProductAGiveawayProduct(type) ? inputData.giveawayAudience : undefined,
        isGiveawayOpenToInternational: isProductAGiveawayProduct(type)
          ? inputData.isGiveawayOpenToInternational
          : undefined,
      }

      const createdProducts = await addBulkProductsToInventoryAndShow({
        variables: {
          input: {
            products: [toBeSentInput],
            showId: showId ? `Show|${showId}` : undefined,
          },
        },
        onError: (err: ApolloError) => {
          const initialError = err?.graphQLErrors?.[0]?.message
          const error =
            t('productCreationGenericError') + (initialError ? `${t('commonColonSeparator')}<br />${initialError}` : '')
          setError(error)
        },
      })

      if (!createdProducts || !createdProducts.data) {
        return
      } else if (
        showId &&
        (!createdProducts.data?.addBulkProductsToInventoryAndShow.productIds ||
          !createdProducts.data?.addBulkProductsToInventoryAndShow.productIds[0])
      ) {
        return
      }
      const createdProductGlobalId = showId
        ? // @ts-expect-error - TS does not recognize the check above
          createdProducts.data.addBulkProductsToInventoryAndShow.productIds[0]
        : createdProducts.data.addBulkProductsToInventoryAndShow.inventoryProductIds[0]
      const createdProductId = showId
        ? parseInt(createdProductGlobalId.split('|')[1])
        : createdProductGlobalId.split('|')[1]

      const imagesToBeUploaded = images.filter((image) => !image.isDeleted) || []

      // The following if is for the case of a duplicated product
      // We need to first download the images and re-built a proper File object to be able to upload them
      const imagesToDownload = imagesToBeUploaded.filter(({ file, url }) => !file && url)
      await Promise.allSettled(
        imagesToDownload.map(async (image) => {
          try {
            image.file = await imageUrlToFile(image.url)
          } catch {
            notificationDanger(t('productImageUploadSomethingWentWrong'))
          }
        })
      )

      const toBeUploadedImages = images.filter((image) => image.file)
      if (toBeUploadedImages.length && createdProductId) {
        const files = toBeUploadedImages.map((image) => image.file) || []
        const actualFiles = files.filter((file) => file instanceof File) as File[]

        if (showId) {
          await uploadImages(actualFiles, createdProductId as number, true)
        } else {
          await uploadImagesInventory(actualFiles, createdProductId.toString())
        }
      }
      onSuccess({ id: createdProductGlobalId, ...toBeSentInput, currency: Currency.Eur } as Product, {
        createMore,
        createAndLaunch,
      })
    },
    [fashionCategoriesSlugs, tcgCategoriesSlugs, onSuccess, t]
  )

  const handleUpdateProduct = useCallback(
    async (inputData: ProductInputData) => {
      if (!productLegacyId) {
        return
      }

      const {
        category,
        images,
        description,
        title,
        type,
        giveawayAudience,
        isPreOrder,
        salesType,
        isGiveawayOpenToInternational,
      } = inputData
      const { startingPrice, buyNowPrice, price, availableQuantity } = inputData

      const baseInfo = {
        name: title,
        description,
        type,
        availableQuantity,
        giveawayAudience: type === ProductType.Giveaway ? giveawayAudience : null,
        isGiveawayOpenToInternational: type === ProductType.Giveaway ? isGiveawayOpenToInternational : undefined,
        isPreOrder,
        salesType: salesType || null,
      }
      const productAttributes = getApplicableProductAttributesForCategory(
        inputData,
        category || '',
        fashionCategoriesSlugs,
        tcgCategoriesSlugs
      )

      const startingAmount = sanitizeStartingAmount(startingPrice, type)
      // TODO: Clean this once the backend will expose new proper APIs that will correctly handle all this
      // Note:
      // When updating an auction product, to remove a previously set fixedAmount, we currently need to set it to 0 (instead of null)
      // as the backend does not correctly handle null values for fixedAmount
      const fixedAmount = buyNowPrice ? convertFloatingNumberToPriceInCents(buyNowPrice) : showId ? 0 : null
      const instantByPrice = price ? convertFloatingNumberToPriceInCents(price) : undefined

      if (images?.length) {
        await handleImagesChanges(images, productLegacyId)
      }

      // TODO: is this really necessary if no image has been submitted?
      const orderedImageIds = await computeImagesPositions(
        images,
        showId ? refetchImages : refetchInventoryImages,
        productId as string
      )

      // Note:
      // This is a temporary check to ensure the imagesPosition sent does not contain any invalid image id (which seems to happen)
      const hasInvalidImagesIds = orderedImageIds.some((position: string) => !position)
      if (hasInvalidImagesIds) {
        trackError(new Error('Sent images position contains invalid image id'), {
          meta: {
            productId,
            productLegacyId,
            imagesPosition: orderedImageIds,
            images,
            scope: 'CreateOrEditProduct.handleUpdateProduct',
          },
        })
      }

      // Note:
      // This is a temporary check to ensure the imagesPosition array length matches the expected images count
      // because we currently (2024-05-30) have a bug where multiple imagesPosition are at 0 for the same product,
      // event if they have been added at the same time
      // Adding the following to track the error and have more insights
      if (images?.length && orderedImageIds.length !== images.filter(({ isDeleted }) => !isDeleted).length) {
        trackError(new Error('Sent images position does not match expected images count'), {
          meta: {
            productId,
            productLegacyId,
            imagesPosition: orderedImageIds,
            images,
            scope: 'CreateOrEditProduct.handleUpdateProduct',
          },
        })
      }

      const toBeSentInput: ProductInput = {
        categoryName: category,
        ...baseInfo,
        ...productAttributes,
        ...(isProductAnAuctionProduct(type)
          ? { startingAmount, fixedAmount: fixedAmount ? fixedAmount : showId ? 0 : null }
          : {}),
        ...(isProductAnInstantBuyProduct(type)
          ? { startingAmount: showId ? instantByPrice : undefined, fixedAmount: instantByPrice }
          : {}),
        ...(isProductAGiveawayProduct(type) ? { startingAmount: null, fixedAmount: null } : {}),
        availableQuantity,
        imagesPosition: orderedImageIds,
      }

      const { data } = await updateInventoryProductOrProductInShow({
        variables: {
          input: {
            product: toBeSentInput,
            productId: showId ? `Product|${productLegacyId}` : undefined,
            inventoryProductId: !showId ? `InventoryProduct|${productLegacyId}` : undefined,
          },
        },
        onCompleted: () => {
          if (showId) {
            trackEvent('SHOW_EDIT_PRODUCT', { showId, productId })
          } else {
            trackEvent('EDITED_INVENTORY_PRODUCT', { productId })
          }
        },
        onError: (err: ApolloError) => {
          const initialError = err?.graphQLErrors?.[0]?.message
          const error =
            t('productUpdateGenericError') + (initialError ? `${t('commonColonSeparator')}<br />${initialError}` : '')
          setError(error)
        },
      })
      const updatedProduct = data?.updateInventoryProductOrProductInShow?.ok === true

      if (!updatedProduct) {
        return
      }

      onSuccess(undefined, undefined, baseInfo.type)
    },
    [productId, showId, tcgCategoriesSlugs, fashionCategoriesSlugs, refetchImages, t, onSuccess]
  )

  const resetForm = useCallback(() => {
    setError('')
    setIsLoading(false)
    setIsGiveawayDetails(false)
  }, [])

  const handleCloseDialog = useCallback(() => {
    resetForm()
    onCancel()
  }, [onCancel])

  const handleSubmit = useCallback(
    async (productInput) => {
      setIsLoading(true)
      setError('')
      setIsGiveawayDetails(false)

      if (isEditMode) {
        await handleUpdateProduct(productInput)
      } else {
        await handleCreateProduct(productInput)
      }

      setIsLoading(false)
    },
    [isEditMode]
  )

  const handleNext = useCallback(
    async (productInput) => {
      setGiveawayDetails(productInput)
      setIsGiveawayDetails(true)
    },
    [isEditMode, showId]
  )

  useEffect(() => {
    if (productLegacyId) {
      if (showId) {
        trackEvent('PRODUCT_UPDATE', { inventory_product: productLegacyId, showId })
      } else {
        trackEvent('INVENTOY_PRODUCT_UPDATE', { product: productLegacyId })
      }
      return
    }

    if (showId) {
      trackEvent('PRODUCT_CREATE')
    } else {
      trackEvent('INVENTORY_PRODUCT_CREATE', { showId })
    }
  }, [productLegacyId])

  const shouldDisplayCreateOrEditDialog = !!productId

  const shouldDisplayGiveawayForm = productType === TabName.giveaway && !isGiveawayDetails && !showHasEnded

  return (
    <>
      {shouldDisplayCreateOrEditDialog && (
        <CreateOrEditProductDialog
          className={shouldDisplayGiveawayForm ? 'giveaway-form-dialog' : ''}
          isOpen={true}
          title={isEditMode ? t('showProductsDrawerEditTitle') : t('showProductsDrawerCreateTitle')}
          onClose={handleCloseDialog}
        >
          {shouldDisplayGiveawayForm ? (
            <CreateGiveaway
              initialValues={initialFormValues}
              isEditMode={isEditMode}
              isLoading={isLoading}
              isShowBroadcasting={isShowBroadcasting}
              onNext={(product) => handleNext(product)}
              onSubmit={(product) => handleSubmit(product)}
            />
          ) : (
            <CreateOrEditProductForm
              canProductBeUpdated={{ canProductBeUpdated, canProductBeRenamed, canAmountQuantityOrTypeBeUpdated }}
              error={error}
              giveawayDetails={giveawayDetails}
              initialValues={initialFormValues}
              isInShow={!!showId}
              isLoading={isLoading || isLoadingImages || isLoadingImagesInventory}
              isShowBroadcasting={isShowBroadcasting}
              productId={productId}
              showHasEnded={showHasEnded}
              onSubmit={handleSubmit}
            />
          )}
        </CreateOrEditProductDialog>
      )}
    </>
  )
}

export default CreateOrEditProduct
