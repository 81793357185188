import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

import type { BankInfoFormValidity } from '@/views/Account/components/BankInfo/BankInfoForm/types'
import type { ChangeEvent } from 'react'

type SellerBankNameFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
  onValidityChange?: (name: keyof BankInfoFormValidity, isValid: boolean) => void
}

const SellerBankNameField = (props: SellerBankNameFieldProps) => {
  const { value: bankName, onChange, onValidityChange = () => undefined, ...others } = props
  const { t } = useTranslation()

  return (
    <Field
      id="bank-name"
      label={t('sellerBankInfoBankNameFieldLabel')}
      name="bank-name"
      placeholder={t('sellerBankInfoBankNameFieldPlaceholder')}
      required={true}
      type="text"
      value={bankName}
      onChange={onChange}
      {...others}
      onValidityChange={(isValid: boolean) => onValidityChange('bankName', isValid)}
    />
  )
}

export default SellerBankNameField
