import { Dropdown } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFileArrowDown } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Config from '@/config/config'
import { useShipmentBulkShippingLabelsForSendcloudLazyQuery } from '@/views/Shipments/operations.generated'

import { PrinterType } from './types'

import type { OnShippingLabelsDownloadedOptions } from './types'

const { VITE_APP_LONG_TIMEOUT } = Config

type BulkDownloadShippingLabelsForSendcloudProps = {
  shipmentIds: string[]
  onDownloaded?: (shipmentsIds: string[], options?: OnShippingLabelsDownloadedOptions) => void
}

const BulkDownloadShippingLabelsForSendcloud = (props: BulkDownloadShippingLabelsForSendcloudProps) => {
  const { shipmentIds, onDownloaded } = props
  const hasSelectedShipments = !!shipmentIds.length

  const { t } = useTranslation()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [bulkDownloadShippingLabelsForSendcloud, { loading: bulkShippingLabelDownloadLoading }] =
    useShipmentBulkShippingLabelsForSendcloudLazyQuery()

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleDownloadLabelsForSelection = async (printerType: PrinterType) => {
    if (!hasSelectedShipments) {
      return
    }

    await bulkDownloadShippingLabelsForSendcloud({
      variables: {
        sendcloudShipmentIds: shipmentIds,
        printerType,
      },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: async (data) => {
        if (data?.bulkShippingLabelsForSendcloud?.label) {
          window.open(data.bulkShippingLabelsForSendcloud.label)
        }
      },
    })

    setIsDropdownOpen(false)

    if (onDownloaded) {
      onDownloaded(shipmentIds, { printerType })
    }
  }

  return (
    <Dropdown
      className="download-shipping-labels-menu"
      disabled={!hasSelectedShipments}
      open={isDropdownOpen}
      dropdownRender={() => {
        return (
          <div className="actions download-shipping-labels-actions">
            <Button
              className="bulk-download-shipping-labels-action bulk-download-shipping-labels-a6-action"
              icon={<FaFileArrowDown />}
              label={t('ordersBulkDownloadShippingLabelsButtonDropdownItemA6Page')}
              onClick={() => handleDownloadLabelsForSelection(PrinterType.LABEL_PRINTER)}
            />
            <Button
              className="bulk-download-shipping-labels-action bulk-download-shipping-labels-a4-action"
              icon={<FaFileArrowDown />}
              label={t('ordersBulkDownloadShippingLabelsButtonDropdownItemA4Page')}
              onClick={() => handleDownloadLabelsForSelection(PrinterType.NORMAL_PRINTER)}
            />
          </div>
        )
      }}
      autoFocus
      destroyPopupOnHide
    >
      <Button
        className="bulk-download-shipping-labels-menu primary"
        isLoading={bulkShippingLabelDownloadLoading}
        label={t('shipmentsBulkDownloadShippingLabelsButtonLabel', { count: shipmentIds.length })}
        onClick={handleDropdownClick}
      />
    </Dropdown>
  )
}

export default BulkDownloadShippingLabelsForSendcloud
