import { useEffect, useState } from 'react'

import './ProductsTab.scss'

export interface TabBarProps {
  tabs: {
    key: string
    label: string
  }[]
  className?: string
  onChange?: (tab: string) => void
  initial?: string
}

export const ProductsTab = (props: TabBarProps) => {
  const { tabs, className, initial, onChange } = props

  const [activeTab, setActiveTab] = useState<string>(initial || tabs[0].key)

  const handleTabClick = (key: string) => {
    setActiveTab(key)
    onChange && onChange(key)
  }

  useEffect(() => {
    if (initial) setActiveTab(initial)
  }, [initial])

  if (!tabs.length) return null

  const classNames = `products-tab ${className || ''}`

  return (
    <>
      <div className={`${classNames} expanded`}>
        {tabs.map(({ key, label }) => (
          <button
            key={key}
            className={`products-tab-item ${activeTab === key && 'active'}`}
            onClick={() => handleTabClick(key)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className={`${classNames} minimized`}>
        <select className="products-tab-items-selector" onChange={(e) => handleTabClick(e.target.value)}>
          {tabs.map(({ key, label }) => (
            <option key={key} className={`products-tab-item ${activeTab === key && 'active'}`} value={key}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}
