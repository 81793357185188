import { Select } from 'antd'
import moment from 'moment'
import { useCallback } from 'react'

import { groupBy } from '@/util/lodash-replacer'

import { useGetPastAndCurrentShowsQuery } from './operations.generated'

import type { SelectProps } from 'antd'

type ShowSelectProps = Omit<SelectProps, 'mode' | 'options' | 'loading' | 'onChange'> & {
  onChange?: (id: string, legacyId: number) => void
  value?: string
}

/**
 * A selector of all shows of the user, past and current, as options.
 *
 * The shows are grouped by start date month.
 */
const ShowSelect = ({ value, onChange, onClear, ...rest }: ShowSelectProps) => {
  const { data, loading, fetchMore } = useGetPastAndCurrentShowsQuery({
    variables: {
      first: 40,
    },
  })

  const fetchAllShows = useCallback(async () => {
    if (!data || !data.viewer) return

    // we'll load all shows via pagination by iterating
    // on pages using a loop
    // this is not ideal but ok => see remark below on onDropdownVisibleChange
    let currentPageInfo = data.viewer.shows.pageInfo
    let requestsCount = 0
    // guard against inifinite loop (max 4000 items loaded)
    const MAX_REQUESTS_COUNT = 100
    while (requestsCount < MAX_REQUESTS_COUNT && currentPageInfo.hasNextPage && currentPageInfo.endCursor) {
      requestsCount += 1
      const { data } = await fetchMore({
        variables: {
          after: currentPageInfo.endCursor,
        },
      })
      if (!data || !data.viewer) break
      currentPageInfo = data.viewer.shows.pageInfo
    }
  }, [fetchMore, data])

  const onSelectChange = (
    _event: string,
    option: { value: string; legacyId: number } | { value: string; label: string; legacyId: number }[]
  ): void => {
    if (Array.isArray(option)) throw new Error('Not setup to select multiple values')

    onChange?.(option?.value, option?.legacyId)
  }

  const onSelectClear = () => {
    onClear?.()
  }

  const shows = data?.viewer?.shows.edges.map(({ node }) => node)

  if (!shows) return null

  return (
    <Select<string, { value: string; label: string; legacyId: number }>
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      loading={loading}
      value={value}
      // Load all shows when the select is open
      // Ideally, we would have preferred implementing an infinite scrolling
      // on the dropdown like done in https://github.com/mrinalraj/antd-select-infinte-scroll
      onChange={onSelectChange}
      onClear={onSelectClear}
      onDropdownVisibleChange={(open) => {
        if (open) fetchAllShows()
      }}
      {...rest}
    >
      {Object.entries(groupBy(shows, (show) => moment(show.startAt).format('MMMM YYYY'))).map(([monthLabel, shows]) => (
        <Select.OptGroup key={monthLabel} label={monthLabel}>
          {shows.map((show) => (
            <Select.Option key={show.id}>{`${moment(show.startAt).format('dddd Do')} | ${show.name}`}</Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  )
}

export default ShowSelect
