export enum Country {
  FR = 'FR',
  BE = 'BE',
  CH = 'CH',
  GB = 'GB',
  DE = 'DE',
  AT = 'AT',
  NL = 'NL',
  ES = 'ES',
}

const countryClusters: { [key: string]: Country } = {
  // FR cluster
  FR: Country.FR,
  BE: Country.FR,
  CH: Country.FR,

  // GB cluster
  GB: Country.GB,

  // DE cluster
  DE: Country.DE,
  AT: Country.DE,
}

export default countryClusters
