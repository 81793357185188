import { message } from 'antd'
import { useMemo } from 'react'

import {
  AuctionInitialDurationPreset,
  AuctionResetDurationPreset,
  ShowAuctionStatus,
} from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import {
  useCurrentAuctionShowQuery,
  useGetAuctionDurationFromShowQuery,
  useGetAuctionResetDurationFromShowQuery,
  useUpdateShowAuctionsInitialDurationMutation,
  useUpdateShowAuctionsResetDurationMutation,
} from '@/views/Show/operations.generated'

import AuctionDurationSelector from './AuctionDurationSelector'

type AuctionDurationSelectorContainerProps = {
  showId: string
}

export type DurationValue = {
  value: AuctionInitialDurationPreset | AuctionResetDurationPreset
  label: string
}

export const auctionDurationPossibleValues: DurationValue[] = [
  {
    value: AuctionInitialDurationPreset.Time_5Seconds,
    label: '5s',
  },
  {
    value: AuctionInitialDurationPreset.Time_15Seconds,
    label: '15s',
  },
  {
    value: AuctionInitialDurationPreset.Time_30Seconds,
    label: '30s',
  },
  {
    value: AuctionInitialDurationPreset.Time_60Seconds,
    label: '60s',
  },
]

export const auctionResetPossibleValues: DurationValue[] = [
  {
    value: AuctionResetDurationPreset.Time_00Seconds,
    label: `0s⚡️`,
  },
  {
    value: AuctionResetDurationPreset.Time_03Seconds,
    label: '3s',
  },
  {
    value: AuctionResetDurationPreset.Time_10Seconds,
    label: '10s',
  },
]

export const AuctionDurationSelectorContainer = ({ showId }: AuctionDurationSelectorContainerProps) => {
  const { data: currentAuction } = useCurrentAuctionShowQuery({
    skip: !showId,
    variables: { showId },
  })

  const auctionIsRunning = useMemo(() => {
    if (currentAuction?.node.__typename === 'Show') {
      return !!currentAuction.node.auction && currentAuction.node.auction?.status === ShowAuctionStatus.Opened
    }
    return false
  }, [currentAuction])

  // Auction Duration
  // ----------------
  const [updateShowAuctionsInitialDuration] = useUpdateShowAuctionsInitialDurationMutation()

  const { data: auctionDurationFromShowData, loading: auctionDurationLoading } = useGetAuctionDurationFromShowQuery({
    skip: !showId,
    variables: {
      nodeId: showId,
    },
  })

  const defaultAuctionDuration = auctionDurationPossibleValues[2].value as AuctionInitialDurationPreset

  const auctionDuration = useMemo(() => {
    if (auctionDurationFromShowData?.node.__typename === 'Show') {
      return auctionDurationFromShowData.node.auctionInitialDurationPreset
    }
    return defaultAuctionDuration
  }, [auctionDurationFromShowData])

  const updateAuctionDuration = (auctionInitialDurationPreset: AuctionInitialDurationPreset) => {
    const input = { showId, auctionInitialDurationPreset }

    updateShowAuctionsInitialDuration({
      variables: {
        input,
      },
      onCompleted: () => {
        trackEvent('UPDATE_AUCTION_TIME_PRESET_SHOW', {
          showId: showId,
          auctionInitialDurationPreset,
        })
      },
      onError: (error) => {
        // TODO: We should probably display a custom error message here instead
        message.error(error.message)

        trackError(error, {
          meta: { ...input, scope: 'AuctionDurationSelector.updateShowAuctionsInitialDuration' },
        })
      },
    })
  }

  // Auction Reset Duration
  // ----------------
  const [updateShowAuctionsResetDuration] = useUpdateShowAuctionsResetDurationMutation()

  const { data: auctionResetFromShowData, loading: auctionResetDurationLoading } =
    useGetAuctionResetDurationFromShowQuery({
      skip: !showId,
      variables: {
        nodeId: showId,
      },
    })

  const defaultAuctionTimerReset = auctionResetPossibleValues[2].value as AuctionResetDurationPreset

  const auctionReset = useMemo(() => {
    if (auctionResetFromShowData?.node.__typename === 'Show') {
      return auctionResetFromShowData.node.auctionResetDurationPreset
    }
    return defaultAuctionTimerReset
  }, [auctionResetFromShowData])

  const updateAuctionReset = (auctionResetDurationPreset: AuctionResetDurationPreset) => {
    const input = { showId, auctionResetDurationPreset }

    updateShowAuctionsResetDuration({
      variables: {
        input,
      },
      onCompleted: () => {
        trackEvent('UPDATE_AUCTION_RESET_PRESET_SHOW', {
          showId: showId,
          auctionResetDurationPreset,
        })
      },
      onError: (error) => {
        // TODO: We should probably display a custom error message here instead
        message.error(error.message)

        trackError(error, {
          meta: { ...input, scope: 'AuctionDurationSelector.updateShowAuctionsResetDuration' },
        })
      },
    })
  }

  return (
    <AuctionDurationSelector
      auctionDuration={auctionDuration}
      auctionDurationLoading={auctionDurationLoading}
      auctionDurationPossibleValues={auctionDurationPossibleValues}
      auctionIsRunning={auctionIsRunning}
      auctionReset={auctionReset}
      auctionResetDurationLoading={auctionResetDurationLoading}
      auctionResetPossibleValues={auctionResetPossibleValues}
      updateAuctionDuration={updateAuctionDuration}
      updateAuctionReset={updateAuctionReset}
    />
  )
}
