import { message } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { removeShow } from '@/actions/remove-show'
import Confirm from '@/components/Confirm/Confirm'
import Button from '@/components/ui/Button/Button'
import { trackEvent } from '@/util/eventTracker'

import DeleteIcon from '../ui/Icons/DeleteIcon/DeleteIcon'

type DeleteShowProps = {
  showId: number
}

const DeleteShow = (props: DeleteShowProps) => {
  const { showId } = props
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const handleConfirmDeleteShow = useCallback(async () => {
    const result = await dispatch(removeShow(showId))
    const { type } = result
    const isSuccess = type.indexOf('fulfilled') > 0

    if (isSuccess) {
      trackEvent('SHOW_DELETE', { showId })
      navigate('/shows')
    } else {
      message.error(result?.payload?.details)
    }
  }, [showId])

  return (
    <Confirm message={t('showButtonDeleteModalContent')} onConfirm={handleConfirmDeleteShow}>
      <Button
        className="delete-action delete-show-action"
        icon={<DeleteIcon />}
        label={t('commonDelete')}
        tooltip={t('deleteShowActionTitle')}
        tooltipPosition="bottom left"
      />
    </Confirm>
  )
}

export default DeleteShow
