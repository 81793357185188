import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { useGetSellerGlobalMetricsQuery } from '@/views/Home/components/KeyFigures/operations.generated'

import './style.scss'

const KeyFigures = () => {
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()

  const { data, loading } = useGetSellerGlobalMetricsQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
  const user = data?.viewer
  const { shows, customers, followers, sellerRating, sellerStatistics, referrals } = user || {}
  const currency = shows?.edges[0]?.node.currency
  const { totalOrderAmount: totalRevenue, totalStreamingHours } = sellerStatistics || {}

  const formattedTotalRevenue =
    totalRevenue !== undefined && currency ? formatCurrency(totalRevenue || 0, currency) : undefined
  const averageSellersRating = sellerRating !== undefined && sellerRating !== null ? `${sellerRating}/5` : undefined
  const totalShowsCount = shows?.totalCount
  const totalReferrals = referrals?.totalCount
  const totalCustomers = customers?.totalCount
  const totalFollowers = followers?.totalCount
  const notAvailable = t('commonNotAvailableValue')

  return (
    <Card className="key-figures global-user-key-figures" title={t('widgetGlobalTitle')}>
      {loading ? (
        <Loader />
      ) : (
        <dl className="key-figures-list">
          <dt>{t('grossRevenueWidgetTitle')}</dt>
          <dd>
            <data value={totalRevenue}>{formattedTotalRevenue ?? notAvailable}</data>
          </dd>
          <dt>{t('showsOverallWidgetTitle')}</dt>
          <dd>
            <data value={totalShowsCount}>{totalShowsCount ?? notAvailable}</data>
          </dd>
          <dt>{t('streamingHoursWidgetTitle')}</dt>
          <dd>
            <data value={totalStreamingHours}>{totalStreamingHours ?? notAvailable}</data>
          </dd>
          <dt>{t('widgetSellerRatingTitle')}</dt>
          <dd>
            {/* Below, we will prefer displaying "N/A" than 0 when the rating is actually 0, to avoid frustration */}
            <data value={averageSellersRating}>{averageSellersRating || notAvailable}</data>
          </dd>
          <dt>{t('customersOverallWidgetTitle')}</dt>
          <dd>
            <data value={totalCustomers}>{totalCustomers ?? notAvailable}</data>
          </dd>
          <dt>{t('followersOverallWidgetTitle')}</dt>
          <dd>
            <data value={totalFollowers}>{totalFollowers ?? notAvailable}</data>
          </dd>
          <dt>{t('keyFiguresTotalReferralsLabel')}</dt>
          <dd>
            <data value={totalReferrals}>{totalReferrals ?? notAvailable}</data>
          </dd>
        </dl>
      )}
    </Card>
  )
}

export default KeyFigures
