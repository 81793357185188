import Link from 'antd/lib/typography/Link'
import dayjs from 'dayjs'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import Table from '@/components/ui/Table/Table'
import { useUser } from '@/contexts/user/User.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderPaymentStatus } from '@/network/graphql/types.generated'

import {
  useGetCustomerWithUnshippedOrderedProductsQuery,
  useUnbundleAllShipmentsForCustomerMutation,
} from '../operations.generated'
import { BulkDownloadPackingSlips } from '../Tables/components/BulkDownloadPackingSlips/BulkDownloadPackingSlips'
import { OrderStatusTag } from '../Tables/components/OrderStatus/OrderStatus'
import { ShipAllButton } from '../Tables/components/ShipAllButton/ShipAllButton'
import { ShippingAddressColumn } from '../Tables/components/ShippingAddressColumn/ShippingAddressColumn'
import { ShipSomeButton } from '../Tables/components/ShipSomeButton/ShipSomeButton'
import UsernameAndContactColumn from '../Tables/components/UsernameAndContactColumn/UsernameAndContactColumn'

import { CancelShippingFee } from './components/CancelShippingFee/CancelShippingFee'
import { OrderCancellationModal } from './components/OrderCancellation/OrderCancellationModal'

import type { ShipmentFieldsFragment } from '../operations.generated'

import './Details.scss'

interface PendingOrdersDetailsProps {
  customerId: string
  onCompleted: () => void
  isShipping?: boolean
  onShipping: (shipment: ShipmentFieldsFragment) => void
}

const PendingOrdersDetails = (props: PendingOrdersDetailsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currencyFormatter = useCurrencyFormatter()

  const { customerId, onCompleted, isShipping, onShipping } = props
  const customerGlobalId = `User|${customerId}`

  const { user } = useUser()
  const { id: sellerId } = user || {}

  const [selectedOrderProducts, setSelectedOrderProducts] = useState<string[]>([])

  const { data, loading, refetch } = useGetCustomerWithUnshippedOrderedProductsQuery({
    skip: !customerId,
    variables: {
      customerId: customerGlobalId,
    },
  })

  const [unbundleAllShipmentsForCustomer] = useUnbundleAllShipmentsForCustomerMutation()

  useEffect(() => {
    if (isShipping) return
    if (
      data?.customerWithUnshippedOrderedProducts?.unshippedShipments.edges.length &&
      data?.customerWithUnshippedOrderedProducts?.unshippedShipments.edges.length > 0
    ) {
      unbundleAllShipmentsForCustomer({
        variables: { input: { customerId: customerGlobalId } },
        onCompleted: () => {
          refetch()
        },
      })
    }
  }, [data, isShipping])

  useEffect(() => {
    setSelectedOrderProducts([])
  }, [data])

  const status = data?.customerWithUnshippedOrderedProducts?.statusSummary
  const customer = data?.customerWithUnshippedOrderedProducts?.customer
  const shippingAddress = data?.customerWithUnshippedOrderedProducts?.shippingAddress
  const amount = data?.customerWithUnshippedOrderedProducts?.orderedProductsAmountSum
  const items = data?.customerWithUnshippedOrderedProducts?.orderedProductsCount
  const createdAt = data?.customerWithUnshippedOrderedProducts?.oldestOrderedProductDate

  const unshippedOrderedProducts = data?.customerWithUnshippedOrderedProducts?.unshippedOrderedProducts.edges.map(
    (edge) => edge.node
  )
  const totalShippingFee =
    unshippedOrderedProducts
      ?.filter((item) => !item.order.shippingFeesCancellationRequestedAt)
      .map((item) => item.order.shippingAmountInCurrencyCents)
      .reduce((accumulator, current) => accumulator + current, 0) || 0
  const unshippedOrderedProductsIds = unshippedOrderedProducts?.map((item) => item.id) || []

  const columns = [
    {
      header: t('shipmentDetailTableHeaderProduct'),
      id: 'product',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { id, name, images, description } = record
        const imageUrl = images?.[0]?.webPUrl || '/badges/empty-pic.png'

        return (
          <div key={`product-${id}`} className="shipment-detail-product">
            <div>
              <div className="shipment-detail-product-image">
                <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
              </div>
              <div className="shipment-detail-product-details">
                <p>{name}</p>
                <p>{description}</p>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      header: t('shipmentDetailTableHeaderCreatedAt'),
      id: 'createdAt',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { createdAt } = record.order

        return <p className="shipments-date">{dayjs(createdAt).format('DD/MM/YYYY')}</p>
      },
    },
    {
      header: t('shipmentDetailTableHeaderShow'),
      id: 'show',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { name } = record.order.product.show

        return <p className="shipments-show">{name}</p>
      },
    },
    {
      header: t('shipmentDetailTableHeaderStatus'),
      id: 'status',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { paymentStatus } = record.order

        switch (paymentStatus) {
          case OrderPaymentStatus.Success:
            return <Tag content={t('orderPaymentStatusSuccess')} status={TagStatus.SUCCESS} />
          case OrderPaymentStatus.Failed:
            return <Tag content={t('orderPaymentStatusFailed')} status={TagStatus.DANGER} />
          case OrderPaymentStatus.InProgress:
            return <Tag content={t('orderPaymentStatusInProgress')} status={TagStatus.WARNING} />
          case OrderPaymentStatus.PendingSepaDebit:
            return <Tag content={t('orderPaymentStatusInProgress')} status={TagStatus.WARNING} />
        }
      },
    },
    {
      header: t('shipmentDetailTableHeaderAmount'),
      id: 'amount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { amountInCurrencyCents, currency } = record.order

        return amountInCurrencyCents ? currencyFormatter(amountInCurrencyCents, currency) : '-'
      },
    },
    {
      header: t('shipmentDetailTableHeaderShippingFees'),
      id: 'shippingFees',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { shippingAmountInCurrencyCents, currency, shippingFeesCancellationRequestedAt, id, status } =
          record.order
        const isCancelled = !!shippingFeesCancellationRequestedAt

        return (
          <div className="column-shipping-fee">
            <p className={isCancelled ? 'shipping-fees-refunded' : ''}></p>
            {shippingAmountInCurrencyCents ? currencyFormatter(shippingAmountInCurrencyCents, currency) : '-'}
            <CancelShippingFee
              cancellationRequestedAt={shippingFeesCancellationRequestedAt}
              currency={currency}
              orderId={id}
              shippingFeeAmount={shippingAmountInCurrencyCents}
              status={status}
              onCompleted={refetch}
            />
          </div>
        )
      },
    },
    {
      header: t('shipmentDetailTableHeaderAction'),
      id: 'action',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { order } = record

        return <OrderCancellationModal order={order} sellerId={sellerId} onSubmittedCallback={refetch} />
      },
    },
  ]

  const handleGoBack = () => {
    navigate('/shipments?tab=pending')
  }

  if (!data && !loading) {
    handleGoBack()
    return null
  }

  if (
    (data &&
      data.customerWithUnshippedOrderedProducts?.unshippedOrderedProducts.edges.length === 0 &&
      data.customerWithUnshippedOrderedProducts?.unshippedShipments.edges.length === 0) ||
    data?.customerWithUnshippedOrderedProducts === null
  ) {
    onCompleted && onCompleted()
    handleGoBack()
    return null
  }

  return (
    <div className="pending-orders-details details-page">
      <div className="pending-orders-detail-links detail-links">
        <Link onClick={handleGoBack}>{t('ordersTabSwitcherToShipLabel')}</Link>
        <FaCaretRight />
        <p>{t('shipmentDetail')}</p>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="pending-orders-detail-header detail-header">
            <h2 className="title">{t('pendingOrdersDetailheaderTitle')}</h2>
            <div className="pending-orders-detail-header-actions detail-header-actions">
              <OrderStatusTag status={status} />
              <BulkDownloadPackingSlips
                buttonLabel={t('downloadPackingSlipForCustomerButtonLabel')}
                customerId={customerGlobalId}
                orderedProductIds={unshippedOrderedProductsIds || []}
                tooltip={t('bulkDownloadPackingSlipForCustomerButtonTitle')}
              />
              <ShipAllButton className="primary" customerId={customerGlobalId} onComplete={onShipping} />
            </div>
          </Card>

          <div className="pending-orders-detail-infos detail-infos">
            <Card>
              <h3 className="title">{t('pendingOrdersDetailInfoAmountsTitle')}</h3>

              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoTotalProducts')}</p>
                <p>{amount ? currencyFormatter(amount.amount, amount.currency) : '-'}</p>
              </div>

              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoUnits')}</p>
                <p>{items || '-'}</p>
              </div>

              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoTotalShippingFees')}</p>
                <p>{totalShippingFee && amount ? currencyFormatter(totalShippingFee, amount.currency) : '-'}</p>
              </div>
            </Card>

            <Card>
              <h3 className="title">{t('pendingOrdersDetailInfoShipmentTitle')}</h3>
              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoUser')}</p>
                {customer && (
                  <UsernameAndContactColumn
                    avatarUrl={customer.avatarWebPUrl}
                    createdAt={createdAt}
                    userId={customer.id}
                    username={customer.username}
                  />
                )}
              </div>
              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoFullName')}</p>
                {shippingAddress && <p>{shippingAddress.name}</p>}
              </div>
              <div className="detail-info">
                <p>{t('pendingOrdersDetailInfoAddress')}</p>
                {shippingAddress && <ShippingAddressColumn {...shippingAddress} />}
              </div>
            </Card>
          </div>

          <Card className="pending-orders-items detail-items">
            <Table
              columns={columns}
              data={unshippedOrderedProducts}
              header={
                <div className="pending-orders-items-header">
                  <h3 className="title">{t('pendingOrderDetailArticlesTableTitle')}</h3>
                  <ShipSomeButton
                    customerId={customerId}
                    selectedOrders={selectedOrderProducts}
                    onComplete={onShipping}
                  />
                </div>
              }
              selectable={{
                selectedRows: selectedOrderProducts,
                onSelectedRowsChange: setSelectedOrderProducts,
              }}
            />
          </Card>
        </>
      )}
    </div>
  )
}

export default memo(PendingOrdersDetails)
