interface RatingStarProps {
  percentage: string
}

interface StarProps {
  fillPercentage: number
}

const Star = ({ fillPercentage }: StarProps) => {
  return (
    <div className="relative inline-block align-middle w-4 h-4 overflow-hidden text-transparent">
      <div className="absolute text-black-110 w-full h-full">★</div>
      <div
        className="absolute w-full h-full text-accents-yellow-150"
        style={{
          clipPath: `inset(0 ${100 - fillPercentage}% 0 0)`,
          background: '-webkit-linear-gradient(-90deg, #FFF06A 0%, #FFE920 56.25%, #FF9839 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        ★
      </div>
    </div>
  )
}

export const RatingStar = ({ percentage }: RatingStarProps) => {
  return (
    <div>
      <div className="relative flex items-center justify-center">
        <Star fillPercentage={Number(percentage)} />
      </div>
    </div>
  )
}
