const SortingIconNeutral = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 11 16" width="11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.87245 5.87078C9.71784 6.02741 9.47591 6.04165 9.30543 5.9135L9.25658 5.87078L5.5 2.06529L1.74342 5.87078C1.58881 6.02741 1.34688 6.04165 1.17639 5.9135L1.12755 5.87078C0.972944 5.71416 0.958889 5.46906 1.08539 5.29635L1.12755 5.24686L5.19207 1.12922C5.34667 0.97259 5.58861 0.958351 5.75909 1.0865L5.80793 1.12922L9.87245 5.24686C10.0425 5.41915 10.0425 5.69849 9.87245 5.87078Z"
        fill="#222C36"
        fillOpacity="0.5"
      />
      <path
        d="M9.23959 5.98877L9.23941 5.98898L1.74342 5.87078L1.81458 5.94103L1.81459 5.94103L5.5 2.20764L9.18542 5.94103L9.18522 5.94122L9.19075 5.94606L9.23959 5.98877Z"
        stroke="#222C36"
        strokeOpacity="0.5"
        strokeWidth="0.2"
      />
      <path
        d="M1.12755 10.1292C1.28216 9.97259 1.52409 9.95835 1.69457 10.0865L1.74342 10.1292L5.5 13.9347L9.25658 10.1292C9.41119 9.97259 9.65312 9.95835 9.82361 10.0865L9.87245 10.1292C10.0271 10.2858 10.0411 10.5309 9.91461 10.7037L9.87245 10.7531L5.80793 14.8708C5.65333 15.0274 5.41139 15.0416 5.24091 14.9135L5.19207 14.8708L1.12755 10.7531C0.957483 10.5808 0.957483 10.3015 1.12755 10.1292Z"
        fill="#222C36"
        fillOpacity="0.5"
      />
      <path
        d="M1.76041 10.0112L1.76059 10.011L9.25658 10.1292L9.18542 10.059L9.18541 10.059L5.5 13.7924L1.81458 10.059L1.81478 10.0588L1.80925 10.0539L1.76041 10.0112Z"
        stroke="#222C36"
        strokeOpacity="0.5"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export default SortingIconNeutral
