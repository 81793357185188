/**
 * Will trigger a download of the file at the given URI.
 *
 * @see https://stackoverflow.com/questions/3916191/download-data-url-file
 */
export function triggerDownloadForFile(uri: string, name?: string) {
  const link = document.createElement('a')
  link.download = name || ''
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
