import { memo, useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  isProductFromFashionCategory,
  isProductFromSneakersCategory,
} from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

// import type { ProductFormValidity } from '@/components/CreateOrEditProduct/types'

type ProductTitleFieldProps = {
  value?: string
  category: string
  placeholder?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  // onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductTitleField = memo((props: ProductTitleFieldProps) => {
  const {
    value: title,
    onChange,
    category,
    // onValidityChange = () => undefined,
    disabled = false,
  } = props
  const { t } = useTranslation()

  const isFashionProduct = isProductFromFashionCategory(category)
  const isSneakersProduct = isProductFromSneakersCategory(category)

  const placeholder = useMemo(() => {
    return isFashionProduct
      ? t('productFormFashionTitlePlaceholder')
      : isSneakersProduct
        ? t('productFormSneakersTitlePlaceholder')
        : t('productFormTitlePlaceholder')
  }, [isFashionProduct, isSneakersProduct, t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormTitleLabel')}
      name="title"
      placeholder={placeholder}
      required={true}
      value={title}
      onChange={onChange}
      // onValidityChange={(isValid: boolean) => onValidityChange('title', isValid)}
    />
  )
})

export default ProductTitleField
