import { useTranslation } from 'react-i18next'

import { Breaker } from '../../ui/Breaker/Breaker'
import { useSellerTier } from '../hooks/use-seller-tier'

interface BreakerCannotCreateShowProps {
  className?: string
}

export const BreakerCannotCreateShow = ({ className }: BreakerCannotCreateShowProps) => {
  const { t } = useTranslation()
  const { sellerTier, loading } = useSellerTier()

  if (sellerTier?.canCreateShow || loading) return null

  return (
    <Breaker
      className={className}
      imageUrl="/badges/eyes0.png"
      subtitle={t('sellerTierCannotCreateShowBreakerSubtitle')}
      title={t('sellerTierCannotCreateShowBreakerTitle')}
      type="primary"
    />
  )
}
