import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import { useUser } from '@/contexts/user/User.context'
import getShowShippingCategories from '@/data/showShippingCategories'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { getFromLocalStorage } from '@/helpers/localstorage'
import { Currency } from '@/network/graphql/types.generated'

import { useGetAllLanguagesQuery, useGetDefaultShowLanguageQuery } from '../operations.generated'

interface DetailsProps {
  isUpdate?: boolean
  isShop?: boolean
  category: string
  name: string
  language: string
  shipping: string
  reuse_shipping: boolean
  target: number
  date?: string
  time?: string
  note?: string
  preAuth?: number
  onChange: (
    name: string,
    language: string,
    shipping: string,
    reuse_shipping: boolean,
    target: number,
    date?: string,
    time?: string,
    note?: string,
    preAuth?: number
  ) => void
}

const MIN_GMV_TARGET_AMOUNT = 1
const MAX_GMV_TARGET_AMOUNT = 50000

export const Details = (props: DetailsProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const {
    isUpdate,
    isShop,
    category,
    name: nameForm,
    language: languageForm,
    shipping: shippingForm,
    reuse_shipping: reuseShippingForm,
    target: targetForm,
    date: dateForm,
    time: timeForm,
    note: noteForm,
    preAuth: preAuthForm,
    onChange,
  } = props

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { shippingAddress, country, sellerCurrency, canAccessPreAuth } = sellerConfig || {}

  const sellerCountry = shippingAddress?.countryIso2Code || country?.iso2Code

  const { data: languages } = useGetAllLanguagesQuery()
  const languagesOptions = useMemo(
    () =>
      languages &&
      languages?.allLanguages?.map((language) => {
        return {
          label: language.code,
          value: language.id,
        }
      }),
    [languages]
  )
  const { data: languageDefault } = useGetDefaultShowLanguageQuery()

  useEffect(() => {
    if (languageDefault && languageDefault.viewer?.sellerConfig?.defaultShowLanguage.id && languageForm === '') {
      onChange(
        nameForm,
        languageDefault.viewer?.sellerConfig?.defaultShowLanguage.id,
        shippingForm,
        reuseShippingForm,
        targetForm,
        dateForm,
        timeForm,
        noteForm,
        preAuthForm
      )
    }
  }, [languageDefault])

  const shippingOptions = getShowShippingCategories(sellerCountry, parseInt(category.split('|')[1])).map((option) => {
    return {
      label: `${t(option.label)} ${currencyFormatter((option.amount || 0) * 100 || 0, sellerCurrency || Currency.Eur)}`,
      value: option.value,
    }
  })
  const storedShippingOption = getFromLocalStorage('lastShowShippingCategorySelected')
  const defaultShippingOption = shippingOptions.find((shippingOption) => shippingOption.value === storedShippingOption)
    ? storedShippingOption
    : ''

  useEffect(() => {
    if (defaultShippingOption && shippingForm === '') {
      setShipping(defaultShippingOption)
      onChange(
        nameForm,
        languageForm,
        defaultShippingOption,
        reuseShippingForm,
        targetForm,
        dateForm,
        timeForm,
        noteForm,
        preAuthForm
      )
    }
  }, [defaultShippingOption])

  const [name, setName] = useState<string>(nameForm)
  const [note, setNote] = useState<string | undefined>(noteForm)
  const [language, setLanguage] = useState<string>(languageForm)
  const [shipping, setShipping] = useState<string>(shippingForm)
  const [reuseShipping, setReuseShipping] = useState<boolean>(reuseShippingForm)
  const [target, setTarget] = useState<number>(targetForm)
  const [preAuth, setPreAuth] = useState<number | undefined>(preAuthForm)
  const [date, setDate] = useState<string | undefined>(dateForm)
  const [time, setTime] = useState<string | undefined>(timeForm)
  const [hour, setHour] = useState<string | undefined>(undefined)
  const [minutes, setMinutes] = useState<string | undefined>(undefined)

  let timeoutHandleChange: any
  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timeoutHandleChange)
      setName(event.target.value)

      timeoutHandleChange = setTimeout(async () => {
        onChange(
          event.target.value,
          languageForm,
          shippingForm,
          reuseShippingForm,
          targetForm,
          dateForm,
          timeForm,
          noteForm,
          preAuthForm
        )
      }, 500)
    },
    [onChange]
  )

  const handleLanguageChange = useCallback(
    (event) => {
      setLanguage(event.target.value)
      onChange(
        nameForm,
        event.target.value,
        shippingForm,
        reuseShippingForm,
        targetForm,
        dateForm,
        timeForm,
        noteForm,
        preAuthForm
      )
    },
    [onChange]
  )

  const handleShippingChange = useCallback(
    (event) => {
      setShipping(event.target.value)
      {
        onChange(
          nameForm,
          languageForm,
          event.target.value,
          reuseShippingForm,
          targetForm,
          dateForm,
          timeForm,
          noteForm,
          preAuthForm
        )
      }
    },
    [onChange]
  )

  const handleReuseShippingChange = useCallback(() => {
    const value = !reuseShipping
    setReuseShipping(value)
    {
      onChange(nameForm, languageForm, shippingForm, value, targetForm, dateForm, timeForm, noteForm, preAuthForm)
    }
  }, [onChange])

  const handleTargetChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timeoutHandleChange)
      const value = parseInt(event.target.value)
      setTarget(value)

      timeoutHandleChange = setTimeout(async () => {
        onChange(
          nameForm,
          languageForm,
          shippingForm,
          reuseShippingForm,
          value,
          dateForm,
          timeForm,
          noteForm,
          preAuthForm
        )
      }, 500)
    },
    [onChange]
  )

  const handleDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDate(event.target.value)
      {
        onChange(
          nameForm,
          languageForm,
          shippingForm,
          reuseShippingForm,
          targetForm,
          event.target.value,
          timeForm,
          noteForm,
          preAuthForm
        )
      }
    },
    [onChange]
  )
  const handleTimeChange = useCallback(
    (time: string) => {
      setTime(time)
      {
        onChange(
          nameForm,
          languageForm,
          shippingForm,
          reuseShippingForm,
          targetForm,
          dateForm,
          time,
          noteForm,
          preAuthForm
        )
      }
    },
    [onChange]
  )

  const handleNoteChange = useCallback(
    (event) => {
      clearTimeout(timeoutHandleChange)
      setNote(event.target.value)

      timeoutHandleChange = setTimeout(async () => {
        onChange(
          nameForm,
          languageForm,
          shippingForm,
          reuseShippingForm,
          targetForm,
          dateForm,
          timeForm,
          event.target.value,
          preAuthForm
        )
      }, 500)
    },
    [onChange]
  )

  const handlePreAuthChange = useCallback(
    (event) => {
      clearTimeout(timeoutHandleChange)
      setPreAuth(event.target.value)

      timeoutHandleChange = setTimeout(async () => {
        onChange(
          nameForm,
          languageForm,
          shippingForm,
          reuseShippingForm,
          targetForm,
          dateForm,
          timeForm,
          noteForm,
          event.target.value
        )
      }, 500)
    },
    [onChange]
  )

  const maxDate = useMemo(() => {
    return dayjs().add(3, 'month').format('YYYY-MM-DD')
  }, [])
  const minDate = useMemo(() => {
    return dayjs().format('YYYY-MM-DD')
  }, [])

  const hourSlots = useMemo(() => {
    const slots = []
    const start = date === minDate ? dayjs().hour() : 0
    for (let i = start; i < 24; i++) {
      slots.push({ label: i.toString(), value: i.toString().padStart(2, '0') })
    }
    return [{ label: '', value: '' }, ...slots]
  }, [date, minDate])

  const minutesSlots = useMemo(() => {
    const slots = []
    const start = date === minDate && hour && parseInt(hour) === dayjs().hour() ? dayjs().minute() : 0

    const nextQuarter = Math.ceil(start / 15) * 15

    for (let i = nextQuarter; i < 60; i += 15) {
      slots.push({ label: i.toString(), value: i.toString() === '0' ? '00' : i.toString() })
    }
    return [{ label: '', value: '' }, ...slots]
  }, [minDate, date, hour])

  useEffect(() => {
    if (hour && minutes) {
      const time = `${hour}:${minutes}`
      handleTimeChange(time)
    }
  }, [hour, minutes])

  useEffect(() => {
    if (time) {
      const [hour, minutes] = time.split(':')
      setHour(hour)
      setMinutes(minutes)
    }
  }, [time])

  return (
    <Form className="details">
      {!isShop && (
        <div className="datetime">
          <Field
            label={t('createShowDateLabel')}
            max={maxDate}
            min={minDate}
            name="date"
            type="date"
            value={date}
            required
            onChange={handleDateChange}
          />

          <div className="time">
            <Field
              disabled={!date}
              label={t('createShowHourLabel')}
              name="hour"
              options={hourSlots}
              type="select"
              value={hour}
              required
              onChange={(e: any) => setHour(e.target.value)}
            />

            <span>:</span>

            <Field
              disabled={!hour}
              label={t('createShowMinLabel')}
              name="minutes"
              options={minutesSlots}
              type="select"
              value={minutes}
              required
              onChange={(e: any) => setMinutes(e.target.value)}
            />
          </div>
        </div>
      )}

      <Field
        label={t('createShowNameLabel')}
        name="category"
        type="text"
        value={name}
        required
        onChange={handleNameChange}
      />
      <Field label={t('createShowNoteLabel')} name="note" type="textarea" value={note} onChange={handleNoteChange} />

      {!isUpdate && !isShop && (
        <Field
          label={t('createShowLanguageLabel')}
          name="language"
          options={languagesOptions}
          type="select"
          value={language}
          required
          onChange={handleLanguageChange}
        />
      )}

      <Field
        label={t('createShowShipmentLabel')}
        name="shipping"
        options={[{ label: '', value: '' }, ...shippingOptions]}
        type="select"
        value={shipping}
        required
        onChange={handleShippingChange}
      />

      <Field
        checked={reuseShipping}
        className="reuse-shipping"
        label={t('createShowReuseShippingLabel')}
        name="reuse_shipping"
        type="checkbox"
        onChange={handleReuseShippingChange}
      />

      <Field
        label={t('createShowTargetLabel')}
        max={MAX_GMV_TARGET_AMOUNT}
        min={MIN_GMV_TARGET_AMOUNT}
        name="target"
        type="number"
        value={target}
        required
        onChange={handleTargetChange}
        onWheel={(e) => e.currentTarget.blur()}
      />

      {canAccessPreAuth && (
        <Field
          label={t('createShowPreAuthLabel')}
          max={100}
          min={10}
          name="preAuth"
          type="number"
          value={preAuth}
          onChange={handlePreAuthChange}
          onWheel={(e) => e.currentTarget.blur()}
        />
      )}
    </Form>
  )
}
