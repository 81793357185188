import { useSSO } from '@bits-app/react-sso'
import { useEffect } from 'react'

import type { GoogleServiceSignInResponse } from '@bits-app/react-sso/lib/esm/SSO/Google/google.types'

import './GoogleSSOLoginButton.scss'

interface Props {
  onClick: (response: GoogleServiceSignInResponse) => void
}

function GoogleSSOLoginButton({ onClick }: Props) {
  const { renderGoogleButton } = useSSO()

  useEffect(() => {
    ;(async () => {
      try {
        await renderGoogleButton(
          { disable_on_tap: true },
          {
            shape: 'circle',
            elementId: 'button-google-sign-in',
            type: 'icon',
          },
          (response: GoogleServiceSignInResponse) => {
            return onClick(response)
          }
        )
      } catch (e) {
        // Nothing here for the moment
      }
    })()
  })

  return <div className="sso-login-action google-sso-login-action" id="button-google-sign-in" />
}

export default GoogleSSOLoginButton
