import * as Sentry from '@sentry/react'
import { Result } from 'antd'
import { type ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Button from '@/components/ui/Button/Button'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { IS_SENTRY_ENABLED, initSentry } from '@/util/sentry'

import type { User } from '@/types'

initSentry()

type ErrorBoundarySentryProps = {
  children: ReactNode
  user: User
}

const ErrorBoundary = (props: ErrorBoundarySentryProps) => {
  const { children, user } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (IS_SENTRY_ENABLED && user) {
      Sentry.setUser({ id: `${user.id}`, email: user.email, username: user.username })
    }
  }, [])

  const ErrorPage = (
    <div style={{ width: '100%', display: 'flex', height: '100vh' }}>
      <div style={{ overflow: 'scroll', width: '100%' }}>
        <ViewContainer leftContent={`${t('errorPageHeaderTitle')}`}>
          <Result
            status="warning"
            subTitle={t('errorPageMainMessage')}
            title={t('errorPageTitle')}
            extra={
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className="reload-action primary"
                  label={t('errorPageCTA')}
                  onClick={() => window.location.reload()}
                />
              </div>
            }
          />
        </ViewContainer>
      </div>
    </div>
  )

  if (!IS_SENTRY_ENABLED) return <>{children}</>
  else return <Sentry.ErrorBoundary fallback={ErrorPage}>{children}</Sentry.ErrorBoundary>
}

const mapStateToProps = (state: any) => {
  return { user: state.authentication.user }
}

export default connect(mapStateToProps)(ErrorBoundary)
