import { sellerContractAdaptater } from '../reducers/seller-contract'

import type { RootState } from '../reducers'

export const {
  selectAll: selectAllBlocks,
  selectById: selectBlockById,
  selectEntities: selectBlockEntities,
  selectIds: selectBlockIds,
  selectTotal: selectTotalBlocks,
} = sellerContractAdaptater.getSelectors((state: RootState) => state.sellerContract)
