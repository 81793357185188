import { Select } from 'antd'
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowsRotate, FaRegCircleStop } from 'react-icons/fa6'

import Confirm from '@/components/Confirm/Confirm'
import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import { useShow } from '@/contexts/show/Show.context'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useRaid } from '@/views/Show/components/CurrentRaid/hooks/use-raid'
import { useGetSimilarShowsToRaidLazyQuery } from '@/views/Show/components/CurrentRaid/operations.generated'
import { useTerminateShowMutation } from '@/views/Show/components/ShowInfos/StopShow/operation.generated'

import type { GetSimilarShowsToRaidQuery } from '@/views/Show/components/CurrentRaid/operations.generated'

import './StopShow.scss'

type StopShowProps = {
  showId: number
  isOfflineShop?: boolean
}
type SimilarShowOption = { label: string; value: string }

const extractSelectShowsToRaidOptions = (similarShowsToRaidData: GetSimilarShowsToRaidQuery) => {
  if (!similarShowsToRaidData?.similarShowsToRaid) return []

  return similarShowsToRaidData?.similarShowsToRaid.edges.map((item) => {
    return { label: item.node?.seller?.username, value: item.node.id } as SimilarShowOption
  })
}
const StopShow = (props: StopShowProps) => {
  const { showId, isOfflineShop } = props
  const showGlobalId = `Show|${showId}`
  const [showDestinationGlobalId, setShowDestinationGlobalId] = useState<string | undefined>(undefined)
  const [displayModal, setDisplayModal] = useState(false)
  const [similarShowsToRaidOptions, setSimilarShowsToRaidOptions] = useState<SimilarShowOption[]>([])
  const { t } = useTranslation()

  const { refetchProducts } = useShow()

  const [loadSimilarShows] = useGetSimilarShowsToRaidLazyQuery({
    variables: {
      showId: showGlobalId,
      all: true,
    },
    onError: (error) => {
      trackError(error, {
        meta: {
          showId: showGlobalId,
          destinationShowId: showDestinationGlobalId,
          scope: 'StopShow.useGetSimilarShowsToRaidQuery',
        },
      })
    },
  })

  useEffect(() => {
    if (displayModal) {
      loadSimilarShows({
        onCompleted: (data) => {
          setSimilarShowsToRaidOptions(extractSelectShowsToRaidOptions(data))
        },
      })
    }
  }, [displayModal])

  const [terminateShow, { loading }] = useTerminateShowMutation({
    variables: { input: { showId: `Show|${showId}` } },
  })

  const { openRaid, openRaidLoading } = useRaid({
    showGlobalId,
  })

  const handleConfirmStopShowClick = useCallback(async () => {
    await terminateShow()
    refetchProducts()
  }, [])

  const handleSendRaidClick = async () => {
    if (!showDestinationGlobalId) {
      return
    }
    trackEvent('SHOW_RAID_OPENED', { show: showId })
    openRaid(showDestinationGlobalId)
    setDisplayModal(false)
  }

  const handleSelectRandomShowToRaid = () => {
    if (!similarShowsToRaidOptions.length) {
      return
    }
    const randomIndex = Math.floor(Math.random() * similarShowsToRaidOptions.length)
    const randomShow = similarShowsToRaidOptions[randomIndex]
    setShowDestinationGlobalId(randomShow.value)
  }

  return (
    <>
      {!isOfflineShop && (
        <>
          <Button
            className="stop-show-action primary is-danger"
            disabled={loading || openRaidLoading}
            icon={<FaRegCircleStop />}
            isLoading={loading || openRaidLoading}
            label={t('showStopActionLabel')}
            tooltip={t('showStopActionTitle')}
            tooltipPosition="bottom left"
            onClick={() => setDisplayModal(true)}
          />
          <Dialog isOpen={displayModal} modal={true} title={t('showStopTitle')} onClose={() => setDisplayModal(false)}>
            <div className="open-raid">
              <h2 className="title">{t('showStopSendRaidTtitle')}</h2>

              <div className="selector">
                {!similarShowsToRaidOptions.length ? (
                  <span>{t('showStopSendRaidNoSimilarShows')}</span>
                ) : (
                  <>
                    <Select
                      options={similarShowsToRaidOptions}
                      placeholder={t('showStopSendRaidPlaceholder')}
                      value={showDestinationGlobalId}
                      showSearch
                      onChange={setShowDestinationGlobalId}
                    />
                    <NewFeatureIntroducer
                      alreadySeenKey="showStopSendRaidRandom"
                      content={t('showStopSendRaidRandomFeatureIntroducer')}
                    >
                      <Button
                        className="randomly-select-show-action"
                        icon={<FaArrowsRotate />}
                        tooltip={t('showStopSendRaidRandomTooltip')}
                        onClick={handleSelectRandomShowToRaid}
                      />
                    </NewFeatureIntroducer>
                  </>
                )}
              </div>

              <div className="buttons">
                <Button
                  className="secondary terminate-show-action"
                  disabled={openRaidLoading}
                  label={t('showStopConfirmActionLabel')}
                  onClick={handleConfirmStopShowClick}
                />
                <Button
                  className="validate-action primary"
                  disabled={!showDestinationGlobalId || openRaidLoading}
                  label={t('showStopSendRaidOpen')}
                  onClick={handleSendRaidClick}
                />
              </div>
            </div>
          </Dialog>
        </>
      )}

      {isOfflineShop && (
        <Confirm message={t('shopStopConfirmationMessage')} onConfirm={handleConfirmStopShowClick}>
          <Button
            className="stop-show-action primary is-danger"
            disabled={loading}
            icon={<FaRegCircleStop />}
            isLoading={loading}
            label={t('shopStopActionLabel')}
            tooltip={t('shopStopActionTitle')}
            tooltipPosition="bottom left"
          />
        </Confirm>
      )}
    </>
  )
}

export default memo(StopShow)
