import { type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { isValidBIC } from '@/views/Account/components/BankInfo/helpers/bic'

import type { BankInfoFormValidity } from '@/views/Account/components/BankInfo/BankInfoForm/types'

type SellerBankBicFieldFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof BankInfoFormValidity, isValid: boolean) => void
}

const SellerBankBicField = (props: SellerBankBicFieldFieldProps) => {
  const { value: bic, onChange, onValidityChange = () => undefined, ...others } = props
  const { t } = useTranslation()

  return (
    <Field
      // formatValue={(value) => value.replace(/-/g, '').toUpperCase()}
      id="bic"
      label={t('sellerBankInfoBicFieldLabel')}
      name="bic"
      placeholder={t('sellerBankInfoBicFieldPlaceholder')}
      required={true}
      type="text"
      value={bic}
      validators={[
        {
          test: (val) => isValidBIC(val?.toString() || ''),
          errorMessage: t('sellerBankInfoBicFieldInvalidFormatError'),
        },
      ]}
      {...others}
      onChange={onChange}
      onValidityChange={(isValid: boolean) => onValidityChange('bic', isValid)}
    />
  )
}

export default SellerBankBicField
