import { InfoCircleOutlined } from '@ant-design/icons'

import CustomPopover from '../Popover'

import type { ReactNode } from 'react'

import style from './style.module.scss'

type InputLabelProps = {
  title: ReactNode
  info?: ReactNode
  mandatory?: boolean
  noticePopover?: {
    title?: ReactNode
    content?: ReactNode
    placement?: string
  }
  className?: string
  withTitleSection?: boolean
}

const InputLabel = (props: InputLabelProps) => {
  const { title, info, mandatory, noticePopover, className, withTitleSection } = props
  const classNames = `label ${style.input__label} ${className}`

  return (
    <div className={classNames}>
      <label className={withTitleSection ? `${style.input__label_grey} callout_4` : 'callout_3'}>{title}</label>
      {info && <span className="info callout_3 pl-1">({info})</span>}
      {mandatory && <span className="required callout_3 pl-1 text-error">*</span>}
      {noticePopover && (
        <div className={style.label__popover}>
          <CustomPopover content={noticePopover.content} title={noticePopover.title}>
            <InfoCircleOutlined />
          </CustomPopover>
        </div>
      )}
    </div>
  )
}

export default InputLabel
