const BellIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12.4715 5.8642C12.4715 6.70149 12.6928 7.195 13.1798 7.76371C13.5489 8.18269 13.6668 8.72051 13.6668 9.30399C13.6668 9.88681 13.4753 10.4401 13.0917 10.8893C12.5895 11.4278 11.8812 11.7715 11.1583 11.8313C10.1108 11.9206 9.0626 11.9958 8.00049 11.9958C6.93772 11.9958 5.89019 11.9508 4.84266 11.8313C4.11913 11.7715 3.41084 11.4278 2.90927 10.8893C2.52564 10.4401 2.3335 9.88681 2.3335 9.30399C2.3335 8.72051 2.4521 8.18269 2.82049 7.76371C3.32272 7.195 3.52944 6.70149 3.52944 5.8642V5.58018C3.52944 4.45887 3.80905 3.72566 4.38483 3.00789C5.24087 1.96111 6.61306 1.33331 7.97068 1.33331H8.03031C9.41708 1.33331 10.8337 1.99133 11.6751 3.08309C12.2211 3.78609 12.4715 4.48842 12.4715 5.58018V5.8642ZM6.04928 13.3739C6.04928 13.0381 6.35738 12.8844 6.64228 12.8186C6.97556 12.7481 9.00635 12.7481 9.33962 12.8186C9.62453 12.8844 9.93263 13.0381 9.93263 13.3739C9.91606 13.6935 9.72855 13.9768 9.46949 14.1568C9.13356 14.4186 8.73933 14.5845 8.32721 14.6442C8.09928 14.6738 7.87533 14.6745 7.65536 14.6442C7.24258 14.5845 6.84834 14.4186 6.51308 14.1561C6.25335 13.9768 6.06584 13.6935 6.04928 13.3739Z"
        fill="#222C36"
        fillOpacity="0.5"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default BellIcon
