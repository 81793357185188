import { message } from 'antd'
import { memo, useCallback, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'

import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'
import Link from '@/components/ui/Link/Link'
import { triggerDownloadForFile } from '@/util/download-files'
import { trackError } from '@/util/sentry'
import { useCreateOrdersCsvForShowMutation } from '@/views/OrdersExport/operations.generated'

import './ExportOrdersButton.scss'

type ExportOrdersButtonProps = {
  showId: string | null
}

const ExportOrdersButton = (props: ExportOrdersButtonProps) => {
  const { showId } = props
  const showGlobalId = `Show|${showId}`
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)
  const filename = `orders-${showId}.csv`

  const [createOrdersExportForShow, { loading: isOrdersCsvExportLoading }] = useCreateOrdersCsvForShowMutation({
    variables: {
      showId: showGlobalId,
      language,
    },
  })

  const handleOrdersExportClick = useCallback(
    async (event) => {
      if (!showId) {
        event.preventDefault()
      }
      if (fileUrl) {
        triggerDownloadForFile(fileUrl, filename)
        return
      }

      let exportDownloadUrl: string | undefined
      try {
        const { data } = await createOrdersExportForShow()
        ;({ fileURL: exportDownloadUrl } = data?.export || {})
      } catch (error: any) {
        trackError(error, {
          meta: { showId, fileUrl, filename, scope: 'ExportOrdersButton.handleOrdersExportClick' },
        })
      }

      if (!exportDownloadUrl) {
        message.error(<Trans i18nKey="ordersCsvExportForShowGenericError" />, 0)
        return
      }

      setFileUrl(exportDownloadUrl)
      triggerDownloadForFile(exportDownloadUrl, filename)
    },
    [showId, fileUrl, filename, createOrdersExportForShow, t]
  )

  return (
    <NewFeatureIntroducer
      alreadySeenKey="hasSeenShowOrdersCsvExportButton"
      content={<p>{t('ordersCsvExportForShowNewFeatureIntroduction')}</p>}
    >
      <Link
        className="export-show-orders-action"
        disabled={!showId}
        download={filename}
        href={fileUrl || '#'}
        isLoading={isOrdersCsvExportLoading}
        rel="noreferrer"
        target="_blank"
        title={t('ordersCsvExportForShowActionTitle')}
        tooltip={t('ordersCsvExportForShowActionTitle')}
        tooltipPosition={'bottom right'}
        type="secondary-outlined"
        onClick={handleOrdersExportClick}
      >
        <span className="icon">
          <FaDownload />
        </span>
        <span className="label">{t('ordersCsvExportForShowActionLabel')}</span>
      </Link>
    </NewFeatureIntroducer>
  )
}

export default memo(ExportOrdersButton)
