import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductsForOrderingQueryVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
  productType: Types.ProductType;
}>;


export type ProductsForOrderingQuery = { __typename?: 'Query', productsForOrdering: { __typename?: 'ProductForOrderingConnection', totalCount: number, edges: Array<{ __typename?: 'ProductForOrderingEdge', node: { __typename?: 'ProductForOrdering', id: string, name: string, description?: string | null } }> } };

export type UpdateProductPositionsMutationVariables = Types.Exact<{
  input: Types.UpdateProductPositionsInput;
}>;


export type UpdateProductPositionsMutation = { __typename?: 'Mutation', updateProductPositions: { __typename?: 'UpdateProductPositionsPayload', ok: boolean } };


export const ProductsForOrderingDocument = gql`
    query ProductsForOrdering($showId: ID!, $productType: ProductType!) {
  productsForOrdering(all: true, showId: $showId, productType: $productType) {
    totalCount
    edges {
      node {
        id
        name
        description
      }
    }
  }
}
    `;

/**
 * __useProductsForOrderingQuery__
 *
 * To run a query within a React component, call `useProductsForOrderingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForOrderingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForOrderingQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useProductsForOrderingQuery(baseOptions: Apollo.QueryHookOptions<ProductsForOrderingQuery, ProductsForOrderingQueryVariables> & ({ variables: ProductsForOrderingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>(ProductsForOrderingDocument, options);
      }
export function useProductsForOrderingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>(ProductsForOrderingDocument, options);
        }
export function useProductsForOrderingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>(ProductsForOrderingDocument, options);
        }
export type ProductsForOrderingQueryHookResult = ReturnType<typeof useProductsForOrderingQuery>;
export type ProductsForOrderingLazyQueryHookResult = ReturnType<typeof useProductsForOrderingLazyQuery>;
export type ProductsForOrderingSuspenseQueryHookResult = ReturnType<typeof useProductsForOrderingSuspenseQuery>;
export type ProductsForOrderingQueryResult = Apollo.QueryResult<ProductsForOrderingQuery, ProductsForOrderingQueryVariables>;
export const UpdateProductPositionsDocument = gql`
    mutation UpdateProductPositions($input: UpdateProductPositionsInput!) {
  updateProductPositions(input: $input) {
    ok
  }
}
    `;
export type UpdateProductPositionsMutationFn = Apollo.MutationFunction<UpdateProductPositionsMutation, UpdateProductPositionsMutationVariables>;

/**
 * __useUpdateProductPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateProductPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPositionsMutation, { data, loading, error }] = useUpdateProductPositionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductPositionsMutation, UpdateProductPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductPositionsMutation, UpdateProductPositionsMutationVariables>(UpdateProductPositionsDocument, options);
      }
export type UpdateProductPositionsMutationHookResult = ReturnType<typeof useUpdateProductPositionsMutation>;
export type UpdateProductPositionsMutationResult = Apollo.MutationResult<UpdateProductPositionsMutation>;
export type UpdateProductPositionsMutationOptions = Apollo.BaseMutationOptions<UpdateProductPositionsMutation, UpdateProductPositionsMutationVariables>;