import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegCopy } from 'react-icons/fa6'

import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'

type CopyableTextProps = {
  value: string
  messageSuccess?: string
}

const Copyable = (props: CopyableTextProps) => {
  const { value, messageSuccess } = props
  const { t } = useTranslation()

  const handleCopy = useCallback(
    async (e: any) => {
      e.stopPropagation()
      if (!navigator.clipboard) {
        notificationDanger(t('copyableTextMessageError'))
        return
      }
      try {
        value && (await navigator.clipboard.writeText(value))
        notificationSuccess(messageSuccess ?? t('copyableTextMessageSuccess'))
      } catch (err) {
        notificationDanger(t('copyableTextMessageError'))
      }
    },
    [value, messageSuccess]
  )

  if (!value) return null

  return (
    <div className="copyable-content" onClick={handleCopy}>
      <span>{value}</span>
      <span className="icon">
        <FaRegCopy title={t('commonCopyToClipboardTitle')} />
      </span>
    </div>
  )
}

export default memo(Copyable)
