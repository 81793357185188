import { useCallback, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { preventChangeOnWheelScrollOnNumberInputs } from '@/helpers/forms/preventChangeOnWheelScrollOnNumberInputs'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct/types'

type ProductStartingPriceFieldProps = {
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  value?: string
  buyNowPrice: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

export const STARTING_PRICE_MAX_IN_CENTS = 10000000
export const STARTING_PRICE_MIN_IN_CENTS = 100

const ProductStartingPriceField = (props: ProductStartingPriceFieldProps) => {
  const {
    value: startingPrice,
    buyNowPrice,
    onChange: handleStartingPriceChange,
    onValidityChange = () => undefined,
    disabled = false,
  } = props
  const { t } = useTranslation()

  const preventInputWheelOnNumberInput = useCallback(preventChangeOnWheelScrollOnNumberInputs, [])

  return (
    <Field
      disabled={disabled}
      label={t('productFormStartingPriceLabel')}
      max={STARTING_PRICE_MAX_IN_CENTS / 100}
      min={STARTING_PRICE_MIN_IN_CENTS / 100}
      name="starting-price"
      required={true}
      type="number"
      value={startingPrice}
      validators={[
        {
          test: (val) => parseInt(val?.toString() || '', 10) == val, // we do not allow cents
          errorMessage: t('startingPriceShouldBeInt'),
        },
        {
          test: (val) =>
            buyNowPrice && val
              ? parseInt(val?.toString() || '', 10) < parseInt(buyNowPrice?.toString() || '', 10)
              : true,
          errorMessage: t('startingPriceShouldBeLowerThanInstantBuy'),
        },
      ]}
      onChange={handleStartingPriceChange}
      onValidityChange={(isValid: boolean) => onValidityChange('startingPrice', isValid)}
      onWheel={preventInputWheelOnNumberInput}
    />
  )
}

export default ProductStartingPriceField
