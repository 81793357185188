import { useGetSellerTierQuery } from '@/components/SellerTier/graphql/operations.generated'
import { trackError } from '@/util/sentry'

export const useSellerTier = () => {
  const { data, loading } = useGetSellerTierQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onError: (error) => {
      trackError(error, { meta: { scope: 'useSellerTier' } })
    },
  })

  return { sellerTier: data?.viewer?.sellerConfig?.sellerTier, loading }
}
