import { useMemo } from 'react'

import { useGetParentCategoriesQuery } from './CreateOrEditProductForm/operations.generated'

import type { Category, ParentCategory } from '@/network/graphql/types.generated'

export type CategoriesForParentCategory = Pick<Category, 'id' | 'name' | 'slug' | 'isOffline' | 'stickerUrl'>
export type ParentCategories = Array<
  Pick<ParentCategory, 'id' | 'name' | 'slug'> & { categories: CategoriesForParentCategory[] }
>

const useParentCategories = () => {
  const { data: parentCategoriesData, loading } = useGetParentCategoriesQuery({
    variables: { first: 100 },
  })

  const parentCategories: ParentCategories = useMemo(() => {
    return (
      parentCategoriesData?.parentCategoriesOrderedByPosition.edges.map(({ node }) => {
        const { __typename, allCategories, ...others } = node
        return {
          ...others,
          categories: allCategories.map(({ __typename, ...rest }) => ({ ...rest })),
        }
      }) || []
    )
  }, [parentCategoriesData])

  return { loading, parentCategories }
}

export default useParentCategories
