import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import Card from '@/components/ui/Card/Card'
import Table, { DEFAULT_ITEM_PER_PAGE } from '@/components/ui/Table/Table'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { GetSellerCancelledAndRefundedOrdersSort, OrderStatus } from '@/network/graphql/types.generated'

import CanceledDetails from '../Details/CanceledDetails'
import { useGetSellerCancelledAndRefundedOrdersQuery } from '../operations.generated'
import { useShipmentContext } from '../ShipmentProvider'
import ShipmentSearch from '../ShipmentSearch/ShipmentSearch'

import { ShippingAddressColumn } from './components/ShippingAddressColumn/ShippingAddressColumn'
import UsernameAndContactColumn from './components/UsernameAndContactColumn/UsernameAndContactColumn'

import type { GetSellerCancelledAndRefundedOrdersQuery } from '../operations.generated'
import type { ShipmentsTabsFilter } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './ShipmentsTables.scss'

const extractRows = (rows: GetSellerCancelledAndRefundedOrdersQuery | undefined) => {
  return rows?.getSellerCancelledAndRefundedOrders.edges.map((edge) => edge.node)
}

const CanceledOrdersTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currencyFormatter = useCurrencyFormatter()
  const { usernameSearchValue, onSetUsernameSearchValue } = useShipmentContext()

  const perPageStored = getFromLocalStorage('default_per_page_canceled_shipments')
  const defaultPerPage = perPageStored ? parseInt(perPageStored) : DEFAULT_ITEM_PER_PAGE
  const [perPage, setPerPage] = useState<number>(defaultPerPage)

  const [sort, setSort] = useState<GetSellerCancelledAndRefundedOrdersSort | undefined>(
    GetSellerCancelledAndRefundedOrdersSort.CreatedAtDateDesc
  )
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'createdAt',
      desc: true,
    },
  ])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filters, setFilters] = useState<ShipmentsTabsFilter>()

  const orderId = new URLSearchParams(location.search).get('orderId') || undefined

  useEffect(() => {
    setFilters(usernameSearchValue ? { username: usernameSearchValue } : undefined)
  }, [usernameSearchValue])

  const { data, loading, fetchMore } = useGetSellerCancelledAndRefundedOrdersQuery({
    skip: !!orderId,
    variables: {
      first: perPage,
      sort,
      filter: filters,
    },
  })
  const rows = extractRows(data)
  const total = data?.getSellerCancelledAndRefundedOrders.totalCount || 0
  const fetchMoreData = async (page: number) => {
    setCurrentPage(page)
    await fetchMore({
      variables: {
        offset: (page - 1) * perPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  const columns = [
    {
      header: t('canceledOrdersTableHeaderCustomer'),
      id: 'customer',
      accessorKey: 'customer',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { customer, createdAt, shippingAddress } = record
        const { username, id, avatarWebPUrl } = customer
        const { name } = shippingAddress
        return (
          <UsernameAndContactColumn
            avatarUrl={avatarWebPUrl}
            createdAt={createdAt}
            name={name}
            userId={id}
            username={username}
          />
        )
      },
    },
    {
      header: t('canceledOrdersTableHeaderAddress'),
      id: 'address',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { shippingAddress } = record
        return <ShippingAddressColumn {...shippingAddress} />
      },
    },
    {
      header: t('canceledOrdersTableHeaderDate'),
      id: 'createdAt',
      accessorKey: 'createdAt',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { createdAt } = record
        return <p>{dayjs(createdAt).format('DD/MM/YYYY')}</p>
      },
    },
    {
      header: t('canceledOrdersTableHeaderAmount'),
      id: 'amount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        return <p>{currencyFormatter(record.amountInCurrencyCents, record.currency)}</p>
      },
    },
    {
      header: t('canceledOrdersTableHeaderStatus'),
      id: 'status',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { status: orderStatus } = record
        switch (orderStatus) {
          case OrderStatus.Canceled:
            return <Tag content={t('canceledOrdersTableStatusCanceled')} status={TagStatus.WARNING} />
          case OrderStatus.Refunded:
            return <Tag content={t('canceledOrdersTableStatusRefunded')} status={TagStatus.SECONDARY} />
          default:
            return
        }
      },
    },
  ]

  const handlePageChange = async (page: number) => {
    fetchMoreData(page)
  }
  const handleItemPerPageChange = async (perPage: number) => {
    setPerPage(perPage)
    setToLocalStorage('default_per_page_canceled_shipments', perPage.toString())
  }
  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setCurrentPage(1)
    if (!sort[0]) {
      setSort(undefined)
    } else {
      if (sort[0].id === 'createdAt' && sort[0].desc === true) {
        setSort(GetSellerCancelledAndRefundedOrdersSort.CreatedAtDateDesc)
      } else if (sort[0].id === 'createdAt' && sort[0].desc === false) {
        setSort(GetSellerCancelledAndRefundedOrdersSort.CreatedAtDateAsc)
      } else if (sort[0].id === 'customer' && sort[0].desc === true) {
        setSort(GetSellerCancelledAndRefundedOrdersSort.UsernameDesc)
      } else if (sort[0].id === 'customer' && sort[0].desc === false) {
        setSort(GetSellerCancelledAndRefundedOrdersSort.UsernameAsc)
      }
    }
  }
  const handleSearch = useCallback(
    (username: string) => {
      onSetUsernameSearchValue(username)
      setCurrentPage(1)
    },
    [onSetUsernameSearchValue]
  )

  const handleOpenDetails = useCallback((row: any) => {
    const id = row.id.split('|')[1]
    const url = `/shipments?tab=cancelAndRefund&orderId=${id}`
    navigate(url)
  }, [])

  return orderId ? (
    <CanceledDetails orderId={orderId} />
  ) : (
    <>
      <ShipmentSearch value={usernameSearchValue} onChange={handleSearch} />
      <Card className="shipments-tables shipments-canceled" noPadding={true}>
        {rows && rows.length === 0 && !loading ? (
          <div className="no-shipments">
            <svg fill="none" height="72" viewBox="0 0 73 72" width="73" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.4">
                <path
                  clipRule="evenodd"
                  d="M61.25 34.9917H56.297C55.055 34.9917 54.047 35.9997 54.047 37.2417C54.047 38.4837 55.055 39.4917 56.297 39.4917H61.25V45.2997C61.25 47.5197 59.48 49.2897 57.26 49.2897H56.78C56.03 46.7097 53.96 44.6397 51.35 43.8897V27.4797L58.37 30.5697C60.14 31.3197 61.25 33.0597 61.25 34.9797V34.9917ZM52.61 51.3897C52.58 51.4497 52.58 51.4797 52.58 51.5397C52.58 51.5997 52.58 51.6297 52.61 51.6897V51.7197C52.49 53.6097 50.96 55.0497 49.1 55.0497C47.45 55.0497 46.1 53.9397 45.71 52.4397C45.623 52.1457 45.593 51.8487 45.593 51.5547C45.593 51.5487 45.59 51.5427 45.59 51.5367C45.59 50.4597 46.07 49.4997 46.85 48.8697C47.45 48.3597 48.23 48.0597 49.1 48.0597C49.94 48.0597 50.75 48.3597 51.35 48.8997C52.07 49.4997 52.55 50.3397 52.61 51.3597V51.3897ZM35.984 29.5617L27.599 37.9617C27.179 38.3817 26.606 38.6217 26.009 38.6217C25.412 38.6217 24.842 38.3847 24.419 37.9647L19.973 33.5247C19.094 32.6457 19.094 31.2207 19.97 30.3417C20.849 29.4597 22.277 29.4627 23.153 30.3387L26.006 33.1887L32.798 26.3817C33.674 25.4997 35.099 25.4997 35.981 26.3787C36.86 27.2577 36.86 28.6827 35.984 29.5617ZM25.13 51.8097C25.01 53.6397 23.51 55.0497 21.65 55.0497C19.76 55.0497 18.2 53.5197 18.17 51.5997V51.5397C18.17 49.6197 19.73 48.0597 21.65 48.0597C23.6 48.0597 25.16 49.6197 25.16 51.5397C25.16 51.6297 25.16 51.7197 25.13 51.8097ZM60.2 26.4597L51.35 22.5597V20.9397C51.35 16.2597 47.54 12.4497 42.86 12.4497H15.65C11.03 12.4497 7.25 16.2297 7.25 20.8497V45.2997C7.25 49.3497 10.13 52.7697 13.94 53.5797C14.81 56.9997 17.96 59.5497 21.65 59.5497C25.304 59.5497 28.361 57.1257 29.324 53.8017H41.423C42.389 57.1257 45.476 59.5497 49.1 59.5497C52.73 59.5497 55.82 57.1197 56.78 53.7897H57.26C61.94 53.7897 65.75 49.9797 65.75 45.2997V34.9797C65.75 31.2597 63.56 27.8997 60.2 26.4597Z"
                  fill="#171514"
                  fillRule="evenodd"
                />
              </g>
            </svg>

            <p>{t('noCanceledOrders')}</p>
          </div>
        ) : (
          <Table
            columns={columns}
            data={rows}
            loading={loading}
            header={
              <header className="shipments-tables-header">
                <h2 className="shipments-tables-title">{t('canceledOrderedProductsTitle')}</h2>
              </header>
            }
            pagination={{
              total,
              currentPage,
              perPage,
              onPageChange: handlePageChange,
              onItemPerPageChange: handleItemPerPageChange,
            }}
            sort={{
              sorting,
              onSortingChange: handleSortingChange,
            }}
            onRowClick={handleOpenDetails}
          />
        )}
      </Card>
    </>
  )
}

export default memo(CanceledOrdersTable)
