export enum IssueTypeTag {
  emergency_start = 'emergency_start',
  emergency_cut = 'emergency_cut',
  emergency_upsidedown = 'emergency_upsidedown',
  emergency_notification = 'emergency_notification',
  emergency_auction = 'emergency_auction',
  emergency_frozen = 'emergency_frozen',
  emergency = 'emergency',
  other = 'other',
}

export type IssueType = {
  key: string
  translationKey: string
  link: string
  tag: IssueTypeTag
}

const universityLinks: IssueType[] = [
  {
    key: 'cantStartShow',
    translationKey: 'showIssueReporterUniversityLinkCantStartShow',
    link: 'showIssueReporterCantStartShowUniversityUrl',
    tag: IssueTypeTag.emergency_start,
  },
  {
    key: 'videoKeepsCutting',
    translationKey: 'showIssueReporterUniversityLinkVideoKeepsCutting',
    link: 'showIssueReporterVideoKeepsCuttingUniversityUrl',
    tag: IssueTypeTag.emergency_cut,
  },
  {
    key: 'videoUpsideDown',
    translationKey: 'showIssueReporterUniversityLinkVideoUpsideDown',
    link: 'showIssueReporterVideoUpsideDownUniversityUrl',
    tag: IssueTypeTag.emergency_upsidedown,
  },
  {
    key: 'notificationNotSent',
    translationKey: 'showIssueReporterUniversityLinkNotificationNotSent',
    link: 'showIssueReporterNotificationNotSentUniversityUrl',
    tag: IssueTypeTag.emergency_notification,
  },
  {
    key: 'auctionsStuck',
    translationKey: 'showIssueReporterUniversityLinkAuctionsStuck',
    link: 'showIssueReporterAuctionsStuckUniversityUrl',
    tag: IssueTypeTag.emergency_auction,
  },
  {
    key: 'other',
    translationKey: 'showIssueReporterUniversityLinkOther',
    link: '',
    tag: IssueTypeTag.other,
  },
  // {
  //   key: 'chatFrozen',
  //   translationKey: 'showIssueReporterUniversityLinkChatFrozen',
  //   link: 'showIssueReporterChatFrozenUniversityUrl',
  //   tag: IssueTypeTag.emergency_frozen,
  // },
]

export default universityLinks
