import { memo } from 'react'

import ArrowRightIcon from '../ui/Icons/ArrowIcon/ArrowRightIcon'

import type { ReactNode } from 'react'

type ProductTypeCellProps = {
  icon: ReactNode
  title: string
  subTitle: string
  onClick: () => void
}

const ProductTypeCell = (props: ProductTypeCellProps) => {
  return (
    <div className="product-type-cell" onClick={props.onClick}>
      <div className="product-type-cell-icon">{props.icon}</div>
      <div className="product-type-cell-content">
        <h2 className="product-type-cell-title">{props.title}</h2>
        <p className="product-type-cell-subtitle">{props.subTitle}</p>
      </div>

      <div className="product-type-cell-arrow">
        <ArrowRightIcon />
      </div>
    </div>
  )
}

export default memo(ProductTypeCell)
