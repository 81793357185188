import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { convertImageIfNecessary } from '@/helpers/images/convertImageIfNecessary'
import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

interface AddInventoryProductPictureProps {
  files: File[]
  inventoryProductId: string
}

export const addInventoryProductPicture = createAsyncThunk(
  'add-inventory-product-picture',
  async ({ files, inventoryProductId }: AddInventoryProductPictureProps, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    const form = new FormData()

    try {
      // NOTE: We do the following in series to make sure we keep the order of the images
      for (const file of files) {
        // TODO: this should be done higher up in the stack
        const usedFile = await convertImageIfNecessary(file)
        if (usedFile) {
          form.append('files[]', usedFile)
        }
      }

      const { data } = await internalRequest({
        headers: {
          authorization: token,
          'content-type': 'multipart/form-data',
          ...(await getAdditionalHeaders()),
        },
        data: form,
        url: `/inventory-products/${inventoryProductId}/uploads-multipart`,
        method: 'POST',
        dispatch,
      })

      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
      throw error // Rethrow other errors
    }
  }
)
