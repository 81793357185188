import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircleQuestion } from 'react-icons/fa6'

import { useShow } from '@/contexts/show/Show.context'
import AmountPopover from '@/views/Show/components/AmountPopover/AmountPopover'

import './ShowSalesFigures.scss'

type ShowSalesFiguresProps = {
  showId: string
}

const ShowSalesFigures = (props: ShowSalesFiguresProps) => {
  const { showId } = props
  const { t } = useTranslation()

  const { totalAmount } = useShow()

  return (
    <div className="sales-figures show-sales-figures">
      <span className="total-sold-amount">
        <span className="label">{t('showSalesFiguresTotalSoldAmountLabel')}</span>
        <data className="value">
          &nbsp;
          {totalAmount !== null ? (totalAmount / 100).toFixed(2) : '?'}
          {totalAmount !== null && <span className="currency">€</span>}
        </data>
        <AmountPopover showId={showId}>
          <FaCircleQuestion />
        </AmountPopover>
      </span>
    </div>
  )
}

export default memo(ShowSalesFigures)
