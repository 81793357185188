import { useTranslation } from 'react-i18next'

import Countdown from '@/components/Countdown'
import Card from '@/components/ui/Card/Card'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { ShowFlashSaleStatus } from '@/network/graphql/types.generated'

import type { FlashSaleFragment } from '@/views/Show/operations.generated'

import './CurrentFlashSale.scss'

type CurrentFlashSaleProps = {
  currentFlashSale?: FlashSaleFragment | null
}

const CurrentFlashSale = (props: CurrentFlashSaleProps) => {
  const { currentFlashSale: currentFlashSale } = props
  const { t } = useTranslation()
  const formatCurrency = useCurrencyFormatter()

  if (!currentFlashSale) return null

  const {
    serverTime,
    initialProductAmount,
    finalProductAmount,
    initialQuantity,
    remainingQuantity,
    status,
    discountPercentage,
  } = currentFlashSale

  const product = currentFlashSale?.product
  const endAt = new Date(currentFlashSale?.endAt)
  const displayedEndDate = endAt ? new Date(endAt) : new Date()
  const displayedStartDate = serverTime || new Date()

  const { amount: initialAmount } = initialProductAmount || {}
  const { amount, currency } = finalProductAmount || {}
  const hasDiscount = initialAmount !== amount

  const displayedStatus = status === ShowFlashSaleStatus.Closed ? 'done' : 'in-progress'
  const statusClassName = `is-${displayedStatus}`

  return (
    <Card className="current-activity current-flashsale" title={t('showCurrentFlashSaleTitle')}>
      <dl>
        <dt className="remaining-time-title">{t('showFlashSaleCardTitle')}</dt>
        <dd className="remaining-time">
          <Countdown endDate={displayedEndDate} startDate={displayedStartDate} />
        </dd>
        <dt className="product-name-title">{t('showFlashSaleProductTitle')}</dt>
        <dd className="product-name">
          <span className="value">{product?.name}</span>
        </dd>

        <dt className="status-title">{t('showFlashSaleStatusTitle')}</dt>
        <dd className={`status ${statusClassName}`}>
          {displayedStatus === 'in-progress' && t('showCurrentActivityItemStatusInProgressLabel')}
          {displayedStatus === 'done' && t('showCurrentActivityItemStatusDoneLabel')}
        </dd>

        <dt className="price-title">{t('showFlashSaleAmountTitle')}</dt>
        <dd className="price">
          {hasDiscount && <span className="initial-price">{formatCurrency(initialAmount, currency)}</span>}
          <span className="final-price">{formatCurrency(amount, currency)}</span>
          {discountPercentage && <span className="discount">(-{discountPercentage}%)</span>}
        </dd>

        <dt className="sold-quantity-title">{t('showFlashSaleQuantityTitle')}</dt>
        <dd className="sold-quantity">{initialQuantity - remainingQuantity}</dd>
      </dl>
    </Card>
  )
}

export default CurrentFlashSale
