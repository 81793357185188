import { message } from 'antd'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import getShowShippingCategories from '@/data/showShippingCategories'
import { legacyCurrencyFormatter } from '@/helpers/currencyFormatter'
import { ShippingCategory } from '@/network/graphql/types.generated'
import { AuctionDurationSelectorContainer as AuctionDurationSelector } from '@/views/Show/components/CurrentAuction/AuctionDurationSelector/AuctionDurationSelector.container'

import { useUpdateShowShippingCategoryMutation } from '../../operations.generated'

import type { ShowByIdFragment } from '../../operations.generated'

import './NextItemsOptions.scss'

export interface NextItemsOptionsProps {
  show: ShowByIdFragment
  isShowLaunched: boolean
  isShop?: boolean
}

const rawValues: any = {
  free: ShippingCategory.Free,
  letter: ShippingCategory.Letter,
  large_letter: ShippingCategory.LargeLetter,
  letter_registered: ShippingCategory.LetterRegistered,
  package: ShippingCategory.Package,

  ups: ShippingCategory.Ups,
  mondial_relay: ShippingCategory.MondialRelay,
  colissimo: ShippingCategory.Colissimo,

  sneakers_standard: ShippingCategory.SneakersStandard,
  sneakers_affordable: ShippingCategory.SneakersAffordable,
  sneakers_premium: ShippingCategory.SneakersPremium,

  fashion_small_package: ShippingCategory.FashionSmallPackage,
  fashion_small_package_incremental: ShippingCategory.FashionSmallPackageIncremental,
  fashion_medium_package: ShippingCategory.FashionMediumPackage,
  fashion_medium_package_incremental: ShippingCategory.FashionMediumPackageIncremental,
  fashion_large_package: ShippingCategory.FashionLargePackage,
  fashion_large_package_incremental: ShippingCategory.FashionLargePackageIncremental,

  letter_plus: ShippingCategory.LetterPlus,
  large_letter_plus: ShippingCategory.LargeLetterPlus,
  package_plus: ShippingCategory.PackagePlus,

  letter_incremental: ShippingCategory.LetterIncremental,
  package_incremental: ShippingCategory.PackageIncremental,
  letter_registered_incremental: ShippingCategory.LetterRegisteredIncremental,
}

const NextItemsOptions = (props: NextItemsOptionsProps) => {
  const { show, isShowLaunched, isShop = false } = props
  const { id: showId } = show

  const { t } = useTranslation()
  const [updateShowShippingCategory] = useUpdateShowShippingCategoryMutation()
  const [selectedShippingOption, setSelectedShippingOption] = useState(show.shippingCategory as ShippingCategory)

  const shippingOptions: any = getShowShippingCategories(show.country.id.split('|')[1], show.category.legacyId)

  let tabCustomValuesFormatted: any = {}

  shippingOptions?.forEach(
    (shippingOption: any) =>
      (tabCustomValuesFormatted = {
        ...tabCustomValuesFormatted,
        [rawValues[shippingOption.value]]: {
          value: t(shippingOption.label).toLowerCase(),
          amount: shippingOption.amount,
        },
      })
  )

  const handleShippingCategoryChange = useCallback(
    (event: React.ChangeEvent) => {
      const shippingCategory = (event.target as HTMLInputElement).value
      if (!shippingCategory) {
        return
      }

      updateShowShippingCategory({
        variables: {
          input: {
            showId,
            shippingCategory: shippingCategory as ShippingCategory,
          },
        },
        onCompleted: () => {
          setSelectedShippingOption(shippingCategory as ShippingCategory)
        },
        onError: (err) => {
          message.error(err.message)
        },
      })
    },
    [showId]
  )

  if (isShop) {
    return (
      <>
        <p className="mb-2 font-semibold">{t('showCardShowConfigShippingCategoryUpdateTitle')}</p>
        <select
          value={selectedShippingOption}
          className="bg-white border border-gray-300 rounded-md px-2 py-1bg-white-100 border 
          border-slate-200 rounded-md px-3 py-2 w-full focus:border-slate-500 focus:ring-0 
          hover:border-slate-400 transition-colors animate duration-200 min-w-52"
          onChange={handleShippingCategoryChange}
        >
          {Object.entries(tabCustomValuesFormatted).map(([key, option]: any) => (
            <option key={key} value={key}>
              {option.value} ({legacyCurrencyFormatter(option.amount)})
            </option>
          ))}
        </select>
      </>
    )
  }

  return (
    <Card className="next-item-options">
      <h2 className="title">{isShowLaunched ? t('showCardShowConfigTitle') : undefined}</h2>
      <div className="field next-item-option-field next-item-shipping-category-field">
        <div className="field-label">
          <label htmlFor="next-item-shipping-cagegory">{t('showCardShowConfigShippingCategoryUpdateTitle')}</label>
        </div>
        <div className="field-input">
          <select
            name="next-item-shipping-cagegory"
            value={selectedShippingOption}
            onChange={handleShippingCategoryChange}
          >
            {Object.entries(tabCustomValuesFormatted).map(([key, option]: any) => (
              <option key={key} value={key}>
                {option.value} ({legacyCurrencyFormatter(option.amount)})
              </option>
            ))}
          </select>
        </div>
      </div>
      <AuctionDurationSelector showId={showId} />
    </Card>
  )
}

export default NextItemsOptions
