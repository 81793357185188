import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import Card from '@/components/ui/Card/Card'
import Link from '@/components/ui/Link/Link'
import Loader from '@/components/ui/Loader/Loader'
import Table from '@/components/ui/Table/Table'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import { useGetOrderWithOrderedProductsQuery } from '../operations.generated'
import { OrderStatusTag } from '../Tables/components/OrderStatus/OrderStatus'
import { ShippingAddressColumn } from '../Tables/components/ShippingAddressColumn/ShippingAddressColumn'
import UsernameAndContactColumn from '../Tables/components/UsernameAndContactColumn/UsernameAndContactColumn'

import './Details.scss'

interface CanceledDetailsProps {
  orderId: string
}

const CanceledDetails = (props: CanceledDetailsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const currencyFormatter = useCurrencyFormatter()

  const { orderId } = props
  const { loading, data } = useGetOrderWithOrderedProductsQuery({
    skip: !orderId,
    variables: {
      nodeId: `Order|${orderId}`,
    },
  })

  const order = data?.node.__typename === 'Order' ? data?.node : undefined

  const totalOrderedProductsAmount = order?.amountInCurrencyCents || 0
  const totalShippingAmount = order?.shippingFeesCancellationRequestedAt ? 0 : order?.shippingAmountInCurrencyCents || 0

  const handleGoBack = () => {
    navigate('/shipments?tab=cancelAndRefund')
  }

  if (!order && !loading) {
    handleGoBack()
    return null
  }

  const columns = [
    {
      header: t('canceledDetailTableHeaderProduct'),
      id: 'product',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { id, name, images } = record.orderedProducts.edges[0].node
        const imageUrl = images?.[0]?.webPUrl || '/badges/empty-pic.png'

        return (
          <div key={`product-${id}`} className="shipment-detail-product">
            <div>
              <div className="shipment-detail-product-image">
                <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
              </div>
              <div className="shipment-detail-product-details">
                <p>{name}</p>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      header: t('canceledDetailTableHeaderCreatedAt'),
      id: 'createdAt',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { createdAt } = record

        return <p className="shipments-date">{dayjs(createdAt).format('DD/MM/YYYY')}</p>
      },
    },
    {
      header: t('canceledDetailTableHeaderAmount'),
      id: 'amount',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { amountInCurrencyCents, currency } = record

        return amountInCurrencyCents ? currencyFormatter(amountInCurrencyCents, currency) : '-'
      },
    },
    {
      header: t('canceledDetailTableHeaderShippingFees'),
      id: 'shippingFees',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { shippingAmountInCurrencyCents, currency, shippingFeesCancellationRequestedAt } = record
        const isCancelled = !!shippingFeesCancellationRequestedAt

        return (
          <div className="column-shipping-fee">
            <p className={isCancelled ? 'shipping-fees-refunded' : ''}>
              {shippingAmountInCurrencyCents ? currencyFormatter(shippingAmountInCurrencyCents, currency) : '-'}
            </p>
          </div>
        )
      },
    },
  ]

  return (
    <div className="canceled-details details-page">
      <div className="canceled-detail-links detail-links">
        <Link onClick={handleGoBack}>{t('canceledTabSwitcherShippedLabel')}</Link>
        <FaCaretRight />
        <p>{t('shipmentDetail')}</p>
      </div>

      {!order || loading ? (
        <Loader />
      ) : (
        <>
          <Card className="canceled-detail-header detail-header">
            <h2 className="title">{t('canceledDetailheaderTitle', { id: `#${order.legacyId}` })}</h2>
            <div className="canceled-detail-header-actions detail-header-actions">
              <OrderStatusTag status={order.status} />
            </div>
          </Card>

          <div className="canceled-detail-infos detail-infos">
            <Card>
              <h3 className="title">{t('canceledDetailInfoAmountsTitle')}</h3>

              <div className="detail-info">
                <p>{t('canceledDetailInfoTotalProducts')}</p>
                <p>
                  {totalOrderedProductsAmount ? currencyFormatter(totalOrderedProductsAmount, order.currency) : '-'}
                </p>
              </div>

              <div className="detail-info">
                <p>{t('canceledDetailInfoTotalShipping')}</p>
                <p>{totalShippingAmount ? currencyFormatter(totalShippingAmount, order.currency) : '-'}</p>
              </div>
            </Card>

            <Card>
              <h3 className="title">{t('canceledDetailInfoShipmentTitle')}</h3>
              <div className="detail-info">
                <p>{t('canceledDetailInfoUser')}</p>
                <UsernameAndContactColumn
                  avatarUrl={order.customer.avatarWebPUrl}
                  createdAt={order.createdAt}
                  userId={order.customer.id}
                  username={order.customer.username}
                />
              </div>
              <div className="detail-info">
                <p>{t('canceledDetailInfoFullName')}</p>
                <p>{order.shippingAddress.name}</p>
              </div>
              <div className="detail-info">
                <p>{t('canceledDetailInfoAddress')}</p>
                <ShippingAddressColumn {...order.shippingAddress} />
              </div>
            </Card>
          </div>

          <Card className="canceled-detail-items detail-items">
            <Table
              columns={columns}
              data={[order]}
              header={<h3 className="title">{t('shipmentDetailArticlesTableTitle')}</h3>}
            />
          </Card>
        </>
      )}
    </div>
  )
}

export default memo(CanceledDetails)
