import { ShippingCategory } from '@/network/graphql/types.generated'

export const mappingToShippingCategory = {
  free: ShippingCategory.Free,
  letter: ShippingCategory.Letter,
  large_letter: ShippingCategory.LargeLetter,
  letter_registered: ShippingCategory.LetterRegistered,
  package: ShippingCategory.Package,

  ups: ShippingCategory.Ups,
  colissimo: ShippingCategory.Colissimo,
  mondial_relay: ShippingCategory.MondialRelay,

  sneakers_standard: ShippingCategory.SneakersStandard,
  sneakers_affordable: ShippingCategory.SneakersAffordable,
  sneakers_premium: ShippingCategory.SneakersPremium,

  fashion_small_package: ShippingCategory.FashionSmallPackage,
  fashion_small_package_incremental: ShippingCategory.FashionSmallPackageIncremental,
  fashion_medium_package: ShippingCategory.FashionMediumPackage,
  fashion_medium_package_incremental: ShippingCategory.FashionMediumPackageIncremental,
  fashion_large_package: ShippingCategory.FashionLargePackage,
  fashion_large_package_incremental: ShippingCategory.FashionLargePackageIncremental,

  letter_plus: ShippingCategory.LetterPlus,
  large_letter_plus: ShippingCategory.LargeLetterPlus,
  package_plus: ShippingCategory.PackagePlus,

  letter_incremental: ShippingCategory.LetterIncremental,
  package_incremental: ShippingCategory.PackageIncremental,
  letter_registered_incremental: ShippingCategory.LetterRegisteredIncremental,
}

export const mappingFromShippingCategory = Object.fromEntries(
  Object.entries(mappingToShippingCategory).map(([key, value]) => [value, key])
) as Record<ShippingCategory, string>
