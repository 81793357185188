import { useShops } from '@/views/Shops/hooks/use-shops'

import { ShopsTable } from '../ShowsTable/ShopsTable'

import './ShowsList.scss'
export const ShopsList = () => {
  const { shops } = useShops()

  return (
    <div className="shops-list">
      <ShopsTable shops={shops} />
    </div>
  )
}
