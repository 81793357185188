import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

type ValidateProductFormButtonProps = {
  disabled: boolean
  isLoading: boolean
  isEditMode: boolean
}

const ValidateProductFormButton = (props: ValidateProductFormButtonProps) => {
  const { disabled, isLoading, isEditMode } = props
  const { t } = useTranslation()

  return (
    <Button
      className="validate-action primary"
      disabled={disabled}
      isLoading={isLoading}
      label={isEditMode ? t('productFormUpdateActionLabel') : t('productFormCreateActionLabel')}
      type="submit"
    />
  )
}

export default ValidateProductFormButton
