import Loader from '@/components/ui/Loader/Loader'

import type { AnchorHTMLAttributes, MouseEvent, ReactNode, SyntheticEvent } from 'react'

import './Link.scss'

export type TooltipPosition = 'top left' | 'top center' | 'top right' | 'bottom left' | 'bottom center' | 'bottom right'

export type LinkType = 'primary' | 'secondary' | 'secondary-outlined' | undefined

export type LinkProps = {
  className?: string
  type?: LinkType
  children?: ReactNode
  disabled?: boolean
  href?: string
  isSmall?: boolean
  icon?: ReactNode
  isLoading?: boolean
  onClick?: (event?: SyntheticEvent) => void
  target?: string
  tooltip?: string
  tooltipPosition?: TooltipPosition
} & AnchorHTMLAttributes<HTMLAnchorElement>

const Link = (props: LinkProps) => {
  const {
    children,
    className,
    href,
    icon,
    onClick,
    type,
    isLoading,
    isSmall,
    disabled,
    target,
    tooltip,
    tooltipPosition,
  } = props

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (disabled) {
      event.preventDefault()
    }

    if (onClick) {
      onClick(event)
    }
  }

  const classNames = `link ${type || ''} ${isLoading ? 'is-loading' : ''} ${isSmall ? 'text-xs' : ''} ${disabled ? 'is-disabled cursor-not-allowed opacity-50' : ''} ${className || ''}`

  return (
    <a
      aria-disabled={disabled}
      className={classNames}
      data-tooltip={tooltip}
      data-tooltip-position={tooltip ? tooltipPosition || 'bottom center' : undefined}
      href={href}
      target={target}
      onClick={handleClick}
    >
      {isLoading && (
        <div className="inline">
          <Loader />
        </div>
      )}
      {children}
      {icon}
    </a>
  )
}

export default Link
