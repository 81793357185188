import Papa from 'papaparse'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { parseImagesUrls } from '@/components/ImportProducts/ProductsBulkImportForm2/helpers'
import {
  acceptableColorApricotValues,
  acceptableColorBeigeValues,
  acceptableColorBlackValues,
  acceptableColorBlueValues,
  acceptableColorBrownValues,
  acceptableColorBurgundyValues,
  acceptableColorCoralValues,
  acceptableColorCreamValues,
  acceptableColorDarkGreenValues,
  acceptableColorGoldValues,
  acceptableColorGrayValues,
  acceptableColorGreenValues,
  acceptableColorKhakiValues,
  acceptableColorLightBlueValues,
  acceptableColorLilacValues,
  acceptableColorMintValues,
  acceptableColorMultiValues,
  acceptableColorMustardValues,
  acceptableColorNavyValues,
  acceptableColorOrangeValues,
  acceptableColorPinkValues,
  acceptableColorPurpleValues,
  acceptableColorRedValues,
  acceptableColorSilverValues,
  acceptableColorTurquoiseValues,
  acceptableColorWhiteValues,
  acceptableColorYellowValues,
} from '@/data/fashionProductColors'
import {
  acceptableConditionGoodValues,
  acceptableConditionNewWithoutTagsValues,
  acceptableConditionNewWithTagsValues,
  acceptableConditionSatisfactoryValues,
  acceptableConditionVeryGoodValues,
} from '@/data/fashionProductConditions'
import {
  acceptableGenderMenValues,
  acceptableGenderUnisexValues,
  acceptableGenderWomenValues,
} from '@/data/fashionProductGenders'
import { trackError } from '@/util/sentry'

import type { BulkProductInput } from '@/components/ImportProducts/ProductsBulkImportForm2/types'
import type { TFunction } from 'i18next'

type ProductImportParsingResult = {
  products: BulkProductInput[]
  errors: Error[]
}

const sanitizeColor = (value: string, t: TFunction): string | null => {
  const lowerCasedValue = value.toLowerCase()

  if (acceptableColorBlackValues.includes(lowerCasedValue)) {
    return t('colorBlack')
  } else if (acceptableColorGrayValues.includes(lowerCasedValue)) {
    return t('colorGray')
  } else if (acceptableColorWhiteValues.includes(lowerCasedValue)) {
    return t('colorWhite')
  } else if (acceptableColorCreamValues.includes(lowerCasedValue)) {
    return t('colorCream')
  } else if (acceptableColorBeigeValues.includes(lowerCasedValue)) {
    return t('colorBeige')
  } else if (acceptableColorApricotValues.includes(lowerCasedValue)) {
    return t('colorApricot')
  } else if (acceptableColorOrangeValues.includes(lowerCasedValue)) {
    return t('colorOrange')
  } else if (acceptableColorCoralValues.includes(lowerCasedValue)) {
    return t('colorCoral')
  } else if (acceptableColorRedValues.includes(lowerCasedValue)) {
    return t('colorRed')
  } else if (acceptableColorBurgundyValues.includes(lowerCasedValue)) {
    return t('colorBurgundy')
  } else if (acceptableColorPinkValues.includes(lowerCasedValue)) {
    return t('colorPink')
  } else if (acceptableColorPurpleValues.includes(lowerCasedValue)) {
    return t('colorPurple')
  } else if (acceptableColorLilacValues.includes(lowerCasedValue)) {
    return t('colorLilac')
  } else if (acceptableColorLightBlueValues.includes(lowerCasedValue)) {
    return t('colorLightBlue')
  } else if (acceptableColorBlueValues.includes(lowerCasedValue)) {
    return t('colorBlue')
  } else if (acceptableColorNavyValues.includes(lowerCasedValue)) {
    return t('colorNavy')
  } else if (acceptableColorTurquoiseValues.includes(lowerCasedValue)) {
    return t('colorTurquoise')
  } else if (acceptableColorMintValues.includes(lowerCasedValue)) {
    return t('colorMint')
  } else if (acceptableColorGreenValues.includes(lowerCasedValue)) {
    return t('colorGreen')
  } else if (acceptableColorDarkGreenValues.includes(lowerCasedValue)) {
    return t('colorDarkGreen')
  } else if (acceptableColorKhakiValues.includes(lowerCasedValue)) {
    return t('colorKhaki')
  } else if (acceptableColorBrownValues.includes(lowerCasedValue)) {
    return t('colorBrown')
  } else if (acceptableColorMustardValues.includes(lowerCasedValue)) {
    return t('colorMustard')
  } else if (acceptableColorYellowValues.includes(lowerCasedValue)) {
    return t('colorYellow')
  } else if (acceptableColorSilverValues.includes(lowerCasedValue)) {
    return t('colorSilver')
  } else if (acceptableColorGoldValues.includes(lowerCasedValue)) {
    return t('colorGold')
  } else if (acceptableColorMultiValues.includes(lowerCasedValue)) {
    return t('colorMulti')
  }

  return null
}

const sanitizeGender = (value: string, t: TFunction): string | null => {
  const lowerCasedValue = value.toLowerCase()

  if (acceptableGenderMenValues.includes(lowerCasedValue)) {
    return t('genderMen')
  } else if (acceptableGenderWomenValues.includes(lowerCasedValue)) {
    return t('genderWomen')
  } else if (acceptableGenderUnisexValues.includes(lowerCasedValue)) {
    return t('genderUnisex')
  }

  return null
}

const sanitizeCondition = (value: string, t: TFunction): string | null => {
  const lowerCasedValue = value.toLowerCase().replace(/-/g, ' ')

  if (acceptableConditionNewWithTagsValues.includes(lowerCasedValue)) {
    return t('showProductsDrawerFormConditionNewWithTags')
  } else if (acceptableConditionNewWithoutTagsValues.includes(lowerCasedValue)) {
    return t('showProductsDrawerFormConditionNewWithoutTags')
  } else if (acceptableConditionVeryGoodValues.includes(lowerCasedValue)) {
    return t('showProductsDrawerFormConditionVeryGood')
  } else if (acceptableConditionGoodValues.includes(lowerCasedValue)) {
    return t('showProductsDrawerFormConditionGood')
  } else if (acceptableConditionSatisfactoryValues.includes(lowerCasedValue)) {
    return t('showProductsDrawerFormConditionSatisfactory')
  }

  return null
}

const cleanHeader = (header: string) => {
  return header.trim().replace(/^"/, '').replace(/"$/, '')
}

const useParseBulkProductsImport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const parseBulkProductImportCsvFile = useCallback(async (file: File) => {
    setIsLoading(true)

    const result: ProductImportParsingResult = await new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        comments: '#',
        skipEmptyLines: 'greedy',
        // !!! Warning !!!
        // Don't use worker as it seems to have a bug when using transformHeader
        // DOMException: Failed to execute 'postMessage' on 'Worker': transformHeader(header)
        // https://github.com/mholt/PapaParse/issues/761
        worker: false,
        transformHeader: (header: string) => {
          let usedHeader = cleanHeader(header)
          const lowerCasedHeader = usedHeader.toLowerCase()

          if (['product name' || 'name of the product', 'nom du produit'].includes(lowerCasedHeader)) {
            usedHeader = 'name'
          }

          if (['description'].includes(lowerCasedHeader)) {
            usedHeader = 'description'
          }

          if (['stock'].includes(lowerCasedHeader)) {
            usedHeader = 'quantity'
          }

          if (['starting price', 'prix de départ'].includes(lowerCasedHeader)) {
            usedHeader = 'startingPrice'
          }

          if (['instant buy price', "prix d'achat instantané", 'price', 'prix'].includes(lowerCasedHeader)) {
            usedHeader = 'instantBuyPrice'
          }

          if (lowerCasedHeader.includes('images')) {
            usedHeader = 'imagesUrls'
          }

          return usedHeader
        },
        transform: (value: string, header) => {
          if (header === 'imagesUrls') {
            return parseImagesUrls(value).join('|')
          } else {
            const sanitizedValue = value.trim().replace(/^"/, '').replace(/"$/, '')

            if (header === 'gender') {
              const sanitizedGender = sanitizeGender(sanitizedValue, t)
              return sanitizedGender || sanitizedValue
            }
            if (header === 'condition') {
              const sanitizedCondition = sanitizeCondition(sanitizedValue, t)
              return sanitizedCondition || sanitizedValue
            }
            if (header === 'color') {
              const sanitizedColor = sanitizeColor(sanitizedValue, t)
              return sanitizedColor || sanitizedValue
            }

            return sanitizedValue
          }
        },
        complete: async (results) => {
          const { data: products = [], errors } = results || {}

          resolve({ products: products as BulkProductInput[], errors: errors as unknown as Error[] })
        },
        error: (error) => {
          trackError(error, { meta: { scope: 'useParseBulkProductsImport' } })
          reject(error)
        },
      })
    })

    setIsLoading(false)

    return result
  }, [])

  return {
    isLoading,
    parseCSV: parseBulkProductImportCsvFile,
  }
}

export default useParseBulkProductsImport
