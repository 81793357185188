import { Input, Form, DatePicker, Select } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import InputLabel from '../../../../../components/InputLabel'

import type { Moment } from 'moment'

const BillingInfoForm = (props: any) => {
  const {
    firstname,
    lastname,
    birthdate,
    address,
    zipcode,
    city,
    state,
    country,
    setFirstname,
    setLastname,
    setBirthdate,
    setAddress,
    setZipcode,
    setCity,
    setState,
    setCountry,
  } = props

  const { t } = useTranslation()

  const isDateDisable = (e: Moment) => {
    return moment(e).isAfter(moment().subtract(18, 'year')) || parseInt(moment(e).format('YYYY')) < 1901
  }

  const { Option } = Select

  useEffect(() => {
    if (window) window.scrollTo(0, 0)
  }, [])

  return (
    <Form className="billing-info-form" initialValues={{ remember: true }} name="basic">
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelFirstname')} mandatory />
        <Input value={firstname} disabled onChange={({ target }) => setFirstname(target.value)} />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelLastname')} mandatory />
        <Input value={lastname} disabled onChange={({ target }) => setLastname(target.value)} />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel
          noticePopover={{ content: t('signupFormHelpNoticeMustBeOver18') }}
          title={t('signupFormLabelBirthdate')}
          mandatory
        />
        <DatePicker
          allowClear={false}
          defaultPickerValue={birthdate || moment().subtract(18, 'year')}
          disabledDate={isDateDisable}
          format="DD/MM/YYYY"
          style={{ width: '100%' }}
          value={birthdate}
          disabled
          onChange={(date) => setBirthdate(date)}
        />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelAddress')} mandatory />
        <Input value={address} disabled onChange={({ target }) => setAddress(target.value)} />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelZipcode')} mandatory />
        <Input value={zipcode} disabled onChange={({ target }) => setZipcode(target.value)} />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelCity')} mandatory />
        <Input value={city} disabled onChange={({ target }) => setCity(target.value)} />
      </Form.Item>

      <Form.Item>
        <InputLabel title={t('signupFormLabelRegionState')} />
        <Input value={state} disabled onChange={({ target }) => setState(target.value)} />
      </Form.Item>

      <Form.Item>
        <InputLabel title={t('signupFormLabelCountry')} mandatory />
        <Select defaultValue={country} style={{ width: '100%' }} value={country} disabled onChange={setCountry}>
          <Option value="AT">{t('commonAustria')}</Option>
          <Option value="FR">{t('commonFrance')}</Option>
          <Option value="BE">{t('commonBelgium')}</Option>
          <Option value="GB">{t('commonGreatBritain')}</Option>
          <Option value="DE">{t('commonGermany')}</Option>
          <Option value="LU">{t('commonLuxembourg')}</Option>
          <Option value="NL">{t('commonNetherlands')}</Option>
          <Option value="ES">{t('commonSpain')}</Option>
        </Select>
      </Form.Item>
    </Form>
  )
}

export default BillingInfoForm
