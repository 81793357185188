import { useMemo, useState } from 'react'

import './TabBar.scss'

export interface TabBarProps {
  tabs: {
    key: string
    title: string | React.ReactNode
    content: React.ReactNode
    disabled?: boolean
  }[]
  className?: string
  onChange?: (tab: string) => void
  initial?: string
  extraContent?: React.ReactNode
}

export const TabBar = (props: TabBarProps) => {
  const { tabs, className, initial, onChange, extraContent } = props

  const [activeTab, setActiveTab] = useState<string>(initial || tabs[0].key)

  const activeTabContent = useMemo(() => tabs.find(({ key }) => key === activeTab)?.content, [activeTab, tabs])
  const handleTabClick = (key: string) => {
    setActiveTab(key)
    onChange && onChange(key)
  }

  if (!tabs.length) return null

  const classNamesTabBar = `tab-bar ${className || ''}`
  const classNamesTabBarContent = `tab-bar-content ${className || ''}`

  return (
    <>
      <div className={classNamesTabBar}>
        {tabs.map(
          ({ key, title, disabled }) =>
            !disabled && (
              <button
                key={key}
                className={`tab-bar-item ${activeTab === key && 'active'}`}
                onClick={() => handleTabClick(key)}
              >
                {title}
              </button>
            )
        )}
        {extraContent}
      </div>

      <div className={classNamesTabBarContent}>{activeTabContent}</div>
    </>
  )
}
