import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowsRotate } from 'react-icons/fa6'

import Field from '@/components/Form/Field/Field'
import Button from '@/components/ui/Button/Button'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useRaid } from '@/views/Show/components/CurrentRaid/hooks/use-raid'
import { useGetSimilarShowsToRaidQuery } from '@/views/Show/components/CurrentRaid/operations.generated'

import type { GetSimilarShowsToRaidQuery } from '@/views/Show/components/CurrentRaid/operations.generated'

import './Raid.scss'

type StopShowProps = {
  showId: number
  onComplete: () => void
}
type SimilarShowOption = { label: string; value: string }

const extractSelectShowsToRaidOptions = (similarShowsToRaidData: GetSimilarShowsToRaidQuery) => {
  if (!similarShowsToRaidData?.similarShowsToRaid) return []

  return similarShowsToRaidData?.similarShowsToRaid.edges.map((item) => {
    return { label: item.node?.seller?.username, value: item.node.id } as SimilarShowOption
  })
}
const StopShow = (props: StopShowProps) => {
  const { showId, onComplete } = props
  const showGlobalId = `Show|${showId}`
  const [showDestinationGlobalId, setShowDestinationGlobalId] = useState<string | undefined>(undefined)
  const [similarShowsToRaidOptions, setSimilarShowsToRaidOptions] = useState<SimilarShowOption[]>([])
  const { t } = useTranslation()

  const { data } = useGetSimilarShowsToRaidQuery({
    variables: {
      showId: showGlobalId,
      all: true,
    },
    onError: (error) => {
      trackError(error, {
        meta: {
          showId: showGlobalId,
          destinationShowId: showDestinationGlobalId,
          scope: 'Raid.useGetSimilarShowsToRaidQuery',
        },
      })
    },
  })

  useEffect(() => {
    if (data) {
      setSimilarShowsToRaidOptions(extractSelectShowsToRaidOptions(data))
    }
  }, [data])

  const { openRaid, openRaidLoading } = useRaid({
    showGlobalId,
  })

  const handleSendRaidClick = async () => {
    if (!showDestinationGlobalId) {
      return
    }
    trackEvent('SHOW_RAID_OPENED', { show: showId })
    openRaid(showDestinationGlobalId)
    onComplete()
  }

  const handleSelectRandomShowToRaid = () => {
    if (!similarShowsToRaidOptions.length) {
      return
    }
    const randomIndex = Math.floor(Math.random() * similarShowsToRaidOptions.length)
    const randomShow = similarShowsToRaidOptions[randomIndex]
    setShowDestinationGlobalId(randomShow.value)
  }

  return (
    <div className="open-raid-from-chat">
      <div className="selector">
        {!similarShowsToRaidOptions.length ? (
          <span>{t('showStopSendRaidNoSimilarShows')}</span>
        ) : (
          <>
            <Field
              name="raid"
              options={[{ label: '', value: '' }, ...similarShowsToRaidOptions]}
              placeholder={t('showStopSendRaidPlaceholder')}
              type="select"
              value={showDestinationGlobalId}
              onChange={(e: any) => setShowDestinationGlobalId(e.target.value)}
            />
            <Button
              className="randomly-select-show-action"
              icon={<FaArrowsRotate />}
              tooltip={t('showStopSendRaidRandomTooltip')}
              tooltipPosition="bottom right"
              onClick={handleSelectRandomShowToRaid}
            />
          </>
        )}
      </div>

      <div className="buttons">
        <Button
          className="validate-action primary"
          disabled={!showDestinationGlobalId || openRaidLoading}
          label={t('showStopSendRaidOpen')}
          onClick={handleSendRaidClick}
        />
      </div>
    </div>
  )
}

export default memo(StopShow)
