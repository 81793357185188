import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerTierQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerTierQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, sellerConfig?: { __typename?: 'SellerConfig', sellerTier?: { __typename?: 'SellerTier', id: string, name: string, canCreateShow: boolean, showStartMaxTime?: number | null } | null } | null } | null };


export const GetSellerTierDocument = gql`
    query GetSellerTier {
  viewer {
    id
    sellerConfig {
      sellerTier {
        id
        name
        canCreateShow
        showStartMaxTime
      }
    }
  }
}
    `;

/**
 * __useGetSellerTierQuery__
 *
 * To run a query within a React component, call `useGetSellerTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerTierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerTierQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerTierQuery, GetSellerTierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerTierQuery, GetSellerTierQueryVariables>(GetSellerTierDocument, options);
      }
export function useGetSellerTierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerTierQuery, GetSellerTierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerTierQuery, GetSellerTierQueryVariables>(GetSellerTierDocument, options);
        }
export function useGetSellerTierSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerTierQuery, GetSellerTierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerTierQuery, GetSellerTierQueryVariables>(GetSellerTierDocument, options);
        }
export type GetSellerTierQueryHookResult = ReturnType<typeof useGetSellerTierQuery>;
export type GetSellerTierLazyQueryHookResult = ReturnType<typeof useGetSellerTierLazyQuery>;
export type GetSellerTierSuspenseQueryHookResult = ReturnType<typeof useGetSellerTierSuspenseQuery>;
export type GetSellerTierQueryResult = Apollo.QueryResult<GetSellerTierQuery, GetSellerTierQueryVariables>;