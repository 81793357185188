export enum TabName {
  auction = 'auction',
  instantBuy = 'instantBuy',
  giveaway = 'giveaway',
  sold = 'sold',
}

export enum ActionsProduct {
  edit = 'edit',
  duplicate = 'duplicate',
}
