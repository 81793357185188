import { MenuOutlined } from '@ant-design/icons'
import { useCallback, type ReactNode, type MouseEvent } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

import './ViewHeader.scss'

type ViewHeaderProps = {
  leftContent?: ReactNode | string | number
  rightContent?: ReactNode | string | number
}

const ViewHeader = (props: ViewHeaderProps) => {
  const { leftContent, rightContent } = props
  const { t } = useTranslation()

  const handleToggleMainNav = useCallback((event: MouseEvent) => {
    event.preventDefault()
    document.getElementById('header')?.classList.toggle('is-open')
  }, [])

  if (!leftContent && !rightContent) {
    return null
  }

  return (
    <header className={`view-header ${isMobileOnly ? 'is-mobile' : ''}`}>
      <div className="view-header-left">
        <Button
          className="toggle-nav-action primary"
          href="#header"
          icon={<MenuOutlined />}
          label={t('commonMainMenuActionLabel')}
          onClick={handleToggleMainNav}
        />
        {leftContent}
      </div>
      <div className="view-header-right">{rightContent}</div>
    </header>
  )
}

export default ViewHeader
