import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersBannedFromShowQueryVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type GetUsersBannedFromShowQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign', id: string } | { __typename?: 'AutocompleteSearchItem', id: string } | { __typename?: 'Badge', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'CommercialEvent', id: string } | { __typename?: 'Country', id: string } | { __typename?: 'Emote', id: string } | { __typename?: 'FavoriteProduct', id: string } | { __typename?: 'FavoriteShow', id: string } | { __typename?: 'ImageUpload', id: string } | { __typename?: 'InventoryProduct', id: string } | { __typename?: 'Language', id: string } | { __typename?: 'LoyaltyLevel', id: string } | { __typename?: 'Order', id: string } | { __typename?: 'OrderedProduct', id: string } | { __typename?: 'ParentCategory', id: string } | { __typename?: 'Product', id: string } | { __typename?: 'ProductForOrdering', id: string } | { __typename?: 'Promotion', id: string } | { __typename?: 'PromotionV2', id: string } | { __typename?: 'RatingFromCustomer', id: string } | { __typename?: 'SellerLedgerPayout', id: string } | { __typename?: 'SellerLedgerTransaction', id: string } | { __typename?: 'SellerShowsAggregatedByMonth', id: string } | { __typename?: 'SellerTier', id: string } | { __typename?: 'Shipment', id: string } | { __typename?: 'Shop', id: string } | { __typename?: 'Show', id: string, bannedUsersFromShow: Array<{ __typename?: 'User', id: string, username: string, avatarWebPUrl?: string | null }> } | { __typename?: 'ShowOrdersGroup', id: string } | { __typename?: 'ShowProductNode', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserSellerLoyaltyProgress', id: string } };

export type GetUsersBannedFromSellerQueryVariables = Types.Exact<{
  sellerId: Types.Scalars['ID']['input'];
}>;


export type GetUsersBannedFromSellerQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign', id: string } | { __typename?: 'AutocompleteSearchItem', id: string } | { __typename?: 'Badge', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'CommercialEvent', id: string } | { __typename?: 'Country', id: string } | { __typename?: 'Emote', id: string } | { __typename?: 'FavoriteProduct', id: string } | { __typename?: 'FavoriteShow', id: string } | { __typename?: 'ImageUpload', id: string } | { __typename?: 'InventoryProduct', id: string } | { __typename?: 'Language', id: string } | { __typename?: 'LoyaltyLevel', id: string } | { __typename?: 'Order', id: string } | { __typename?: 'OrderedProduct', id: string } | { __typename?: 'ParentCategory', id: string } | { __typename?: 'Product', id: string } | { __typename?: 'ProductForOrdering', id: string } | { __typename?: 'Promotion', id: string } | { __typename?: 'PromotionV2', id: string } | { __typename?: 'RatingFromCustomer', id: string } | { __typename?: 'SellerLedgerPayout', id: string } | { __typename?: 'SellerLedgerTransaction', id: string } | { __typename?: 'SellerShowsAggregatedByMonth', id: string } | { __typename?: 'SellerTier', id: string } | { __typename?: 'Shipment', id: string } | { __typename?: 'Shop', id: string } | { __typename?: 'Show', id: string } | { __typename?: 'ShowOrdersGroup', id: string } | { __typename?: 'ShowProductNode', id: string } | { __typename?: 'User', id: string, bannedUsersFromSeller: Array<{ __typename?: 'User', id: string, username: string, avatarWebPUrl?: string | null }> } | { __typename?: 'UserSellerLoyaltyProgress', id: string } };

export type BanUserFromShowMutationVariables = Types.Exact<{
  input: Types.BanUserFromShowInput;
}>;


export type BanUserFromShowMutation = { __typename?: 'Mutation', banUserFromShow: { __typename?: 'BanUserFromShowPayload', ok: boolean } };

export type BanUserFromSellerMutationVariables = Types.Exact<{
  input: Types.BanUserFromSellerInput;
}>;


export type BanUserFromSellerMutation = { __typename?: 'Mutation', banUserFromSeller: { __typename?: 'BanUserFromSellerPayload', ok: boolean } };

export type UnbanUserFromSellerMutationVariables = Types.Exact<{
  input: Types.UnbanUserFromSellerInput;
}>;


export type UnbanUserFromSellerMutation = { __typename?: 'Mutation', unbanUserFromSeller: { __typename?: 'UnbanUserFromSellerPayload', ok: boolean } };

export type UnbanUserFromShowMutationVariables = Types.Exact<{
  input: Types.UnbanUserFromShowInput;
}>;


export type UnbanUserFromShowMutation = { __typename?: 'Mutation', unbanUserFromShow: { __typename?: 'UnbanUserFromShowPayload', ok: boolean } };


export const GetUsersBannedFromShowDocument = gql`
    query GetUsersBannedFromShow($showId: ID!) {
  node(id: $showId) {
    id
    ... on Show {
      bannedUsersFromShow {
        id
        username
        avatarWebPUrl
      }
    }
  }
}
    `;

/**
 * __useGetUsersBannedFromShowQuery__
 *
 * To run a query within a React component, call `useGetUsersBannedFromShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersBannedFromShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersBannedFromShowQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useGetUsersBannedFromShowQuery(baseOptions: Apollo.QueryHookOptions<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables> & ({ variables: GetUsersBannedFromShowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>(GetUsersBannedFromShowDocument, options);
      }
export function useGetUsersBannedFromShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>(GetUsersBannedFromShowDocument, options);
        }
export function useGetUsersBannedFromShowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>(GetUsersBannedFromShowDocument, options);
        }
export type GetUsersBannedFromShowQueryHookResult = ReturnType<typeof useGetUsersBannedFromShowQuery>;
export type GetUsersBannedFromShowLazyQueryHookResult = ReturnType<typeof useGetUsersBannedFromShowLazyQuery>;
export type GetUsersBannedFromShowSuspenseQueryHookResult = ReturnType<typeof useGetUsersBannedFromShowSuspenseQuery>;
export type GetUsersBannedFromShowQueryResult = Apollo.QueryResult<GetUsersBannedFromShowQuery, GetUsersBannedFromShowQueryVariables>;
export const GetUsersBannedFromSellerDocument = gql`
    query GetUsersBannedFromSeller($sellerId: ID!) {
  node(id: $sellerId) {
    id
    ... on User {
      bannedUsersFromSeller {
        id
        username
        avatarWebPUrl
      }
    }
  }
}
    `;

/**
 * __useGetUsersBannedFromSellerQuery__
 *
 * To run a query within a React component, call `useGetUsersBannedFromSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersBannedFromSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersBannedFromSellerQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetUsersBannedFromSellerQuery(baseOptions: Apollo.QueryHookOptions<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables> & ({ variables: GetUsersBannedFromSellerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>(GetUsersBannedFromSellerDocument, options);
      }
export function useGetUsersBannedFromSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>(GetUsersBannedFromSellerDocument, options);
        }
export function useGetUsersBannedFromSellerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>(GetUsersBannedFromSellerDocument, options);
        }
export type GetUsersBannedFromSellerQueryHookResult = ReturnType<typeof useGetUsersBannedFromSellerQuery>;
export type GetUsersBannedFromSellerLazyQueryHookResult = ReturnType<typeof useGetUsersBannedFromSellerLazyQuery>;
export type GetUsersBannedFromSellerSuspenseQueryHookResult = ReturnType<typeof useGetUsersBannedFromSellerSuspenseQuery>;
export type GetUsersBannedFromSellerQueryResult = Apollo.QueryResult<GetUsersBannedFromSellerQuery, GetUsersBannedFromSellerQueryVariables>;
export const BanUserFromShowDocument = gql`
    mutation BanUserFromShow($input: BanUserFromShowInput!) {
  banUserFromShow(input: $input) {
    ok
  }
}
    `;
export type BanUserFromShowMutationFn = Apollo.MutationFunction<BanUserFromShowMutation, BanUserFromShowMutationVariables>;

/**
 * __useBanUserFromShowMutation__
 *
 * To run a mutation, you first call `useBanUserFromShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserFromShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserFromShowMutation, { data, loading, error }] = useBanUserFromShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanUserFromShowMutation(baseOptions?: Apollo.MutationHookOptions<BanUserFromShowMutation, BanUserFromShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanUserFromShowMutation, BanUserFromShowMutationVariables>(BanUserFromShowDocument, options);
      }
export type BanUserFromShowMutationHookResult = ReturnType<typeof useBanUserFromShowMutation>;
export type BanUserFromShowMutationResult = Apollo.MutationResult<BanUserFromShowMutation>;
export type BanUserFromShowMutationOptions = Apollo.BaseMutationOptions<BanUserFromShowMutation, BanUserFromShowMutationVariables>;
export const BanUserFromSellerDocument = gql`
    mutation BanUserFromSeller($input: BanUserFromSellerInput!) {
  banUserFromSeller(input: $input) {
    ok
  }
}
    `;
export type BanUserFromSellerMutationFn = Apollo.MutationFunction<BanUserFromSellerMutation, BanUserFromSellerMutationVariables>;

/**
 * __useBanUserFromSellerMutation__
 *
 * To run a mutation, you first call `useBanUserFromSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserFromSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserFromSellerMutation, { data, loading, error }] = useBanUserFromSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanUserFromSellerMutation(baseOptions?: Apollo.MutationHookOptions<BanUserFromSellerMutation, BanUserFromSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanUserFromSellerMutation, BanUserFromSellerMutationVariables>(BanUserFromSellerDocument, options);
      }
export type BanUserFromSellerMutationHookResult = ReturnType<typeof useBanUserFromSellerMutation>;
export type BanUserFromSellerMutationResult = Apollo.MutationResult<BanUserFromSellerMutation>;
export type BanUserFromSellerMutationOptions = Apollo.BaseMutationOptions<BanUserFromSellerMutation, BanUserFromSellerMutationVariables>;
export const UnbanUserFromSellerDocument = gql`
    mutation UnbanUserFromSeller($input: UnbanUserFromSellerInput!) {
  unbanUserFromSeller(input: $input) {
    ok
  }
}
    `;
export type UnbanUserFromSellerMutationFn = Apollo.MutationFunction<UnbanUserFromSellerMutation, UnbanUserFromSellerMutationVariables>;

/**
 * __useUnbanUserFromSellerMutation__
 *
 * To run a mutation, you first call `useUnbanUserFromSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbanUserFromSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbanUserFromSellerMutation, { data, loading, error }] = useUnbanUserFromSellerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnbanUserFromSellerMutation(baseOptions?: Apollo.MutationHookOptions<UnbanUserFromSellerMutation, UnbanUserFromSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnbanUserFromSellerMutation, UnbanUserFromSellerMutationVariables>(UnbanUserFromSellerDocument, options);
      }
export type UnbanUserFromSellerMutationHookResult = ReturnType<typeof useUnbanUserFromSellerMutation>;
export type UnbanUserFromSellerMutationResult = Apollo.MutationResult<UnbanUserFromSellerMutation>;
export type UnbanUserFromSellerMutationOptions = Apollo.BaseMutationOptions<UnbanUserFromSellerMutation, UnbanUserFromSellerMutationVariables>;
export const UnbanUserFromShowDocument = gql`
    mutation UnbanUserFromShow($input: UnbanUserFromShowInput!) {
  unbanUserFromShow(input: $input) {
    ok
  }
}
    `;
export type UnbanUserFromShowMutationFn = Apollo.MutationFunction<UnbanUserFromShowMutation, UnbanUserFromShowMutationVariables>;

/**
 * __useUnbanUserFromShowMutation__
 *
 * To run a mutation, you first call `useUnbanUserFromShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbanUserFromShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbanUserFromShowMutation, { data, loading, error }] = useUnbanUserFromShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnbanUserFromShowMutation(baseOptions?: Apollo.MutationHookOptions<UnbanUserFromShowMutation, UnbanUserFromShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnbanUserFromShowMutation, UnbanUserFromShowMutationVariables>(UnbanUserFromShowDocument, options);
      }
export type UnbanUserFromShowMutationHookResult = ReturnType<typeof useUnbanUserFromShowMutation>;
export type UnbanUserFromShowMutationResult = Apollo.MutationResult<UnbanUserFromShowMutation>;
export type UnbanUserFromShowMutationOptions = Apollo.BaseMutationOptions<UnbanUserFromShowMutation, UnbanUserFromShowMutationVariables>;