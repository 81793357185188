import type { ReactNode } from 'react'

export interface BreakerProps {
  type: 'primary' | 'secondary'
  title?: string
  subtitle?: string | ReactNode
  imageUrl?: string
  cta?: ReactNode
  className?: string
}

export const Breaker = (props: BreakerProps) => {
  const { title, subtitle, imageUrl, cta, type, className } = props

  const classNames = `${className || ''} flex flex-row gap-4 items-center justify-between p-4 rounded-md ${type === 'primary' ? 'bg-black text-white' : 'bg-slate-200 text-black'} ${type === 'secondary' ? 'bg-slate-200 text-black' : ''}`

  return (
    <div className={classNames}>
      <div className="flex flex-row gap-2 items-center">
        {imageUrl && <img alt="" className="grow-0" src={imageUrl} />}
        <div className="px-4">
          {title && <h1 className={`mb-0 font-bold ${type === 'primary' ? 'text-white' : ''}`}>{title}</h1>}
          {subtitle && <p className="text-sm m-0">{subtitle}</p>}
        </div>
      </div>
      {cta}
    </div>
  )
}
