import { useCallback, useEffect, useState } from 'react'

import type { ReactNode } from 'react'

export interface CollapsibleProps {
  title: string
  subtitle?: string
  children: ReactNode
  headerContent?: ReactNode
  opened?: boolean
  onCollapse?: (isCollapsed: boolean) => void
}

export default function Collapsible({
  children,
  title,
  subtitle,
  headerContent,
  opened = false,
  onCollapse,
}: CollapsibleProps) {
  const [isCollapsed, setIsCollapsed] = useState(!opened)
  const hasHeaderContent = !!headerContent

  const handleCollapse = useCallback(() => {
    const value = !isCollapsed
    setIsCollapsed(value)
    onCollapse && onCollapse(value)
  }, [isCollapsed])

  useEffect(() => {
    setIsCollapsed(!opened)
  }, [opened])

  return (
    <div className="bg-white w-full border-b border-slate-200 last:border-b-0">
      <div className="grid grid-cols-12 cursor-pointer select-none" onClick={handleCollapse}>
        <div className={`flex-1 p-4 ${hasHeaderContent ? 'col-span-3' : 'col-span-11'}`}>
          <h1 className="mb-0 text-base">{title}</h1>
          {subtitle && <p className="text-slate-400 text-sm m-0">{subtitle}</p>}
        </div>

        {headerContent && <div className="flex-1 col-span-8">{headerContent}</div>}

        <div className="flex items-center p-4 col-span-1 col-start-12 justify-end">
          <svg
            className={`h-4 w-4 transform transition-transform duration-100 ${isCollapsed ? 'rotate-0' : 'rotate-180'}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M19 9l-7 7-7-7" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
          </svg>
        </div>
      </div>

      <div className={`${isCollapsed ? 'h-0 overflow-hidden' : 'h-auto'}`}>{isCollapsed ? null : children}</div>
    </div>
  )
}
