import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackError } from '@/util/sentry'
import {
  useGeneratePackingSlipForOrderedProductsMutation,
  useGeneratePackingSlipForShipmentsMutation,
} from '@/views/Shipments/Details/components/CreatePackingSlipButton/operations.generated'

import type { TooltipPosition } from '@/components/ui/Button/Button'
// import type { PackingInfo } from '@/views/Shipments/types'

type BulkDownloadPackingSlipsProps = {
  // !!!
  // The component expects either packings or shipmentIds to be provided.
  // If shipmentIds are provided, the component will need to fetch the related shipment info before actually building the packing slips.
  // !!!
  // packings?: PackingInfo[]
  shipmentIds?: string[]
  orderedProductIds?: string[]
  customerId?: string
  onDownloaded?: (shipmentIds: string[]) => void
  buttonLabel?: string
  tooltip?: string
  tooltipPosition?: TooltipPosition
}

export const BulkDownloadPackingSlips = (props: BulkDownloadPackingSlipsProps) => {
  const {
    // packings = [],
    shipmentIds,
    buttonLabel,
    tooltip,
    tooltipPosition,
    orderedProductIds,
    customerId,
  } = props
  const hasSelectedPackages = !!orderedProductIds?.length || !!shipmentIds?.length
  const selectedItemsLength = orderedProductIds?.length || shipmentIds?.length

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const isDisabled = isLoading || !hasSelectedPackages

  const [generatePackingSlipForShipments] = useGeneratePackingSlipForShipmentsMutation()
  const [generatePackingSlipForOrderedProducts] = useGeneratePackingSlipForOrderedProductsMutation()

  const handleBulkDownloadPackingSlips = async () => {
    if (!hasSelectedPackages) {
      return
    }

    setIsLoading(true)

    if (shipmentIds) {
      await generatePackingSlipForShipments({
        variables: { input: { shipmentIds } },
        onCompleted: async (data) => {
          window.open(data.generatePackingSlipForShipments.url, '_blank')
        },
        onError: (err) => {
          trackError(err, {
            meta: {
              feature: 'shipments.components.CreatePackingSlipButton',
              shipmentIds,
            },
          })
          notificationDanger(err.message)
        },
      })
      setIsLoading(false)
    } else if (orderedProductIds && customerId) {
      await generatePackingSlipForOrderedProducts({
        variables: { input: { customerId, orderedProductIds } },
        onCompleted: async (data) => {
          window.open(data.generatePackingSlipForUnshippedOrderedProducts.url, '_blank')
        },
        onError: (err) => {
          trackError(err, {
            meta: {
              feature: 'shipments.components.CreatePackingSlipButton',
              shipmentIds,
            },
          })
          notificationDanger(err.message)
        },
      })

      setIsLoading(false)
    }
  }

  return (
    <Button
      className="action download-action download-action download-packing-slips-action secondary"
      disabled={isDisabled}
      isLoading={isLoading}
      label={buttonLabel || t('bulkDownloadPackingSlipButtonLabel', { count: selectedItemsLength })}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={handleBulkDownloadPackingSlips}
    />
  )
}
