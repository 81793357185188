import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEye, FaRegCopy } from 'react-icons/fa6'

import { notificationDanger, notificationSuccess } from '../ui/Notification/Notification'

type CopyableTextProps = {
  value: string
  messageSuccess?: string
  label?: string
  title?: string
  secret?: boolean
  content?: string
}

const CopyableText = (props: CopyableTextProps) => {
  const { value, messageSuccess, label, secret, title, content } = props
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useState(secret)

  const handleCopy = async () => {
    if (!navigator.clipboard) {
      notificationDanger(t('copyableTextMessageError'))
      return
    }
    try {
      value && (await navigator.clipboard.writeText(value))
      notificationSuccess(messageSuccess ?? t('copyableTextMessageSuccess'))
    } catch (err) {
      notificationDanger(t('copyableTextMessageError'))
    }
  }

  if (!value) return null

  const classNames = `${isHidden ? 'text-transparent' : ''} ${secret ? 'mr-2' : ''}`

  return (
    <div className="flex items-center cursor-pointer gap-2 text-slate-600" onClick={handleCopy}>
      <p className="whitespace-nowrap overflow-hidden overflow-ellipsis truncate mb-0">
        {label && <strong className="mr-2">{label}:</strong>}
        <span className={classNames} style={isHidden ? { color: 'transparent', textShadow: '0 0 7px black' } : {}}>
          {content ?? value}
        </span>
      </p>

      <span className="icon">
        <FaRegCopy title={title ? title : t('commonCopyToClipboardTitle')} />
      </span>

      {secret && (
        <span className="icon">
          <FaEye
            size={16}
            onClick={(e) => {
              e.stopPropagation()
              // e.preventDefault()
              setIsHidden(!isHidden)
            }}
          />
        </span>
      )}
    </div>
  )
}

export default CopyableText
