import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddBulkProductsToInventoryAndShowMutationVariables = Types.Exact<{
  input: Types.AddBulkProductsToInventoryAndShowInput;
}>;


export type AddBulkProductsToInventoryAndShowMutation = { __typename?: 'Mutation', addBulkProductsToInventoryAndShow: { __typename?: 'AddBulkProductsToInventoryAndShowPayload', ok: boolean, inventoryProductIds: Array<string>, productIds?: Array<string> | null } };


export const AddBulkProductsToInventoryAndShowDocument = gql`
    mutation AddBulkProductsToInventoryAndShow($input: AddBulkProductsToInventoryAndShowInput!) {
  addBulkProductsToInventoryAndShow(input: $input) {
    ok
    inventoryProductIds
    productIds
  }
}
    `;
export type AddBulkProductsToInventoryAndShowMutationFn = Apollo.MutationFunction<AddBulkProductsToInventoryAndShowMutation, AddBulkProductsToInventoryAndShowMutationVariables>;

/**
 * __useAddBulkProductsToInventoryAndShowMutation__
 *
 * To run a mutation, you first call `useAddBulkProductsToInventoryAndShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBulkProductsToInventoryAndShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBulkProductsToInventoryAndShowMutation, { data, loading, error }] = useAddBulkProductsToInventoryAndShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBulkProductsToInventoryAndShowMutation(baseOptions?: Apollo.MutationHookOptions<AddBulkProductsToInventoryAndShowMutation, AddBulkProductsToInventoryAndShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBulkProductsToInventoryAndShowMutation, AddBulkProductsToInventoryAndShowMutationVariables>(AddBulkProductsToInventoryAndShowDocument, options);
      }
export type AddBulkProductsToInventoryAndShowMutationHookResult = ReturnType<typeof useAddBulkProductsToInventoryAndShowMutation>;
export type AddBulkProductsToInventoryAndShowMutationResult = Apollo.MutationResult<AddBulkProductsToInventoryAndShowMutation>;
export type AddBulkProductsToInventoryAndShowMutationOptions = Apollo.BaseMutationOptions<AddBulkProductsToInventoryAndShowMutation, AddBulkProductsToInventoryAndShowMutationVariables>;