import { Popover } from 'antd'

import { useChatBoxContext } from '../../ChatBoxProvider'

import type { CustomizedJoinFragment, CustomizedUserFollowFragment } from '../../hooks/useChat'

export interface JoinAndFollowItemProps {
  item: CustomizedUserFollowFragment | CustomizedJoinFragment
}

export const JoinAndFollowItem = ({ item }: JoinAndFollowItemProps) => {
  const { fontSize } = useChatBoxContext()

  if (!item) return null

  const { user } = item
  if (!user) return null

  return (
    <div className={`chat-join-follow-message chat-comment-message-${fontSize}`}>
      <p>
        <span className="username">{item.user.username}</span>
        {item.userLoyaltyLevelForShowSeller?.imageUrl && (
          <Popover content={item.userLoyaltyLevelForShowSeller?.name}>
            <img alt="loyalty-bage" src={item.userLoyaltyLevelForShowSeller.imageUrl} />
          </Popover>
        )}
        {item.customText}
      </p>
    </div>
  )
}
