import { memo } from 'react'

export interface AvatarProps {
  src: string | undefined | null
  children: React.ReactNode
  withDot?: boolean
  isSmall?: boolean
}

const getAvatarSize = (isSmall: boolean) => (isSmall ? 'w-5 h-5' : 'w-8 h-8')
const getDotSize = (isSmall: boolean) => (isSmall ? 'w-2 h-2' : 'w-3 h-3')
const getDotPosition = (isSmall: boolean) => (isSmall ? 'left-4' : 'left-6')
const getUserInitial = (children: React.ReactNode) => {
  const userName = children?.toString() ?? '?'
  return userName.length > 0 ? userName[0].toLocaleUpperCase() : userName
}

const AvatarImage = ({ src, size }: { src: string; size: string }) => (
  <div className={`relative flex items-center justify-center ${size} overflow-hidden bg-gray-100 rounded-full`}>
    <img alt="avatar" className={`${size} rounded-full`} src={src} />
  </div>
)

const AvatarInitials = ({ initial, size }: { initial: string; size: string }) => (
  <div className={`relative flex items-center justify-center ${size} overflow-hidden bg-gray-100 rounded-full`}>
    <span className="font-medium text-xs text-black">{initial}</span>
  </div>
)

const StatusDot = ({ size, position }: { size: string; position: string }) => (
  <span className={`bottom-0 ${position} absolute ${size} bg-green-400 border-2 border-white rounded-full`}></span>
)

const Avatar = ({ src, children, withDot, isSmall, ...props }: AvatarProps) => {
  const avatarSize = getAvatarSize(isSmall ?? false)
  const dotSize = getDotSize(isSmall ?? false)
  const dotPosition = getDotPosition(isSmall ?? false)
  const userInitial = getUserInitial(children)

  return (
    <div className="relative flex" {...props}>
      {src ? <AvatarImage size={avatarSize} src={src} /> : <AvatarInitials initial={userInitial} size={avatarSize} />}
      {withDot && <StatusDot position={dotPosition} size={dotSize} />}
    </div>
  )
}

export default memo(Avatar)
