import { memo, type ReactNode } from 'react'

import './Tag.scss'

export enum TagStatus {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  SECONDARY = 'secondary',
}

interface orderStatusTagProps {
  status: TagStatus
  content: string | ReactNode
  onClick?: (e: any) => void
}

const Tag = ({ status, content, onClick = () => undefined }: orderStatusTagProps) => {
  return (
    <span className={`tag tag-${status}`} onClick={(e: any) => onClick(e)}>
      {content}
    </span>
  )
}

export default memo(Tag)
