import { useCallback, useRef, useState } from 'react'

import { ReactionCounterStatus } from '@/network/graphql/types.generated'
import { useOnReactionShowFeedItemSubscription } from '@/views/Shows/operations.generated'

import { ReactionItem } from './ReactionItem'

import type { ReactionFragment } from '@/views/Shows/operations.generated'

const MAX_MS_PER_REACTION = 16

interface ReactionItemsProps {
  showId: string
}

export const ReactionItems = ({ showId }: ReactionItemsProps) => {
  const [reactionItems, setReactionItems] = useState<ReactionFragment[]>([])
  const time = useRef(0)

  const removeReaction = useCallback((reactionId: ReactionFragment['id']) => {
    setReactionItems((prevReactions) => prevReactions.filter((reaction) => reaction.id !== reactionId))
  }, [])

  useOnReactionShowFeedItemSubscription({
    ignoreResults: true,
    variables: { showId },
    onData: ({ data }) => {
      const feedItem = data?.data?.showFeedItemAdded?.feedItem
      if (feedItem && feedItem.__typename === 'ReactionFeedItem') {
        if (
          feedItem.reactionCounterStatus === ReactionCounterStatus.Starting ||
          feedItem.reactionCounterStatus === ReactionCounterStatus.Stopping
        ) {
          return
        }
        const now = Date.now()
        if (now > time.current + MAX_MS_PER_REACTION) {
          time.current = now
          setReactionItems([...reactionItems, feedItem])
        }
      }
    },
  })

  return (
    <>
      {reactionItems.map((reaction) => (
        <ReactionItem
          key={reaction.id}
          reactionEmoji={reaction.emoji}
          reactionId={reaction.id}
          removeReaction={removeReaction} // Call removeReaction when a reaction expires
        />
      ))}
    </>
  )
}
