import { ConfigProvider } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, connect } from 'react-redux'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import 'moment/min/locales'

import { fetchLastSellerContract } from '@/actions/fetch-last-seller-contract'
import { fetchLastSellerContractDate } from '@/actions/fetch-last-seller-contract-date'
import { fetchMe } from '@/actions/fetch-me'
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary'
// import { useUser } from '@/contexts/user/User.context'
import Header from '@/components/Layout/Header/Header'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { useUser } from '@/contexts/user/User.context'
import getAntdLocale from '@/helpers/antdLocales'
import browserLanguage from '@/helpers/browserLanguage'
import { setToLocalStorage } from '@/helpers/localstorage'
import { useAppSelector } from '@/reducers'
import { signLastContract, storeToken } from '@/reducers/authentication'
import { trackEvent } from '@/util/eventTracker'
import {
  getUrlAuthTokens,
  IMPERSONATION_TOKEN_ID_PARAM_NAME,
  MAGIC_TOKEN_QUERY_PARAM_NAME,
  PAYPAL_SSO_PARAM_NAME,
} from '@/util/url-auth-tokens'
import Account from '@/views/Account/Account'
import Calendar from '@/views/Calendar/Calendar'
import Home from '@/views/Home/Home'
import { InventoryContainer } from '@/views/Inventory/InventoryContainer'
import Ledger from '@/views/Ledger/Ledger'
import Login from '@/views/Login/Login'
import Logout from '@/views/Logout/Logout'
import OrdersExport from '@/views/OrdersExport'
import SellerContract from '@/views/SellerContract/SellerContract'
import { Shipments } from '@/views/Shipments/Shipments'
import Show from '@/views/Show/Show'
import { Shows } from '@/views/Shows/Shows'
import Signup from '@/views/Signup/Signup'

import { Contact } from '../Contact/Contact'
import {
  useLoginWithImpersonationTokenMutation,
  useLoginWithMagicTokenMutation,
  useLoginWithSsoMutation,
} from '../Login/operations.generated'
import { Ratings } from '../Ratings/Ratings'
import { Shops } from '../Shops/Shops'

type RouterProps = {
  lastSellerContractDate: string
  isDateLoading: boolean
  hasSignedLastContract: boolean
  isContractError: boolean
}

const Router = (props: RouterProps) => {
  const { lastSellerContractDate, isDateLoading, hasSignedLastContract, isContractError } = props

  const queryParams = new URLSearchParams(window.location.search)
  const { impersonateToken, magicToken, paypalSsoToken } = getUrlAuthTokens()

  const { fetchUser } = useUser()

  const dispatch = useDispatch<any>()
  const { i18n } = useTranslation()
  const userLanguage = browserLanguage()
  const locale = getAntdLocale(i18n.language.replace('-', '_'))
  moment.locale(i18n.language.split('-')[0])

  // TODO: Uncomment the following to be able to replace the fetchMe redux action by using the user context,
  // when everything will be ready
  // const { user: user2, isLoading: isUser2Loading } = useUser()

  const { isLogged, user } = useAppSelector((state) => state.authentication)
  const [isLoaded, setIsLoaded] = useState(false)

  const userId = user?.id
  const lastSellerContractSignatureDate = user?.sellerConfig?.acceptedStudioConditionsOn
  const sellerNeedsToSignUpAsASeller = isLogged && user && !user.isSeller && user.isSelected
  const sellerNeedsToSign =
    !lastSellerContractSignatureDate ||
    moment(lastSellerContractSignatureDate).isBefore(moment(lastSellerContractDate), 'day')

  if (location.hash.startsWith('#/')) {
    location.href = location.href.replace('#/', '')
  }

  const getMe = async () => {
    const result = await dispatch(fetchMe())
    const { sellerConfig } = result?.payload || {}
    const { countryId } = sellerConfig || {}
    if (countryId) {
      trackEvent(`LOGIN_${countryId}`)
    }
    if (sellerConfig) {
      await fetchUser()
    }
  }

  const [loginWithImpersonateToken] = useLoginWithImpersonationTokenMutation()

  const handleConnectionWithImpersonateToken = async (impersonationToken: string) => {
    await loginWithImpersonateToken({
      variables: { impersonationToken },
      onCompleted: async ({ loginWithImpersonationToken }) => {
        setToLocalStorage('isImpersonating', 'true')
        const currentUrl = new URL(window.location.href)
        queryParams.delete(IMPERSONATION_TOKEN_ID_PARAM_NAME)
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        // hides the impersonationTokenId from the URL
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithImpersonationToken))
      },
      onError: (error) => notificationDanger(error.message),
    })
  }

  const [loginWithMagicToken] = useLoginWithMagicTokenMutation()

  const handleConnectionWithMagicToken = async (magicToken: string) => {
    await loginWithMagicToken({
      variables: { magicToken },
      onCompleted: async ({ loginWithMagicToken }) => {
        const currentUrl = new URL(window.location.href)
        queryParams.delete(MAGIC_TOKEN_QUERY_PARAM_NAME)
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        // hides the magicToken from the URL
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithMagicToken))
      },
      onError: (error) => notificationDanger(error.message),
    })
  }

  const [loginWithSSO] = useLoginWithSsoMutation()
  const handleConnectionWithPaypal = async (paypalSsoToken: string) => {
    loginWithSSO({
      variables: { idToken: paypalSsoToken },
      onCompleted: async ({ loginWithSso }) => {
        const currentUrl = new URL(window.location.href)
        queryParams.delete(PAYPAL_SSO_PARAM_NAME)
        queryParams.forEach((_, param) => {
          queryParams.delete(param)
        })
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithSso))
      },
      onError: (error) => notificationDanger(error.message),
    })
  }

  // TODO: split this useEffect into smaller unitary ones
  useEffect(() => {
    ;(async () => {
      if (magicToken) {
        await handleConnectionWithMagicToken(magicToken)
        return
      }

      if (impersonateToken) {
        await handleConnectionWithImpersonateToken(impersonateToken)
        return
      }

      const idToken = queryParams.get('id_token')
      if (paypalSsoToken && typeof idToken === 'string') {
        await handleConnectionWithPaypal(idToken)
        return
      }

      if (isLogged) {
        await getMe()
      }

      setIsLoaded(true)

      if (isLogged) {
        if (!hasSignedLastContract) {
          dispatch(fetchLastSellerContractDate())
        }

        if (lastSellerContractDate) {
          if (sellerNeedsToSign) {
            dispatch(signLastContract(false))
            dispatch(fetchLastSellerContract(userLanguage))
          } else if (!sellerNeedsToSign) {
            dispatch(signLastContract(true))
          }
        }
      }
    })()
  }, [
    dispatch,
    sellerNeedsToSignUpAsASeller,
    isLogged,
    userId,
    lastSellerContractDate,
    hasSignedLastContract,
    sellerNeedsToSign,
    userLanguage, // TODO: probably not needed. Test and remove if not.
    magicToken,
    impersonateToken,
  ])

  if (!isLoaded) return null

  if (sellerNeedsToSignUpAsASeller) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Signup />} path="/" />
          <Route element={<Logout />} path="/logout" />
        </Routes>
      </BrowserRouter>
    )
  }

  if (
    isLogged &&
    userId &&
    sellerNeedsToSign &&
    !isDateLoading &&
    !hasSignedLastContract &&
    !isContractError &&
    user?.isSeller
  ) {
    // TODO:
    //  - Create a specific path for the seller contract,
    //  - Add this it the routes below
    //  - In the view, make sure the user needs to sign the contract, otherwise Navigate to the home page
    //  - Below, just Navigate to the seller contract path
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<SellerContract userId={userId} />} path="/" />
          <Route element={<Logout />} path="/logout" />
        </Routes>
      </BrowserRouter>
    )
  }

  const shouldNavigateToLogin = !isLogged || !user || !user.isSelected

  if (shouldNavigateToLogin) {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Login redirectTo={queryParams.get('NavigateTo') ?? ''} />} path="/" />
          <Route element={<Logout />} path="/logout" />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <ErrorBoundary>
          <Header />
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Calendar />} path="/calendar" />
            <Route element={<Contact />} path="/contact" />
            <Route element={<Show />} path="/shows/:showId/products/:productId" />
            <Route element={<Show />} path="/shows/:showId" />
            <Route element={<Shows />} path="/shows" />
            <Route element={<Show isShop={true} />} path="/shops/:showId/products/:productId" />
            <Route element={<Show isShop={true} />} path="/shops/:showId" />
            <Route element={<Shops />} path="/shops" />
            <Route element={<OrdersExport />} path="/export" />
            <Route element={<Account />} path="/account" />
            <Route element={<Ledger />} path="/ledger" />
            <Route element={<InventoryContainer />} path="/inventory" />
            <Route element={<Shipments />} path="/shipments" />
            <Route element={<Ratings />} path="/ratings" />
            <Route element={<Logout />} path="/logout" />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </ConfigProvider>
  )
}

const mapStateToProps = (state: any) => {
  return {
    hasSignedLastContract: state.authentication.hasSignedLastSellerContract,
    lastSellerContractDate: state.sellerContract.lastSellerContractDate,
    isDateLoading: state.sellerContract.isDateLoading,
    isContractError: state.sellerContract.isError,
  }
}

export default connect(mapStateToProps)(Router)
