import { useTranslation } from 'react-i18next'

import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useStartPollInShowMutation } from '@/views/Show/operations.generated'

import type { PollInputData } from '@/views/Show/components/ChatBox/components/CreatePoll/CreatePollForm/CreatePollForm'

type UsePollProps = {
  showId: number
  onLaunched: () => void
}

const useLaunchPoll = (props: UsePollProps) => {
  const { showId, onLaunched } = props
  const { t } = useTranslation()
  const showGlobalId = `Show|${showId}`

  const [startPoll, { loading }] = useStartPollInShowMutation()

  const launchPoll = async (data: PollInputData) => {
    const { question, answers } = data
    const input = { showId: showGlobalId, question, answers }
    await startPoll({
      variables: {
        input,
      },
      onCompleted: async (pollData) => {
        notificationSuccess(t('showPollLaunched'))
        const initialDuration = pollData.createAndLaunchPollInShow.poll.durationMs

        trackEvent('POLL_START', { initialDuration, show: showId })

        onLaunched()
      },
      onError: (err) => {
        notificationDanger(err.message)
        trackError(err, { meta: { ...input, scope: 'Show.launchPoll' } })
      },
    })
  }

  return { launchPoll, loading }
}

export default useLaunchPoll
