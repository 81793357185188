import { convertHeicToPng } from '@/helpers/images/heic2any'

export const convertImageIfNecessary = async (file: File) => {
  try {
    const isHeicImage = ['image/heic', 'image/heif'].includes(file.type)
    const usedFile = isHeicImage ? await convertHeicToPng(file) : file
    return usedFile
  } catch (error: any) {
    // nothing here for the moment
    // TODO handle error?
  }
}
