import { PlayCircleFilled } from '@ant-design/icons'
import { Radio, Form, message, Checkbox, Divider } from 'antd'
import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { ShowGiveawayAudience } from '@/network/graphql/types.generated'

import InfoLabel from '../InfoLabel'
import Dialog from '../ui/Dialog/Dialog'

import type { RadioChangeEvent } from 'antd'

import './GiveawayModal.scss'

interface GiveawayModalProps {
  giveawayAudience: ShowGiveawayAudience | null
  giveawayInternational: boolean
  onChangeModalVisibility: (value: boolean) => void
  productId?: string
  launchGiveaway: (productId: string, audience: ShowGiveawayAudience, isOpenedToInternationalBuyers: boolean) => void
}

const GiveawayModal = ({
  giveawayAudience,
  giveawayInternational,
  onChangeModalVisibility,
  productId,
  launchGiveaway,
}: GiveawayModalProps) => {
  const [audience, setAudience] = useState<ShowGiveawayAudience>(giveawayAudience || ShowGiveawayAudience.PresentUsers)
  const [isOpenedToInternationalBuyers, setIsOpenedToInternationalBuyers] = useState<boolean>(giveawayInternational)

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onChange = (e: RadioChangeEvent) => {
    setAudience(e.target.value)
  }

  const closeModal = () => {
    onChangeModalVisibility(false)
  }

  useEffect(() => {
    if (!productId) message.error(t('giveawayModalNoProductErrorMessage'))
  }, [productId, t])

  if (!productId) return null

  const onGiveawayLaunch = () => {
    launchGiveaway(productId, audience, isOpenedToInternationalBuyers)
    closeModal()
  }

  return (
    <Dialog isOpen={true} modal={true} title={t('giveawayModalTitle')} onClose={closeModal}>
      {giveawayAudience ? (
        <div className="giveaway-with-audience">
          <p>
            <Trans i18nKey={`giveawayLaunchModalWithAudience${giveawayAudience}`} />
          </p>
          <span className="giveaway-audience-update-info">{t('giveawayLaunchModalWithAudienceUpdateInfo')}</span>
        </div>
      ) : (
        <>
          <span className="callout_2">{t('giveawayLaunchModalAudienceTypeTitle')}</span>

          <div className="giveaway_modal__radio_group">
            <Form form={form} name="giveaway-audience-form">
              <Form.Item name="giveaway-audience" valuePropName="checked">
                <Radio.Group value={audience} onChange={onChange}>
                  <Radio value={ShowGiveawayAudience.PresentUsers}>
                    <Trans i18nKey="giveawayLaunchModalCriteriaPresentUsers" />
                  </Radio>
                  <Radio value={ShowGiveawayAudience.Buyers}>
                    <Trans i18nKey="giveawayLaunchModalCriteriaBuyers" />
                  </Radio>
                  <Radio value={ShowGiveawayAudience.BuyersOrders}>
                    <Trans i18nKey="giveawayLaunchModalCriteriaBuyersOrders" />
                  </Radio>
                  <Radio value={ShowGiveawayAudience.SharersOpeners}>
                    <Trans i18nKey="giveawayLaunchModalSharersOpeners" />
                  </Radio>
                  <Radio value={ShowGiveawayAudience.Followers}>
                    <Trans i18nKey="giveawayLaunchModalCriteriaFollowers" />
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <InfoLabel
                type="info"
                message={
                  <Checkbox
                    checked={isOpenedToInternationalBuyers}
                    style={{ marginTop: -4, marginBottom: 2 }}
                    onChange={(e) => setIsOpenedToInternationalBuyers(e.target.checked)}
                  >
                    {t('giveawayLaunchModalCriteriaInternationalParticipants')}
                  </Checkbox>
                }
              />
            </Form>
            <Divider />
            <span className="callout_2">{t('giveawayLaunchModalNoticeTitle')}</span>
            <div>{t('giveawayLaunchModalNoticeDescription')}</div>
          </div>
        </>
      )}
      <div className="giveaway-modal-footer">
        <Button className="cancel-action secondary" label={t('commonCancel')} onClick={closeModal} />
        <Button
          className="validate-action launch-giveaway-action primary"
          form="giveaway-audience-form"
          htmlType="submit"
          icon={<PlayCircleFilled />}
          label={t('commonLaunch')}
          onClick={onGiveawayLaunch}
        />
      </div>
    </Dialog>
  )
}

export default GiveawayModal
