import { memo, useMemo } from 'react'

import './ColumnImagesAndName.scss'

type ColumnImageAndNameProps = {
  id: string
  name: string
  image: string
  color?: string
  description?: string
  onClick: () => void
}

const ColumnImageAndName = (props: ColumnImageAndNameProps) => {
  const { id, name, image, color, description, onClick } = props

  const imageUrl = useMemo(() => {
    return image || '/badges/empty-pic.png'
  }, [image])

  return (
    <div key={`column-product-${id}`} className="column-product" onClick={onClick}>
      <div>
        <div className="image">
          <img key={`${id}-${imageUrl}`} alt={`thumbnail-${name}`} src={imageUrl} />
        </div>
        <div className="details">
          <p>{name}</p>
          <span>{[color, description].filter(Boolean).join(' | ')}</span>
        </div>
      </div>
    </div>
  )
}

export default memo(ColumnImageAndName)
