import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBulkUnshippedShippingAddressStickersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBulkUnshippedShippingAddressStickersQuery = { __typename?: 'Query', bulkUnshippedShippingAddressStickers: { __typename?: 'BulkUnshippedShippingAddressStickersPayload', label: string } };


export const GetBulkUnshippedShippingAddressStickersDocument = gql`
    query GetBulkUnshippedShippingAddressStickers {
  bulkUnshippedShippingAddressStickers {
    label
  }
}
    `;

/**
 * __useGetBulkUnshippedShippingAddressStickersQuery__
 *
 * To run a query within a React component, call `useGetBulkUnshippedShippingAddressStickersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkUnshippedShippingAddressStickersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkUnshippedShippingAddressStickersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBulkUnshippedShippingAddressStickersQuery(baseOptions?: Apollo.QueryHookOptions<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>(GetBulkUnshippedShippingAddressStickersDocument, options);
      }
export function useGetBulkUnshippedShippingAddressStickersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>(GetBulkUnshippedShippingAddressStickersDocument, options);
        }
export function useGetBulkUnshippedShippingAddressStickersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>(GetBulkUnshippedShippingAddressStickersDocument, options);
        }
export type GetBulkUnshippedShippingAddressStickersQueryHookResult = ReturnType<typeof useGetBulkUnshippedShippingAddressStickersQuery>;
export type GetBulkUnshippedShippingAddressStickersLazyQueryHookResult = ReturnType<typeof useGetBulkUnshippedShippingAddressStickersLazyQuery>;
export type GetBulkUnshippedShippingAddressStickersSuspenseQueryHookResult = ReturnType<typeof useGetBulkUnshippedShippingAddressStickersSuspenseQuery>;
export type GetBulkUnshippedShippingAddressStickersQueryResult = Apollo.QueryResult<GetBulkUnshippedShippingAddressStickersQuery, GetBulkUnshippedShippingAddressStickersQueryVariables>;