import { useTranslation } from 'react-i18next'

import Link from '@/components/ui/Link/Link'

const { VITE_APP_BITSWEB_URL } = import.meta.env

const SignUpLink = () => {
  const { t } = useTranslation()
  return (
    <div className="not-yet-registered">
      <span className="intro-message">{t('loginSignUpLinkIntroMessage')}</span>
      <Link className="!text-white underline" href={VITE_APP_BITSWEB_URL}>
        {t('loginSignUpLinkLabel')}
      </Link>
    </div>
  )
}

export default SignUpLink
