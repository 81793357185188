import { useCallback, useEffect, type ReactNode } from 'react'
import { useCacheBuster } from 'react-cache-buster'
import { isMobileOnly } from 'react-device-detect'

import Loader from '@/components/ui/Loader/Loader'

import ViewHeader from './ViewHeader/ViewHeader'

import './ViewContainer.scss'

type ViewContainerProps = {
  children: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
  isLoading?: boolean
  id?: string
}

const ViewContainer = (props: ViewContainerProps) => {
  const { children, leftContent, rightContent, isLoading, id = '' } = props

  const { checkCacheStatus } = useCacheBuster()

  const htmlId = id ? `page-${id}` : undefined

  const checkAppVersion = useCallback(async () => {
    await checkCacheStatus()
  }, [checkCacheStatus])

  useEffect(() => {
    ;(async () => {
      await checkAppVersion()
    })()
  }, [checkAppVersion])

  return (
    <main className={`page ${htmlId || ''} ${isMobileOnly ? 'is-mobile' : ''}`} id={htmlId}>
      <div className="page-wrapper">
        <ViewHeader leftContent={leftContent} rightContent={rightContent} />
        <div className={`main-content ${isLoading ? 'is-loading' : ''}`}>{isLoading ? <Loader /> : children}</div>
      </div>
    </main>
  )
}

export default ViewContainer
