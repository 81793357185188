import { ShowEventDate } from './ShowEventDate'

type ShowEventHeaderProps = {
  banner?: string
  title: string
  description?: string
  startAt?: Date
  endAt?: Date
}

const ShowEventHeader = ({ banner, title, description, startAt, endAt }: ShowEventHeaderProps) => {
  return (
    <div className="header">
      {banner && (
        <div className="header__banner">
          <img alt={title} src={banner} />
        </div>
      )}
      <div className="header__content">
        <h2 className="header__title">{title}</h2>
        {description && <p className="header__description">{description}</p>}
        {startAt && <ShowEventDate endAt={endAt} startAt={startAt} />}
      </div>
    </div>
  )
}

export default ShowEventHeader
