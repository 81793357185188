import BellIcon from '@/components/ui/Icons/BellIcon/BellIcon'
import GavelIcon from '@/components/ui/Icons/GavelIcon/GavelIcon'

type Props = {
  autoBidsCount?: number
  followingUsersCount: number
}

const FollowsAndAutoBidsCount = ({ autoBidsCount, followingUsersCount }: Props) => {
  return (
    <>
      <div className={`metric counter ${followingUsersCount ? '' : 'disabled'}`}>
        <BellIcon />
        <div className="count">{followingUsersCount ? `x${followingUsersCount}` : `-`}</div>
      </div>

      {autoBidsCount !== undefined && (
        <div className={`metric counter ${autoBidsCount ? 'disabled' : 'disabled'}`}>
          <GavelIcon />
          <div className="count">{autoBidsCount ? `x${autoBidsCount}` : `-`}</div>
        </div>
      )}
    </>
  )
}

export default FollowsAndAutoBidsCount
