import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ParentCategoriesOrderedByPositionQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type ParentCategoriesOrderedByPositionQuery = { __typename?: 'Query', parentCategoriesOrderedByPosition: { __typename?: 'ParentCategoryConnection', edges: Array<{ __typename?: 'ParentCategoryEdge', node: { __typename?: 'ParentCategory', shapeUrl: string, secondaryColor: string, primaryColor: string, name: string, id: string, allCategories: Array<{ __typename?: 'Category', id: string, name: string, stickerUrl: string }> } }> } };

export type CategoriesOrderedByPositionQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
}>;


export type CategoriesOrderedByPositionQuery = { __typename?: 'Query', categoriesOrderedByPosition: { __typename?: 'CategoryConnection', edges: Array<{ __typename?: 'CategoryEdge', node: { __typename?: 'Category', slug: string, colorAccent?: string | null, stickerUrl: string, id: string, isFollowing: boolean, isNew: boolean, legacyId: number, name: string, isOffline: boolean, parentCategory: { __typename?: 'ParentCategory', id: string, name: string } } }> } };


export const ParentCategoriesOrderedByPositionDocument = gql`
    query ParentCategoriesOrderedByPosition($first: Int!) {
  parentCategoriesOrderedByPosition(first: $first) {
    edges {
      node {
        shapeUrl
        secondaryColor
        primaryColor
        name
        id
        allCategories {
          id
          name
          stickerUrl
        }
      }
    }
  }
}
    `;

/**
 * __useParentCategoriesOrderedByPositionQuery__
 *
 * To run a query within a React component, call `useParentCategoriesOrderedByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentCategoriesOrderedByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentCategoriesOrderedByPositionQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useParentCategoriesOrderedByPositionQuery(baseOptions: Apollo.QueryHookOptions<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables> & ({ variables: ParentCategoriesOrderedByPositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>(ParentCategoriesOrderedByPositionDocument, options);
      }
export function useParentCategoriesOrderedByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>(ParentCategoriesOrderedByPositionDocument, options);
        }
export function useParentCategoriesOrderedByPositionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>(ParentCategoriesOrderedByPositionDocument, options);
        }
export type ParentCategoriesOrderedByPositionQueryHookResult = ReturnType<typeof useParentCategoriesOrderedByPositionQuery>;
export type ParentCategoriesOrderedByPositionLazyQueryHookResult = ReturnType<typeof useParentCategoriesOrderedByPositionLazyQuery>;
export type ParentCategoriesOrderedByPositionSuspenseQueryHookResult = ReturnType<typeof useParentCategoriesOrderedByPositionSuspenseQuery>;
export type ParentCategoriesOrderedByPositionQueryResult = Apollo.QueryResult<ParentCategoriesOrderedByPositionQuery, ParentCategoriesOrderedByPositionQueryVariables>;
export const CategoriesOrderedByPositionDocument = gql`
    query CategoriesOrderedByPosition($first: Int!) {
  categoriesOrderedByPosition(first: $first) {
    edges {
      node {
        slug
        colorAccent
        stickerUrl
        id
        isFollowing
        isNew
        legacyId
        name
        parentCategory {
          id
          name
        }
        isOffline
      }
    }
  }
}
    `;

/**
 * __useCategoriesOrderedByPositionQuery__
 *
 * To run a query within a React component, call `useCategoriesOrderedByPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesOrderedByPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesOrderedByPositionQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCategoriesOrderedByPositionQuery(baseOptions: Apollo.QueryHookOptions<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables> & ({ variables: CategoriesOrderedByPositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>(CategoriesOrderedByPositionDocument, options);
      }
export function useCategoriesOrderedByPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>(CategoriesOrderedByPositionDocument, options);
        }
export function useCategoriesOrderedByPositionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>(CategoriesOrderedByPositionDocument, options);
        }
export type CategoriesOrderedByPositionQueryHookResult = ReturnType<typeof useCategoriesOrderedByPositionQuery>;
export type CategoriesOrderedByPositionLazyQueryHookResult = ReturnType<typeof useCategoriesOrderedByPositionLazyQuery>;
export type CategoriesOrderedByPositionSuspenseQueryHookResult = ReturnType<typeof useCategoriesOrderedByPositionSuspenseQuery>;
export type CategoriesOrderedByPositionQueryResult = Apollo.QueryResult<CategoriesOrderedByPositionQuery, CategoriesOrderedByPositionQueryVariables>;