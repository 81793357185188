export enum UserType {
  Admin = 'Admin',
  Moderator = 'Moderator',
  User = 'User',
}

export const DEFAULT_USERTYPE_COLOR = 'text-gray-700'

export const getUsernameColorClass = (userType: UserType): string => {
  switch (userType) {
    case UserType.Admin:
      return 'text-red-500'
    case UserType.Moderator:
      return 'text-blue-500'
    case UserType.User:
    default:
      return DEFAULT_USERTYPE_COLOR
  }
}

// Used to compute emote position
export function generateRandom(min = 0, max = 100) {
  const difference = max - min
  let rand = Math.random()

  rand = Math.floor(rand * difference)
  rand = rand + min

  return rand
}

export type Viewer = {
  __typename?: 'User' | undefined
  id: string
  legacyId?: number
  username: string
  avatarWebPUrl?: string | null | undefined
}

export const userIsPresent = (user: Viewer, viewers: Viewer[]) => {
  return !!viewers.find((viewer) => viewer.id === user.id)
}

export const userIsModerator = (user: Pick<Viewer, 'id'>, moderators: Pick<Viewer, 'id'>[]) => {
  return !!moderators.find((moderator) => moderator.id === user.id)
}

export const userIsBannedFromShow = (user: Viewer, bannedUsersFromShow: Viewer[]) => {
  return !!bannedUsersFromShow.find((bannedViewer) => bannedViewer.id === user.id)
}

export const userIsBannedFromSeller = (user: Viewer, bannedUsersFromSeller: Viewer[]) => {
  return !!bannedUsersFromSeller.find((bannedViewer) => bannedViewer.id === user.id)
}

export const userIsBanned = (user: Viewer, bannedUsersFromShow: Viewer[], bannedUsersFromSeller: Viewer[]) => {
  return userIsBannedFromShow(user, bannedUsersFromShow) || userIsBannedFromSeller(user, bannedUsersFromSeller)
}

export enum ViewerActionsType {
  promoteAction,
  demoteAction,
  banFromShowAction,
  banFromSellerAction,
}
