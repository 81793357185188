import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CreateOrEditProduct from '../CreateOrEditProduct/CreateOrEditProduct'
import ImportProducts from '../ImportProducts/ImportProducts'
import Button from '../ui/Button/Button'
import Dialog from '../ui/Dialog/Dialog'
import CloudIcon from '../ui/Icons/CloudIcon/CloudIcon'
import GavelOutlineIcon from '../ui/Icons/GavelIcon/GavelOutlineIcon'
import GiftIcon from '../ui/Icons/GiftIcon/GiftIcon'
import ShopIcon from '../ui/Icons/ShopIcon/ShopIcon'

import { OnboardingBanner } from './OnboardingBanner/OnboardingBanner'
import ProductTypeCell from './ProductTypeCell'

import type { ExtraProductFormOptions } from '../CreateOrEditProduct/types'
import type { ProductType, Product } from '@/network/graphql/types.generated'

import './CreateProductTypeSelector.scss'

type CreateProductTypeSelectorProps = {
  showId?: any
  showHasEnded?: boolean

  onClose?: () => void
  onImported?: () => void

  onSuccessCreate?: (product?: Product, options?: ExtraProductFormOptions, type?: ProductType) => void
  onCancelCreate?: () => void
  onAddFromInventory?: () => void
  isShowBroadcasting?: boolean
  product?: Product
  lastCreatedProduct?: Product
  showCategory?: string
}

const CreateProductTypeSelector = (props: CreateProductTypeSelectorProps) => {
  const {
    showId,
    showHasEnded,
    onClose,
    onImported,
    onSuccessCreate,
    onCancelCreate,
    onAddFromInventory,
    isShowBroadcasting,
    product,
    lastCreatedProduct,
    showCategory,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined)

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setActiveTab(undefined)
    onClose && onClose()
  }, [onClose])

  const handleCreateProduct = useCallback(
    (type: 'auction' | 'instantBuy' | 'giveaway') => {
      if (showId) {
        const currentUrl = window.location.pathname
        const url = `${currentUrl}products/new?productType=${type}`
        navigate(url)
      } else {
        const currentUrl = window.location.pathname
        const url = `${currentUrl}?productType=${type}`
        navigate(url)
        onAddFromInventory && onAddFromInventory()
      }
      setIsOpen(false)
    },
    [showId, onAddFromInventory]
  )

  const handleImported = useCallback(() => {
    setActiveTab(undefined)
    setIsOpen(false)
    onImported && onImported() // TODO: We should pass the imported products as argument
  }, [onImported])

  return (
    <div className="create-products-selector">
      {!showHasEnded && (
        <Button
          className="create-products-selector-action primary"
          label={t('showProductsCreationSelectorActionLabel')}
          onClick={() => setIsOpen(true)}
        />
      )}

      <Dialog
        className="create-products-selector-dialog is-drawer is-animated"
        isOpen={isOpen}
        modal={true}
        title={t('showProductsCreationSelectorActionLabel')}
        onClose={handleClose}
      >
        <div className="product-selector">
          <ProductTypeCell
            icon={<GavelOutlineIcon />}
            subTitle={t('productTypeSelectorAuctionSubTitle')}
            title={t('productTypeSelectorAuction')}
            onClick={() => handleCreateProduct('auction')}
          />

          <ProductTypeCell
            icon={<ShopIcon />}
            subTitle={t('productTypeSelectorInstantBuySubTitle')}
            title={t('productTypeSelectorInstantBuy')}
            onClick={() => handleCreateProduct('instantBuy')}
          />

          <ProductTypeCell
            icon={<GiftIcon />}
            subTitle={t('productTypeSelectorGiveawaySubTitle')}
            title={t('productTypeSelectorGiveaway')}
            onClick={() => handleCreateProduct('giveaway')}
          />
        </div>

        <div className="product-selector-bulk">
          {showId && (
            <ProductTypeCell
              icon={<CloudIcon />}
              subTitle={t('productTypeSelectorInventorySubTitle')}
              title={t('productTypeSelectorInventory')}
              onClick={() => setActiveTab('inventory')}
            />
          )}

          <ProductTypeCell
            icon={<CloudIcon />}
            subTitle={t('productTypeSelectorBulkSubTitle')}
            title={t('productTypeSelectorBulk')}
            onClick={() => setActiveTab('bulk')}
          />

          <OnboardingBanner />
        </div>
      </Dialog>
      <CreateOrEditProduct
        isShowBroadcasting={isShowBroadcasting}
        lastCreatedProduct={lastCreatedProduct}
        product={product}
        showCategory={showCategory}
        showHasEnded={showHasEnded}
        showId={showId}
        onCancel={onCancelCreate}
        onSuccess={onSuccessCreate}
      />
      <ImportProducts activeTab={activeTab} showId={showId} onClose={handleClose} onImported={handleImported} />
    </div>
  )
}

export default memo(CreateProductTypeSelector)
