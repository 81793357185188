import { Popconfirm } from 'antd'
import { useCallback, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type ConfirmProps = {
  children: ReactNode
  message: string
  confirmActionLabel?: string
  cancelActionLabel?: string
  onConfirm?: () => void
  onCancel?: () => void
}

const Confirm = (props: ConfirmProps) => {
  const { t } = useTranslation()

  const {
    message,
    onConfirm = () => undefined,
    onCancel = () => undefined,
    confirmActionLabel = t('commonConfirm'),
    cancelActionLabel = t('commonCancel'),
    children,
  } = props

  const handleConfirm = useCallback(() => {
    onConfirm()
  }, [onConfirm])

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  // TODO: Replace usage of Antd Popconfirm with a custom one
  return (
    <Popconfirm
      cancelText={cancelActionLabel}
      className="confirm"
      icon={false}
      okText={confirmActionLabel}
      placement="top"
      title={message}
      cancelButtonProps={{
        style: {
          borderRadius: 8,
          height: 40,
          padding: 8,
        },
      }}
      okButtonProps={{
        style: {
          borderRadius: 8,
          height: 40,
          padding: 8,
        },
      }}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      {children}
    </Popconfirm>
  )
}

export default Confirm
