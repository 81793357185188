import { memo } from 'react'

import './LiveIndicator.scss'

export interface LiveIndicatorProps {
  isLive: boolean
  className?: string
}

const LiveIndicator = (props: LiveIndicatorProps) => {
  const { isLive, className } = props
  const classNames = `live-indicator ${isLive ? 'is-live' : ''} ${className || ''}`.trim()

  return <span className={classNames}></span>
}

export default memo(LiveIndicator)
