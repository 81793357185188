import dayjs from 'dayjs'
import { useMemo } from 'react'

import { uniqBy } from '@/util/lodash-replacer'

import { useGetAllShowsQuery } from '../Shows/operations.generated'

import type { ShowItemFragment } from '../Shows/operations.generated'

export const useShows = () => {
  const { data: showsData, loading: loadingShows } = useGetAllShowsQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
  const loading = loadingShows

  const shows: Array<ShowItemFragment> = useMemo(() => {
    let shows: Array<ShowItemFragment> = []

    if (showsData?.viewer?.showsByStartAtDescForSeller?.edges) {
      shows = showsData?.viewer?.showsByStartAtDescForSeller?.edges.map((edge) => edge.node)
    }
    return uniqBy(shows, (show) => show.legacyId)
  }, [showsData])

  const highlightedShow: ShowItemFragment | null = useMemo(() => {
    const maybeLiveShow: ShowItemFragment | undefined = shows.find((show) => show.isBroadcasting)
    const maybeNextShow: ShowItemFragment | undefined = shows
      .filter((show) => dayjs(show.startAt).isAfter(new Date()))
      .sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime())
      .pop()

    return maybeLiveShow ?? maybeNextShow ?? null
  }, [shows])

  return { shows, highlightedShow, loading }
}
