import { message } from 'antd'
import { useMemo } from 'react'

import {
  useDemoteUserFromModeratorMutation,
  useGetModeratorsQuery,
  usePromoteUserToModeratorMutation,
} from '@/modules/moderators/graphql/operations.generated'
import { trackEvent } from '@/util/eventTracker'

export interface Moderator {
  id: string
  legacyId: number
  username: string
  avatarWebPUrl?: string | null
}

export const useSellerModerators = () => {
  const { data, refetch } = useGetModeratorsQuery({ fetchPolicy: 'cache-and-network' })
  const [handlePromoteUserToModerator] = usePromoteUserToModeratorMutation()
  const [handleDemoteUserToModerator] = useDemoteUserFromModeratorMutation()

  const promoteUserToModerator = async (userId: string) => {
    trackEvent('SHOW_PROMOTE_TO_MODERATOR_CLICKED')
    const res = await handlePromoteUserToModerator({ variables: { input: { userId } } })

    if (res.data?.promoteUserToModerator.ok) {
      await refetch()

      message.success('User was successfully added as moderator')
    }
  }

  const demoteUserFromModerator = async (userId: string) => {
    trackEvent('SHOW_DEMOTE_FROM_MODERATOR_CLICKED')
    const res = await handleDemoteUserToModerator({ variables: { input: { userId } } })

    if (res.data?.demoteUserFromModerator.ok) {
      await refetch()

      message.success('User was successfully demoted as moderator')
    }
  }

  const moderators: Array<Moderator> = useMemo(() => {
    if (!data?.viewer?.allModerators?.length) return []

    return data.viewer.allModerators.map((moderator) => ({
      id: moderator.id,
      legacyId: moderator.legacyId,
      username: moderator.username,
      avatarWebPUrl: moderator.avatarWebPUrl,
    }))
  }, [data])

  return { moderators, demoteUserFromModerator, promoteUserToModerator }
}
