import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaMobileScreen } from 'react-icons/fa6'
import QRCode from 'react-qr-code'

import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import Link from '@/components/ui/Link/Link'

import './OpenShowInApp.scss'

// const IN_APP_SHOW_BASE_BASEL_URL = `https://voggt.com/shows/`
const IN_APP_PROFILE_BASE_URL = `https://voggt.com/me/account`

type OpenShowInAppProps = {
  showId: number
}

const OpenShowInApp = (props: OpenShowInAppProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- remove this once the / stream link is implemented
  const { showId } = props

  const { t } = useTranslation()

  const [isStartDialogOpen, setIsStartDialogOpen] = useState(false)

  const handleStartShowClick = useCallback(() => {
    setIsStartDialogOpen(true)
  }, [])

  const handleCloseStartShowDialog = useCallback(() => {
    setIsStartDialogOpen(false)
  }, [])

  // const inAppShowUrl = `${IN_APP_SHOW_BASE_BASEL_URL}${showId}`
  const inAppProfileUrl = `${IN_APP_PROFILE_BASE_URL}`

  return (
    <>
      <Button
        className="open-show-in-app-action secondary"
        icon={<FaMobileScreen className="phone" />}
        label={t('showOpenInAppButtonLabel')}
        tooltip={t('showOpenInAppButtonTitle')}
        tooltipPosition="bottom left"
        onClick={handleStartShowClick}
      />
      <Dialog
        className="start-show-in-app-dialog"
        isOpen={isStartDialogOpen}
        modal={true}
        title={t('showOpenInAppDialogTitle')}
        onClose={handleCloseStartShowDialog}
      >
        {/* <p>{t('showOpenInAppQrCodeIntroMessage')}</p> */}
        <p>{t('showOpenInAppQrCodeIntroMessageToProfile')}</p>
        <QRCode className="qr-code" value={inAppProfileUrl} />
        <span className="or">{t('commonOrLabel')}</span>
        <p>{t('showOpenInAppDirectLinkIntroMessage')}</p>
        <Link className="open-in-app-action primary" href={inAppProfileUrl} rel="alternate noreferrer" target="_blank">
          {t('showOpenInAppDirectLinkLabel')}
        </Link>
      </Dialog>
    </>
  )
}

export default memo(OpenShowInApp)
