import { useCallback, useEffect, useState } from 'react'

import { Dimensions, ShippingLabelFormat } from './Components/Dimensions'
import { QuoteShipping } from './Components/Quotes'

import type { ShippingMethod } from '../ShippingModal'
import type {
  GetSellerShipmentsFieldsFragment,
  ShipmentFieldsFragment,
  UnShippedShipmentsFieldsFragment,
} from '@/views/Shipments/operations.generated'
import type { ShipmentDimension } from '@/views/Shipments/utils/shipments.util'

type EasyShippingProps = {
  defaultShippingProvider: ShippingMethod
  onComplete: () => void
  shipment: UnShippedShipmentsFieldsFragment | ShipmentFieldsFragment | GetSellerShipmentsFieldsFragment
  onDispatching: () => void
  hasAlreadyShipped?: boolean
}

export const EasyShipping = (props: EasyShippingProps) => {
  const { defaultShippingProvider, onComplete, shipment, onDispatching, hasAlreadyShipped } = props

  const [step, setStep] = useState<number>(1)
  const [shipmentDimensions, setShipmentDimensions] = useState<ShipmentDimension>()
  const [formatLabel, setFormatLabel] = useState<ShippingLabelFormat>(ShippingLabelFormat.A4)

  useEffect(() => {
    setStep(1)
  }, [shipment])

  const handleBack = useCallback(() => {
    setStep(1)
  }, [step])

  const handleNext = useCallback(
    (weight: number, height?: number | null, width?: number | null, depth?: number | null, format?: string | null) => {
      setShipmentDimensions({
        weight,
        height: height || undefined,
        width: width || undefined,
        depth: depth || undefined,
      })
      setFormatLabel((format as ShippingLabelFormat) || ShippingLabelFormat.A4)
      setStep(2)
    },
    [step]
  )

  return step === 1 ? (
    <Dimensions defaultShippingProvider={defaultShippingProvider} onSubmit={handleNext} />
  ) : (
    <QuoteShipping
      defaultShippingProvider={defaultShippingProvider}
      dimensions={shipmentDimensions}
      formatLabel={formatLabel}
      hasAlreadyShipped={hasAlreadyShipped}
      shipment={shipment}
      onBack={handleBack}
      onComplete={onComplete}
      onDispatching={onDispatching}
    />
  )
}
