import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackError } from '@/util/sentry'

import { useGeneratePackingSlipForShipmentsMutation } from './operations.generated'

interface CreatePackingSlipButtonProps {
  shipmentId: string
}

export const CreatePackingSlipButton = ({ shipmentId }: CreatePackingSlipButtonProps) => {
  const { t } = useTranslation()

  const [isClicked, setIsClicked] = useState(false)
  const [generatePackingSlipForShipments] = useGeneratePackingSlipForShipmentsMutation()

  const handleClick = async () => {
    setIsClicked(true)
    await generatePackingSlipForShipments({
      variables: { input: { shipmentIds: [shipmentId] } },
      onCompleted: async (data) => {
        window.open(data.generatePackingSlipForShipments.url, '_blank')
      },
      onError: (err) => {
        trackError(err, {
          meta: {
            feature: 'shipments.components.CreatePackingSlipButton',
            shipmentId,
          },
        })
        notificationDanger(err.message)
      },
    })
    setIsClicked(false)
  }

  return (
    <Button
      className="create-packing-slip-action secondary"
      disabled={isClicked}
      label={t('downloadPackingSlipButtonLabel')}
      tooltip={t('packingSlipDownloadTitle')}
      tooltipPosition="bottom right"
      onClick={handleClick}
    />
  )
}
