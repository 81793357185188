import { useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import type { Currency } from '@/network/graphql/types.generated'

export interface ShowsPanelHeaderProps {
  showCount: number
  currency: Currency
  netIncome: number
}

export const ShowsPanelHeader = ({ showCount, netIncome, currency }: ShowsPanelHeaderProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics } = sellerConfig || {}

  return (
    <div className="flex text-sm items-center h-full divide-x divide-slate-200 shows-list-header">
      <div className="flex gap-2 justify-center px-4">
        <p className="m-0 font-semibold">{t('ShowPanelHeaderShows')}</p>
        <p className="m-0">{showCount}</p>
      </div>

      {canAccessHomeMetrics && (
        <div className="flex gap-2 justify-center px-2 shows-list-header">
          <p className="m-0 font-semibold">{t('ShowPanelHeaderNetIncome')}</p>
          <p className="m-0">{currencyFormatter(netIncome, currency)}</p>
        </div>
      )}
    </div>
  )
}
