import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerProfessionalStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerProfessionalStatusQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, isProfessionalSeller: boolean } | null };


export const GetSellerProfessionalStatusDocument = gql`
    query GetSellerProfessionalStatus {
  viewer {
    id
    isProfessionalSeller
  }
}
    `;

/**
 * __useGetSellerProfessionalStatusQuery__
 *
 * To run a query within a React component, call `useGetSellerProfessionalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerProfessionalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerProfessionalStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerProfessionalStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>(GetSellerProfessionalStatusDocument, options);
      }
export function useGetSellerProfessionalStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>(GetSellerProfessionalStatusDocument, options);
        }
export function useGetSellerProfessionalStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>(GetSellerProfessionalStatusDocument, options);
        }
export type GetSellerProfessionalStatusQueryHookResult = ReturnType<typeof useGetSellerProfessionalStatusQuery>;
export type GetSellerProfessionalStatusLazyQueryHookResult = ReturnType<typeof useGetSellerProfessionalStatusLazyQuery>;
export type GetSellerProfessionalStatusSuspenseQueryHookResult = ReturnType<typeof useGetSellerProfessionalStatusSuspenseQuery>;
export type GetSellerProfessionalStatusQueryResult = Apollo.QueryResult<GetSellerProfessionalStatusQuery, GetSellerProfessionalStatusQueryVariables>;