import { Trans, useTranslation } from 'react-i18next'

import { getFromArrayLocalStorage, setToArrayLocalStorage } from '@/helpers/localstorage'

import Alert from '../ui/Alert/Alert'

interface FraudFightingBannerProps {
  showId: number
  category: string | undefined
}

const FASHION_CATEGORY_SLUG = 'fashion'

export const FraudFightingBanner = (props: FraudFightingBannerProps) => {
  const { t } = useTranslation()
  const { showId, category } = props

  if (category?.toLocaleLowerCase() !== FASHION_CATEGORY_SLUG.toLocaleLowerCase()) return null

  const localStorageKey = 'hasSeenFraudFightingBanner'
  const dismissed = !!getFromArrayLocalStorage(localStorageKey, showId)

  const handleClick = () => {
    setToArrayLocalStorage(localStorageKey, showId)
  }

  return (
    <Alert className="fraud-fighting-alert" dismissed={dismissed} type="info" dismissible onDismiss={handleClick}>
      <span>
        <Trans
          i18nKey="fraudFightingBannerText"
          components={{
            1: (
              <a
                className="underline inline text-semibold"
                href={t('fraudFightingBannerUniversityLink')}
                target="blank"
              ></a>
            ),
          }}
        />
      </span>
    </Alert>
  )
}
