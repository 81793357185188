export interface EmptyStateProps {
  title: string
  subtitle?: string
}

export const EmptyState = (props: EmptyStateProps) => {
  const { title, subtitle } = props
  return (
    <div className="flex flex-column gap-4 items-center justify-center py-4">
      <img src="/badges/empty-viewers.png" style={{ width: 40 }} />
      <div className="px-4">
        <h1 className="mb-0 text-base">{title}</h1>
        {subtitle && <p className="text-slate-400 text-sm m-0">{subtitle}</p>}
      </div>
    </div>
  )
}
