import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import Link from '@/components/ui/Link/Link'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import type { Currency } from '@/network/graphql/types.generated'

import './style.scss'

interface SellerPayoutInfoProps {
  commission: number | null
  fixedFee: number | null
  isProSeller?: boolean
  currency: Currency
  isFrenchSeller: boolean
}

const SellerPayoutInfo = (props: SellerPayoutInfoProps) => {
  const { commission, fixedFee, isProSeller, currency, isFrenchSeller } = props
  const currencyFormatter = useCurrencyFormatter()
  const { t } = useTranslation()

  const FALLBACK_PAYOUT_VALUE = t('commonNotAvailableValue')

  const commissionValue = commission !== null ? `${commission}%` : FALLBACK_PAYOUT_VALUE

  let fixedFeeValue = fixedFee !== null ? currencyFormatter(fixedFee, currency) : FALLBACK_PAYOUT_VALUE

  if (isFrenchSeller) {
    fixedFeeValue += ' TTC'
  }

  const isProSellerValue = (): string => {
    if (isProSeller !== undefined) {
      return isProSeller ? t('payoutSellerInfoStatusProfessional') : t('payoutSellerInfoStatusIndividual')
    }
    return FALLBACK_PAYOUT_VALUE
  }

  const moreInfoLink = t('payoutSellerInfoMoreLinkHref')

  return (
    <Card className="seller-payout-info" title={t('payoutSellerInfoTitle')}>
      <div className="flex justify-between py-2">
        <p className="m-0 text-slate-600">{t('payoutSellerInfoCommissionRateLabel')}</p>
        <p className="text-black font-semibold m-0">{commissionValue}</p>
      </div>
      <div className="flex justify-between py-2">
        <p className="m-0 text-slate-600">{t('payoutSellerInfoFixedFeeLabel')}</p>
        <p className="text-black font-semibold m-0">{fixedFeeValue}</p>
      </div>
      <div className="flex justify-between py-2">
        <p className="m-0 text-slate-600">{t('payoutSellerInfoIsProLabel')}</p>
        <p className="text-black font-semibold m-0">{isProSellerValue()}</p>
      </div>
      <div className="become-pro-seller">
        <Link
          className="become-pro-seller-action secondary"
          href={t('becomeProSellerTypeformLink')}
          rel="no-follow noreferrer"
          target="_blank"
          tooltip={isProSeller ? t('updateYourCompanyInfoMoreInfoText') : t('becomeProSellerTypeformPopover')}
          tooltipPosition="bottom right"
        >
          {isProSeller ? t('updateYourCompanyInfoLinkLabel') : t('becomeProSellerTypeformText')}
        </Link>
      </div>
      <div className="flex justify-between pt-2 pb-0">
        <Link href={moreInfoLink} rel="noopener noreferrer" target="_blank">
          {t('payoutSellerInfoMoreLinkText')}
        </Link>
      </div>
    </Card>
  )
}

export default SellerPayoutInfo
