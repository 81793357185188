import { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import Confirm from '@/components/Confirm/Confirm'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useStopReactionCounterInShowMutation } from '@/views/Show/operations.generated'
import { useOnReactionShowFeedItemSubscription } from '@/views/Shows/operations.generated'

import type { Dispatch, SetStateAction } from 'react'

import './FlameCounter.scss'

interface FlameCounterProps {
  setFlameCounterDisabled: Dispatch<SetStateAction<boolean>>
  showId: string
}

export const FlameCounter = memo((props: FlameCounterProps) => {
  const { setFlameCounterDisabled, showId } = props
  const { t } = useTranslation()
  const [stopFlameCounter] = useStopReactionCounterInShowMutation()
  const [counterEnabled, setCounterEnabled] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOnReactionShowFeedItemSubscription({
    ignoreResults: true,
    variables: { showId },
    onData: ({ data }) => {
      const feedItem = data?.data?.showFeedItemAdded?.feedItem
      if (feedItem && feedItem.__typename === 'ReactionFeedItem') {
        if (feedItem.reactionCounterStatus === 'STARTING') {
          setCounterEnabled(true)
          setFlameCounterDisabled(true)
          if (ref.current) {
            ref.current.textContent = '🔥 0'
          }
        } else if (feedItem.reactionCounterStatus === 'STOPPING') {
          setCounterEnabled(false)
          setFlameCounterDisabled(false)
        } else if (feedItem.reactionCounterStatus === 'OFF' && counterEnabled) {
          setCounterEnabled(false)
          setFlameCounterDisabled(false)
        } else if (feedItem.reactionCounterStatus === 'ON' && feedItem.reactionCounter) {
          if (!counterEnabled) {
            setCounterEnabled(true)
            setFlameCounterDisabled(true)
          }
          if (ref.current) {
            ref.current.textContent = '🔥 ' + feedItem.reactionCounter
          }
        }
      }
    },
  })

  const triggerStopFlameCounter = useCallback(() => {
    stopFlameCounter({
      variables: {
        input: {
          showId,
          emoji: '🔥',
        },
      },
      onError: (error) => {
        notificationDanger(error?.message)
      },
      onCompleted: () => {
        notificationSuccess(t('flameCounterStopSuccess'))
      },
    })
  }, [])

  if (!counterEnabled) return null

  return (
    <Card className="flame-counter">
      <h2>{t('flameCounterTitle')}</h2>
      <div className="flame-counter-details">
        <span ref={ref} />
        <Confirm message={t('flameCounterStopConfirm')} onConfirm={triggerStopFlameCounter}>
          <Button icon={<FaXmark />} />
        </Confirm>
      </div>
    </Card>
  )
})
