import { RightOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'

import Empty from '@/components/Empty/Empty'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { CustomerWithUnshippedOrderedProductsSort } from '@/network/graphql/types.generated'
import { useGetCustomersWithUnshippedOrdersQuery } from '@/views/Shipments/operations.generated'

const LastShippingCard = () => {
  const { t } = useTranslation()

  const { data, loading } = useGetCustomersWithUnshippedOrdersQuery({
    variables: {
      first: 40,
      sort: CustomerWithUnshippedOrderedProductsSort.CreatedAtDateDesc,
    },
    fetchPolicy: 'cache-first',
  })

  const ORDER_IS_READY_THRESHOLD = 2
  const ORDER_IS_LATE_THRESHOLD = 5

  const allCustomersWithLateUnshippedOrderedProducts = data?.customersWithUnshippedOrderedProducts?.edges.filter(
    (customer) => {
      return dayjs().diff(customer.oldestOrderedProductDate, 'day') > ORDER_IS_READY_THRESHOLD
    }
  )

  return (
    <Card title={t('widgetLateShippingTitle')}>
      <div className="h-64 overflow-auto">
        {loading && (
          <div className="flex items-center h-full">
            <Loader />
          </div>
        )}

        {!loading && allCustomersWithLateUnshippedOrderedProducts?.length === 0 ? (
          <div className="flex items-center h-full">
            <Empty description={t('widgetLateShippingEmpty')} type="files" />
          </div>
        ) : (
          <div className="grid divide-y divide-slate-100">
            {allCustomersWithLateUnshippedOrderedProducts?.map((customer) => {
              const { username } = customer.node
              const isLate = dayjs().diff(customer.oldestOrderedProductDate, 'day') > ORDER_IS_LATE_THRESHOLD
              const color = isLate ? '#994C4C' : 'green'
              const backgroundColor = isLate ? '#FFB3B3' : '#D9F7BE'

              return (
                <HashLink key={customer.node.id} to={`/shipments`}>
                  <div className={`flex items-center justify-between py-4`}>
                    <div className="flex justify-between items-center gap-4 h-full">
                      <div
                        className="w-[56px] py-1 items-center text-[12px] text-center justify-center rounded-full h-full"
                        style={{ color, backgroundColor }}
                      >
                        {isLate ? t('widgetLateShippingLateStatus') : t('widgetLateShippingReadyStatus')}
                      </div>
                      <Typography.Text className="w-28" strong underline>
                        #{username}
                      </Typography.Text>
                      <div>{dayjs(customer.oldestOrderedProductDate).format('DD/MM/YYYY')}</div>
                    </div>
                    <RightOutlined />
                  </div>
                </HashLink>
              )
            })}
          </div>
        )}
      </div>
    </Card>
  )
}

export default LastShippingCard
