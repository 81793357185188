import { DownloadOutlined } from '@ant-design/icons'
import { DatePicker, Radio } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

import ShowSelect from '@/components/ShowSelect'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import Config from '@/config/config'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { triggerDownloadForFile } from '@/util/download-files'
import {
  useCreateOrdersCsvForDateRangeMutation,
  useCreateOrdersCsvForShowMutation,
} from '@/views/OrdersExport/operations.generated'

import type moment from 'moment'
import type { Moment } from 'moment'
import type { SyntheticEvent } from 'react'

import './style.scss'

const { VITE_APP_LONG_TIMEOUT } = Config

const isDateRangeValid = (dates: any): dates is [moment.Moment, moment.Moment] =>
  dates !== null && dates[0] !== null && dates[1] !== null

type DateRangeItem = Moment | null

const OrdersExport = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  useDocumentTitle(t('ordersExportPageTitle'))

  const [method, setMethod] = useState<'byDates' | 'byShow'>('byDates')

  const [dateRange, setDateRange] = useState<[DateRangeItem, DateRangeItem] | null>([null, null])
  const [showId, setShowId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [createOrdersExportByDateRange] = useCreateOrdersCsvForDateRangeMutation()
  const [createOrdersExportForShow] = useCreateOrdersCsvForShowMutation()

  const isExportByDates = method === 'byDates'

  const exportByDates = async () => {
    if (!isDateRangeValid(dateRange)) {
      return
    }

    setIsLoading(true)

    const after = dateRange[0].startOf('D').toDate()
    const before = dateRange[1].endOf('D').toDate()
    const { data } = await createOrdersExportByDateRange({
      variables: { after, before, language },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
    })
    const dateFormat = 'YYYY-MM-DD'
    const filename = `orders-${dateRange[0].format(dateFormat)}-${dateRange[1].format(dateFormat)}.csv`

    return { data, filename }
  }

  const exportByShow = async () => {
    if (!showId) {
      return null
    }

    setIsLoading(true)

    const { data } = await createOrdersExportForShow({ variables: { showId, language } })
    const filename = `orders-${showId}.csv`

    return { data, filename }
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    const { data, filename } = (isExportByDates ? await exportByDates() : await exportByShow()) || {}

    if (!data) {
      setIsLoading(false)
      return null
    }

    triggerDownloadForFile(data.export.fileURL, filename)
    setIsLoading(false)
  }

  const isDisabled =
    isLoading || (method === 'byDates' && !isDateRangeValid(dateRange)) || (method === 'byShow' && !showId)

  return (
    <ViewContainer
      leftContent={
        <>
          <Link className="arrow-back" to="/shipments">
            <FaArrowLeft />
          </Link>
          {t('ordersExportPageTitle')}
        </>
      }
    >
      <Card className="orders-csv-export" title={t('ordersExportSectionTitle')}>
        <form className="export-orders-form" onSubmit={handleSubmit}>
          <div className="field method-field">
            <label className="label">{t('ordersExportMethodLabel')}</label>
            <Radio.Group
              buttonStyle="solid"
              optionType="button"
              value={method}
              options={[
                { label: t('ordersExportByDatesRangeOptionLabel'), value: 'byDates' },
                { label: t('ordersExportByShowOptionLabel'), value: 'byShow' },
              ]}
              onChange={(e) => setMethod(e.target.value)}
            />
          </div>
          {isExportByDates && (
            <div className="field period-field">
              <label className="label">{t('ordersExportByDatesRangeOptionLabel')}</label>
              <DatePicker.RangePicker
                className="period-"
                value={dateRange}
                allowClear
                onChange={(dates) => setDateRange(dates)}
              />
            </div>
          )}
          {!isExportByDates && (
            <div className="field show-field">
              <label className="label">{t('ordersExportByShowOptionLabel')}</label>
              <ShowSelect
                className="show-select"
                placeholder={t('ordersExportShowSelectPlaceholder')}
                value={showId}
                onChange={(showId) => setShowId(showId)}
              />
            </div>
          )}
          <div className="actions">
            <Button
              className="action validate-action primary"
              disabled={isDisabled}
              htmlType="submit"
              icon={<DownloadOutlined />}
              isLoading={isLoading}
              label={t('ordersCsvExportForActionLabel')}
            />
          </div>
        </form>
      </Card>
    </ViewContainer>
  )
}

export default OrdersExport
