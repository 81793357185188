import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import {
  FaArrowUp,
  FaBell,
  FaGavel,
  // FaThumbtack
} from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import ColumnImageAndName from '@/components/ProductsColumns/ColumnImageAndName'
import Link from '@/components/ui/Link/Link'
import Popover from '@/components/ui/Popover/Popover'
import { useShow } from '@/contexts/show/Show.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { ProductSortColumn, SortDirection, ProductType } from '@/network/graphql/types.generated'

import Card from '../../ui/Card/Card'
import Table from '../../ui/Table/Table'
import DuplicateButton from '../DuplicateButton/DuplicateButton'
import FollowsAndAutoBidsCount from '../FollowsAndAutoBidsCounts/FollowsAndAutoBidsCounts'
import LaunchActivityButton from '../LaunchActivityButton/LaunchActivityButton'

import type { Product } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './Products.scss'

interface ProductsProps {
  onProductEditClick: (product: Product) => void
  onProductDuplicateClick: (product: Product) => void
  handleUpdateProductToFirstPosition: (productId: string, productType: ProductType) => Promise<void>
  onSelectedRows: (selectedProducts: string[]) => void
  selectedRows: string[]
  isActivityRunning: boolean
  openFlashSaleModal: (product: Product) => void
  isShowBroadcasting?: boolean
}

const sortingOptionsMap: { [key: string]: ProductSortColumn } = {
  name: ProductSortColumn.Name,
  fixedAmount: ProductSortColumn.FixedAmount,
  followingUsersCount: ProductSortColumn.FollowingUsersCount,
  availableQuantity: ProductSortColumn.AvailableQuantity,
  createdAt: ProductSortColumn.CreatedAt,
}
function InstantBuyProducts(props: ProductsProps) {
  const {
    itemsPerPage,
    handleSetItemsPerPage,
    loadingInstantBuyProducts,
    instantBuys,
    totalInstantBuys,
    fetchInstantBuyProducts,
    setInstantBuySortBy,
    instantBuySortBy,
    currentPageInstantBuy: currentPage,
    handleInstantBuyPageChange,
  } = useShow()

  const {
    selectedRows,
    isActivityRunning,
    isShowBroadcasting,
    handleUpdateProductToFirstPosition,
    onProductEditClick,
    onProductDuplicateClick,
    onSelectedRows,
    openFlashSaleModal,
  } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const [sorting, setSorting] = useState<SortingState>([])
  const [loading, setLoading] = useState(false)

  const onHandleUpdateProductToFirstPosition = async (id: string) => {
    await handleUpdateProductToFirstPosition(id, ProductType.InstantBuy)
    handleInstantBuyPageChange(1)
  }

  const handleQuickAdd = () => {
    const currentUrl = window.location.pathname
    const url = `${currentUrl}products/new?productType=instantBuy`
    navigate(url)
  }

  const instantBuyColumns = [
    {
      header: t('instantBuyProductNameColumn'),
      id: 'name',
      accessorKey: 'name',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { name, images, description, color, id } = record
        return (
          <ColumnImageAndName
            color={color}
            description={description}
            id={id}
            image={images?.[0]?.webPUrl}
            name={name}
            onClick={() => onProductEditClick(record)}
          />
        )
      },
    },
    {
      header: t('instantBuyProductFixedAmountColumn'),
      id: 'fixedAmount',
      accessorKey: 'fixedAmount',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { fixedAmount, currency } = record
        return (
          <div className="column-amount" onClick={() => onProductEditClick(record)}>
            <span className="amount">{currencyFormatter(fixedAmount, currency)}</span>
          </div>
        )
      },
    },
    {
      header: (
        <div className="column-quantity">
          {t('instantBuyProductQuantityColumn')}
          <Popover
            content={
              <div className="following-user-tooltip">
                <p>
                  <FaBell className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverProductFollowersNotice')}
                </p>
                <p>
                  <FaGavel className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverAutoBidNotice')}
                </p>
              </div>
            }
          >
            <FaInfoCircle />
          </Popover>
        </div>
      ),
      id: 'availableQuantity',
      accessorKey: 'availableQuantity',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { availableQuantity, followingUsersCount } = record
        return (
          <div className="column-quantity" onClick={() => onProductEditClick(record)}>
            <span className={`quantity ${availableQuantity === 0 && 'sold-out'}`}>{availableQuantity}</span>
            <FollowsAndAutoBidsCount followingUsersCount={followingUsersCount} />
          </div>
        )
      },
    },
    {
      header: t('auctionLaunchColumn'),
      id: 'launch',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { status, id } = record

        const isDisabled = record.availableQuantity === 0 || !isShowBroadcasting || isActivityRunning

        return (
          <div className="column-action">
            <Popover content={t('showProductsListPinnedItemTooltip')}>
              <span className="pin">
                <FaArrowUp className="pin" onClick={() => onHandleUpdateProductToFirstPosition(id)} />
              </span>
            </Popover>
            <DuplicateButton product={record} onProductDuplicateClick={onProductDuplicateClick} />
            <LaunchActivityButton
              disabled={isDisabled}
              product={record}
              status={status}
              disabledReason={
                record.availableQuantity === 0
                  ? 'no-quantity'
                  : isActivityRunning
                    ? 'activity-running'
                    : 'not-broadcasting'
              }
              onLaunch={openFlashSaleModal}
            />
          </div>
        )
      },
    },
  ]

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    handleSetItemsPerPage(itemsPerPage)
    handleInstantBuyPageChange(1)
  }

  const handlePageChange = async (page: number) => {
    handleInstantBuyPageChange(page)
    setLoading(true)
    await fetchInstantBuyProducts(page)
    setLoading(false)
  }

  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setLoading(true)
    handleInstantBuyPageChange(1)
    if (!sort[0]) {
      setInstantBuySortBy(undefined)
    } else {
      setInstantBuySortBy({
        column: sortingOptionsMap[sort[0]?.id],
        direction: sort[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (instantBuySortBy === undefined) {
      setSorting([])
    }
  }, [instantBuySortBy])

  return (
    <Card className="instant-buy-products products-list" noPadding={true}>
      {instantBuys && instantBuys.length === 0 && totalInstantBuys === 0 && !loadingInstantBuyProducts ? (
        <div className="no-products">
          <svg fill="none" height="66" viewBox="0 0 66 66" width="66" xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              d="M50.457 17.0999C53.889 18.6599 56.047 21.9099 56.567 26.2519C56.775 27.8898 56.723 29.6318 56.489 31.3738C55.761 36.5998 53.239 41.8257 49.885 44.4518C47.337 46.4277 44.087 47.3898 40.785 47.8318C38.783 48.1178 36.755 48.1958 34.857 48.1958C34.181 48.1958 33.505 48.1958 32.855 48.1698C28.071 48.0397 21.987 47.4938 17.593 44.3738C11.6919 40.1885 12.3667 31.2989 12.9647 23.4218L12.965 23.4179C12.9726 23.319 12.9803 23.2202 12.988 23.1216C13.1879 20.552 13.3781 18.1083 13.303 15.9299C13.407 12.6799 10.027 12.0299 9.63696 11.9779C8.59696 11.7959 7.86896 10.8079 8.02496 9.74186C8.20696 8.70186 9.19496 7.99986 10.235 8.12986C12.679 8.49386 17.229 10.5739 17.203 15.7739C17.203 15.9046 17.2052 16.0352 17.2084 16.1657C17.2209 16.6748 17.6537 17.0811 18.1624 17.0585C23.1715 16.8297 28.1568 16.6019 33.167 16.4239C35.975 16.3199 38.757 16.2419 41.565 16.1899C41.799 16.1899 42.0265 16.1834 42.254 16.1769C42.4815 16.1704 42.709 16.1639 42.943 16.1639L43.0384 16.1607C45.38 16.0833 47.9945 15.9969 50.457 17.0999Z"
              fill="black"
              fillRule="evenodd"
              opacity="0.4"
            />
            <path
              d="M19.7342 51.3341C17.8778 51.3341 16.3672 52.8421 16.3672 54.6985C16.3672 56.5549 17.8778 58.0655 19.7342 58.0655C21.5906 58.0655 23.0986 56.5549 23.0986 54.6985C23.0986 52.8421 21.5906 51.3341 19.7342 51.3341Z"
              fill="black"
            />
            <path
              d="M46.5949 51.3341C44.7385 51.3341 43.2305 52.8421 43.2305 54.6985C43.2305 56.5549 44.7385 58.0655 46.5949 58.0655C48.4513 58.0655 49.9619 56.5549 49.9619 54.6985C49.9619 52.8421 48.4513 51.3341 46.5949 51.3341Z"
              fill="black"
            />
            <path
              d="M34.6482 30.2043H41.8502C42.9422 30.2043 43.8002 29.3203 43.8002 28.2543C43.8002 27.1883 42.9422 26.3042 41.8502 26.3042H34.6482C33.5822 26.3042 32.6982 27.1883 32.6982 28.2543C32.6982 29.3203 33.5822 30.2043 34.6482 30.2043Z"
              fill="black"
            />
          </svg>

          <p>{t('noInstantBuyProducts')}</p>
          <Link onClick={handleQuickAdd}>{t('quickAddInstantBuyProduct')}</Link>
        </div>
      ) : (
        <Table
          columns={instantBuyColumns}
          data={instantBuys}
          loading={loadingInstantBuyProducts || loading}
          pagination={{
            total: totalInstantBuys,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: handlePageChange,
            onItemPerPageChange: handleItemPerPageChange,
          }}
          selectable={{
            selectedRows,
            onSelectedRowsChange: onSelectedRows,
          }}
          sort={{
            sorting,
            onSortingChange: handleSortingChange,
          }}
          onQuickAdd={handleQuickAdd}
        />
      )}
    </Card>
  )
}

export default memo(InstantBuyProducts)
