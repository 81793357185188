const DuplicateIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10.1404 4.06725C10.2506 4.06693 10.3405 3.97707 10.3336 3.86704C10.2514 2.54431 9.34836 1.66669 8.03976 1.66669H4.57979C3.19979 1.66669 2.27979 2.63335 2.27979 4.06669V8.52669C2.27979 9.86589 3.16469 10.8299 4.45965 10.9198C4.56984 10.9275 4.65979 10.8372 4.65979 10.7267V7.47335C4.65979 5.50669 6.04645 4.07335 7.95976 4.07335L10.1404 4.06725Z"
        fill="#222C36"
        fillOpacity="0.8"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.4233 5.0755H7.95992C6.58593 5.0755 5.6626 6.03883 5.6626 7.47285V11.9362C5.6626 13.3695 6.58593 14.3335 7.95992 14.3335H11.4226C12.7973 14.3335 13.7206 13.3695 13.7206 11.9362V7.47285C13.7206 6.03883 12.7973 5.0755 11.4233 5.0755Z"
        fill="#222C36"
        fillOpacity="0.8"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default DuplicateIcon
