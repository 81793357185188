import { type TFunction } from 'i18next'
import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

export enum TCGCardLanguage {
  ENGLISH = 'english-us',
  FRENCH = 'french',
  GERMAN = 'german',
  ITALIAN = 'italian',
  SPANISH = 'spanish',
  JAPANESE = 'japanese',
  TRADITIONAL_CHINESE = 'traditional-chinese',
  SIMPLIFIED_CHINESE = 'simplified-chinese',
  OTHER = 'other',
}

export const computeCardLanguageFieldOptions = (t: TFunction<'translation', undefined>) => {
  return [
    { value: '' },
    { value: TCGCardLanguage.ENGLISH, label: t('productCardLanguageEnglishOptionLabel') },
    { value: TCGCardLanguage.FRENCH, label: t('productCardLanguageFrenchOptionLabel') },
    { value: TCGCardLanguage.GERMAN, label: t('productCardLanguageGermanOptionLabel') },
    { value: TCGCardLanguage.ITALIAN, label: t('productCardLanguageItalianOptionLabel') },
    { value: TCGCardLanguage.SPANISH, label: t('productCardLanguageSpanishOptionLabel') },
    { value: TCGCardLanguage.JAPANESE, label: t('productCardLanguageJapaneseOptionLabel') },
    { value: TCGCardLanguage.TRADITIONAL_CHINESE, label: t('productCardLanguageTraditionalChineseOptionLabel') },
    { value: TCGCardLanguage.SIMPLIFIED_CHINESE, label: t('productCardLanguageSimplifiedChineseOptionLabel') },
    { value: TCGCardLanguage.OTHER, label: t('productCardLanguageOtherOptionLabel') },
  ]
}

type ProductCardLanguageFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductCardLanguageField = (props: ProductCardLanguageFieldProps) => {
  const { value, onChange, disabled = false } = props
  const { t } = useTranslation()

  const options = useMemo(() => computeCardLanguageFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormCardLanguageLabel')}
      name="card-language"
      options={options}
      placeholder={t('productFormCardLanguagePlaceholder')}
      type="select"
      value={value}
      onChange={onChange}
    />
  )
}

export default ProductCardLanguageField
