import { memo, useEffect } from 'react'

import type { ReactionFragment } from '../../../../../Shows/operations.generated'

interface ReactionItemProps {
  reactionId: ReactionFragment['id']
  reactionEmoji: ReactionFragment['emoji']
  removeReaction: (reactionId: ReactionFragment['id']) => void
}

const ANIMATION_DURATION = 2000

export const ReactionItem = memo(({ reactionId, reactionEmoji, removeReaction }: ReactionItemProps) => {
  // Reaction expiration management
  useEffect(() => {
    const timer = setTimeout(() => {
      removeReaction(reactionId) // Notify parent component that this reaction has expired
    }, ANIMATION_DURATION)

    return () => clearTimeout(timer)
  }, [reactionId, removeReaction])

  return (
    <div
      className="absolute animate-fireup text-xl"
      style={{ left: `${Math.floor(Math.random() * 90)}%`, bottom: '40px' }}
    >
      {reactionEmoji}
    </div>
  )
})
