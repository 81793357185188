import { useState, useCallback, useEffect, type MouseEvent } from 'react'
import { FaEyeLowVision, FaTrash } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader'

import type { ProductImage } from '@/components/ProductPicturesForm/product-pictures.service'

import './ProductImageItem.scss'

export interface ProductPictureItemProps {
  url: ProductImage['url']
  onViewImage?: (url: string) => void
  onDeleteImage?: (url: string) => void
  innerRef?: React.Ref<HTMLLIElement>
  disabled?: boolean
}

const ProductPictureItem = (props: ProductPictureItemProps) => {
  const {
    url,
    onDeleteImage = () => undefined,
    onViewImage = () => undefined,
    innerRef,
    disabled = false,
    ...otherProps
  } = props

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleImageClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      onViewImage(url)
    },
    [url, onViewImage]
  )

  const handleDeleteClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onDeleteImage(url)
    },
    [url, onDeleteImage]
  )

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setIsError(false)
      setIsLoading(false)
    }
    img.onerror = () => {
      setIsError(true)
      setIsLoading(false)
    }
    img.src = url
  }, [url])

  return (
    <li
      ref={innerRef}
      className={`product-image-item ${isLoading ? 'is-loading' : ''} ${isError ? 'is-error' : ''}`}
      {...otherProps}
    >
      {isLoading && <Loader />}
      {!isLoading && isError && <FaEyeLowVision />}
      {!isLoading && !isError && <img src={url} onClick={handleImageClick} />}
      <Button
        className="action delete-action delete-image-action"
        disabled={disabled}
        icon={<FaTrash />}
        onClick={handleDeleteClick}
      />
    </li>
  )
}

export default ProductPictureItem
