import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { trackEvent } from '@/util/eventTracker'
import { useBundleShipmentMutation, useGetShipmentByIdLazyQuery } from '@/views/Shipments/operations.generated'

import type { ShipmentFieldsFragment } from '@/views/Shipments/operations.generated'

interface BundleUnshippedProductsButtonProps {
  customerId: string
  selectedOrders: string[]
  onComplete: (shipment: ShipmentFieldsFragment) => void
}

export const ShipSomeButton = (props: BundleUnshippedProductsButtonProps) => {
  const { t } = useTranslation()
  const { customerId, selectedOrders, onComplete } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [bundleShipment] = useBundleShipmentMutation()
  const [getShipment] = useGetShipmentByIdLazyQuery()

  const handleBulkShipClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!selectedOrders.length) return

    setIsLoading(true)
    const { data } = await bundleShipment({
      variables: {
        input: {
          customerId: `User|${customerId}`,
          orderedProductIds: selectedOrders,
        },
      },
      onCompleted: () => {
        trackEvent('SHIPMENT_BUNDLE_ORDERS')
      },
      onError: (error) => {
        notificationDanger(error.message)
      },
    })
    if (!data) return
    const { data: getShipmentData } = await getShipment({
      variables: {
        nodeId: data.bundleShipment.shipment.id,
      },
      onError: (error) => {
        notificationDanger(error.message)
        setIsLoading(false)
      },
    })
    if (getShipmentData?.node.__typename === 'Shipment') {
      onComplete(getShipmentData.node)
    }
    trackEvent('SHIPMENT_SHIP_SELECTION_BUTTON_CLICK')
  }

  return (
    <Button
      className="ship-action bulk-ship-action secondary"
      disabled={!selectedOrders.length}
      isLoading={isLoading}
      label={t('bulkShipSelectedOrdersForCustomerButtonLabel', { count: selectedOrders.length })}
      tooltip={t('bulkShipSelectedOrdersForCustomerButtonTitle')}
      tooltipPosition="bottom left"
      onClick={handleBulkShipClick}
    />
  )
}
