import { useMemo, useState, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'
import { useLocation, useNavigate } from 'react-router-dom'

import { CreateShow } from '@/components/CreateShow/CreateShow'
import CreateShowButton from '@/components/CreateShowButton/CreateShowButton'
import { BreakerCannotCreateShow } from '@/components/SellerTier/BreakerCannotCreateShow/BreakerCannotCreateShow'
import Button from '@/components/ui/Button/Button'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'

import { ShopsList } from '../Shows/components/ShowList/ShopsList'

import { useShops } from './hooks/use-shops'

const CSVHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'Date', key: 'date' },
  { label: 'Shop', key: 'name' },
  { label: 'Shop total', key: 'total' },
  { label: 'Commission', key: 'commission' },
  { label: 'Refunded', key: 'refunded' },
  { label: 'Shipping label', key: 'shipping' },
  { label: 'Net revenue', key: 'revenue' },
  { label: 'Currency', key: 'currency' },
]

export const Shops = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const isQueryParamOpenModal = query.get('action') === 'create'

  const [isShopCreationOpen, setIsShopCreationOpen] = useState<boolean>(isQueryParamOpenModal)

  const { shops, loading } = useShops()

  useDocumentTitle(t('sideMenuMyShopsTitle'))

  useEffect(() => {
    if (isQueryParamOpenModal) navigate('/shops')
  }, [isShopCreationOpen])

  const CSVData = useMemo(() => {
    return shops.map((s) => ({
      id: s.legacyId,
      date: s.startAt,
      name: s.name,
      total: s.totalAmountOrdersSuccessInCurrencyCents / 100,
      commission: s.totalCommissionInCurrencyCents / 100,
      shipping: s.totalDeductedShippingCostInCurrencyCents / 100,
      revenue: s.totalNetIncomeInCurrencyCents / 100,
      refunded: s.totalAmountRefundedInCurrencyCents / 100,
      currency: s.currency,
    }))
  }, [shops])

  return (
    <ViewContainer
      isLoading={loading}
      leftContent={t('shopsPageHeaderTitle')}
      rightContent={<CreateShowButton handleOnClick={() => setIsShopCreationOpen(true)} isShop={true} />}
    >
      {isShopCreationOpen && <CreateShow isShop={true} onClose={() => setIsShopCreationOpen(false)} />}

      {!isMobileOnly ? (
        <>
          <div className="w-full mb-4">
            <BreakerCannotCreateShow className="mb-4" />
          </div>

          <div className="w-full">
            <p className="text-lg font-semibold mb-2">{t('ShowsListMyShops')}</p>

            <div className="w-full layout-card">
              <div className="p-4 flex items-center justify-between border-b border-slate-200">
                <CSVLink
                  className="cursor-pointer flex items-center gap-1"
                  data={CSVData}
                  filename={`shops-export-${new Date().getTime()}`}
                  headers={CSVHeaders}
                >
                  <Button
                    className="export-shows-action"
                    icon={<FaDownload className="h-[16x] w-[16px]" />}
                    label={t('ShowsListExportMe')}
                  />
                </CSVLink>
              </div>
              <ShopsList />
            </div>
          </div>
        </>
      ) : (
        <>
          <BreakerCannotCreateShow className="mb-2" />

          <ShopsList />
        </>
      )}
    </ViewContainer>
  )
}
