import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerAllRatingsFromCustomersQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSellerAllRatingsFromCustomersQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, ratingsFromCustomers: { __typename?: 'RatingFromCustomerConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'RatingFromCustomerEdge', node: { __typename?: 'RatingFromCustomer', createdAt: Date, rating: number, ratingComment?: string | null, customer: { __typename?: 'User', id: string, username: string, avatarWebPUrl?: string | null } } }> } } | null };


export const GetSellerAllRatingsFromCustomersDocument = gql`
    query GetSellerAllRatingsFromCustomers($first: Int!, $offset: Int) {
  viewer {
    id
    ratingsFromCustomers(first: $first, offset: $offset) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on RatingFromCustomer {
            createdAt
            customer {
              id
              username
              avatarWebPUrl
            }
            rating
            ratingComment
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSellerAllRatingsFromCustomersQuery__
 *
 * To run a query within a React component, call `useGetSellerAllRatingsFromCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerAllRatingsFromCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerAllRatingsFromCustomersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSellerAllRatingsFromCustomersQuery(baseOptions: Apollo.QueryHookOptions<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables> & ({ variables: GetSellerAllRatingsFromCustomersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>(GetSellerAllRatingsFromCustomersDocument, options);
      }
export function useGetSellerAllRatingsFromCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>(GetSellerAllRatingsFromCustomersDocument, options);
        }
export function useGetSellerAllRatingsFromCustomersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>(GetSellerAllRatingsFromCustomersDocument, options);
        }
export type GetSellerAllRatingsFromCustomersQueryHookResult = ReturnType<typeof useGetSellerAllRatingsFromCustomersQuery>;
export type GetSellerAllRatingsFromCustomersLazyQueryHookResult = ReturnType<typeof useGetSellerAllRatingsFromCustomersLazyQuery>;
export type GetSellerAllRatingsFromCustomersSuspenseQueryHookResult = ReturnType<typeof useGetSellerAllRatingsFromCustomersSuspenseQuery>;
export type GetSellerAllRatingsFromCustomersQueryResult = Apollo.QueryResult<GetSellerAllRatingsFromCustomersQuery, GetSellerAllRatingsFromCustomersQueryVariables>;