import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import Tabs from '@/components/ui/Tabs/Tabs'

import RevenuesNetPerMonth from '../charts/RevenuesNetPerMonth'
import ShowSum from '../charts/ShowShum'
import { useShows } from '../useShows'

const MetricsCard = () => {
  const { t } = useTranslation()
  const { loading, shows } = useShows()
  const items = [
    {
      key: '1',
      title: t('homeShowsNetIncomeTab'),
      content: <RevenuesNetPerMonth shows={shows} />,
    },
    {
      key: '2',
      title: t('homeShowsNbPerMonthTab'),
      content: <ShowSum shows={shows} />,
    },
  ]

  return (
    <Card title="Metrics">
      <div className="text-black-160 h-72">
        {loading ? (
          <div className="flex items-center h-full">
            <Loader />
          </div>
        ) : (
          <Tabs tabs={items} />
        )}
      </div>
    </Card>
  )
}

export default MetricsCard
