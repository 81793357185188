const GreenTickIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12.5466 14.6936L12.3481 15.0453H11.5728L11.3392 14.7351C11.3254 14.7139 9.95275 12.6628 8.1786 11.5403L7.59244 11.1711L8.33275 10.0006L8.91706 10.3699C10.1955 11.1776 11.2571 12.3573 11.8829 13.1446C12.866 11.6631 15.1681 8.57724 18.6989 6.07478C17.0494 3.98678 14.5008 2.64001 11.6401 2.64001C6.67768 2.64001 2.64014 6.67755 2.64014 11.64C2.64014 16.6025 6.67768 20.64 11.6401 20.64C16.6026 20.64 20.6401 16.6025 20.6401 11.64C20.6401 10.0366 20.2137 8.53385 19.4761 7.22862C15.0149 10.4096 12.5734 14.6465 12.5466 14.6936Z"
        fill="#08A798"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default GreenTickIcon
