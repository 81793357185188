import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerSubAccountsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSellerSubAccountsQuery = { __typename?: 'Query', sellerSubAccounts: { __typename?: 'SellerSubAccountConnection', totalCount: number, edges: Array<{ __typename?: 'SellerSubAccountEdge', node: { __typename?: 'SellerSubAccount', id: string, email: string, createdAt: Date, lastLoginAt?: Date | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };

export type CreateSellerSubAccountMutationVariables = Types.Exact<{
  input: Types.CreateSellerSubAccountInput;
}>;


export type CreateSellerSubAccountMutation = { __typename?: 'Mutation', createSellerSubAccount: { __typename?: 'CreateSellerSubAccountPayload', sellerSubAccount: { __typename?: 'SellerSubAccount', id: string } } };

export type DeleteSellerSubAccountMutationVariables = Types.Exact<{
  input: Types.DeleteSellerSubAccountInput;
}>;


export type DeleteSellerSubAccountMutation = { __typename?: 'Mutation', deleteSellerSubAccount: { __typename?: 'DeleteSellerSubAccountPayload', ok: boolean } };


export const GetSellerSubAccountsDocument = gql`
    query GetSellerSubAccounts($first: Int!, $offset: Int) {
  sellerSubAccounts(first: $first, offset: $offset) {
    edges {
      node {
        id
        email
        createdAt
        lastLoginAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useGetSellerSubAccountsQuery__
 *
 * To run a query within a React component, call `useGetSellerSubAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerSubAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerSubAccountsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSellerSubAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables> & ({ variables: GetSellerSubAccountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>(GetSellerSubAccountsDocument, options);
      }
export function useGetSellerSubAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>(GetSellerSubAccountsDocument, options);
        }
export function useGetSellerSubAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>(GetSellerSubAccountsDocument, options);
        }
export type GetSellerSubAccountsQueryHookResult = ReturnType<typeof useGetSellerSubAccountsQuery>;
export type GetSellerSubAccountsLazyQueryHookResult = ReturnType<typeof useGetSellerSubAccountsLazyQuery>;
export type GetSellerSubAccountsSuspenseQueryHookResult = ReturnType<typeof useGetSellerSubAccountsSuspenseQuery>;
export type GetSellerSubAccountsQueryResult = Apollo.QueryResult<GetSellerSubAccountsQuery, GetSellerSubAccountsQueryVariables>;
export const CreateSellerSubAccountDocument = gql`
    mutation CreateSellerSubAccount($input: CreateSellerSubAccountInput!) {
  createSellerSubAccount(input: $input) {
    sellerSubAccount {
      id
    }
  }
}
    `;
export type CreateSellerSubAccountMutationFn = Apollo.MutationFunction<CreateSellerSubAccountMutation, CreateSellerSubAccountMutationVariables>;

/**
 * __useCreateSellerSubAccountMutation__
 *
 * To run a mutation, you first call `useCreateSellerSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSellerSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSellerSubAccountMutation, { data, loading, error }] = useCreateSellerSubAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSellerSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateSellerSubAccountMutation, CreateSellerSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSellerSubAccountMutation, CreateSellerSubAccountMutationVariables>(CreateSellerSubAccountDocument, options);
      }
export type CreateSellerSubAccountMutationHookResult = ReturnType<typeof useCreateSellerSubAccountMutation>;
export type CreateSellerSubAccountMutationResult = Apollo.MutationResult<CreateSellerSubAccountMutation>;
export type CreateSellerSubAccountMutationOptions = Apollo.BaseMutationOptions<CreateSellerSubAccountMutation, CreateSellerSubAccountMutationVariables>;
export const DeleteSellerSubAccountDocument = gql`
    mutation DeleteSellerSubAccount($input: DeleteSellerSubAccountInput!) {
  deleteSellerSubAccount(input: $input) {
    ok
  }
}
    `;
export type DeleteSellerSubAccountMutationFn = Apollo.MutationFunction<DeleteSellerSubAccountMutation, DeleteSellerSubAccountMutationVariables>;

/**
 * __useDeleteSellerSubAccountMutation__
 *
 * To run a mutation, you first call `useDeleteSellerSubAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSellerSubAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSellerSubAccountMutation, { data, loading, error }] = useDeleteSellerSubAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSellerSubAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSellerSubAccountMutation, DeleteSellerSubAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSellerSubAccountMutation, DeleteSellerSubAccountMutationVariables>(DeleteSellerSubAccountDocument, options);
      }
export type DeleteSellerSubAccountMutationHookResult = ReturnType<typeof useDeleteSellerSubAccountMutation>;
export type DeleteSellerSubAccountMutationResult = Apollo.MutationResult<DeleteSellerSubAccountMutation>;
export type DeleteSellerSubAccountMutationOptions = Apollo.BaseMutationOptions<DeleteSellerSubAccountMutation, DeleteSellerSubAccountMutationVariables>;