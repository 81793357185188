import { useEffect, useRef } from 'react'

type ContenteditableProps = {
  value?: string | number
  onChange: (value: string | number) => void
}

const Contenteditable = (props: ContenteditableProps) => {
  const { value, onChange } = props
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    ref.current.textContent = value?.toString() ?? ''
  }, [value])

  return (
    <div
      ref={ref}
      contentEditable="true"
      onInput={(event) => {
        // @ts-expect-error: fix this
        onChange(event.target.textContent)
      }}
    />
  )
}

export default Contenteditable
