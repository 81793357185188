import Alert from '@/components/ui/Alert/Alert'

type CreateOrEditProductFormErrorsProps = {
  error: string
}

const CreateOrEditProductFormError = (props: CreateOrEditProductFormErrorsProps) => {
  const { error } = props

  if (!error) return null

  return (
    <Alert className="errors" emphasis="high" type="danger">
      <p dangerouslySetInnerHTML={{ __html: error }} className="error message"></p>
    </Alert>
  )
}

export default CreateOrEditProductFormError
