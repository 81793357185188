import User from '../../../../../../components/ui/User/User'
import { userIsPresent } from '../../chatbox.helper'
import { ViewerActionsDropdown } from '../ViewerActionsDropdown/ViewerActionsDropdown'

import type { Viewer } from '../../chatbox.helper'

export interface ShowViewerItemProps {
  viewer: Viewer
  isModerator: boolean
  viewers: Viewer[]
}

export const ShowViewerItem = ({ viewer, isModerator, viewers }: ShowViewerItemProps) => {
  const isPresent = userIsPresent(viewer, viewers)

  const showPresenceDot = isModerator && isPresent

  return (
    <div
      className={`flex items-center justify-between py-2 hover:bg-black-100 hover:bg-opacity-40 group/viewerOrComment`}
    >
      <div className="flex items-center gap-4">
        <User src={viewer.avatarWebPUrl} withDot={!!showPresenceDot}>
          {viewer.username}
        </User>
        <ViewerActionsDropdown viewer={viewer}></ViewerActionsDropdown>
      </div>
    </div>
  )
}
