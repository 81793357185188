export enum FashionProductColor {
  BLACK = 'black',
  GRAY = 'gray',
  WHITE = 'white',
  CREAM = 'cream',
  BEIGE = 'beige',
  APRICOT = 'apricot',
  ORANGE = 'orange',
  CORAL = 'coral',
  RED = 'red',
  BURGUNDY = 'burgundy',
  PINK = 'pink',
  PURPLE = 'purple',
  LILAC = 'lilac',
  LIGHT_BLUE = 'light-blue',
  BLUE = 'blue',
  NAVY = 'navy',
  TURQUOISE = 'turquoise',
  MINT = 'mint',
  GREEN = 'green',
  DARK_GREEN = 'dark-green',
  KHAKI = 'khaki',
  BROWN = 'brown',
  MUSTARD = 'mustard',
  YELLOW = 'yellow',
  SILVER = 'silver',
  GOLD = 'gold',
  MULTI = 'multi',
}

export const fashionProductColorsTranslationKeys = [
  'colorBlack',
  'colorGray',
  'colorWhite',
  'colorCream',
  'colorBeige',
  'colorApricot',
  'colorOrange',
  'colorCoral',
  'colorRed',
  'colorBurgundy',
  'colorPink',
  'colorPurple',
  'colorLilac',
  'colorLightBlue',
  'colorBlue',
  'colorNavy',
  'colorTurquoise',
  'colorMint',
  'colorGreen',
  'colorDarkGreen',
  'colorKhaki',
  'colorBrown',
  'colorMustard',
  'colorYellow',
  'colorSilver',
  'colorGold',
  'colorMulti',
]

export const acceptableColorBlackValues = ['black', 'noir', 'schwarz']
export const acceptableColorGrayValues = ['gray', 'gris', 'grau']
export const acceptableColorWhiteValues = ['white', 'blanc', 'weiß', 'weiss']
export const acceptableColorCreamValues = ['cream', 'crème', 'creme']
export const acceptableColorBeigeValues = ['beige']
export const acceptableColorApricotValues = ['apricot', 'abricot', 'aprikose']
export const acceptableColorOrangeValues = ['orange']
export const acceptableColorCoralValues = ['coral', 'corail', 'koralle']
export const acceptableColorRedValues = ['red', 'rouge', 'rot']
export const acceptableColorBurgundyValues = ['burgundy', 'bourgogne', 'burgund']
export const acceptableColorPinkValues = ['pink', 'rose', 'rosa']
export const acceptableColorPurpleValues = ['purple', 'violet', 'violett']
export const acceptableColorLilacValues = ['lilac', 'lilas', 'lila']
export const acceptableColorLightBlueValues = ['light blue', 'bleu clair', 'hellblau']
export const acceptableColorBlueValues = ['blue', 'bleu', 'blau']
export const acceptableColorNavyValues = ['navy', 'marine', 'marineblau']
export const acceptableColorTurquoiseValues = ['turquoise', 'türkis', 'turkis']
export const acceptableColorMintValues = ['mint', 'menthe', 'minze']
export const acceptableColorGreenValues = ['green', 'vert', 'grün', 'grun']
export const acceptableColorDarkGreenValues = ['dark green', 'vert foncé', 'vert fonce', 'dunkelgrün', 'dunkelgrun']
export const acceptableColorKhakiValues = ['khaki', 'kaki']
export const acceptableColorBrownValues = ['brown', 'marron', 'braun']
export const acceptableColorMustardValues = ['mustard', 'moutarde', 'senfgelb', 'senf']
export const acceptableColorYellowValues = ['yellow', 'jaune', 'gelb']
export const acceptableColorSilverValues = ['silver', 'argenté', 'argente', 'silber']
export const acceptableColorGoldValues = ['gold', 'doré', 'dore']
export const acceptableColorMultiValues = ['multi', 'multicolore']
