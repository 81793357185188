import { useCallback, useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getFashionCategoriesSlugs,
  getTcgCategoriesSlugs,
  isProductAGiveawayProduct,
  isProductAnAuctionProduct,
  isProductAnInstantBuyProduct,
  isProductFromFashionCategory,
  isProductFromSneakersCategory,
  isProductFromTCGCategory,
} from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import ProductBrandField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductBrandField/ProductBrandField'
import ProductColorField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductColorField/ProductColorField'
import ProductConditionField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductConditionField/ProductConditionField'
import ProductGenderField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductGenderField/ProductGenderField'
import ProductModelField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductModelField/ProductModelField'
import ProductSizeField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/fashion/ProductSizeField/ProductSizeField'
import ProductCardConditionField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/tcg/ProductCardConditionField/ProductCardConditionField'
import ProductCardGradeField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/tcg/ProductCardGradeField/ProductCardGradeField'
import ProductCardGradingServiceField, {
  PRODUCT_CARD_GRADING_SERVICE_UNGRADED,
} from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/tcg/ProductCardGradingServiceField/ProductCardGradingServiceField'
import ProductCardLanguageField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/tcg/ProductCardLanguageField/ProductCardLanguageField'
import ProductCardTypeField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/_productAttributes/tcg/ProductCardTypeField/ProductCardTypeField'
import CreateAndLaunchField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/CreateAndLaunchField/CreateAndLaunchField'
import CreateMoreField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/CreateMoreField/CreateMoreField'
import ProductAvailableQuantityField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductAvailableQuantityField/ProductAvailableQuantityField'
import ProductBuyNowPriceField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductBuyNowPriceField/ProductBuyNowPriceField'
import ProductCategoryField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductCategoryField/ProductCategoryField'
import ProductDescriptionField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductDescriptionField/ProductDescriptionField'
import ProductImagesField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductImagesField/ProductImagesField'
import ProductPriceField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductPriceField/ProductPriceField'
import ProductStartingPriceField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductStartingPriceField/ProductStartingPriceField'
import ProductTitleField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductTitleField/ProductTitleField'
import ProductTypeField from '@/components/CreateOrEditProduct/CreateOrEditProductForm/_fields/ProductTypeField/ProductTypeField'
import CreateOrEditProductFormError from '@/components/CreateOrEditProduct/CreateOrEditProductForm/CreateOrEditProductFormError/CreateOrEditProductFormError'
import ValidateProductFormButton from '@/components/CreateOrEditProduct/CreateOrEditProductForm/ValidateProductFormButton/ValidateProductFormButton'
import { PRODUCT_FORM_ACTION } from '@/components/CreateOrEditProduct/types'
import useParentCategories from '@/components/CreateOrEditProduct/useParentCategories'
import Form from '@/components/Form/Form'
import Alert from '@/components/ui/Alert/Alert'
import { useUser } from '@/contexts/user/User.context'
import { ProductType } from '@/network/graphql/types.generated'

import AudienceField from './_fields/AudienceField/AudienceField'
import InternationalField from './_fields/InternationalField/InternationalField'
import PreOrderField from './_fields/PreOrderField/PreOrderField'
import SalesTypeField from './_fields/SalesTypeField/SalesTypeField'

import type {
  ProductImage,
  ProductInputData,
  ExtraProductFormOptions,
  ProductFormValidity,
} from '@/components/CreateOrEditProduct/types'
import type { ShowGiveawayAudience, SalesType } from '@/network/graphql/types.generated'

import './CreateOrEditProductForm.scss'

type InputChangeEvent = ChangeEvent<HTMLInputElement>
type SelectChangeEvent = ChangeEvent<HTMLSelectElement>
type TextareaChangeEvent = ChangeEvent<HTMLTextAreaElement>

const initialValidity: ProductFormValidity = {
  category: false,
  title: false,
  type: false,
  startingPrice: false,
  buyNowPrice: false,
  price: false,
  availableQuantity: false,
}

// TODO: move this somewhere else?
const sanitizeNumberInputValue = (value: string | number) => Number(value.toString().replace(',', '.'))
const sanitizeAudienceValue = (value: string | undefined) => (value as ShowGiveawayAudience) || undefined

type CreateOrEditProductFormProps = {
  productId: string
  isShowBroadcasting?: boolean
  initialValues: ProductInputData
  isLoading?: boolean
  error: string
  onSubmit?: (input: ProductInputData & ExtraProductFormOptions) => void // TODO: improve type?
  showHasEnded?: boolean
  isInShow: boolean
  canProductBeUpdated: {
    canProductBeUpdated: boolean
    canProductBeRenamed: boolean
    canAmountQuantityOrTypeBeUpdated: boolean
  }
  giveawayDetails?: {
    giveawayAudience: ShowGiveawayAudience | undefined
    isGiveawayOpenToInternational: boolean
  }
}

const CreateOrEditProductForm = (props: CreateOrEditProductFormProps) => {
  const { t } = useTranslation()
  const {
    initialValues,
    isLoading = false,
    productId,
    error,
    showHasEnded = false,
    isInShow = false,
    canProductBeUpdated: canProductBeUpdatedData,
    giveawayDetails,
  } = props

  const { isShowBroadcasting = false, onSubmit = () => undefined } = props
  const { canProductBeUpdated, canProductBeRenamed, canAmountQuantityOrTypeBeUpdated } = canProductBeUpdatedData

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { isInventoryInitialized, canCreatePreOrders } = sellerConfig || {}

  const urlParams = new URLSearchParams(location.search)
  const action = (urlParams.get('action') as PRODUCT_FORM_ACTION) || undefined
  const isDuplicateAction = action === PRODUCT_FORM_ACTION.DUPLICATE
  const isEditMode = Boolean(productId && productId !== 'new' && !isDuplicateAction)

  const { parentCategories } = useParentCategories()
  const fashionCategoriesSlugs: string[] = useMemo(
    () => getFashionCategoriesSlugs(parentCategories),
    [parentCategories]
  )
  const tcgCategoriesSlugs: string[] = useMemo(() => getTcgCategoriesSlugs(parentCategories), [parentCategories])

  const [validity, setValidity] = useState<ProductFormValidity>(initialValidity)
  const [images, setImages] = useState<ProductImage[]>(initialValues.images)
  const [category, setCategory] = useState<string>(initialValues.category || '')
  const [title, setTitle] = useState<string>(initialValues.title || '')
  const [description, setDescription] = useState<string>(initialValues.description || '')
  const [brand, setBrand] = useState<string>(initialValues.brand || '')
  const [model, setModel] = useState<string>(initialValues.model || '')
  const [color, setColor] = useState<string>(initialValues.color || '')
  const [size, setSize] = useState<string>(initialValues.size || '')
  const [gender, setGender] = useState<string>(initialValues.gender || '')
  const [condition, setCondition] = useState<string>(initialValues.condition || '')
  const [cardCondition, setCardCondition] = useState<string>(initialValues.cardCondition || '')
  const [cardGrade, setCardGrade] = useState<string>(initialValues.cardGrade || '')
  const [cardGradingService, setCardGradingService] = useState<string>(initialValues.cardGradingService || '')
  const [cardLanguage, setCardLanguage] = useState<string>(initialValues.cardLanguage || '')
  const [cardType, setCardType] = useState<string>(initialValues.cardType || '')
  const [audience, setAudience] = useState<string>(initialValues.giveawayAudience || '')
  const [international, setInternational] = useState<boolean>(initialValues.isGiveawayOpenToInternational || false)

  const [type, setType] = useState<ProductType>(initialValues.type || '')
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  const [startingPrice, setStartingPrice] = useState<string>(initialValues.startingPrice?.toString() ?? '')
  const [buyNowPrice, setBuyNowPrice] = useState<string>(initialValues.buyNowPrice?.toString() ?? '')
  const [price, setPrice] = useState<string>(initialValues.price?.toString() ?? '')
  const [availableQuantity, setAvailableQuantity] = useState<number>(initialValues.availableQuantity)
  const [createAndLaunch, setCreateAndLaunch] = useState<boolean>(false)
  const [createMore, setCreateMore] = useState<boolean>(false)
  const [isPreOrder, setIsPreOrder] = useState<boolean>(initialValues.isPreOrder)
  const [salesType, setSalesType] = useState<SalesType | undefined>(initialValues.salesType ?? undefined)

  useEffect(() => {
    setAudience(giveawayDetails?.giveawayAudience || '')
    setInternational(giveawayDetails?.isGiveawayOpenToInternational || false)
  }, [giveawayDetails])

  const isAuctionProduct = isProductAnAuctionProduct(type)
  const isInstantBuyProduct = isProductAnInstantBuyProduct(type)
  const isGiveawayProduct = isProductAGiveawayProduct(type)

  const isSneakersProduct = useMemo(() => isProductFromSneakersCategory(category), [category])
  const isFashionProduct = useMemo(
    () => isProductFromFashionCategory(category, fashionCategoriesSlugs),
    [category, fashionCategoriesSlugs]
  )
  const isTcgProduct = useMemo(
    () => isProductFromTCGCategory(category, tcgCategoriesSlugs),
    [category, tcgCategoriesSlugs]
  )

  const updateTitleWithProductAttributes = useCallback(() => {
    if (isSneakersProduct) {
      setTitle([brand, model, color, size].filter(Boolean).join(' | '))
    } else if (isFashionProduct) {
      setTitle([brand, size, gender].filter(Boolean).join(' | '))
    }
  }, [brand, model, color, size, gender, isSneakersProduct, isFashionProduct])

  const handleImagesChange = useCallback((newImages: ProductImage[]) => setImages(newImages), [])
  const handleCategoryChange = useCallback((e: SelectChangeEvent) => setCategory(e.target.value), [])
  const handleTitleChange = useCallback((e: InputChangeEvent) => setTitle(e.target.value), [])
  const handleDescriptionChange = useCallback((e: TextareaChangeEvent) => setDescription(e.target.value), [])
  const handleBrandChange = useCallback((e: InputChangeEvent) => setBrand(e.target.value), [])
  const handleModelChange = useCallback((e: InputChangeEvent) => setModel(e.target.value), [])
  const handleColorChange = useCallback((e: SelectChangeEvent) => setColor(e.target.value), [])
  const handleSizeChange = useCallback((e: InputChangeEvent) => setSize(e.target.value), [])
  const handleGenderChange = useCallback((e: SelectChangeEvent) => setGender(e.target.value), [])
  const handleConditionChange = useCallback((e: SelectChangeEvent) => setCondition(e.target.value), [])
  const handleCardConditionChange = useCallback((e: SelectChangeEvent) => setCardCondition(e.target.value), [])
  const handleCardGradeChange = useCallback((e: SelectChangeEvent) => setCardGrade(e.target.value), [])
  const handleCardGradingServiceChange = useCallback(
    (e: SelectChangeEvent) => setCardGradingService(e.target.value),
    []
  )
  const handleCardLanguageChange = useCallback((e: SelectChangeEvent) => setCardLanguage(e.target.value), [])
  const handleCardTypeChange = useCallback((e: SelectChangeEvent) => setCardType(e.target.value), [])
  const handleTypeChange = useCallback((e: SelectChangeEvent) => setType(e.target.value as ProductType), [])
  const handleAudienceChange = useCallback(
    (e: SelectChangeEvent) => setAudience(e.target.value as ShowGiveawayAudience),
    []
  )

  const handleInternationalChange = useCallback((e: InputChangeEvent) => setInternational(e.target.checked), [])
  const handleStartingPriceChange = useCallback((e: InputChangeEvent) => setStartingPrice(e.target.value ?? ''), [])
  const handleBuyNowPriceChange = useCallback((e: InputChangeEvent) => setBuyNowPrice(e.target.value ?? ''), [])
  const handlePriceChange = useCallback((e: InputChangeEvent) => setPrice(e.target.value ?? ''), [])
  const handleAvailableQuantityChange = useCallback(
    (e: InputChangeEvent) => setAvailableQuantity(Number(e.target.value)),
    []
  )
  const handleCreateAndLaunchChange = useCallback((e: InputChangeEvent) => setCreateAndLaunch(e.target.checked), [])
  const handleCreateMoreChange = useCallback((e: InputChangeEvent) => setCreateMore(e.target.checked), [])
  const handleIsPreOrderChange = useCallback((e: InputChangeEvent) => setIsPreOrder(e.target.checked), [])
  const handleSalesTypeChange = useCallback((e: SelectChangeEvent) => setSalesType(e.target.value as SalesType), [])

  const handleValidityChange = useCallback((name: keyof ProductFormValidity, isValid: boolean) => {
    setValidity((prev) => ({ ...prev, [name]: isValid }))
  }, [])

  const handleSubmit = useCallback(async () => {
    const baseFields = { images, category, title, description, type, availableQuantity, isPreOrder, international }
    const fashionProductAttributes = { brand, model, color, size, gender, condition }
    const tcgProductAttributes = { cardCondition, cardGrade, cardGradingService, cardLanguage, cardType }
    const extraOptions = { createAndLaunch, createMore }

    const inputData = {
      ...baseFields,
      ...fashionProductAttributes,
      ...tcgProductAttributes,
      salesType,
      startingPrice: startingPrice.length ? sanitizeNumberInputValue(startingPrice) : undefined,
      buyNowPrice: buyNowPrice.length ? sanitizeNumberInputValue(buyNowPrice) : undefined,
      price: price.length ? sanitizeNumberInputValue(price) : undefined,
      availableQuantity,
      giveawayAudience: sanitizeAudienceValue(audience),
      isGiveawayOpenToInternational: international,
      ...extraOptions,
    }

    onSubmit(inputData)
  }, [
    audience,
    description,
    international,
    images,
    isPreOrder,
    salesType,
    title,
    type,
    // Fashion/sneakers attributes
    brand,
    model,
    color,
    size,
    gender,
    condition,
    // TCG attributes
    cardCondition,
    cardGrade,
    cardGradingService,
    cardLanguage,
    cardType,
    // Pricing & quantity
    startingPrice,
    buyNowPrice,
    price,
    availableQuantity,
    // Extra options
    createAndLaunch,
    createMore,
  ])

  const isMountedRef = useRef(false)
  useEffect(() => {
    // Prevent the first render from updating the title (to avoid replacing the initial value, especially when editing a fashion/sneakers product)
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return
    }

    updateTitleWithProductAttributes()
  }, [brand, model, color, size, gender])

  const hasRequiredBaseInfo = title && validity.availableQuantity
  const hasRequiredPricingInfo = (() => {
    if (isAuctionProduct) {
      return validity.startingPrice
    } else if (isInstantBuyProduct) {
      return validity.price
    } else {
      return true
    }
  })()
  const hasRequiredAttributes = (() => {
    if (isTcgProduct) {
      // The card grading service field is required only if the card grade is set
      return cardGradingService && cardGradingService !== PRODUCT_CARD_GRADING_SERVICE_UNGRADED ? !!cardGrade : true
    }
    return true
  })()
  const isValid = hasRequiredBaseInfo && hasRequiredPricingInfo && hasRequiredAttributes

  const shouldDisplayFashionOrSneakersAttributes = isFashionProduct || isSneakersProduct
  const shouldDisplayGenderField = isFashionProduct
  const shouldDisplayModelField = isSneakersProduct
  const shouldDisplayTcgAttributeFields = isTcgProduct
  const shouldDisplayCreateAndLaunchField = !isEditMode && !!isInShow
  const shouldDisplayCreateMoreField = !isEditMode
  const isCardGradeRequired = Boolean(
    cardGradingService && cardGradingService !== PRODUCT_CARD_GRADING_SERVICE_UNGRADED
  )

  const isCreateAndLaunchDisabled = !isShowBroadcasting
  const isCreateMoreDisabled = createAndLaunch && [ProductType.Giveaway, ProductType.InstantBuy].includes(type)

  return (
    <Form id="create-edit-product-form" onSubmit={handleSubmit}>
      {showHasEnded && <Alert type="danger">{t('productFormAlertShowEnded')}</Alert>}

      <ProductImagesField
        disabled={!canProductBeUpdated || !canProductBeRenamed || !isInventoryInitialized}
        value={images}
        onUpdateImages={handleImagesChange}
      />
      <ProductCategoryField
        disabled={!canProductBeUpdated || !canProductBeRenamed}
        value={category}
        onChange={handleCategoryChange}
        onValidityChange={handleValidityChange}
      />
      <ProductTitleField
        category={category}
        disabled={!canProductBeUpdated || !canProductBeRenamed || !isInventoryInitialized}
        value={title}
        onChange={handleTitleChange}
        // onValidityChange={handleValidityChange}
      />
      <ProductDescriptionField
        disabled={!canProductBeUpdated || !canProductBeRenamed || !isInventoryInitialized}
        value={description}
        onChange={handleDescriptionChange}
      />

      {shouldDisplayFashionOrSneakersAttributes && (
        <>
          <ProductBrandField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={brand}
            onChange={handleBrandChange}
          />
          <ProductSizeField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={size}
            onChange={handleSizeChange}
          />
          <ProductColorField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={color}
            onChange={handleColorChange}
          />
          {shouldDisplayGenderField && (
            <ProductGenderField
              disabled={!canProductBeUpdated || !isInventoryInitialized}
              value={gender}
              onChange={handleGenderChange}
            />
          )}
          {shouldDisplayModelField && (
            <ProductModelField
              disabled={!canProductBeUpdated || !isInventoryInitialized}
              value={model}
              onChange={handleModelChange}
            />
          )}
          <ProductConditionField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={condition}
            onChange={handleConditionChange}
          />
        </>
      )}

      {shouldDisplayTcgAttributeFields && (
        <>
          <ProductCardConditionField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={cardCondition}
            onChange={handleCardConditionChange}
          />
          <ProductCardLanguageField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={cardLanguage}
            onChange={handleCardLanguageChange}
          />
          <ProductCardTypeField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={cardType}
            onChange={handleCardTypeChange}
          />
          <ProductCardGradingServiceField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={cardGradingService}
            onChange={handleCardGradingServiceChange}
          />
          <ProductCardGradeField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            required={isCardGradeRequired}
            value={cardGrade}
            onChange={handleCardGradeChange}
          />
        </>
      )}

      <ProductTypeField
        disabled={!canProductBeUpdated || !canAmountQuantityOrTypeBeUpdated || !isInventoryInitialized}
        value={type}
        onChange={handleTypeChange}
        onValidityChange={handleValidityChange}
      />

      {isAuctionProduct && (
        <>
          <ProductStartingPriceField
            buyNowPrice={buyNowPrice}
            disabled={!canProductBeUpdated || !canAmountQuantityOrTypeBeUpdated || !isInventoryInitialized}
            value={startingPrice}
            onChange={handleStartingPriceChange}
            onValidityChange={handleValidityChange}
          />
          <ProductBuyNowPriceField
            disabled={!canProductBeUpdated || !canAmountQuantityOrTypeBeUpdated || !isInventoryInitialized}
            startingPrice={startingPrice}
            value={buyNowPrice}
            onChange={handleBuyNowPriceChange}
            onValidityChange={handleValidityChange}
          />
        </>
      )}
      {isInstantBuyProduct && (
        <ProductPriceField
          disabled={!canProductBeUpdated || !canAmountQuantityOrTypeBeUpdated || !isInventoryInitialized}
          value={price}
          onChange={handlePriceChange}
          onValidityChange={handleValidityChange}
        />
      )}
      {isGiveawayProduct && (
        <>
          <AudienceField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={audience}
            onChange={handleAudienceChange}
          />
          <InternationalField
            disabled={!canProductBeUpdated || !isInventoryInitialized}
            value={international}
            onChange={handleInternationalChange}
          />
        </>
      )}

      {type && (
        <ProductAvailableQuantityField
          disabled={!canProductBeUpdated || !canAmountQuantityOrTypeBeUpdated || !isInventoryInitialized}
          min={isEditMode ? 0 : 1}
          value={availableQuantity}
          onChange={handleAvailableQuantityChange}
          onValidityChange={handleValidityChange}
        />
      )}

      <SalesTypeField value={salesType} onChange={handleSalesTypeChange} />
      {canCreatePreOrders && <PreOrderField value={isPreOrder} onChange={handleIsPreOrderChange} />}

      <div className="actions">
        {!isInventoryInitialized ? (
          <Alert className="product-form-alert" type="warning">
            {t('productFormAlertInventory')}
          </Alert>
        ) : !canProductBeUpdated ? (
          <Alert className="product-form-alert" type="warning">
            {t('productFormAlertCannotUpdate')}
          </Alert>
        ) : !canAmountQuantityOrTypeBeUpdated ? (
          <Alert className="product-form-alert" type="warning">
            {t('productFormAlertCannotUpdateAmountQuantityOrType')}
          </Alert>
        ) : (
          !canProductBeRenamed && (
            <Alert className="product-form-alert" type="warning">
              {t('productFormAlertCannotRename')}
            </Alert>
          )
        )}
        <CreateOrEditProductFormError error={error} />

        {shouldDisplayCreateAndLaunchField && (
          <CreateAndLaunchField
            disabled={isCreateAndLaunchDisabled}
            value={createAndLaunch}
            onChange={handleCreateAndLaunchChange}
          />
        )}
        {shouldDisplayCreateMoreField && (
          <CreateMoreField disabled={isCreateMoreDisabled} value={createMore} onChange={handleCreateMoreChange} />
        )}

        <ValidateProductFormButton
          disabled={!isValid || showHasEnded || !canProductBeUpdated}
          isEditMode={isEditMode}
          isLoading={isLoading}
        />
      </div>
    </Form>
  )
}

export default CreateOrEditProductForm
