import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'
import Loader from '@/components/ui/Loader/Loader'
import { ShowPollStatus } from '@/network/graphql/types.generated'
import { trackError } from '@/util/sentry'
import ChatForm from '@/views/Show/components/ChatBox/components/Chat/ChatForm/ChatForm'
import ChatMoreOptions from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/ChatMoreOptions'
import { useCurrentPollQuery, useOnShowPollUpdateSubscription } from '@/views/Show/operations.generated'

import { useChatBoxContext } from '../../ChatBoxProvider'
import { useChat } from '../../hooks/useChat'
import Poll from '../Polls/Poll'

import { ChatMessageComponent } from './ChatMessageComponent'
import { FlameCounter } from './FlameCounter/FlameCounter'
import { ReactionItems } from './ReactionItems'

import './Chat.scss'

export const Chat = () => {
  const { t } = useTranslation()
  const { showId, isActivityRunning, isShowBroadcasting } = useChatBoxContext()
  const { chatItems, emoteMap, previousItemsLoaded } = useChat(showId)
  const [flameCounterDisabled, setFlameCounterDisabled] = useState(false)

  const showGlobalId = `Show|${showId}`
  const commentListRef = useRef<HTMLDivElement>(null)

  const {
    data: currentPollData,
    updateQuery: updatePollQuery,
    refetch: refetchCurrentPoll,
  } = useCurrentPollQuery({
    skip: !showId,
    variables: { showId: showGlobalId },
    onError: (currentPollError) => {
      trackError(currentPollError, {
        meta: { showId, scope: 'Show.useCurrentPollQuery' },
      })
    },
  })

  useOnShowPollUpdateSubscription({
    ignoreResults: true,
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: (data) => {
      // As the subscription fields are ShowPollEnd and ShowPollUserParticipation, we need to update the query manually and set the updated values in currentFlashSale
      updatePollQuery((prev) => {
        if (prev?.node.__typename === 'Show' && prev.node.currentShowPoll) {
          if (data.data.data?.showPollUpdate.__typename === 'ShowPollEnd') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentShowPoll: {
                  ...prev.node.currentShowPoll,
                  status: data.data.data?.showPollUpdate.status,
                },
              },
            }
            return newData
          } else if (data.data.data?.showPollUpdate.__typename === 'ShowPollUserParticipation') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentShowPoll: {
                  ...prev.node.currentShowPoll,
                  votes: data.data.data?.showPollUpdate.votes,
                },
              },
            }
            return newData
          }
        }
        return prev
      })
    },
  })

  const currentPoll =
    currentPollData?.node.__typename === 'Show' && currentPollData?.node.currentShowPoll
      ? currentPollData.node.currentShowPoll
      : null
  const currentPollStatus = currentPoll?.status

  const handleNewChatContentAdded = async () => {
    if (!currentPoll || !(currentPollStatus === ShowPollStatus.Opened)) {
      await refetchCurrentPoll()
    }
  }

  // Auto scroll to last message
  useEffect(() => {
    if (!commentListRef.current) return

    commentListRef.current.scrollTo({
      top: commentListRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }, [chatItems])

  const isPollDisabled = !!(currentPoll?.status === ShowPollStatus.Opened || isActivityRunning)
  const isRaidDisabled = !!isActivityRunning
  const shouldDisableChatAction = !isShowBroadcasting

  return (
    <div className="chat">
      <div className="chat-activities">
        <div className="chat-activities">{currentPoll && <Poll currentPoll={currentPoll} />}</div>
        <FlameCounter setFlameCounterDisabled={setFlameCounterDisabled} showId={showGlobalId} />
      </div>

      {!previousItemsLoaded && (
        <div className="flex flex-1 items-center justify-center">
          <Loader />
        </div>
      )}
      {previousItemsLoaded && (
        <div ref={commentListRef} className="chat-content">
          {chatItems.map((chatItem) => (
            <ChatMessageComponent key={chatItem.id} chatItem={chatItem} emoteMap={emoteMap} />
          ))}
          <ReactionItems showId={showGlobalId} />
        </div>
      )}

      <div className="chat-form-container">
        <NewFeatureIntroducer alreadySeenKey="hasSeenFlameCounter" content={<p>{t('flameCounterIntroducer')}</p>}>
          <ChatMoreOptions
            disabled={shouldDisableChatAction}
            flameCounterDisabled={flameCounterDisabled}
            pollDisabled={isPollDisabled}
            raidDisabled={isRaidDisabled}
            setFlameCounterDisabled={setFlameCounterDisabled}
            showId={showId}
            onNewContentAdded={handleNewChatContentAdded}
          />
        </NewFeatureIntroducer>

        <ChatForm showId={showId} />
      </div>
    </div>
  )
}
