const ChatIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9.91464 6.16497H5.82951C5.56455 6.16497 5.34951 5.94993 5.34951 5.68497C5.34951 5.42001 5.56455 5.20497 5.82951 5.20497H9.91464C10.1796 5.20497 10.3946 5.42001 10.3946 5.68497C10.3946 5.94993 10.1796 6.16497 9.91464 6.16497ZM8.17384 8.61231H5.82951C5.56455 8.61231 5.34951 8.39727 5.34951 8.13231C5.34951 7.86735 5.56455 7.65231 5.82951 7.65231H8.17384C8.4388 7.65231 8.65384 7.86735 8.65384 8.13231C8.65384 8.39727 8.4388 8.61231 8.17384 8.61231ZM11.1114 2.13745H4.63335C3.15495 2.13745 1.95239 3.34385 1.95239 4.82673V9.26767C1.95239 10.7512 3.15495 11.9576 4.63335 11.9576H5.31751C5.63879 11.9576 5.95367 12.0881 6.18023 12.316L7.08968 13.2293C7.2996 13.4392 7.57736 13.5544 7.8724 13.5544C8.16744 13.5544 8.44584 13.4392 8.65448 13.2293L9.56456 12.3166C9.79176 12.0881 10.1066 11.9576 10.4279 11.9576H11.1114C12.5892 11.9576 13.7924 10.7512 13.7924 9.26767V4.82673C13.7924 3.34385 12.5892 2.13745 11.1114 2.13745Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ChatIcon
