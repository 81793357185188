import { type ApolloClient, gql } from '@apollo/client'

import type {
  OnShowCurrentAuctionOrderAddedSubscription,
  OnShowCurrentAuctionStatusChangedSubscription,
} from './operations.generated'

export function replaceShowAuctionInCache(
  client: ApolloClient<object>,
  showGlobalId: string,
  {
    __typename,
    endAt,
    id,
    serverTime,
    status,
  }: OnShowCurrentAuctionStatusChangedSubscription['showCurrentAuctionStatusChanged']['auction']
) {
  client.writeQuery({
    query: gql`
      query WriteShowAuction($id: ID!) {
        node(id: $id) {
          __typename
          id
          ... on Show {
            currentAuction {
              __typename
              endAt
              id
              serverTime
              status
            }
          }
        }
      }
    `,
    data: {
      node: {
        __typename: 'Show',
        id: showGlobalId,
        currentAuction: {
          __typename,
          endAt,
          id,
          serverTime,
          status,
        },
      },
    },
    variables: {
      id: showGlobalId,
    },
  })
}

export function replaceShowAuctionOrderInCache(
  client: ApolloClient<object>,
  showGlobalId: string,
  {
    __typename: __typename,
    customer,
    id,
    paymentStatus,
  }: NonNullable<OnShowCurrentAuctionOrderAddedSubscription['showCurrentAuctionOrderAdded']['orderIfAuthorized']>
) {
  client.writeQuery({
    query: gql`
      query WriteOrderInShowAuction($id: ID!) {
        show: node(id: $id) {
          __typename
          id
          ... on Show {
            currentAuction {
              __typename
              id
              orderIfAuthorized {
                id
                paymentStatus
                customer {
                  username
                }
              }
            }
          }
        }
      }
    `,
    data: {
      show: {
        __typename: 'Show',
        id: showGlobalId,
        currentAuction: {
          __typename: 'ShowAuction',
          id: null,
          orderIfAuthorized: {
            __typename,
            id,
            paymentStatus,
            customer,
          },
        },
      },
    },
    variables: {
      id: showGlobalId,
    },
  })
}
