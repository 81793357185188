import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

import type { ChangeEvent } from 'react'

type ProductSizeFieldProps = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ProductSizeField = (props: ProductSizeFieldProps) => {
  const { value: size, onChange: handleSizeChange, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      disabled={disabled}
      label={t('productFormSizeLabel')}
      name="size"
      placeholder={t('productFormSizePlaceholder')}
      value={size}
      onChange={handleSizeChange}
    />
  )
}

export default ProductSizeField
