import { message } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
// @ts-expect-error no types provided by this lib, so we need to ignore the error since Vite build will fail
import Flippy, { FrontSide, BackSide } from 'react-flippy'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Card from '@/components/ui/Card/Card'
import VoggtLogo from '@/components/ui/VoggtLogo/VoggtLogo'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { useAppSelector } from '@/reducers'
import { logout, storeToken } from '@/reducers/authentication'
import { trackEvent } from '@/util/eventTracker'
import LoginForm from '@/views/Login/components/LoginForm/LoginForm'
import ResetPassword from '@/views/Login/components/ResetPassword/ResetPassword'
import SignUpLink from '@/views/Login/components/SignUpLink/SignUpLink'
import AppleSSOLoginButton from '@/views/Login/components/SSO/AppleSSOLoginButton/AppleSSOLoginButton'
import {
  useLoginMutation,
  useLoginWithFacebookSsoMutation,
  useLoginWithSsoMutation,
} from '@/views/Login/operations.generated'
import FacebookSSOLoginButton from 'src/views/Login/components/SSO/FacebookSSOLoginButton/FacebookSSOLoginButton'
import GoogleSSOLoginButton from 'src/views/Login/components/SSO/GoogleSSOLoginButton/GoogleSSOLoginButton'

import Vector1 from '../../assets/shapes/accessories/full/Vector-1.svg'
import Vector2 from '../../assets/shapes/apparels/full/Vector-6.svg'
import Vector3 from '../../assets/shapes/cards/full/Vector-6.svg'
import Vector4 from '../../assets/shapes/collectibles/full/Vector-7.svg'
import Vector5 from '../../assets/shapes/electronics/full/Vector-3.svg'
import Vector6 from '../../assets/shapes/nfts/full/Vector-3.svg'
import Vector7 from '../../assets/shapes/sneakers/full/Vector-7.svg'

import LoginSubAccount from './components/LoginSubAccount/LoginSubAccount'
import PaypalSSOButton from './components/SSO/PaypalSSOButton/PaypalSSOButton'

import './Login.scss'

type LoginProps = {
  redirectTo?: string
}

function Login(props: LoginProps) {
  const { redirectTo } = props

  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const flippableCardRef = useRef<any>(null)
  const navigate = useNavigate()
  const [backside, setBackside] = useState<'forgot-password' | 'sub-account'>('sub-account')

  useDocumentTitle(t('loginPageTitle'))

  const user = useAppSelector((state) => state.authentication.user)

  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onLoginError = useCallback(
    (error) => {
      // wrong credentials -> 401 -> network error
      setError(error.networkError?.result?.errors?.[0]?.message ?? error.message)
    },
    [setError]
  )

  const [loginMutation] = useLoginMutation({
    onCompleted: (data) => dispatch(storeToken(data.login)),
    onError: onLoginError,
  })
  const [loginWithSsoMutation] = useLoginWithSsoMutation({
    onCompleted: (data) => dispatch(storeToken(data.loginWithSso)),
    onError: onLoginError,
  })
  const [loginWithFacebookSsoMutation] = useLoginWithFacebookSsoMutation({
    onCompleted: (data) => dispatch(storeToken(data.loginWithFacebookSso)),
    onError: onLoginError,
  })

  const onLoginSuccess = useCallback(() => {
    if (!user) {
      return
    }

    if (!user.isSelected) {
      trackEvent('LOGIN_ATTEMPT_NOT_SELECTED_SELLER')
      message.error(t('loginSellerNotSelectedErrorMessage'))
      window.open(t('supportLinkArticleBecomeSeller'))
      dispatch(logout())
      return
    }

    trackEvent('LOGIN')
    return
  }, [dispatch, user])

  const onResponseSso = useCallback(
    (response: any) => {
      const idToken = response?.cred?.credential || response?.credential?.authorization.id_token

      if (!idToken) {
        return
      }

      setIsLoading(true)
      loginWithSsoMutation({ variables: { idToken } })
      setIsLoading(false)
    },
    [dispatch, loginWithSsoMutation]
  )

  const onFacebookResponseSso = useCallback(
    (response: any) => {
      const accessToken = response?.accessToken

      if (!accessToken) {
        return
      }

      setIsLoading(true)
      loginWithFacebookSsoMutation({ variables: { accessToken } })
      setIsLoading(false)
    },
    [dispatch, loginWithFacebookSsoMutation]
  )

  const onLogin = useCallback(
    async (credential: string, password: string) => {
      setIsLoading(true)
      await loginMutation({ variables: { credential, password } })
      setIsLoading(false)
    },
    [dispatch, t]
  )

  const handleResetPasswordCancel = useCallback(() => {
    flippableCardRef?.current?.toggle()
  }, [])

  const handleForgottenPassword = useCallback(() => {
    setBackside('forgot-password')
    flippableCardRef?.current?.toggle()
  }, [flippableCardRef])

  const handleSubAccountRequest = useCallback(() => {
    setBackside('sub-account')
    flippableCardRef?.current?.toggle()
  }, [flippableCardRef])

  useEffect(() => {
    navigate(redirectTo ? redirectTo : '/')
  }, [history])

  useEffect(() => {
    if (!user) {
      return
    }

    onLoginSuccess()
  }, [user])

  const flipCardStyles = { boxShadow: 'none' }

  return (
    <div className="login-page">
      {/* TODO: apply the following background via CSS */}
      <div className="visual-background">
        <img className="shape-1" src={Vector1} />
        <img className="shape-2" src={Vector2} />
        <img className="shape-3" src={Vector3} />
        <img className="shape-4" src={Vector4} />
        <img className="shape-5" src={Vector5} />
        <img className="shape-6" src={Vector6} />
        <img className="shape-7" src={Vector7} />
      </div>

      <VoggtLogo />

      <div className="sso-login-actions">
        <span className="label">{t('loginSsoActionsLabel')}</span>
        <AppleSSOLoginButton onClick={onResponseSso} />
        <GoogleSSOLoginButton onClick={onResponseSso} />
        <FacebookSSOLoginButton onClick={onFacebookResponseSso} />
        <PaypalSSOButton />
      </div>

      <Flippy ref={flippableCardRef} flipOnClick={false}>
        <FrontSide style={flipCardStyles}>
          <Card className="login">
            <LoginForm
              error={error}
              isLoading={isLoading}
              onForgottenPassword={handleForgottenPassword}
              onSubAccountRequest={handleSubAccountRequest}
              onSubmit={onLogin}
            />
          </Card>
        </FrontSide>
        <BackSide style={flipCardStyles}>
          {backside === 'forgot-password' ? (
            <ResetPassword onCancel={handleResetPasswordCancel} />
          ) : (
            <LoginSubAccount onCancel={handleResetPasswordCancel} />
          )}
        </BackSide>
      </Flippy>

      <SignUpLink />
    </div>
  )
}

export default Login
