import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { computeSellingModeOptions } from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct/types'

type ProductTypeFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductTypeField = (props: ProductTypeFieldProps) => {
  const { value: type, onChange: handleTypeChange, onValidityChange = () => undefined, disabled = false } = props
  const { t } = useTranslation()

  const typeOptions = useMemo(() => computeSellingModeOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormTypeLabel')}
      name="type"
      options={typeOptions}
      required={true}
      type="select"
      value={type}
      onChange={handleTypeChange}
      onValidityChange={(isValid: boolean) => onValidityChange('type', isValid)}
    />
  )
}

export default ProductTypeField
