import { useCallback, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { DEFAULT_COUNTRY_ID } from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import { trackEvent } from '@/util/eventTracker'
import BankInfoForm from '@/views/Account/components/BankInfo/BankInfoForm/BankInfoForm'
import {
  useGetSellerBankingInfoQuery,
  useTriggerSellerPhoneNumberVerificationMutation,
  useUpsertSellerBankingInfoMutation,
} from '@/views/Account/components/BankInfo/BankInfoForm/operations.generated'

import type { BankInfoInputData } from '@/views/Account/components/BankInfo/BankInfoForm/types'

import './bankInfos.scss'

const BankInfo = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const country = user?.sellerConfig?.country.iso2Code || DEFAULT_COUNTRY_ID

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [verifToken, setVerifToken] = useState<string>('')

  const { data, loading: isLoadingBankInfo = true, refetch } = useGetSellerBankingInfoQuery()
  const { sellerBankingInfo } = data?.sellerInfo || {}
  const { bankAccountName: bankName, bic, ibanFourLastDigits } = sellerBankingInfo || {}
  const initialValues = { bankName: '', iban: '', bic: '' }

  const [triggerSellerPhoneNumberVerification] = useTriggerSellerPhoneNumberVerificationMutation({
    onError: (error) => {
      setError(error.message)
    },
  })
  const [upsertSellerBankingInfo] = useUpsertSellerBankingInfoMutation({
    onError: (error) => {
      setError(error.message)
    },
  })

  const handleToggleEditMode = useCallback(() => {
    setIsEditMode((prev) => !prev)
  }, [])

  const handleCancel = useCallback(() => {
    setVerifToken('')
    setIsEditMode(false)
  }, [])

  const handleSubmit = useCallback(
    async (inputData: BankInfoInputData) => {
      setIsLoading(true)

      if (!inputData.verificationCode) {
        const { data: phoneVerifData } = await triggerSellerPhoneNumberVerification()
        const { phoneVerificationToken } = phoneVerifData?.triggerSellerPhoneNumberVerification || {}

        if (!phoneVerificationToken) {
          return
        }

        setVerifToken(phoneVerificationToken)
      } else {
        const { bankName: bankAccountName, iban, bic, verificationCode: phoneNumberVerificationCode } = inputData
        const input = { phoneNumberVerificationCode, iban, bic, country, bankAccountName }

        trackEvent('SELLER_UPDATING_HIS_IBAN', {
          userId: user?.id,
        })
        const { data: upsertSellerBankingInfoData } = await upsertSellerBankingInfo({
          variables: {
            phoneVerificationToken: verifToken,
            input,
          },
        })

        const isSuccess = upsertSellerBankingInfoData?.upsertSellerBankingInfo.ok || false

        if (isSuccess) {
          trackEvent('SELLER_UPDATED_HIS_IBAN', {
            userId: user?.id,
          })
          setIsSuccess(isSuccess)
          setIsEditMode(false)
          await refetch()
        }
      }

      setIsLoading(false)
    },
    [country, verifToken]
  )

  return (
    <Card className="bank-info" title={t('sellerBankInfoTitle')}>
      {isLoadingBankInfo && <Loader />}
      {!isLoadingBankInfo && (
        <>
          {isSuccess && (
            <Alert className="message success-message" emphasis="high" type="success">
              {t('sellerBankInfoUpdateSuccessMessage')}
            </Alert>
          )}
          {!isEditMode && (
            <>
              <dl className="current-bank-info">
                <dt className="bank-name-title">{t('sellerBankInfoBankNameFieldLabel')}</dt>
                <dd className="bank-name-value">{bankName}</dd>
                <dt className="iban-title">{t('sellerBankInfoIbanFieldLabel')}</dt>
                <dd className="iban-value">
                  {ibanFourLastDigits ? `XXXXXXXXXXXXXXXXXXXXXX${ibanFourLastDigits}` : ''}
                </dd>
                <dt className="bic-title">{t('sellerBankInfoBicFieldLabel')}</dt>
                <dd className="bic-value">{bic}</dd>
              </dl>
              <Button
                className="action primary toggle-bank-info-edit-mode-action"
                label={t('sellerBankInfoEditActionLabel')}
                onClick={handleToggleEditMode}
              />
            </>
          )}
          {isEditMode && (
            <>
              {!verifToken && (
                <Alert className="requested-verification-warning" emphasis="high" type="warning">
                  {t('sellerBankInfoUpdateRequestedVerificationWarningMessage')}
                </Alert>
              )}
              {!isSuccess && (
                <BankInfoForm
                  error={error}
                  initialValues={initialValues}
                  isLoading={isLoading}
                  verificationToken={verifToken}
                  onCancel={handleCancel}
                  onSubmit={handleSubmit}
                />
              )}
            </>
          )}
        </>
      )}
    </Card>
  )
}

export default memo(BankInfo)
