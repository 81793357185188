import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { isValidImageURL } from '@/components/ImportProducts/ProductsBulkImportForm2/helpers'
import Button from '@/components/ui/Button/Button'
import DeleteIcon from '@/components/ui/Icons/DeleteIcon/DeleteIcon'
import { PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT } from '@/config/constants'

import './ImagesCell.scss'

type ImagesTableCellProps = {
  images: string[]
  error?: string
  onUpdate: (updatedImagesUrls: string) => void
}

const ImagesCell = (props: ImagesTableCellProps) => {
  const { images, error, onUpdate } = props
  const { t } = useTranslation()

  const isValid = !error

  const handleDeleteImage = useCallback(
    (index: number) => {
      const newImagesUrls = images.filter((_, i) => i !== index).join('|')
      onUpdate(newImagesUrls)
    },
    [images, onUpdate]
  )

  return (
    <td className={`images ${isValid ? 'is-valid' : 'is-error'}`}>
      {images?.map((url, index) => {
        const isImageValid = index <= PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT - 1 && isValidImageURL(url)
        const classnames = `image ${isImageValid ? 'is-valid' : 'is-error'}`

        return (
          <div key={index} className={classnames}>
            <img key={url} src={url} />
            <Button
              className="delete-action"
              data-tooltip={t('bulkProductsImportDeleteImageActionTitle')}
              data-tooltip-position="top right"
              icon={<DeleteIcon />}
              label={t('bulkProductsImportDeleteImageActionLabel')}
              onClick={() => handleDeleteImage(index)}
            />
          </div>
        )
      })}
      {error && <span className="error">{error}</span>}
    </td>
  )
}

export default ImagesCell
