import { isAxiosError } from 'axios'

import { convertImageIfNecessary } from '@/helpers/images/convertImageIfNecessary'
import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { store } from '@/reducers'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

interface AddProductPictureProps {
  files: File[]
  productId: number
  updateInventoryProduct?: boolean
}

export const addProductPicture = async ({
  files,
  productId,
  updateInventoryProduct = false,
}: AddProductPictureProps) => {
  const state = store.getState()
  const {
    authentication: { token },
  } = state as RootState

  const form = new FormData()

  try {
    // NOTE: We do the following in series to make sure we keep the order of the images
    for (const file of files) {
      // TODO: this should be done higher up in the stack
      const usedFile = await convertImageIfNecessary(file)
      if (usedFile) {
        form.append('files[]', usedFile)
      }
    }

    const { data } = await internalRequest({
      headers: {
        authorization: token,
        'content-type': 'multipart/form-data',
        ...(await getAdditionalHeaders()),
      },
      data: form,
      url: `/products/${productId}/uploads-multipart?updateInventoryProduct=${updateInventoryProduct}`,
      method: 'POST',
      dispatch: store.dispatch,
    })

    return data
  } catch (error: any) {
    if (isAxiosError(error)) {
      return Promise.reject(error.response?.data)
    }

    throw error // Rethrow other errors
  }
}
