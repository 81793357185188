import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Currency } from '@/network/graphql/types.generated'

import { store } from '../reducers'

export type CurrencyFormatter = (amount: number, currency: Currency) => string

//TODO: Rename file to currency.ts since it's an helper for currency and not only formatter

/**
 * Returns a function that, given an amount in cents and its currency,
 * will return a formatted string to disaply this amount in user's
 * locale (exactracted from `i18next`).
 *
 * Note most of GraphQL amount fields come with a sibling `currency` field
 * that should be used in this situation.
 */
export const useCurrencyFormatter = (): CurrencyFormatter => {
  const {
    i18n: { language },
  } = useTranslation()
  return useCallback(
    (amountInCurrencyCents: number, currency: Currency) => {
      const fomatter = new Intl.NumberFormat(language, {
        style: 'currency',
        // `Currency` enum values seems to already be in a ISO 4217 currency code
        currency: currency,
      })

      return fomatter.format(amountInCurrencyCents / 100)
    },
    [language]
  )
}

/**
 * Will format a given amount in a currency using the sellerCurrency
 * from Redux store.
 *
 * @deprecated use `useCurrencyFormatter` instead.
 */
export const legacyCurrencyFormatter = (money: number) => {
  const locale = navigator.language

  const state = store.getState()
  const currency = state?.authentication?.user?.sellerConfig?.sellerCurrency
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(money)

  return formattedAmount
}

/**
 * Returns the most common currency in a collection of objects.
 * @param collection - The array of objects to search through.
 * @returns The most common currency in the collection or Currency.Eur if equality.
 */
export const computeMostUsedCurrency = (collection: { currency: Currency }[]): Currency => {
  const currencyCounts: Record<string, number> = {}

  let mostUsedCurrency = Currency.Eur // Set the default and fallback value to EUR

  collection.forEach((s) => {
    const key = String(s.currency)
    currencyCounts[key] = (currencyCounts[key] || 0) + 1
    mostUsedCurrency = (currencyCounts[key] > currencyCounts[mostUsedCurrency] ? key : mostUsedCurrency) as Currency
  })

  return mostUsedCurrency
}
