import { memo } from 'react'

import ArrowRightIcon from '../ui/Icons/ArrowIcon/ArrowRightIcon'

import type { ReactNode } from 'react'

import './ArrowCell.scss'

type ArrowCellProps = {
  icon?: ReactNode
  title: string
  subTitle?: string
  onClick: () => void
  border?: boolean
}

const ArrowCell = (props: ArrowCellProps) => {
  const { icon, title, subTitle, border = false, onClick } = props
  return (
    <div className={`arrow-cell ${border && 'bordered'}`} onClick={onClick}>
      {icon && <div className="arrow-cell-icon">{icon}</div>}
      <div className="arrow-cell-content">
        <h2 className="arrow-cell-title">{title}</h2>
        {subTitle && <p className="arrow-cell-subtitle">{props.subTitle}</p>}
      </div>

      <div className="product-type-cell-arrow">
        <ArrowRightIcon />
      </div>
    </div>
  )
}

export default memo(ArrowCell)
