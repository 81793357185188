import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FeaturedShowApplicationType } from '../../../../../../network/graphql/types.generated'
import { useGetFeaturedShowApplicationCriteriaQuery } from '../../../../operations.generated'
import ShowEventCriteria from '../ShowEventCriteria'

type FeaturedShowCriteriaProps = {
  showId: string
  handleNextSlide: () => void
  setFeaturedShowTargetInEuros: (value: number) => void
  featuredShowApplicationType: FeaturedShowApplicationType
  setFeaturedShowApplicationType: (value: FeaturedShowApplicationType) => void
}

const FeaturedShowCriteria = ({
  showId,
  handleNextSlide,
  setFeaturedShowTargetInEuros,
  featuredShowApplicationType,
  setFeaturedShowApplicationType,
}: FeaturedShowCriteriaProps) => {
  const { t } = useTranslation()

  const { data, loading: auctionDurationLoading } = useGetFeaturedShowApplicationCriteriaQuery({
    skip: !showId,
    variables: { showId: showId, applicationType: featuredShowApplicationType },
  })

  const {
    hasDoneEnoughShowsOnVoggt = false,
    isSellerRatingHighEnough = false,
    hasCreatedEnoughItemsWithPhotos = false,
    isShowDateInFutureEnough = false,
    featuredShowTarget: { isShowGMVTargetHighEnough = false, featuredShowTargetInEuros = 0 } = {},
  } = data?.node?.__typename === 'Show' ? data.node.featuredShowApplicationCriteria : {}

  useEffect(() => {
    setFeaturedShowTargetInEuros(featuredShowTargetInEuros)
  }, [featuredShowTargetInEuros])

  const canApplyToShowEvent =
    hasDoneEnoughShowsOnVoggt &&
    isSellerRatingHighEnough &&
    hasCreatedEnoughItemsWithPhotos &&
    isShowDateInFutureEnough &&
    isShowGMVTargetHighEnough

  const criteria = [
    { isCompleted: hasDoneEnoughShowsOnVoggt, title: t('showEventApplicationCriteriaHasDoneEnoughShowsOnVoggt') },
    { isCompleted: isSellerRatingHighEnough, title: t('showEventApplicationCriteriaIsSellerRatingHighEnough') },
    {
      isCompleted: hasCreatedEnoughItemsWithPhotos,
      title: t('showEventApplicationCriteriaHasCreatedEnoughItemsWithPhotos'),
    },
    {
      isCompleted: isShowDateInFutureEnough,
      title:
        featuredShowApplicationType === FeaturedShowApplicationType.Featured
          ? t('showEventApplicationCriteriaIsShowDateInFutureEnough')
          : t('premierShowApplicationCriteriaIsShowDateInFutureEnough'),
    },
    {
      isCompleted: isShowGMVTargetHighEnough,
      title: t('showEventApplicationCriteriaIsShowGMVTargetHighEnough', { featuredShowTargetInEuros }),
    },
  ]

  return (
    <>
      <div className="tab-bar">
        <button
          key="featured"
          className={`tab-bar-item ${featuredShowApplicationType === FeaturedShowApplicationType.Featured && 'active'}`}
          onClick={() => setFeaturedShowApplicationType(FeaturedShowApplicationType.Featured)}
        >
          {t('featuredShowApplicationTab')}
        </button>
        <button
          key="premier"
          className={`tab-bar-item ${featuredShowApplicationType === FeaturedShowApplicationType.Premier && 'active'}`}
          onClick={() => setFeaturedShowApplicationType(FeaturedShowApplicationType.Premier)}
        >
          {t('premierShowApplicationTab')}
        </button>
      </div>
      <ShowEventCriteria
        buttonText={t('commonNext')}
        canApply={canApplyToShowEvent}
        criteria={criteria}
        headerTitle={t('featuredShowApplicationTitle')}
        isLoading={auctionDurationLoading}
        onNext={handleNextSlide}
      />
    </>
  )
}

export default FeaturedShowCriteria
