import { useState } from 'react'

type useDroppableProps = {
  onDrop: (file: File) => void
}

const useDroppable = (props: useDroppableProps) => {
  const { onDrop } = props

  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  const handleDragLeave = (_event: React.DragEvent) => {
    setIsDraggingOver(false)
  }

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const file = event?.dataTransfer?.files[0]
    setIsDraggingOver(false)
    onDrop(file)
  }

  return {
    isDraggingOver,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  }
}

export default useDroppable
