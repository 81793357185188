import { memo } from 'react'
import reactStringReplace from 'react-string-replace'

import type { EmoteMap } from '../../hooks/useChat'
import type { CommentFragment } from '@/views/Shows/operations.generated'

interface CommentItemWithEmoteProps {
  item: CommentFragment
  emoteMap: EmoteMap | undefined
}

const EMOJI_REGEX = new RegExp(/(:[a-zA-Z0-9_]+:)/, 'gi')

export const CommentItemWithEmote = memo(({ emoteMap, item }: CommentItemWithEmoteProps) => {
  if (!emoteMap) return <>{item.text}</>
  return (
    <>
      {reactStringReplace(item.text, EMOJI_REGEX, (match, i) => {
        const emote = emoteMap[match]
        return emote ? (
          <img key={i} alt={match} className="inline" src={emote.gifUrl ?? emote.webPUrl} width="18" />
        ) : (
          match
        )
      })}
    </>
  )
})
