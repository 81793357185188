import * as Types from '../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShowViewersQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetShowViewersQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign', id: string } | { __typename?: 'AutocompleteSearchItem', id: string } | { __typename?: 'Badge', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'CommercialEvent', id: string } | { __typename?: 'Country', id: string } | { __typename?: 'Emote', id: string } | { __typename?: 'FavoriteProduct', id: string } | { __typename?: 'FavoriteShow', id: string } | { __typename?: 'ImageUpload', id: string } | { __typename?: 'InventoryProduct', id: string } | { __typename?: 'Language', id: string } | { __typename?: 'LoyaltyLevel', id: string } | { __typename?: 'Order', id: string } | { __typename?: 'OrderedProduct', id: string } | { __typename?: 'ParentCategory', id: string } | { __typename?: 'Product', id: string } | { __typename?: 'ProductForOrdering', id: string } | { __typename?: 'Promotion', id: string } | { __typename?: 'PromotionV2', id: string } | { __typename?: 'RatingFromCustomer', id: string } | { __typename?: 'SellerLedgerPayout', id: string } | { __typename?: 'SellerLedgerTransaction', id: string } | { __typename?: 'SellerShowsAggregatedByMonth', id: string } | { __typename?: 'SellerTier', id: string } | { __typename?: 'Shipment', id: string } | { __typename?: 'Shop', id: string } | { __typename?: 'Show', id: string, presentUsers: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: string, legacyId: number, avatarWebPUrl?: string | null, username: string } }> } } | { __typename?: 'ShowOrdersGroup', id: string } | { __typename?: 'ShowProductNode', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserSellerLoyaltyProgress', id: string } };

export type GetShowViewersCountQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetShowViewersCountQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign', id: string } | { __typename?: 'AutocompleteSearchItem', id: string } | { __typename?: 'Badge', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'CommercialEvent', id: string } | { __typename?: 'Country', id: string } | { __typename?: 'Emote', id: string } | { __typename?: 'FavoriteProduct', id: string } | { __typename?: 'FavoriteShow', id: string } | { __typename?: 'ImageUpload', id: string } | { __typename?: 'InventoryProduct', id: string } | { __typename?: 'Language', id: string } | { __typename?: 'LoyaltyLevel', id: string } | { __typename?: 'Order', id: string } | { __typename?: 'OrderedProduct', id: string } | { __typename?: 'ParentCategory', id: string } | { __typename?: 'Product', id: string } | { __typename?: 'ProductForOrdering', id: string } | { __typename?: 'Promotion', id: string } | { __typename?: 'PromotionV2', id: string } | { __typename?: 'RatingFromCustomer', id: string } | { __typename?: 'SellerLedgerPayout', id: string } | { __typename?: 'SellerLedgerTransaction', id: string } | { __typename?: 'SellerShowsAggregatedByMonth', id: string } | { __typename?: 'SellerTier', id: string } | { __typename?: 'Shipment', id: string } | { __typename?: 'Shop', id: string } | { __typename?: 'Show', id: string, presentUsers: { __typename?: 'UserConnection', totalCount: number } } | { __typename?: 'ShowOrdersGroup', id: string } | { __typename?: 'ShowProductNode', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserSellerLoyaltyProgress', id: string } };

export type ShowViewersSubscriptionVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type ShowViewersSubscription = { __typename?: 'Subscription', showPresentUsersChanged: { __typename?: 'ShowPresentUsersChangedEvent', showPresentUsersTotalCount: number } };


export const GetShowViewersDocument = gql`
    query GetShowViewers($first: Int!, $nodeId: ID!) {
  node(id: $nodeId) {
    id
    ... on Show {
      presentUsers(first: $first) {
        edges {
          cursor
          node {
            id
            legacyId
            avatarWebPUrl(options: {width: 32})
            username
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetShowViewersQuery__
 *
 * To run a query within a React component, call `useGetShowViewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowViewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowViewersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetShowViewersQuery(baseOptions: Apollo.QueryHookOptions<GetShowViewersQuery, GetShowViewersQueryVariables> & ({ variables: GetShowViewersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowViewersQuery, GetShowViewersQueryVariables>(GetShowViewersDocument, options);
      }
export function useGetShowViewersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowViewersQuery, GetShowViewersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowViewersQuery, GetShowViewersQueryVariables>(GetShowViewersDocument, options);
        }
export function useGetShowViewersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowViewersQuery, GetShowViewersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowViewersQuery, GetShowViewersQueryVariables>(GetShowViewersDocument, options);
        }
export type GetShowViewersQueryHookResult = ReturnType<typeof useGetShowViewersQuery>;
export type GetShowViewersLazyQueryHookResult = ReturnType<typeof useGetShowViewersLazyQuery>;
export type GetShowViewersSuspenseQueryHookResult = ReturnType<typeof useGetShowViewersSuspenseQuery>;
export type GetShowViewersQueryResult = Apollo.QueryResult<GetShowViewersQuery, GetShowViewersQueryVariables>;
export const GetShowViewersCountDocument = gql`
    query GetShowViewersCount($nodeId: ID!) {
  node(id: $nodeId) {
    id
    ... on Show {
      presentUsers(first: 0) {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetShowViewersCountQuery__
 *
 * To run a query within a React component, call `useGetShowViewersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowViewersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowViewersCountQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetShowViewersCountQuery(baseOptions: Apollo.QueryHookOptions<GetShowViewersCountQuery, GetShowViewersCountQueryVariables> & ({ variables: GetShowViewersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>(GetShowViewersCountDocument, options);
      }
export function useGetShowViewersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>(GetShowViewersCountDocument, options);
        }
export function useGetShowViewersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>(GetShowViewersCountDocument, options);
        }
export type GetShowViewersCountQueryHookResult = ReturnType<typeof useGetShowViewersCountQuery>;
export type GetShowViewersCountLazyQueryHookResult = ReturnType<typeof useGetShowViewersCountLazyQuery>;
export type GetShowViewersCountSuspenseQueryHookResult = ReturnType<typeof useGetShowViewersCountSuspenseQuery>;
export type GetShowViewersCountQueryResult = Apollo.QueryResult<GetShowViewersCountQuery, GetShowViewersCountQueryVariables>;
export const ShowViewersDocument = gql`
    subscription ShowViewers($showId: ID!) {
  showPresentUsersChanged(showId: $showId) {
    showPresentUsersTotalCount
  }
}
    `;

/**
 * __useShowViewersSubscription__
 *
 * To run a query within a React component, call `useShowViewersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useShowViewersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowViewersSubscription({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useShowViewersSubscription(baseOptions: Apollo.SubscriptionHookOptions<ShowViewersSubscription, ShowViewersSubscriptionVariables> & ({ variables: ShowViewersSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ShowViewersSubscription, ShowViewersSubscriptionVariables>(ShowViewersDocument, options);
      }
export type ShowViewersSubscriptionHookResult = ReturnType<typeof useShowViewersSubscription>;
export type ShowViewersSubscriptionResult = Apollo.SubscriptionResult<ShowViewersSubscription>;