import { BarElement, CategoryScale, Chart as ChartJS, defaults, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import { DEFAULT_BAR_CHART_OPTIONS } from './options'

import type { ShowItemFragment } from '../../Shows/operations.generated'

import globalStyle from '../../../styles/_export.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
defaults.font.family = 'Gantari-Regular'
defaults.font.weight = '600'

const ShowSum = ({ shows }: { shows: ShowItemFragment[] }) => {
  const dates = new Array(12)
    .fill(0)
    .map((_, index) => dayjs(new Date()).subtract(index, 'month').toDate())
    .reverse()
  const labels = dates.map((month) => dayjs(month).format('MMM'))

  const values: number[] = useMemo(() => {
    const _amounts: number[] = []
    for (const date of dates) {
      _amounts.push(shows.filter((show) => dayjs(date).isSame(show.startAt, 'month')).length)
    }

    return _amounts
  }, [shows])

  const data = {
    labels,
    datasets: [
      {
        data: values,
        dates: labels,
        backgroundColor: globalStyle.black160,
      },
    ],
  }

  return (
    <div className="w-full">
      <div className="w-full min-h-[250px]">
        <Bar data={data} options={DEFAULT_BAR_CHART_OPTIONS} />
      </div>
    </div>
  )
}

export default ShowSum
