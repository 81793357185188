import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'

import type { ChangeEvent } from 'react'

type InternationalFieldProps = {
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  value: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const InternationalField = (props: InternationalFieldProps) => {
  const { value: international, onChange: handleInternationalChange, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Field
      checked={international}
      className="international-field"
      disabled={disabled}
      label={t('giveawayLaunchModalCriteriaInternationalParticipants')}
      name="international"
      type="checkbox"
      onChange={handleInternationalChange}
    />
  )
}

export default InternationalField
