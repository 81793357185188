import { LeftOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretDown } from 'react-icons/fa6'

import Loader from '@/components/ui/Loader/Loader'

import { useCategoriesOrderedByPositionQuery, useParentCategoriesOrderedByPositionQuery } from './operations.generated'

import type { Category } from '@/network/graphql/types.generated'

import style from './style.module.scss'

type CategoriesCloudProps = {
  handleCategorySelect: (category: Category) => void
  selectedCategory?: string
  center?: boolean
  createShop?: boolean
}

const CategoriesCloud = (props: CategoriesCloudProps) => {
  const { handleCategorySelect, selectedCategory, center, createShop } = props
  const { t } = useTranslation()
  const [parentCategoryId, setParentCategoryId] = useState<null | string>(null)
  const [categoryId, setCategoryId] = useState<null | string>(null)

  const handleChangeParentCategory = (categoryId: string) => {
    setCategoryId(null)
    setParentCategoryId(categoryId)
  }

  const handleChangeCategory = (category: Category) => {
    setCategoryId(category.id)
    handleCategorySelect(category)
  }

  const { data: parentCategoriesData, loading: parentCategoriesLoading } = useParentCategoriesOrderedByPositionQuery({
    variables: {
      first: 10000,
    },
  })

  const { data: categoriesData, loading: categoriesLoading } = useCategoriesOrderedByPositionQuery({
    variables: {
      first: 10000,
    },
  })

  const getCategoriesByParentId = (parentCatId: string | null) => {
    const parentCategorySelected = parentCategoriesData?.parentCategoriesOrderedByPosition?.edges.find(
      ({ node: parentCategory }) => parentCategory.id === parentCatId
    )?.node

    return categoriesData?.categoriesOrderedByPosition.edges
      .filter(({ node: category }) =>
        parentCategorySelected?.allCategories.some((categoryFind: any) => categoryFind.id === category.id)
      )
      .filter(({ node: category }) => (createShop ? category.isOffline : !category.isOffline))
  }

  const parentCategoriesFiltered = parentCategoriesData?.parentCategoriesOrderedByPosition?.edges
    .filter(({ node: parentCategory }) => {
      const categories = getCategoriesByParentId(parentCategory?.id)
      const isArray = Array.isArray(categories)
      const categoriesNumber = isArray ? categories.length : 0

      if (categoriesNumber > 0) return true
      return false
    })
    .map(({ node }) => node)

  const getParentCategoryFormatted = (parentCategory: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 16,
          borderRadius: 16,
        }}
      >
        <div
          style={{
            backgroundColor: parentCategory.primaryColor,
            height: '48px',
            width: '48px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
          }}
        >
          <div
            style={{
              background: parentCategory.secondaryColor,
              mask: `url(${parentCategory.shapeUrl}) no-repeat 50% 50%`,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              WebkitMask: `url(${parentCategory.shapeUrl}) no-repeat 50% 50%`,
              maskSize: 'cover',
              WebkitMaskSize: 'cover',
            }}
          >
            <div
              style={{
                height: '32px',
                width: '32px',
              }}
            ></div>
          </div>
        </div>
        <span className="body_2" style={{ marginLeft: 8 }}>
          {parentCategory.name}
        </span>
      </div>
    )
  }

  const getCategoryFormatted = (category: any) => {
    return (
      <div className={style.cascader__formatted_category}>
        <img height={48} src={category.stickerUrl} style={{ borderRadius: 8, marginRight: 8 }} width={48} />
        <span className="body_3">{category.name}</span>
      </div>
    )
  }

  const isLoading = parentCategoriesLoading || categoriesLoading

  useEffect(() => {
    const parentCategoryId = categoriesData?.categoriesOrderedByPosition.edges.find(
      ({ node: category }) => category.id === selectedCategory
    )?.node.parentCategory.id

    if (selectedCategory) {
      if (typeof selectedCategory === 'string') {
        setCategoryId(selectedCategory)
      } else {
        setCategoryId(`Category|${selectedCategory}`)
      }
    }
    if (parentCategoryId) setParentCategoryId(parentCategoryId)
  }, [selectedCategory])

  const classNames = `border border-black-100 w-1/2 overflow-scroll ${parentCategoryId ? 'md:block hidden' : 'w-[100%] md:w-1/2 border-none'}`
  const classNames2 = `w-1/2 overflow-scroll ${parentCategoryId ? 'w-[100%] md:w-1/2 border-none' : 'md:block hidden'}`

  return (
    <div className={style.categories_cloud__cascader_container} style={{ justifyContent: center ? 'center' : 'left' }}>
      {isLoading && (
        <div className={style.cascader__loading_container}>
          <Loader />
        </div>
      )}

      {!isLoading && (
        <>
          <div className={classNames}>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {parentCategoriesFiltered?.map((parentCategory, i) => {
                return (
                  <li
                    key={`category_cloud__key_${i}`}
                    value={parentCategory.id}
                    className={`${style.categories_cloud__parent_item} ${
                      parentCategoryId === parentCategory.id && style.categories_cloud__parent_item_selected
                    }`}
                    onClick={() => handleChangeParentCategory(parentCategory.id)}
                  >
                    {getParentCategoryFormatted(parentCategory)}
                    <FaCaretDown direction="right" size={8} />
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={classNames2}>
            <div className="md:hidden">
              <div className="flex px-6 py-1 mt-4 items-center gap-1" onClick={() => setParentCategoryId(null)}>
                <LeftOutlined />
                {t('CategoriesCloudBackToParentCategories')}
              </div>
            </div>

            <ul style={{ listStyle: 'none', padding: 0 }}>
              {getCategoriesByParentId(parentCategoryId)?.map(({ node: category }, i) => (
                <li
                  key={`category_cloud_by_parent_key_${i}`}
                  className={`${style.categories_cloud__category_item} ${
                    categoryId === category.id && style.categories_cloud__category_item_selected
                  }`}
                  onClick={() => handleChangeCategory(category as Category)}
                >
                  {getCategoryFormatted(category)}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default CategoriesCloud
