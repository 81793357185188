import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { RootState } from '@/reducers'

interface DeleteInventoryProductPictureProps {
  fileId: number
  productId: string
}

export const deleteInventoryProductPicture = createAsyncThunk(
  'delete-inventory-product-picture',
  async ({ fileId, productId }: DeleteInventoryProductPictureProps, { rejectWithValue, getState, dispatch }) => {
    const {
      authentication: { token },
    } = getState() as RootState

    try {
      const { data } = await internalRequest({
        headers: {
          authorization: token,
          ...(await getAdditionalHeaders()),
        },
        url: `/inventory-products/${productId}/uploads/${fileId}`,
        method: 'DELETE',
        dispatch,
      })

      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  }
)
