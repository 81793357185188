import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SearchIcon from '@/components/ui/Icons/SearchIcon/SearchIcon'

import './ShipmentSearch.scss'

type ShipmentSearchProps = {
  onChange: (value: string) => void
  value: string
}

export default function ShipmentSearch({ onChange, value }: ShipmentSearchProps) {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>(value)

  let timeoutHandleSearchId: any
  const handleSearch = useCallback(
    async (value: any) => {
      setSearchValue(value.target.value)
      clearTimeout(timeoutHandleSearchId)

      timeoutHandleSearchId = setTimeout(async () => {
        const filterByText = value.target.value || undefined
        onChange(filterByText)
      }, 500)
    },
    [onChange]
  )

  return (
    <div className="shipments-search">
      <SearchIcon />
      <input
        className="shipments-search-input"
        placeholder={t('shipmentsSearchByUsernamePlaceholder')}
        type="search"
        value={searchValue}
        onChange={handleSearch}
      />
    </div>
  )
}
