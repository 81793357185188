import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import ResetPasswordForm from '@/views/Login/components/ResetPassword/ResetPasswordForm'

import { useRequestPasswordResetMutation } from './operations.generated'

import './styles.scss'

type ResetPasswordProps = {
  onCancel: () => void
}

const ResetPassword = (props: ResetPasswordProps) => {
  const { onCancel = () => undefined } = props

  const { t } = useTranslation()

  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [requestPasswordResetMutation] = useRequestPasswordResetMutation({
    onError: (error) => {
      setError(error.message)
    },
    onCompleted: () => {
      setSuccess(true)
    },
  })

  const handleResetPassword = useCallback(
    async (credential: string) => {
      await requestPasswordResetMutation({ variables: { credential } })
    },
    [requestPasswordResetMutation]
  )

  return (
    <Card className="reset-password" noPadding={true} title={t('loginResetPasswordCardTitle')}>
      <ResetPasswordForm error={error} success={success} onCancel={onCancel} onSubmit={handleResetPassword} />
    </Card>
  )
}

export default ResetPassword
