const SettingsIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10.0917 9.41455C11.0965 9.41455 11.9097 10.229 11.9097 11.2331C11.9097 12.2379 11.0965 13.0522 10.0917 13.0522C9.08692 13.0522 8.27283 12.2379 8.27283 11.2331C8.27283 10.229 9.08692 9.41455 10.0917 9.41455Z"
        fillRule="evenodd"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        clipRule="evenodd"
        d="M6.04961 2.94702C7.05441 2.94702 7.86874 3.76138 7.86874 4.76615C7.86874 5.77032 7.05441 6.58468 6.04961 6.58468C5.04484 6.58468 4.23071 5.77037 4.23071 4.7662C4.23071 3.76143 5.04484 2.94702 6.04961 2.94702Z"
        fillRule="evenodd"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        d="M2.44946 4.76685H4.23087M7.86782 4.76685H13.5264"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
      <path
        d="M2.44946 11.2332H8.27268M11.9097 11.2332H13.5264"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.38462"
      />
    </svg>
  )
}

export default SettingsIcon
