import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { computeConditionFieldOptions } from '@/components/CreateOrEditProduct/CreateOrEditProduct.helpers'
import Field from '@/components/Form/Field/Field'

type ProductConditionFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductConditionField = (props: ProductConditionFieldProps) => {
  const { value: condition, onChange: handleConditionChange, disabled = false } = props
  const { t } = useTranslation()

  const conditionOptions = useMemo(() => computeConditionFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormConditionLabel')}
      name="condition"
      options={conditionOptions}
      placeholder={t('productFormConditionPlaceholder')}
      type="select"
      value={condition}
      onChange={handleConditionChange}
    />
  )
}

export default ProductConditionField
