// import { useStripe } from '@stripe/react-stripe-js'
// import { message } from 'antd'
import moment from 'moment'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import {
  // useDispatch,
  connect,
} from 'react-redux'

// import { updateSellerAccount } from '@/actions/update-seller-billing-data'
// import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Popover from '@/components/ui/Popover/Popover'
import { DEFAULT_COUNTRY_ID } from '@/config/config'

import BillingInfoForm from './BillingInfoForm/BillingInfoForm'

import type { Moment } from 'moment'

import './BillingInfo.scss'

type BillingInfoProps = {
  seller: any // TODO: improve type
  billingData: any // TODO: improve type
}

const BillingInfo = (props: BillingInfoProps) => {
  const {
    // seller,
    billingData,
  } = props

  const { t } = useTranslation()
  // const formattedPhoneNumber = seller.phoneNumber?.startsWith('+3333')
  //   ? seller.phoneNumber.replace('+3333', '+33')
  //   : seller.phoneNumber

  const [current, setCurrent] = useState<number>(0)
  const carousel = useRef<any>()

  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [birthdate, setBirthdate] = useState<Moment | null>(null)
  const [address, setAddress] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [country, setCountry] = useState<string>(DEFAULT_COUNTRY_ID)

  // const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  // const dispatch = useDispatch<any>()
  // const stripe = useStripe()

  // const handleSubmit = async () => {
  //   if (!stripe) {
  //     return message.error(t('accountUpdateBillingDataRequestRejected'))
  //   }

  //   setIsSubmitLoading(true)

  //   const accountResult = await stripe
  //     .createToken('account', {
  //       business_type: 'individual',
  //       individual: {
  //         ...(formattedPhoneNumber ? { phone: formattedPhoneNumber } : {}),
  //         first_name: firstname,
  //         last_name: lastname,
  //         dob: {
  //           day: moment(birthdate).date(),
  //           month: moment(birthdate).month() + 1,
  //           year: moment(birthdate).year(),
  //         },
  //         email: seller.email,
  //         address: {
  //           line1: address,
  //           postal_code: zipcode,
  //           state: state?.length ? state : undefined,
  //           city: city,
  //           country: country,
  //         },
  //       },
  //     })
  //     .catch(() => {
  //       message.error(t('accountUpdateBillingDataRequestRejected'))
  //     })

  //   if (accountResult?.token?.id) {
  //     const updateSeller = await dispatch(updateSellerAccount({ accountToken: accountResult?.token?.id }))

  //     if (updateSeller.type.indexOf('rejected') !== -1) {
  //       message.error(updateSeller?.payload?.details || t('accountUpdateBillingDataRequestRejected'))
  //     }

  //     if (updateSeller.type.indexOf('fulfilled') !== -1) {
  //       message.success(t('accountUpdateBillingDataRequestSuccess'))
  //     }
  //   }

  //   setIsSubmitLoading(false)
  // }

  useEffect(() => {
    if (carousel?.current) {
      carousel.current.goTo(current)
    }
  }, [current])

  useEffect(() => {
    return function () {
      if (window) window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    if (billingData?.data?.individual) {
      const data = billingData.data.individual
      setFirstname(data.first_name)
      setLastname(data.last_name)
      setBirthdate(
        moment()
          .set('date', data.dob.day)
          .set('month', data.dob.month - 1)
          .set('year', data.dob.year)
      )
      setAddress(data.address.line1)
      setZipcode(data.address.postal_code)
      setCity(data.address.city)
      setState(data.address.state)
      setCountry(data.address.country)
    }
  }, [billingData])

  // const isFormValid = firstname && lastname && birthdate && address && zipcode && city && country

  return (
    <Card className="billing-info">
      <h2 className="billing-info-title">
        {t('accountBillingCardTitle')}
        <Popover
          content={
            <div>
              <p>{t('billingInfoAlertContentLine1')}</p>
              <p>{t('billingInfoAlertContentLine2')}</p>
              <p>{t('billingInfoAlertContentLine3')}</p>
            </div>
          }
        >
          <FaInfoCircle />
        </Popover>
      </h2>
      <BillingInfoForm
        address={address}
        billingData={billingData?.data}
        birthdate={birthdate}
        city={city}
        country={country}
        current={current}
        firstname={firstname}
        lastname={lastname}
        setAddress={setAddress}
        setBirthdate={setBirthdate}
        setCity={setCity}
        setCountry={setCountry}
        setCurrent={setCurrent}
        setFirstname={setFirstname}
        setLastname={setLastname}
        setState={setState}
        setZipcode={setZipcode}
        state={state}
        zipcode={zipcode}
      />
      {/* <div className="flex justify-end">
        <Button
          className="validate-action update-billing-info-action primary"
          disabled={!isFormValid}
          isLoading={isSubmitLoading}
          label={t('accountBillingButtonTitle')}
          tooltip={isFormValid ? undefined : t('accountButtonDisabledNotice')}
          onClick={handleSubmit}
        />
      </div> */}
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    seller: state.authentication?.user,
    billingData: state.authentication?.billingData,
  }
}

export default connect(mapStateToProps)(BillingInfo)
